import React, { useEffect, useState } from 'react'
import { GetBankList, GetBankTransactionHistory } from '../../../api/BankTransactionApi'
import { LocalTimeStampToDate } from '../../MyFunctions/MyFunctions'
import BankTransHisCss from './Css/BankTransactionHistory.module.css'

const BankTransactionHistory = () => {
    const [filterData, setFilterData] = useState()
    const [tableData, setTableData] = useState()
    function onDataChange(params) {
         
    }
    function onSearchClick(params) {
        
    }

   async function getDatafromDatabase(params) {
        let bankList = await GetBankList()
        let transactionData = await GetBankTransactionHistory()
        setTableData(transactionData)
    }
    useEffect(()=>{
        getDatafromDatabase()
    },[])
  return (
    <>
     <section className={BankTransHisCss.salesMain}>
              <div className={BankTransHisCss.innerSale}>
                  <h2 className={BankTransHisCss.saleHeading}>Bank Transaction History</h2>
                  <div className={BankTransHisCss.search_section}>
                      <div className={BankTransHisCss.input_section}>
                          <label>Start Date</label>
                          <input name='startDate' type={'date'} value={filterData?.startDate? filterData?.startDate:''} onChange={onDataChange}/>
                      </div>
                      <div className={BankTransHisCss.input_section}>
                          <label>End Date</label>
                          <input name='endDate' type={'date'} value={filterData?.endDate ? filterData?.endDate:''} onChange={onDataChange}/>
                      </div>
                     
                      
                  </div>

                  
                  <div className={BankTransHisCss.buttonWrapper}>
                          <button onClick={onSearchClick}>search</button>
                          <button>reset</button>
                      </div>
                  <div className={BankTransHisCss.tableSection}>
                      <table style={{marginTop:'0px'}}>
                          <tbody>
                              <tr>
                                  <th>Sl</th>
                                  <th>Date</th>
                                  <th>Transaction Type</th>
                                  <th>Particulars</th>
                                  <th>Cheque No</th>
                                  <th>Bank Name</th>
                                  <th>A/C Name</th>
                                  <th>A/C No</th>
                                  <th>To Account</th>
                                  <th>IN</th>
                                  <th>OUT</th>
                                  <th>Action</th>
                              </tr>
                              {tableData?.map((item, index)=>{
                                return <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{LocalTimeStampToDate(item.created_date)}</td>
                                    <td>{item.transactionType}</td>
                                    <td>{item.particulars} #({item.identity})</td>
                                    <td>{item.chequeNo ? item.chequeNo :'---'}</td>
                                    <td>{item.bankName}</td>
                                    <td>{item.acName}</td>
                                    <td>{item.acNumber}</td>
                                    <td>{item.toAccount ? item.toAccount:'---'}</td>
                                    <td>{item.receiveAmmount}</td>
                                    <td>{item.paymentAmmount}</td>
                                    <td>{'action'}</td>
                                </tr>
                              })}
                          </tbody>
                      </table>
                  </div>
              </div>
          </section>
    
    </>
  )
}

export default BankTransactionHistory