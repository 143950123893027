import React, { useEffect, useState } from "react";
import AddComplainCss from "./css/AddComplain.module.css";

const AddComplain = () => {
  let myDate = new Date();
  const [editData, setEditData] = useState();
  const [CustomerList, setCustomerList] = useState();
  const [searchText, setSearchText] = useState("");
  const [complainType, setComplainType] = useState();
  const [complainCat, setComplainCat] = useState();
  const [priorityData, setPriorityData] = useState();
  const [ComplainData, setComplainData] = useState();
  const [userData, setUserData] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  function onSearchChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    setSearchText({ ...searchText, [name]: value });
    setIsSelected(false);
    if (value === "") {
      setUserData();
    }
  }
  function onDataChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    // console.log(e.target.files)
    if (e.target.files) {
      let imageValue = e.target.files;
      // console.log(imageValue.length)
      let myArr = [];
      for (let index = 0; index < imageValue.length; index++) {
        const element = imageValue[index];
        myArr.push(element);
      }
      setComplainData({ ...ComplainData, [name]: myArr });
    } else {
      setComplainData({ ...ComplainData, [name]: value });
    }
  }

  async function saveToDataBase(params) {
    let myTime = myDate.getTime();
    let subjectError = ValidateFunction("subject", "Complain Subject ");
    let typeError = ValidateFunction("complainTypeID", "Complain Type ");
    let catError = ValidateFunction("complainCatID", "Complain Catagory ");
    let priorityError = ValidateFunction("priority", "Complain Priority ");
    let messageError = ValidateFunction("complainMessage", "Complain Message ");
    let customerError = "";
    //console.log(messageError)
    if (!userData) {
      customerError = "Customer Data Required ";
    }
    setErrorMessage({
      subjectError,
      typeError,
      catError,
      priorityError,
      messageError,
      customerError,
    });

    let finalData = {
      ...ComplainData,
      userName: userData.name,
      userId: userData.userID,
      status: "Created",
      solved: false,
    };
    delete finalData.complainImages;
    if (
      !subjectError &&
      !typeError &&
      !catError &&
      !priorityError &&
      !messageError &&
      !customerError
    ) {
      //   console.log(finalData)
      let myID = []; //await SaveData('userComplain', {...finalData})
      ComplainData?.complainImages?.forEach(async (element) => {
        // await MultipleImageUploadwtihURL(element, myTime+'__'+ element.name, 'userimages/comaplain', 'userComplain', 'complainImages', myID)
      });
    } else {
    }
    setComplainData();
    setUserData();
    //  console.log('data saved')
    // console.log(ComplainData?.complainImages.length)
    //    let myID = await SaveData('testData', {name:'hellob'})
  }
  function ValidateFunction(field, message) {
    let text = "";
    if (!ComplainData?.[field]) {
      text = message + "is Required";
    }
    return text;
  }
  async function getDatafromDataBase() {
    let mytypeData = []; // await GetWhereAndOrderData('ComplainSetting', 'identity', 'type', 'createdDate', 'asc')
    setComplainType(mytypeData);

    let myCatData = []; // await GetWhereAndOrderData('ComplainSetting', 'identity', 'category', 'createdDate', 'asc')
    setComplainCat(myCatData);
    let myPriotrityData = []; // await GetWhereAndOrderData('ComplainSetting', 'identity', 'priority', 'createdDate', 'asc')
    setPriorityData(myPriotrityData);
    let allCustomer = []; //await GetDatafromDatabase('user', 'userID')
    setCustomerList(allCustomer);
  }

  useEffect(() => {
    getDatafromDataBase();
  }, []);
  const errorStyle = {
    margin: "0",
    padding: "0",
    color: "red",
    width: "100%",
    textAlign: "right",
  };
  return (
    <section className={AddComplainCss.salesMain}>
      <div className={AddComplainCss.innerSale}>
        <h2 className={AddComplainCss.saleHeading}>Complain Setting</h2>

        <div
          className={`${AddComplainCss.common_class} ${AddComplainCss.complain_category}`}
        >
          <div className={AddComplainCss.input_section}>
            <div className={AddComplainCss.input_wrapper}>
              <label>Search By Costomer Name or Mobile Number *</label>
              <input
                onChange={onSearchChange}
                name="searchText"
                type={"text"}
                placeholder="Search By Costomer Name or Mobile Number"
                value={searchText?.searchText}
                onFocus={() => {
                  setIsSelected(false);
                }}
              />
              <div className={AddComplainCss.dropdown_list}>
                {CustomerList?.filter((fitem) => {
                  let msearchText = searchText.searchText?.toLowerCase();
                  let matchText = fitem.name.toLowerCase();
                  let matchTextnumber = fitem.phone.toLowerCase();
                  if (
                    msearchText &&
                    matchText !== msearchText &&
                    !isSelected &&
                    matchText.includes(msearchText)
                  ) {
                    return fitem;
                  } else if (
                    msearchText &&
                    matchText !== msearchText &&
                    !isSelected &&
                    matchTextnumber.includes(msearchText)
                  ) {
                    return fitem;
                  } else {
                    return null;
                  }
                })
                  .slice(0, 10)
                  .map((item, index) => {
                    return (
                      <p
                        key={index}
                        onClick={() => {
                          setSearchText({ searchText: item.name });
                          setUserData(item);
                          setIsSelected(true);
                        }}
                      >
                        {item.name} @ {item.phone}
                      </p>
                    );
                  })}
              </div>
              {/* <p>ok</p> */}
              <p style={{ ...errorStyle }}>{errorMessage?.customerError}</p>
              {userData ? (
                <div className={AddComplainCss.input_wrapper}>
                  <p>Customer Name : {userData?.name}</p>
                  <p>Customer Phone : {userData?.phone}</p>
                  <p>Customer Email : {userData?.email}</p>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className={AddComplainCss.input_wrapper}>
              <label>Complain Subject *</label>
              <input
                name="subject"
                type={"text"}
                placeholder="Complain Subject"
                onChange={onDataChange}
                value={ComplainData?.subject}
              />
              <p style={{ ...errorStyle }}>{errorMessage?.subjectError}</p>
            </div>

            <div className={AddComplainCss.input_wrapper}>
              <label>Complain Type *</label>
              <select
                name="complainTypeID"
                onChange={onDataChange}
                defaultValue={""}
              >
                <option value={""}>select one</option>
                {complainType?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.complainType}
                    </option>
                  );
                })}
              </select>
              <p style={{ ...errorStyle }}>{errorMessage?.typeError}</p>
            </div>

            <div className={AddComplainCss.input_wrapper}>
              <label>Complain Category *</label>
              <select
                name="complainCatID"
                onChange={onDataChange}
                defaultValue={""}
              >
                <option value={""}>select one</option>
                {complainCat?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.complainCatName}
                    </option>
                  );
                })}
              </select>
              <p style={{ ...errorStyle }}>{errorMessage?.catError}</p>
            </div>

            <div className={AddComplainCss.input_wrapper}>
              <label>Complain Priority *</label>
              <select name="priority" onChange={onDataChange} defaultValue={""}>
                <option value={""}>select one</option>
                {priorityData?.map((item, index) => {
                  return (
                    <option key={index} value={item.priority}>
                      {item.priority}
                    </option>
                  );
                })}
              </select>
              <p style={{ ...errorStyle }}>{errorMessage?.priorityError}</p>
            </div>

            <div className={AddComplainCss.input_wrapper}>
              <label>Attachments *</label>
              <input
                name="complainImages"
                type={"file"}
                onChange={onDataChange}
                multiple
              />
            </div>
            <div className={AddComplainCss.input_wrapper}>
              <label>Complain Message *</label>
              <textarea
                name="complainMessage"
                rows="4"
                cols="50"
                placeholder="Complain Category Notes"
                onChange={onDataChange}
                value={ComplainData?.complainMessage}
              ></textarea>
              <p style={{ ...errorStyle }}>{errorMessage?.messageError}</p>
            </div>

            <div className={AddComplainCss.buttonWrapper}>
              <button onClick={saveToDataBase}>Save Complain</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddComplain;
