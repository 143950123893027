import { faBars, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApiLink, GetFilteredDataExpress } from '../../api/ExpressFunctions';
import PurchaseReturnHisCss from './css/PurchaseReturnHistory.module.css';

const PurchaseReturnHistory = () => {
    const [tableData, setTableData] = useState([])
    const [spinnerShow, setSpinnerShow] = useState(false)
    const [summeryPop, setSummeryPop] = useState()
    const [backendUser, setBackenUser] = useState()

    function convertDate(time) {
        // console.log(time)
        const dateInMillis = time
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        // return myDate + " " + myTime
        return myDate + " " + myTime
    }

    function showSummeryPop(e, item) {
        //console.log(item)
        setSummeryPop(item)
    }
    const CommonStyle = {
        color: 'white',
        borderRadius: '5px',
        textAlign: 'center',
        padding: '5px 0'
    }
    const statusSwitch = (value) => {
        // console.log(value)
        let data = <></>
        switch (value) {
            case 'PO Received':
                data = <p style={{ ...CommonStyle, backgroundColor: '#EE5A24' }}>{value}</p>
                break;
            case 'PO Partial Return':
                data = <p style={{ ...CommonStyle, backgroundColor: '#096960' }}>{value}</p>
                break;
            case 'PO Full Return':
                data = <p style={{ ...CommonStyle, backgroundColor: '#6F1E51' }}>{value}</p>
                break;
            default:
                data = <p style={{ ...CommonStyle, backgroundColor: '#006266' }}>{'PO Placed'}</p>
                break;
        }

        return data
    }

    const tableItem = tableData?.map((item, index) => {
        return <MyTable key={item.id} po={'#' + item.id} date={convertDate(item.created_date)} quantity={item.quantity} total={Math.round(item.grandTotal)} processedby={<div className='salesHistoruImagesec'>
            {backendUser?.filter((fitem) => {
                return fitem.id === item.processorID ? fitem : ''
            })
                .map((uitem) => {
                    return <>
                        <img src={ApiLink + 'userimages/' + uitem.userImageName} />
                        {uitem.userName}
                    </>
                })}

        </div>}
            status={statusSwitch(item.status)}
            action={
                <div className={PurchaseReturnHisCss.actionButton}>
                    <Link to={'/admin/purchase/purchasereturnhistory/' + item.id + '/viewreturn'}><FontAwesomeIcon className='color-purple' icon={faEye} /></Link>
                    <FontAwesomeIcon style={{ cursor: 'pointer', color: 'green' }} icon={faBars} onClick={(e) => { showSummeryPop(e, item) }} />
                </div>
            } />
    })

    function checkClick(params) {

        const dateInMillis = new Date().getTime()
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        console.log(myDate)
    }

    const marginTop = {
        marginTop: '10px'
    }

    return (
        <>
            {spinnerShow ? <Spinner text={'Loading...'} /> : ""}
            {summeryPop ? <SummeryPop popData={summeryPop} setPopData={setSummeryPop} /> : ""}
            <section style={marginTop} className={PurchaseReturnHisCss.salesMain}>
                <div className={PurchaseReturnHisCss.innerSale}>
                    <h2 className={PurchaseReturnHisCss.saleHeading} onClick={checkClick}>Purchase Return History</h2>
                    <div className={PurchaseReturnHisCss.tableSection}>
                        <table>
                            <tbody>
                                <tr>
                                    <th>PO</th>
                                    <th>Date</th>
                                    <th>Total Quantity</th>
                                    <th>Total Ammount</th>
                                    <th>Processed By</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                                {tableItem}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PurchaseReturnHistory

const SummeryPop = ({ popData, setPopData }) => {
    const [tableData, setTableData] = useState()


    async function getPoSummery() {
        console.log(1)
        let summeryData = await GetFilteredDataExpress('purhcase_summery', 'purchase_id', popData.id)
        console.log(summeryData)
        let staticData = {
            supplierName: popData.supplierInfo?.name, 
            status: "PO Placed",
            supplier_id: popData.supplierID,
            attemptBy: popData.processorID,
            created_date: popData.created_date,
        }
        summeryData.splice(0, 0, staticData)
        setTableData(summeryData)

    }
    function convertDate(time) {
        const dateInMillis = time * 1000
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }

    useEffect(() => {
        getPoSummery()
    }, [])

    return <>
        <div className={PurchaseReturnHisCss.orderactivites}>
            <h3> Order Activities</h3>
            <h4> Order Activity History Of #{popData.PoNo}</h4>
            <table>
                <tbody>
                    <tr>
                        <th>Sl</th>
                        <th>Date</th>
                        <th>Supplier Name</th>
                        <th>Status</th>
                        <th>Attempt By</th>
                        <th>Attempt Time</th>
                    </tr>
                    {tableData?.map((item, index) => {
                        return <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{popData?.poDate.replaceAll('T', ' ')}</td>
                            <td>{item.supplier_id}</td>
                            <td>{item.status}</td>
                            <td>{item.attemptBy}</td>
                            <td>{convertDate(item.created_date)}</td>
                        </tr>
                    })}
                </tbody>
            </table>
            <button onClick={() => { setPopData() }}>Close</button>
        </div>
    </>
}


const MyTable = ({ po, date, quantity, total, processedby, status, action }) => {

    return <>
        <tr>
            <td>{po}</td>
            <td>{date}</td>
            <td>{quantity}</td>
            <td>{total}</td>
            <td>{processedby}</td>
            <td>{status}</td>
            <td>{action}</td>
        </tr>
    </>
}

const Spinner = ({ text }) => {
    const myIndex = {
        zIndex: '15'
    }
    return (
        <>
            <div style={myIndex} className={PurchaseReturnHisCss.SpinnerWrrapper}>
                <div id="loader"></div>
                <p>{text}</p>
            </div>
        </>
    )
}
