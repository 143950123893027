import Axios from "axios";
import { API_LINK } from "../APILink";

const MY_API_LINK = `${API_LINK}district/`


export async function GetDistrictList() {
    try {
        let result = await Axios.get(`${MY_API_LINK}all`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
