import React from "react";
import styles from "./css/scrollcomp.module.css";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
export default function ScrollComp({ divid }) {
  function topScroll() {
    if (divid) {
      let element = document.getElementById(divid);
      element.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }
  function bottomScroll() {
    if (divid) {
      let element = document.getElementById(divid);
      element.scrollTo({
        top: element.scrollHeight - element.clientHeight,
        behavior: "smooth",
      });
      // element.scrollTop = element.scrollHeight - element.clientHeight;
    } else {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  }
  return (
    <div className={styles.up_down}>
      <button onClick={topScroll}>
        <FaAngleUp />
      </button>
      <button onClick={bottomScroll}>
        <FaAngleDown />
      </button>
    </div>
  );
}
