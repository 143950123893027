import React, { useEffect, useState } from 'react'
import commonCss from './common.module.css'
import { FaRegQuestionCircle } from "react-icons/fa";
import { toast } from 'react-toastify';
import { UpdateProductList } from '../../../api/ProductListApi';
import { fetchProducts } from '../../../ReduxFiles/Slices/ProductSlice';
import { useDispatch } from 'react-redux';

export default function CommonAlertFile({popUp, setPopUp}) {
    const [messageState, setMessageState] = useState({})
     const product = popUp.item
     const dispatch = useDispatch()

   async function onYesClick() {
       try {
        switch (messageState.trigger) {
            case 1:
                await UpdateProductList(product.id, { featured: 1 })
                break;
            case 2:
                await UpdateProductList(product.id, { featured: 0 })
                console.log(product.id);
                break;
            case 3:
                await UpdateProductList(product.id, { stockout: 1 })
                break;
            case 4:
                await UpdateProductList(product.id, { stockout: 0 })
                break;
        
            default:
                break;
        }
        dispatch(fetchProducts())
        toast.success(`${product.en_sort_title} updated successfully.`)
        setPopUp()  
       } catch (error) {
        toast.error(error.message)
       }      
       document.body.style.overflow = 'auto'
    }

    function onNoClick() {
        setPopUp()
        document.body.style.overflow = 'auto'
    }

    useEffect(()=>{
        switch (popUp.identity) {
            case 'featured':
                  setMessageState({trigger:1, title:'Do you want to add this item as featured Product?', message : 'With this action this product will show on featured product in frontend.'})  
                break;
            case 'notfeatured':
                  setMessageState({trigger:2, title:'Do you want to remove this item from featured Product?', message : 'With this action this product will be removed from featured product in frontend.'})  
                break;
            case 'stockout':
                  setMessageState({trigger:3, title:'Do you want to set stock out for this product?', message : 'With this action this product will show stock out in frontend & user cannot order this item no longer. you can set stock in any time from here.'})  
                break;
            case 'instock':
                  setMessageState({trigger:4, title:'Do you want to set stock in for this product?', message : 'With this action this product will show stock in in frontend & user can order this item. make sure you have this product in your stock.'})  
                break;
                default:
                    break;
        }
    },[popUp])

  return (
    <div className={commonCss.wrapper}>
        <div className={commonCss.holder}>
            <h3>{product.en_sort_title}</h3>
            <div className={commonCss.icon}>
            <FaRegQuestionCircle />
            </div>
            <div className={commonCss.information}>
                <h2>{messageState.title}</h2>
                <p>{messageState.message}</p>
            </div>
            <div className={commonCss.button_section}>
                <button onClick={onNoClick}>No</button>
                <button onClick={onYesClick}>Yes</button>
            </div>
        </div>
    </div>
  )
}
