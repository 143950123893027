import React, { useEffect, useState } from "react";
import { FaBars, FaEdit, FaInfoCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ServerTimeToFormateTime } from "../../api/GlobalFunctions";
import {
  GetCompleteSalesData,
  GetOrderMethod,
  GetTotalOrderNumber,
} from "../../api/SalesApi";
import GobackButton from "../global/GobackButton";
import LoadingShow from "../smallThings/LoadingShow";
import SalesSearchComp from "./SalesSearchComp";
import ActivityViewModel from "./models/ActivityViewModel";
import CustomerInformationModel from "./models/CustomerInformationModel";
import CompOrCss from "./salesCss/CompleteOrder.module.css";
import { toast } from "react-toastify";
import EditCompForCharge from "./models/EditCompForCharge";
import { GrResources } from "react-icons/gr";
import { OrderMethodObject } from "../global/GlobalConstant";
import SourceModel from "./models/SourceModel";
const CompleteOrder = ({ mtableData }) => {
  const [viewNumber, setViewNumber] = useState(10);
  const [numberofPage, setNumberofPage] = useState();
  const [lastOrderID, setLastOrderID] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [showUser, setShowUser] = useState(false);
  const [spinnerShow, setSpinnerShow] = useState(false);
  const [activityView, setActivityView] = useState(false);

  async function customerClick(item) {
    setShowUser(item);
    document.body.style.overflow = "hidden";
  }
  async function activityViewClick(e, item) {
    setActivityView(item);
    document.body.style.overflow = "hidden";
  }

  useEffect(() => {
    async function tableDataFilter() {
      setSpinnerShow(true);
      let gettotalOrder = await GetTotalOrderNumber({
        filter: "deliveryComplete = 1",
      });
      let totalOrder = gettotalOrder[0]?.totalOrder;
      setNumberofPage(Math.round(totalOrder / viewNumber));
      let data = await GetCompleteSalesData({ limit: viewNumber });

      let tempArr = [];
      data?.forEach((element) => {
        let perdiscount =
          (element.discountPercent * parseInt(element.total_orprice)) / 100;
        let discount =
          element.total_orprice - (parseInt(element.total_price) - perdiscount);
        let total =
          parseInt(element.total_orprice) + element.deliveryCharge - discount;
        let due = total - (element.receiveAmount + element.rprice);
        tempArr.push({ ...element, total, due });
      });
      let lastID = tempArr[tempArr.length - 1];
      setLastOrderID(lastID.id);
      setTableData(tempArr);
      setSpinnerShow(false);
    }

    tableDataFilter();
  }, [mtableData, viewNumber]);

  const [customChange, setCustomChange] = useState(false);

  async function onPreCLick() {
    let firstID = tableData[tableData.length - 1];
    let lastID = tableData[0];
    if (firstID.id >= lastOrderID) {
      toast.warn("No Previous Data Found!");
    } else {
      setSpinnerShow(true);
      let data = await GetCompleteSalesData({
        limit: viewNumber,
        clicked: "pre",
        lastID: lastID.id,
      });
      let tempArr = [];
      data?.forEach((element) => {
        let perdiscount =
          (element.discountPercent * parseInt(element.total_orprice)) / 100;
        let discount =
          element.total_orprice - (parseInt(element.total_price) - perdiscount);
        let total =
          parseInt(element.total_orprice) + element.deliveryCharge - discount;
        let due = total - (element.receiveAmount + element.rprice);
        tempArr.push({ ...element, total, due });
      });
      let sortArr = tempArr.sort((a, b) => (a.id > b.id ? -1 : 1));
      setTableData(sortArr);
      setSpinnerShow(false);
      setCurrentPage((currentPage) => currentPage - 1);
    }
  }
  async function onNextCLick() {
    let lastID = tableData[tableData.length - 1];
    setSpinnerShow(true);
    let data = await GetCompleteSalesData({
      limit: viewNumber,
      clicked: "next",
      lastID: lastID.id,
    });
    let tempArr = [];
    data?.forEach((element) => {
      let perdiscount =
        (element.discountPercent * parseInt(element.total_orprice)) / 100;
      let discount =
        element.total_orprice - (parseInt(element.total_price) - perdiscount);
      let total =
        parseInt(element.total_orprice) + element.deliveryCharge - discount;
      let due = total - (element.receiveAmount + element.rprice);
      tempArr.push({ ...element, total, due });
    });
    setTableData(tempArr);
    setSpinnerShow(false);
    setCurrentPage((currentPage) => currentPage + 1);
  }

  function onPageNoChange(e) {
    setCustomChange(e.target.value);
  }
  async function onCustomClick() {}
  const [editComp, setEditComp] = useState();
  function onEditClick(item) {
    // console.log(item);
    setEditComp(item);
  }
  const [sourceData, setSourceData] = useState();
  async function onSourceClick(item) {
    const msourcedata = await GetOrderMethod(`order_id=${item.id}`);
    setSourceData({ ...item, source: msourcedata[0] || {} });
    document.body.style.overflow = "hidden";
  }
  // console.log(tableData);

  return (
    <section className={CompOrCss.wrapper}>
      <LoadingShow ShowLodign={spinnerShow} />

      {sourceData && (
        <SourceModel popUp={sourceData} setPopUp={setSourceData} />
      )}
      {activityView ? (
        <ActivityViewModel popUp={activityView} setPopUp={setActivityView} />
      ) : (
        ""
      )}
      {editComp && (
        <EditCompForCharge popUp={editComp} setPopUp={setEditComp} />
      )}

      {showUser && (
        <CustomerInformationModel popUp={showUser} setPopUp={setShowUser} />
      )}
      <div className={CompOrCss.holder}>
        <div className={CompOrCss.head}>
          <GobackButton />
          <h2>Complete Orders</h2>
          <div className={CompOrCss.view_quantity}>
            <label>View Quantity : </label>
            <select
              onChange={(e) => {
                setViewNumber(e.target.value);
              }}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={30}>30</option>
            </select>
          </div>
        </div>
        <div className={CompOrCss.search_holder}>
          <SalesSearchComp
            passedFrom={"complete"}
            setFilteredData={setTableData}
            limitNumber={viewNumber}
          />
        </div>
        <div className={CompOrCss.table_wrapper}>
          <table>
            <tbody>
              <tr>
                <th>SL</th>
                <th>id</th>
                <th>Date</th>
                <th>Customer Name</th>
                <th>Customer Phone</th>
                <th>Customer Address</th>
                <th>Status</th>
                <th>Total Ammount</th>
                <th>Due Ammount</th>
                <th>Confirmed By</th>
                <th>Action</th>
              </tr>
              {tableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {
                        <Link to={`/sales/complete_orders/${item.id}`}>
                          {"#" + item.id}
                        </Link>
                      }
                    </td>
                    <td>{ServerTimeToFormateTime(item.created_date)}</td>
                    <td>
                      <span onClick={() => customerClick(item)}>
                        {item.name}
                      </span>
                    </td>
                    <td>{item.aphone || item.phone}</td>
                    <td>{item.address || "In House Delivery"}</td>
                    <td>
                      <div className={CompOrCss.myStatus}>
                        {item.partialReturn ? (
                          <p className={CompOrCss.pertial_css}>
                            Order Partial Return
                          </p>
                        ) : (
                          <p className={CompOrCss.complete_css}>
                            Order Complete
                          </p>
                        )}
                      </div>
                    </td>
                    <td>{item.total.toFixed()}</td>
                    <td>{item.due.toFixed()}</td>
                    <td>{item.userName}</td>
                    <td>
                      <div className={CompOrCss.action_buttons}>
                        <Link to={`/sales/complete_orders/${item.id}`}>
                          <FaInfoCircle />
                        </Link>

                        <Link to={""}>
                          {" "}
                          <FaBars
                            onClick={(e) => {
                              activityViewClick(e, item);
                            }}
                          />
                        </Link>
                        <button>
                          <FaEdit onClick={() => onEditClick(item)} />
                        </button>
                        <button
                          className={
                            item.method_code > 0 ? CompOrCss.assigned : ""
                          }
                          title={
                            OrderMethodObject[item.method_code] ||
                            "not assigned"
                          }
                          onClick={() => onSourceClick(item)}
                        >
                          <GrResources />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={CompOrCss.pagination}>
          <button onClick={onPreCLick}>Pre</button>
          <input
            type={"number"}
            value={currentPage}
            onChange={onPageNoChange}
          />
          <p>of {numberofPage}</p>
          <button onClick={onNextCLick}>Next</button>
          {customChange ? <button onClick={onCustomClick}>Submit</button> : ""}
        </div>
      </div>
    </section>
  );
};

export default CompleteOrder;
