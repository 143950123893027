import React, { useEffect, useState } from 'react'

import DummyImage from "../../assets/dummyuser.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faBars, faEye, faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import DeclinedListCss from './css/DeclinedListCss.module.css'

const DeclinedList = () => {
    const [tableData, setTableData] =useState([])
    const[spinnerShow, setSpinnerShow] = useState(false)
    const [userImagestate, setUserImagastate] = useState()
    const [summeryPop, setSummeryPop] = useState()
   
    async function getAllData(params) {
        setSpinnerShow(true)
        let filteredData = []
        let allData = []  //await GetDatafromDatabasewithOrder('Purchase','createdDate', 'asc')
        setSpinnerShow(false)
        allData.forEach(element => {
            if (element.declined) {
                filteredData.push(element)
            }
        });
        setTableData(filteredData)
    }
    async function setAdminUserImage(){
        let myAllData = [] 
        setUserImagastate(myAllData)
        getAllData()
    }
    function getLink(name){
            let link = ''
            userImagestate?.forEach(element => {
                if (element.userName===name) {
                    link = element.userImage
                }
            });
            return link;

        }
    

    useEffect(()=>{
        setAdminUserImage()
    },[])
    function convertDate(time) {
        const dateInMillis = time * 1000
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        // return myDate + " " + myTime
        return myDate + " " + myTime
    }
    const toltipPos = {
        top:'30px',
    }
    function showSummeryPop(e, item) {
  
        setSummeryPop(item)
    }

    async function onRestoreClick(e, item) {
    
            let userData = window.localStorage.getItem('userData')
            userData = JSON.parse(userData)
            let summeryData = {
              supplierName:item.supplierInfo?.name,
              status:'PO Restored',
              attemptBy:userData.name,
            }
     
          //   await SaveNestedExistingData('Purchase', item.id, 'PoSummery', summeryData)
    
    
            // await UpdateData('Purchase', {declined:false, status:''}, item.id)
            let tableArr = []
             tableData?.forEach(element => {
                if (element.id===item.id) {
                }else{
                    tableArr.push(element)
                }
                
             });
             setTableData(tableArr)
            // setDataChanged(!dataChanged)
        }

    const CommonStyle = {
        color: 'white',
        borderRadius: '5px',
        textAlign: 'center',
        padding: '5px 0'
    }

        const statusSwitch =(value, item)=>{

           let data = <></>
            switch (value) {
                case 'PO Declined':
                    data = <p style={{...CommonStyle, backgroundColor:'#EA2027'}}>{value}</p>
                break;
                default:
                   data =  <p style={{...CommonStyle, backgroundColor:'#006266'}}>{'PO Placed'}</p>
                break;
            }
    
            return data
         }

    const tableItem = tableData?.map((item, index) => {
        return <MyTable key={item.id} po={'#' + item.PoNo} date={convertDate(item.createdDate?.seconds)} quantity={item.totalQuantity} total={item.total} processedby={<div className='salesHistoruImagesec'>
            {item.processedBy ? <img src={getLink(item.processedBy)} /> : <img src={DummyImage} />}
            {item.processedBy ? item.processedBy : "No User"}
        </div>}
        status={statusSwitch(item.status)}
            action={
                <div className={DeclinedListCss.actionButton}>
                    <Link to={'/admin/purchase/declinedpurchase/' + item.id + '/view'}>
                    <p className={DeclinedListCss.tooltip_holder}>
                    <FontAwesomeIcon className='color-purple' icon={faEye} />
                            <span style={toltipPos} className={DeclinedListCss.tooltip}>View Order</span>
                        </p>
                    </Link>
                    <a onClick={(e)=>{onRestoreClick(e, item)}}>
                        <p className={DeclinedListCss.tooltip_holder}>
                            <FontAwesomeIcon className='color-green' icon={faTrashRestore} />
                            <span style={toltipPos} className={DeclinedListCss.tooltip}>Restore Order</span>
                        </p>
                    </a>
                    <FontAwesomeIcon style={{cursor:'pointer', color:'blue'}} icon={faBars} onClick={(e)=>{showSummeryPop(e, item)}}/>
                </div>
            } />
    })

    function checkClick(params) {
        tableData.map((item)=>{
            console.log(item)
        })
    }
    
    const marginTop = {
        marginTop: '10px'
      }
    
  return (
    <>
     {summeryPop ?  <SummeryPop popData={summeryPop} setPopData={setSummeryPop}/>:""}
     {spinnerShow ?  <Spinner text={'Loading...'}/>:""}
    <section style={marginTop} className={DeclinedListCss.salesMain}>
                <div className={DeclinedListCss.innerSale}>
                    <h2 className={DeclinedListCss.saleHeading} onClick={checkClick}>Declined Purchase List</h2>
                    <div className={DeclinedListCss.tableSection}>
                        <table>
                           <tbody>
                           <tr>
                                <th>PO</th>
                                <th>Date</th>
                                <th>Total Quantity</th>
                                <th>Total Ammount</th>
                                <th>Processed By</th>
                                <th>status</th>
                                <th>Action</th>
                            </tr>
                            {tableItem}
                           </tbody>
                        </table>
                    </div>
                </div>
            </section>
    </>
  )
}

export default DeclinedList

const SummeryPop=({popData, setPopData})=>{
    const [tableData, setTableData] = useState()


   async function getPoSummery() {

    let summeryData =  []//await GetNestedAllDatawithOrder('Purchase', popData.id, 'PoSummery', 'summeryID', 'createdDate', 'asc')
        // console.log(summeryData)
        let staticData ={
            supplierName: popData.supplierInfo?.name,
            status:"PO Placed",
            attemptBy: popData.processedBy,
            createdDate:popData.createdDate,
        }
        summeryData.splice(0, 0, staticData)
        setTableData(summeryData)

    }
    function convertDate(time) {
        const dateInMillis = time * 1000
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }

    useEffect(()=>{
        getPoSummery()
    },[])

    return <>
    <div className={DeclinedListCss.orderactivites}>
        <h3> Order Activities</h3>
        <h4> Order Activity History Of #{popData.PoNo}</h4>
        <table>
            <tbody>
            <tr>
                <th>Sl</th>
                <th>Date</th>
                <th>Supplier Name</th>
                <th>Status</th>
                <th>Attempt By</th>
                <th>Attempt Time</th>
            </tr>
            {tableData?.map((item, index)=>{
                return <tr key={index}>
                    <td>{index+1}</td>
                    <td>{popData?.poDate.replaceAll('T', ' ' )}</td>
                    <td>{item.supplierName}</td>
                    <td>{item.status}</td>
                    <td>{item.attemptBy}</td>
                    <td>{convertDate(item.createdDate?.seconds)}</td>
                </tr>
            })}
            </tbody>
        </table>
        <button onClick={()=>{setPopData()}}>Close</button>
    </div>
</>
}

const MyTable = ({po, date, quantity, total, processedby, status, action}) => {

    return <>
      <tr>
        <td>{po}</td>
        <td>{date}</td>
        <td>{quantity}</td>
        <td>{total}</td>
        <td>{processedby}</td>
        <td>{status}</td>
        <td>{action}</td>    
      </tr>
    </>
  }

const Spinner = ({text}) => {
    const myIndex = {
      zIndex: '15' 
    }
    return (
       <>
         <div style={myIndex} className={DeclinedListCss.SpinnerWrrapper}>
        <div id="loader"></div>
        <p>{text}</p>
    </div>
       </>
    )
  }
