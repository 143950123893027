import MyAxios from 'axios'
import { API_LINK} from './APILink'
const MY_API_LINK = `${API_LINK}day_book/`

const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}

export async function SaveDayBookData(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetDayBookFilterData(query) {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}filter/${query}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
