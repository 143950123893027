import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LogintoDashboard from "./AllContent/ManageUser/LogintoDashboard";
import { selectIsLogedIn } from "./ReduxFiles/Slices/UserSlice";
import { getLocalUser } from "./utils/getLocalUser";
export default function ProtectedRoute({ children }) {
  const dispatch = useDispatch();
  const isLogedIn = useSelector(selectIsLogedIn);
  useEffect(() => {
    getLocalUser(dispatch);
  }, [dispatch]);

  if (isLogedIn) {
    return children;
  }

  return <LogintoDashboard />;
}
