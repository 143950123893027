import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GetAllBackUser } from '../../api/BackendUserApi'

const initialState = {
  userInfo: {},
  userList: [],
  activeUserList: [],
  inActiveUserList: [],
  isLogedIn: false,
  status: 'idle'
}
export const UserSlice = createSlice({
  name: 'backenuser',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
    },
    setUserList: (state, action) => {
      state.userList = action.payload
    },
    setIsLogedIn: (state, action) => {
      state.isLogedIn = action.payload
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUser.pending, (state, action) => {
        state.status = 'pending'
      })
      .addCase(fetchAllUser.fulfilled, (state, action) => {
        state.status = 'success'
        let mData = action.payload
        state.userList = mData
        let activetempArr = []
        let inactivetempArr = []
        mData.forEach(element => {
          if (element.active) {
            activetempArr.push(element)
          } else {
            inactivetempArr.push(element)
          }
        });
        state.activeUserList = activetempArr
        state.inActiveUserList = inactivetempArr
      })
      .addCase(fetchAllUser.rejected, (state, action) => {
        state.status = action.payload
      })
  },
})

export const fetchAllUser = createAsyncThunk('backenuser/fetchAllUser', async () => {
  try {
    const resposne = await GetAllBackUser()
    return resposne
  } catch (error) {
    return error.message
  }
})

export const selectIsLogedIn = state => state.backenuser.isLogedIn
export const selectUserInfo = state => state.backenuser.userInfo
export const selectBackUserList = state => state.backenuser.userList
export const selectActiveUserList = state => state.backenuser.activeUserList
export const selectinActiveUserList = state => state.backenuser.inActiveUserList
export const selectBackUserStatus = state => state.backenuser.status
export const { setUserInfo, setIsLogedIn, setStatus } = UserSlice.actions

export default UserSlice.reducer
