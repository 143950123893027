import React from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import gobackCss from "./css/goback.module.css";
export default function GobackButton() {
  const navigate = useNavigate();
  function onGoback() {
    navigate(-1);
  }
  return (
    <div className={gobackCss.wrapper}>
      <button onClick={onGoback}>
        <IoMdArrowBack />
        Back
      </button>
    </div>
  );
}
