
import React, { useEffect, useState } from 'react'
import { GetAllDataExpress } from '../../../api/ExpressFunctions'
import { Route, Routes } from 'react-router-dom'
import ExpenseReportHeadWisePDF from './ExpenseReportHeadWisePDF'
import ExpenseReportHeadWiseCss from './css/ExpenseReportHeadWise.module.css'
const ExpenseReportHeadWise = () => {
    
    return (
        <>
            <Routes>
                <Route path='/' element={<MainComp/>}/>
                <Route path='/expense-view-head' element={<ExpenseReportHeadWisePDF/>}/>
            </Routes>
        </>
    )
}

export default ExpenseReportHeadWise

const MainComp = () => {
    const [groupData, setGroupData] = useState()
    const [SearchData, setSearchData] = useState()
    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        if (name==='time_selection') {
            setSearchData({[name]:value})
        }else{
            setSearchData({...SearchData, [name]:value})
        }
    }

    function onSearchClick() {
        let staticLink = '/admin/report/expense_report_head/expense-view-head'
        let queryString = ''
        if (SearchData?.time_selection==='custom_range') {
            if (SearchData?.start_date && SearchData?.end_date) {
                if (SearchData?.headid) {
                    queryString = queryString = `/?start_date=${SearchData.start_date}&end_date=${SearchData.end_date} &head=${SearchData?.headid}`
                    window.open(staticLink+queryString, '_blank')
                } else {
                    queryString = `/?start_date=${SearchData.start_date}&end_date=${SearchData.end_date}`
                    window.open(staticLink+queryString, '_blank')
                }
            }else{
                alert('Please Select Date Range to Continue')
            }
        }else{
            if (SearchData?.headid) {
                queryString = `/?head=${SearchData.headid}`
                window.open(staticLink+queryString, '_blank')
            } else {
                window.open(staticLink, '_blank')
            }
           
        }
    }
   async function getExpenseGroup() {
        let myGroupData = await GetAllDataExpress('expense_head')
       // console.log(myGroupData)
       setGroupData(myGroupData)
    }
    useEffect(()=>{
        getExpenseGroup()
    },[])
   
    return <>
        <section className={ExpenseReportHeadWiseCss.heading}>
                <div className={ExpenseReportHeadWiseCss.inner_headign}>
                    <h2 className={ExpenseReportHeadWiseCss.headign_h2}>Expense Report Head Wise</h2>
        <div className={ExpenseReportHeadWiseCss.input_section}>
            <div className={ExpenseReportHeadWiseCss.common}>
                <select name='time_selection' onChange={onDataChange}>
                    <option value={'all-time'}>All Time</option>
                    <option value={'custom_range'}>Custom Range</option>
                </select>                
            </div>
            <div className={ExpenseReportHeadWiseCss.common}>
                <select name='headid' onChange={onDataChange}>
                   <option value={''}>Select Head</option>
                   {groupData?.map((item)=>{
                        return <option key={item.id} value={item.id}>{item.headName}</option>
                   })}
                </select>             
            </div>
        </div>
        {SearchData?.time_selection ==='custom_range' ? 
        <><div className={`${ExpenseReportHeadWiseCss.input_section2} ${ExpenseReportHeadWiseCss.date_section}`}>
        <div className={ExpenseReportHeadWiseCss.common}>
            <label>Start Date</label>
            <input name='start_date' type='date' onChange={onDataChange} />
        </div>
        <div className={ExpenseReportHeadWiseCss.common}>
            <label>End Date</label>
            <input name='end_date' type='date' onChange={onDataChange} />
        </div>
    </div></> : 
        ''}
        <div className={ExpenseReportHeadWiseCss.input_section2}>
            <div className={ExpenseReportHeadWiseCss.common}>
                <button className={ExpenseReportHeadWiseCss.report_button} onClick={onSearchClick}>Search</button>
            </div>
        </div>
        </div>
            </section>
    </>

}
