import React from 'react'
import { Link } from 'react-router-dom'
import expenseMainCss from './css/ExpenseMain.module.css'

const ExpenseMain = () => {
    const expenseMenu = [
        {title:'Add Expense', link:'/expense/add-new-expense', icon:''},
        {title:'Pending Expense', link:'/expense/pending', icon:''},
        {title:'Expense Hisrory', link:'/expense/history', icon:''},
        {title:'Declined Expense', link:'/expense/decline', icon:''},
        {title:'Expense Head', link:'/expense/head', icon:''},
        {title:'Expense Group', link:'/expense/group', icon:''},
    ]
    return (
        <div className={expenseMainCss.wrapper}>
        <div className={expenseMainCss.menuitems}> 
            {expenseMenu.map((item, index)=>{
                return <Link to={item.link} className={expenseMainCss.item} key={index}> 
                <h2>{item.title}</h2>  
        </Link>
            })}
        </div>
    </div>
       
    )
}

export default ExpenseMain

{/* <> 
<div className={ExpenseMainCss.menupart}>
    <ul className={ExpenseMainCss.menuitems}>
      
        
        <NavLink to={"/admin/expenses/declined_expense"}>
            <li >Declined Expense</li>
        </NavLink>
        <NavLink to={"/admin/expenses/expense_head"}>
            <li >Expense Head</li>
        </NavLink>
        <NavLink to={"/admin/expenses/expense_group"}>
            <li >Expense Group</li>
        </NavLink>
    </ul>
</div>
<div>
</div>
</> */}