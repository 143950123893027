import AllCombine from "./AllCombine";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { store } from "./ReduxFiles/store";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LogintoDashboard from "./AllContent/ManageUser/LogintoDashboard";
import ProtectedRoute from "./ProtectedRoute";
import AppLayout from "./AppLayout";
import ErrorPage from "./ErrorPage";

function App() {
  return (
    <>
      <Provider store={store}>
        <ToastContainer />
        <BrowserRouter>
          <Routes>
            <Route element={<ProtectedRoute>{<AppLayout />}</ProtectedRoute>}>
              <Route path="/*" element={<AllCombine />} />
            </Route>

            <Route path="login" element={<LogintoDashboard />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
