import React from 'react'
import { Link } from 'react-router-dom'
import accountCss from './Css/AccountMain.module.css'
const AccountMain = () => {
    const accountMenu = [
        { title: 'Debit Voucher', link: 'debit-voucher' },
        { title: 'Debit Voucher History', link: 'debit-voucher-history' },
        { title: 'Credit Voucher', link: 'credit-voucher' },
        { title: 'Credit Voucher History', link: 'credit-voucher-history' },
        { title: 'Day Book', link: 'day-book' },
        { title: 'Cash', link: 'cash' },
        { title: 'SSL', link: 'ssl' },
        { title: 'Add New Bank', link: 'add-bank' },
        { title: 'Bank List', link: 'bank-list' },
        { title: 'Manage-cheque', link: 'manage-cheque' },
        { title: 'Bank Balance List', link: 'bank-balance-list' },
        { title: 'Bank Transaction History', link: 'bank-transaction-history' },
        { title: 'Deposite', link: 'deposite' },
        { title: 'Withdraw', link: 'withdraw' },
        { title: 'Transaction in', link: 'transaction-in' },
        { title: 'Transaction out', link: 'transaction-out' },
        { title: 'Cheque in', link: 'cheque-in' },
        { title: 'Cheque in History', link: 'cheque-in-history' },
        { title: 'Cheque out', link: 'cheque-out' },
        { title: 'Cheque out History', link: 'cheque-out-history' },
        // { title: 'Check Api', link: '/check-api' },
      ];
      
    return (
        <div className={accountCss.wrapper}>
        <div className={accountCss.menuitems}>
            {accountMenu.map((item, index) => {
                return <Link to={item.link} className={accountCss.item} key={index}>
                    <h2>{item.title}</h2>
                </Link>
            })}
        </div>
    </div>
    )
}

export default AccountMain