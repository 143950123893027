import { faSquare, faSquareCheck} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { GetAllDataExpress } from '../../../api/ExpressFunctions'
import ShortReport from '../PdfFiles/ShortReport'
import DetailsReport from '../PdfFiles/DetailsReport'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAllSupplier, selectSupplierList, selectSupplierStatus } from '../../../ReduxFiles/Slices/SupplierSlice'
import PurchaseReportCss from './css/PurchaseReport.module.css'
import Select from 'react-select'

const PurchaseReport = () => {
    
    return (
        <>
            <Routes>
                <Route path='/' element={<MainComp/>}/>
                <Route path='/short_report' element={<ShortReport/>}/>
                <Route path='/details_report' element={<DetailsReport/>}/>
            </Routes>
           
        </>
    )
}

export default PurchaseReport

const MainComp =()=>{
    const [viewState, setViewState] = useState(1)
    
    return (
        <section className={PurchaseReportCss.heading}>
        <div className={PurchaseReportCss.inner_headign}>
            <h2 className={PurchaseReportCss.headign_h2}>Purchase Report</h2>
            <div className={PurchaseReportCss.buttons}>
                <div className={`${PurchaseReportCss.common} ${PurchaseReportCss.flex_row}`}>
                    <button className={viewState ===1 ? `${PurchaseReportCss.report_button} ${PurchaseReportCss.bg_green}`:`${PurchaseReportCss.report_button} ${PurchaseReportCss.bg_gray} ${PurchaseReportCss.color_purple}`} onClick={() => { setViewState(1) }}>Short Report</button>
                    <button className={viewState ===2 ? `${PurchaseReportCss.report_button} ${PurchaseReportCss.bg_green}`:`${PurchaseReportCss.report_button} ${PurchaseReportCss.bg_gray} ${PurchaseReportCss.color_purple}`} onClick={() => { setViewState(2) }}>Details Report</button>
                </div>
            </div>
            {viewState === 1 ? <ShortReportSection /> : ''}
            {viewState === 2 ? <DetailsReportSection /> : ''}
        </div>
    </section>
    )
}

const ShortReportSection = () => {
    const supplierStatus = useSelector(selectSupplierStatus)
    const supplierList = useSelector(selectSupplierList)
    const dispatch = useDispatch()
    // const [supplierList, setSupplierList] = useState()
    const [SearchData, setSearchData] = useState()
    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        setSearchData({...SearchData, [name]:value})
    }

    function onSearchClick() {
        let staticLink = '/admin/report/purchase_report/short_report'
        let queryString = ''
        if (SearchData?.start_date && SearchData?.end_date && SearchData?.supplierid) {
            queryString = `/?start_date=${SearchData.start_date}&end_date=${SearchData.end_date}&supplierid=${SearchData.supplierid}`
           
        }else if (SearchData?.start_date && SearchData?.end_date) {
            queryString = `/?start_date=${SearchData.start_date}&end_date=${SearchData.end_date}`
        }else if(SearchData?.supplierid){
            queryString = `/?supplierid=${SearchData.supplierid}`
        }
       // myNavigte(staticLink+queryString)
        window.open(staticLink+queryString, '_blank')

      //  myNavigte(staticLink)
    }
    async function getSupplier() {
        // let supplierData = await GetAllDataExpress('supplier')
        // console.log(supplierData)
        // setSupplierList(supplierData)
    }
    useEffect(() => {
        getSupplier()
        if (supplierStatus === 'idle') {
            dispatch(fetchAllSupplier())
        }
    }, [])
    return <>

        <div className={PurchaseReportCss.input_section}>
            <div className={PurchaseReportCss.common}>
                <select name={PurchaseReportCss.time_selection} onChange={onDataChange}>
                    <option value={'all-time'}>All Time</option>
                    <option value={'custom_range'}>Custom Range</option>
                </select>
            </div>
            <div className={PurchaseReportCss.common}>
                <select name='supplierid' onChange={onDataChange}>
                    <option value={''}>Select Supplier</option>
                    {supplierList?.map((item, index) => {
                        return <option key={index} value={item.id}>{item.companyaName}</option>
                    })}
                </select>
            </div>
        </div>
        {SearchData?.time_selection ==='custom_range' ? 
        <><div className={`${PurchaseReportCss.input_section2} ${PurchaseReportCss.date_section}`}>
        <div className={PurchaseReportCss.common}>
            <label>Start Date</label>
            <input name='start_date' type='date' onChange={onDataChange} />
        </div>
        <div className={PurchaseReportCss.common}>
            <label>End Date</label>
            <input name='end_date' type='date' onChange={onDataChange} />
        </div>
    </div></> : 
        ''}
        <div className={PurchaseReportCss.search}>
            <div className={PurchaseReportCss.common}>
                <button className={PurchaseReportCss.report_button} onClick={onSearchClick}>Search</button>
            </div>
        </div>
    </>
}
const DetailsReportSection = () => {
    // const myNavigte = useNavigate()
    const supplierList = useSelector(selectSupplierList)
    const [SearchData, setSearchData] = useState()
    const [checkProduct, setcheckProduct] = useState({all_product: true})
    const [allProctsSearch, setAllProductSearh] = useState()
    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        // console.log(name, value)
        setSearchData({...SearchData, [name]:value})
    }

    function onCheckClick(e, data) {
        setcheckProduct({[data]:true})
    }

    function onSearchClick() {
        let staticLink = '/admin/report/purchase_report/details_report'
        let queryString = ''
        if (SearchData?.start_date && SearchData?.end_date && SearchData?.supplierid && SearchData.productIdentity) {
            queryString = `/?start_date=${SearchData.start_date}&end_date=${SearchData.end_date}&supplierid=${SearchData.supplierid}&productIdentity=${SearchData.productIdentity}`
        }
        else if (SearchData?.start_date && SearchData?.end_date && SearchData?.supplierid) {
            queryString = `/?start_date=${SearchData.start_date}&end_date=${SearchData.end_date}&supplierid=${SearchData.supplierid}`
        }
        else if (SearchData?.start_date && SearchData?.end_date && SearchData?.productIdentity) {
            queryString = `/?start_date=${SearchData.start_date}&end_date=${SearchData.end_date}&productIdentity=${SearchData.productIdentity}`
        }
        else if (SearchData?.productIdentity && SearchData?.supplierid) {
            queryString = `/?productIdentity=${SearchData.productIdentity}&supplierid=${SearchData.supplierid}`
        }
        else if (SearchData?.start_date && SearchData?.end_date) {
            queryString = `/?start_date=${SearchData.start_date}&end_date=${SearchData.end_date}`
        }
        else if(SearchData?.productIdentity){
            queryString = `/?productIdentity=${SearchData.productIdentity}`
        }
        else if(SearchData?.supplierid){
            queryString = `/?supplierid=${SearchData.supplierid}`
        }
      //  console.log(staticLink+queryString)
        window.open(staticLink+queryString, '_blank')

    }
   
    useEffect(() => {
        let allProduct = window.localStorage.getItem('allProduct')
        setAllProductSearh(JSON.parse(allProduct))
    }, [])
    return <>
        <div className={PurchaseReportCss.DetailsReport}>
            <h3>Purchase Report (Received)</h3>
           
                <div className={`${PurchaseReportCss.common} ${PurchaseReportCss.selectioin_section}`}>
                <p onClick={(e)=>{onCheckClick(e, 'product')}} data='product'>
                    {checkProduct?.product ? <FontAwesomeIcon className={PurchaseReportCss.color_green} icon={faSquareCheck} />:<FontAwesomeIcon icon={faSquare}/>}
                    Product</p>
                <p onClick={(e)=>{onCheckClick(e, 'all_product')}}>
                    {checkProduct?.all_product ? <FontAwesomeIcon className={PurchaseReportCss.color_green} icon={faSquareCheck} />:<FontAwesomeIcon icon={faSquare}/>}
                    All Product</p>
                </div>
          
        </div>
      
       {checkProduct?.product ?  <div className={PurchaseReportCss.input_section2}>
       <Select options={allProctsSearch} 
       getOptionLabel={(option)=>`${option.en_sort_title}`}
       getOptionValue={(option)=>`${option.id}`}
       />

        </div>:''}
        <div className={PurchaseReportCss.input_section}>
            <div className={PurchaseReportCss.common}>
                <select name='time_selection' onChange={onDataChange}>
                    <option value={'all-time'}>All Time</option>
                    <option value={'custom_range'}>Custom Range</option>
                </select>
            </div>
            <div className={PurchaseReportCss.common}>
                <select name='supplierid' onChange={onDataChange}>
                    <option>All Supplier</option>
                    {supplierList?.map((item, index) => {
                        return <option key={index} value={item.id}>{item.companyaName}</option>
                    })}
                </select>
            </div>
        </div>
        {SearchData?.time_selection ==='custom_range' ? 
        <><div className={`${PurchaseReportCss.input_section} ${PurchaseReportCss.date_section}`}>
        <div className={PurchaseReportCss.common}>
            <label>Start Date</label>
            <input name='start_date' type='date' onChange={onDataChange} />
        </div>
        <div className={PurchaseReportCss.common}>
            <label>End Date</label>
            <input name='end_date' type='date' onChange={onDataChange} />
        </div>
    </div></> : 
        ''}
        <div className={PurchaseReportCss.input_section2}>
            <div className={PurchaseReportCss.common}>
                <button className={PurchaseReportCss.report_button} onClick={onSearchClick}>Search</button>
            </div>
        </div>
    </>
}