import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetAllCategory } from '../../api/CategoryApi'

const initialState = {
  category_list:[],
  status:'idle',
}
export const CategorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategoryList: (state, action) => {
      state.product_list = action.payload
    },
    setCategoryStatus: (state, action) => {
        state.status = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchCategory.pending, (state, action) => {
      state.status = 'pending'
    })
    .addCase(fetchCategory.fulfilled, (state, action) => {
      state.status = 'success'
      let mData = action.payload
        state.category_list = mData
    })
    .addCase(fetchCategory.rejected, (state, action) => {
      state.status = action.payload
    })
},
  
})

export const fetchCategory = createAsyncThunk('category/fetchCategory', async ()=>{
    try {
      const resposne = await GetAllCategory()
      return resposne
    } catch (error) {
      return error.message
    }
})


export const selectCategoryList = state =>state.category.category_list
export const selectCategoryStatus = state =>state.category.status
export const { setCategoryList, setCategoryStatus} = CategorySlice.actions

export default CategorySlice.reducer
