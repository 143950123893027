import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { GetBankList, UpdateBankData } from '../../api/BankTransactionApi'
import BankListCss from './css/BankList.module.css'

const BankList = () => {
const [tableData, setTableData] = useState()
const [bankData, setBankData] = useState()

   async function getBankList(params) {
    let BankList =  await GetBankList()
    setTableData(BankList)
    }
    useEffect(()=>{
        getBankList()
    },[])
    const marginTop = {
        marginTop: '10px'
    }
    return (
        <>
            {bankData ? <EditBank bankData={bankData} setBankData={setBankData} tableData={tableData} setTableData={setTableData}/>:''}
            <section style={marginTop} className={`${BankListCss.salesMain} ${BankListCss.manageuserMain}`}>
                <div className={BankListCss.innerSale}>
                    <h2 className={BankListCss.saleHeading}>Bank List</h2>
                    <div className={BankListCss.tableSection}>
                        <table>
                            <tr>
                                <th>SL</th>
                                <th>Bank Name</th>
                                <th>A/C Name</th>
                                <th>A/C Number</th>
                                <th>A/C Type</th>
                                <th>Ammount</th>
                                <th>status</th>
                                <th>Action</th>
                            </tr>
                            {tableData?.map((item, index)=>{
                                return <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{item.bankName}</td>
                                    <td>{item.acName}</td>
                                    <td>{item.acNumber}</td>
                                    <td>{item.acType}</td>
                                    <td>{item.ammount}</td>
                                    <td className={BankListCss.toggle}><ToggleSwitch toggleOnOff={item.active} id={item.id} tableData={tableData} setTableData={setTableData}/></td>
                                    <td><button className={BankListCss.edit_btn} onClick={()=>{setBankData(item)}}>edit</button></td>
                                </tr>
                            })}
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BankList

const ToggleSwitch = ({ toggleOnOff, id, tableData, setTableData}) => {
    function toggleClick() {
       let myArr = []
        tableData?.map(async (item)=>{
            if (item.id===id) {
                let okActive = !item.active
                item = {...item, active:okActive}
                myArr.push(item)
                await UpdateBankData(id,{active:okActive})
                
            }else{
                myArr.push(item)
            }
        })
        setTableData(myArr)
       // console.log(tableData[findIndex]['active'])
    }

    return (
        <>
            {toggleOnOff ? <div className={BankListCss.ToggleSwitchon}> <p onClick={() => { toggleClick(id) }}> </p><FontAwesomeIcon icon={faToggleOn} /></div> : <div className={BankListCss.ToggleSwitchoff}><p onClick={() => { toggleClick(id) }}> </p><FontAwesomeIcon icon={faToggleOff} /></div>}
        </>
    )
}

const EditBank = ({bankData, setBankData, tableData, setTableData}) => {
    const [inputData, setInputData] = useState(bankData)
    const [errText, setErrText] = useState()
    let bankNameArray = ['Dutch Bangla Bank Limited', 'Sonali Bank Limited', 'Janata Bank Limited', 'Agrani Bank Limited', 'BASIC Bank Limited', 'Pubali Bank Limited', 'BRAC Bank Limited', 'United Commercial Bank Ltd', 'Standard Chartered Bank', 'IFIC Bank Limited', 'Bangladesh Krishi Bank', 'AB Bank Limited', 'Eastern Bank Limited', 'Mercantile Bank Limited', 'UNITED COMMERCIAL BANK LTD.', 'Al-Arafah Islami Bank Limited', 'Islami Bank Limited', 'One Bank']
    bankNameArray.sort()

    function onInputChange(e) {
        let name = e.target.name
        let value = e.target.value
        setInputData({...inputData, [name]:value})
    }
  async  function onUpdateClick(params) {
       if (inputData) {
            let acNameErr = ValidateFunction('acName', 'A/C Name')
            let acTypeErr = ValidateFunction('acType', 'A/C Type')
            let branchNameErr = ValidateFunction('branchName', 'Branch Name ')
            let routingNameErr = ValidateFunction('routingNumber', 'Routing Number')
            let keyPersonErr = ValidateFunction('keyPName', 'Key Person Name ')
            let phoneErr = ValidateFunction('acPhone', 'Phone Number')
            let addressErr = ValidateFunction('acAddress', 'A/C Type')
            setErrText({ acNameErr, acTypeErr, branchNameErr, routingNameErr, keyPersonErr, phoneErr, addressErr})
            if (!acNameErr && !acTypeErr && !branchNameErr && !routingNameErr && !keyPersonErr && !phoneErr && !addressErr) {
                LiveUpdateData(bankData.id, inputData)
                delete inputData.id
                delete inputData.bankName
                delete inputData.acNumber
                delete inputData.ammount
                 await UpdateBankData(bankData.id, inputData)
                // alert('data saved')
                setBankData()

            }
       }else{
        alert('input data required')
       }
    }
    function  LiveUpdateData(id, changeData) {
        let myArr = []
        tableData?.map(async (item)=>{
            if (item.id===id) {
                let okActive = !item.active
                item = {...item, ...changeData}
                myArr.push(item)                
            }else{
                myArr.push(item)
            }
        })
        setTableData(myArr)
    }
    function ValidateFunction(field, message) {
        let text = ""
            if (!inputData[field]) {
                text = message + " is Required"
            }
        return text;
      }

      function onCloseClick() {
        setBankData()
      }

    return (
        <div className={BankListCss.edit_bank_wrapper}>
            <section className={BankListCss.edit_bank}>
                <h2>Edit Bank</h2>
                <div className={BankListCss.addsupplier}>
                    <div className={BankListCss.supplierinput} >
                        <label>Bank Name *</label>
                        <select name='bankName' onChange={onInputChange} defaultValue={inputData?.bankName} disabled>
                            <option value={''}>-Select Bank-</option>
                            {bankNameArray?.map((item, index) => {
                                return <option key={index} value={item}>{item}</option>
                            })}
                        </select>
                        {errText ? <p className={BankListCss.WarningText}>{errText?.bankNameErr}</p> : ""}
                    </div>
                    <div className={BankListCss.supplierinput}>
                        <label>A/C Name *</label>
                        <input name='acName' type='text' placeholder='A/C Name' onChange={onInputChange} value={inputData?.acName}/>
                        {errText ? <p className={BankListCss.WarningText}>{errText?.acNameErr}</p> : ""}
                    </div>
                    <div className={BankListCss.supplierinput} >
                        <label>A/C Type *</label>
                        <select name='acType' onChange={onInputChange} defaultValue={inputData?.acType}>
                            <option>-Select Bank-</option>
                            <option>Savings Account (SA)</option>
                            <option>Current Account (CA)</option>
                            <option>Short Notice Deposit (SND)</option>
                            <option>Fixed Deposit Receipt (FDR)</option>
                            <option>Monthly Saving Deposit (MSD)</option>
                            <option>Cash Credit Hypothecation (CCH)</option>
                            <option>Overdraft (OD)</option>
                            <option>Loans Account (LA)</option>
                        </select>
                        {errText ? <p className={BankListCss.WarningText}>{errText?.acTypeErr}</p> : ""}
                    </div>
                    <div className={BankListCss.supplierinput}>
                        <label>A/C Number *</label>
                        <input name='acNumber' type='text' placeholder='A/C Number' onChange={onInputChange} value={inputData?.acNumber} disabled/>
                        {errText ? <p className={BankListCss.WarningText}>{errText?.acNumberErr}</p> : ""}
                    </div>
                    <div className={BankListCss.supplierinput}>
                        <label>Branch Name *</label>
                        <input name='branchName' type='text' placeholder='Branch Name' onChange={onInputChange}  value={inputData?.branchName}/>
                        {errText ? <p className={BankListCss.WarningText}>{errText?.branchNameErr}</p> : ""}
                    </div>
                    <div className={BankListCss.supplierinput}>
                        <label>Routing Name *</label>
                        <input name='routingNumber' type='text' placeholder='Routing Number' onChange={onInputChange} value={inputData?.routingNumber}/>
                        {errText ? <p className={BankListCss.WarningText}>{errText?.routingNameErr}</p> : ""}
                    </div>
                    <div className={BankListCss.supplierinput}>
                        <label>Initial Ammount *</label>
                        <input name='ammount' type='text' placeholder='0.00' onChange={onInputChange} value={inputData?.ammount} disabled/>
                        {errText ? <p className={BankListCss.WarningText}>{errText?.ammountErr}</p> : ""}
                    </div>
                    <div className={BankListCss.supplierinput}>
                        <label>Key Person Name *</label>
                        <input name='keyPName' type='text' placeholder='Key Person Name' onChange={onInputChange} value={inputData?.keyPName}/>
                        {errText ? <p className={BankListCss.WarningText}>{errText?.keyPersonErr}</p> : ""}
                    </div>
                    <div className={BankListCss.supplierinput}>
                        <label>Phone Number *</label>
                        <input name='acPhone' type='text' placeholder='Phone Number' onChange={onInputChange} value={inputData?.acPhone}/>
                        {errText ? <p className={BankListCss.WarningText}>{errText?.phoneErr}</p> : ""}
                    </div>
                    <div className={BankListCss.supplierinput}>
                        <label>Address *</label>
                        <input name='acAddress' type='text' placeholder='Address' onChange={onInputChange} value={inputData?.acAddress}/>
                        {errText ? <p className={BankListCss.WarningText}>{errText?.addressErr}</p> : ""}
                    </div>
                    <div className={`${BankListCss.supplierinput} ${BankListCss.buttonsection}`}>
                        <button onClick={onUpdateClick}>Update</button>
                        <button onClick={onCloseClick}>Close</button>
                    </div>

                </div>
            </section>
        </div>
    )
}