import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBrands, selectBrandList, selectBrandStatus } from '../../ReduxFiles/Slices/BrandListSlice'
import { GetBrandImageLink, UpdateStatus } from '../../api/BrandListApi'
import ToggleSwitch from '../smallThings/ToggleSwitch'
import AddNewBrand from './BrandPopUp/AddNewBrand'
import UpdateBrand from './BrandPopUp/UpdateBrand'
import BrandSEOpop from './BrandSEOpop'
import BrandListCss from './css/BrandList.module.css'

const BrandList = () => {
    const dispatch = useDispatch()
    const allBrands = useSelector(selectBrandList)
    const brandStatus = useSelector(selectBrandStatus)
    const [addBrand, setAddBrand] = useState(false);
    const [editBrand, setEditBrand] = useState();
    const [tableData, setTabledata] = useState(allBrands);

    function addnewPop() {
        document.body.style.overflow = 'hidden'
        setAddBrand(true)
    }

    function EditClick(item) {
        setEditBrand(item)
        document.body.style.overflow = 'hidden'
    }
    async function switchClick(item) {
        let myNumber = 0
        if (item.WebsiteVisibitly) {
            myNumber = 0
        } else {
            myNumber = 1
        }
        await UpdateStatus(item.id, { WebsiteVisibitly: myNumber })

        let myArr = []
        tableData.forEach(element => {
            if (element.id === item.id) {
                element = {
                    ...element,
                    WebsiteVisibitly: myNumber
                }
                myArr.push(element)
            } else {
                myArr.push(element)
            }
        });

        setTabledata(myArr)
    }

    useEffect(() => {
        if (brandStatus === 'idle') {
            dispatch(fetchBrands())
        }
    }, [])

    const [seoData, setSeoData] = useState()
    function onSeoClick(item) {
        setSeoData(item);
    }
    
    return (
        <div className={BrandListCss.wrapper}>
            {addBrand && <AddNewBrand setPopUp={setAddBrand} />}
            {editBrand && <UpdateBrand popUp={editBrand} setPopUp={setEditBrand} />}
            {seoData && <BrandSEOpop popData={seoData} setPopData={setSeoData} />}           
            <div className={BrandListCss.info_group}>
            <h3 className={BrandListCss.itemlisth3} >Brand List</h3>
                <button onClick={addnewPop}>Add New Brand</button>
            </div>

            <div className={BrandListCss.table_wrapper}>
                <table>
                    <thead>
                        <tr>
                            <th>Sl</th>
                            <th>Brand Name</th>
                            <th>Brand Image</th>
                            <th>Brand Banner</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allBrands.map((item, index) => {
                            return <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.BrandName}</td>
                                <td><TableImageComp name={item.Brandimg} /></td>
                                <td><TableImageComp name={item.BrandBanner} /></td>
                                <td><ToggleSwitch toggleClick={() => { switchClick(item) }} toggleOnOff={item.WebsiteVisibitly} id={item.id} /></td>
                                <td><div className={BrandListCss.action_btns}>
                                    <button onClick={() => { EditClick(item) }}>Edit</button>
                                    <button onClick={() => { onSeoClick(item); document.body.style.overflow = 'hidden' }}>SEO</button>
                                </div></td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default BrandList

const TableImageComp = ({ name }) => {
    const [image, setImage] = useState()
    async function getLink() {
        if (name) {
            let imageInfo = await GetBrandImageLink({ image_name: name })
            if (imageInfo.imageExist) {
                setImage(imageInfo.url)
            } else {
                setImage('')
            }
        } else {
            setImage('')
        }
    }
    useEffect(() => {
        getLink()
    }, [])
    return (
        <>
            {image ? <img src={image} alt={name} /> : ''}
        </>
    )
}