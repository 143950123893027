import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GetALLSupplier } from '../../api/SupplierApi'

const initialState = {
  supplier_list:[],
  status:'idle'
}
export const SupplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    setSupplierList: (state, action) => {
      state.supplier_list = action.payload
    },
    setStatus: (state, action) => {
        state.status = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchAllSupplier.pending, (state, action) => {
      state.status = 'pending'
    })
    .addCase(fetchAllSupplier.fulfilled, (state, action) => {
      state.status = 'success'
      let mData = action.payload
        state.supplier_list = mData
    })
    .addCase(fetchAllSupplier.rejected, (state, action) => {
      state.status = action.payload
    })
},
})

export const fetchAllSupplier = createAsyncThunk('backenuser/fetchAllSupplier', async ()=>{
  try {
    const resposne = await GetALLSupplier()
    return resposne
  } catch (error) {
    return error.message
  }
})

export const selectSupplierList = state =>state.supplier.supplier_list
export const selectSupplierStatus = state =>state.supplier.status
export const { setSupplierList, setStatus} = SupplierSlice.actions

export default SupplierSlice.reducer
