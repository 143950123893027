import MyAxios from 'axios'
import { API_LINK} from './APILink'
const MY_API_LINK = `${API_LINK}bank-transaction/`
const BANK_LIST_API_LINK = `${API_LINK}bank-list/`

const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}

export async function GetBankList() {
    try {
        let result = await MyAxios.get(`${BANK_LIST_API_LINK}all`)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function AddBankData( data) {
    try {
        let result = await MyAxios.post(`${BANK_LIST_API_LINK}add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function UpdateBankData(id, data) {
    try {
        let result = await MyAxios.put(`${BANK_LIST_API_LINK}${id}`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetTodayTransaction(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}total`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function SaveBankTransactionData(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetBankTransactionHistory(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}transaction_history`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function IncreaseBankAmount(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}increase`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function DecreaseBankAmount(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}decrease`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
