
import React, { useEffect, useState } from 'react'
import { GetAllDataExpress, GetDataPassQuery } from '../../../api/ExpressFunctions'
import { HeaderFooterWrapper } from '../HeadetFooter'
import { GetALLSupplier } from '../../../api/SupplierApi'
import AllLedgerCss from './css/AllLedger.module.css'

const AllLedger = ({ heading, date }) => {
    const [invoiceState, setInvoiceState] = useState()
    async function getData() {
        let supplierData = [] //await GetAllDataExpress('supplier')
        // setSupplierList(supplierData)
        let purchaseData = await GetALLSupplier()
        let purchaseReturnData = [] // await GetAllDataExpress('purchase_return_items')
        let debitQuery =  `SELECT * FROM debit_voucher WHERE particulars='Purchase Payment'`
        let mPayment = [] //await GetDataPassQuery(debitQuery)
       // console.log(purchaseData, purchaseReturnData, mPayment)

        let DevidedData = []

        supplierData.forEach(supplier => {
            let tempArr = []
            purchaseData.forEach(purchaseElement => {
                if (supplier.id===purchaseElement.supplierID) {
                    tempArr.push(purchaseElement)
                }
            });
            let myOBJ = {
                marray:tempArr,
                name:supplier.companyaName,
                sid:supplier.id
            }
            DevidedData.push(myOBJ)
        });

      
       // console.log(DevidedData)
        getIndividualdata(DevidedData, purchaseReturnData, mPayment)
        // setInvoiceState(finalData)

    }

    function getIndividualdata(mainArr, returnArr, paymentArr) { 
        //  console.log(mainArr, returnArr, paymentArr)
        let finalArr = []
        mainArr.forEach(main_data => {
            let purchaseAmmount = 0
            let returnAmmount = 0
            let paidAmmount = 0
            let receiptAmmount = 0

            main_data?.marray?.forEach(element => {
                purchaseAmmount += singleDataPurchase(element)
                returnAmmount += singleDataReturn(returnArr, element.id, element.percent)
                paidAmmount += singleDataPaymnet(paymentArr, element.id)
                
            });
            let myfinalData = {
                name: main_data.name,
                purchaseAmmount,
                returnAmmount,
                paidAmmount,
                receiptAmmount,
                balance: (purchaseAmmount + receiptAmmount) - (paidAmmount + returnAmmount)
            }
            finalArr.push(myfinalData)
            //console.log(purchaseAmmount, returnAmmount, paidAmmount)
            
            
        });
       setInvoiceState(finalArr)


        
      //  return myfinalData
        // console.log(purchaseTotal, returnTotal, paidAmmount, receiptAmmount)
    }
    function singleDataPurchase(mysigleData) {
        let tempTotal = mysigleData.grandTotal 
        tempTotal = tempTotal - (mysigleData.percent ? tempTotal * parseFloat(mysigleData.percent) / 100 : 0)
        // console.log(tempTotal, mysigleData.percent, mysigleData.PoNo)
        return tempTotal
    }

    function singleDataReturn(returnData, identity, percent) {
        let tempTotal = 0
        returnData.forEach(relement => {
            if (relement.po_id===identity) {
                tempTotal += relement.price*relement.quantity
            }
        });
        tempTotal = tempTotal - ((percent ? tempTotal * parseFloat(percent) / 100 : 0))
        return tempTotal
    }

    function singleDataPaymnet(paymentData, identity) {
        let tempTotal = 0
        paymentData.forEach(element => {
            if (element.identity===identity) {
                tempTotal += element.paymentAmmount
            }
        });
        return tempTotal
    }
    function singleDataReceipt(mysigleData) {
        let tempReturn = 0
        mysigleData.receiptItem?.map((ritem) => {
            tempReturn += ritem.receiptAmmount
        })
        return tempReturn
    }


    useEffect(() => {
        getData()
    }, [])

    /* let myfinalData = {
                name: element.companyaName,
                purchaseAmmount,
                returnAmmount,
                paidAmmount,
                receiptAmmount,
                balance
            }
 */

    function getAlltotal(array, identity) {
        //invoiceState 
        let allTotal = 0
        array?.map((item) => {
            // console.log(item.purchaseAmmount)
            allTotal += item[identity] ? parseInt(item[identity]) : 0
        })
        // console.log(allTotal)
        return allTotal
    }

    return (
        <>

            <HeaderFooterWrapper
            content={
                <>
                <h3>{heading}</h3>
            <div className={AllLedgerCss.details}>
                <div className={AllLedgerCss.client}>
                    <div className={AllLedgerCss.info}>
                        <span className={AllLedgerCss.moreinfo}>Supplier : </span>All Supplier
                    </div>
                </div>
                <div className={AllLedgerCss.invoice}>
                    <div className={AllLedgerCss.info}>
                        {date ? <span className={AllLedgerCss.moreinfo}>Date : {date} </span> : ''}
                    </div>
                </div>
            </div>
            <div className={AllLedgerCss.table}>
                <table>
                    <thead>
                        <tr>
                            <th className={AllLedgerCss.no}>SI</th>
                            <th style={{ paddingLeft: '10px' }}>Supplier Name</th>
                            <th className={AllLedgerCss.unit}>Purchase Ammount</th>
                            <th className={AllLedgerCss.unit_price}>Return Ammount</th>
                            <th className={AllLedgerCss.total}>Paid Ammount</th>
                            <th className={AllLedgerCss.total}>Receipt Ammount</th>
                            <th className={AllLedgerCss.total}>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoiceState?.map((item, index) => {
                            return <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td style={{ textAlign: 'left' }} >{item.name}</td>
                                    <td>{Math.round(item.purchaseAmmount)}</td>
                                    <td>{Math.round(item.returnAmmount)}</td>
                                    <td>{Math.round(item.paidAmmount)}</td>
                                    <td>{Math.round(item.receiptAmmount)}</td>
                                    <td>{Math.round(item.balance)}</td>
                                </tr>
                        })}
                        <tr key={'ok'} className={AllLedgerCss.final_row}>
                            <td className={AllLedgerCss.no_border_right}></td>
                            <td className={AllLedgerCss.no_border_right}>Total :</td>
                            <td>{getAlltotal(invoiceState, 'purchaseAmmount')}</td>
                            <td>{getAlltotal(invoiceState, 'returnAmmount')}</td>
                            <td>{getAlltotal(invoiceState, 'paidAmmount')}</td>
                            <td>{getAlltotal(invoiceState, 'receiptAmmount')}</td>
                            <td>{getAlltotal(invoiceState, 'balance')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
                </>
            }
            />

        </>
    )
}

export default AllLedger
