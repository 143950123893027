import React, { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { GetPageContentSingle, UpdatePageContent } from '../../../api/PagesApi';
import JoditEditor from 'jodit-react';
import { toast } from 'react-toastify';
import PageContentEditCss from './css/PageContentEdit.module.css'
async function getData(id, setData) {
    let data = await GetPageContentSingle(id)
    setData(data)
}
const PageContentEdit = () => {
    const [pageContent, setPageContent] = useState({})
    const [searchParams] = useSearchParams();
    const content_id =  searchParams.get('content_id')
    useEffect(()=>{
        getData(content_id, setPageContent)
    },[content_id])
    const my_content = useRef()
    
    const config = {
        useSplitMode: false,
        toolbarSticky: true,
        toolbarStickyOffset: 51,
        uploader: {
            insertImageAsBase64URI: true
        },
        placeholder: 'Type Your Content Here...',
        showXPathInStatusbar: false,
      
    };
   async function onUpdateClick() {
     try {
        await UpdatePageContent(content_id, { my_content: my_content.current.value })
        toast.success('Data updated')
     } catch (error) {
        toast.error(error.message)
     }
    }
  return (
    <div className={PageContentEditCss.salesMain}>
          <h2 className={PageContentEditCss.heading}>Edit Data for {pageContent.catName || 'Home'}</h2>
        <div className={PageContentEditCss.innerSale}>
            <div className={PageContentEditCss.EditData}>
            
            <JoditEditor
                value={pageContent.my_content || ''}
                config={config}
                tabIndex={1}
                ref={my_content}
            />
            <div className={PageContentEditCss.update_btn}>

            <button onClick={onUpdateClick}>Update Data</button>
            
            </div>
            </div>
        </div>
       
    </div>
    
  )
}

export default PageContentEdit