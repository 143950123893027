import React, { useState } from "react";
import { Link, NavLink, Route, Routes, useParams } from "react-router-dom";
import ComplainFunctions from "./Actions/ComplainFunctions";
import ComplainEdit from "./Actions/ComplainEdit";
import AddComplain from "./AddComplain";
import ComplainList from "./ComplainList";
import ComplainSettingMain from "./ComplainSettingMain";
import ManageComplainMainCss from "./css/ManageComplainMain.module.css";

const ManageComplainMain = () => {
  const myParams = useParams();
  const [activeCustom, setActiveCustom] = useState(1);
  const MamageButton = [
    { name: "Manage Complains", link: "complain" },
    { name: "Complain Setting", link: "complain_setting" },
    { name: "Add Complain", link: "add_complain" },
  ];
  return (
    <>
      <div className={ManageComplainMainCss.menupart}>
        <ul className={ManageComplainMainCss.menuitems}>
          {MamageButton.map((item, index) => {
            return (
              <NavLink key={index} to={item.link}>
                <li>{item.name}</li>
              </NavLink>
            );
          })}
        </ul>
      </div>

      <Routes>
        <Route path="complain" element={<ComplainList />} />
        <Route path="complain_setting/*" element={<ComplainSettingMain />} />
        <Route path="add_complain" element={<AddComplain />} />
      </Routes>
    </>
  );
};

export default ManageComplainMain;
