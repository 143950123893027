import MyAxios from 'axios'
import { API_LINK} from './APILink'
const MY_API_LINK = `${API_LINK}debit-voucher/`

const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}

export async function SaveDebitData(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetDebitInitData() {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}get-init-data`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetDebitDataFilter(query) {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}filter/${query}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function CustomerDebitData(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}customer-debit`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
