import CustomerListCss from "./css/CustomerList.module.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInitCustomer,
  fetchSearchedCustomer,
  selectCustomerStatus,
  selectInitCustomer,
} from "../../ReduxFiles/Slices/CustomerSlice";
import { GetLimitCustomer } from "../../api/CustomerApi";
import { LocalTimeStampToDateOnly } from "../MyFunctions/MyFunctions";
import Select from "react-select";
import { toast } from "react-toastify";
import AddressView from "./models/AddressView";
import EditCustomer from "./models/EditCustomer";
import ProfileView from "./models/ProfileView";
import { Link } from "react-router-dom";
import { FaEye, FaEdit } from "react-icons/fa";
import { IoMdInformationCircle } from "react-icons/io";
const CustomerList = () => {
  const dispatch = useDispatch();
  const userData = useSelector(selectInitCustomer);
  const [myUserData, setMyUserData] = useState([]);
  const customerStatus = useSelector(selectCustomerStatus);
  const [editUserData, setEditUserData] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const [profileData, setProfileData] = useState();
  const [addressData, setAddressData] = useState();
  const [userLimit, setUserLimit] = useState(20);

  useEffect(() => {
    if (customerStatus === "idle") {
      dispatch(fetchInitCustomer(userLimit));
    }
    setMyUserData(userData);
  }, [customerStatus, userData, userLimit, dispatch]);

  function onEditClick(e, item) {
    setEditUserData(item);
  }
  function onInfoClick(e, item) {
    setProfileData(item);
  }
  function onAddressViewClick(e, item) {
    setAddressData(item);
  }

  const myref = useRef();

  function onResetClick() {
    dispatch(fetchInitCustomer(userLimit));
    myref.current.value = "";
  }

  async function onDataChange(e) {
    let inputValue = e.target.value;
    if (inputValue.length >= 3) {
      dispatch(fetchSearchedCustomer(inputValue));
    } else {
      dispatch(fetchInitCustomer(userLimit));
    }
  }
  const [myOffset, setMyOffset] = useState(userLimit);
  async function onPreClick() {
    let offset = myOffset - userLimit;
    if (offset > 0) {
      let data = await GetLimitCustomer({ offset: offset, limit: userLimit });
      setMyOffset(myOffset - userLimit);
      setMyUserData(data);
    } else {
      toast.warn("No Previous Data Found");
    }
  }
  async function onNextClick() {
    let data = await GetLimitCustomer({
      offset: myOffset + userLimit,
      limit: userLimit,
    });

    setMyOffset(myOffset + userLimit);
    setMyUserData(data);
  }
  const options = [
    { value: "019", label: "Banglalink", value2: "014" },
    { value: "017", label: "Grameenphone", value2: "013" },
    { value: "018", label: "Robi" },
    { value: "015", label: "Teletalk" },
    { value: "016", label: "Airtel" },
  ];

  async function onOperatorChange(e) {
    // console.log(e);
    if (e) {
      let data = await GetLimitCustomer({
        operatorCode: e.value,
        opcode2: e.value2,
      });
      setMyUserData(data);
    } else {
      dispatch(fetchInitCustomer(userLimit));
    }
  }
  function onLimitChange(e) {
    let value = e.target.value;
    setUserLimit(value);
  }
  return (
    <section className={CustomerListCss.salesMain}>
      {alertMessage ? (
        <AlertMessage
          message={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      ) : (
        ""
      )}

      {editUserData && (
        <EditCustomer popUp={editUserData} setPopUp={setEditUserData} />
      )}
      {profileData ? (
        <ProfileView popUp={profileData} setPopUp={setProfileData} />
      ) : (
        ""
      )}
      {addressData ? (
        <AddressView popUp={addressData} setPopUp={setAddressData} />
      ) : (
        ""
      )}

      <div className={CustomerListCss.innerSale}>
        <h2 className={CustomerListCss.saleHeading}>Manage Customer</h2>
        <div className={CustomerListCss.filter_sections}>
          <div className={CustomerListCss.input_wrapper}>
            <label>Limit</label>
            <input
              ref={myref}
              name="limit"
              type="number"
              defaultValue={userLimit}
              onChange={onLimitChange}
            />
          </div>
          <div className={CustomerListCss.input_wrapper}>
            <label>Invoice No*</label>
            <input
              ref={myref}
              name="nmae"
              type="search"
              onChange={onDataChange}
              placeholder={"Invoice No"}
              autoComplete="off"
            />
          </div>
          <div className={CustomerListCss.input_wrapper}>
            <label>Customer name or Phone number*</label>
            <input
              ref={myref}
              name="nmae"
              type="search"
              onChange={onDataChange}
              placeholder={"customer name or phone number"}
              autoComplete="off"
            />
          </div>
          <div className={CustomerListCss.input_wrapper}>
            <label>Address*</label>
            <input
              ref={myref}
              name="nmae"
              type="search"
              onChange={onDataChange}
              placeholder={"customer name or phone number"}
              autoComplete="off"
            />
          </div>
          <div className={CustomerListCss.input_wrapper}>
            <label>SIM*</label>
            <Select options={options} onChange={onOperatorChange} isClearable />
          </div>
          <div
            className={`${CustomerListCss.buttonWrapper} ${CustomerListCss.search_product}`}
          >
            <button onClick={onResetClick}>Reset</button>
          </div>
        </div>
        <div className={CustomerListCss.tableSection}>
          <table>
            <thead>
              <tr>
                <th>SL</th>
                <th>user id</th>
                <th>Joined</th>
                <th>Name</th>
                <th>Email</th>
                <th>Total Order</th>
                <th>Address</th>
                <th>Phone Number</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {myUserData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>#{item.id}</td>
                    <td>{LocalTimeStampToDateOnly(item.created_date)}</td>
                    <td>{item.name}</td>
                    <td>{item.email || "-"}</td>
                    <td>
                      <Link
                        to={`/reports/customer-ladger/view_customer_ledger?phone=${item.phone}`}
                        target="blank"
                      >
                        {item.total_order}
                      </Link>
                    </td>
                    <td>
                      <FaEye
                        onClick={(e) => {
                          onAddressViewClick(e, item);
                        }}
                      />
                    </td>
                    <td>{item.phone}</td>
                    <td>
                      <div className={CustomerListCss.userIcon}>
                        <IoMdInformationCircle
                          onClick={(e) => {
                            onInfoClick(e, item);
                          }}
                        />

                        <FaEdit
                          onClick={(e) => {
                            onEditClick(e, item);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
              {/* <FontAwesomeIcon icon={faTrashCan} onClick={(e) => { onDeleteClick(e, item); document.body.style.overflow = 'hidden' }} /> */}
            </tbody>
          </table>
          <div className={CustomerListCss.btn_group}>
            <button onClick={onPreClick}>Pre</button>
            <button onClick={onNextClick}>Next</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomerList;

const AlertMessage = ({ message, setAlertMessage }) => {
  // yeasin
  const cancelClick = () => {
    setAlertMessage();
    document.body.style.overflow = "auto";
  };

  function alertClick() {
    document.body.style.overflow = "hidden";
    setAlertMessage();
  }
  const zindex = {
    zIndex: 1000,
  };
  return (
    <div className={CustomerListCss.AlertMessage_wrapper}>
      <div style={zindex} className={CustomerListCss.AlertMessage}>
        <h2>{message}</h2>
        <div className={CustomerListCss.alert_btn}>
          <button onClick={alertClick}>Delete</button>
          <button onClick={cancelClick}>Cancel</button>
        </div>
      </div>
    </div>
  );
};
