import React from "react";
import { IoClose } from "react-icons/io5";
import profileChangePassCss from "./css/profileChangePassword.module.css";
import { UpdateUserPassword } from "../../../../api/BackendUserApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setIsLogedIn,
  setUserInfo,
} from "../../../../ReduxFiles/Slices/UserSlice";
export default function ProfileChangePassword({ popUp, setPasswordPop }) {
  function onClose() {
    setPasswordPop(false);
  }
  const myNavigate = useNavigate();
  const myDispatch = useDispatch();
  async function onUpdatePassword(e) {
    e.preventDefault();
    const oldpassword = e.target.oldpassword.value;
    const newpassword = e.target.newpassword.value;
    const confirmpass = e.target.confirmpass.value;
    const username = popUp.userName;

    try {
      if (newpassword === confirmpass) {
        const result = await UpdateUserPassword({
          oldpassword,
          newpassword,
          confirmpass,
          username,
        });
        if (result.error) {
          toast.error(result.message);
        } else {
          toast.success(result.message);
          window.localStorage.removeItem("userData");
          myNavigate("/");
          myDispatch(setIsLogedIn(false));
          myDispatch(setUserInfo({}));
        }
      } else {
        toast.error("Password Mismatch!");
      }
    } catch (error) {
      toast.error(error.message);
    }

    //
  }
  return (
    <div className={profileChangePassCss.wrapper_popup}>
      <div className={profileChangePassCss.holder}>
        <h3>Change Password</h3>
        <button onClick={onClose} className={profileChangePassCss.close_button}>
          <IoClose />
        </button>
        <form onSubmit={onUpdatePassword}>
          <div className={profileChangePassCss.input_wrapper}>
            <label>Old Password*</label>
            <input type="password" name="oldpassword" required />
          </div>
          <div className={profileChangePassCss.input_wrapper}>
            <label>New Password*</label>
            <input type="password" name="newpassword" required />
          </div>
          <div className={profileChangePassCss.input_wrapper}>
            <label>Confirm Password*</label>
            <input type="password" name="confirmpass" required />
          </div>
          <div className={profileChangePassCss.button_wrapper}>
            <button>Change Password</button>
          </div>
        </form>
      </div>
    </div>
  );
}
