import React from 'react'
import LoadingShowCss from './css/LoadingShow.module.css'

const LoadingShow = ({ ShowLodign, message }) => {
    return (
        <>
         {ShowLodign ? <div className={LoadingShowCss.wrapper}>
                <div className={LoadingShowCss.load_wrapp}>
                    <div className={LoadingShowCss.load_3}>
                        <p>Please wait</p>
                        <h3>{message || 'Loading your Data ...'}</h3>
                        <div className={LoadingShowCss.line}></div>
                        <div className={LoadingShowCss.line}></div>
                        <div className={LoadingShowCss.line}></div>
                    </div>
                </div>
            </div>:''}   
        </>
    )
}

export default LoadingShow