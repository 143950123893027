import React from "react";
import styles from "./deliveryboy.module.css";
import { selectUserInfo } from "../../../ReduxFiles/Slices/UserSlice";
import { UpdateOrderData } from "../../../api/SalesApi";
import { SaveSummeryData } from "../../../api/OrderInformationApi";
import { useSelector } from "react-redux";

export default function EditCompForCharge({ popUp, setPopUp }) {
  const userIfno = useSelector(selectUserInfo);
  function onCancelClick() {
    setPopUp();
  }

  async function onSubmitClick(e) {
    e.preventDefault();
    const deliveryCost = e.target.deliveryCost.value;
    const codSSLCharge = e.target.codSSLCharge.value;
    let finalUpdateData = {
      codSSLCharge,
      deliveryCost,
    };
    await UpdateOrderData(popUp.id, finalUpdateData);
    let nestedData = {
      activity: "Delivery Cost OR COD Changed",
      ProssesedBy: userIfno.id,
      sale_id: popUp.id,
      created_date: new Date().getTime(),
    };
    await SaveSummeryData(nestedData);
    // console.log(popUp);
    setPopUp();
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.holder}>
        <h2>Edit COD & SSL Charge</h2>
        <div className={styles.inner_holder}>
          <h3> Your Invoice No : # {popUp.id}</h3>
          <form className={styles.inputSection} onSubmit={onSubmitClick}>
            <div className={styles.common}>
              <label>Delivery Cost </label>
              <input
                name="deliveryCost"
                type={"number"}
                defaultValue={popUp.deliveryCost}
              />
            </div>
            <div className={styles.common}>
              <label>COD/SSL Charge</label>
              <input
                name="codSSLCharge"
                type={"number"}
                defaultValue={popUp.codSSLCharge}
              />
            </div>

            <div className={styles.buttonsection}>
              <button type="button" onClick={onCancelClick}>
                Cancel
              </button>
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
