

export let DistrictData = [
  {
    division: "Dhaka",
    district: "Dhaka",
  },
  {
    division: "Dhaka",
    district: "Faridpur",
  },
  {
    division: "Dhaka",
    district: "Gazipur",
  },
  {
    division: "Dhaka",
    district: "Gopalganj",
  },
  {
    division: "Dhaka",
    district: "Kishoreganj",
  },
  {
    division: "Dhaka",
    district: "Madaripur",
  },
  {
    division: "Dhaka",
    district: "Manikganj",
  },
  {
    division: "Dhaka",
    district: "Munshiganj",
  },

  {
    division: "Dhaka",
    district: "Naryanganj",
  },

  {
    division: "Dhaka",
    district: "Narsingdi",
  },
  {
    division: "Dhaka",
    district: "Rajbari",
  },
  {
    division: "Dhaka",
    district: "Shariatpur",
  },



  //Chittagongdivision


  {
    division: "Chittagong",
    district: "Bandarban",
  },


  {
    division: "Chittagong",
    district: "Brahmanbaria",
  },

  {
    division: "Chittagong",
    district: "Chandpur",
  },
  {
    division: "Chittagong",
    district: "Comilla",
  },
  {
    division: "Chittagong",
    district: "Cox'sBazar",
  },

  {
    division: "Chittagong",
    district: "Feni",
  },
  {
    division: "Chittagong",
    district: "Khagrachhari",
  },
  {
    division: "Chittagong",
    district: "Lakshmipur",
  },
  {
    division: "Chittagong",
    district: "Noakhali",
  },
  {
    division: "Chittagong",
    district: "Rangamati",
  },
  {
    division: "Chittagong",
    district: "Chittagong",
  },

  //Khulnadivision


  {
    division: "Khulna",
    district: "Bagerhat",
  },

  {
    division: "Khulna",
    district: "Chuadanga",
  },
  {
    division: "Khulna",
    district: "Jessore",
  },

  {
    division: "Khulna",
    district: "Jhenaidah",
  },
  {
    division: "Khulna",
    district: "Khulna",
  },

  {
    division: "Khulna",
    district: "Kushtia",
  },

  {
    division: "Khulna",
    district: "Magura",
  },
  {
    division: "Khulna",
    district: "Meherpur",
  },
  {
    division: "Khulna",
    district: "Narail",
  },
  {
    division: "Khulna",
    district: "Satkhira",
  },

  //Mymensingh

  {
    division: "Mymensingh",
    district: "Jamalpur",
  },
  {
    division: "Mymensingh",
    district: "Mymensingh",
  },
  {
    division: "Mymensingh",
    district: "Netrakona",
  },
  {
    division: "Mymensingh",
    district: "Sherpur",
  },
  {
    division: "Mymensingh",
    district: "Tangail",
  },


  //Sylhet

  {
    division: "Sylhet",
    district: "Habiganj",
  },

  {
    division: "Sylhet",
    district: "Moulvibazar",
  },

  {
    division: "Sylhet",
    district: "Sunamganj",
  },
  {
    division: "Sylhet",
    district: "Sylhet",
  },

  //Rangpur


  {
    division: "Rangpur",
    district: "Dinajpur",
  },

  {
    division: "Rangpur",
    district: "Gaibandha",
  },
  {
    division: "Rangpur",
    district: "Kurigram",
  },

  {
    division: "Rangpur",
    district: "Lalmanirhat",
  },
  {
    division: "Rangpur",
    district: "Nilphamari",
  },
  {
    division: "Rangpur",
    district: "Panchagarh",
  },
  {
    division: "Rangpur",
    district: "Rangpur",
  },
  {
    division: "Rangpur",
    district: "Thakurgaon",
  },

  //Rajshahi


  {
    division: "Rajshahi",
    district: "Bogra",
  },
  {
    division: "Rajshahi",
    district: "Joypurhat",
  },

  {
    division: "Rajshahi",
    district: "Naogaon",
  },


  {
    division: "Rajshahi",
    district: "Natore",
  },
  {
    division: "Rajshahi",
    district: "Nawabganj",
  },
  {
    division: "Rajshahi",
    district: "Pabna",
  },

  {
    division: "Rajshahi",
    district: "Rajshahi",
  },
  {
    division: "Rajshahi",
    district: "Sirajganj",
  },
  //Barisal

  {
    division: "Barisal",
    district: "Barguna",
  },

  {
    division: "Barisal",
    district: "Barisal",
  },
  {
    division: "Barisal",
    district: "Bhola",
  },
  {
    division: "Barisal",
    district: "Jhalokati",
  },
  {
    division: "Barisal",
    district: "Patuakhali",
  },
  {
    division: "Barisal",
    district: "Pirojpur",
  }
]

//Dhaka,DhakaArea
export let AreaData = [

  { distrctName: "Dhaka", AreaName: "Dhamrai Upazila" },
  { distrctName: "Dhaka", AreaName: "Dohar Upazila" },
  { distrctName: "Dhaka", AreaName: "Keraniganj Upazila" },
  { distrctName: "Dhaka", AreaName: "Nawabganj Upazila" },
  { distrctName: "Dhaka", AreaName: "Savar Upazila" },
  { distrctName: "Dhaka", AreaName: "Adabor" },
  { distrctName: "Dhaka", AreaName: "Aftabnagar" },
  { distrctName: "Dhaka", AreaName: "Agargaon" },
  { distrctName: "Dhaka", AreaName: "Azimpur" },
  { distrctName: "Dhaka", AreaName: "Babu Bazar" },
  { distrctName: "Dhaka", AreaName: "Badda" },
  { distrctName: "Dhaka", AreaName: "Banani" },
  { distrctName: "Dhaka", AreaName: "Banasree" },
  { distrctName: "Dhaka", AreaName: "Bangshal" },
  { distrctName: "Dhaka", AreaName: "Baridhara" },
  { distrctName: "Dhaka", AreaName: "Baridhara J Block" },
  { distrctName: "Dhaka", AreaName: "Baridhara RA" },
  { distrctName: "Dhaka", AreaName: "Bawnia" },
  { distrctName: "Dhaka", AreaName: "Cantonment" },
  { distrctName: "Dhaka", AreaName: "Dakshinkhan" },
  { distrctName: "Dhaka", AreaName: "Dayaganj" },
  { distrctName: "Dhaka", AreaName: "Dhaka University" },
  { distrctName: "Dhaka", AreaName: "Dhalpur" },
  { distrctName: "Dhaka", AreaName: "Dhanmondi" },
  { distrctName: "Dhaka", AreaName: "Dholaipur" },
  { distrctName: "Dhaka", AreaName: "DOHS Banani" },
  { distrctName: "Dhaka", AreaName: "DOHS Baridhara" },
  { distrctName: "Dhaka", AreaName: "DOHS Mirpur" },
  { distrctName: "Dhaka", AreaName: "DOHS Mohakhali" },
  { distrctName: "Dhaka", AreaName: "Eskaton" },
  { distrctName: "Dhaka", AreaName: "Faridabad" },
  { distrctName: "Dhaka", AreaName: "Farmgate" },
  { distrctName: "Dhaka", AreaName: "Gabtoli" },
  { distrctName: "Dhaka", AreaName: "Gandaria" },
  { distrctName: "Dhaka", AreaName: "Gulshan" },
  { distrctName: "Dhaka", AreaName: "Indira Road" },
  { distrctName: "Dhaka", AreaName: "Jatrabari" },
  { distrctName: "Dhaka", AreaName: "Jurain" },
  { distrctName: "Dhaka", AreaName: "Kakrail" },
  { distrctName: "Dhaka", AreaName: "Kalabagan" },
  { distrctName: "Dhaka", AreaName: "Kallyanpur" },
  { distrctName: "Dhaka", AreaName: "Kalshi" },
  { distrctName: "Dhaka", AreaName: "Kamrangirchar" },
  { distrctName: "Dhaka", AreaName: "Kathalbagan" },
  { distrctName: "Dhaka", AreaName: "Khilgaon" },
  { distrctName: "Dhaka", AreaName: "Khilkhet" },
  { distrctName: "Dhaka", AreaName: "Kotwali" },
  { distrctName: "Dhaka", AreaName: "Lalbagh" },
  { distrctName: "Dhaka", AreaName: "Lalmatia" },
  { distrctName: "Dhaka", AreaName: "Malibagh" },
  { distrctName: "Dhaka", AreaName: "Maniknagar" },
  { distrctName: "Dhaka", AreaName: "Matikata" },
  { distrctName: "Dhaka", AreaName: "Matuail" },
  { distrctName: "Dhaka", AreaName: "Merul Badda" },
  { distrctName: "Dhaka", AreaName: "Mirpur" },
  { distrctName: "Dhaka", AreaName: "Moghbazar" },
  { distrctName: "Dhaka", AreaName: "Mohakhali" },
  { distrctName: "Dhaka", AreaName: "Mohammadpur" },
  { distrctName: "Dhaka", AreaName: "Motijheel" },
  { distrctName: "Dhaka", AreaName: "Mridha Bari" },
  { distrctName: "Dhaka", AreaName: "Niketan" },
  { distrctName: "Dhaka", AreaName: "Nikunja" },
  { distrctName: "Dhaka", AreaName: "Paikpara" },
  { distrctName: "Dhaka", AreaName: "Pallabi" },
  { distrctName: "Dhaka", AreaName: "Paltan" },
  { distrctName: "Dhaka", AreaName: "Panthapath" },
  { distrctName: "Dhaka", AreaName: "Pink City" },
  { distrctName: "Dhaka", AreaName: "Ramna" },
  { distrctName: "Dhaka", AreaName: "Rampura" },
  { distrctName: "Dhaka", AreaName: "Rayerbagh" },
  { distrctName: "Dhaka", AreaName: "Sadarghat" },
  { distrctName: "Dhaka", AreaName: "Shahbagh" },
  { distrctName: "Dhaka", AreaName: "Shaymoli" },
  { distrctName: "Dhaka", AreaName: "Shonir Akhra" },
  { distrctName: "Dhaka", AreaName: "Sutrapur" },
  { distrctName: "Dhaka", AreaName: "Tejgaon" },
  { distrctName: "Dhaka", AreaName: "Tongi" },
  { distrctName: "Dhaka", AreaName: "Uttar Khan" },
  { distrctName: "Dhaka", AreaName: "Uttara" },
  { distrctName: "Dhaka", AreaName: "Vatara" },
  { distrctName: "Dhaka", AreaName: "Wari" },
  { distrctName: "Dhaka", AreaName: "Kafrul" },
  { distrctName: "Dhaka", AreaName: "Bashundhara RA" },
  { distrctName: "Dhaka", AreaName: "Hatirpool" },
  { distrctName: "Dhaka", AreaName: "Basabo" },
  { distrctName: "Dhaka", AreaName: "Mugda" },
  { distrctName: "Dhaka", AreaName: "Gulistan" },
  { distrctName: "Dhaka", AreaName: "Shahjahanpur" },
  { distrctName: "Dhaka", AreaName: "Darussalam" },
  { distrctName: "Dhaka", AreaName: "Elephant Road" },
  { distrctName: "Dhaka", AreaName: "Karwan Bazar" },
  { distrctName: "Dhaka", AreaName: "Chawkbazar" },
  { distrctName: "Dhaka", AreaName: "Demra" },
  { distrctName: "Dhaka", AreaName: "Mouchak" },
  { distrctName: "Dhaka", AreaName: "Sher-e-Bangla Nagar" },
  { distrctName: "Dhaka", AreaName: "Signboard" },
  { distrctName: "Dhaka", AreaName: "Airport" },
  { distrctName: "Dhaka", AreaName: "Armanitola" },
  { distrctName: "Dhaka", AreaName: "Purbachal" },
  { distrctName: "Dhaka", AreaName: "Uttarkhan" },
  { distrctName: "Dhaka", AreaName: "Khilkhet Thana" },
  { distrctName: "Dhaka", AreaName: "Shahjadpur" },
  { distrctName: "Dhaka", AreaName: "New Market" },
  { distrctName: "Dhaka", AreaName: "Darus Salam" },


  //Faridpur,FaridpurArea
  { distrctName: "Faridpur", AreaName: "Faridpur Sadar Upazila" },
  { distrctName: "Faridpur", AreaName: "Boalmari Upazila" },
  { distrctName: "Faridpur", AreaName: "Alfadanga Upazila" },
  { distrctName: "Faridpur", AreaName: "Madhukhali Upazila" },
  { distrctName: "Faridpur", AreaName: "Bhanga Upazila" },
  { distrctName: "Faridpur", AreaName: "Nagarkanda Upazila" },
  { distrctName: "Faridpur", AreaName: "Charbhadrasan Upazila" },
  { distrctName: "Faridpur", AreaName: "Sadarpur Upazila" },
  { distrctName: "Faridpur", AreaName: "Shaltha Upazila" },
  //Gazipur,GazipurArea

  {
    distrctName: "Gazipur",
    AreaName: "GazipurSadar-Joydebpur",
  },

  {
    distrctName: "Gazipur",
    AreaName: "Kaliakior",
  },
  {
    distrctName: "Gazipur",
    AreaName: "Kapasia",
  },
  {
    distrctName: "Gazipur",
    AreaName: "Sripur",
  },
  {
    distrctName: "Gazipur",
    AreaName: "Kaliganj",
  },
  {
    distrctName: "Gazipur",
    AreaName: "Tongi",
  },


  //Gopalganj,GopalganjArea

  {
    distrctName: "Gopalganj",
    AreaName: "GopalganjSadar",
  },

  {
    distrctName: "Gopalganj",
    AreaName: "Kashiani",
  },
  {
    distrctName: "Gopalganj",
    AreaName: "Kotalipara",
  },
  {
    distrctName: "Gopalganj",
    AreaName: "Muksudpur",
  },
  {
    distrctName: "Gopalganj",
    AreaName: "Tungipara",
  },
  //Kishoreganj,KishoreganjArea

  {
    distrctName: "Kishoreganj",
    AreaName: "Astagram",
  },

  {
    distrctName: "Kishoreganj",
    AreaName: "Bajitpur",
  },
  {
    distrctName: "Kishoreganj",
    AreaName: "Bhairab",
  },
  {
    distrctName: "Kishoreganj",
    AreaName: "Hossainpur",
  },
  {
    distrctName: "Kishoreganj",
    AreaName: "Itna",
  },

  {
    distrctName: "Kishoreganj",
    AreaName: "Karimganj",
  },
  {
    distrctName: "Kishoreganj",
    AreaName: "Katiadi",
  },
  {
    distrctName: "Kishoreganj",
    AreaName: "Kishoreganj Sadar",
  },
  {
    distrctName: "Kishoreganj",
    AreaName: "Kuliarchar",
  },
  {
    distrctName: "Kishoreganj",
    AreaName: "Mithamain",
  },

  {
    distrctName: "Kishoreganj",
    AreaName: "Nikli",
  },

  {
    distrctName: "Kishoreganj",
    AreaName: "Pakundia",
  },

  {
    distrctName: "Kishoreganj",
    AreaName: "Tarail",
  },
  {
    distrctName: "Kishoreganj",
    AreaName: "Mithamoin",
  },

  //Madaripur,MadaripurArea

  {
    distrctName: "Madaripur",
    AreaName: "MadaripurSadar",
  },

  {
    distrctName: "Madaripur",
    AreaName: "Kalkini",
  },
  {
    distrctName: "Madaripur",
    AreaName: "Rajoir",
  },
  {
    distrctName: "Madaripur",
    AreaName: "Shibchar",
  },
  //Manikgonj,ManikgonjArea
  { distrctName: "Manikganj", AreaName: "Manikganj Sadar Upazila" },
  { distrctName: "Manikganj", AreaName: "Singair Upazila" },
  { distrctName: "Manikganj", AreaName: "Shibalaya Upazila" },
  { distrctName: "Manikganj", AreaName: "Saturia Upazila" },
  { distrctName: "Manikganj", AreaName: "Harirampur Upazila" },
  { distrctName: "Manikganj", AreaName: "Ghior Upazila" },
  { distrctName: "Manikganj", AreaName: "Daulatpur Upazila" },


  //Munshiganj,MunshiganjArea

  {
    distrctName: "Munshiganj",
    AreaName: "Lohajang",
  },

  {
    distrctName: "Munshiganj",
    AreaName: "Sreenagar",
  },
  {
    distrctName: "Munshiganj",
    AreaName: "MunshiganjSadar",
  },
  {
    distrctName: "Munshiganj",
    AreaName: "Sirajdikhan",
  },


  {
    distrctName: "Munshiganj",
    AreaName: "Tongibari",
  },
  {
    distrctName: "Munshiganj",
    AreaName: "Gazaria",
  },



  //Narsingdi,NarsingdiArea

  {
    distrctName: "Narsingdi",
    AreaName: "Belabo",
  },

  {
    distrctName: "Narsingdi",
    AreaName: "Monohardi",
  },
  {
    distrctName: "Narsingdi",
    AreaName: "NarsingdiSadar",
  },
  {
    distrctName: "Narsingdi",
    AreaName: "Palash",
  },


  {
    distrctName: "Narsingdi",
    AreaName: "Raipura",
  },
  {
    distrctName: "Narsingdi",
    AreaName: "Shibpur",
  },


  //Naryanganj,NaryanganjArea

  {
    distrctName: "Naryanganj",
    AreaName: "Araihazar",
  },

  {
    distrctName: "Naryanganj",
    AreaName: "Sonargaon",
  },
  {
    distrctName: "Naryanganj",
    AreaName: "Bandar",
  },
  {
    distrctName: "Naryanganj",
    AreaName: "NaryanganjSadar",
  },


  {
    distrctName: "Naryanganj",
    AreaName: "Rupganj",
  },
  {
    distrctName: "Naryanganj",
    AreaName: "Siddirgonj",
  },
  {
    distrctName: "Naryanganj",
    AreaName: "Fatullah",
  },

  //Rajbari,RajbarArea

  {
    distrctName: "Rajbari",
    AreaName: "Baliakandi",
  },

  {
    distrctName: "Rajbari",
    AreaName: "Goalandaghat",
  },
  {
    distrctName: "Rajbari",
    AreaName: "Pangsha",
  },
  {
    distrctName: "Rajbari",
    AreaName: "Kalukhali",
  },


  {
    distrctName: "Rajbari",
    AreaName: "RajbariSadar",
  },

  //Shariatpur,ShariatpurArea

  {
    distrctName: "Shariatpur",
    AreaName: "ShariatpurSadar-Palong",
  },

  {
    distrctName: "Shariatpur",
    AreaName: "Damudya",
  },
  {
    distrctName: "Shariatpur",
    AreaName: "Naria",
  },
  {
    distrctName: "Shariatpur",
    AreaName: "Jajira",
  },


  {
    distrctName: "Shariatpur",
    AreaName: "Bhedarganj",
  },


  {
    distrctName: "Shariatpur",
    AreaName: "Gosairhat",
  },




  //Bandarban,BandarbanArea



  {
    distrctName: "Bandarban",
    AreaName: "BandarbanSadar",
  },

  {
    distrctName: "Bandarban",
    AreaName: "Thanchi",
  },


  {
    distrctName: "Bandarban",
    AreaName: "Lama",
  },


  {
    distrctName: "Bandarban",
    AreaName: "Naikhongchhari",
  },

  {
    distrctName: "Bandarban",
    AreaName: "Alikadam",
  },
  {
    distrctName: "Bandarban",
    AreaName: "Rowangchhari",
  },


  {
    distrctName: "Bandarban",
    AreaName: "Ruma",
  },

  //Brahmanbaria,BrahmanbariaArea



  {
    distrctName: "Brahmanbaria",
    AreaName: "BrahmanbariaSadar",
  },

  {
    distrctName: "Brahmanbaria",
    AreaName: "Ashuganj",
  },


  {
    distrctName: "Brahmanbaria",
    AreaName: "Nasirnagar",
  },


  {
    distrctName: "Brahmanbaria",
    AreaName: "Nabinagar",
  },

  {
    distrctName: "Brahmanbaria",
    AreaName: "Sarail",
  },
  {
    distrctName: "Brahmanbaria",
    AreaName: "ShahbazpurTown",
  },


  {
    distrctName: "Brahmanbaria",
    AreaName: "Kasba",
  },


  {
    distrctName: "Brahmanbaria",
    AreaName: "Akhaura",
  },
  {
    distrctName: "Brahmanbaria",
    AreaName: "Bancharampur",
  },
  {
    distrctName: "Brahmanbaria",
    AreaName: "Bijoynagar",
  },


  //Chandpur,ChandpurArea



  {
    distrctName: "Chandpur",
    AreaName: "ChandpurSadar",
  },

  {
    distrctName: "Chandpur",
    AreaName: "Faridganj",
  },


  {
    distrctName: "Chandpur",
    AreaName: "Haimchar",
  },


  {
    distrctName: "Chandpur",
    AreaName: "Haziganj",
  },

  {
    distrctName: "Chandpur",
    AreaName: "Kachua",
  },
  {
    distrctName: "Chandpur",
    AreaName: "MatlabUttar",
  },


  {
    distrctName: "Chandpur",
    AreaName: "MatlabDakkhin",
  },


  {
    distrctName: "Chandpur",
    AreaName: "Shahrasti",
  },

  //Chittagong,ChittagongArea



  {
    distrctName: "Chittagong",
    AreaName: "Anwara",
  },

  {
    distrctName: "Chittagong",
    AreaName: "Banshkhali",
  },


  {
    distrctName: "Chittagong",
    AreaName: "Boalkhali",
  },


  {
    distrctName: "Chittagong",
    AreaName: "Chandanaish",
  },

  {
    distrctName: "Chittagong",
    AreaName: "Fatikchhari",
  },
  {
    distrctName: "Chittagong",
    AreaName: "Hathazari",
  },


  {
    distrctName: "Chittagong",
    AreaName: "Lohagara",
  },


  {
    distrctName: "Chittagong",
    AreaName: "Mirsharai",
  },
  {
    distrctName: "Chittagong",
    AreaName: "Patiya",
  },

  {
    distrctName: "Chittagong",
    AreaName: "Rangunia",
  },


  {
    distrctName: "Chittagong",
    AreaName: "Raozan",
  },


  {
    distrctName: "Chittagong",
    AreaName: "Sandwip",
  },

  {
    distrctName: "Chittagong",
    AreaName: "Satkania",
  },
  {
    distrctName: "Chittagong",
    AreaName: "Sitakunda",
  },


  {
    distrctName: "Chittagong",
    AreaName: "Akborsha",
  },


  {
    distrctName: "Chittagong",
    AreaName: "Baijidbostami",
  },

  {
    distrctName: "Chittagong",
    AreaName: "Bakolia",
  },
  {
    distrctName: "Chittagong",
    AreaName: "Bandar",
  },


  {
    distrctName: "Chittagong",
    AreaName: "Chandgaon",
  },


  {
    distrctName: "Chittagong",
    AreaName: "Chokbazar",
  },
  {
    distrctName: "Chittagong",
    AreaName: "Doublemooring",
  },
  {
    distrctName: "Chittagong",
    AreaName: "Bandar",
  },


  {
    distrctName: "Chittagong",
    AreaName: "EPZ",
  },


  {
    distrctName: "Chittagong",
    AreaName: "HaliShohor",
  },
  {
    distrctName: "Chittagong",
    AreaName: "Kornafuli",
  },
  {
    distrctName: "Chittagong",
    AreaName: "Kotwali",
  },


  {
    distrctName: "Chittagong",
    AreaName: "Kulshi",
  },


  {
    distrctName: "Chittagong",
    AreaName: "Pahartali",
  },
  {
    distrctName: "Chittagong",
    AreaName: "Panchlaish",
  },


  {
    distrctName: "Chittagong",
    AreaName: "Potenga",
  },


  {
    distrctName: "Chittagong",
    AreaName: "Shodhorgat",
  },


  //Comilla,ComillaArea
  {
    distrctName: "Comilla",
    AreaName: "Barura",
  },
  {
    distrctName: "Comilla",
    AreaName: "Brahmanpara",
  },


  {
    distrctName: "Comilla",
    AreaName: "Burichong",
  },


  {
    distrctName: "Comilla",
    AreaName: "Chandina",
  },
  {
    distrctName: "Comilla",
    AreaName: "Chauddagram",
  },
  {
    distrctName: "Comilla",
    AreaName: "Daudkandi",
  },


  {
    distrctName: "Comilla",
    AreaName: "Debidwar",
  },


  {
    distrctName: "Comilla",
    AreaName: "Homna",
  },
  {
    distrctName: "Comilla",
    AreaName: "ComillaSadar",
  },
  {
    distrctName: "Comilla",
    AreaName: "Laksam",
  },


  {
    distrctName: "Comilla",
    AreaName: "Monohorgonj",
  },


  {
    distrctName: "Comilla",
    AreaName: "Meghna",
  },
  {
    distrctName: "Comilla",
    AreaName: "Muradnagar",
  },
  {
    distrctName: "Comilla",
    AreaName: "Nangalkot",
  },


  {
    distrctName: "Comilla",
    AreaName: "ComillaSadarSouth",
  },


  {
    distrctName: "Comilla",
    AreaName: "Titas",
  },



  {
    distrctName: "Comilla",
    AreaName: "Muradnagar",
  },
  {
    distrctName: "Comilla",
    AreaName: "Nangalkot",
  },

  //Cox'sBazar,Cox'sBazarArea
  {
    distrctName: "Cox'sBazar",
    AreaName: "Chakaria",
  },


  {
    distrctName: "Cox'sBazar",
    AreaName: "Cox'sBazarSadar",
  },

  {
    distrctName: "Cox'sBazar",
    AreaName: "Kutubdia",
  },

  {
    distrctName: "Cox'sBazar",
    AreaName: "Maheshkhali",
  },


  {
    distrctName: "Cox'sBazar",
    AreaName: "Ramu",
  },

  {
    distrctName: "Cox'sBazar",
    AreaName: "Teknaf",
  },
  {
    distrctName: "Cox'sBazar",
    AreaName: "Ukhia",
  },
  {
    distrctName: "Cox'sBazar",
    AreaName: "Pekua",
  },

  //Feni,FeniArea

  {
    distrctName: "Feni",
    AreaName: "FeniSadar",
  },


  {
    distrctName: "Feni",
    AreaName: "Chagalnaiya",
  },
  {
    distrctName: "Feni",
    AreaName: "Daganbhyan",
  },
  {
    distrctName: "Feni",
    AreaName: "Parshuram",
  },
  {
    distrctName: "Feni",
    AreaName: "Fhulgazi",
  },
  {
    distrctName: "Feni",
    AreaName: "Sonagazi",
  },

  //Khagrachhari,KhagrachhariArea

  {
    distrctName: "Khagrachhari",
    AreaName: "Dighinala",
  },


  {
    distrctName: "Khagrachhari",
    AreaName: "Khagrachhari",
  },
  {
    distrctName: "Khagrachhari",
    AreaName: "Lakshmichhari",
  },
  {
    distrctName: "Khagrachhari",
    AreaName: "Mahalchhari",
  },
  {
    distrctName: "Khagrachhari",
    AreaName: "Manikchhari",
  },

  {
    distrctName: "Khagrachhari",
    AreaName: "Matiranga",
  },
  {
    distrctName: "Khagrachhari",
    AreaName: "Panchhari",
  },
  {
    distrctName: "Khagrachhari",
    AreaName: "Ramgarh",
  },

  //Lakshmipur,LakshmipurArea


  {
    distrctName: "Lakshmipur",
    AreaName: "LakshmipurSadar",
  },
  {
    distrctName: "Lakshmipur",
    AreaName: "Raipur",
  },

  {
    distrctName: "Lakshmipur",
    AreaName: "Ramganj",
  },
  {
    distrctName: "Lakshmipur",
    AreaName: "Ramgati",
  },
  {
    distrctName: "Lakshmipur",
    AreaName: "KomolNagar",
  },

  //Noakhali,NoakhaliArea


  {
    distrctName: "Noakhali",
    AreaName: "NoakhaliSadar",
  },
  {
    distrctName: "Noakhali",
    AreaName: "Begumganj",
  },

  {
    distrctName: "Noakhali",
    AreaName: "Chatkhil",
  },
  {
    distrctName: "Noakhali",
    AreaName: "Companyganj",
  },
  {
    distrctName: "Noakhali",
    AreaName: "Shenbag",
  },

  {
    distrctName: "Noakhali",
    AreaName: "Hatia",
  },

  {
    distrctName: "Noakhali",
    AreaName: "Kobirhat",
  },
  {
    distrctName: "Noakhali",
    AreaName: "Sonaimuri",
  },
  {
    distrctName: "Noakhali",
    AreaName: "SubornoChar",
  },


  //Rangamati,RangamatiArea


  {
    distrctName: "Rangamati",
    AreaName: "RangamatiSadar",
  },
  {
    distrctName: "Rangamati",
    AreaName: "Belaichhari",
  },

  {
    distrctName: "Rangamati",
    AreaName: "Bagaichhari",
  },
  {
    distrctName: "Rangamati",
    AreaName: "Barkal",
  },
  {
    distrctName: "Rangamati",
    AreaName: "Juraichhari",
  },

  {
    distrctName: "Rangamati",
    AreaName: "Rajasthali",
  },

  {
    distrctName: "Rangamati",
    AreaName: "Kaptai",
  },
  {
    distrctName: "Rangamati",
    AreaName: "Langadu",
  },

  {
    distrctName: "Rangamati",
    AreaName: "Nannerchar",
  },
  {
    distrctName: "Rangamati",
    AreaName: "Kaukhali",
  },

  //Bagerhat,BagerhatArea

  {
    distrctName: "Bagerhat",
    AreaName: "BagerhatSadar",
  },
  {
    distrctName: "Bagerhat",
    AreaName: "Chitalmari",
  },

  {
    distrctName: "Bagerhat",
    AreaName: "Fakirhat",
  },
  {
    distrctName: "Bagerhat",
    AreaName: "Kachua",
  },

  {
    distrctName: "Bagerhat",
    AreaName: "Mollahat",
  },
  {
    distrctName: "Bagerhat",
    AreaName: "Mongla",
  },

  {
    distrctName: "Bagerhat",
    AreaName: "Morrelganj",
  },
  {
    distrctName: "Bagerhat",
    AreaName: "Rampal",
  },
  {
    distrctName: "Bagerhat",
    AreaName: "Sarankhola",
  },

  //Chuadanga,ChuadangaArea

  {
    distrctName: "Chuadanga",
    AreaName: "Damurhuda",
  },

  {
    distrctName: "Chuadanga",
    AreaName: "Chuadanga-Sadar",
  },
  {
    distrctName: "Chuadanga",
    AreaName: "Jibannagar",
  },
  {
    distrctName: "Chuadanga",
    AreaName: "Alamdanga",
  },

  //Jessore,JessoreArea

  {
    distrctName: "Jessore",
    AreaName: "Abhaynagar",
  },

  {
    distrctName: "Jessore",
    AreaName: "Keshabpur",
  },
  {
    distrctName: "Jessore",
    AreaName: "Bagherpara",
  },
  {
    distrctName: "Jessore",
    AreaName: "JessoreSadar",
  },
  {
    distrctName: "Jessore",
    AreaName: "Chaugachha",
  },

  {
    distrctName: "Jessore",
    AreaName: "Manirampur",
  },
  {
    distrctName: "Jessore",
    AreaName: "Jhikargachha",
  },
  {
    distrctName: "Jessore",
    AreaName: "Sharsha",
  },



  //Jhenaidah,JhenaidahArea

  {
    distrctName: "Jhenaidah",
    AreaName: "JhenaidahSadar",
  },

  {
    distrctName: "Jhenaidah",
    AreaName: "Maheshpur",
  },
  {
    distrctName: "Jhenaidah",
    AreaName: "Kaliganj",
  },
  {
    distrctName: "Jhenaidah",
    AreaName: "Kotchandpur",
  },
  {
    distrctName: "Jhenaidah",
    AreaName: "Shailkupa",
  },

  {
    distrctName: "Jhenaidah",
    AreaName: "Harinakunda",
  },

  //Khulna,KhulnaArea

  {
    distrctName: "Khulna",
    AreaName: "Terokhada",
  },

  {
    distrctName: "Khulna",
    AreaName: "Maheshpur",
  },
  {
    distrctName: "Khulna",
    AreaName: "Batiaghata",
  },
  {
    distrctName: "Khulna",
    AreaName: "Dacope",
  },
  {
    distrctName: "Khulna",
    AreaName: "Dumuria",
  },

  {
    distrctName: "Khulna",
    AreaName: "Dighalia",
  },

  {
    distrctName: "Khulna",
    AreaName: "Koyra",
  },
  {
    distrctName: "Khulna",
    AreaName: "Paikgachha",
  },

  {
    distrctName: "Khulna",
    AreaName: "Phultala",
  },
  {
    distrctName: "Khulna",
    AreaName: "Rupsa",
  },

  {
    distrctName: "Khulna",
    AreaName: "Aranghata",
  },
  {
    distrctName: "Khulna",
    AreaName: "Daulatpur",
  },

  {
    distrctName: "Khulna",
    AreaName: "Harintana",
  },

  {
    distrctName: "Khulna",
    AreaName: "Horintana",
  },

  {
    distrctName: "Khulna",
    AreaName: "Khalishpur",
  },
  {
    distrctName: "Khulna",
    AreaName: "KhanjahanAli",
  },

  {
    distrctName: "Khulna",
    AreaName: "KhulnaSadar",
  },

  {
    distrctName: "Khulna",
    AreaName: "Labanchora",
  },

  {
    distrctName: "Khulna",
    AreaName: "Sonadanga",
  },

  //Kushtia,KushtiaArea

  {
    distrctName: "Kushtia",
    AreaName: "KushtiaSadar",
  },

  {
    distrctName: "Kushtia",
    AreaName: "Kumarkhali",
  },

  {
    distrctName: "Kushtia",
    AreaName: "Daulatpur",
  },

  {
    distrctName: "Kushtia",
    AreaName: "Mirpur",
  },

  {
    distrctName: "Kushtia",
    AreaName: "Bheramara",
  },

  {
    distrctName: "Kushtia",
    AreaName: "Khoksa",
  },

  //Magura,MaguraArea

  {
    distrctName: "Magura",
    AreaName: "MaguraSadar",
  },

  {
    distrctName: "Magura",
    AreaName: "Mohammadpur",
  },

  {
    distrctName: "Magura",
    AreaName: "Shalikha",
  },

  {
    distrctName: "Magura",
    AreaName: "Sreepur",
  },

  //Meherpur,MeherpurArea

  {
    distrctName: "Meherpur",
    AreaName: "angni",
  },

  {
    distrctName: "Meherpur",
    AreaName: "MujibNagar",
  },

  {
    distrctName: "Meherpur",
    AreaName: "Meherpur-Sadar",
  },

  //Narail,NarailArea
  {
    distrctName: "Narail",
    AreaName: "NarailSadar",
  },

  {
    distrctName: "Narail",
    AreaName: "Lohagara",
  },
  {
    distrctName: "Narail",
    AreaName: "Kalia",
  },

  //Satkhira,SatkhiraArea
  {
    distrctName: "Satkhira",
    AreaName: "SatkhiraSadar",
  },

  {
    distrctName: "Satkhira",
    AreaName: "Assasuni",
  },
  {
    distrctName: "Satkhira",
    AreaName: "Debhata",
  },
  {
    distrctName: "Satkhira",
    AreaName: "Tala",
  },
  {
    distrctName: "Satkhira",
    AreaName: "Kalaroa",
  },
  {
    distrctName: "Satkhira",
    AreaName: "Kaliganj",
  },
  {
    distrctName: "Satkhira",
    AreaName: "Shyamnagar",
  },

  //Jamalpur,JamalpurArea

  {
    distrctName: "Jamalpur",
    AreaName: "Dewanganj",
  },
  {
    distrctName: "Jamalpur",
    AreaName: "Baksiganj",
  },
  {
    distrctName: "Jamalpur",
    AreaName: "Islampur",
  },
  {
    distrctName: "Jamalpur",
    AreaName: "JamalpurSadar",
  },

  {
    distrctName: "Jamalpur",
    AreaName: "Madarganj",
  },
  {
    distrctName: "Jamalpur",
    AreaName: "Melandaha",
  },
  {
    distrctName: "Jamalpur",
    AreaName: "Sarishabari",
  },
  {
    distrctName: "Jamalpur",
    AreaName: "NarundiPoliceI.C",
  },

  //Mymensingh,MymensinghArea
  {
    distrctName: "Mymensingh",
    AreaName: "Bhaluka",
  },
  {
    distrctName: "Mymensingh",
    AreaName: "Trishal",
  },
  {
    distrctName: "Mymensingh",
    AreaName: "Haluaghat",
  },
  {
    distrctName: "Mymensingh",
    AreaName: "Muktagachha",
  },
  {
    distrctName: "Mymensingh",
    AreaName: "Dhobaura",
  },
  {
    distrctName: "Mymensingh",
    AreaName: "Fulbaria",
  },
  {
    distrctName: "Mymensingh",
    AreaName: "Gaffargaon",
  },

  {
    distrctName: "Mymensingh",
    AreaName: "Gauripur",
  },
  {
    distrctName: "Mymensingh",
    AreaName: "Ishwarganj",
  },
  {
    distrctName: "Mymensingh",
    AreaName: "MymensinghSadar",
  },

  {
    distrctName: "Mymensingh",
    AreaName: "Nandail",
  },
  {
    distrctName: "Mymensingh",
    AreaName: "Phulpur",
  },


  //Netrakona,NetrakonaArea

  {
    distrctName: "Netrakona",
    AreaName: "Kendua",
  },

  {
    distrctName: "Netrakona",
    AreaName: "Atpara",
  },

  {
    distrctName: "Netrakona",
    AreaName: "Barhatta",
  },
  {
    distrctName: "Netrakona",
    AreaName: "Durgapur",
  },

  {
    distrctName: "Netrakona",
    AreaName: "Kalmakanda",
  },

  {
    distrctName: "Netrakona",
    AreaName: "Madan",
  },


  {
    distrctName: "Netrakona",
    AreaName: "Mohanganj",
  },

  {
    distrctName: "Netrakona",
    AreaName: "NetrakonaSadar",
  },
  {
    distrctName: "Netrakona",
    AreaName: "Purbadhala",
  },

  {
    distrctName: "Netrakona",
    AreaName: "Khaliajuri",
  },

  //Sherpur,SherpurArea


  {
    distrctName: "Sherpur",
    AreaName: "Jhenaigati",
  },


  {
    distrctName: "Sherpur",
    AreaName: "Nakla",
  },

  {
    distrctName: "Sherpur",
    AreaName: "Nalitabari",
  },
  {
    distrctName: "Sherpur",
    AreaName: "SherpurSadar",
  },

  {
    distrctName: "Sherpur",
    AreaName: "Sreebardi",
  },
  //Tangail,TangailArea

  {
    distrctName: "Tangail",
    AreaName: "TangailSadar",
  },

  {
    distrctName: "Tangail",
    AreaName: "Sakhipur",
  },
  {
    distrctName: "Tangail",
    AreaName: "Basail",
  },
  {
    distrctName: "Tangail",
    AreaName: "Madhupur",
  },


  {
    distrctName: "Tangail",
    AreaName: "Ghatail",
  },


  {
    distrctName: "Tangail",
    AreaName: "Kalihati",
  },

  {
    distrctName: "Tangail",
    AreaName: "Nagarpur",
  },


  {
    distrctName: "Tangail",
    AreaName: "Mirzapur",
  },


  {
    distrctName: "Tangail",
    AreaName: "Gopalpur",
  },

  {
    distrctName: "Tangail",
    AreaName: "Delduar",
  },


  {
    distrctName: "Tangail",
    AreaName: "Bhuapur",
  },


  {
    distrctName: "Tangail",
    AreaName: "Dhanbari",
  },

  //Habiganj,HabiganjArea


  {
    distrctName: "Habiganj",
    AreaName: "Ajmiriganj",
  },


  {
    distrctName: "Habiganj",
    AreaName: "Baniachang",
  },

  {
    distrctName: "Habiganj",
    AreaName: "Bahubal",
  },
  {
    distrctName: "Habiganj",
    AreaName: "Chunarughat",
  },

  {
    distrctName: "Habiganj",
    AreaName: "HabiganjSadar",
  },

  {
    distrctName: "Habiganj",
    AreaName: "Lakhai",
  },

  {
    distrctName: "Habiganj",
    AreaName: "Madhabpur",
  },
  {
    distrctName: "Habiganj",
    AreaName: "Nabiganj",
  },

  {
    distrctName: "Habiganj",
    AreaName: "Shaistagonj",
  },

  //Moulvibazar,MoulvibazarArea


  {
    distrctName: "Moulvibazar",
    AreaName: "MoulvibazarSadar",
  },
  {
    distrctName: "Moulvibazar",
    AreaName: "Barlekha",
  },

  {
    distrctName: "Moulvibazar",
    AreaName: "Juri",
  },

  {
    distrctName: "Moulvibazar",
    AreaName: "Kamalganj",
  },

  {
    distrctName: "Moulvibazar",
    AreaName: "Kulaura",
  },
  {
    distrctName: "Moulvibazar",
    AreaName: "Rajnagar",
  },

  {
    distrctName: "Moulvibazar",
    AreaName: "Sreemangal",
  },

  //Sunamganj,SunamganjArea

  {
    distrctName: "Sunamganj",
    AreaName: "Bishwamvarpur",
  },

  {
    distrctName: "Sunamganj",
    AreaName: "Chhatak",
  },
  {
    distrctName: "Sunamganj",
    AreaName: "Derai",
  },

  {
    distrctName: "Sunamganj",
    AreaName: "Dharampasha",
  },

  {
    distrctName: "Sunamganj",
    AreaName: "Dowarabazar",
  },

  {
    distrctName: "Sunamganj",
    AreaName: "Jagannathpur",
  },

  {
    distrctName: "Sunamganj",
    AreaName: "Jamalganj",
  },
  {
    distrctName: "Sunamganj",
    AreaName: "Sulla",
  },

  {
    distrctName: "Sunamganj",
    AreaName: "SunamganjSadar",
  },
  {
    distrctName: "Sunamganj",
    AreaName: "Shanthiganj",
  },

  {
    distrctName: "Sunamganj",
    AreaName: "Tahirpur",
  },



  //Sylhet,SylhetArea

  {
    distrctName: "Sylhet",
    AreaName: "SylhetSadar",
  },

  {
    distrctName: "Sylhet",
    AreaName: "Beanibazar",
  },
  {
    distrctName: "Sylhet",
    AreaName: "Bishwanath",
  },

  {
    distrctName: "Sylhet",
    AreaName: "DakshinSurma",
  },

  {
    distrctName: "Sylhet",
    AreaName: "Balaganj",
  },

  {
    distrctName: "Sylhet",
    AreaName: "Companiganj",
  },

  {
    distrctName: "Sylhet",
    AreaName: "Golapganj",
  },
  {
    distrctName: "Sylhet",
    AreaName: "Gowainghat",
  },

  {
    distrctName: "Sylhet",
    AreaName: "Jaintiapur",
  },
  {
    distrctName: "Sylhet",
    AreaName: "Kanaighat",
  },

  {
    distrctName: "Sylhet",
    AreaName: "Zakiganj",
  },

  {
    distrctName: "Sylhet",
    AreaName: "Nobigonj",
  },
  {
    distrctName: "Sylhet",
    AreaName: "Airport",
  },

  {
    distrctName: "Sylhet",
    AreaName: "HazratShahParan",
  },

  {
    distrctName: "Sylhet",
    AreaName: "Jalalabad",
  },
  {
    distrctName: "Sylhet",
    AreaName: "Kowtali",
  },

  {
    distrctName: "Sylhet",
    AreaName: "Moglabazar",
  },

  {
    distrctName: "Sylhet",
    AreaName: "OsmaniNagar",
  },

  {
    distrctName: "Sylhet",
    AreaName: "SouthSurma",
  },





  //Dinajpur,DinajpurArea

  {
    distrctName: "Dinajpur",
    AreaName: "Birampur",
  },

  {
    distrctName: "Dinajpur",
    AreaName: "Birganj",
  },
  {
    distrctName: "Dinajpur",
    AreaName: "Biral",
  },

  {
    distrctName: "Dinajpur",
    AreaName: "Bochaganj",
  },

  {
    distrctName: "Dinajpur",
    AreaName: "Chirirbandar",
  },

  {
    distrctName: "Dinajpur",
    AreaName: "Phulbari",
  },

  {
    distrctName: "Dinajpur",
    AreaName: "Ghoraghat",
  },
  {
    distrctName: "Dinajpur",
    AreaName: "Hakimpur",
  },

  {
    distrctName: "Dinajpur",
    AreaName: "Kaharole",
  },
  {
    distrctName: "Dinajpur",
    AreaName: "Khansama",
  },

  {
    distrctName: "Dinajpur",
    AreaName: "DinajpurSadar",
  },

  {
    distrctName: "Dinajpur",
    AreaName: "Nawabganj",
  },
  {
    distrctName: "Dinajpur",
    AreaName: "Parbatipur",
  },



  //Gaibandha,GaibandhaArea


  {
    distrctName: "Gaibandha",
    AreaName: "Fulchhari",
  },
  {
    distrctName: "Gaibandha",
    AreaName: "Gaibandhasadar",
  },

  {
    distrctName: "Gaibandha",
    AreaName: "Gobindaganj",
  },
  {
    distrctName: "Gaibandha",
    AreaName: "Palashbari",
  },

  {
    distrctName: "Gaibandha",
    AreaName: "Sadullapur",
  },

  {
    distrctName: "Gaibandha",
    AreaName: "Saghata",
  },
  {
    distrctName: "Gaibandha",
    AreaName: "Sundarganj",
  },


  //Kurigram,KurigramArea


  {
    distrctName: "Kurigram",
    AreaName: "KurigramSadar",
  },
  {
    distrctName: "Kurigram",
    AreaName: "Nageshwari",
  },

  {
    distrctName: "Kurigram",
    AreaName: "Bhurungamari",
  },
  {
    distrctName: "Kurigram",
    AreaName: "Phulbari",
  },

  {
    distrctName: "Kurigram",
    AreaName: "Rajarhat",
  },

  {
    distrctName: "Kurigram",
    AreaName: "Ulipur",
  },
  {
    distrctName: "Kurigram",
    AreaName: "Chilmari",
  },

  {
    distrctName: "Kurigram",
    AreaName: "Rowmari",
  },
  {
    distrctName: "Kurigram",
    AreaName: "CharRajibpur",
  },


  //Lalmanirhat,LalmanirhatArea


  {
    distrctName: "Lalmanirhat",
    AreaName: "LalmanirhatSadar",
  },
  {
    distrctName: "Lalmanirhat",
    AreaName: "Aditmari",
  },

  {
    distrctName: "Lalmanirhat",
    AreaName: "Kaliganj",
  },
  {
    distrctName: "Lalmanirhat",
    AreaName: "Hatibandha",
  },

  {
    distrctName: "Lalmanirhat",
    AreaName: "Patgram",
  },




  //Nilphamari,NilphamariArea


  {
    distrctName: "Nilphamari",
    AreaName: "NilphamariSadar",
  },
  {
    distrctName: "Nilphamari",
    AreaName: "Saidpur",
  },

  {
    distrctName: "Nilphamari",
    AreaName: "Jaldhaka",
  },
  {
    distrctName: "Nilphamari",
    AreaName: "Kishoreganj",
  },

  {
    distrctName: "Nilphamari",
    AreaName: "Domar",
  },
  {
    distrctName: "Nilphamari",
    AreaName: "Dimla",
  },


  //Panchagarh,PanchagarhArea


  {
    distrctName: "Panchagarh",
    AreaName: "PanchagarhSadar",
  },
  {
    distrctName: "Panchagarh",
    AreaName: "Debiganj",
  },

  {
    distrctName: "Panchagarh",
    AreaName: "Boda",
  },
  {
    distrctName: "Panchagarh",
    AreaName: "Atwari",
  },

  {
    distrctName: "Panchagarh",
    AreaName: "Tetulia",
  },

  //Rangpur,RangpurArea


  {
    distrctName: "Rangpur",
    AreaName: "Badarganj",
  },
  {
    distrctName: "Rangpur",
    AreaName: "Mithapukur",
  },

  {
    distrctName: "Rangpur",
    AreaName: "Gangachara",
  },
  {
    distrctName: "Rangpur",
    AreaName: "Kaunia",
  },

  {
    distrctName: "Rangpur",
    AreaName: "RangpurSadar",
  },

  {
    distrctName: "Rangpur",
    AreaName: "Pirgachha",
  },
  {
    distrctName: "Rangpur",
    AreaName: "Pirganj",
  },

  {
    distrctName: "Rangpur",
    AreaName: "Taraganj",
  },

  //Thakurgaon,ThakurgaonArea


  {
    distrctName: "Thakurgaon",
    AreaName: "ThakurgaonSadar",
  },
  {
    distrctName: "Thakurgaon",
    AreaName: "Pirganj",
  },

  {
    distrctName: "Thakurgaon",
    AreaName: "Baliadangi",
  },
  {
    distrctName: "Thakurgaon",
    AreaName: "Haripur",
  },

  {
    distrctName: "Thakurgaon",
    AreaName: "Ranisankail",
  },




  //Bogra,BograArea


  {
    distrctName: "Bogra",
    AreaName: "Adamdighi",
  },
  {
    distrctName: "Bogra",
    AreaName: "BograSadar",
  },

  {
    distrctName: "Bogra",
    AreaName: "Sherpur",
  },
  {
    distrctName: "Bogra",
    AreaName: "Dhunat",
  },

  {
    distrctName: "Bogra",
    AreaName: "Dhupchanchia",
  },
  {
    distrctName: "Bogra",
    AreaName: "Gabtali",
  },

  {
    distrctName: "Bogra",
    AreaName: "Kahaloo",
  },
  {
    distrctName: "Bogra",
    AreaName: "Nandigram",
  },

  {
    distrctName: "Bogra",
    AreaName: "Sahajanpur",
  },
  {
    distrctName: "Bogra",
    AreaName: "Sariakandi",
  },

  {
    distrctName: "Bogra",
    AreaName: "Shibganj",
  },
  {
    distrctName: "Bogra",
    AreaName: "Sonatala",
  },


  //Joypurhat,JoypurhatArea


  {
    distrctName: "Joypurhat",
    AreaName: "JoypurhatSadar",
  },
  {
    distrctName: "Joypurhat",
    AreaName: "Akkelpur",
  },

  {
    distrctName: "Joypurhat",
    AreaName: "Kalai",
  },
  {
    distrctName: "Joypurhat",
    AreaName: "Khetlal",
  },

  {
    distrctName: "Joypurhat",
    AreaName: "Panchbibi",
  },



  //Naogaon,NaogaonArea


  {
    distrctName: "Naogaon",
    AreaName: "NaogaonSadar",
  },
  {
    distrctName: "Naogaon",
    AreaName: "Mohadevpur",
  },

  {
    distrctName: "Naogaon",
    AreaName: "Manda",
  },
  {
    distrctName: "Naogaon",
    AreaName: "Niamatpur",
  },

  {
    distrctName: "Naogaon",
    AreaName: "Atrai",
  },

  {
    distrctName: "Naogaon",
    AreaName: "Raninagar",
  },
  {
    distrctName: "Naogaon",
    AreaName: "Patnitala",
  },

  {
    distrctName: "Naogaon",
    AreaName: "Dhamoirhat",
  },
  {
    distrctName: "Naogaon",
    AreaName: "Sapahar",
  },

  {
    distrctName: "Naogaon",
    AreaName: "Porsha",
  },

  {
    distrctName: "Naogaon",
    AreaName: "Badalgachhi",
  },




  //Natore,NatoreArea


  {
    distrctName: "Natore",
    AreaName: "NatoreSadar",
  },
  {
    distrctName: "Natore",
    AreaName: "Baraigram",
  },

  {
    distrctName: "Natore",
    AreaName: "Bagatipara",
  },
  {
    distrctName: "Natore",
    AreaName: "Lalpur",
  },

  //Nawabganj,NawabganjArea


  {
    distrctName: "Nawabganj",
    AreaName: "Bholahat",
  },
  {
    distrctName: "Nawabganj",
    AreaName: "Gomastapur",
  },

  {
    distrctName: "Nawabganj",
    AreaName: "Nachole",
  },
  {
    distrctName: "Nawabganj",
    AreaName: "NawabganjSadar",
  },
  {
    distrctName: "Nawabganj",
    AreaName: "Shibganj",
  },


  //Pabna,PabnaArea


  {
    distrctName: "Pabna",
    AreaName: "Atgharia",
  },
  {
    distrctName: "Pabna",
    AreaName: "Bera",
  },

  {
    distrctName: "Pabna",
    AreaName: "Bhangura",
  },
  {
    distrctName: "Pabna",
    AreaName: "Chatmohar",
  },
  {
    distrctName: "Pabna",
    AreaName: "Faridpur",
  },

  {
    distrctName: "Pabna",
    AreaName: "Ishwardi",
  },
  {
    distrctName: "Pabna",
    AreaName: "PabnaSadar",
  },
  {
    distrctName: "Pabna",
    AreaName: "Santhia",
  },
  {
    distrctName: "Pabna",
    AreaName: "Sujanagar",
  },


  //Rajshahi,RajshahiArea


  {
    distrctName: "Rajshahi",
    AreaName: "Bagha",
  },
  {
    distrctName: "Rajshahi",
    AreaName: "Bagmara",
  },

  {
    distrctName: "Rajshahi",
    AreaName: "Charghat",
  },
  {
    distrctName: "Rajshahi",
    AreaName: "Durgapur",
  },
  {
    distrctName: "Rajshahi",
    AreaName: "Godagari",
  },

  {
    distrctName: "Rajshahi",
    AreaName: "Mohanpur",
  },
  {
    distrctName: "Rajshahi",
    AreaName: "Paba",
  },
  {
    distrctName: "Rajshahi",
    AreaName: "Puthia",
  },
  {
    distrctName: "Rajshahi",
    AreaName: "Tanore",
  },
  {
    distrctName: "Rajshahi",
    AreaName: "Boalia",
  },
  {
    distrctName: "Rajshahi",
    AreaName: "Motihar",
  },
  {
    distrctName: "Rajshahi",
    AreaName: "Shahmokhdum",
  },
  {
    distrctName: "Rajshahi",
    AreaName: "Rajpara",
  },
  //Sirajganj,SirajganjArea


  {
    distrctName: "Sirajganj",
    AreaName: "SirajganjSadar",
  },
  {
    distrctName: "Sirajganj",
    AreaName: "Belkuchi",
  },

  {
    distrctName: "Sirajganj",
    AreaName: "Chauhali",
  },
  {
    distrctName: "Sirajganj",
    AreaName: "Kamarkhanda",
  },
  {
    distrctName: "Sirajganj",
    AreaName: "Kazipur",
  },

  {
    distrctName: "Sirajganj",
    AreaName: "Raiganj",
  },

  {
    distrctName: "Sirajganj",
    AreaName: "Shahjadpur",
  },
  {
    distrctName: "Sirajganj",
    AreaName: "Tarash",
  },
  {
    distrctName: "Sirajganj",
    AreaName: "Ullahpara",
  },

  //Barguna,BargunaArea


  {
    distrctName: "Barguna",
    AreaName: "Amtali",
  },
  {
    distrctName: "Barguna",
    AreaName: "Bamna",
  },

  {
    distrctName: "Barguna",
    AreaName: "BargunaSadar",
  },
  {
    distrctName: "Barguna",
    AreaName: "Betagi",
  },
  {
    distrctName: "Barguna",
    AreaName: "Patharghata",
  },
  {
    distrctName: "Barguna",
    AreaName: "Taltali",
  },


  //Barisal,BarisalArea


  {
    distrctName: "Barisal",
    AreaName: "Muladi",
  },
  {
    distrctName: "Barisal",
    AreaName: "Babuganj",
  },

  {
    distrctName: "Barisal",
    AreaName: "Agailjhara",
  },
  {
    distrctName: "Barisal",
    AreaName: "BarisalSadar",
  },
  {
    distrctName: "Barisal",
    AreaName: "Bakerganj",
  },
  {
    distrctName: "Barisal",
    AreaName: "Banaripara",
  },

  {
    distrctName: "Barisal",
    AreaName: "Gaurnadi",
  },
  {
    distrctName: "Barisal",
    AreaName: "Hizla",
  },

  {
    distrctName: "Barisal",
    AreaName: "Mehendiganj",
  },
  {
    distrctName: "Barisal",
    AreaName: "Wazirpur",
  },
  {
    distrctName: "Barisal",
    AreaName: "Airport",
  },
  {
    distrctName: "Barisal",
    AreaName: "Kawnia",
  },

  {
    distrctName: "Barisal",
    AreaName: "Bondor",
  },
  //Bhola,BholaArea


  {
    distrctName: "Bhola",
    AreaName: "BholaSadar",
  },
  {
    distrctName: "Bhola",
    AreaName: "Burhanuddin",
  },

  {
    distrctName: "Bhola",
    AreaName: "CharFasson",
  },
  {
    distrctName: "Bhola",
    AreaName: "Daulatkhan",
  },
  {
    distrctName: "Bhola",
    AreaName: "Lalmohan",
  },

  {
    distrctName: "Bhola",
    AreaName: "Manpura",
  },
  {
    distrctName: "Bhola",
    AreaName: "Tazumuddin",
  },

  //Jhalokati,JhalokatiArea


  {
    distrctName: "Jhalokati",
    AreaName: "JhalokatiSadar",
  },
  {
    distrctName: "Jhalokati",
    AreaName: "Kathalia",
  },

  {
    distrctName: "Jhalokati",
    AreaName: "Nalchity",
  },
  {
    distrctName: "Jhalokati",
    AreaName: "Rajapur",
  },


  //Patuakhali,PatuakhaliArea


  {
    distrctName: "Patuakhali",
    AreaName: "Bauphal",
  },
  {
    distrctName: "Patuakhali",
    AreaName: "Dashmina",
  },

  {
    distrctName: "Patuakhali",
    AreaName: "Galachipa",
  },
  {
    distrctName: "Patuakhali",
    AreaName: "Kalapara",
  },
  {
    distrctName: "Patuakhali",
    AreaName: "Mirzaganj",
  },
  {
    distrctName: "Patuakhali",
    AreaName: "PatuakhaliSadar",
  },

  {
    distrctName: "Patuakhali",
    AreaName: "Dumki",
  },
  {
    distrctName: "Patuakhali",
    AreaName: "Rangabali",
  },

  //Pirojpur,PirojpurArea


  {
    distrctName: "Pirojpur",
    AreaName: "Bhandaria",
  },
  {
    distrctName: "Pirojpur",
    AreaName: "Kaukhali",
  },

  {
    distrctName: "Pirojpur",
    AreaName: "Mathbaria",
  },
  {
    distrctName: "Pirojpur",
    AreaName: "Nazirpur",
  },
  {
    distrctName: "Pirojpur",
    AreaName: "Nesarabad",
  },
  {
    distrctName: "Pirojpur",
    AreaName: "PirojpurSadar",
  },

  {
    distrctName: "Pirojpur",
    AreaName: "Zianagar",
  },

]