import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SalesReport from './SalesReport/SalesReport'
import SalesReturnReport from './SalesReport/SalesReturnReport'
import SalesReportNew from './SalesReportNew/SalesReportNew'
import PurchaseReport from './PurchaseReport/PurchaseReport'
import SupplierLedger from './PurchaseReport/SupplierLedger'
import StockSummeryPS from './PurchaseReport/StockSummeryPS'
import StockCapital from './PurchaseReport/StockCapital'
import StockMaintainReport from './PurchaseReport/StockMaintainReport'
import ExpenseReport from './ExpenseReport/ExpenseReport'
import ExpenseReportHeadWise from './ExpenseReport/ExpenseReportHeadWise'
import SaleCollectionStatement from './BankBalance/SaleCollectionStatement'
import CashinHandReceive from './BankBalance/CashinHandReceive'
import BankPaymentReceive from './BankBalance/BankPaymentReceive'
import AtaGlanceReport from './AccountsPart/AtaGlanceReport'
import AccountAraGlanceReport from './AccountsPart/AccountAraGlanceReport'
import TrialBalance from './AccountsPart/TrialBalance'
import SalesProductWiseReportNew from './SalesReportNew/SalesProductWiseReportNew'
import SalesReturnReportNew from './SalesReportNew/SalesReturnReportNew'
import CustomerLedger from './SalesReportNew/CustomerLedger'
import ErrorPage from '../../ErrorPage'
export default function ReportRoutes() {
  return (
    <Routes>    
      
    <Route path='/sales-report/*' element={<SalesReportNew/>} /> 
    <Route path='/sales-report-productwise/*' element={<SalesProductWiseReportNew/>} /> {/*done */}
    <Route path='/sales-return-report/*' element={<SalesReturnReportNew/>} /> {/*done */}
    <Route path='/customer-ladger/*' element={<CustomerLedger/>} /> {/*done */}

    <Route path='/purchase-report/*' element={<PurchaseReport/>} /> {/*done */}
    <Route path='/supplier-ladger/*' element={<SupplierLedger/>} /> {/*done */}
    <Route path='/stock-summery-ps/*' element={<StockSummeryPS/>} /> {/*done */}
    <Route path='/stock-capital/*' element={<StockCapital/>} /> {/*done */}
    <Route path='/stock-maintain/*' element={<StockMaintainReport/>} /> {/*done */}

    <Route path='/expense_report/*' element={<ExpenseReport/>} /> {/*done */}
    <Route path='/expense_report_head/*' element={<ExpenseReportHeadWise/>} />  {/*done */}
    <Route path='/collection_statement/*' element={<SaleCollectionStatement/>} /> {/*done */}
    <Route path='/cashinhand_receive/*' element={<CashinHandReceive/>} /> {/*done */}
    <Route path='/bank_payment_receive/*' element={<BankPaymentReceive/>} /> {/*done */}
    <Route path='/ata_glance/*' element={<AtaGlanceReport/>} /> {/*done */}
    <Route path='/account_ata_glance/*' element={<AccountAraGlanceReport/>} /> {/*done */}
    <Route path='/trial_balance/*' element={<TrialBalance/>} />
    {/* <Route path='*' element={<ErrorPage/>}/> */}
</Routes>
  )
}
