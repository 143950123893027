import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { GetFilteredDataExpress, SaveDataExpress, UpdateItemExpress } from '../../api/ExpressFunctions'
import PendingViewCss from './css/PendingView.module.css'
const PendingView = () => {
    const [infoData, setInfoData] = useState()
    const [tableData, setTableData] = useState()
    const [mySearchParam, setMySearchParam] = useSearchParams()

    async  function getDataFormDatabase() {   
        let expenseNo = mySearchParam.get('exno')
        let myData = await GetFilteredDataExpress('expense', 'id', expenseNo)
        setInfoData(myData[0])
        let myTableData = await GetFilteredDataExpress('expense_items', 'expenseID',myData[0].id)
        setTableData(myTableData)
       // console.log(myTableData)
    }
    const [noteData, setNoteData] = useState()
    function onNoteChange(e) {
        let value = e.target.value
        setNoteData(value)
    }
  async  function onConfirmClick() {
    let userID = window.localStorage.getItem('user_id')
     if (!noteData) {
        alert('Note is needed to continue')
     }else{
            let dataID = infoData.id
            await UpdateItemExpress('expense', {confirmed:1, pending:0}, dataID)
            let ExpenseSummeryDetals ={
                status:'Expense Confirmed',             
                expensID:dataID,
                attemptByID:userID,
                note:noteData,
                created_date:new Date().getTime(),
               }
               await SaveDataExpress('expense_summery', {...ExpenseSummeryDetals})
               alert('Confirmed')
     }
       
    }
  async  function onDeclineClick() {
        let userID = window.localStorage.getItem('user_id')
        let dataID = infoData.id
        //console.log(infoData)
        if (!noteData) {
            alert('Note is needed to continue')
         }else{
         await UpdateItemExpress('expense', {declined:1, pending:0}, dataID)
         let ExpenseSummeryDetals ={
            status:'Expense Declined',             
            expensID:dataID,
            attemptByID:userID,
            note:noteData,
            created_date:new Date().getTime(),
           }
           await SaveDataExpress('expense_summery', {...ExpenseSummeryDetals})
         }
    }

    function onSearchClick(params) {
      
    }

    useEffect(() => {
        getDataFormDatabase()
    },[])
    const tableIconStyle = {
        margin: '0',
        padding: '0',
        margin: '0 3px 0 3px',
        fontSize: '18px',
        cursor: 'pointer'
      }
  return (
    <>
    <section className={PendingViewCss.salesMain}>
        <div className={PendingViewCss.innerSale}>
            <h2 className={PendingViewCss.saleHeading} onClick={onSearchClick}>Pending Expense View </h2>
            <div >
                <p>Expense No : {infoData?.id}</p> 
                <p>Payment Type : {infoData?.paymentType}</p> 
                <p>Process By : {infoData?.processorID}</p> 
                <p>Expense Note : {infoData?.expenseNote}</p>
                
            </div>
            <div className={PendingViewCss.tableSection}>
                <table style={{marginTop:'0'}}>
                    <tbody>
                        <tr>
                            <th>Sl</th>
                            <th>Particulars</th>
                            <th>Note</th>
                            <th>Price</th>
                        </tr>
                        {tableData?.map((item, index)=>{
                          return <tr key={index}>
                              <td>{index+1}</td>
                              <td>{item.particular}</td>
                              <td>{item.note}</td>
                              <td style={{textAlign:'right'}}>{item.price}</td>
                          </tr>
                        })}
                    </tbody>
                </table>
                <div className={PendingViewCss.tableDetails}>
                      <p>Total Amount : {infoData?.ammount}</p>
                      <p>Paid Amount : {infoData?.paymentAmmount}</p>
                      <p>Due Amount : {infoData?.ammount - infoData?.paymentAmmount}</p>
                  </div>
            </div>

            <div style={{display:'flex', justifyContent:'flex-end', marginTop:'15px', alignItems:'center'}}>
                <label style={{marginRight:'10px'}}>Confirm / Decline Note*</label>
                <input style={{padding:'8px', borderRadius:'5px', border:'none', outline:'1px solid green'}} name='note' placeholder='write your note here' onChange={onNoteChange} />
            </div>
            <div className={PendingViewCss.button_section} style={{display:'flex', justifyContent:'flex-end'}}>
                <button style={{background:'red'}} onClick={onDeclineClick}>Decline</button>
                <button style={{background:'green'}} onClick={onConfirmClick}>Confirm</button>
            </div>
        </div>
    </section>
</>
  )
}

export default PendingView
