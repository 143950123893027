import React from "react";
import { Route, Routes } from "react-router-dom";
import InvoiceWiseReport from "./reports/InvoiceWiseReport";
import ProductWiseReport from "./reports/ProductWiseReport";

export default function ProfileRoutes() {
  return (
    <Routes>
      <Route path="invoice-wise" element={<InvoiceWiseReport />} />
      <Route path="product-wise" element={<ProductWiseReport />} />
      {/* <Route path="brand-wise" element={<BrandWiseReport />} /> */}
    </Routes>
  );
}
