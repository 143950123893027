import React from "react";
import { IoClose } from "react-icons/io5";
import { API_LINK } from "../../../../api/APILink";
import userImageUpdateCss from "./css/userImageUpdate.module.css";
import { UpdateBackUserData } from "../../../../api/BackendUserApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setIsLogedIn,
  setUserInfo,
} from "../../../../ReduxFiles/Slices/UserSlice";
export default function UserImageUpdate({ setUpdateImagePop, updateImage }) {
  function onClose() {
    setUpdateImagePop(false);
  }
  const myNavigate = useNavigate();
  const myDispatch = useDispatch();

  async function onUpdateImage(e) {
    e.preventDefault();
    const userImage = e.target.userImage.files[0];
    const id = updateImage.id;
    try {
      await UpdateBackUserData(id, {}, userImage, updateImage.userImageName);
      window.localStorage.removeItem("userData");
      myNavigate("/");
      myDispatch(setIsLogedIn(false));
      myDispatch(setUserInfo({}));

      toast.success("Image update Success");
    } catch (error) {
      console.log(error.message);
    }
    // console.log(userImage, updateImage);
  }
  return (
    <div className={userImageUpdateCss.wrapper_popup}>
      <div className={userImageUpdateCss.holder}>
        <h3>Update Your Profile</h3>
        <button onClick={onClose} className={userImageUpdateCss.close_button}>
          <IoClose />
        </button>
        <form onSubmit={onUpdateImage}>
          <div className={userImageUpdateCss.input_wrapper}>
            <input
              type="file"
              name="userImage"
              accept="image/jpg, image/jpeg, image/webp"
            />
            <img src={`${API_LINK}/userimages/${updateImage.userImageName}`} />
          </div>
          <div className={userImageUpdateCss.button_wrapper}>
            <button>Update</button>
          </div>
        </form>
      </div>
    </div>
  );
}
