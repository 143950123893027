import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {  GetNestedAllData, GetWhereQueryData,} from '../connector/DatabaseCheck'
import DeclineViewCss from './css/DeclineView.module.css'

const DeclineView = () => {
    const [filterData, setFilterData] = useState()
    const [infoData, setInfoData] = useState()
    const [tableData, setTableData] = useState()
    const [mySearchParam, setMySearchParam] = useSearchParams()

    async  function getDataFormDatabase() {   
        let expenseNo = mySearchParam.get('exno')
        let myData = await GetWhereQueryData('Expense', 'expenseNo', parseInt(expenseNo), 'expenseID')
        let myTableData = await GetNestedAllData('Expense', myData[0].expenseID, 'ExpenseDetails', 'nestedID' )
        setInfoData(myData[0])
        setTableData(myTableData)
    }
    function onPrintClick(params) {
        
    }
    function onSearchClick(params) {
      
    }

    useEffect(() => {
        getDataFormDatabase()
    },[])
    const tableIconStyle = {
        margin: '0',
        padding: '0',
        margin: '0 3px 0 3px',
        fontSize: '18px',
        cursor: 'pointer'
      }
  return (
    <>
    <section className={DeclineViewCss.salesMain}>
        <div className={DeclineViewCss.innerSale}>
            <h2 className={DeclineViewCss.saleHeading} onClick={onSearchClick}>Expense Decline View</h2>
            <div >
                <p>Expense No : {infoData?.expenseNo}</p> 
                <p>Payment Type : {infoData?.paymentType}</p> 
                <p>Process By : {infoData?.processBy}</p> 
                <p>Expense Note : {infoData?.expenseNote}</p>
                
            </div>
            <div className={DeclineViewCss.tableSection}>
                <table style={{marginTop:'0'}}>
                    <tbody>
                        <tr>
                            {/* Sl	Particulars	Note	Expense Head	Price	Action */}
                            <th>Sl</th>
                            <th>Particulars</th>
                            <th>Note</th>
                            <th>Price</th>
                        </tr>
                        {tableData?.map((item, index)=>{
                          return <tr key={index}>
                              <td>{index+1}</td>
                              <td>{item.particular}</td>
                              <td>{item.note}</td>
                              <td style={{textAlign:'right'}}>{item.price}</td>
                          </tr>
                        })}
                    </tbody>
                </table>
                <div className={DeclineViewCss.tableDetails}>
                      <p>Total Amount : {infoData?.ammount}</p>
                      <p>Paid Amount : {infoData?.paymentAmmount}</p>
                      <p>Due Amount : {infoData?.ammount - infoData?.paymentAmmount}</p>
                  </div>
            </div>

            
            <div className={DeclineViewCss.button_section} style={{display:'flex', justifyContent:'flex-end'}}>
                <button style={{background:'green'}} onClick={onPrintClick}>Print</button>
            </div>
        </div>
    </section>
</>
  )
}

export default DeclineView
