import React, { useEffect, useState } from 'react'
import { LocalTimeStampToDate } from '../../MyFunctions/MyFunctions'
import SSLCss from './Css/SSL.module.css'
const SSL = () => {
    const [filterData, setFilterData] = useState()
    const [tableData, setTableData] = useState()


    function onDataChange(params) {
        
    }
    function onSearchClick(params) {
        
    }


    function getTotalAmmount(myArray, fieldName) {
        let total = 0
        myArray?.map((item)=>{
            total +=item[fieldName] ? item[fieldName]:0
        })
        return total
    }
  return (
    <>
    <section className={SSLCss.salesMain}>
        <div className={SSLCss.innerSale}>
            <h2 className={SSLCss.saleHeading}>SSL History</h2>
                 <div className={SSLCss.search_section}>
                      <div className={SSLCss.input_section}>
                          <label>Start Date</label>
                          <input name='startDate' type={'date'} value={filterData?.startDate? filterData?.startDate:''} onChange={onDataChange}/>
                      </div>
                      <div className={SSLCss.input_section}>
                          <label>End Date</label>
                          <input name='endDate' type={'date'} value={filterData?.endDate ? filterData?.endDate:''} onChange={onDataChange}/>
                      </div>
                     
                    
                      
                  </div>
                  <div className={SSLCss.buttonWrapper}>
                          <button onClick={onSearchClick}>search</button>
                          <button>reset</button>
                 </div>
                 
            <div className={SSLCss.tableSection}>
                <table>
                    <tbody>
                        <tr>
                            <th>Sl</th>
                            <th>Date</th>
                            <th>Particular</th>
                            <th>Receipt</th>
                            <th>Payment</th>
                            <th>Agent Info</th>
                            <th>Process By</th>
                            <th>Action</th>
                        </tr>
                        {tableData?.map((item, index)=>{
                          return <tr key={index}>
                              <td>{index+1}</td>
                              <td>{LocalTimeStampToDate(item.created_date)}</td>
                              <td>{item.particulars} (#{item.identity})</td>
                              <td>{item.receiveAmmount}</td>
                              <td>{item.paymentAmmount}</td>
                              <td>{item.card_type}</td>
                              <td>{item.receivedBy}</td>
                              <td>action</td>
                          </tr>
                        })}
                        <tr>
                            <td colSpan={3}></td>
                            <td>Total : {getTotalAmmount(tableData, 'receiveAmmount')}</td>
                            <td>Total : {getTotalAmmount(tableData, 'paymentAmmount')}</td>
                            <td colSpan={3}>Grand Total : {getTotalAmmount(tableData, 'receiveAmmount')-getTotalAmmount(tableData, 'paymentAmmount')}</td>
                            
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</>
  )
}

export default SSL