import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { DecreaseValue, GetDataPassQuery, GetFilteredDataExpress, GetSingleDocExpress, SaveDataExpress, UpdateItemExpress } from '../../api/ExpressFunctions'
import PurchaseReturnCss from './css/PurchaseReturn.module.css'

const PurchaseReturn = () => {
    const myfoucsRef = useRef()
    const [searchData, setSearchData] = useState()
    const [spinerShow, setSpinnerShow] = useState(false)
    const [alertMessage, setAlertMessage] = useState(false)
    const [mainPo, setMainPo] = useState()
    const [orderItems, setOrderitems] = useState()
    const [oldOrderItems, setOldOrderitems] = useState()
    const [returnQuantity, setReturnQuantity] = useState()
    const [editVarData, setEditVarData] = useState()
    const [showPop, setShowPop] = useState(false)
    const [loadVar, setLoadVar] = useState(false)
    const [allProducts, setAllProducts] = useState(JSON.parse(window.localStorage.getItem('allProduct')))

    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        // setReturnQuantity()
        setSearchData({ ...searchData, [name]: value })
    }
    function onSearchClick() {
        if (searchData?.search) {
            SearchProducts(searchData?.search)
        } else {
            setAlertMessage('Please Enert PO Number to Return')
        }
    }
    function onEnterPress(e) {
        if (e.key === 'Enter') {
            if (searchData?.search) {
                setReturnQuantity()
                SearchProducts(searchData?.search)
                myfoucsRef.current.focus()
            } else {
                alert('Please Enert PO Number to Return')
            }
        }
    }
    async function SearchProducts(value) {
        //       console.log(value)
        setSpinnerShow(true)
        let data = await GetSingleDocExpress('purchase', value)
        // console.log(data)
        if (data.length > 0) {
            if (data[0].receivedPO) {
                if (data[0].fullReturn) {
                    setAlertMessage('PO Already Fully Returned')
                } else {
                    setMainPo(data[0])
                    let nestedData = await GetFilteredDataExpress('purchase_items', 'purchase_id', value)
                    let returnData = await GetFilteredDataExpress('purchase_return_items', 'po_id', value)
                    let finalArr = []
                    nestedData.forEach(element => {
                        let dataMatch = false
                        let returnElemetn = {}
                        returnData?.forEach(relement => {
                            if (element.id === relement.purchase_item_id) {
                                returnElemetn = relement
                                dataMatch = true
                            }

                        });
                        if (dataMatch) {
                            element = { ...element, quantity: element.quantity - returnElemetn.quantity }
                            finalArr.push(element)
                        } else {
                            finalArr.push(element)
                        }
                    });
                    setOrderitems(finalArr)
                    setOldOrderitems(finalArr)
                }

            } else if (data[0].declined) {
                setAlertMessage('Declined Order Cannot be Returned')
            }
            else {
                setAlertMessage('First You Need To Confirm This PO')
                setMainPo()
                setOrderitems()
            }
            setLoadVar(!loadVar)
            // setAlertMessage('Data Found')

        }
        else {
            setAlertMessage('Data Not Found')
            setMainPo()
            setOrderitems()
        }
        setSpinnerShow(false)
    }

    function returnQuantityChange(e) {
        let name = e.target.name
        let value = e.target.value
        setReturnQuantity({ ...returnQuantity, [name]: value })
        // console.log(name, value)
    }
    function getReturnAmmount() {
        let total = 0
        let myArray = []
        orderItems?.forEach((element, index) => {
            let identity = "returnQuantity" + index
            if (parseInt(returnQuantity?.[identity]) > parseInt(element.quantity)) {
                setAlertMessage('Return Value Should not Greater than Quantity')
                setReturnQuantity({ ...returnQuantity, [identity]: 0 })
                myArray.push(element)
            } else if (parseInt(returnQuantity?.[identity]) < 0) {
                setAlertMessage('Return Value Should not be Negative')
                myArray.push(element)
            } else {
                // if (varitemQuantity>0) {
                //     let tempTotal = parseInt(element.price) * parseInt(returnQuantity?.[identity])
                // }
                let tempTotal = parseInt(element.price) * parseInt(returnQuantity?.[identity])

                if (!tempTotal) {
                    tempTotal = 0
                    //  console.log('ok')
                }
                element = { ...element, returnAmmount: tempTotal, returnQuantity: parseInt(returnQuantity?.[identity]) }
                myArray.push(element)
                // console.log(tempTotal)
                if (tempTotal > 0) {
                    total += tempTotal
                }
            }

        });
        setOrderitems(myArray)
        let mtotal = 0
        orderItems?.forEach(element => {
            if (element.purchasedVariance) {
                element.purchasedVariance.forEach(vritems => {
                    mtotal += vritems.price * (vritems.returnQuantity ? vritems.returnQuantity : 0)
                });
            } else {
                // mtotal += element.price* (element.returnQuantity ? element.returnQuantity : 0) 
            }
        });

        setMainPo({ ...mainPo, newReturn: total + mtotal })
    }

    function checkClick(params) {
        console.log(orderItems)
    }
    function getVarQuantity(items) {
        let quantity = 0
        items?.forEach(element => {
            quantity += element.returnQuantity ? element.returnQuantity : 0
        });
        return quantity;
    }

    function getVrtotal() {
        let total = 0
        orderItems?.forEach(element => {
            if (element.purchasedVariance) {
                element.purchasedVariance.forEach(vritems => {
                    total += vritems.price * (vritems.returnQuantity ? vritems.returnQuantity : 0)
                });
            } else {
                total += element.price * (element.returnQuantity ? element.returnQuantity : 0)
            }
        });
        setMainPo({ ...mainPo, newReturn: total })
    }
    function getVrtotalLive() {
        let total = 0
        orderItems?.forEach(element => {
            if (element.purchasedVariance) {
                element.purchasedVariance.forEach(vritems => {
                    total += vritems.price * (vritems.returnQuantity ? vritems.returnQuantity : 0)
                });
            }
        });
        return total
    }

    useEffect(() => {
        getVrtotal()
    }, [showPop])


    useEffect(() => {
        getReturnAmmount()
    }, [returnQuantity])

    function varianceQuantity(e, item) {
        setShowPop(true)
        setEditVarData(item)
        // console.log(item)
    }
    //productID  returnQuantity varienceID
    async function onSubmitClick() {
        // saveToReturnTable()
        returnSubmit()
    }
    async function returnSubmit() {
        orderItems?.forEach(async (item) => {

            if (item.hasVriance) {
                let totalQuantity = 0
                let myVrPromises = []
                myVrPromises = item?.purchasedVariance?.map(async (element, index) => {
                    if (element.returnQuantity) {
                        //  console.log(element)
                        let myFinalData = {
                            po_id: mainPo.id,
                            purchase_item_id: element.purchase_item_id,
                            product_id: element.product_id,
                            variance_id: element.varinace_id,
                            quantity: element.returnQuantity,
                            price: element.price,
                            created_date: new Date().getTime(),
                        }
                        await SaveDataExpress('purchase_return_variance', { ...myFinalData })
                        await DecreaseValue('product_variance_stock', 'stock_quantity', element.returnQuantity, 'variance_id', element.varinace_id)
                        totalQuantity += element.returnQuantity
                    }
                })

                Promise.all(myVrPromises ? myVrPromises : []).then(async () => {
                    let finalData = {
                        po_id: mainPo.id,
                        purchase_item_id: item.id,
                        product_id: item.product_id,
                        quantity: totalQuantity,
                        price: item.price,
                        has_variance: item.hasVriance,
                        created_date: new Date().getTime(),
                    }
                    await SaveDataExpress('purchase_return_items', { ...finalData })
                    await DecreaseValue('product_stock', 'stock_quantity', totalQuantity, 'product_id', item.product_id)
                })

            } else {

                if (item.returnQuantity) {
                    let finalData = {
                        po_id: mainPo.id,
                        purchase_item_id: item.id,
                        product_id: item.product_id,
                        quantity: item.returnQuantity,
                        price: item.price,
                        has_variance: item.hasVriance ? item.hasVriance : 0,
                        created_date: new Date().getTime(),
                    }
                    await SaveDataExpress('purchase_return_items', { ...finalData })
                    await DecreaseValue('product_stock', 'stock_quantity', item.returnQuantity, 'product_id', item.product_id)
                }

            }
        });

        await UpdateItemExpress('purchase', { partialReturn: 1, status: "PO Partial Return", }, mainPo.id)

        let userData = window.localStorage.getItem('userData')
        userData = JSON.parse(userData)
        let summeryData = {
            order_date: mainPo.poDate,
            supplier_id: mainPo.supplierID,
            status: 'Order Partial Return',
            attemptBy: window.localStorage.getItem('user_id'),
            purchase_id: mainPo.id,
            created_date: new Date().getTime()
        }
        // console.log(summeryData)
        await SaveDataExpress('purhcase_summery', { ...summeryData })
        setAlertMessage('Product Return Success')
    }

    async function onWholePoCancel() {
        //  await UpdateData('Purchase', {returningAmmount:mainPo.grandTotal, fullReturn:true, status:"PO Full Return", returnQuantity:mainPo.totalQuantity}, mainPo.id)
        //   console.log(mainPo, orderItems) totalQuantity
        alert('under development')
    }

    const tableItems = orderItems?.map((item, index) => {
        return <MyTable key={item.id + item.product_id} item={allProducts?.filter((fitem) => {
            return fitem.id === item.product_id ? fitem : ''
        }).map((myItem) => {
            return myItem.en_sort_title

        })} price={item.price} quantity={item.quantity}
            returnQuantity={<div className={PurchaseReturnCss.variance_quantity}>{item?.hasVriance ?
                <><FontAwesomeIcon className={PurchaseReturnCss.color_purple} icon={faEdit} onClick={(e) => { varianceQuantity(e, item) }} />
                    <span>{getVarQuantity(item?.purchasedVariance)}
                    </span>
                </>
                :
                <><input name={'returnQuantity' + index} type='text' placeholder='enter quantity' onChange={returnQuantityChange}
                    value={returnQuantity?.['returnQuantity' + index]} onFocus={(e) => { e.target.select() }} /></>}</div>} total={item.quantity * item.price} returntotal={item.hasVriance ? getVrtotalLive() : item.returnAmmount} />
    })

    return (
        <div className={PurchaseReturnCss.wrapper}>
            {showPop ? 
            <div className={PurchaseReturnCss.popup_wrapper}>
                <div className={PurchaseReturnCss.popup_holder}>
                    <ShowVarianceData data={editVarData} setShowPop={setShowPop} setData={setOrderitems} fulldata={orderItems} />  
                    {alertMessage ? <AlertMessage message={alertMessage} setAlertMessage={setAlertMessage} /> : ''}
                    {spinerShow ? <Spinner text={"Loading..."} /> 
                    
                    : ""}
                </div>
            </div>
            : ""}
            <div className={PurchaseReturnCss.holder}>
                <div className={PurchaseReturnCss.search}>
                    <input name='search' className={PurchaseReturnCss.searchbox} type="search" placeholder="Search PO No. Here..." autoFocus={true} onKeyDown={onEnterPress}
                        onChange={onDataChange} value={searchData?.search} />
                    <input className={PurchaseReturnCss.buttonsubmit} type="submit" value='search ' onClick={onSearchClick} />
                </div>
                <div className={PurchaseReturnCss.table_wrapper}>
                    <table>
                        <tr>
                            <th>Item Name</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Return Quantity</th>
                            <th>Total</th>
                            <th>Return Ammount </th>
                        </tr>
                        {tableItems}
                    </table>
                    <div className={PurchaseReturnCss.return_input}>
                        <div className={PurchaseReturnCss.common}>
                            <label>Return Product Ammount</label>
                            <input type='text' placeholder='returnig ammount' value={mainPo?.newReturn ? mainPo.newReturn : 0} disabled />
                        </div>
                    </div>

                    <div className={PurchaseReturnCss.info_section}>
                        <p className={PurchaseReturnCss.highlight} onClick={checkClick}>Payment Details</p>
                        <div className={PurchaseReturnCss.common}>
                            <p>Po No : </p>
                            <p># {mainPo?.id}</p>
                        </div>
                        <div className={PurchaseReturnCss.common}>
                            <p>PO Ammount : </p>
                            <p>{mainPo?.grandTotal}</p>
                        </div>
                        <div className={PurchaseReturnCss.common}>
                            <p>Previous Return Price : </p>
                            <p>{mainPo?.returningAmmount ? mainPo.returningAmmount : 0}</p>
                        </div>
                        <div className={PurchaseReturnCss.common}>
                            <p>Previous Return Ammount : </p>
                            <p>{mainPo?.returningAmmount ? mainPo.returningAmmount : 0}</p>
                        </div>
                        <div className={PurchaseReturnCss.common}>
                            <p>Now Returning : </p>
                            <p>{mainPo?.newReturn ? mainPo?.newReturn : 0}</p>
                        </div>
                        <div className={PurchaseReturnCss.common}>
                            <p>Discount : </p>
                            <p>{mainPo?.percent ? mainPo?.percent : 0} %</p>
                        </div>
                    </div>
                    <div className={PurchaseReturnCss.button_section}>
                        <button onClick={onWholePoCancel}>Cancel Whole Order</button>
                        <button onClick={onSubmitClick}>Submit</button>
                    </div>
                </div>
               
            </div>
        </div>
    )
}

export default PurchaseReturn

const ShowVarianceData = ({ data, setShowPop, setData, fulldata }) => {
    const [varState, setVarState] = useState()
    const [varQuantity, setVarQuantity] = useState(0)

    function onVarQuantityChange(e, pQuantity) {

        let varData = [...varState]
        let name = e.target.name
        let value = e.target.value
        //  console.log(varData)
        if (pQuantity >= parseInt(value)) {
            let findIndex = varData.findIndex(obj => obj.varinace_id == name)
            varData[findIndex].returnQuantity = parseInt(value)
            //varData[findIndex].newQuantity = value
            CountQuantity(varData)
        } else {
            value = 0
            let findIndex = varData.findIndex(obj => obj.varinace_id == name)
            varData[findIndex].returnQuantity = parseInt(value)
            //varData[findIndex].newQuantity = value
            CountQuantity(varData)
            console.log('Quantity must be less then Purchase Quantity')
        }
        // console.log(varData)
    }

    function CountQuantity(arrayData) {
        let counter = 0
        arrayData?.forEach(element => {
            let elementValue = 0
            if (element.returnQuantity) {
                elementValue = element.returnQuantity
            } else {
                elementValue = 0
            }
            counter += parseInt(elementValue)
        });
        setVarQuantity(counter)
    }

    async function onUpdateClick() {
        if (varQuantity > data?.quantity) {
            console.log('variance quantity is greater then purchase quantity')
        } else {
            let varData = []
            varState.forEach(element => {
                let returnQuantity = element.returnQuantity ? element.returnQuantity : 0
                let stockAmmount = element.quantity ? element.quantity : 0

                if (parseInt(returnQuantity) > 0) {
                    element = { ...element, stockAmmount: (parseInt(stockAmmount) - parseInt(returnQuantity)) }
                    varData.push(element)
                } else {
                    varData.push(element)
                }
            });
            let finalArr = []
            let finalData = { ...data, purchasedVariance: varData }

            // console.log(finalData)
            fulldata?.forEach(element => {
                if (element.id === finalData.id) {
                    finalArr.push(finalData)
                } else {
                    finalArr.push(element)
                }
            });
            // console.log(finalArr)
            setData(finalArr)
            setShowPop(false)
        }
    }
    function CloseMainpPop() {
        setShowPop(false)
    }
    const [allVariance, setAllVariance] = useState()
    async function getVarianceList() {
        setAllVariance(JSON.parse(window.localStorage.getItem('allVariance')))
        let myQuary = `SELECT * FROM purchase_variance WHERE product_id = ${data?.product_id} AND purchase_item_id = ${data.id}`
        let nestedData = await GetDataPassQuery(myQuary)

        let rmyQuary = `SELECT * FROM purchase_return_variance WHERE product_id = ${data?.product_id} AND purchase_item_id = ${data.id}`
        let rnestedData = await GetDataPassQuery(rmyQuary)
        // console.log(rnestedData)
        let finalArr = []
        nestedData.forEach(element => {
            let dataFound = false
            let matchData = {}
            rnestedData.forEach(relement => {
                if (element.varinace_id === relement.variance_id) {
                    // console.log(relement, element)
                    dataFound = true
                    matchData = relement
                }
            });

            if (dataFound) {
                // console.log(matchData)
                element = {
                    ...element,
                    quantity: element.quantity - matchData.quantity
                }
            }
            finalArr.push(element)
        });
        setVarState(finalArr)
        // console.log(nestedData)
    }
    useEffect(() => {
        getVarianceList()
    }, [])

    function checkClick(params) {
        console.log(data)
    }

    return (
        <>
            <div className={`${PurchaseReturnCss.editVariantData} ${PurchaseReturnCss.purchase_variance}`}>
                <h3 className={PurchaseReturnCss.varTitle}>Product : {data ? data.mainName : ""} <span>(Order Quantity : {data?.quantity})</span> <span>[Variance Quantity : {varQuantity ? varQuantity : 0}]</span></h3>
                <div className={PurchaseReturnCss.detailstableSection}>
                    <table>
                        <tr>
                            <th>Variant</th>
                            <th>Variaance Info</th>
                            <th>Purchase Quantity</th>
                            <th>Return Quantity</th>
                        </tr>

                        {varState?.map((item, index) => {
                            return <>
                                <tr key={item.varinace_id}>
                                    <td>{allVariance?.filter((fitem) => {
                                        return fitem.id === item.varinace_id ? fitem : ''
                                    }).map((myItem) => {
                                        return myItem.Variance_name
                                    })}</td>
                                    <td>{allVariance?.filter((fitem) => {
                                        return fitem.id === item.varinace_id ? fitem : ''
                                    }).map((myItem) => {
                                        return myItem.attr_value
                                    })}</td>
                                    <td>{item.quantity}</td>
                                    <td>{<input className={PurchaseReturnCss.tableinput} name={item.varinace_id} type='number' onChange={(e) => { onVarQuantityChange(e, item.quantity) }}
                                        value={item.returnQuantity ? item.returnQuantity : 0} onFocus={(e) => { e.target.select() }} />}</td>
                                </tr>
                            </>
                        })}

                    </table>
                </div>
                <div className={PurchaseReturnCss.buttonClass}>
                    <button onClick={onUpdateClick}>Update</button>
                    <button onClick={CloseMainpPop}>close</button>
                    <button onClick={checkClick}>Check</button>
                </div>
            </div>
        </>
    )
}

const MyTable = ({ item, price, quantity, returnQuantity, total, returntotal }) => {

    return (
        <>
            <tr>
                <td className={PurchaseReturnCss.foreditVariance}>{item}</td>
                <td >{price}</td>
                <td>{quantity}</td>
                <td>{returnQuantity}</td>
                <td>{total}</td>
                <td>{returntotal}</td>
            </tr>
        </>
    )
}

const Spinner = ({ text }) => {
    const myIndex = {
        zIndex: '15'
    }
    return (
        <>
            <div style={myIndex} className={PurchaseReturnCss.SpinnerWrrapper}>
                <div id="loader"></div>
                <p>{text}</p>
            </div>
        </>
    )
}
const ConfirmAlert = (props) => {
    const { alertText, yesClick, noClick, delID } = props

    return (
        <>
            <div className={PurchaseReturnCss.conAlert}>
                <h2 className={PurchaseReturnCss.caption}>Do you Want To Delete This?</h2>
                <div className={PurchaseReturnCss.buttonsec}>
                    <input sid={delID} onClick={yesClick} className={PurchaseReturnCss.yes} type={"submit"} value={"Yes"} />
                    <input onClick={noClick} className={PurchaseReturnCss.no} type={"submit"} value={"No"} />
                </div>

            </div>
        </>
    )
}

const AlertMessage = ({ message, setAlertMessage }) => {

    function alertClick(e) {
        // console.log(e)
        setAlertMessage()

    }
    const zindex = {
        zIndex: 20
    }
    return (
        <div style={zindex} className={PurchaseReturnCss.AlertMessage}>
            <h2 >{message}</h2>
            <button onClick={alertClick} autoFocus={true}>OK</button>
        </div>
    )
}