import React, { useEffect, useState } from 'react'
import { GetAllDataExpress } from '../../../api/ExpressFunctions'
import { LocalTimeStampToDate } from '../../MyFunctions/MyFunctions'
import { GetCreditVaocerHistory } from '../../../api/CreditApi'
import CreditVoucherHistoryCss from './Css/CreditVoucherHistory.module.css'

const CreditVoucherHistory = () => {
    const [tableData, setTableData] = useState()
    async  function getDataFormDatabase() {   
        let myData = await GetCreditVaocerHistory()
        setTableData(myData)
    }


    useEffect(() => {
        getDataFormDatabase()
    },[])
  return (
   
          <section className={CreditVoucherHistoryCss.creditVoucherHestoryWrapper}>
              <div className={CreditVoucherHistoryCss.inner_headign}>
                  <h2 className={CreditVoucherHistoryCss.saleHeading}>Credit Voucher History</h2>
                  <div className={CreditVoucherHistoryCss.search_section}>
                      <div className={CreditVoucherHistoryCss.input_section}>
                          <label>Start Date*</label>
                          <input type={'date'} />
                      </div>
                      <div className={CreditVoucherHistoryCss.input_section}>
                          <label>End Date*</label>
                          <input type={'date'} />
                      </div>
                      <div className={CreditVoucherHistoryCss.input_section}>
                          <label>Select Customer*</label>
                          <select>
                              <option>select</option>
                          </select>
                      </div>
                      <div className={CreditVoucherHistoryCss.input_section}>
                          <label>Transaction Type*</label>
                          <select>
                              <option>select</option>
                          </select>
                      </div>
                                
                  </div>
                  <div className={CreditVoucherHistoryCss.input_section}>
                            <div className={CreditVoucherHistoryCss.action_btn}>
                                <button>search</button>
                                <button>reset</button>
                            </div>
                    </div>
                  <div className={CreditVoucherHistoryCss.tableSection}>
                      <table>
                          <tbody>
                              <tr>
                                  <th>Sl</th>
                                  <th>Date</th>
                                  <th>Vaoucher No</th>
                                  <th>Reference No</th>
                                  <th>Ammount</th>
                                  <th>Type of Transaction</th>
                                  <th>Process By</th>
                                  <th>Action</th>

                              </tr>
                              {tableData?.map((item, index)=>{
                                return <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{LocalTimeStampToDate(item.created_date)}</td>
                                    <td>{item.id}</td>
                                    <td>{item.particulars} (#{item.identity})</td>
                                    <td>{item.receiveAmmount}</td>
                                    <td>{item.receiveType}</td>
                                    <td>{item.userName}</td>
                                    <td>action</td>
                                </tr>
                              })}
                          </tbody>
                      </table>
                  </div>
              </div>
          </section>
  
  )
}

export default CreditVoucherHistory