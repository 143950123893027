import MyAxios from 'axios'
import { API_LINK} from './APILink'
const MY_API_LINK = `${API_LINK}combine/`
const PStock_API_LINK = `${API_LINK}product-stock/`
const VStock_API_LINK = `${API_LINK}product-vrstock/`
const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}

export async function GetProductStock() {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}pstock`)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function GetFilterProductStock(query) {
    try {
        let result = await MyAxios.get(`${PStock_API_LINK}filter/${query}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetVarianceStock(data) {
    try {
        let result = await MyAxios.post(`${VStock_API_LINK}variance-stock`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function SaveProductStock(data) {
    try {
        let result = await MyAxios.post(`${PStock_API_LINK}add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function UpdateProductStock(id, data) {
    try {
        let result = await MyAxios.put(`${PStock_API_LINK}${id}`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function DecreaseProductStock(data) {
    try {
        let result = await MyAxios.post(`${PStock_API_LINK}decrease-value`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function InecreaseProductStock(data) {
    try {
        let result = await MyAxios.post(`${PStock_API_LINK}increase-value`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}



// Varinace stock
export async function GetFilterProductVarianceStock(query) {
    try {
        let result = await MyAxios.get(`${VStock_API_LINK}filter/${query}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function SaveProductVarianceStock(data) {
    try {
        let result = await MyAxios.post(`${VStock_API_LINK}add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function UpdateProductVarianceStock(id, data) {
    try {
        let result = await MyAxios.put(`${VStock_API_LINK}${id}`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function DecreaseVarianceStock(data) {
    try {
        let result = await MyAxios.post(`${VStock_API_LINK}decrease-value`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function InecreaseVarianceStock(data) {
    try {
        let result = await MyAxios.post(`${VStock_API_LINK}increase-value`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }
}
// get stock request data 

let REQUEST_API_LINK = `${API_LINK}request-stock/`

export async function GetStockRequestData() {
    try {
        let result = await MyAxios.post(`${REQUEST_API_LINK}get-request-data`)
        return result.data        
    } catch (error) {
        return 0
    }
}
export async function UpdateStockRequest(id, data) {
    try {
        let result = await MyAxios.put(`${REQUEST_API_LINK}${id}`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}