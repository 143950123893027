import MyAxios from 'axios'
import { API_LINK} from './APILink'
const MY_API_LINK = `${API_LINK}credit-voucher/`

const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}

export async function GetTodayCredit(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}today`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function SaveCreditData(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}



export async function GetCustomerCredit(id) {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}customer-credit/${id}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function GetCreditFilterData(query) {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}filter/${query}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetCreditVaocerHistory(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}/credit_history`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetCashHistory(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}/cash_history`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
