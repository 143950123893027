import React from "react";
import { Link } from "react-router-dom";
import salesmainCss from "./salesCss/SalesMain.module.css";

const SalesMain = () => {
  const salesMenu = [
    { title: "Create Order", link: "/sales/create-order", icon: "" },
    { title: "Unverified Sales", link: "/sales/unverified", icon: "" },
    {
      title: "Unverified Sales (Declined)",
      link: "/sales/unverified-declined",
      icon: "",
    },
    { title: "Pending Sales", link: "/sales/pending", icon: "" },
    { title: "Sale History", link: "/sales/history", icon: "" },
    { title: "Complete Orders", link: "/sales/complete", icon: "" },
    { title: "Decline Orders", link: "/sales/decline", icon: "" },
    { title: "Sales Return", link: "/sales/sales-return", icon: "" },
    { title: "Sales Return History", link: "/sales/return-history", icon: "" },
    { title: "Offer Items", link: "/sales/offer-items", icon: "" },
    { title: "Offer Report", link: "/sales/offer-report", icon: "" },
    { title: "Delivery Management", link: "/sales/delivery-manage", icon: "" },
    { title: "Buy 1 Get 1", link: "/sales/bogo", icon: "" },
    { title: "Invoice Wise Sales Report", link: "/sales/invoice", icon: "" },
    { title: "Product Wise Sales Report", link: "/sales/product", icon: "" },
  ];

  return (
    <div className={salesmainCss.wrapper}>
      <div className={salesmainCss.menuitems}>
        {salesMenu.map((item, index) => {
          return (
            <Link to={item.link} className={salesmainCss.item} key={index}>
              <h2>{item.title}</h2>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default SalesMain;
