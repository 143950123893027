import MyAxios from 'axios'
import { API_LINK} from './APILink'
const MY_API_LINK = `${API_LINK}supplier/`
const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}

export async function GetALLSupplier() {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}all`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetFilterSupplier(id) {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}filter/${id}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function AddSupplier(data) {
    try {
        let result = await MyAxios.put(`${MY_API_LINK}add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function UpdateSupplier(id, data) {
    try {
        let result = await MyAxios.put(`${MY_API_LINK}${id}`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetTotalSupplier() {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}count/total`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
