import React, { useState } from 'react'
import DummyImage from '../../../assets/dummyuser.png'
import { useNavigate } from 'react-router-dom'
import AddnewAgentCss from './css/AddnewAgent.module.css'

const AddnewAgent = () => {
    const [agentState, setAgentState] = useState({})
    const [errState, setErrState] = useState()
    let myDate = new Date()
    const myNavigate = useNavigate()

    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        if (e.target.type === 'file') {
            // value = e.target.files[0]
            setAgentState({ ...agentState, [name]: e.target.files[0], fakeURL: genarateFakeUrl(e.target.files[0]) })
        } else {
            setAgentState({ ...agentState, [name]: value })
        }

    }
    function genarateFakeUrl(image) {
        let myurl = URL.createObjectURL(image)
        return myurl;
    }
    async function onSubmitClick() {
        // let time = myDate.getTime();
        // let finalData = {
        //     userName: agentState?.userName?.toLowerCase(),
        //     fullName: agentState?.fullName,
        //     email: agentState?.email,
        //     phone: agentState?.phone,
        //     about: agentState?.about,

        //     created_date: new Date().getTime()
        // }
        // userImageName: time+"__"+agentState?.agentImage?.name,
        let fullNameerr = ValidateFunction('fullName', 'Full Name ')
        let userNameerr = ValidateFunction('userName', 'User Name ')
        let emailerr = ValidateFunction('email', 'Email Address ')
        let phoneerr = ValidateFunction('phone', 'Phone Number ')
        let abouterr = ValidateFunction('about', 'about ')
        let imgerr = ValidateFunction('agentImage', 'Image ')


        if (!fullNameerr && !userNameerr && !emailerr && !phoneerr && !abouterr && !imgerr) {
            let checkExistingData =[] // await GetFilteredDataExpress('delivery_management', 'userName', agentState?.userName)
            //console.log(checkExistingData)
            if (checkExistingData.length > 0) {
                alert('This username taken, try another')
            } else {
                //userName
                let userId = 0// await SaveDataExpress('delivery_management', { ...finalData })
                if (agentState.agentImage) {
                   // await UploadImageAndUpdateDataExpress(agentState.agentImage, 'delivery_management', 'userImageName', userId, 'userimages')
                }
                myNavigate('/admin/deliveryagent/agentlist')
                //alert('Data Saved')
            }
        } else {
            setErrState({ fullNameerr, userNameerr, emailerr, phoneerr, abouterr, imgerr })
            console.log('data not confirmed')
        }
        // console.log('data confirmed')
    }
    function ValidateFunction(field, message) {
        let text = ""
        if (!agentState[field]) {
            text = message + "is Required"
        }
        return text;
    }

    const marginTop = {
        marginTop: '10px'
    }
    return (
        <>
            <section style={marginTop} className={`${AddnewAgentCss.salesMain} ${AddnewAgentCss.deliveryAgent}`}>
                <div className={AddnewAgentCss.innerSale}>
                    <h2 className={AddnewAgentCss.saleHeading}>Profile Information</h2>
                    <div className={AddnewAgentCss.searchSection}>
                        <div className={AddnewAgentCss.input_section}>
                            <MyInput labelText="Full Name" type='text' inputName='fullName' placeholderText='Agent full name' onDataChange={onDataChange} errmessage={errState?.fullNameerr} />
                        </div>
                        <div className={AddnewAgentCss.input_section}>
                            <MyInput labelText="User Name" type='text' inputName='userName' placeholderText='agent user name' onDataChange={onDataChange} errmessage={errState?.userNameerr} />     
                        </div>
                        <div className={AddnewAgentCss.input_section}>
                            <MyInput labelText="Email" type='email' inputName='email' placeholderText='agent user name' onDataChange={onDataChange} errmessage={errState?.emailerr} />
                        </div>
                        
                        <div className={AddnewAgentCss.input_section}>
                             <MyInput labelText="Phone" type='text' inputName='phone' placeholderText='agent user name' onDataChange={onDataChange} errmessage={errState?.phoneerr} />
                        </div>
                       
                    </div>
                        <div className={AddnewAgentCss.textandimage}>
                            <div className={AddnewAgentCss.mTextArea}>
                                <label>About</label>
                                <textarea name='about' rows="5" cols="50" onChange={onDataChange}></textarea>
                                {errState?.abouterr ? <p className={AddnewAgentCss.WarningText}>{errState.abouterr}</p> : ""}
                            </div>
                            <div className={AddnewAgentCss.input_section}>
                                <MyInput labelText="Image Uploded*" type='file' inputName='agentImage' onDataChange={onDataChange} />
                                <img src={agentState?.fakeURL ? agentState?.fakeURL : DummyImage} alt="agent image here" />
                            {errState?.imgerr ? <p className={AddnewAgentCss.WarningText}>{errState.imgerr}</p> : ""}
                            </div>
                            <div className={AddnewAgentCss.buttonWrapper}>
                                <button onClick={onSubmitClick} className=''>Submit</button>
                             </div>
                        </div>
                      
                </div>
              
            </section>
        </>
    )
}

export default AddnewAgent

const MyInput = ({ labelText, type, inputName, placeholderText, myValue, onDataChange, errmessage }) => {
    const diplayBlock = {
        'display': 'block'
    }
    return (
        <>
           
                <label style={diplayBlock}>{labelText}</label>
                <input type={type} name={inputName} placeholder={placeholderText} value={myValue} onChange={onDataChange} />
                {errmessage ? <p className={AddnewAgentCss.WarningText}>{errmessage}</p> : ''}
          
        </>
    )
}

