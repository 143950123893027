import React from "react";
import manageCss from "./css/managebannerpop.module.css";
import { API_LINK } from "../../api/APILink";
import { UpdateBannerData } from "../../api/SettingApi";
import { toast } from "react-toastify";

export default function ManageBannersPop({ popUp, setPopUp }) {
  async function onUpdateData(e) {
    e.preventDefault();
    const name = e.target.name.value;
    const alter_text = e.target.alter_text.value;
    const banner_url = e.target.banner_url.value;
    const imageNo = e.target.imageNo.value;
    const sectionNo = e.target.sectionNo.value;
    const banner_image = e.target.banner_image.files;
    let finalData = {
      name,
      alter_text,
      banner_url,
      imageNo,
      sectionNo,
      active: 0,
    };
    try {
      await UpdateBannerData(popUp.id, { ...finalData }, banner_image[0]);
      toast.success("Banner Updated Successfully.");
    } catch (error) {
      toast.error(error.message);
    }
  }
  function onCancelClick() {
    setPopUp();
  }
  return (
    <div className={manageCss.ewrapper}>
      <div className={manageCss.holder}>
        <h3>Update Banner</h3>
        <div className={manageCss.image_holder}>
          <img src={`${API_LINK}slider_images/${popUp.image_name}`} />
        </div>
        <form className={manageCss.my_form} onSubmit={onUpdateData}>
          <div className={manageCss.common}>
            <label>Name *</label>
            <input
              name="name"
              type="text"
              placeholder="banner name"
              required
              defaultValue={popUp.name}
            />
          </div>
          <div className={manageCss.common}>
            <label>URL *</label>
            <input
              name="banner_url"
              type="text"
              placeholder="banner url here"
              required
              defaultValue={popUp.banner_url}
            />
          </div>
          <div className={manageCss.common}>
            <label>Alter Text *</label>
            <input
              name="alter_text"
              type="text"
              placeholder="image alter here"
              required
              defaultValue={popUp.alter_text}
            />
          </div>
          <div className={manageCss.common}>
            <label>Banner Image *</label>
            <input type="file" name="banner_image" />
          </div>

          <div className={manageCss.common}>
            <label>Section Name</label>
            <select name="sectionNo" required defaultValue={popUp.sectionNo}>
              <option value={""}>Select Section</option>
              <option value={1}>Section One</option>
              <option value={2}>Section Two</option>
              <option value={3}>Section Three</option>
              <option value={4}>Section Four</option>
              <option value={5}>Section Five</option>
            </select>
          </div>

          <div className={manageCss.common}>
            <label>Image No</label>
            <select name="imageNo" required defaultValue={popUp.imageNo}>
              <option value={""}>Select One</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
            </select>
          </div>
          <div className={manageCss.buttonWrapper}>
            <button onClick={onCancelClick} type="button">
              Cancel
            </button>
            <button>Update</button>
          </div>
        </form>
      </div>
    </div>
  );
}
