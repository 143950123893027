import LogintoDashboardCss from "./css/LogintoDashboard.module.css";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginBG from "../../assets/loginbgok.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faUser } from "@fortawesome/free-solid-svg-icons";
import CryptoJS from "crypto-js";
import { ENCRYPTION_KEY } from "../../api/APILink";
import { LoginUser } from "../../api/BackendUserApi";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLogedIn,
  setIsLogedIn,
  setUserInfo,
} from "../../ReduxFiles/Slices/UserSlice";
import { setCookie } from "cookies-next";
import { getLocalUser } from "../../utils/getLocalUser";

const LogintoDashboard = () => {
  const isLogedIn = useSelector(selectIsLogedIn);
  const myDispatch = useDispatch();
  const myNavigation = useNavigate();
  const [alertMessage, setAlertMessage] = useState();
  const [logingText, setLoginText] = useState({
    username: "",
    password: "",
  });
  function onDataChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    setLoginText({ ...logingText, [name]: value });
  }

  function onEnterPress(e) {
    if (e.key === "Enter") {
      LoginClick(e);
    }
  }
  const expirationDate = new Date();

  async function LoginClick(e) {
    e?.preventDefault();
    let username = logingText.username;
    let password = logingText.password;
    if (!username || !password) {
      toast.warn("username & password required");
      //alert('user name & password required')
    } else {
      const userData = await LoginUser({ username, password });
      if (userData.isMatch) {
        expirationDate.setTime(expirationDate.getTime() + 1440 * 60 * 1000); //1 day in minutes
        const toastId = toast.loading("Checking User! please Wait...");
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(userData.userData[0]),
          ENCRYPTION_KEY
        ).toString();
        myDispatch(setUserInfo(userData.userData[0]));
        myDispatch(setIsLogedIn(true));
        setCookie("anyase_admin_user", encryptedData, {
          expires: expirationDate,
        });
        myNavigation("/");
        toast.update(toastId, {
          render: "User Logedin! Welcome to anayase.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.warn(userData.message);
      }
    }
  }

  useEffect(() => {
    getLocalUser(myDispatch);
  }, [myDispatch]);
  if (isLogedIn) {
    myNavigation("/");
  }
  return (
    <>
      {alertMessage ? (
        <AlertMessage
          message={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      ) : (
        ""
      )}
      <section className={LogintoDashboardCss.login}>
        {/* <CheckLogin /> */}

        <div className={LogintoDashboardCss.container}>
          <h3>Welcome to {process.env.REACT_APP_SITE_NAME}</h3>
          <form className={LogintoDashboardCss.main} onSubmit={LoginClick}>
            <span>Login to Continue</span>
            <div className={LogintoDashboardCss.wrapper}>
              <div className={LogintoDashboardCss.input_container}>
                <input
                  name="username"
                  type="text"
                  placeholder="Enter Username"
                  onChange={onDataChange}
                  value={logingText.username}
                  required
                />

                <FontAwesomeIcon icon={faUser} />
              </div>
              <div
                className={LogintoDashboardCss.input_container}
                onKeyUp={(e) => {
                  onEnterPress(e);
                }}
              >
                <input
                  name="password"
                  type="password"
                  placeholder="Enter Password"
                  onChange={onDataChange}
                  value={logingText.password}
                  required
                />
                <FontAwesomeIcon icon={faKey} />
              </div>
              <button>Login</button>
            </div>
          </form>
          <img src={LoginBG} />
        </div>
      </section>
    </>
  );
};

export default LogintoDashboard;

const AlertMessage = ({ message, setAlertMessage }) => {
  function alertClick(e) {
    // console.log(e)
    setAlertMessage();
  }
  const zindex = {
    zIndex: 1000,
  };
  return (
    <div style={zindex} className="AlertMessage">
      <h2>{message}</h2>
      <button onClick={alertClick}>OK</button>
    </div>
  );
};
