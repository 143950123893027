import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { selectUserInfo } from '../../ReduxFiles/Slices/UserSlice'
import { fetchAllVariance, selectAllVariance, selectVarianceStatus } from '../../ReduxFiles/Slices/VarianceSlice'
import { SaveSummeryData } from '../../api/OrderInformationApi'
import { GetDataToReturn, GetSingleOrderItems, SaveSalesReturnData, UpdateOrderData } from '../../api/SalesApi'
import salesReturnCss from './salesCss/SalesReturn.module.css'

const SalesReturn = () => {
    const userInfo = useSelector(selectUserInfo)
    const myNavigate = useNavigate()
    const [mainPo, setMainPo] = useState()
    const [tableData, setTableData] = useState()
    const [returnQuantity, setReturnQuantity] = useState()

    function returnQuantityChange(e, preQuantity,) {
        let name = e.target.name
        let value = e.target.value
        if (value > preQuantity) {
            value = preQuantity
            setReturnQuantity({ ...returnQuantity, [name]: parseInt(value) })
        } else {
            setReturnQuantity({ ...returnQuantity, [name]: parseInt(value) })
        }

    }
    function onSearchClick(e) {
        e.preventDefault()
        const search = e.target.search.value
        searchInvoice(search)
    }


    async function searchInvoice(value) {
        let returnInvoice = await GetDataToReturn({invoice:value})
        if (returnInvoice.length > 0) {
            if (returnInvoice[0].fullReturn === 1) {
                toast.warn('Your Invoice is Fully Returned, Please Check Return History')
            } else {
                setMainPo(returnInvoice[0])
                getNestedData(returnInvoice[0])
            }
        } else {
            toast.warn('Your Invoice Number not Found')
        }
    }
    function onResetClick() {
        setMainPo()
        setTableData([])
    }

    async function getNestedData(mainData) {
        let allProducts = JSON.parse(window.localStorage.getItem('allProduct'))
        let allItem = await GetSingleOrderItems({id:mainData.id} )
        let myArr = []
        allItem?.forEach(ielement => {
            for (let index = 0; index < allProducts.length; index++) {
                const element = allProducts[index];
                if (ielement.product_id === element.id) {
                      ielement = { 
                        ...ielement,
                        name: element.en_sort_title
                    }
                    myArr.push(ielement)
                    break
                }
            }
        });
        setTableData(myArr)
    }

    async function onSubmitClick() {
        let finalArr = []
        let returnArr = []
        tableData?.forEach((item, index) => {
            let myreturnQuantity = (returnQuantity?.['returnQuantity' + index] ? returnQuantity?.['returnQuantity' + index] : 0)
            if (myreturnQuantity > 0) {
                let myreturnItem = { ...item, rQuantity: myreturnQuantity }
                delete myreturnItem.mQuantity
                returnArr.push(myreturnItem)
            }

            item = {
                ...item,
                mQuantity: item.mQuantity - myreturnQuantity
            }
            finalArr.push(item)
        })
        let mainDataID = mainPo.id
        await UpdateOrderData(mainDataID, { partialReturn: 1, status: 'Order Partial Return' })
         updateReturnArray(returnArr, mainDataID)
        
    }

    async function updateReturnArray(returnArr, mainID) {

        returnArr?.map(async function (item) {
            let returnSingleData = {
                sales_id: mainID,
                product_id: item.product_id,
                hasVriance: item.hasVriance ? item.hasVriance : 0,
                variance_id: item.variance_id,
                price: item.price,
                sale_item_id: item.sales_id,
                quantity: item.rQuantity,
                created_date: new Date().getTime()
            }
          await SaveSalesReturnData(returnSingleData)
        })

        let summeryData = {
            sale_id: mainID,
            ProssesedBy: userInfo.id,
            activity: 'Order Partial Return',
            created_date: new Date().getTime(),
        }
        await SaveSummeryData(summeryData)
        myNavigate('/sales/return-history')
    }
   
    async function onWholePoCancel() {
        let mainID = mainPo.id
        tableData.map(async function (item) {
            let returnSingleData = {
                sales_id: mainID,
                product_id: item.product_id,
                hasVriance: item.hasVriance ? item.hasVriance : 0,
                variance_id: item.variance_id,
                price: item.price,
                sale_item_id: item.sales_id,
                quantity: item.quantity,
                created_date: new Date().getTime()
            }
            await SaveSalesReturnData(returnSingleData)
        })
        let summeryData = {
            sale_id: mainID,
            ProssesedBy:  userInfo.id,
            activity: 'Order Full Return',
            created_date: new Date().getTime(),
        }
        await SaveSummeryData(summeryData)
        await UpdateOrderData(mainID, { fullReturn: 1, status: 'Order Full Return', packaging: 0, deliveryComplete: 0 })
        myNavigate('/sales/return-history')
    }
    function checkClick() {

    }
    function getReturnAmmount() {
        let total = 0
        tableData?.forEach((item, index) => {
            total += item.price * (returnQuantity?.['returnQuantity' + index] ? returnQuantity?.['returnQuantity' + index] : 0)
        })

        return total
    }
    const myAllVariance = useSelector(selectAllVariance)
    const varinaceStatus = useSelector(selectVarianceStatus)
    const dispatch = useDispatch()
    useEffect(() => {
        // console.log(varinaceStatus);
         if (varinaceStatus === 'idle') {
            dispatch(fetchAllVariance())
         }
    }, [])

    return (
        <section className={salesReturnCss.wrapper}>
               
                  <div className={salesReturnCss.holder}>
                  <h2>Sales Return</h2>
            <form className={salesReturnCss.filter_section} onSubmit={onSearchClick}>
                <input name='search' type="search" placeholder="Search Invoice ID Here..." autoFocus={true} required />
                <button type='submit'>Search</button>
                <button type='button' onClick={onResetClick}>Reset</button>

            </form>
            <div className={salesReturnCss.return_item}>
                <div className={salesReturnCss.table_wrapper}>
                    <table>
                        <tbody>
                            <tr>
                                <th>Item Name</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Return Quantity</th>
                                <th>Total</th>
                                <th>Return Ammount </th>
                            </tr>
                            {tableData?.map((item, index) => {
                                return <tr key={index}>
                                    <td>{item.name}
                                        {item.hasVriance ? <> - {
                                            myAllVariance?.filter((fitem) => {
                                                return fitem.id === item.variance_id ? fitem : ''
                                            })
                                                .map((finalItem) => {

                                                    return <span key={item.variance_id}>{finalItem.Variance_name}</span>
                                                    //item.variance_id
                                                })
                                        }
                                        </> : ''}
                                    </td>
                                    <td>{item.price}</td>
                                    <td>{item.quantity}</td>
                                    <td>
                                        <input style={{ maxWidth: '50px' }} name={'returnQuantity' + index} type='number' placeholder='enter quantity' onChange={(e) => { returnQuantityChange(e, item.quantity, item.price) }}
                                            value={returnQuantity?.['returnQuantity' + index] ? returnQuantity?.['returnQuantity' + index] : '0'}
                                            onFocus={(e) => { e.target.select() }} />
                                    </td>

                                    <td>{item.quantity * item.price}</td>
                                    <td>{(returnQuantity?.['returnQuantity' + index] ? returnQuantity?.['returnQuantity' + index] : 0) * item.price}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                    <div className={salesReturnCss.return_input}>
                        <div className={salesReturnCss.common}>
                            <label>Return Product Ammount*</label>
                            <input type='text' placeholder='returnig ammount' value={getReturnAmmount()} disabled />
                        </div>
                    </div>
                </div>
                <div className={salesReturnCss.info_section}>
                    <p className={salesReturnCss.highlight} onClick={checkClick}>Invoice Details</p>
                    <div className={salesReturnCss.common}>
                        <p>Invocie No : </p>
                        <p>#{mainPo?.id}</p>
                    </div>
                    <div className={salesReturnCss.common}>
                        <p>Invoice Ammount : </p>
                        <p>৳ {mainPo?.grandTotal} </p>
                    </div>
                    <div className={salesReturnCss.common}>
                        <p>Now Returning : </p>
                        <p>{getReturnAmmount()}</p>
                    </div>
                    <div className={salesReturnCss.common}>
                        <p>Discount : </p>
                        <p>{mainPo?.discountAmmount}</p>
                    </div>
                </div>

            </div>
            <div className={salesReturnCss.button_section}>
                {mainPo ? <>
                    <button onClick={onWholePoCancel}>Return Whole Order</button>
                <button onClick={onSubmitClick}>Submit</button>
                </>:<h3>Select an order to return</h3>}
                
            </div>
        </div>
        </section>
    )
}

export default SalesReturn
