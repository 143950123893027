import React from 'react'
import editCss from './edit.module.css'
import { BogoGroupUpdate } from '../../../../../api/OrderInformationApi'
import { toast } from 'react-toastify'


const BogoListEditpop = ({popUp, setPopUp}) => {
    
  async  function onUpdateGroup(e) {
        e.preventDefault()
        const group_name = e.target.group_name.value
        const start_date = e.target.start_date.value
        const end_date = e.target.end_date.value
        const id = popUp.bogo_group_id
        try {
            await BogoGroupUpdate(id, {group_name, start_date, end_date})
            toast.success('Data Updated Successfully!')
            setPopUp()
            document.body.style.overflow = 'auto'
        } catch (error) {
           toast.error(error.message) 
        }
        
    }
  return (
    <div className={editCss.wrapper}>
        <div className={editCss.holder}>
            <h3>Update - {popUp.group_name} </h3>
            <form className={editCss.my_form} onSubmit={onUpdateGroup}>
                <div className={editCss.inner_form}>
                    <label>Group Name</label>
                    <input type='text' name='group_name' required placeholder='enter group name' defaultValue={popUp.group_name}/>
                </div>
                <div className={editCss.inner_form}>
                    <label>Start Date</label>
                    <input type='date' name='start_date' required defaultValue={popUp.start_date.split('T')[0]}/>
                </div>
                <div className={editCss.inner_form}>
                    <label>End Date</label>
                    <input type='date' name='end_date' required defaultValue={popUp.end_date.split('T')[0]}/>
                </div>
                  <div className={editCss.button_wrapper}>
                      <button onClick={()=>{setPopUp(); document.body.style.overflow = 'auto'}} type='button'>Close</button>
                      <button type='submit'>Update Group</button>
                  </div>
            </form>
        </div>
    </div>
  )
}

export default BogoListEditpop