import MyAxios from 'axios'
import { API_LINK } from './APILink'
const MY_API_LINK = `${API_LINK}product-age/`

const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}

export async function SaveProductAge(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetAllAgeValue() {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}age-value/all`)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function GetFilterProductAge(query) {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}filter/${query}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function UpdateProductAge(id, data) {
    try {
        let result = await MyAxios.put(`${MY_API_LINK}${id}`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function DeleteAge(id) {
    try {
        let result = await MyAxios.delete(`${MY_API_LINK}${id}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
