import React, { useEffect, useState } from 'react'
import { DeleteAge, GetAllAgeValue, GetFilterProductAge, SaveProductAge } from '../../../api/ProductAgeApi'
import ageCss from './age.module.css'
import Select from 'react-select'
import { RiDeleteBin6Line } from "react-icons/ri";

async function getAgeData(pid, setData, setAttData) {
    let mattData = await GetAllAgeValue()
    let okageData = await GetFilterProductAge(`product_id = ${pid}`)
    setAttData(mattData)
    let mArr = []
    //  console.log(mattData, okageData )
    okageData.forEach(element => {
        for (let index = 0; index < mattData.length; index++) {
            let melement = mattData[index];
            if (melement.id === element.age_id) {
                melement = {
                    ...melement,
                    item_id: element.id
                }
                mArr.push(melement)
            }
        }
    });
    setData(mArr)
}

export default function AgePop({popUp, setPopUp}) {
    const [attData, setAttData] = useState()
    const [addedValue, setAddedValue] = useState([])



    function onAgeValueChange(e) {
        let myData = [...addedValue]
        let value = e.target.value
        //   let name = e.target.name
        //     console.log(name)
        attData.forEach(element => {
            //   console.log(element)
            let dataFound = false
            if (element.id === parseInt(value)) {
                if (addedValue.length > 0) {
                    for (let index = 0; index < myData.length; index++) {
                        const melement = addedValue[index];
                        if (melement.id === element.id) {
                            dataFound = true
                            break
                        }
                    }
                }
                if (!dataFound) {
                    myData.push(element)
                } else {
                    alert('Age Value Already Exist on this list')
                }
            }

        });
        setAddedValue(myData)
    }

    function onDeleteClick(item, index) {
        let tableData = []
        addedValue.forEach(async (element, mindex) => {
            if (mindex === index) {
                if (element.item_id) {
                    await DeleteAge(element.item_id)
                }
            } else {
                tableData.push(element)
            }
        })
        setAddedValue(tableData)
    }

    async function onSaveClick() {
        let myPromises = addedValue.map(async function (item) {
            let finalItem = {
                age_id: item.id,
                product_id: popUp.id,
                created_date: new Date().getTime()
            }
            if (!item.item_id) {
                await SaveProductAge(finalItem)
            }
        })
        Promise.all(myPromises).then(() => {
            setPopUp()
        })
    }

    useEffect(() => {
        getAgeData(popUp.id, setAddedValue, setAttData)
    }, [popUp])
  return (
    <div className={ageCss.wrapper}>
    <div className={ageCss.holder}>
    <h2>Pealse Manage Age Value Here</h2>
    <div className={ageCss.age_select}>
        <label>Age Name : </label>
        <Select options={attData || []}
        getOptionValue={(option)=>`${option.id}`}
        getOptionLabel={(option)=>`${option.attr_value_name}`}
        onChange={onAgeValueChange}
        maxMenuHeight={150}
        className={ageCss.my_select}
        />
    </div>
    <div className={ageCss.table_wrapper}>
        <table>
            <thead>
                <tr >
                    <th>sl</th>
                    <th>name</th>
                    <th>atcion</th>
                </tr>
            </thead>
            <tbody>
            {addedValue?.map((item, index) => {
                    return <tr>
                        <td>{index + 1}</td>
                        <td>{item.attr_value_name}</td>
                        <td><button onClick={() => { onDeleteClick(item, index) }} className={ageCss.Lisdelete_button}><RiDeleteBin6Line /></button></td>
                    </tr>
                })}
            </tbody>
        </table>
        </div>
    <div className={ageCss.button_section}>
        <button onClick={() => { setPopUp(); document.body.style.overflow = 'auto' }} className={ageCss.close_button}>close</button>
        <button onClick={onSaveClick} className={ageCss.age_save}>Save</button>
    </div>
    </div>
</div>
  )
}
