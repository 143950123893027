import { faCircleCheck, faCircle} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import CreditVoucherCustomer from './CreditVoucherCustomer'
import CreditVoucnerSupplier from './CreditVoucnerSupplier'
import CreditVoucherCss from './Css/CreditVoucher.module.css'
const CreditVoucher = () => {
    const [selectedType, setSelectedType] = useState('customer')

    function onTypeClick(text) {
        setSelectedType(text)
    }

    return (
        <>
        <section className={CreditVoucherCss.heading}>
            <div className={`${CreditVoucherCss.inner_headign} ${CreditVoucherCss.credit_voucher}`}>
            <div className={`${CreditVoucherCss.inner_headign} ${CreditVoucherCss.credit_voucher}`}>
                    <h2 className={CreditVoucherCss.headign_h2}>Credit Voucher</h2>
                    <div className={`${CreditVoucherCss.input_section} ${CreditVoucherCss.selection_section}`}>
                        <label>Select Type : </label>
                        <div className={CreditVoucherCss.icon_sec}>
                            {selectedType === 'customer' ? <span><FontAwesomeIcon className={CreditVoucherCss.color_green} icon={faCircleCheck} /> <p>Customer</p></span> : <span onClick={() => { onTypeClick('customer') }}><FontAwesomeIcon icon={faCircle} /> <p>Customer</p></span>}
                        </div>
                        <div className={CreditVoucherCss.icon_sec}>
                            {selectedType === 'supplier' ? <span><FontAwesomeIcon className={CreditVoucherCss.color_green} icon={faCircleCheck} /> <p>Supplier</p></span> : <span onClick={() => { onTypeClick('supplier') }}><FontAwesomeIcon icon={faCircle} /> <p>Supplier</p></span>}
                        </div>
                    </div>
                </div>
                {selectedType === 'supplier' ? <CreditVoucnerSupplier/>:''}
            {selectedType === 'customer' ? <CreditVoucherCustomer/>:''}
            </div>
          
        </section>
        </>
    )
}

export default CreditVoucher
