import React, { useEffect, useState } from 'react'
import { SavePurchaseVariance, UpdatePurchaseItemData } from '../../../api/PurchaseApi'
import { GetFilterProductVariance } from '../../../api/ProductVarianceApi'
import mvmCss from './mvm.module.css'

export default function ManageVarianceModel({data, setShowPop, tableData, setTableData}) {
    const [varState, setVarState] = useState()
    const [varQuantity, setVarQuantity] = useState(0)

    function onVarQuantityChange(e) {
        let varData = [...varState]
        let name = e.target.name
        let value = e.target.value
        let findIndex = varData.findIndex(obj => obj.id === parseInt(name))
        varData[findIndex].newQuantity = parseInt(value)
        CountQuantity(varData)
    }
    function CountQuantity(arrayData) {
        let counter = 0
        arrayData.forEach(element => {
            let elementValue = 0
            if (element.newQuantity) {
                elementValue = element.newQuantity
            } else {
                elementValue = 0
            }
            counter += parseInt(elementValue)
        });
        setVarQuantity(counter)
    }

    async function onUpdateClick() {
        if (varQuantity > data.quantity) {
            alert('variance quantity is greater then purchase quantity')
        } else if (varQuantity < data.quantity) {
            alert('variance quantity is less then purchase quantity')
        } else {
            let varData = []
            varState.forEach(element => {
                let newQuantity = element.newQuantity ? element.newQuantity : 0
                let stockAmmount = element.stockAmmount ? element.stockAmmount : 0

                if (parseInt(newQuantity) > 0) {
                    element = { ...element, stockAmmount: (parseInt(newQuantity) + parseInt(stockAmmount)) }
                    varData.push(element)

                }
            });
            let myUpdateArr = []
            tableData.forEach(element => {
                if (element.id === data.id) {
                    element = { ...element, varianceManaged: 1 }
                    myUpdateArr.push(element)
                } else {
                    myUpdateArr.push(element)
                }
            });
            setTableData(myUpdateArr)
            await UpdatePurchaseItemData(data.id, { varianceManaged: 1 })
            varData.forEach(async element => {
                let mfinalData = {
                    purchase_item_id: data.id,
                    product_id: data.product_id,
                    varinace_id: element.id,
                    quantity: element.newQuantity,
                    price: data.price,
                    created_date: new Date().getTime(),
                }
                await SavePurchaseVariance(mfinalData)
            });
            setShowPop(false)
        }
    }
    function CloseMainpPop() {
        setShowPop(false)
    }
  

    useEffect(() => {
        async function getVarianceList() {
            let varianceData = await GetFilterProductVariance(`product_id = ${data.product_id}`)
            setVarState(varianceData)
        }
        getVarianceList()
    }, [data])


  return (
    <div className={mvmCss.wrapper}>
        <div className={mvmCss.holder}>
        <h3 className={mvmCss.varTitle}>Product : {data?.name}</h3>
        <div className={mvmCss.info}>
        <span>(Order Quantity : {data?.quantity})</span> <span>[Variance Quantity : {varQuantity ? varQuantity : 0}]</span>
        </div>
        <div className={mvmCss.table_wrapper}>
            <table>
                <tbody>
                    <tr >
                        <th>Variant</th>
                        <th>Variaance Info</th>
                        <th>Quantity</th>
                    </tr>

                    {varState?.map((item, index) => {
                        return <tr key={index + 10}>
                            <td>{item.Variance_name}</td>
                            <td>{item.selectedVarUnit}</td>
                            <td>{<input className={mvmCss.table_input} name={item.id} type='number' onChange={onVarQuantityChange} value={item.newQuantity ? item.newQuantity : 0} />}</td>
                        </tr>
                    })}

                </tbody>
            </table>
            <div className={mvmCss.button_group}>
            <button onClick={onUpdateClick}>Update</button>
            <button onClick={CloseMainpPop}>close</button>
            {/* <button onClick={checkClick}>Check</button> */}
        </div>
        </div>
       
        </div>
    </div>
  )
}
