// utils/getLocalUser.js
import { getCookie } from "cookies-next";
import CryptoJS from "crypto-js";
import { setIsLogedIn, setUserInfo } from "../ReduxFiles/Slices/UserSlice";
import { ENCRYPTION_KEY } from "../api/APILink";

export function getLocalUser(dispatch) {
  const userCookie = getCookie("anyase_admin_user");

  if (userCookie) {
    const decryptedData = CryptoJS.AES.decrypt(
      userCookie,
      ENCRYPTION_KEY
    ).toString(CryptoJS.enc.Utf8);
    const parsedData = JSON.parse(decryptedData);

    if (parsedData) {
      dispatch(setUserInfo(parsedData)); // Dispatch to set user info in Redux
      dispatch(setIsLogedIn(true)); // Dispatch to set login status in Redux
    }
  }
}
