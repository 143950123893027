import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GetAllDataExpress, GetDataPassQuery } from '../../../api/ExpressFunctions';
import { GetEndDate, GetStartDate } from '../../MyFunctions/MyFunctions';
import { HeaderFooterWrapper } from '../HeadetFooter';
import AtaGlanceReportPDFCss from './css/AtaGlanceReportPDF.module.css'

const AtaGlanceReportPDF = ({ heading }) => {

    const [brandData, setBrandData] = useState()
    let [searchParams, setSearchParams] = useSearchParams();
    async function getDataformDatabase(params) {
        let myBrandData = await GetAllDataExpress('brand_list')
        let sortArr = myBrandData.sort((a, b) => (a.BrandName > b.BrandName) ? 1 : -1)
        //  console.log(sortArr)
        // myOkBrand = myBrandData
        setBrandData(sortArr)
        getSalesAllData(myBrandData)
    }

    async function getSalesAllData(myBrandData) {
        // console.log(myBrandData)
        let startDate = searchParams.get('start_date')
        let endDate = searchParams.get('end_date')
        let SalesData = []
        let cashData = []
        let SSLokData = []
        if (!startDate) {
            let salesQuery = `SELECT * FROM sales WHERE deliveryComplete = 1`
            SalesData = await GetDataPassQuery(salesQuery)
            let cashQuery = `SELECT * FROM credit_voucher WHERE particulars = 'Sales' AND transactionType = 'cash'`
            cashData = await GetDataPassQuery(cashQuery)
        } else {
            let okData  = [] // await DatawithTimeBoundary('sales', 'createdDate', myStartDate, myEndDate, 'salesID')
            let cashOkData  =  [] //await DatawithTimeBoundary('cashinhand', 'createdDate', myStartDate, myEndDate, 'salesID')
        //    SSLokData  = await DatawithTimeBoundary('SSLData', 'createdDate', myStartDate, myEndDate, 'sslID')
           okData.forEach(element => {
                if (element.deliveryComplete) {
                    SalesData.push(element)
                }
           });

          //  console.log(2) discountPercent
        }
        let allItems = []
        let returnItems = []
        let myPromises = SalesData.map(async function (item) { 
            let salesItemQuery = `SELECT sitem.*, sale.discountPercent, sale.deliveryCharge FROM sale_items sitem LEFT JOIN sales sale ON sitem.sales_id = sale.id WHERE sale.id=${item.id}`
            let salesReturnQuery = `SELECT sritem.*, sale.discountPercent FROM sales_return_items sritem LEFT JOIN sales sale ON sritem.sales_id = sale.id WHERE sale.id = ${item.id}`
            // console.log(1)
            let salesitem = await GetDataPassQuery(salesItemQuery)
             let saleReturnitem = await GetDataPassQuery(salesReturnQuery)
             returnItems.push(...saleReturnitem)
             allItems.push(...salesitem)
        })

         cashDataArranger(cashData)
         TotalDeliveryCharger(SalesData)
        Promise.all(myPromises).then(() => {
            todayData(allItems, SalesData, myBrandData, returnItems)
           
        })
    }
    useEffect(() => {
        getDataformDatabase()
    }, [])

    const [nowData, setNowData] = useState()
    const [preData, setPreData] = useState()
    const [nowDataPercent, setNowDataPercent] = useState()
    const [preDataPercent, setPreDataPercernt] = useState()
    const [nowCashData, setNowCashData] = useState()
    const [preCashData, setPreCashData] = useState()
    const [nowSSLData, setNowSSLData] = useState(0)
    const [preSSLData, setPreSSLData] = useState(0)

    const [nowDeliveryCharge, setDeliveryCharge] = useState(0)
    const [preDeliveryCharge, setPreDeliveryCharge] = useState(0)
  
    function cashDataArranger(dataArr) {
      // console.log(dataArr) 
        let mendDate = searchParams.get('end_date')
        let todayTime = ''
        if (mendDate) {
            todayTime = new Date(mendDate).toLocaleDateString()
        } else {
            todayTime = new Date().toLocaleDateString()
        }

        let startDate = new Date(GetStartDate(todayTime)).getTime()
        let endDate = new Date(GetEndDate(todayTime)).getTime() 
        // console.log(endDate, startDate, itemsArr) 
        // console.log(itemsArr)
        let toadayArr = []
        let previousArr = []
        dataArr?.forEach(element => {
            if (element.created_date > startDate && element.created_date < endDate) {
                toadayArr.push(element)
            } else { 
                previousArr.push(element)
            }
        });
        // console.log(toadayArr)
        let nowTotal = 0
        let preTotal = 0
        toadayArr.forEach(element => {
            nowTotal +=element.receiveAmmount
        });
        previousArr.forEach(element => {
            preTotal +=element.receiveAmmount
        });
        setNowCashData(nowTotal)
        setPreCashData(preTotal)
       
    }

    function TotalDeliveryCharger(dataArr){
        let mendDate = searchParams.get('end_date')
        let todayTime = ''
        if (mendDate) {
            todayTime = new Date(mendDate).toLocaleDateString()
        } else {
            todayTime = new Date().toLocaleDateString()
        }

        let startDate = new Date(GetStartDate(todayTime)).getTime()
        let endDate = new Date(GetEndDate(todayTime)).getTime() 
        // console.log(endDate, startDate, itemsArr) 
        // console.log(itemsArr)
        let toadayArr = []
        let previousArr = []
        dataArr?.forEach(element => {
            if (element.created_date > startDate && element.created_date < endDate) {
                toadayArr.push(element)
            } else { 
                previousArr.push(element)
            }
        });
        let nowTotalDeliveryCharge = 0
        let preTotalDeliveryCharge = 0

        toadayArr.forEach(element => {
            nowTotalDeliveryCharge +=element.deliveryCharge
        });
        previousArr.forEach(element => {
            preTotalDeliveryCharge +=element.deliveryCharge
        });

        setDeliveryCharge(nowTotalDeliveryCharge)
        setPreDeliveryCharge(preTotalDeliveryCharge)
    }
  
    function todayData(itemsArr, SalesData, myBrandData, returnItems) {
        let mendDate = searchParams.get('end_date')
        let todayTime = ''
        if (mendDate) {
            todayTime = new Date(mendDate).toLocaleDateString()
        } else {
            todayTime = new Date().toLocaleDateString()
        }
        let toadayArr = []
        let previousArr = [] 
        itemsArr?.forEach(element => {
            let serverTime = new Date(element.created_date).toLocaleDateString()
            if (serverTime ===todayTime) {
                toadayArr.push(element)
            } else { 
                previousArr.push(element)
            }
        });
        //console.log(toadayArr)
        dataArranger(toadayArr, myBrandData, setNowData, setNowDataPercent)
        dataArranger(previousArr, myBrandData, setPreData, setPreDataPercernt)
        returnData(returnItems, myBrandData)
        NetDeliveryCount(itemsArr, returnItems, myBrandData)
    }
    function dataArranger(dataArray, myBrandData, setData, setDatab) {
        let myAllProducts = JSON.parse(window.localStorage.getItem('allProduct'))
        let myArr = []
        let discountArr = []
        let tempArr = []
        myAllProducts.forEach(element => {
            dataArray.forEach(telement => { 
                if (element.id===telement.product_id) {
                    telement = {
                        ...telement,
                        brandID:element.selected_brand_id
                    }
                    tempArr.push(telement)
                }
                
            });

        })
       
        myBrandData?.forEach((melement, index) => {
            let myTotal = 0
            let myDiscont = 0
            tempArr.forEach(felement => {
                if (felement.brandID===melement.id) {
                    myTotal += felement.price*felement.quantity
                    myDiscont += ((parseFloat(felement.price) * felement.quantity)*felement.discountPercent)/100
                }
            });
            myArr.push(myTotal)
            discountArr.push(myDiscont)
        });
        setData(myArr)
        setDatab(discountArr)
       // return myArr
    }
    // return section starts here
    const [nowReturnData, setNowReturnData] = useState()
    const [preReturnData, setPreReturnData] = useState()
    const [nowReturnDataPercent, setNowReturnDataPercent] = useState()
    const [preReturnDataPercent, setPreReturnDataPercent] = useState()

    function returnData(itemsArr, myBrandData, setDatab) {
        let mendDate = searchParams.get('end_date')
        let todayTime = ''
        if (mendDate) {
            todayTime = new Date(mendDate).toLocaleDateString()
        } else {
            todayTime = new Date().toLocaleDateString()
        }
        let toadayArr = []
        let previousArr = [] 
        itemsArr?.forEach(element => {
            let serverTime = new Date(element.created_date).toLocaleDateString()
            if (serverTime ===todayTime) {
                toadayArr.push(element)
            } else { 
                previousArr.push(element)
            }
        });
       // console.log(toadayArr)
       dataArrangerReturn(toadayArr, myBrandData, setNowReturnData, setNowReturnDataPercent)
       dataArrangerReturn(previousArr, myBrandData, setPreReturnData, setPreReturnDataPercent)
    }
    function dataArrangerReturn(dataArray, myBrandData, setData, setDatab) {
        let myAllProducts = JSON.parse(window.localStorage.getItem('allProduct'))
        let myArr = []
        let discountArr = []
        let tempArr = []
        myAllProducts.forEach(element => {
            dataArray.forEach(telement => { 
                if (element.id===telement.product_id) {
                    telement = {
                        ...telement,
                        brandID:element.selected_brand_id
                    }
                    tempArr.push(telement)
                }
                
            });

        })
       
        myBrandData?.forEach((melement, index) => {
            let myTotal = 0
            let myDiscont = 0
            tempArr.forEach(felement => {
                if (felement.brandID===melement.id) {
                    myTotal += felement.price*felement.quantity
                    myDiscont += ((parseFloat(felement.price) * felement.quantity)*felement.discountPercent)/100
                }
            });
            myArr.push(myTotal)
            discountArr.push(myDiscont)
        });
        //console.log(myArr)
        setData(myArr)
       if (setDatab) {
        setDatab(discountArr)
       }
       
    }

    const [afterReturnNetToday, setAfterReturnNetToday] = useState()
    const [afterReturnNetPre, setAfterReturnNetPre] = useState()

    function NetDeliveryCount(SalesData, returnItems, myBrandData) {
        // console.log(SalesData, returnItems, myBrandData)
        let mendDate = searchParams.get('end_date')
        let todayTime = ''
        if (mendDate) {
            todayTime = new Date(mendDate).toLocaleDateString()
        } else {
            todayTime = new Date().toLocaleDateString()
        }
        let toadaySaleArr = []
        let previousSaleArr = [] 
        let toadayReturnArr = []
        let previousReturnArr = [] 
        SalesData?.forEach(element => {
            let serverTime = new Date(element.created_date).toLocaleDateString()
            if (serverTime ===todayTime) {
                toadaySaleArr.push(element)
            } else { 
                previousSaleArr.push(element)
            }
        });
        returnItems?.forEach(element => {
            let serverTime = new Date(element.created_date).toLocaleDateString()
            if (serverTime ===todayTime) {
                toadayReturnArr.push(element)
            } else { 
                previousReturnArr.push(element)
            }
        });
        let SalesArrToday = dataArrangerWithReturnArr(toadaySaleArr, myBrandData)
        let SalesArrPre = dataArrangerWithReturnArr(previousSaleArr, myBrandData)
        let retunrArrToday = dataArrangerWithReturnArr(toadayReturnArr, myBrandData)
        let returnArrPre = dataArrangerWithReturnArr(previousReturnArr, myBrandData)
        let tempTotalToday = 0
        let tempTotalPre = 0
        let tempTotalTodayArr = []
        let tempTotalPreArr = []
        myBrandData?.forEach((melement, index) => {
            tempTotalToday += SalesArrToday[index] - retunrArrToday[index]
            tempTotalPre += SalesArrPre[index] - returnArrPre[index]
            tempTotalTodayArr.push(tempTotalToday)
            tempTotalPreArr.push(tempTotalPre)
            tempTotalToday = 0
            tempTotalPre = 0
        })
        setAfterReturnNetToday(tempTotalTodayArr)
        setAfterReturnNetPre(tempTotalPreArr)
        
        
    }
    function dataArrangerWithReturnArr(dataArray, myBrandData) {
        let myAllProducts = JSON.parse(window.localStorage.getItem('allProduct'))
        let myArr = []
        let tempArr = []
        myAllProducts.forEach(element => {
            dataArray.forEach(telement => { 
                if (element.id===telement.product_id) {
                    telement = {
                        ...telement,
                        brandID:element.selected_brand_id
                    }
                    tempArr.push(telement)
                }
                
            });

        })
       
        myBrandData?.forEach((melement) => {
            let myTotal = 0
            let myDiscont = 0
            tempArr.forEach(felement => {
                if (felement.brandID===melement.id) {
                    myTotal += felement.price*felement.quantity
                    myDiscont += ((parseFloat(felement.price) * felement.quantity)*felement.discountPercent)/100
                }
            });
            myArr.push(myTotal)
        });
        return myArr    
    }

    // stock info from here
    const [openingStocks, setOpeningStocks] = useState()
    const [nowStocks, setNowStcoks] = useState()
    const [openingStockp, setOpeningStockp] = useState()
    const [nowStockp, setNowStcokp] = useState()

   async function GetStockData() {
    let myBrandData = await GetAllDataExpress('brand_list')
    let myBrandArr = myBrandData.sort((a, b) => (a.BrandName > b.BrandName) ? 1 : -1)
    let purchaseQuery = `SELECT pitem.*, preturn.quantity AS rquantity, mpurchase.percent FROM purchase_items pitem LEFT JOIN purchase_return_items preturn ON pitem.purchase_id = preturn.po_id AND pitem.product_id=preturn.product_id  LEFT JOIN purchase mpurchase ON mpurchase.id = pitem.purchase_id`
    let  purchaseData = await GetDataPassQuery(purchaseQuery)

    let saleQuery = `SELECT 
    sitem.*, sritem.quantity as rquantity, sale.discountPercent, MinustTowValue(sitem.quantity, sritem.quantity) AS salefinalQuantity FROM sale_items sitem LEFT JOIN sales_return_items  sritem ON sitem.product_id = sritem.product_id LEFT JOIN test_anayase.sales sale ON sitem.sales_id = sale.id`
    let  saleData = await GetDataPassQuery(saleQuery)

    //  console.log(saleData)
    let FinalDatab =    purchaseData.map(element => {
      return  element = {
            ...element,
            finalQuantity:element.quantity-element.rquantity
        }
    });

    let FinalData = FinalDatab.map((pitem)=>{
            saleData.forEach((sitem, index)=>{
                if (sitem.product_id === pitem.product_id) {
                    pitem = {...pitem,saleQuantiy:sitem.salefinalQuantity}
                }
            })
            return pitem
    })
    
    let mendDate = searchParams.get('end_date')
        let todayTime = ''
        if (mendDate) {
            todayTime = new Date(mendDate).toLocaleDateString()
        } else {
            todayTime = new Date().toLocaleDateString()
        }
        let toadayArr = []
        let previousArr = [] 
        FinalData?.forEach(element => {
            let serverTime = new Date(element.created_date).toLocaleDateString()
            if (serverTime === todayTime) {
                toadayArr.push({...element, mdate:new Date(element.created_date).toLocaleDateString()})
            } else { 
                previousArr.push(element)
            }
        });

        setOpeningStockp(StockDataArranger(toadayArr, myBrandArr, saleData))
        setNowStcokp(StockDataArranger(previousArr, myBrandArr))
    }

    function StockDataArranger(myArray, BrandArr, saleArr) {
        let myAllProducts = JSON.parse(window.localStorage.getItem('allProduct'))
        let myArr = []
        let discountArr = []
        let tempArr = []
        myAllProducts.forEach(element => {
            myArray.forEach(telement => { 
                if (element.id===telement.product_id) {
                    telement = {
                        ...telement,
                        brandID:element.selected_brand_id
                    }
                    tempArr.push(telement)
                }
                
            });

        })
       
        BrandArr?.forEach((melement) => {
            let myTotal = 0
            let myDiscont = 0
            tempArr.forEach(felement => {
                if (felement.brandID===melement.id) {
                    myTotal += felement.price*felement.finalQuantity 
                }
            });
            myArr.push(myTotal)
            
        });
        return myArr 
                
    }

    useEffect(() => {
        GetStockData()
    }, [])

    function getColor() {
        let leftPart = document.querySelector('.sidepart')
        // console.log(leftPart)
        leftPart?.classList.add('display-none')
    }

    useEffect(() => {
        getColor()
    }, [])
   
    return (
        <>
            <HeaderFooterWrapper
            content={<>
            <h3>AT A GLANCE REPORT</h3>
            <div>
                <div className={AtaGlanceReportPDFCss.client}>
                    <div className={AtaGlanceReportPDFCss.info}>
                        {searchParams.get('start_date') ? <span className={AtaGlanceReportPDFCss.moreinfo}>Date : {searchParams.get('start_date')}  To {searchParams.get('end_date')}</span> : <span className={AtaGlanceReportPDFCss.moreinfo}> Date : All Time</span>}
                    </div>
                </div>
                <div className={AtaGlanceReportPDFCss.invoice}>

                </div>
            </div>
            <div className={`${AtaGlanceReportPDFCss.table} ${AtaGlanceReportPDFCss.sales_table}`}>
                <table className={AtaGlanceReportPDFCss.myTable}>
                    <thead className={AtaGlanceReportPDFCss.myhead}>
                        <tr>
                            <th className={AtaGlanceReportPDFCss.mainhead} colSpan={6}>At a Glance-
                                {searchParams.get('end_date') ? new Date(searchParams.get('end_date')).toLocaleDateString() : new Date().toLocaleDateString()}
                            </th>
                        </tr>
                    </thead>
                    <tbody className={AtaGlanceReportPDFCss.mybody}>
                        {/* Delivery data */}
                        <>
                            <tr>
                                <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>On Date Goods Delivery</td>
                                <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>To Date Goods Delivery</td>
                            </tr>
                            {/* {console.log(preData)} */}
                            {brandData?.map((item, index) => {
                                return <AtaGlanceTable key={index} sl={index + 1} name={item.BrandName} amount={nowData?.[index]} tname={item.BrandName} tamount={preData?.[index]+nowData?.[index]} />
                            })}
                            <TabelTotal passedArrOne={nowData} passedArrTwo={preData} messageTextOne={'Total Goods Delivery'} messageTextTwo={'Total Goods Delivery'} passedArrOnetoday={[]} passedArrTwotoday={nowData} extraData={[]} extraDataB={[]} minusReturn = {[]}/>
                        </>

                        <>
                          {/* Return data */}
                       <tr>
                            <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>On Date Goods Return</td>
                            <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>To Date Goods Return</td>
                        </tr>
                         {brandData?.map((item, index)=>{
                           return <AtaGlanceTable key={index} sl={index+1} name={item.BrandName} amount={nowReturnData?.[index]} tsl={index+1} tname={item.BrandName} tamount={preReturnData?.[index]+nowReturnData?.[index]}v/>
                        })}
                        <TabelTotal passedArrOne = {nowReturnData} passedArrTwo={preReturnData} messageTextOne = {'To Date Total Goods Return'} messageTextTwo={'To Date Total Goods Return'} passedArrTwotoday={nowReturnData} />
                        </> 
                        <>
                         {/* Net Delivery */}
                         <tr>
                                <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>On Date Net Delivery</td>
                                <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>To Date Net Delivery</td>
                            </tr>
                            {brandData?.map((item, index) => {
                                return <AtaGlanceTable key={index} sl={index + 1} name={item.BrandName} amount={afterReturnNetToday?.[index]} tsl={index + 1} tname={item.BrandName} tamount={afterReturnNetToday?.[index]+afterReturnNetPre?.[index]} />
                            })}
                            <TabelTotal passedArrOne={afterReturnNetToday} passedArrTwo={afterReturnNetPre} messageTextOne={'Total Net Delivery'} messageTextTwo={'Total Net Delivery'} passedArrTwotoday={afterReturnNetToday}/>
                        </>
                        <>
                        {/* invoice Discount */}
                        <tr>
                                <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>On Date Invoice Discount</td>
                                <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>To Date Invoice Discount</td>
                            </tr>
                            {brandData?.map((item, index) => {
                                return <AtaGlanceTable key={index} sl={index + 1} name={item.BrandName} amount={nowDataPercent?.[index]+nowReturnDataPercent?.[index]} tsl={index + 1} tname={item.BrandName} tamount={nowDataPercent?.[index]+nowReturnDataPercent?.[index]+preReturnDataPercent?.[index]+preDataPercent?.[index]} />
                            })}
                            <TabelTotal passedArrOne={nowDataPercent} passedArrTwo={preDataPercent} messageTextOne={'On Date Total Discount'} messageTextTwo={'To Date Total Discount'} passedArrOnetoday={nowReturnDataPercent} passedArrTwotoday={nowDataPercent} extraData={preReturnDataPercent}/>
                        </>
                                
                        <>
                        {/* Return Discount */}
                        <tr>
                                <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>On Date Return Invoice Discount</td>
                                <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>To Date Return Invoice Discount</td>
                            </tr>
                            {brandData?.map((item, index) => {
                                return <AtaGlanceTable key={index} sl={index + 1} name={item.BrandName} amount={nowReturnDataPercent?.[index]} tsl={index + 1} tname={item.BrandName} tamount={preReturnDataPercent?.[index]+nowReturnDataPercent?.[index]} />
                            })}
                            <TabelTotal passedArrOne={nowReturnDataPercent} passedArrTwo={preReturnDataPercent} messageTextOne={'On Date Total Return Discount'} messageTextTwo={'To Date Total Return Discount'} passedArrTwotoday={nowReturnDataPercent}/>
                        </>
                        
                         <>
                          {/* Net Discount */}
                          {/* todo  */}
                        <div className={AtaGlanceReportPDFCss.pagebreakAtAGlance}></div>
                        <tr>
                                <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>On Date Net Invoice Discount</td>
                                <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>To Date Net Invoice Discount</td>
                            </tr>
                            {brandData?.map((item, index) => {
                                return <AtaGlanceTable key={index} sl={index + 1} name={item.BrandName} amount={nowDataPercent?.[index]} tsl={index + 1} tname={item.BrandName} tamount={nowDataPercent?.[index]+preDataPercent?.[index]} />
                            })}
                            <TabelTotal passedArrOne={nowDataPercent} passedArrTwo={preDataPercent} messageTextOne={'On Date Net Total Discount'} messageTextTwo={'To Date Net Total Discount'} passedArrTwotoday={nowDataPercent} extraData={preReturnDataPercent}/>
                        </>
                        <>
                        {/* Received */}
                        <tr>
                        <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>On Date Received</td>
                         <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>To Date Received</td>
                        </tr>
                        <AtaGlanceTable sl={1} name={'On Date Total Cash Received'} amount={nowCashData} tsl={1} tname={'To Date Total Cash Received'} tamount={preCashData+nowCashData} />

                        <AtaGlanceTable sl={2} name={'On Date Total SSL Received'} amount={nowSSLData} tsl={2} tname={'To Date Total SSL Received'} tamount={nowSSLData+preSSLData} />

                        <TabelTotalSingle ondateAmount={nowCashData+nowSSLData} todateAmount={preCashData+nowCashData+nowSSLData} messageTextOne={'On Date Total Cash Received'} messageTextTwo={'To Date Total Cash Received'}/>
                        </> 
                         {/* Refund */}
                         <>
                        <tr>
                        <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>On Date Refund</td>
                         <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>To Date Refund</td>
                        </tr>
                        <AtaGlanceTable sl={1} name={'On Date Total Refund'} amount={0} tsl={1} tname={'To Date Total Refund'} tamount={0} />
                        <TabelTotalSingle ondateAmount={0+0} todateAmount={0+0+0} messageTextOne={'On Date Total Cash Refund'} messageTextTwo={'To Date Total Refund'}/>
                        </>
                        {/* Delivery Charge */}     
                        <>
                        <tr>
                        <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>On Date Delivery Charge</td>
                         <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>To Date Delivery Charge</td>
                        </tr>
                        <AtaGlanceTable sl={1} name={'On Date Total Delivery Charge'} amount={nowDeliveryCharge} tsl={1} tname={'To Date Total Delivery Charge'} tamount={preDeliveryCharge+nowDeliveryCharge} />
                        <TabelTotalSingle ondateAmount={nowDeliveryCharge} todateAmount={preDeliveryCharge+nowDeliveryCharge} messageTextOne={'On Date Total Delivery Charge'} messageTextTwo={'To Date Total Delivery Charge'}/>
                        </>

                         <>
                         {/* Stock Information MRP */}
                        <tr>
                                <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>On Date Opening Stock Value</td>
                                <td colSpan={3} className={AtaGlanceReportPDFCss.groupHead}>To Date Closing Stock Value</td>
                            </tr>
                            {brandData?.map((item, index) => {
                                return <AtaGlanceTable key={index} sl={index + 1} name={item.BrandName} amount={nowStocks?.[index]} tsl={index + 1} tname={item.BrandName} tamount={nowStocks?.[index]} />
                            })}
                            <TabelTotalStcok passedArrOne={nowStockp} passedArrTwo={openingStockp} messageTextOne={'Total Opening Stock Trade Price Value'} messageTextTwo={'Total Closing Stock Trade Price Value'} ondateSales={openingStocks} toDateSales = {nowStocks}/>
                        </>

                    </tbody>
                </table>
            </div>    
            </>}
            />
        </>
    )
}

export default AtaGlanceReportPDF

const AtaGlanceTable = ({sl, name, amount, tsl, tname, tamount}) => {
    return <>
        <tr >
            <td colSpan={3}> 
                <tr style={{ width: '100%' }} colSpan={3} >
                    <td className={AtaGlanceReportPDFCss.slno}>{sl}</td>
                    <td className={AtaGlanceReportPDFCss.brandName}>{name}</td>
                    <td className={AtaGlanceReportPDFCss.amount}>{amount}</td>
                </tr>
            </td>
            <td colSpan={3} >
                <tr style={{ width: '100%', }} colSpan={3} >
                    <td className={AtaGlanceReportPDFCss.slno}>{tsl}</td>
                    <td className={AtaGlanceReportPDFCss.brandName}>{tname}</td>
                    <td className={AtaGlanceReportPDFCss.amount}>{tamount}</td>
                </tr>
            </td>
        </tr>
    </>
}
const TabelTotal =({passedArrOne, passedArrTwo, messageTextOne, messageTextTwo, passedArrOnetoday, passedArrTwotoday, extraData, extraDataB, minusReturn})=>{
    function getTotal(passedArr) {
        let total = 0
        passedArr?.forEach(element => {
            total += element
        });
        return total
    }
    return <tr >
    <td colSpan={3}>
        <tr style={{ width: '100%', fontWeight:'bold'}} colSpan={3} >
            <td className={`${AtaGlanceReportPDFCss.slno} ${AtaGlanceReportPDFCss.slTotal}`}>{}</td>
            <td className={`${AtaGlanceReportPDFCss.brandName} ${AtaGlanceReportPDFCss.tmessage}`}>{messageTextOne}</td>
            <td className={AtaGlanceReportPDFCss.amount}>{getTotal(passedArrOne)+getTotal(passedArrOnetoday)+getTotal(extraDataB)-getTotal(minusReturn)}</td>
        </tr>
    </td>
    <td colSpan={3} >
        <tr style={{ width: '100%', fontWeight:'bold' }} colSpan={3} >
            <td className={`${AtaGlanceReportPDFCss.slno} ${AtaGlanceReportPDFCss.slTotal}`}>{}</td>
            <td className={`${AtaGlanceReportPDFCss.brandName} ${AtaGlanceReportPDFCss.tmessage}`}>{messageTextTwo}</td>
            <td className={AtaGlanceReportPDFCss.amount}>{getTotal(passedArrTwo)+getTotal(passedArrTwotoday)+getTotal(extraData)+getTotal(passedArrOnetoday)+getTotal(extraDataB)}</td>
        </tr>
    </td>
</tr>
}
const TabelTotalStcok =({passedArrOne, passedArrTwo, messageTextOne, messageTextTwo, ondateSales, toDateSales})=>{
    function getTotal(passedArr) {
        let total = 0
        passedArr?.forEach(element => {
            total += element
        });
        return total
    }
    return <tr >
    <td colSpan={3}>
        <tr style={{ width: '100%', fontWeight:'bold'}} colSpan={3} >
            <td className={`${AtaGlanceReportPDFCss.slno} ${AtaGlanceReportPDFCss.slTotal}`}>{}</td>
            <td className={`${AtaGlanceReportPDFCss.brandName} ${AtaGlanceReportPDFCss.tmessage}`}>{messageTextOne}</td>
            <td className={AtaGlanceReportPDFCss.amount}>{(getTotal(passedArrOne)-getTotal(toDateSales))}</td>
        </tr>
    </td>
    <td colSpan={3} >
        <tr style={{ width: '100%', fontWeight:'bold' }} colSpan={3} >
            <td className={`${AtaGlanceReportPDFCss.slno} ${AtaGlanceReportPDFCss.slTotal}`}>{}</td>
            <td className={`${AtaGlanceReportPDFCss.brandName} ${AtaGlanceReportPDFCss.tmessage}`}>{messageTextTwo}</td>
            <td className={AtaGlanceReportPDFCss.amount}>{(getTotal(passedArrTwo)+getTotal(passedArrOne)-(getTotal(ondateSales)+getTotal(toDateSales)))}</td>
        </tr>
    </td>
</tr>
}
const TabelTotalSingle =({ondateAmount, todateAmount, messageTextOne, messageTextTwo,})=>{
    
    return <tr >
    <td colSpan={3}>
        <tr style={{ width: '100%', fontWeight:'bold'}} colSpan={3} >
            <td className={`${AtaGlanceReportPDFCss.slno} ${AtaGlanceReportPDFCss.slTotal}`}>{}</td>
            <td className={`${AtaGlanceReportPDFCss.brandName} ${AtaGlanceReportPDFCss.tmessage}`}>{messageTextOne}</td>
            <td className={AtaGlanceReportPDFCss.amount}>{ondateAmount}</td>
        </tr>
    
    </td>
    <td colSpan={3} >
        <tr style={{ width: '100%', fontWeight:'bold' }} colSpan={3} >
            <td className={`${AtaGlanceReportPDFCss.slno} ${AtaGlanceReportPDFCss.slTotal}`}>{}</td>
            <td className={`${AtaGlanceReportPDFCss.brandName} ${AtaGlanceReportPDFCss.tmessage}`}>{messageTextTwo}</td>
            <td className={AtaGlanceReportPDFCss.amount}>{todateAmount}</td>
        </tr>
    </td>
 
     </tr>
}