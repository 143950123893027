import React from "react";
import { Route, Routes } from "react-router-dom";
import AddNewBank from "../Banks/AddNewBank";
import BankList from "../Banks/BankList";
import ManageCheque from "../Banks/ManageCheque";
import BankBalanceList from "./AllComponent/BankBalanceList";
import BankTransactionHistory from "./AllComponent/BankTransactionHistory";
import Cash from "./AllComponent/Cash";
import CreditVoucher from "./AllComponent/CreditVoucher";
import CreditVoucherHistory from "./AllComponent/CreditVoucherHistory";
import DayBook from "./AllComponent/DayBook";
import DebitVoucher from "./AllComponent/DebitVoucher";
import DebitVoucherHistory from "./AllComponent/DebitVoucherHistory";
import Deposite from "./AllComponent/Deposite";
import SSL from "./AllComponent/SSL";
import TransictionIN from "./AllComponent/TransictionIN";
import TransictionOUT from "./AllComponent/TransictionOUT";
import Withdraw from "./AllComponent/Withdraw";

const AccountRoutes = () => {
  return (
    <Routes>
      <Route path="debit-voucher" element={<DebitVoucher />} />
      <Route path="debit-voucher-history" element={<DebitVoucherHistory />} />
      <Route path="credit-voucher" element={<CreditVoucher />} />
      <Route path="credit-voucher-history" element={<CreditVoucherHistory />} />
      <Route path="day-book" element={<DayBook />} />
      <Route path="cash" element={<Cash />} />
      <Route path="ssl" element={<SSL />} />
      <Route path="add-bank" element={<AddNewBank />} />
      <Route path="bank-list" element={<BankList />} />
      <Route path="manage-cheque" element={<ManageCheque />} />
      <Route path="bank-balance-list" element={<BankBalanceList />} />
      <Route
        path="bank-transaction-history"
        element={<BankTransactionHistory />}
      />
      <Route path="transaction-in" element={<TransictionIN />} />
      <Route path="transaction-out" element={<TransictionOUT />} />
      <Route path="cheque-in" element={<TransictionOUT />} />
      <Route path="deposite" element={<Deposite />} />
      <Route path="withdraw" element={<Withdraw />} />
    </Routes>
    //   <div>
    //   {myParams.function ==='payment' ? <Payment/>:''}
    //   {myParams.function ==='payment-history' ? <PaymentHistory/>:''}
    //   {myParams.function ==='payment-history' && myParams.action ==='print-voucher'? <VoucherCommon/>:''}
    //   {myParams.function ==='debit-voucher' ? <DebitVoucher/>:''}
    //   {myParams.function ==='debit_voucher_history' ? <DebitVoucherHistory/>:''}
    //   {myParams.function ==='view_bank_history' ? <ViewBankBook/>:''}
    //   {myParams.function ==='check_api' ? <CheckApi/>:''}
    // </div>
  );
};

export default AccountRoutes;
