import React from 'react'
import { Route, Routes } from 'react-router-dom'
import DeclinedList from './DeclinedList'
import NewPurchase from './NewPurchase'
import PendingPurchaseList from './PendingPurchaseList'
import PurchaseReturn from './PurchaseReturn'
import PurchaseReturnHistory from './PurchaseReturnHistory'
import PurhcaseHistory from './PurhcaseHistory'
import PurchaseConfirm from './PurchaseConfirm'
import HisotoryViewDetails from './HisotoryViewDetails'
import POInvoice from './POInvoice'
import StockDetails from './StockInfo/StockDetails'
import StockRequest from './StockInfo/StockRequest'

export default function PurchaseRoute() {

    return (
        <Routes>
            <Route path='/pending' element={<PendingPurchaseList />} />
            <Route path='/history' element={<PurhcaseHistory />} />
            <Route path='/new-purchase' element={<NewPurchase />} />
            <Route path='/decline' element={<DeclinedList />} />
            <Route path='/purchase-return' element={<PurchaseReturn />} />
            <Route path='/purchase-return-history' element={<PurchaseReturnHistory />} />

            <Route path='/stock-details' element={<StockDetails />} />
            <Route path='/stock-request' element={<StockRequest />} />

            {/* other routes */}
            <Route path='/variance-manage' element={<PurchaseConfirm />} />
            <Route path='/history/view' element={<HisotoryViewDetails />} />
            <Route path='/history/view/invoice' element={<POInvoice />} />

        </Routes>
    )
}
