
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import PopUpCss from './css/confirm.module.css'
const ConfirmAlert = ({yesClick, noClick, title, message}) => {

    return (
        <div className={PopUpCss.pop_wrapper}>
            <div className={PopUpCss.pop_holder}>
                {/* <ImCancelCircle className={PopUpCss.cross} /> */}
                <AiOutlineQuestionCircle className={PopUpCss.icon} />
                <h2>{title || 'Are You Sure?'}</h2>
                <p>{message || 'You cannot undo your action here! So be sure before the action.'}</p>
                <div className={PopUpCss.popup_btns}>
                    <button onClick={yesClick}>Yes</button>
                    <button onClick={noClick}>No</button>
                </div>
            </div>

        </div>
    );
};

export default ConfirmAlert;