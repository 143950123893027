import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GetAllDataExpress, GetDataPassQuery, GetFilteredDataExpress } from '../../../api/ExpressFunctions';
import { GetEndDate, GetStartDate, LocalTimeStampToDateOnly } from '../../MyFunctions/MyFunctions';
import { HeaderFooterWrapper } from '../HeadetFooter';
import ExpenseReportHeadWisePDFCss from './css/ExpenseReportHeadWisePDF.module.css'


const ExpenseReportHeadWisePDF = ({ heading }) => {
    
    const [invoiceState, setInvoiceState] = useState()
    const [headData, setHeaddata] = useState()
    let [searchParams, setSearchParams] = useSearchParams();

    async function getDataformDatabase(params) {
        let myHeadData = await GetAllDataExpress('expense_head')
        setHeaddata(myHeadData)
        let myGroupData = await GetAllDataExpress('expense_group')
        let expenseData = []
        let startDate = searchParams.get('start_date')
        let endDate = searchParams.get('end_date')
        let myHead = searchParams.get('head')
        let myStartDate = GetStartDate(startDate)
        let myEndDate = GetEndDate(endDate)
    //     myHead.push(searchParams.get('head'))
    //    console.log(myHead)
        if (startDate) {
            let mQuery = `SELECT * FROM expense WHERE created_date > ${myStartDate} AND created_date < ${myEndDate}`
            expenseData = await GetDataPassQuery(mQuery)
        }else if(myHead){
            expenseData = await GetAllDataExpress('expense')
            //console.log(expenseData)  
        }else{
            expenseData = await GetAllDataExpress('expense',)
        }
       // console.log(expenseData)
 
        let itemArr = []

        let myPromises = expenseData.map(async function (item) {
            let nestedData = await GetFilteredDataExpress('expense_items', 'expenseID', item.id)
            nestedData?.forEach(element => {
                
                if (myHead) {
                    if (parseInt(myHead) === element.expenseHeadID) {
                        element = { ...element, expenseNo: item.expenseNo }
                        itemArr.push(element) 
                    }
                }else{
//                    console.log(element)
                    element = { ...element, expenseNo: item.id }
                    itemArr.push(element) 
                }
               
            })
        })
        Promise.all(myPromises).then(() => {
            let okArr = []
            for (let index = 0; index < itemArr.length; index++) {
                let element = itemArr[index];
                // console.log(element)
                for (let index = 0; index < myGroupData?.length; index++) {
                    let gelement = myGroupData[index];
                    if (element.selectedGroup === gelement.id) {
                        element = { ...element, groupName: gelement.groupName }
                        okArr.push(element)
                    }
                }
            }
            setInvoiceState(okArr)
            // console.log(itemArr)
        })
    }
    function getAllTotal(arrayPass, identifire) {
        let total = 0
        arrayPass?.forEach(element => {
            total += element[identifire]
        });
        return total;
    }

    useEffect(() => {
        getDataformDatabase()
    }, [])
    function getColor() {
        let leftPart = document.querySelector('.sidepart')
        leftPart?.classList.add('display-none')
       // console.log(leftPart)
    }

    useEffect(() => {
        getColor()
       
    }, [])

    return (
        <>
        <HeaderFooterWrapper
        content={<>
        <h3>Expense Report Head Wise</h3>
            <div className={ExpenseReportHeadWisePDFCss.details}>
                <div className={ExpenseReportHeadWisePDFCss.client}>
                    <div className={ExpenseReportHeadWisePDFCss.info}>
                        {searchParams.get('head') ? <span className={ExpenseReportHeadWisePDFCss.moreinfo}>Head : 
                        
                        {headData?.filter((fitem)=>{
                            return searchParams.get('head') == fitem.id ? fitem:''
                        })
                        .map((item)=>{
                            return ' '+item.headName
                        })}
                        </span> : <span className={ExpenseReportHeadWisePDFCss.moreinfo}> Head : All Head</span>}
                    </div>
                    <div className={ExpenseReportHeadWisePDFCss.info}>
                        {searchParams.get('start_date') ? <span className={ExpenseReportHeadWisePDFCss.moreinfo}>Date : {searchParams.get('start_date')}  To {searchParams.get('end_date')}</span> : <span className={ExpenseReportHeadWisePDFCss.moreinfo}> Date : All Time</span>}
                    </div>
                </div>
                <div className={ExpenseReportHeadWisePDFCss.invoice}>

                </div>
            </div>
            <div className={`${ExpenseReportHeadWisePDFCss.table} ${ExpenseReportHeadWisePDFCss.sales_table}`}>
                <table>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}>Sl</th>
                            <th>Exp. Id</th>
                            <th>Date</th>
                            <th>Particulars</th>
                            <th >Expense Group</th>
                            <th >Expense Head</th>
                            <th >Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoiceState?.map((item, index) => {
                            return <tr key={index}>
                                <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                <td style={{ textAlign: 'center' }}>Ex #{item.id}</td>
                                <td style={{ textAlign: 'left' }}>{LocalTimeStampToDateOnly(item.createdDate?.seconds)}</td>
                                <td style={{ textAlign: 'left' }}>{item.particular}</td>
                                <td style={{ textAlign: 'left' }}>{item.groupName}</td>
                                <td style={{ textAlign: 'left' }}>
                                {headData?.filter((fitem)=>{
                                    return fitem.id===item.expenseHeadID ? fitem:''
                                })
                                .map((mitem)=>{
                                    return mitem.headName
                                })}</td>
                                <td style={{ textAlign: 'right' }}>{item.price}</td>
                            </tr>
                        })}
                        <tr>
                            <td colSpan={6}>Total : </td>
                            <td>{getAllTotal(invoiceState, 'price')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>}
        />
        </>
    )
}

export default ExpenseReportHeadWisePDF