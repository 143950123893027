import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { GetFilteredDataExpress } from '../../api/ExpressFunctions'
import HistoryViewCss from './css/HistoryView.module.css'

const HistoryView = () => {
    const [infoData, setInfoData] = useState()
    const [tableData, setTableData] = useState()
    const [mySearchParam, setMySearchParam] = useSearchParams()

    async function getDataFormDatabase() {
        let expenseNo = mySearchParam.get('exno')
        let myData = await GetFilteredDataExpress('expense', 'id', expenseNo)
        //  console.log(myData, expenseNo)
        let myTableData = await GetFilteredDataExpress('expense_items', 'expenseID', expenseNo)
        setInfoData(myData[0])
        setTableData(myTableData)
    }
    function onPrintClick(params) {

    }
    function onSearchClick(params) {

    }

    useEffect(() => {
        getDataFormDatabase()
    }, [])
    return (
        <>
            <section className={HistoryViewCss.salesMain}>
                <div className={HistoryViewCss.innerSale}>
                    <h2 className={HistoryViewCss.saleHeading} onClick={onSearchClick}>Expense History View</h2>
                    <div >
                        <p>Expense No : {infoData?.expenseNo}</p>
                        <p>Payment Type : {infoData?.paymentType}</p>
                        <p>Process By : {infoData?.processBy}</p>
                        <p>Expense Note : {infoData?.expenseNote}</p>

                    </div>
                    <div className={HistoryViewCss.tableSection}>
                        <table style={{ marginTop: '0' }}>
                            <tbody>
                                <tr>
                                    {/* Sl	Particulars	Note	Expense Head	Price	Action */}
                                    <th>Sl</th>
                                    <th>Particulars</th>
                                    <th>Note</th>
                                    <th>Price</th>
                                </tr>
                                {tableData?.map((item, index) => {
                                    return <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.particular}</td>
                                        <td>{item.note}</td>
                                        <td style={{ textAlign: 'right' }}>{item.price}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <div className={HistoryViewCss.tableDetails}>
                            <p>Total Amount : {infoData?.ammount}</p>
                            <p>Paid Amount : {infoData?.paymentAmmount}</p>
                            <p>Due Amount : {infoData?.ammount - infoData?.paymentAmmount}</p>
                        </div>
                    </div>


                    <div className={HistoryViewCss.button_section} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <button style={{ background: 'green' }} onClick={onPrintClick}>Print</button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HistoryView
