import React from "react";
import style from "./css/courierTable.module.css";
export default function CourierTable({ table_item }) {
  return table_item?.pathao ? (
    <div className={style.wrapper}>
      <div className={style.holder}>
        <div className={style.table_wrapper}>
          <table>
            <thead>
              <tr>
                <th>Courier</th>
                <th>Total</th>
                <th>Success</th>
                <th>Reject</th>
                <th>Success Ration</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Pathao</td>
                <td>{table_item.pathao.total_parcel}</td>
                <td>{table_item.pathao.success_parcel}</td>
                <td>{table_item.pathao.cancelled_parcel}</td>
                <td>{table_item.pathao.success_ratio}</td>
              </tr>
              <tr>
                <td>Redx</td>
                <td>{table_item.redx.total_parcel}</td>
                <td>{table_item.redx.success_parcel}</td>
                <td>{table_item.redx.cancelled_parcel}</td>
                <td>{table_item.redx.success_ratio}</td>
              </tr>
              <tr>
                <td>SteadFast</td>
                <td>{table_item.steadfast.total_parcel}</td>
                <td>{table_item.steadfast.success_parcel}</td>
                <td>{table_item.steadfast.cancelled_parcel}</td>
                <td>{table_item.steadfast.success_ratio}</td>
              </tr>
              <tr>
                <td>Paperfly</td>
                <td>{table_item.paperfly.total_parcel}</td>
                <td>{table_item.paperfly.success_parcel}</td>
                <td>{table_item.paperfly.cancelled_parcel}</td>
                <td>{table_item.paperfly.success_ratio}</td>
              </tr>
            </tbody>
          </table>
          <div className={style.final_wrapper}>
            <span>Total Parcel : {table_item.summary.total_parcel}</span>
            <span>Success Parcel : {table_item.summary.success_parcel}</span>
            <span>Reject Parcel : {table_item.summary.cancelled_parcel}</span>
          </div>
          <div className={style.progressbar}>
            <progress
              value={`${table_item.summary.success_ratio}`}
              //   value={50}
              max="100"
            ></progress>
            <small>{table_item.summary.success_ratio}% Success</small>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}
