import React, { useEffect, useState } from 'react'
import seoCss from "./seopop.module.css";
import { GetSingleSeoData, SaveProductSeoData, UpdateProductList, UpdateProductSeoData } from '../../../api/ProductListApi';
import {toast} from 'react-toastify'

async function getData(product_id, setData) {
    let data = await GetSingleSeoData(product_id)
    setData(data?.[0] || {})
}

const SeoPop = ({popData, setPopData}) => {
    const [seoData, setSeoData] = useState({})
      async  function onSeoDataSubmit(e) {
            e.preventDefault()
            const metatitle = e.target.metatitle.value
            const metadescription = e.target.metadescription.value
            const metakeywords = e.target.metakeywords.value
            const product_slug = e.target.product_slug.value
            // const og_image = e.target.og_image.files
            try {
                if (seoData.pseo_id) {
                    await UpdateProductSeoData(seoData.pseo_id, {product_id:popData.id, metatitle, metadescription, metakeywords})
                }else{
                    await  SaveProductSeoData({product_id:popData.id, metatitle, metadescription, metakeywords})
                }
                
                if (product_slug) {
                    if (product_slug.includes('-') || product_slug.includes('&')) {
                        toast.warn('In Product Slug Special Charecter not allowed')
                    }else{
                       let response =  await UpdateProductList(popData.id, {product_slug})
                       console.log(response);
                       if (response.errorCode) {
                            toast.warn('Duplicate Entry Found for Product Slug')
                       }else{
                        toast.success('Product Slug Update Success')
                       }
                       
                    }
                }
                toast.success('SEO Data Updated Successfully')
                setPopData()
                document.body.style.overflow = 'auto'
             
            } catch (error) {
                toast.error(error.message)
            }

     }
     useEffect(()=>{
        getData(popData.id, setSeoData)
     },[popData])


  return (
    <div className={seoCss.main}>
       <div className={seoCss.data_holder}>
       <h3>Seo Data Add</h3>
        <form onSubmit={onSeoDataSubmit}>
            <div className={seoCss.common}>
                <label>Product Slug : </label>
                <input type='text' name='product_slug' defaultValue={popData.product_slug}/>
            </div>
            <div className={seoCss.common}>
                <label>Meta Title </label>
                <input type='text' name='metatitle' defaultValue={seoData.metatitle}/>
            </div>
            <div className={seoCss.common}>
                <label>Meta Description </label>
                <input type='text' name='metadescription' defaultValue={seoData.metadescription}/>
            </div>
            <div className={seoCss.common}>
                <label>Meta Keywords </label>
                <input type='text' name='metakeywords' defaultValue={seoData.metakeywords}/>
            </div>
            <div className={seoCss.common}>
                <label>Og Image </label>
                <input type='file' name='og_image' multiple='false'/>
            </div>
            <div className={seoCss.btns}>
            <button type='submit'>update</button>
            <button onClick={()=>{setPopData(); document.body.style.overflow = 'auto'}}>Close</button>
        </div>
        </form>
       
       </div>
    </div>
  )
}

export default SeoPop