import { faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { GetAllDataExpress, GetDataPassQuery, SaveDataExpress } from '../../../api/ExpressFunctions';
import { LocalDateToTime, LocalTimeStampToDate } from '../../MyFunctions/MyFunctions';
import { AutoIncrementNumberCustom, GetSingleDoc, SaveData, SaveNestedExistingData, UpdateData, UpdateDataArray } from '../../connector/DatabaseCheck';
import DebitVouSupCss from './Css/DebitVoucherSupplier.module.css';
const DebitVoucherSupplier = () => {
    // const myNavigate = useNavigate()
    const [supplierList, setSupplierList] = useState()
    const [singleSupplier, setSingleSupplier] = useState()
    const [SearchData, setSearchData] = useState()
    const [tableData, setTableData] = useState()
    const [myCheck, setMyCheck] = useState(true)
    const [runningVoucherNo, setRunningVoucherNo] = useState()
    const [paymentInfo, setPaymentInfo] = useState({
        paymentDate: '',
        paymentMethod: 1,
        paymentAmmount: 0,
        paymentRemark: '',
        bankID:'',
        sub_method:''
    })
    const [errMessage, setErrMessage] = useState()
    const [myBankList, setMyBankList] = useState() 
async function getBankList() {
        let NumberCollection = await GetSingleDoc("NumberCollection", "NumberID")
        setRunningVoucherNo(NumberCollection.data().debitVoucherNo+1)
        let bankData = await GetAllDataExpress(`bank_list`)
        setMyBankList(bankData)
    }
    useEffect(()=>{
        getBankList()
    },[])
                                     

    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        if (name === 'paymentAmmount') {
            value = e.target.value ? parseInt(e.target.value) : ''
            if (value > getPayableDue()) {
                value = getPayableDue()
                setPaymentInfo({ ...paymentInfo, [name]: value })
            } else if (value < 0) {
                setPaymentInfo({ ...paymentInfo, [name]: 0 })
            } else {
                setPaymentInfo({ ...paymentInfo, [name]: value })
            }
        } else {
            setPaymentInfo({ ...paymentInfo, [name]: value })
        }

        if (name ==='paymentMethod') {
            //console.log(parseInt(value))
            if (parseInt(value)===1 || parseInt(value)===4) {
                setPaymentInfo({ ...paymentInfo, [name]: parseInt(value),  bankID:'',
                sub_method:'' })
            }else{
                setPaymentInfo({ ...paymentInfo, [name]: parseInt(value) })
            } 
        }
    }

function getDateTime() {
        let mydate = new Date();
        let day = mydate.getDate()
        let month = mydate.getMonth() + 1
        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }
        let year = mydate.getFullYear()
        let hour = mydate.getHours()
        if (hour < 10) {
            hour = '0' + hour
        }
        let minute = mydate.getMinutes()
        if (minute < 10) {
            minute = '0' + minute
        }
        let seconds = mydate.getSeconds()
        if (seconds < 10) {
            seconds = '0' + seconds
        }
        let finalTime = year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + seconds
        return finalTime
    }
  
    function checkClick(params) {
        
    }
    async function getSearchData() {
        setSingleSupplier()
        setPaymentInfo({
            paymentDate: '',
            paymentMethod: 1,
            paymentAmmount: 0,
            paymentRemark: '',
            bankID:'',
            sub_method:''
        })
        supplierList.forEach(element => {
            if (element.id === SearchData) {
                setSingleSupplier(element)
            }
        });
        let purchaseQuery = `SELECT * FROM purchase WHERE supplierID=${SearchData}`
        let mtableData = await GetDataPassQuery(purchaseQuery)
        let filterArr = []
        mtableData?.map((item)=>{
            if (!item.fullPaid) {
                filterArr.push(item)
            } else if(false){

            }
        })

        let returnArr = []
        let debitArr = []

       let myPromises = filterArr?.map(async function (item) {
           
            let debitQuery = `SELECT * FROM debit_voucher WHERE identity=${item.id} AND particulars='Purchase Payment'`
            let returnQuery = `SELECT * FROM purchase_return_items WHERE po_id=${item.id}` 
            let returnData = await GetDataPassQuery(returnQuery)
            let debitData = await GetDataPassQuery(debitQuery)
            returnArr.push(...returnData)
            debitArr.push(...debitData)
        })

        Promise.all(myPromises).then(()=>{
        organizeData(filterArr, returnArr, debitArr)
        })
       organizeData(filterArr)
    }
    const [allData, setAllData] = useState()
    function organizeData(myTableData, returnArr, debitArr) {
        let organizeArr = []
        myTableData?.map((item)=>{
           console.log(returnArr)
           getDiscountAmmount(item.percent, item.total)
            let mDueAmmount = item.grandTotal - debitTotal(debitArr, item.id)-getDiscountAmmount(item.percent, item.grandTotal)-Math.round(getReturnTotal(returnArr, item.percent, item.id))
            let finalData = {
                id:item.id,
                PoNo:item.id,
                date:item.created_date,
                nowPay:true,
                total:item.grandTotal,
                paidAmmount: debitTotal(debitArr, item.id),
                returnAmmount: Math.round(getReturnTotal(returnArr, item.percent, item.id)),
                totalDiscount :getDiscountAmmount(item.percent, item.grandTotal),
                supplierID:item.supplierID,
                dueAmmount: mDueAmmount
            }
            if (mDueAmmount>0) {
                organizeArr.push(finalData)
            }
            
        })
        setTableData(organizeArr)
        setAllData(organizeArr)
    }

    function debitTotal (debitArr, identity) {
         let total = 0
         debitArr?.filter((fitem)=>{
             return fitem.identity === identity ? fitem:''
         })
         .map((pitem)=>{
             total += pitem.paymentAmmount
         })
         return total
     }

    function getDiscountAmmount(percent, total){
        return Math.round(total*percent/100)
    }
    function  getReturnTotal(returnArr, discount, identity) {
         let total = 0
         returnArr?.map((ritem)=>{
           if (identity===ritem.po_id) {
            total += ritem.price*ritem.quantity
           }
             
         })
         total = total - total*(discount? discount:0)/100
        return total
     }
    function getMinusAmmount(podata){
        
        
    }

  async  function onSaveClick() {
       
        let payErr = ValidateFunction('paymentAmmount', 'Payment ')
        let remarkErr = ValidateFunction('paymentRemark', 'Remark  ')
        let dateErr = ValidateFunction('paymentDate', 'Date ')
        let bankIDErr = ''
        let sub_methodErr = ''
        if (paymentInfo.paymentMethod ===2 || paymentInfo.paymentMethod===3) {
            bankIDErr = ValidateFunction('bankID', 'Bank Name ')
            sub_methodErr = ValidateFunction('sub_method', 'Sub Method ')
        }
        setErrMessage({ ...errMessage, payErr, remarkErr, dateErr, bankIDErr, sub_methodErr })
        if (!payErr && !remarkErr && !dateErr && !bankIDErr && !sub_methodErr) {
            let paymentArr = []
            tableData.forEach(element => {
                if (element.nowPay) {
                    paymentArr.push(element)
                }
            });
          
            if (paymentArr.length > 0) {
                if (paymentInfo.paymentMethod===1) {
                    cashPayment(paymentArr)
                } else if (paymentInfo.paymentMethod===2) {
                   // onlineDeposite(paymentArr)
                } else if (paymentInfo.paymentMethod===3) {
                   // onlineDeposite(paymentArr)
                }
            } else {
                console.log('no invoice found')
            }
            
        }
    }

   async function cashPayment(paymentArr) {
        let userData = window.localStorage.getItem('userData')
        userData = JSON.parse(userData)
        let userID = window.localStorage.getItem('user_id')
        if (paymentArr.length > 1) {
            if (paymentInfo.paymentAmmount === getPayableDue()) {
                paymentArr.forEach(async(item) => {
                    let dataID = item.id
                    let debitData = {
                        identity: dataID,
                        paymentAmmount:item.dueAmmount,
                        particulars:'Purchase Payment',
                        processorID:userID,
                        remark:paymentInfo.paymentRemark,
                        paymentType : 'cash',
                        created_date:new Date().getTime()
                    }
                    let dayBookData = {
                        created_date:new Date().getTime(),
                        paticulars:"Purchase (Payment)",
                        identity:dataID,
                        received:0,
                        payment:item.dueAmmount,
                        processorID:userID,
                    }

                    let summeryData = {
                        order_date:item.date,
                        supplier_id:item.supplierID,
                        status:'PO Ammount Full Paid',
                        attemptBy:userID,
                        purchase_id:item.id,
                        created_date:new Date().getTime()
                      }                    
                    await SaveDataExpress('debit_voucher', {...debitData})
                    await SaveDataExpress('day_book', {...dayBookData})
                    await SaveDataExpress('purhcase_summery', {...summeryData})
                });

               alert('data saved')

            } else {
                console.log('you must pay full ammount')
            }
        } else {
            let fullPaid = true
            let status = ''
            let dataID = paymentArr[0].id
            let item = paymentArr[0]
            if (paymentInfo.paymentAmmount < getPayableDue()) {
                fullPaid =false
                status = 'PO Ammount Partial Paid'
            }else{
                fullPaid =true
                status = 'PO Ammount Paid'
            }

            let debitData = {
                identity: dataID,
                paymentAmmount:paymentInfo.paymentAmmount,
                particulars:'Purchase Payment',
                processorID:userID,
                remark:paymentInfo.paymentRemark,
                paymentType : 'cash',
                created_date:new Date().getTime()
            }
            let dayBookData = {
                created_date:new Date().getTime(),
                paticulars:"Purchase (Payment)",
                identity:dataID,
                received:0,
                payment:paymentInfo.paymentAmmount,
                processorID:userID,
            }

            let summeryData = {
                order_date:item.date,
                supplier_id:item.supplierID,
                status:status,
                attemptBy:userID,
                purchase_id:item.id,
                created_date:new Date().getTime()
              }                    
             console.log(debitData, dayBookData, summeryData)
            await SaveDataExpress('debit_voucher', {...debitData})
            await SaveDataExpress('day_book', {...dayBookData})
            await SaveDataExpress('purhcase_summery', {...summeryData})
          alert('data saved')
        }
    }
   async function onlineDeposite(paymentArr) {
        let userData = window.localStorage.getItem('userData')
        userData = JSON.parse(userData)
        if (paymentArr.length > 1) {
            if (paymentInfo.paymentAmmount === getPayableDue()) {
                let pono = ''
                paymentArr.forEach(async(item) => {
                    let dataID = item.id
                    //getDueAmmount(total, payment, discount, payAmmounthere)
                    let purchaseUpdate = {
                        paymentAmmount: item.dueAmmount,
                        paymentDate: LocalDateToTime(paymentInfo.paymentDate),
                        paymentRemark: paymentInfo.paymentRemark,
                        voucherNo:runningVoucherNo,
                        voucherType:1,
                        paymentMethod:methodReturn(paymentInfo.paymentMethod),
                        bankID:paymentInfo.bankID,
                        subMethod:paymentInfo.sub_method
                    }
                    //console.log(purchaseUpdate)
                    let summeryData = {
                        supplierName: singleSupplier?.companyaName,
                        status: 'PO Ammount Paid',
                        attemeptBy: userData.name,
                    }
                 await UpdateDataArray('Purchase', dataID, 'Payment', {...purchaseUpdate})
                 await UpdateData('Purchase', { fullPaid: true}, dataID)
                 await SaveNestedExistingData('Purchase', dataID, 'PoSummery', summeryData)
                });
                // loop ends

                paymentArr.map((item)=>{
                    pono += item.PoNo+','
                })

                let voucherData = {
                    PoNo:pono,
                    paidAmmount:paymentInfo.paymentAmmount,
                    paymentMethod:methodReturn(paymentInfo.paymentMethod),
                    paymentRemark:paymentInfo.paymentRemark,
                    supplierInfo:singleSupplier,
                    voucherNo:runningVoucherNo,
                    voucherType:1,
                    processorID:window.localStorage.getItem('user_id'),
                    processorName:userData.name,
                    bankID:paymentInfo.bankID,
                    subMethod:paymentInfo.sub_method
                }
                let bankData = {
                    bankID: paymentInfo.bankID,
                    clientInfo:{name:singleSupplier.companyaName, clientID:singleSupplier.supplierID, phone:singleSupplier.phone},
                    identity: pono,
                    inOut:2,
                    particulars:'Purchase',
                    paymentAmmount: paymentInfo.paymentAmmount,
                    voucherNo: runningVoucherNo,
                    receiveAmmount:0,
                    head:'Debit Voucher',
                    processorName:userData.name,
                    processorID:window.localStorage.getItem('user_id'),
                    remark:paymentInfo.paymentRemark,
                    transactionType:methodReturn(paymentInfo.paymentMethod),
                    voucherType:1
                }
                //console.log(voucherData)
                await AutoIncrementNumberCustom('NumberCollection', 'NumberID', 'debitVoucherNo', 1 )
                await SaveData('Vouchers', {...voucherData})
                await SaveData('Bank_Transaction', {...bankData})
                await AutoIncrementNumberCustom('BankList', paymentInfo.bankID, 'ammount', -paymentInfo.paymentAmmount )
                
               alert('data saved')
              //  console.log(paymentData)
              
            } else {
                console.log('you must pay full ammount')
            }
        } else {
            let fullPaid = true
            let status = ''
            let dataID = paymentArr[0].id
            if (paymentInfo.paymentAmmount < getPayableDue()) {
                fullPaid =false
                status = 'PO Ammount Partial Paid'
            }else{
                fullPaid =true
                status = 'PO Ammount Paid'
            }

            let purchaseUpdate = {
                paymentAmmount: paymentInfo.paymentAmmount,
                paymentDate: LocalDateToTime(paymentInfo.paymentDate),
                paymentRemark: paymentInfo.paymentRemark,
                voucherNo:runningVoucherNo,
                voucherType:1,
                paymentMethod:methodReturn(paymentInfo.paymentMethod),
                bankID:paymentInfo.bankID,
                subMethod:paymentInfo.sub_method
            }

            let summeryData = {
                supplierName: singleSupplier?.companyaName,
                status: status,
                attemeptBy: userData.name,
            }
           // console.log(fullPaid)
            await UpdateDataArray('Purchase', dataID, 'Payment', {...purchaseUpdate})
           await UpdateData('Purchase', {fullPaid: fullPaid}, dataID)
           await SaveNestedExistingData('Purchase', dataID, 'PoSummery', summeryData)

            let voucherData = {
                PoNo:paymentArr[0].PoNo,
                paidAmmount:paymentInfo.paymentAmmount,
                paymentMethod:methodReturn(paymentInfo.paymentMethod),
                paymentRemark:paymentInfo.paymentRemark,
                supplierInfo:singleSupplier,
                voucherNo:runningVoucherNo,
                voucherType:1,
                processorID:window.localStorage.getItem('user_id'),
                processorName:userData.name,
                bankID:paymentInfo.bankID,
                subMethod:paymentInfo.sub_method
            }
            let bankData = {
                bankID: paymentInfo.bankID,
                clientInfo:{name:singleSupplier.companyaName, clientID:singleSupplier.supplierID, phone:singleSupplier.phone},
                identity: paymentArr[0].PoNo,
                inOut:2,
                particulars:'Purchase',
                paymentAmmount: paymentInfo.paymentAmmount,
                voucherNo: runningVoucherNo,
                receiveAmmount:0,
                head:'Debit Voucher',
                processorName:userData.name,
                processorID:window.localStorage.getItem('user_id'),
                remark:paymentInfo.paymentRemark,
                transactionType:methodReturn(paymentInfo.paymentMethod),
                voucherType:1
            }
            //console.log(cashData)
           console.log(voucherData)
           await SaveData('Vouchers', {...voucherData})
           await SaveData('Bank_Transaction', {...bankData})
           await AutoIncrementNumberCustom('NumberCollection', 'NumberID', 'debitVoucherNo', 1 )
           await AutoIncrementNumberCustom('BankList', paymentInfo.bankID, 'ammount', -paymentInfo.paymentAmmount )
           alert('data saved')
        }
    }

    function methodReturn(data) {
        let method = ''
        if (data===1) {
            method = 'Cash'
        } else if (data===2) {
            method = 'Online Deposite'
        }else if (data===3) {
            method = 'Transfered'
        }else{
            method ='Cheque'
        }
        return method
    }

    function ValidateFunction(field, message) {
        let text = ""
        if (!paymentInfo[field]) {
            text = message + "is Required"
        }
        return text;
    }

    function CheckAll() {
        let newArr = []
        tableData.forEach(element => {
            element = {
                ...element,
                nowPay: true
            }
            newArr.push(element)
        });
        setTableData(newArr)
    }
    function unCheckAll() {
        let newArr = []
        tableData.forEach(element => {
            element = {
                ...element,
                nowPay: false
            }
            newArr.push(element)
        });
        setTableData(newArr)
    }
    function CheckData(e, id) {
        let newArr = []
        tableData.forEach(element => {
            if (element.id === id) {
                element = {
                    ...element,
                    nowPay: true
                }
                newArr.push(element)
            } else {
                newArr.push(element)
            }
        });
        setTableData(newArr)
    }
    function unCheckData(e, id) {
        let newArr = []
        tableData.forEach(element => {
            if (element.id === id) {
                element = {
                    ...element,
                    nowPay: false
                }
                newArr.push(element)
            } else {
                newArr.push(element)
            }
        });
        setTableData(newArr)

    }
    function getPayableDue() {
        let dueTotal = 0
        tableData?.map((item) => {
            if (item.nowPay) {
                dueTotal += item.dueAmmount
            }
        })
        return dueTotal
    }
    function checkAllData(params) {
        for (let index = 0; index < tableData?.length; index++) {
            const element = tableData[index];
            if (!element.nowPay) {
                setMyCheck(false)
                break;
            } else {
                setMyCheck(true)
            }
        }
    }
    useEffect(() => {
        checkAllData()
        getPayableDue()
        setPaymentInfo({ ...paymentInfo, paymentAmmount: 0, paymentDate:getDateTime()})
    }, [tableData])

    function getLeadgerBalance() {
       
        let dueTotal = 0
        allData?.map((item) => {
           dueTotal += item.dueAmmount
        })
        return dueTotal
    }
    async function getSupplier() {
        let supplierData = await GetAllDataExpress('supplier')
        setSupplierList(supplierData)
    }
    useEffect(() => {
        getSupplier()
    }, [])
    function getPaymentTotal(payArr){
        let total = 0
        payArr?.map((pitem)=>{
            total += pitem.paymentAmmount
        })

        return total
    }
   
   
    const errStyle = {
        display: 'block',
        textAlign: 'right',
        color: 'red',
    }
 
    return (
        <>
            <section className={DebitVouSupCss.heading}>
                <div className={DebitVouSupCss.inner_headign}>
                    <div className={DebitVouSupCss.input_section}>
                        <div className={DebitVouSupCss.common}>
                            <select onChange={(e) => { setSearchData(e.target.value) }}>
                                <option>Select Supplier</option>
                                {supplierList?.map((item, index) => {
                                    return <option key={index} value={item.id}>{item.companyaName}</option>
                                })}
                            </select>
                        </div>
                        <div className={DebitVouSupCss.common}>
                            <button onClick={getSearchData}>Search</button>
                        </div>
                        <div className={DebitVouSupCss.common}>
                            <button onClick={checkClick}>Credit Voucher Payment</button>
                        </div>
                    </div>
                    {singleSupplier ? <div className={DebitVouSupCss.supplier_info}>
                        <p>{singleSupplier?.companyaName}</p>
                        <p>{singleSupplier?.ownerName}</p>
                        <p>{singleSupplier?.phone}</p>
                        <p>{singleSupplier?.address + ', ' + singleSupplier?.Area + ', ' + singleSupplier?.District + ', ' + singleSupplier?.Divition}</p>
                    </div> : ''}
                    {singleSupplier ? <div className={DebitVouSupCss.tableSection}>
                        <h3>Debit Voucher No : {runningVoucherNo}</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Check
                                        {myCheck ? <FontAwesomeIcon style={{ color: 'white', margin: '0 0 0 8px', padding: '0', fontSize: "18px", cursor: 'pointer' }} icon={faSquareCheck} onClick={(e) => { unCheckAll(e) }} /> : <FontAwesomeIcon style={{ color: 'white', margin: '0 0 0 8px', padding: '0', fontSize: "18px", cursor: 'pointer' }} icon={faSquare} onClick={(e) => { CheckAll(e) }} />}
                                    </th>
                                    <th>Date</th>
                                    <th>Po No.</th>
                                    <th>Total Ammount</th>
                                    <th>Paid Ammount</th>
                                    <th>Return Ammount</th>
                                    <th>Discount</th>
                                    <th>Due Ammount</th>
                                </tr>

                                {tableData?.map((item, index) => {
                                    return <tr key={index}>
                                        <td>{item.nowPay ? <FontAwesomeIcon style={{ color: 'green', cursor: 'pointer', margin: '0 0 0 8px', padding: '0', }} icon={faSquareCheck} onClick={(e) => { unCheckData(e, item.id) }} /> : <FontAwesomeIcon style={{ color: '#b5b5b5', cursor: 'pointer', margin: '0 0 0 8px', padding: '0', }} icon={faSquare} onClick={(e) => { CheckData(e, item.id) }} />} </td>
                                        <td>{LocalTimeStampToDate(item.date)}</td>
                                        <td>{item.PoNo}</td>
                                        <td>{item.total}</td>
                                        <td>{item.paidAmmount}</td>
                                        <td>{item.returnAmmount}</td>
                                        <td>{item.totalDiscount}</td>
                                        <td>{item.dueAmmount}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <div className={DebitVouSupCss.other_information}>
                            <div className={DebitVouSupCss.text_section}>
                                <p className={DebitVouSupCss.ledger_ballnce}>Supplier Ledger Ballance : {getLeadgerBalance()} Tk</p>
                                <div className={`${DebitVouSupCss.payment_info} ${DebitVouSupCss.limit_due}`}>
                                    <p>Payment Limit : 0</p>
                                    <p>Payable Due : {getPayableDue()} Tk</p>
                                </div>
                            </div>
                            <div className={DebitVouSupCss.payment_info}>
                            <div className={DebitVouSupCss.option_class}>
                                    <label>Payment Method</label>
                                    <select name='paymentMethod' onChange={onDataChange}>
                                        <option value={1}>Cash</option>
                                        <option value={2}>Online Deposite</option>
                                        <option value={3}>Transferred</option>
                                        <option value= {4} >Cheque</option>
                                    </select>
                                </div>
                                {paymentInfo?.paymentMethod === 2 || paymentInfo?.paymentMethod === 3 ? <>
                                    <div className={DebitVouSupCss.option_class} >
                                        <label>Receipt Sub Method : </label>
                                        <select name='sub_method' onChange={onDataChange}>
                                            <option value={''}>Select Type</option>
                                            <option value='BEFTN'>BEFTN</option>
                                            <option value='NPSB'>NPSB</option>
                                            <option value='RTGS'>RTGS</option>
                                        </select>
                                    </div>
                                    {errMessage ? <p className={DebitVouSupCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.sub_methodErr}</p> : ''}

                                    <div className={DebitVouSupCss.option_class}>
                                        <label>Select Bank Account : </label>
                                        <select name='bankID' onChange={onDataChange}>
                                            <option value={''}>Select Bank</option>
                                            {myBankList?.map((item, index) => {
                                                return <option key={index} value={item.myBankID}>{item.bankName} - ({item.acNumber})</option>
                                            })}
                                        </select>
                                    </div>
                                    {errMessage ? <p className={DebitVouSupCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.bankIDErr}</p> : ''}
                                </> : ''}
                                <div className={DebitVouSupCss.input_section}>
                                    <label>Paid Ammount</label>
                                    <input onChange={onDataChange} name='paymentAmmount' type='number' placeholder='payment ammount' value={paymentInfo?.paymentAmmount} />
                                </div>
                                <p style={errStyle}>{errMessage?.payErr}</p>
                                <div className={DebitVouSupCss.input_section}>
                                    <label>Payments Remarks</label>
                                    <input onChange={onDataChange} name='paymentRemark' type='text' placeholder='payment remark here' value={paymentInfo.remark} />
                                </div>
                                <p style={errStyle}>{errMessage?.remarkErr}</p>
                                <div className={DebitVouSupCss.input_section}>
                                    <label>Date</label>
                                    <input onChange={onDataChange} name='paymentDate' type='datetime-local' value={paymentInfo?.paymentDate} />
                                </div>
                                <p style={errStyle}>{errMessage?.dateErr}</p>
                                <div className={DebitVouSupCss.input_section}>
                                    <label>Remaining </label>
                                    <input type='text' disabled value={paymentInfo?.paymentAmmount ? getPayableDue() - paymentInfo?.paymentAmmount : getPayableDue()} />
                                </div>
                                <div className={DebitVouSupCss.button_section}>
                                    <button onClick={onSaveClick}>Save</button>
                                </div>
                            </div>
                        </div>

                    </div> : ''}
                </div>
            </section>
        </>
    )
}

export default DebitVoucherSupplier