import { Route, Routes } from 'react-router-dom'
import CustomerList from './CustomerList'
import AddnewCustomer from './AddnewCustomer'
import CustomerTrashList from './CustomerTrashList'
import CrmMain from './CRM/CrmMain'
import CustomerWishlist from './CustomerWishlist/CustomerWishlist'
import ManageComplainMain from './ManageComplains/ManageComplainMain'
export default function CustomerRoutes() {
  return (
    
    <Routes>
       <Route path='/customer-list' element={<CustomerList />}/>
       <Route path='/add-new-customer' element={<AddnewCustomer data ={'add new user'}/>}/>
       <Route path='/trash-list' element={<CustomerTrashList data ={'Trash List'}/>}/>
       <Route path='/send-message' element={<CrmMain/>}/>
       <Route path='/wishlist' element={<CustomerWishlist/>}/>
       <Route path='/complain/*' element={<ManageComplainMain/>}/>
      
   </Routes>
  )
}
