import Chart from 'chart.js/auto';
import { useEffect, useRef } from "react";

const ChartJsChart = ({amountData}) => {
   const chartRef = useRef(null);
   let labels = [];
   for (let index = 0; index <100; index++) {
    labels.push(index+1)
   }

    const data = {
        // labels: labels,
        datasets: [{
            label: 'Net sales',
            data: amountData,
            backgroundColor: [
                '#5747FF80',
                '#76009D80',
                '#34C30080',
                '#5747FF80',
                '#E6CF0080',
                '#DC044580',
                '#CC00B880'
              ],
              borderColor: [
                '#5747FF',
                '#76009D',
                '#34C300',
                '#5747FF',
                '#E6CF00',
                '#DC0445',
                '#CC00B8'
              ],
            parsing: {
              yAxisKey: 'total' 
            }
          },],
         
          borderWidth: 1
      
      };
      useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        // Check if a chart instance already exists
        if (chartRef.current.chart) {
            chartRef.current.chart.destroy();
        }

        chartRef.current.chart = new Chart(ctx, {
            type: 'bar',
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });
    }, [data]);
  
    return <canvas id='chart' ref={chartRef} />;
  };
  
  export default ChartJsChart;
