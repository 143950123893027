import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { GetBankList } from '../../../api/BankTransactionApi'
import BankBalanceListCss from './Css/BankBalanceList.module.css'

const BankBalanceList = () => {
    const [filterData, setFilterData] = useState()
    const [tableData, setTableData] = useState()
    function onDataChange(params) {
        
    }
    function onSearchClick(params) {
        
    }

   async function getDatafromDatabase(params) {
        let bankList = await GetBankList()
        setTableData(bankList)
    }
    function onAmountClick(params) {
        
    }
    useEffect(()=>{
        getDatafromDatabase()
    },[])
  return (
    <>
     <section className={BankBalanceListCss.salesMain}>
              <div className={BankBalanceListCss.innerSale}>
                  <h2 className={BankBalanceListCss.saleHeading}>Bank List</h2>
                  <div className={BankBalanceListCss.search_section}>
                      <div className={BankBalanceListCss.input_section}>
                          <label>Start Date</label>
                          <input name='startDate' type={'date'} value={filterData?.startDate? filterData?.startDate:''} onChange={onDataChange}/>
                      </div>
                      <div className={BankBalanceListCss.input_section}>
                          <label>End Date</label>
                          <input name='endDate' type={'date'} value={filterData?.endDate ? filterData?.endDate:''} onChange={onDataChange}/>
                      </div>
                     
                      
                      
                  </div>

                  <div className={BankBalanceListCss.buttonWrapper}>
                          <button onClick={onSearchClick}>search</button>
                          <button>reset</button>
                      </div>
                  <div className={BankBalanceListCss.tableSection}>
                      <table style={{marginTop:'0px'}}>
                          <tbody>
                              <tr>
                                  <th>Sl</th>
                                  <th>Bank</th>
                                  <th>Branch</th>
                                  <th>Account Name</th>
                                  <th>Account No</th>
                                  <th>Opening Balance</th>
                                  <th>Current Balance</th>
                              </tr>
                              {tableData?.map((item, index)=>{
                                return <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{item.bankName}</td>
                                    <td>{item.branchName}</td>
                                    <td>{item.acName}</td>
                                    <td>{item.acNumber}</td>
                                    <td style={{color:'green', textAlign:'right', fontWeight:'bold'}}>{item.openingBanlance}</td>
                                    <td style={{color:'blue', textAlign:'right', fontWeight:'bold', cursor:'pointer'}} onClick={onAmountClick}><Link to={'/admin/accounts/view_bank_history/'+item.id} target='_blank' rel="noopener noreferrer">{item.ammount}</Link></td>
                                    
                                </tr>
                              })}
                          </tbody>
                      </table>
                  </div>
              </div>
          </section>
    
    </>
  )
}

export default BankBalanceList