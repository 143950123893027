import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { GetDetailsOrderItems, GetSingleOrder } from '../../api/SalesApi'
import { GetSingleCustomer, GetSingleCustomerOrderAddress } from '../../api/CustomerApi'
import ComDetViewCss from './salesCss/CompleteDetailsView.module.css'
import GobackButton from '../global/GobackButton'

const CompleteDetailsView = () => {
    const myParams = useParams()
    const [tableData, setTableData] = useState()
    const [orderData, setOrderData] = useState()
  
    function onPrintInvoice() {
        let okdata = {}
        okdata = {...okdata, tableData, ...orderData}
       localStorage.setItem('invoiceData', JSON.stringify(okdata))
    }
    
    function convertDate(time) {
        const dateInMillis = time
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }
    async function getAllData() {
        let myData = await GetSingleOrder(myParams.orderid)
        let myCustomer = await GetSingleCustomer(myData[0].userID)
        let userAddress = await GetSingleCustomerOrderAddress(myData[0].user_address_id)
        let getSSLdata = []
        let finalData = {
            ...myData[0],
            order_created: myData[0].created_date,
            ...userAddress[0],
            ...myCustomer[0],
            ...getSSLdata[0],
            deliveryCharge:myData[0].deliveryCharge,
            id:myData[0].id
        }
setOrderData(finalData)
        let morderItems = await GetDetailsOrderItems({id:myParams.orderid} )
        organizeData(morderItems)
    }

    function organizeData(myData) {
        let finalArr = []
        let uniqueElements = myData.slice()
        for (let index = 0; index < myData.length; index++) {
            const element = myData[index];
            let tempArr = []
            for (let mindex = 0; mindex < uniqueElements.length; mindex++) {
                const melement = uniqueElements[mindex];
                if (melement.product_id === element.product_id) {
                    tempArr.push(melement)
                }
            }

            if (tempArr.length > 0) {
                tempArr.forEach(element => {
                    uniqueElements.forEach((melement, mindex) => {
                        if (melement.id === element.id) {
                            uniqueElements.splice(mindex, 1)
                        }
                    });
                });
                finalArr.push(tempArr)           
            }
        }
        setTableData(finalArr)
    }



    useEffect(() => {
        getAllData()
    }, [myParams.orderid])


    function getQTotal(item) {
        let total = 0
        item?.forEach(element => {
            total += element.quantity
        });

        return total;
    }
    function totalAmmount(item) {
        let quantity = getQTotal(item)
        let total = 0
        total += item[0].price
        return total * quantity;
    }
     
      function getVarianceList(item) {
        let variations = ''
            item.forEach(element => {
                variations += element.Variance_name ?  element.Variance_name+', ' : ''
            });
            return variations
      }
      function getAgeList(item) {
        let age = ''
            item.forEach(element => {
                age += element.attr_value_name ?  element.attr_value_name+', ': ''
            });
            return age
      }

    const TableItem = tableData?.map((item, index) => {
        return <MyTable key={index} item={item[0].mainName ? item[0]?.mainName : item[0].name}
            variance={getVarianceList(item)}
            age={getAgeList(item)}
            price={item[0]?.price} qty={getQTotal(item)} tammount={totalAmmount(item)} />
    })

    function getAllTotal() {
        let total = 0
        tableData?.forEach(item => {
            item.forEach(element => {
                total += element.quantity*element.price
            });
        });
        return total
    }
    return (
        <section className={ComDetViewCss.wrapper}>
                    <div className={ComDetViewCss.holder}>
                        <div className={ComDetViewCss.head}>
                            <GobackButton/>
                            <h2 className={ComDetViewCss.saleHeading}>Complete Order Details</h2>
                        </div>
                        <div className={ComDetViewCss.invoice_info}>
                    <p><strong>Invoice #</strong>{orderData?.id}</p>
                    <p><strong>Note  </strong> : {orderData?.approval_note}</p>
                    <p><strong>Priority  </strong> : {orderData?.priority}</p>
                    <p><strong>D. Date </strong> : {orderData?.delivery_date}</p>
                    <p><strong>S. Note  </strong> : {orderData?.special_instruction}</p>
                </div>
                        <div className={ComDetViewCss.information}>
                        <div className={ComDetViewCss.customerDetails}>
                                    <p className={ComDetViewCss.highlighter}>Customer Details</p>
                                    <p><strong>Customer Name</strong> : {orderData?.name}</p>
                                    <p><strong>Phone</strong> : {orderData?.Phone}</p>
                                   <div className={ComDetViewCss.address}>
                                   <p><strong>Division</strong> :{orderData?.Divition}</p>
                                    <p><strong>District</strong> : {orderData?.District}</p>
                                    <p><strong>area</strong> : {orderData?.Area}</p>
                                   </div>
                                    <div className={ComDetViewCss.addressSec}>
                                        <p><strong>Address</strong> : {orderData?.Address}</p>
                                    </div>
                                </div>
                                <div className={ComDetViewCss.orderDetails}>
                                    <p className={ComDetViewCss.highlighter}>Order Details</p>
                                    <p>Order Date : {convertDate(orderData?.order_created)} </p>
                                    <p>Payment Type : {orderData?.paymentType}</p>
                                </div>
                         
                        </div>
                        <div className={ComDetViewCss.table_wrapper}>
                                <table>
                                    <tbody>
                                    <tr>
                                        <th>Item</th>
                                        <th>Variance</th>
                                        <th>Age</th>
                                        <th>Price</th>
                                        <th>Quantity</th>
                                        <th>Total Ammount</th>
                                    </tr>
                                    {TableItem}
                                    </tbody>
                                </table>
                                <div className={ComDetViewCss.tableDetails}>
                                <p>Total Ammount : {getAllTotal()} tk</p>
                                    <p>Discount : {Math.round(orderData?.discountPercent ? (orderData?.grandTotal *orderData?.discountPercent)/100 : "0")} tk</p>
                                    <p>Delivery Charge : {orderData?.deliveryCharge} tk</p>
                                    <p>Grand Total : {Math.round((getAllTotal() + orderData?.deliveryCharge)- (orderData?.discountPercent ? (getAllTotal() *orderData?.discountPercent)/100 : "0"))} tk</p>
                                </div>
                                <div className={`${ComDetViewCss.detailInput} ${ComDetViewCss.salesDetail}`}>
                                        <div className={ComDetViewCss.button_group}>
                                            <button>Print Chalan Copy</button>
                        <button>
                        <Link to={`/sales/salesdetails/${orderData?.id}/invoice`} target='_blank' rel="noopener noreferrer" onClick={onPrintInvoice}>Print Invoice</Link>
                        </button>
                                        </div>
                                    </div>

                            </div>
                    </div>
                </section>
    )
}

export default CompleteDetailsView



const MyTable = ({ item, variance, age, price, qty, tammount }) => {

    return (
        <>
            <tr>
                <td className={ComDetViewCss.foreditVariance}>{item}</td>
                <td >{variance}</td>
                <td >{age}</td>
                <td >{price}</td>
                <td>{qty}</td>
                <td>{tammount}</td>
            </tr>
        </>
    )
}