import PurchaseConfirmCSs from './css/PurchaseConfirm.module.css'

import React, { useEffect, useState } from 'react'
import { FaRegEdit } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from "react-toastify"
import { selectSupplierList } from '../../ReduxFiles/Slices/SupplierSlice'
import { selectUserInfo } from '../../ReduxFiles/Slices/UserSlice'
import { GetDebitDataFilter } from '../../api/DebitApi'
import { GetPurchaseItems, GetsinglePurchase, SavePurchaseSummery, UpdatePurchaseData } from '../../api/PurchaseApi'
import { GetAllLocalProducts } from '../MyFunctions/MyFunctions'
import GobackButton from '../global/GobackButton'
import ManageVarianceModel from './models/ManageVarianceModel'
const PurchaseConfirm = () => {
    const userInfo = useSelector(selectUserInfo)
    const supplierData = useSelector(selectSupplierList)
    const [tableData, setTableData] = useState([])
    const [orderData, setOrderData] = useState()
    const [editVarData, setEditVarData] = useState()
    const [showPop, setShowPop] = useState(false)
    const myNavigation = useNavigate()
    const [searchParams] = useSearchParams();
    const poid = searchParams.get('pid')

    async function OnConfirmData() {
        let allVarianceManaged = false
        for (let index = 0; index < tableData.length; index++) {
            let element = tableData[index];
            if (element.hasVriance) {
                if (element.varianceManaged) {
                    allVarianceManaged = true
                } else {
                    allVarianceManaged = false
                    toast.warn(`please manage all variance, ${element.name}`)
                    break
                }
            } else {
                allVarianceManaged = true
            }

        }
        if (allVarianceManaged) {
            let summeryData = {
                order_date: orderData.poDate,
                supplier_id: orderData.supplierID,
                status: 'Variance Managed',
                attemptBy: userInfo.id,
                purchase_id: orderData.id,
                created_date: new Date().getTime()
            }
            await UpdatePurchaseData(orderData.id, { varianceManaged: 1 })
            await SavePurchaseSummery(summeryData)
            myNavigation('/purchase/pending')
        }
    }
    function convertDate(time) {
        const dateInMillis = time
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }
    

    function EditeVariance(e, items) {
        setEditVarData(items)
        setShowPop(true)
    }
    function getDiscountAmount(amount, percent) {
        let mpercent = percent ? percent : 0
        return amount * mpercent / 100
    }
    useEffect(() => {
        async function getAllData() {
            let mAllProducts = GetAllLocalProducts()
            let myData = await GetsinglePurchase(poid)
            let myNestedData = await GetPurchaseItems(`purchase_id = ${poid}`)
            let myPayData = await GetDebitDataFilter(`identity = ${poid}`)
            //product_id
            let myArr = []
            myNestedData.forEach(element => {
                for (let index = 0; index < mAllProducts.length; index++) {
                    const pelement = mAllProducts[index];
                    if (element.product_id === pelement.id) {
                        element = {
                            ...element,
                            name: pelement.en_sort_title
                        }
                        myArr.push(element)
                        break
                    }
                }
            });
            let total = 0
            let finalData = {
                ...myData[0]
            }
            for (let index = 0; index < myPayData?.length; index++) {
                const pelement = myPayData[index];
                if (pelement.identity === parseInt(poid)) {
                    total = pelement.paymentAmmount
                }
    
            }
            setOrderData({ ...finalData, payAmmount: total })
            setTableData(myArr)
        }
        getAllData()
    }, [poid, showPop])


    function getItemTotal(mtabelData) {
        let total = 0
        mtabelData?.forEach((item) => {
            total += item.quantity * item.price
        })

        return total;
    }

    return (
        <section className={PurchaseConfirmCSs.wrapper}>
            <div className={PurchaseConfirmCSs.holder}>
                <div className={PurchaseConfirmCSs.head}>
                    <GobackButton />
                    <h2 className={PurchaseConfirmCSs.saleHeading}>PO Variance Manage</h2>
                </div>

                {showPop ? <ManageVarianceModel data={editVarData} setShowPop={setShowPop} tableData={tableData} setTableData={setTableData} /> : ""}

                <div className={PurchaseConfirmCSs.information}>
                    <div className={PurchaseConfirmCSs.customerDetails}>
                        <h3>PO No. #{orderData?.id}</h3>
                        <p >Supplier Details</p>
                        {supplierData?.filter((fitem) => {
                            return fitem.id === orderData?.supplierID ? fitem : ''
                        }).map((item, index) => {
                            return <React.Fragment key={index}>
                                <p><strong>Company Name</strong> : {item?.companyaName}</p>
                                <p><strong>Phone</strong> : {item?.phone}</p>
                                <div className={PurchaseConfirmCSs.address}>
                                    <p><strong>Division</strong> :{item?.Divition}</p>
                                    <p><strong>District</strong> : {item?.District}</p>
                                    <p><strong>area</strong> : {item?.Area}</p>
                                </div>

                            </React.Fragment>
                        })}
                    </div>

                    <div className={PurchaseConfirmCSs.order_details}>
                        <p className={PurchaseConfirmCSs.highlighter}>PO Details</p>
                        <p>PO Date : {convertDate(orderData?.created_date)} </p>
                        <p>Payment Method : {orderData?.paymentMethod}</p>
                    </div>

                </div>
                <div className={PurchaseConfirmCSs.table_wrapper}>
                        <table key={'abcd'}>
                            <tbody>
                                <tr>
                                    <th>Item</th>
                                    <th>Variations</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Total Ammount</th>
                                </tr>
                                {tableData.map((item, index)=>{
                                    return <tr key={index}>
                                        <td>{item.name}</td>
                                        <td>{item.hasVriance ? <FaRegEdit onClick={(e) => { EditeVariance(e, item) }} title='Manage Variance Quantity' />:''}
                                        {item.varianceManaged ? <span className={PurchaseConfirmCSs.success}>variance managed</span> : ""}
                                        </td>
                                        <td>{item.price}</td>
                                        <td>{item.quantity}</td>
                                        <td>{item.quantity * item.price}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>

                        <div className={PurchaseConfirmCSs.table_details}>
                            
                            <p>Total Ammount : {getItemTotal(tableData)} tk</p>
                            <p>Paid : {orderData?.payAmmount ? orderData?.payAmmount : "0"} tk</p>
                            <p>Discount : {getDiscountAmount(getItemTotal(tableData), orderData?.percent)} tk</p>
                            <p>Due Ammount : {getItemTotal(tableData) - (orderData?.payAmmount + getDiscountAmount(getItemTotal(tableData), orderData?.percent))} tk</p>
                            <div>
                            <button onClick={OnConfirmData}>Variance Managed</button>
                            </div>
                        </div>

                      

                    </div>
            </div>
        </section>
    )
}

export default PurchaseConfirm
