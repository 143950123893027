import React from 'react'
import { Link } from 'react-router-dom'
import manageCss from './css/manage.module.css'


const ProductManagement = () => {
    const itemMenu = [
        {title:'Product List', link:'/products/product-list', icon:''},
        {title:'Add New Product', link:'/products/add-new-product', icon:''},
        {title:'Categories', link:'/products/category', icon:''},
        {title:'Brand List', link:'/products/brands', icon:''},
        {title:'Manage Tag', link:'/products/managetag', icon:''},
        {title:'Attributes ', link:'/products/atributes', icon:''},
        {title:'Units', link:'/products/unit', icon:''},        
    ]
    return (
        <div className={manageCss.wrapper}>
        <div className={manageCss.menuitems}>
            {itemMenu.map((item, index)=>{
                return <Link to={item.link} className={manageCss.item} key={index}> 
                <h2>{item.title}</h2>  
        </Link>
            })}
        </div>
    </div>
    )
}

export default ProductManagement
