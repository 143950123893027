import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUnits, selectMAUStatus, selectUnitList } from '../../ReduxFiles/Slices/MAU_Slice'
import { DeleteUnits, SaveUnits, UpdateUnits } from '../../api/MAU_Api'
import ConfirmAlert from '../smallThings/ConfirmAlert'
import UnitsCss from './css/Units.module.css'
const Units = () => {
    const mauStatus = useSelector(selectMAUStatus)
    const unitList = useSelector(selectUnitList)
    const dispatch = useDispatch()

    const [conAlert, setConAlert] = useState(false)
    const [editId, setEditID] = useState()
    const [buttonValue, setButtonValue] = useState("Add New")
    const [unitName, setUnitName] = useState('');
    const [tableData, setTableData] = useState([]);

    function handleChange(e) {
        setUnitName(e.target.value)
        //  console.log(unitName)
    }


    function cancelClick() {
        setUnitName('')
        setButtonValue('Add New')
    }
    function saveUnit() {
        if (unitName !== '') {
            // console.log(unitName)
            if (buttonValue === 'Add New') {
                saveData();
                setUnitName('');
            } else {
                updateData(editId, unitName)
                setUnitName('');
            }

        } else {
            alert('unite Name Required')
        }
    }

    const updateData = async (id, value) => {
        const newFields = { unit: value };
        await UpdateUnits(id, newFields)
        let tempArr = []
        tableData.forEach(element => {
            if (element.id === id) {
                element = { ...newFields, id: id }
                tempArr.push(element)
            } else {
                tempArr.push(element)
            }
        });
        setTableData(tempArr)
        setButtonValue('Add New')
        // console.log(newFields, id)
    };

    async function saveData() {
        let itemID = await SaveUnits({ unit: unitName })
        setTableData([...tableData, { unit: unitName, id: itemID.insertId }])

    }

    function EditClick(e) {
        setButtonValue('Update Data')
        let mquery = e.target.getAttribute("sid")
        getEditData(mquery);
    }

    function getEditData(query) {
        for (let index = 0; index < tableData.length; index++) {
            const element = tableData[index];
            if (element.id == query) {
                const getId = element.id
                const getName = element.unit
                setUnitName(getName)
                setEditID(getId)
            }
        }
    }

    function DeletClick(e) {
        const dataid = e.target.getAttribute("sid")
        setConAlert(true)
        setEditID(dataid)
    }

    function Clickyes(e) {
        let delid = e.target.getAttribute("sid")
        DeleteUnits(delid)
        let tempArr = []
        tableData.forEach(element => {
            if (element.id !== parseInt(delid)) {
                tempArr.push(element)
            }
        });
        setTableData(tempArr)
        setConAlert(false)
    }

    function ClickNo() {
        setConAlert(false)
    }


    useEffect(() => {
        if (mauStatus.unitStatus==='idle') {
            dispatch(fetchUnits())
        }
    }, [])


    return (
        <div className={UnitsCss.wrapper}>
        {conAlert ? <ConfirmAlert delID={editId} yesClick={Clickyes} noClick={ClickNo} /> : ''}
            <h3>Units</h3>
            <div className={UnitsCss.atributesec}>
                <div className={UnitsCss.nameAtrribute}>
                    <label>Unit  Name :</label>
                    <input name='unitName' type='text'ondatachange={handleChange} placeholder='unit' value={unitName}/>
                    <div className={UnitsCss.button_group}>
                        <button>Save</button>
                    </div>
                </div>

            </div>


            <div className={UnitsCss.table_wrapper}>
                <table>
                    <tbody>
                        <tr>
                            <th>Sl</th>
                            <th>Unit Name</th>
                            <th>Action</th>
                        </tr>
                        {unitList.map((item, index)=>{
                            return <tr key={index}>
                                <td>{index+1}</td>
                                <td>{item.unit}</td>
                                <td>
                                <div className={UnitsCss.action_buttons}>
                                <button onClick={EditClick}>Edit</button>
                    <button onClick={DeletClick} >Delete</button>
                    </div>    
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Units