import React, { useEffect, useState } from 'react'
import customerCss from './cinfo.module.css'
import DummyFemale from '../../../assets/dummyuserfemal.png'
import { API_LINK } from '../../../api/APILink'
import { GetSingleCustomer, GetSingleCustomerOrderAddress } from '../../../api/CustomerApi'

async function getData(userid, address_id, setData) {
    let userData = await GetSingleCustomer(userid)
    let myData = address_id ? await GetSingleCustomerOrderAddress(address_id):[]
    let addData = myData.length > 0 ? myData[0]:{}
    if (userData.length > 0) {
        let address = addData.Address + ", " + addData.Area + ", " + addData.District + ", " + addData.Divition
        setData({ ...userData[0], address: address })
    }
   

}

const CustomerInformationModel = ({popUp, setPopUp}) => {
    const [userData, setUserData] = useState({})
    function oncloseClick() {
        document.body.style.overflow = 'auto'
        setPopUp()
    }
    useEffect(()=>{
        getData(popUp.userID, popUp.user_address_id, setUserData)
    },[popUp])

  return (
    <div className={customerCss.wrapper}>
        <div className={customerCss.holder}>
                {userData.userImageName ? <img src={API_LINK + 'userimages/' + userData.userImageName} alt={userData.userImageName}/> : <img src={DummyFemale} alt='place holder'/>}
                <div>
                
                <div className={customerCss.info}>
                <h3>Customer Information :</h3>
                    <p><strong>Name : </strong>{userData.name}</p>
                    <p><strong>Phone : </strong>{userData.phone}</p>
                    <p><strong>Email : </strong>{userData.email}</p>
                    <p><strong>Address : </strong>{userData.address}</p>
                </div>
                </div>
                <div className={customerCss.btn_group}>
                    <button onClick={oncloseClick}>Close</button>
                </div>
            </div>
    </div>
  )
}

export default CustomerInformationModel