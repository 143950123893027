import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import ToggleSwitchCss from './css/ToggleSwitch.module.css'

const ToggleSwitch = ({ toggleOnOff, toggleClick, item }) => {
  //  console.log(toggleOnOff)
    return (
        <>

            {toggleOnOff === 1 ? <div className={ToggleSwitchCss.ToggleSwitchon}> <p onClick={() => { toggleClick(item) }}> </p><FontAwesomeIcon icon={faToggleOn} /></div> : <div className={ToggleSwitchCss.ToggleSwitchoff}><p onClick={() => { toggleClick(item) }}> </p><FontAwesomeIcon icon={faToggleOff} /></div>}


        </>
    )
}

export default ToggleSwitch