import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AddBankData } from '../../api/BankTransactionApi';
import AddNewBankCss from './css/AddNewBank.module.css'
const AddNewBank = () => {
    const [inputData, setInputData] = useState()
    const [errText, setErrText] = useState()
    const myNavigate = useNavigate()
    let bankNameArray = ['Dutch Bangla Bank Limited', 'Sonali Bank Limited', 'Janata Bank Limited', 'Agrani Bank Limited', 'BASIC Bank Limited', 'Pubali Bank Limited', 'BRAC Bank Limited', 'United Commercial Bank Ltd', 'Standard Chartered Bank', 'IFIC Bank Limited', 'Bangladesh Krishi Bank', 'AB Bank Limited', 'Eastern Bank Limited', 'Mercantile Bank Limited', 'UNITED COMMERCIAL BANK LTD.', 'Al-Arafah Islami Bank Limited', 'Islami Bank Limited', 'One Bank']
    bankNameArray.sort()

    function onInputChange(e) {
        let name = e.target.name
        let value = e.target.value
        setInputData({...inputData, [name]:value})
    }
  async  function onSaveClick() {
       if (inputData) {
            let bankNameErr = ValidateFunction('bankName', 'Bank Name')
            let acNameErr = ValidateFunction('acName', 'A/C Name')
            let acTypeErr = ValidateFunction('acType', 'A/C Type')
            let acNumberErr = ValidateFunction('acNumber', 'A/C Number ')
            let branchNameErr = ValidateFunction('branchName', 'Branch Name ')
            let routingNumberErr = ValidateFunction('routingNumber', 'Routing Name')
            let ammountErr = ValidateFunction('ammount', 'Initial Ammount')
            let keyPersonErr = ValidateFunction('keyPName', 'Key Person Name ')
            let phoneErr = ValidateFunction('acPhone', 'Phone Number')
            let addressErr = ValidateFunction('acAddress', 'A/C Type')
            setErrText({bankNameErr, acNameErr, acTypeErr, acNumberErr, branchNameErr, routingNumberErr, ammountErr, keyPersonErr, phoneErr, addressErr})
            if (!bankNameErr && !acNameErr && !acTypeErr && !acNumberErr && !branchNameErr && !routingNumberErr && !ammountErr && !keyPersonErr && !phoneErr && !addressErr) {
                let finalData = {
                    ...inputData, 
                    ammount:inputData.ammount,
                    openingBanlance: inputData.ammount,
                    active:1,
                    lastChequeNumber:0,
                    created_date:new Date().getTime()
                }
                // console.log(finalData)
                await AddBankData(finalData)
                myNavigate('/admin/banks/bank_list')
              //  alert('data saved')
            }
       }else{
        alert('input data required')
       }
    }

    function ValidateFunction(field, message) {
        let text = ""
            if (!inputData[field]) {
                text = message + " is Required"
            }
        return text;
      }

    return (
        <>
            <section style={{ backgroundColor: '#d7edff', padding: '10px' }}>
                <h2 className={AddNewBankCss.heading}>Add new Bank</h2>
                <div className={AddNewBankCss.addsupplier}>
                    <div className={AddNewBankCss.supplierinput} >
                        <label>Bank Name *</label>
                        <select name='bankName' onChange={onInputChange}>
                            <option value={''}>-Select Bank-</option>
                            {bankNameArray?.map((item, index) => {
                                return <option key={index} value={item}>{item}</option>
                            })}
                        </select>
                        {errText ? <p className={AddNewBankCss.WarningText}>{errText?.bankNameErr}</p> : ""}
                    </div>
                    <div className={AddNewBankCss.supplierinput}>
                        <label>A/C Name *</label>
                        <input name='acName' type='text' placeholder='A/C Name' onChange={onInputChange} />
                        {errText ? <p className={AddNewBankCss.WarningText}>{errText?.acNameErr}</p> : ""}
                    </div>
                    <div className={AddNewBankCss.supplierinput} >
                        <label>A/C Type *</label>
                        <select name='acType' onChange={onInputChange}>
                            <option>-Select Bank-</option>
                            <option>Savings Account (SA)</option>
                            <option>Current Account (CA)</option>
                            <option>Short Notice Deposit (SND)</option>
                            <option>Fixed Deposit Receipt (FDR)</option>
                            <option>Monthly Saving Deposit (MSD)</option>
                            <option>Cash Credit Hypothecation (CCH)</option>
                            <option>Overdraft (OD)</option>
                            <option>Loans Account (LA)</option>
                        </select>
                        {errText ? <p className={AddNewBankCss.WarningText}>{errText?.acTypeErr}</p> : ""}
                    </div>
                    <div className={AddNewBankCss.supplierinput}>
                        <label>A/C Number *</label>
                        <input name='acNumber' type='text' placeholder='A/C Number' onChange={onInputChange} />
                        {errText ? <p className={AddNewBankCss.WarningText}>{errText?.acNumberErr}</p> : ""}
                    </div>
                    <div className={AddNewBankCss.supplierinput}>
                        <label>Branch Name *</label>
                        <input name='branchName' type='text' placeholder='Branch Name' onChange={onInputChange} />
                        {errText ? <p className={AddNewBankCss.WarningText}>{errText?.branchNameErr}</p> : ""}
                    </div>
                    <div className={AddNewBankCss.supplierinput}>
                        <label>Routing Number *</label>
                        <input name='routingNumber' type='text' placeholder='Routing Name' onChange={onInputChange} />
                        {errText ? <p className={AddNewBankCss.WarningText}>{errText?.routingNumberErr}</p> : ""}
                    </div>
                    <div className={AddNewBankCss.supplierinput}>
                        <label>Initial Ammount *</label>
                        <input name='ammount' type='text' placeholder='0.00' onChange={onInputChange} />
                        {errText ? <p className={AddNewBankCss.WarningText}>{errText?.ammountErr}</p> : ""}
                    </div>
                    <div className={AddNewBankCss.supplierinput}>
                        <label>Key Person Name *</label>
                        <input name='keyPName' type='text' placeholder='Key Person Name' onChange={onInputChange} />
                        {errText ? <p className={AddNewBankCss.WarningText}>{errText?.keyPersonErr}</p> : ""}
                    </div>
                    <div className={AddNewBankCss.supplierinput}>
                        <label>Phone Number *</label>
                        <input name='acPhone' type='text' placeholder='Phone Number' onChange={onInputChange} />
                        {errText ? <p className={AddNewBankCss.WarningText}>{errText?.phoneErr}</p> : ""}
                    </div>
                    <div className={AddNewBankCss.supplierinput}>
                        <label>Address *</label>
                        <input name='acAddress' type='text' placeholder='Address' onChange={onInputChange} />
                        {errText ? <p className={AddNewBankCss.WarningText}>{errText?.addressErr}</p> : ""}
                    </div>
                    <div className={`${AddNewBankCss.supplierinput} ${AddNewBankCss.buttonsection}`}>
                        <button onClick={onSaveClick}>SAVE</button>
                    </div>

                </div>
            </section>
        </>
    )
}

export default AddNewBank