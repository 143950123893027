import React, { useState } from 'react';
import addNewUserCss from './css/addNewUser.module.css'
import Select from 'react-select';
import { IoClose } from "react-icons/io5";
import { useDispatch } from 'react-redux'
import { SaveBackUserData } from '../../../api/BackendUserApi';
import { fetchAllUser} from '../../../ReduxFiles/Slices/UserSlice'
import { toast } from 'react-toastify';
export default  function AddnewUser({setAddUserShow}) {
    const dispatch = useDispatch()
    const [selectedValue, setSelectedValue] = useState('');
    function onClose(){
        setAddUserShow(false)
    }
    async function handleSubmit(e) {
        e.preventDefault()
        const from=e.target;
        const userName=from.userName.value;
        const fullName=from.fullName.value;
        const email=from.email.value;
        const phone=from.phone.value;
        const password=from.password.value;
        const conpassword=from.conpassword.value;
        const userbio=from.userbio.value;
        const userrole =from.userrole.value
        const userImage=from.userImage.files[0];
        const active = 1
        const created_date = new Date().getTime()
        if(password===conpassword){
            const data=await SaveBackUserData({userName,fullName,email,phone,password,conpassword,userbio,userrole, active, created_date},userImage)
            if(data){
                toast.success('user added succesfully')
                dispatch(fetchAllUser())
                setAddUserShow(false)
            }else{
                toast.warn(data.message)
            }
        }else{
            toast.warn("Password Not Matched")
        }
    }
    // const handleSelectChange = (selectedOption) => {
    //   setSelectedValue(selectedOption.value);
    // };
    const options = [
        { value: "1", label: 'Admin' },
        { value: "2", label: 'Editor' },
        { value: "3", label: 'Moderator' },
      ];
  return (
    <div className={addNewUserCss.wrapper_popup}>
        <div className={addNewUserCss.holder}>
            <h3>Add New User</h3>
            <button onClick={onClose} className={addNewUserCss.close_button}><IoClose /></button>
           <form onSubmit={handleSubmit}>
                <div className={addNewUserCss.input_wrapper}>
                    <label>User Name*</label>
                    <input type='text' name='userName'/>
                </div>
                <div className={addNewUserCss.input_wrapper}>
                    <label>Full Name*</label>
                    <input type='text' name='fullName' required />
                </div>
                <div className={addNewUserCss.input_wrapper}>
                    <label>Email*</label>
                    <input type='text' name='email' required />
                </div>
                <div className={addNewUserCss.input_wrapper}>
                    <label>Phone*</label>
                    <input type='text' name='phone' required />
                </div>
                <div className={addNewUserCss.input_wrapper}>
                    <label>Password*</label>
                    <input type='password' name='password' required />
                </div>
                <div className={addNewUserCss.input_wrapper}>
                    <label>Confirm Password*</label>
                    <input type='password' name='conpassword' required />
                </div>
                <div className={addNewUserCss.input_wrapper}>
                <label>Bio*</label>
                <input type='text' name='userbio' required />
                </div>
                <div className={addNewUserCss.input_wrapper}>
                <label>Upload Image*</label>
                <input type='file' name='userImage'/>
                </div> 
                <div className={addNewUserCss.input_wrapper}>
                <label>User Role*</label>
                <Select
                    name="userrole"
                    options={options}
                    // onChange={handleSelectChange}
                />
                </div>
                <div className={addNewUserCss.button_wrapper}>
                    <button>Submit</button>
                </div>
           </form>
           
        </div>
    </div>
  )
}
