import React, { useEffect, useState } from 'react'
import { GetDebitInitData } from "../../../api/DebitApi";
import DebitVouHisCss from './Css/DebitVoucherHistory.module.css'
import Select from 'react-select'
const DebitVoucherHistory = () => {
    const [tableData, setTableData] = useState()
    async function getDataFormDatabase() {
        let myData = await GetDebitInitData()
        setTableData(myData)
    }

    useEffect(() => {
        getDataFormDatabase()
    }, [])
    return (

            <section className={DebitVouHisCss.debitVoucherWrapper}>
                <div className={DebitVouHisCss.inner_headign}>
                    <h2 className={DebitVouHisCss.saleHeading}>Debit Voucher History</h2>
                    <div className={DebitVouHisCss.search_section}>
                        <div className={DebitVouHisCss.input_section}>
                            <label>Start Date</label>
                            <input type={'date'}  />
                        </div>
                        <div className={DebitVouHisCss.input_section}>
                            <label>End Date</label>
                            <input type={'date'} />
                        </div>
                        <div className={DebitVouHisCss.input_section}>

                            <label>Select Customer</label>
                            <input type={'date'} />
                       
                        </div>
                        <div className={DebitVouHisCss.input_section}>

                            <label>Transaction Type</label> 
                            <input type={'date'} />
                           
                        </div>
                       
                    </div>
                    <div className={DebitVouHisCss.input_section}>
                            <div className={DebitVouHisCss.action_btn}>
                                <button>search</button>
                                <button>reset</button>
                            </div>
                    </div>
                    
                    <div className={DebitVouHisCss.tableSection}>
                        <table>
                           <thead>
                           <tr>
                                    <th>Sl</th>
                                    <th>Date</th>
                                    <th>Vaoucher No</th>
                                    <th>Reference No</th>
                                    <th>Ammount</th>
                                    <th>Type of Transaction</th>
                                    <th>Process By</th>
                                    <th>Action</th>
                                </tr>
                           </thead>
                               
                            <tbody>
                                {tableData?.map((item, index) => {
                                    return <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{new Date(item.created_date).toLocaleDateString()}</td>
                                        <td>{item.id}</td>
                                        <td>{item.particulars} (#{item.identity})</td>
                                        <td>{item.paymentAmmount}</td>
                                        <td>{item.paymentType}</td>
                                        <td>{item.userName}</td>
                                        <td>action</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
  
    )
}

export default DebitVoucherHistory