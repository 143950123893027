import React, { useEffect, useState } from "react";
import {
  Link,
  Route,
  Routes,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ComplainCategories from "./SettingComponents/ComplainCategories";
import ComplainPriority from "./SettingComponents/ComplainPriority";
import ComplainType from "./SettingComponents/ComplainType";
import ComplainSettingMainCss from "./css/ComplainSettingMain.module.css";

const ComplainSettingMain = ({ activity }) => {
  const [activeCustom, setActiveCustom] = useState(1);
  const [searchedParam, setSearchedParam] = useSearchParams();
  const myParams = useParams();
  const ComplainButton = [
    {
      name: "Complain Priorities",
      link: "manage-customer/complain/complain_setting?page=complainpriorities",
    },
    {
      name: "Complain Type",
      link: "manage-customer/complain/complain_setting?page=complaintype",
    },
    {
      name: "Complain Categories",
      link: "manage-customer/complain/complain_setting?page=complaincategories",
    },
  ];
  return (
    <>
      <section className={ComplainSettingMainCss.salesMain}>
        <div className={ComplainSettingMainCss.innerSale}>
          <h2 className={ComplainSettingMainCss.saleHeading}>
            Complain Setting
          </h2>

          <div className={ComplainSettingMainCss.sub_menu_section}>
            {ComplainButton.map((item, index) => {
              return (
                <Link
                  key={index}
                  className={
                    activeCustom === index + 1
                      ? `${ComplainSettingMainCss.activeCustom}`
                      : ""
                  }
                  to={item.link}
                  onClick={() => {
                    setActiveCustom(index + 1);
                  }}
                >
                  {item.name}
                </Link>
              );
            })}
          </div>

          <div>
            {activeCustom === 1 ? <ComplainPriority /> : ""}
            {activeCustom === 2 ? <ComplainType /> : ""}
            {activeCustom === 3 ? <ComplainCategories /> : ""}
          </div>
        </div>
      </section>
    </>
  );
};

export default ComplainSettingMain;
