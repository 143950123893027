import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DeleteItemExpress, GetAllDataExpress, GetFilteredDataExpress, SaveDataExpress, UpdateItemExpress } from '../../api/ExpressFunctions'
import PendingEditViewCss from './css/PendingEditView.module.css'

const PendingEditView = () => {
    const [tableData, setTableData] = useState([])
    const [headData, setHeadData] = useState()
    const [errMessage, setErrMessage] = useState()
    const [expenseData, setExpenseData] = useState()

    const [debitData, setDebitData] = useState()
    const [mySearchParam] = useSearchParams()
    const [runningExpenseNo, setRunningExpenseNo] = useState()

    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        if (name === 'price') {
            value = parseFloat(value)
        }
        if (name==='expenseHeadID') {
             headData?.forEach((item)=>{
                 if (item.id===parseInt(value)) {
                  setExpenseData({ ...expenseData, [name]: value, selectedGroup:item.selectedGroup})
                 }
             })
         }else{
             setExpenseData({ ...expenseData, [name]: value })
         }
    }
    async function onAddClick() {
        let particularErr = ValidateFunction('particular', 'Particular ')
        let priceErr = ValidateFunction('price', 'Price ')
        let noteErr = ValidateFunction('note', 'Particular is ')
        let expenseHeadIDErr = ValidateFunction('expenseHeadID', 'Particular is ')
        setErrMessage({ particularErr, priceErr, noteErr, expenseHeadIDErr })
        if (!particularErr && !priceErr && !noteErr && !expenseHeadIDErr) {
            let finalData = { ...expenseData }
            for (let index = 0; index < headData.length; index++) {
                const element = headData[index];
                if (element.id === parseInt(finalData.expenseHeadID)) {
                    finalData = { ...finalData, expenseHeadID: element.id, expenseID: '' }
                }

            }
            setTableData([...tableData, finalData])
        }

    }
    function ValidateFunction(field, message) {
        let text = ""
        if (!expenseData?.[field]) {
            text = message + " is Required"
        }
        return text;
    }
    function onTableDataChange(e, id, index) {
        let name = e.target.name
        let value = e.target.value
        // tableData[index][name] = value
        let newArr = []
        tableData.forEach((element, mindex) => {
          if (index===mindex) {
            let data = {...element, [name]:value}
            // console.log(data)
            newArr.push(data)
          }else{
            newArr.push(element)
          }
        });
       setTableData(newArr)
    }
    const [datatoRemove, setDatatoRemove] = useState([])

    function onRemoveClick(index) {
        let newArr = []
        let removeArr = []
        tableData?.filter((fitem, myIndex) => {
            return myIndex !== index ? newArr.push(fitem) : ''
        })
        tableData?.filter((fitem, myIndex) => {
            return myIndex === index ? removeArr.push(fitem) : ''
        })
        setTableData(newArr)
        setDatatoRemove([...datatoRemove, ...removeArr])

    }
    function getTableTotal() {
        let total = 0
        tableData?.forEach((item) => {
            total += item.price ?  parseFloat(item.price):0
        })
        return total
    }
    function getTableTotalPayment() {
        let total = 0
        tableData?.forEach((item) => {
            total += item.paymentAmmount?  parseFloat(item.paymentAmmount):0
        })
        return total
    }
    let [paymentInfo, setPaymentInfo] = useState({
        paymentAmmount:0,
        paymentDate:'',
        paymentType:'cash',
        expenseNote:''
    })
    function onPaymentDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        if (name==='paymentAmmount') {
            value = parseFloat(value)
            if (value>getTableTotal()) {
                value=getTableTotal()
            }
        }
        setPaymentInfo({...paymentInfo, [name]:value})
    }
    
  async function onUpdateClick() {
        let userID = window.localStorage.getItem('user_id')
        let dateErr = ValidateFunctionpayment('paymentDate', 'Payment Date ')
        let paymentTypeErr = ValidateFunctionpayment('paymentType', 'Payment Type ')
        let expenseNoteErr = ValidateFunctionpayment('expenseNote', 'Expense Note  ')
        setErrMessage({...errMessage, dateErr, paymentTypeErr, expenseNoteErr})
        if (tableData.length>0) {
            if (!dateErr && !paymentTypeErr && !expenseNoteErr) {
                let expenseData = {
                    ammount: getTableTotal(),
                    paymentAmmount:getTableTotalPayment(),
                    paymentType:paymentInfo.paymentType,
                    paymentDate:paymentInfo.paymentDate,
                    expenseNote:paymentInfo.expenseNote,
                    pending:1,
                    
                }
               // console.log(paymentInfo)

              let dataID = paymentInfo.id
              await UpdateItemExpress('expense', {...expenseData}, dataID)

              if (getTableTotal('paymentAmmount')>0) {
                let mdebitData = {
                    paymentAmmount:parseFloat(getTableTotalPayment()),
                }
                await UpdateItemExpress('debit_voucher', {...mdebitData}, debitData.id)
            }
            tableData.map(async(item)=>{
                if (!item.id) {
                    let itemData = {
                        expenseID:dataID,
                        particular:item.particular,
                        note:item.note,
                        expenseHeadID:item.expenseHeadID,
                        selectedGroup:item.selectedGroup,
                        price:item.price,
                        paymentAmmount:item.paymentAmmount ? item.paymentAmmount:0,
                        created_date:new Date().getTime(),
                    }
               //     console.log(itemData)
                   await SaveDataExpress('expense_items', {...itemData})
                }
               })
               datatoRemove.map(async(item)=>{
                    console.log(item)
                    if (item.id) {
                        await DeleteItemExpress('expense_items', item.id)
                    }
               })
               let ExpenseSummeryDetals ={
                expensID:dataID,
                status:'Expense Edited',
                attemptByID:userID,
                note:paymentInfo.expenseNote,
                created_date:new Date().getTime(),
               }
               await SaveDataExpress('expense_summery', {...ExpenseSummeryDetals})
          
               
            alert('Data Saved')
            }
        }else{
            alert('expense data needed')
        }
    }
    function ValidateFunctionpayment(field, message) {
        let text = ""
        if (!paymentInfo?.[field]) {
            text = message + " is Required"
        }
        return text;
    }

    async function getDataFromDatabase() {

         let exno = mySearchParam.get('exno')
         let mheadData = await GetAllDataExpress('expense_head')
        // console.log(mheadData)
        let paymentData = await GetFilteredDataExpress('expense', 'id', exno)
        let mdebitData = await GetFilteredDataExpress('debit_voucher', 'identity', exno)
        let myTableData = await GetFilteredDataExpress('expense_items', 'expenseID', exno)
      // console.log(mdebitData[0])
        setRunningExpenseNo(exno) 
        setHeadData(mheadData)
         setPaymentInfo(paymentData[0])
         setTableData(myTableData)
         setDebitData(mdebitData[0])
    }
    useEffect(() => {
        getDataFromDatabase()
    }, [])
    return (
        <section className={`${PendingEditViewCss.salesMain} ${PendingEditViewCss.expense}`}>
            <div className={PendingEditViewCss.innerSale}>
                <h2 className={PendingEditViewCss.saleHeading} style={{margin:'10px 0 0 0'}}>Add New Expense</h2>
                <h3 style={{margin:'10px 0', padding:'0', color:'#A91079'}}>Expense Number :  {runningExpenseNo}</h3>
                <div className={`${PendingEditViewCss.input_section} ${PendingEditViewCss.add_expense}`}>
                    <div className={PendingEditViewCss.input_common}>
                        <label>Particulars</label>
                        <input name='particular' type={'text'} onChange={onDataChange} />
                        {errMessage ? <p className={PendingEditViewCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.particularErr}</p> : ''}
                    </div>
                    <div className={PendingEditViewCss.input_common}>
                        <label>Price</label>
                        <input name='price' type={'number'} onChange={onDataChange}/>
                        {errMessage ? <p className={PendingEditViewCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.priceErr}</p> : ''}
                    </div>
                    <div className={PendingEditViewCss.input_common}>
                        <label>Note</label>
                        <input name='note' type={'text'} onChange={onDataChange} />
                        {errMessage ? <p className={PendingEditViewCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.noteErr}</p> : ''}
                    </div>
                    <div className={PendingEditViewCss.input_common}>
                        <label>head</label>
                        <select name='expenseHeadID' onChange={onDataChange}>
                            <option value={''}>Select Head</option>
                            {headData?.filter((fitem) => {
                                return fitem.status ? fitem : ''
                            })
                                .map((item, index) => {
                                    return <option key={index} value={item.id}>{item.headName}</option>
                                })}

                        </select>
                        {errMessage ? <p className={PendingEditViewCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.expenseHeadIDErr}</p> : ''}
                    </div>

                    <div className={PendingEditViewCss.input_common}>
                        <button onClick={onAddClick}>Add New</button>
                    </div>
                </div>
                <div className={PendingEditViewCss.tableSection}>
                    <table style={{ marginTop: '0' }}>
                        <tbody>
                            <tr>
                                <th>Sl</th>
                                <th>Particulars</th>
                                <th>Note</th>
                                <th>Expense Head</th>
                                <th>Price</th>
                                <th>Paid Amount</th>
                                <th>Action</th>
                            </tr>
                            {tableData?.map((item, index) => {
                                return <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.particular}</td>
                                    <td>{item.note}</td>
                                    <td>{
                                        headData?.filter((fitem)=>{
                                            return item.expenseHeadID === fitem.id? fitem:''
                                        }).map((mitem)=>{
                                            return mitem.headName
                                        })
                                    }</td>
                                    <td style={{ textAlign: 'right' }}><input type={'number'} name='price' value={item.price ? item.price:''} onChange={(e)=>{onTableDataChange(e, item.id, index)}}/></td>
                                    <td style={{ textAlign: 'right' }}><input type={'number'} name='paymentAmmount' value={item.paymentAmmount ? item.paymentAmmount:''}  onChange={(e)=>{onTableDataChange(e, item.id, index)}}/></td>
                                    <td><FontAwesomeIcon onClick={() => { onRemoveClick(index) }} icon={faTrashCan} className='color-red' /></td>
                                </tr>
                            })}
                            <tr>
                                <td colSpan={4} style={{ textAlign: 'right', fontWeight: 'bold', color: 'black', fontSize:'1.25rem' }}>Grand Total</td>
                                <td style={{ textAlign: 'right', fontWeight: 'bold', color: 'black', fontSize:'1.25rem' }}>{getTableTotal()}</td>
                                <td style={{ textAlign: 'right', fontWeight: 'bold', color: 'black', fontSize:'1.25rem' }}>{getTableTotalPayment()}</td>
                                <td ></td>
                            </tr>
                            <tr>
                                <td colSpan={7} style={{ textAlign: 'center', fontWeight: 'bold', color: 'red', fontSize:'1.25rem' }}>Due Amount : {getTableTotal()-getTableTotalPayment()}</td>
                               
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={PendingEditViewCss.save_expense}>
                    <div className={PendingEditViewCss.input_common}>
                        <label>Payment Date</label>
                        <input name='paymentDate' type={'date'} onChange={onPaymentDataChange} value={paymentInfo?.paymentDate}/>
                    </div>
                    {errMessage ? <p className={PendingEditViewCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.dateErr}</p> : ''}
                    {/* {errMessage ? <p className={PendingEditViewCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.paymentErr}</p> : ''} */}
                    <div className={PendingEditViewCss.input_common}>
                        <label>Payment Type</label>
                        <select name='paymentType' onChange={onPaymentDataChange} value={paymentInfo?.paymentType}>
                            <option value={'cash'}>Cash</option>
                            <option value={'cheque'}>Cheque</option>
                        </select>
                    </div>
                    {errMessage ? <p className={PendingEditViewCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.paymentTypeErr}</p> : ''}
                    <div className={PendingEditViewCss.input_common}>
                        <label>Expense Note</label>
                        <input name='expenseNote' type={'text'} onChange={onPaymentDataChange} value={paymentInfo?.expenseNote}/>
                    </div>
                    {errMessage ? <p className={PendingEditViewCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.expenseNoteErr}</p> : ''}
                    <div className={PendingEditViewCss.input_common}>
                        <button onClick={onUpdateClick}>Update Data</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PendingEditView
