import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { UpdateProductList } from "../../../../api/ProductListApi";
import {
  DeleteVariaceImage,
  DeleteVariance,
  GetFilterProductVariance,
  GetVarianceImage,
  UpdateProductVariance,
} from "../../../../api/ProductVarianceApi";
import productAttributeEditCss from "./css/ProductsAttributesEdit.module.css";
import AddVariance from "./editpop/AddVariance";
import EditVariance from "./editpop/EditVariance";

const ProductsAttributesEdit = ({ showState, item }) => {
  const myParams = useParams();
  let [varianceDataOld, setVarDataOld] = useState([]);
  const [addPopUp, setAddPopUp] = useState();

  async function SaveToDatabase() {
    let hasAge = ageIndicationValue ? 1 : 0;
    try {
      await UpdateProductList(item.id, { hasAge: hasAge });
      toast.success("Information Updated");
    } catch (error) {
      toast.error(error.message);
    }
  }

  const [ageIndicationValue, setAgeIndicationValue] = useState(false);

  function onAgeChange(e) {
    let value = e.target.checked;
    setAgeIndicationValue(value);
  }

  // function deleteClick(e) {
  //   let identity = e.target.getAttribute("identifire");
  //   let arr = [];
  //   varianceData.forEach((element, index) => {
  //     if (identity === element.Variance_name) {
  //       varianceData.splice(index, 1);
  //     } else {
  //       arr.push(element);
  //     }
  //   });
  //   setVarData(arr);
  // }
  const [deleteItem, setDeleteItem] = useState();

  async function OlddeleteClick(item) {
    setDeleteItem(item);
  }
  const [editVar, setEditVar] = useState();
  function onEditClick(item) {
    setEditVar(item);
  }

  async function getSelectedData() {
    let variance = await GetFilterProductVariance(
      `product_id = ${myParams.data}`
    );
    let sortArr = variance.sort((a, b) =>
      parseInt(a.Variance_name) > parseInt(b.Variance_name) ? 1 : -1
    );
    setVarDataOld(sortArr);
  }

  useEffect(() => {
    getSelectedData();
  }, [showState, addPopUp, deleteItem, editVar]);

  return (
    <div className={productAttributeEditCss.productAttributeWrapper}>
      {addPopUp && <AddVariance popUp={addPopUp} setPopUp={setAddPopUp} />}
      {deleteItem && (
        <ConfirmAlert deleteItem={deleteItem} setDeleteItem={setDeleteItem} />
      )}
      {editVar && (
        <EditVariance popUp={editVar} setPopUp={setEditVar} mainitem={item} />
      )}
      <div className={productAttributeEditCss.att_head}>
        <h4>Product Attributes</h4>
        <div>
          <input type="checkbox" id="age" name="age" onChange={onAgeChange} />
          <label htmlFor="age">Have Age Variations?</label>
        </div>
      </div>
      <section className={productAttributeEditCss.main}>
        <div className={productAttributeEditCss.att_buttons}>
          <button
            onClick={() => {
              setAddPopUp(item);
            }}
          >
            Add Variance
          </button>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <th>Sl</th>
                <th>Variance Name</th>
                <th>Variations</th>
                <th>Variation Image</th>
                <th>Shade Image</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {varianceDataOld.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.Variance_name}</td>
                    <td>-</td>
                    <td>
                      {<ProductVarianceImageShow name={item.MainIamge} />}
                    </td>
                    <td>
                      {<ProductVarianceImageShow name={item.ShadeIamge} />}
                    </td>
                    <td>
                      {" "}
                      <ToggleSwitch
                        toggleOnOff={item.status}
                        id={item.id}
                        tableData={varianceDataOld}
                        setTableData={setVarDataOld}
                      />
                    </td>
                    <td>
                      <div className={productAttributeEditCss.table_buttons}>
                        <button
                          onClick={() => {
                            onEditClick(item);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => {
                            OlddeleteClick(item);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
              {/* {TableItem} */}
            </tbody>
          </table>
        </div>
        {/*  onClick={SaveToDatabase} */}
        {/* <input onClick={SaveToDatabase} className={productAttributeEditCss.submitbutton} type={"submit"} value="Update data" /> */}
        <div className={productAttributeEditCss.btn_group}>
        <button
          className={productAttributeEditCss.submitbutton}
          onClick={SaveToDatabase}
        >
          Update Data
        </button>
          </div>
     
      </section>
    </div>
  );
};

export default ProductsAttributesEdit;

const ToggleSwitch = ({ toggleOnOff, id, tableData, setTableData }) => {
  function toggleClick() {
    let myArr = [];
    tableData?.map(async (item) => {
      if (item.id === id) {
        let okActive = !item.status;
        item = { ...item, status: okActive };
        myArr.push(item);
        await UpdateProductVariance(id, { status: okActive });
      } else {
        myArr.push(item);
      }
    });
    setTableData(myArr);
  }

  return (
    <>
      {toggleOnOff ? (
        <div className={productAttributeEditCss.switch_on}>
          {" "}
          <FontAwesomeIcon
            icon={faToggleOn}
            onClick={() => {
              toggleClick(id);
            }}
          />
        </div>
      ) : (
        <div className={productAttributeEditCss.switch_off}>
          <FontAwesomeIcon
            icon={faToggleOff}
            onClick={() => {
              toggleClick(id);
            }}
          />
        </div>
      )}
    </>
  );
};

const ConfirmAlert = (props) => {
  const { deleteItem, setDeleteItem } = props;

  async function yesClick() {
    try {
      await DeleteVariance(deleteItem.id);
      await DeleteVariaceImage({ image_name: deleteItem.ShadeIamge });
      await DeleteVariaceImage({ image_name: deleteItem.MainIamge });
      setDeleteItem();
    } catch (error) {
      console.log(error);
    }
  }
  function noClick() {
    setDeleteItem();
  }

  return (
    <div className={productAttributeEditCss.wrapper}>
      <div className={productAttributeEditCss.conAlert}>
        <h2>Do you Want To Delete This?</h2>
        <div className={productAttributeEditCss.button_section}>
          <button onClick={yesClick}>Yes</button>
          <button onClick={noClick}>No</button>
        </div>
      </div>
    </div>
  );
};

const ProductVarianceImageShow = ({ name }) => {
  const [image, setImage] = useState();

  async function getLink() {
    // console.log(name);
    if (name) {
      let imageInfo = await GetVarianceImage({ image_name: name });
      // console.log(imageInfo);
      if (imageInfo.imageExist) {
        setImage(imageInfo.url);
      } else {
        setImage("");
      }
    } else {
      setImage("");
    }
  }
  useEffect(() => {
    getLink();
  }, [name]);
  return <> {image ? <img src={image} alt={name} /> : ""}</>;
};
