import React, { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
// import { DistrictData, AreaData } from "../../../api/MyLocation";
import SalesReportViewNew from "../SalesReportPDFnew/SalesReportViewNew";
import SalesReportViewDetailsNew from "../SalesReportPDFnew/SalesReportViewDetailsNew";
import SalesReportNewCss from "./css/SalesReportNew.module.css";
import { toast } from "react-toastify";

const SalesReportNew = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<MainComp />} />
        <Route path="/sales_view_new" element={<SalesReportViewNew />} />
        <Route
          path="/sales_view_details"
          element={<SalesReportViewDetailsNew />}
        />
      </Routes>
    </>
  );
};

export default SalesReportNew;
const MainComp = () => {
  const [searchData, setSearchData] = useState();
  let DivitionArray = [
    "Dhaka",
    "Chittagong",
    "Khulna",
    "Mymensingh",
    "Sylhet",
    "Rangpur",
    "Rajshahi",
    "Barisal",
  ];

  const myNavigate = useNavigate();
  function onDataChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    // console.log(e.target.type) 2022-08-07T23:32:18
    // if (e.target.type === "date") {
    //   if (name === "start_date") {
    //     value = value;
    //   } else {
    //     value = value;
    //   }
    // }
    setSearchData({ ...searchData, [name]: value });
  }
  function onCheckClick() {
    // console.log(DistrictData)
    // DistrictData.map((item)=>{
    //   console.log(item.district)
    // })
  }

  async function onSalesReportClick() {
    if (searchData?.start_date && searchData?.end_date) {
      let dateQueryUrl = `start_date=${searchData?.start_date}&end_date=${searchData?.end_date} `;
      window.open("/reports/sales-report/sales_view_new?" + dateQueryUrl);
    } else {
      toast.warn("Select Date to Continue");
    }
  }

  // function onCombineClick() {
  //   if (searchData?.start_date && searchData?.end_date) {
  //     let dateQueryUrl = `start_date=${searchData?.start_date}&end_date=${searchData?.end_date} `;
  //     window.open(
  //       "/reports/sales-report/sales_view_new?" +
  //         dateQueryUrl +
  //         "&rtype=combine"
  //     );
  //   } else {
  //     toast.warn("Select Date to Continue");
  //   }
  // }
  // async  function onDeliveryReportClick() {
  //   if (searchData?.start_date && searchData?.end_date) {
  //     let dateQueryUrl = `&start_date=${searchData?.start_date}&end_date=${searchData?.end_date} `
  //     window.open('/admin/report/sales_report_new/sales_view_details?type=delivery'+dateQueryUrl)
  //   }else{
  //     alert('select date to continue')
  //   }

  //   }
  async function packagingReportClick() {
    if (searchData?.start_date && searchData?.end_date) {
      let dateQueryUrl = `&start_date=${searchData?.start_date}&end_date=${searchData?.end_date} `;
      window.open(
        "/reports/sales-report/sales_view_details?type=packaging" + dateQueryUrl
      );
    } else {
      toast.warn("Select Date to Continue");
    }
  }

  function onConfirmClick() {
    if (searchData?.start_date && searchData?.end_date) {
      let dateQueryUrl = `&start_date=${searchData?.start_date}&end_date=${searchData?.end_date} `;
      window.open(
        "/reports/sales-report/sales_view_details?type=confirm" + dateQueryUrl
      );
      //  myNavigate('/admin/report/sales_report/sales_view_details?type=confirm'+dateQueryUrl, '_blank')
    } else {
      toast.warn("Select Date to Continue");
    }
  }
  function onPendingClick() {
    if (searchData?.start_date && searchData?.end_date) {
      let dateQueryUrl = `&start_date=${searchData?.start_date}&end_date=${searchData?.end_date} `;
      window.open(
        "/reports/sales-report/sales_view_details?type=pending" + dateQueryUrl
      );
      myNavigate();
    } else {
      toast.warn("Select Date to Continue");
    }
  }
  function onDeclineClick() {
    if (searchData?.start_date && searchData?.end_date) {
      let dateQueryUrl = `&start_date=${searchData?.start_date}&end_date=${searchData?.end_date} `;
      window.open(
        "/reports/sales-report/sales_view_details?type=declined" + dateQueryUrl
      );
    } else {
      toast.warn("Select Date to Continue");
    }
  }

  return (
    <section className={SalesReportNewCss.heading}>
      <div className={SalesReportNewCss.inner_headign}>
        <h2 className={SalesReportNewCss.headign_h2} onClick={onCheckClick}>
          Sales Related Reports
        </h2>
        <div className={SalesReportNewCss.input_section}>
          {/* divition */}
          <div className={SalesReportNewCss.address}>
            <div className={SalesReportNewCss.common}>
              <label>Division:</label>
              <input
                name="divition_name"
                type="text"
                onChange={onDataChange}
                value={
                  searchData?.divition_name ? searchData?.divition_name : ""
                }
                placeholder="type division name here"
              />

              {DivitionArray?.filter((fitem) => {
                let msearchText = searchData?.divition_name?.toLowerCase();
                let matchText = fitem?.toLowerCase();

                if (
                  msearchText &&
                  matchText !== msearchText &&
                  matchText.includes(msearchText)
                ) {
                  return fitem;
                } else {
                  return null;
                }
                // return conditionMatch &&  matchText.includes(msearchText) || matchTextnumber.includes(msearchText)
              })
                .slice(0, 10)
                .map((item, index) => {
                  return (
                    <p
                      key={index}
                      onClick={() => {
                        setSearchData({ ...searchData, divition_name: item });
                      }}
                    >
                      {item}
                    </p>
                  );
                })}
            </div>
            {/* District */}
            <div className={SalesReportNewCss.common}>
              <label>District:</label>
              <input
                name="district_name"
                type="text"
                onChange={onDataChange}
                value={
                  searchData?.district_name ? searchData?.district_name : ""
                }
                placeholder="type district name here"
              />
            </div>
            {/* Area */}
            <div className={SalesReportNewCss.common}>
              <label>Area:</label>
              <input
                name="area_name"
                type="text"
                onChange={onDataChange}
                value={searchData?.area_name ? searchData?.area_name : ""}
                placeholder="type area name here"
              />
            </div>
          </div>
          <div className={SalesReportNewCss.dates}>
            <div className={SalesReportNewCss.common}>
              <label>Start Date</label>
              <input name="start_date" type="date" onChange={onDataChange} />
            </div>
            <div className={SalesReportNewCss.common}>
              <label>End Date</label>
              <input name="end_date" type="date" onChange={onDataChange} />
            </div>
          </div>
        </div>

        <div className={SalesReportNewCss.buttons}>
          <button
            onClick={onSalesReportClick}
            className={`${SalesReportNewCss.bg_green} ${SalesReportNewCss.p_10}`}
          >
            Sales Report:
          </button>

          <button
            className={`${SalesReportNewCss.bg_green} ${SalesReportNewCss.p_10}`}
            onClick={packagingReportClick}
          >
            Packaging Report:
          </button>

          <button
            className={`${SalesReportNewCss.bg_orange} ${SalesReportNewCss.p_10}`}
            onClick={onConfirmClick}
          >
            Comfirm Report:
          </button>

          <button
            onClick={onPendingClick}
            className={`${SalesReportNewCss.bg_green} ${SalesReportNewCss.p_10}`}
          >
            Pending Report
          </button>

          <button
            onClick={onDeclineClick}
            className={`${SalesReportNewCss.bg_orange} ${SalesReportNewCss.p_10}`}
          >
            Declined Report
          </button>
        </div>
      </div>
    </section>
  );
};
