import React, { useEffect } from 'react';
import SeoCss from "./productlistmodels/seopop.module.css";

import { toast } from 'react-toastify';
import { UpdateBrandList } from '../../api/BrandListApi';

const BrandSEOpop = ({popData, setPopData}) => {
      async  function onSeoDataSubmit(e) {
            e.preventDefault()
            const metatitle = e.target.metatitle.value
            const metadescription = e.target.metadescription.value
            const metakeywords = e.target.metakeywords.value
            const brand_slug = e.target.brand_slug.value
            const og_image = e.target.og_image.files
            
            try {
                await UpdateBrandList(popData.id, {metatitle, metadescription, metakeywords, brand_slug})
                toast.success('SEO Data Updated Successfully')
                setPopData()
                document.body.style.overflow = 'auto'
             
            } catch (error) {
                toast.error(error.message)
            }
     }

  return (
    <div className={SeoCss.main}>
       <div className={SeoCss.data_holder}>
       <h3>Seo Data Add</h3>
        <form onSubmit={onSeoDataSubmit}>
            <div className={SeoCss.common}>
                <label>Brand Slug : </label>
                <input type='text' name='brand_slug' defaultValue={popData.brand_slug}/>
            </div>
            <div className={SeoCss.common}>
                <label>Meta Title </label>
                <input type='text' name='metatitle' defaultValue={popData.metatitle}/>
            </div>
            <div className={SeoCss.common}>
                <label>Meta Description </label>
                <input type='text' name='metadescription' defaultValue={popData.metadescription}/>
            </div>
            <div className={SeoCss.common}>
                <label>Meta Keywords </label>
                <input type='text' name='metakeywords' defaultValue={popData.metakeywords}/>
            </div>
            <div className={SeoCss.common}>
                <label>Og Image </label>
                <input type='file' name='og_image' multiple={false}/>
            </div>
            <div className={SeoCss.btns}>
            <button type='submit'>update</button>
            <button onClick={()=>{setPopData(); document.body.style.overflow = 'auto'}}>Close</button>
        </div>
        </form>
       
       </div>
    </div>
  )
}

export default BrandSEOpop