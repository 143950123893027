import React from 'react'
import { NavLink, Route, Routes } from 'react-router-dom'
import AddnewAgent from './AddnewAgent'
import DeliveryAgentList from './DeliveryAgentList'
import DeliveryAgentMainCss from './css/DeliveryAgentMain.module.css'
const DeliveryAgentMain = () => {
    return (
        <>
            <div className={DeliveryAgentMainCss.menupart}>
                <ul className={DeliveryAgentMainCss.menuitems}>
                    <NavLink to={""}>
                        <li >Delivery Agent List</li>
                    </NavLink>

                    <NavLink to={"addnewagent"}>
                        <li >Add New Delivery Agent</li>
                    </NavLink>

                </ul>
            </div>
            <div>
                <Routes>
                    <Route path='/' element={<DeliveryAgentList />} />
                    <Route path='addnewagent' element={<AddnewAgent />} /> 
                </Routes>
            </div>
        </>
    )
}

export default DeliveryAgentMain
