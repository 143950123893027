import React from 'react'
import { NavLink, Route, Routes } from 'react-router-dom'
import offerMainCss from './css/bogomain.module.css'
import GroupList from './GroupList'
import AssignProducts from './AssignProducts'

const BogoMain = () => {
  return (
    <>
       <div className={offerMainCss.menupart}>
              <ul className={offerMainCss.menuitems}>
                  <NavLink to={"/sales/bogo"}>
                      <li >Group list</li>
                  </NavLink>
                  <NavLink to={"/sales/bogo/assign_products"}>
                      <li >Assign Products</li>
                  </NavLink>
              </ul>                 
        </div>
            <Routes>
                <Route path='/' element={<GroupList />} />
                <Route path='assign_products' element={<AssignProducts />} />
            </Routes>
    </>
         
          
  )
}

export default BogoMain