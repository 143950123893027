import MyAxios from 'axios'
import { API_LINK} from './APILink'
const MY_API_LINK = `${API_LINK}purchase/`

const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}

export async function SavePurchaseData(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function UpdatePurchaseData(id, data) {
    try {
        let result = await MyAxios.put(`${MY_API_LINK}${id}`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function GetsinglePurchase(id) {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}/${id}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetTodayPurchase(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}today`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function GetLastPurchaseNumber() {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}last-number`,)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetPurchasePending() {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}get-pending`,)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetPurchaseCompleate(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}get-complete`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function GetLastPurchasePrice(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}last-price`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}

// purchase item api
const ITEM_API_LINK = `${API_LINK}purchase-items/`
export async function SavePurchaseItemData(data) {
    try {
        let result = await MyAxios.post(`${ITEM_API_LINK}add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function UpdatePurchaseItemData(id, data) {
    try {
        let result = await MyAxios.put(`${ITEM_API_LINK}${id}`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function GetPurchaseItems(query) {
    try {
        let result = await MyAxios.get(`${ITEM_API_LINK}filter/${query}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}

// Purchase Variance api
const VARIANCE_API_LINK = `${API_LINK}purchase-variance/`

export async function SavePurchaseVariance(data) {
    try {
        let result = await MyAxios.post(`${VARIANCE_API_LINK}add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetFilterPurchaseVariance(query) {
    try {
        let result = await MyAxios.get(`${VARIANCE_API_LINK}filter/${query}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}

// Purchase Summery api
const SUMMERY_API_LINK = `${API_LINK}purhcase-summery/`

export async function SavePurchaseSummery(data) {
    try {
        let result = await MyAxios.post(`${SUMMERY_API_LINK}add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetSummeryList(data) {
    try {
        let result = await MyAxios.post(`${SUMMERY_API_LINK}get-summery`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}