import React, { useEffect, useState } from "react";
import { AreaData, DistrictData } from "../../api/MyLocation";
import PopUpLocaCss from "./salesCss/PopUpLocation.module.css";
import { UpdateCustomerAddress } from "../../api/CustomerApi";
import { toast } from "react-toastify";

const PopUpLocation = ({ setPopAddress, orderData }) => {
  let DivitionArray = [
    "Dhaka",
    "Chittagong",
    "Khulna",
    "Mymensingh",
    "Sylhet",
    "Rangpur",
    "Rajshahi",
    "Barisal",
  ];
  const [districtData, setDistrictData] = useState();
  const [areaData, setAreaData] = useState();
  const [deliveryCharge, setDeliveryCharge] = useState();

  const [data, setdata] = useState({
    Address: "",
    Phone: "",
    Divition: "",
    District: "",
    Area: "",
  });

  async function onUpdateClick() {
    document.body.style.overflow = "auto";
    console.log(data, orderData);

    try {
      await UpdateCustomerAddress(orderData.user_address_id, data);
      toast.success("This order Address Updated!");
    } catch (error) {
      toast.error(error.message);
    }
  }

  function onCLoseClick() {
    document.body.style.overflow = "auto";
    setPopAddress(false);
  }

  function onDataChange(e) {
    const DivitionValue = e.target.value;
    const DivitionName = e.target.name;
    setdata({ ...data, [DivitionName]: DivitionValue });
    let arr = [];
    DistrictData.forEach((element) => {
      if (element.division === e.target.value) {
        arr.push(element.district);
      }
    });
    arr.sort();
    setDistrictData(arr);
  }

  function onDisChange(e) {
    const DistrictValue = e.target.value;
    const DistrictName = e.target.name;

    setdata({ ...data, [DistrictName]: DistrictValue });
    if (DistrictValue === "Dhaka") {
      setDeliveryCharge(60);
    } else {
      setDeliveryCharge(130);
    }
    let arr = [];
    AreaData.forEach((element) => {
      if (element.distrctName === e.target.value) {
        arr.push(element.AreaName);
      }
    });
    arr.sort();
    setAreaData(arr);
  }

  function OnAreaChange(e) {
    const AreaValue = e.target.value;
    const AreaName = e.target.name;
    setdata({ ...data, [AreaName]: AreaValue });
  }

  const HandelChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setdata({ ...data, [name]: value });
  };

  async function automatedData() {
    let disArray = [];
    DistrictData.forEach((element) => {
      if (element.division === orderData.Divition) {
        disArray.push(element.district);
      }
    });
    disArray.sort();
    setDistrictData(disArray);

    let areaArray = [];
    AreaData.forEach((element) => {
      if (element.distrctName === orderData.District) {
        areaArray.push(element.AreaName);
      }
    });
    areaArray.sort();
    setAreaData(areaArray);
    let myNewData = {
      Address: orderData.Address,
      Phone: orderData.AddressPhone,
      Divition: orderData.Divition,
      District: orderData.District,
      Area: orderData.Area,
    };

    setdata(myNewData);
  }
  useEffect(() => {
    automatedData();
  }, [orderData]);

  return (
    <div className={PopUpLocaCss.popUpLocationSection}>
      <div className={PopUpLocaCss.popUp}>
        <h2 className={PopUpLocaCss.popheadign}>Edit Delivery Address</h2>
        <div className={PopUpLocaCss.input_section}>
          <div className={PopUpLocaCss.input_wrapper}>
            <label>Division</label>
            <PopUpOption
              popName={"Divition"}
              myArray={DivitionArray}
              onDataChange={onDataChange}
              myValue={data?.Divition}
            />
          </div>

          <div className={PopUpLocaCss.input_wrapper}>
            <label>District</label>
            <PopUpOption
              popName={"District"}
              name="Dist_Data"
              myArray={districtData}
              onDataChange={onDisChange}
              myValue={data?.District}
            />
          </div>
          <div className={PopUpLocaCss.input_wrapper}>
            <label>Area</label>
            <PopUpOption
              popName={"Area"}
              name="Area_Data"
              myArray={areaData}
              onDataChange={OnAreaChange}
              myValue={data?.Area}
            />
          </div>
          <div className={PopUpLocaCss.input_wrapper}>
            <MyInput
              onDataChange={HandelChange}
              labelText="Address"
              type="text"
              inputName="Address"
              placeholderText="address"
              myValue={data?.Address}
            />
          </div>
          <div className={PopUpLocaCss.input_wrapper}>
            <MyInput
              onDataChange={HandelChange}
              labelText="Phone"
              type="text"
              inputName="Phone"
              placeholderText="+880"
              myValue={data?.Phone}
            />
          </div>
        </div>

        <div className={PopUpLocaCss.button_wrapper}>
          <button className={PopUpLocaCss.close} onClick={onCLoseClick}>
            Close
          </button>
          <button onClick={onUpdateClick}>Update</button>
        </div>
      </div>
    </div>
  );
};

export default PopUpLocation;

const PopUpOption = ({ myArray, onDataChange, popName, myValue }) => {
  let OptionData = (
    <>
      {myArray
        ? myArray.map((item, index) => {
            return (
              <option
                key={index}
                onChange={onDataChange}
                value={item}
                selected={myValue === item ? true : false}
              >
                {item}
              </option>
            );
          })
        : ""}
    </>
  );

  return (
    <>
      <select
        className={PopUpLocaCss.popOption}
        name={popName}
        onChange={onDataChange}
      >
        <option value={""}>--Select Item--</option>
        {OptionData}
      </select>
    </>
  );
};

const MyInput = ({
  labelText,
  type,
  inputName,
  placeholderText,
  myValue,
  onDataChange,
}) => {
  return (
    <>
      <label>{labelText}</label>
      <input
        onChange={onDataChange}
        type={type}
        name={inputName}
        placeholder={placeholderText}
        value={myValue ? myValue : ""}
      />
    </>
  );
};
