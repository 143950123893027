
import React, { useEffect, useState } from 'react'
import {  useSearchParams } from 'react-router-dom'
import {  LocalTimeStampToDate } from '../../MyFunctions/MyFunctions'
import { HeaderFooterWrapper } from '../HeadetFooter'
import TrailBalancePDFCss from './css/TrailBalancePDF.module.css'

const TrialBalancePDF = ({ heading, date }) => {
    const [bankList, setBankList] = useState()
    let [searchParams, setSearchParams] = useSearchParams();
    const [dateRange, setDateRange] = useState()
    const [arrangeData, setArrangeData] = useState()
    async function getData() {
        let myBankList = [] // await GetDatafromDatabase('BankList', 'mbankID')
        setBankList(myBankList)
        let mySalesData = [] //await GetDatafromDatabase('sales', 'salesID')
        let myPurchaseData = [] // await GetDatafromDatabase('Purchase', 'purchaseID')
        let myCashData = [] //await GetDatafromDatabase('cashinhand', 'cashID')
        let myExpenseData  =[] // await GetDatafromDatabase('Expense', 'expenseID')
     //  console.log(myExpenseData)
        let myTransictionData = [] // await GetDatafromDatabase('Bank_Transaction', 'trID')
        let SSLData = [] //await GetDatafromDatabase('SSLData', 'sslID')

        let trData = []
        myTransictionData.forEach(element => {
            if (element.head === "Withdraw" || element.head === "Deposite") {
                trData.push(element)
            }
        });
        let salesItemArr = []
        let myPromises = mySalesData.map(async function(item)  {
            let nestedDoc = [] // await GetNestedAllData('sales', item.salesID, 'orders', 'salseNID' )
            salesItemArr.push(...nestedDoc)
        })
        let expenseItem = []
        let myExpensePromises = myExpenseData.map(async function(item)  {
            let nestedDoc =  [] //await GetNestedAllData('Expense', item.expenseID, 'ExpenseDetails', 'exItemID' )
            console.log(item)
            // console.log(item)
          //  console.log(item.expenseID, nestedDoc)
        //   nestedDoc.forEach(element => {
        //     expenseItem.push({...element,paymentAmmount:item.paymentAmmount })
        //   });
        expenseItem.push(...nestedDoc)
                   
        })
        let finalPurchaseData = []
        let purchasePromises = myPurchaseData.map(async function(item)  {
            let nestedDoc = [] //await GetNestedAllData('Purchase', item.purchaseID, 'purchaseItem', 'pItemID' )
            // console.log(item)
          //  console.log(item.expenseID, nestedDoc)
          finalPurchaseData.push(...nestedDoc)
        })


        Promise.all([...myPromises, ...myExpensePromises,...purchasePromises]).then(()=>{
            setArrangeData(organizeCashData(myBankList, myTransictionData, myCashData, SSLData, finalPurchaseData, salesItemArr, mySalesData, organizeData(expenseItem), myPurchaseData))
            //organizeData(expenseItem)
          // console.log(organizeData(finalPurchaseData))  
          
        })
     

    }
    function todayData(itemsArr) {
        let mendDate = searchParams.get('end_date')
        let todayTime = ''
        if (mendDate) {
            todayTime = new Date(mendDate).toLocaleDateString()
        } else {
            todayTime = new Date().toLocaleDateString()
        }
        
        let toadayArr = []
        itemsArr?.forEach(element => {
            let myNewData = new Date(element.createdDate.seconds*1000).toLocaleDateString()
            if (myNewData===todayTime) {
                toadayArr.push(element)
            }
          });
        return toadayArr
    }
    function preData(itemsArr) {
        let mendDate = searchParams.get('end_date')
        let todayTime = ''
        if (mendDate) {
            todayTime = new Date(mendDate).toLocaleDateString()
        } else {
            todayTime = new Date().toLocaleDateString()
        }

        let previousArr = []
        itemsArr?.forEach(element => {
            let myNewData = new Date(element.createdDate.seconds*1000).toLocaleDateString()
            if (myNewData!==todayTime) {
                previousArr.push(element)
            }
        });
        return previousArr
        
    }
    function organizeData(myData) {
        // console.log(myData)
        let finalArr = []
        let uniqueElements = myData.slice()
        for (let index = 0; index < myData.length; index++) {
            const element = myData[index];
            let tempArr = []
            for (let mindex = 0; mindex < uniqueElements.length; mindex++) {
                const melement = uniqueElements[mindex];
                if (melement.expenseHeadID === element.expenseHeadID) {
                    tempArr.push(melement)
                }
            }

            if (tempArr.length > 0) {
                tempArr.forEach(element => {
                    uniqueElements.forEach((melement, mindex) => {
                        if (melement.expenseHeadID === element.expenseHeadID) {
                            uniqueElements.splice(mindex, 1)
                        }
                    });
                });

                finalArr.push(tempArr)
            }
        }
        return finalArr
    }
    function organizeCashData(myBankList, trData, myCashData, mySSlData, myPurchaseData, mySalesData, salesMain, expenseData, purchaseMainData) {
        let productList = JSON.parse(window.localStorage.getItem('allProduct'))
        let okArr = []
     okArr.push({particular:'Cash in Hand (Opening)', camount:0})
        myBankList.forEach(melement => {
            let rtotal = 0
            let bankName =melement.bankName
            trData?.forEach(element => {
                if (element.bankID === melement.mbankID) {
                  
                   if (element.particulars==='Deposite') {
                      // console.log(element.receiveAmmount)
                       rtotal += element.receiveAmmount
                       bankName = melement.bankName+` (${melement.acName})`
                   }
                 }
            });
            okArr.push({particular: bankName+' '+'(Opening)', camount:melement.openingBanlance})
        })

        let sslTotal = 0
        mySSlData.forEach(element => {
            sslTotal +=element.receiveAmmount 
        });
        okArr.push({particular:'Cash in SSL (Opening)', camount:sslTotal})

        console.log(expenseData) 

        expenseData?.forEach(element => {
            let total = 0
            let particular = ''
            element.forEach(melement => {
               
                total +=melement.paymentAmmount ? melement.paymentAmmount:0
                particular =melement.headName 
            });
            let dataOK = {
                particular:particular,
                amount:total
            }
            okArr.push(dataOK)
        });

        let purchaseTotal = 0
        let returnArr = []
        let PaymentArr = []
        let paymentTotal = 0
        let discountPurchase = 0
        myPurchaseData.forEach(element => {
            purchaseTotal += parseInt(element.Selling_price)*element.quantity
        });

        purchaseMainData.forEach(element => {
//            console.log(element)
           let myPercent = element.percent ? parseInt(element.percent):0
            paymentTotal += element.payAmmount ? parseInt(element.payAmmount):0
            discountPurchase += element.total*myPercent/100
            let returnData = element.returnItems ? element.returnItems:[]
            let paymentData = element.Payment ? element.Payment:[]
            returnArr.push(...returnData)
            PaymentArr.push(...paymentData)
        });
//        console.log((purchasePayment(PaymentArr)+paymentTotal))
        let duePurchase = purchaseTotal - (purchasePayment(PaymentArr)+paymentTotal)
        //  okArr.push({particular:'Purchase (Stock)', amount:purchaseTotal})
        //  okArr.push({particular:'Purchase Return (A)', camount:returnItemsAmount(returnArr), })
        okArr.push({particular:'Oversease Purchase', amount:purchasePayment(PaymentArr), })

        let salesTotal = 0
        let salesreturnArr = []
       // console.log(salesMain)
        mySalesData.forEach(element => {
            for (let index = 0; index < productList.length; index++) {
                const pelement = productList[index];
                if (element.id===pelement.id) {
                    salesTotal += element.mQuantity * parseInt(pelement.lastPrice)
                    break
                }
            } 
        });
        let salesTotalMRP = 0
        let receiveTotalMRP = 0
        let deliveryTotal = 0
        let receiveArr = []
        let discountAmount = 0
        salesMain.forEach(element => {
            let returnData = element.returnItems ? element.returnItems:[]
            //let okData = []
            let myPercent = element.percent ? element.percent:0
            returnData?.forEach(relement => {
                relement = {...relement, percent:myPercent}
                salesreturnArr.push(relement)
            });
            salesTotalMRP += element.grandTotal
            receiveTotalMRP += element.collectedAmmount
            deliveryTotal = element.collectedDeliveryCharge
            discountAmount  = (element.grandTotal*myPercent/100)
            let mReceiveArr = element.salesReceive ? element.salesReceive:[]
            receiveArr.push(...mReceiveArr)

        });
        okArr.push({particular:'Sales (MRP)', camount:salesTotalMRP+deliveryTotal})
        okArr.push({particular:'Delivery Charge', amount:deliveryTotal})
        okArr.push({particular:'Sales Commision', amount:discountAmount})
        okArr.push({particular:'Sales Return (TP)', amount:salesReturnItemsAmount(salesreturnArr, productList)})
        okArr.push({particular:'Sales Return Commision', amount:salesReturnPercent(salesreturnArr)})

        let dueAmount = salesTotalMRP-(salesReceiveAmount(receiveArr)+receiveTotalMRP-deliveryTotal)
       // console.log(dueAmount)
        okArr.push({particular:'Acount Receiveable', amount:dueAmount})
        // okArr.push({particular:'Acount Payable', camount:duePurchase})
        okArr.push({particular:'Purchase Discount', camount:discountPurchase})

        // clossing starts from here

        let cashArr = []
        let myTotal = 0
        let pTotal = 0
        myCashData.forEach(element => {
            //element.head !== "Withdraw" && 
            if (element.head !== "Deposite") {
               // console.log(element)
                myTotal += element.receiveAmmount
                pTotal += element.paymentAmmount
                cashArr.push(element)
            } else {
                pTotal += element.paymentAmmount
              //console.log(element)
            }
        });
         okArr.push({particular:'Cash in Hand (Closing)', amount:myTotal-(pTotal+deliveryTotal)})

         myBankList.forEach(melement => {
            let rtotal = 0
            let paymentTotal = 0
            let bankName =melement.bankName
            trData?.forEach(element => {
//                console.log(trData)
                if (element.bankID === melement.mbankID) {
                    paymentTotal += element.paymentAmmount
                    rtotal += element.receiveAmmount
                    bankName = melement.bankName+` (${melement.acName})`
                 }
            });
           // console.log(paymentTotal)
            okArr.push({particular: bankName+' '+'(Closing)', amount:melement.openingBanlance+rtotal -paymentTotal})
        })
        let CloingsslTotal = 0
        mySSlData.forEach(element => {
            CloingsslTotal +=element.receiveAmmount 
        });
        okArr.push({particular:'Cash in SSL (Closing)', amount:CloingsslTotal})
       // console.log(salesreturnArr)
       return okArr
    }
   
    function salesReceiveAmount(receiveArr) {
      //  console.log(salesReturnItems)
       let total = 0
       receiveArr?.map((item)=>{
            total += item.receiveAmmount
        })
       //returnItems
       return total
    }
    function salesReturnPercent(salesReturnItems) {
    //   console.log(salesReturnItems)
       let total = 0
       salesReturnItems?.map((item)=>{
           total = item.returnTotal*item.percent/100
        })
       //returnItems
       return total
    }
    function salesReturnItemsAmount(salesReturnItems, productList) {
    //   console.log(salesReturnItems)
       let total = 0
       salesReturnItems?.map((item)=>{
           item.returnItem.map((mitem)=>{
            for (let index = 0; index < productList.length; index++) {
                const pelement = productList[index];
                if (mitem.id===pelement.id) {
                    total += mitem.rQuantity * parseInt(pelement.lastPrice)
                    break
                }
            }
           })
        })
       //returnItems
       return total
    }
    function purchasePayment(paymentItems) {
       // console.log(returnItems)
       let total = 0
       paymentItems?.map((item)=>{
            total += item.paymentAmmount
        })
       //returnItems
       return total
    }
    function returnItemsAmount(returnItems) {
        let localProducts = JSON.parse(window.localStorage.getItem('allProduct'))
        //console.log(localProducts)

       let total = 0
        returnItems?.map((item)=>{
            item.returnItem.map((mitem)=>{
                let findIndex = localProducts.findIndex(obj => obj.id == mitem.productID)
                total +=  parseInt(localProducts[findIndex].Selling_price)
               // console.log()
            })
           // total += item.returnTotal
        })
       //returnItems
       return total
    }
   
    useEffect(() => {
        getData()
    }, [])

    function checkClick() {
        for (let index = 1; index <= 10; index++) {
           let number = 5
           let myString = index + ' x '+number +' = '+ (number*index)
           console.log(myString)
            
        }
    }
   function getTotal(keyName){
    let total = 0 
        arrangeData?.map((item)=>{
            total += item?.[keyName] ? item?.[keyName]:0
        })
        return total
   }
    return (
        <>
            <HeaderFooterWrapper
            content={<>
            <h3>Trial Balance</h3>
            <div className={TrailBalancePDFCss.details}>
                <div className={TrailBalancePDFCss.invoice}>
                    <div className={TrailBalancePDFCss.info} onClick={checkClick}> 
                    Date :  {dateRange?.end_date ? LocalTimeStampToDate(dateRange?.end_date) : new Date().toLocaleDateString()}
                    </div>
                </div>
            </div>
            <div className={TrailBalancePDFCss.table}>
                <table>
                    <thead>
                        <tr>
                            <th className={TrailBalancePDFCss.no}>Sl</th>
                            <th className={TrailBalancePDFCss.no}>Account</th>
                            <th className={TrailBalancePDFCss.no}>Debit</th>
                            <th className={TrailBalancePDFCss.no}>Credit</th>
                        </tr>
                    </thead>
                    <tbody>
                    {arrangeData?.map((item, index)=>{
                        return    <tr key={index}>
                        <td>{index+1}</td>
                        <td style={{textAlign:'left'}}>{item.particular}</td>
                        <td>{item.amount}</td>
                        <td>{item.camount}</td>
                        </tr>
                    })
                     
                    }
                        <tr>
                            <td colSpan={2}>Total : </td>
                            <td>{getTotal('amount')}</td>
                            <td>{getTotal('camount')}</td>
                        </tr>
                        <tr>
                            <td colSpan={3}>Difference : </td>
                            <td>{getTotal('amount')-getTotal('camount')}</td>
                        </tr>
                        
                    
                    </tbody>
                </table>
            </div>
            </>}
            />
        </>
    )
}

export default TrialBalancePDF
