import React, { useState } from "react";
import resetPasswordCss from "./css/resetPassword.module.css";
import { toast } from "react-toastify";
import { UpdateUserPassword } from "../../../api/BackendUserApi";
import { useEffect } from "react";

function genRandomNumber(setRandomNumber) {
  const number = Math.random();
  const fourDigits = Math.floor(number * 10000);
  setRandomNumber(fourDigits);
}
export default function ResetPassword({
  resetPasswordPop,
  setResetPasswordPop,
}) {
  const [randomNumber, setRandomNumber] = useState();

  function onClose() {
    setResetPasswordPop(false);
  }

  useEffect(() => {
    genRandomNumber(setRandomNumber);
  }, []);
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const form = e.target;
      const securityCode = form.securityCode.value;
      if (parseInt(securityCode) === parseInt(randomNumber)) {
        console.log({
          oldpassword: "admin2024",
          newpassword: "123456",
          username: resetPasswordPop.userName,
        });

        const result = await UpdateUserPassword({
          oldpassword: "admin2024",
          newpassword: "123456",
          username: resetPasswordPop.userName,
        });
        if (result.error) {
          toast.error(result.message);
        } else {
          toast.success(result.message);
        }
        setResetPasswordPop(false);
      } else {
        toast.error("Security Code not Mass");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className={resetPasswordCss.wrapper_popup}>
      <div className={resetPasswordCss.holder}>
        <h2>Reset Password for</h2>
        <p>
          Before change the Password be sure! After changing the Password user
          may not logged in his/her account! Be Sure that you inform user that
          you resset his/her password.
        </p>
        <h3>
          The New Password is:<span>123456</span>
        </h3>
        <div className={resetPasswordCss.form_wrapper}>
          <form onSubmit={handleSubmit}>
            <div className={resetPasswordCss.input_wrapper}>
              <label>Security Code:</label>
              <input
                type="number"
                placeholder="Enter Security Code"
                name="securityCode"
              />
              <span>{randomNumber}</span>
            </div>
            <div className={resetPasswordCss.button_wrapper}>
              <button>Reset</button>
              <button onClick={onClose}>Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
