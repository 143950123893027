
import { faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { LocalDateToTime, LocalTimeStampToDate } from '../../MyFunctions/MyFunctions';
import CreditVoucnerSupplierCss from './Css/CreditVoucnerSupplier.module.css'
const CreditVoucnerSupplier = () => {
    const myNavigate = useNavigate()
    const [supplierList, setSupplierList] = useState()
    const [singleSupplier, setSingleSupplier] = useState()
    const [SearchData, setSearchData] = useState()
    const [tableData, setTableData] = useState()
    const [myCheck, setMyCheck] = useState(true)
    const [myBankList, setMyBankList] = useState() 
    const [paymentInfo, setPaymentInfo] = useState({
        paymentDate: '',
        paymentMethod: 1,
        paymentAmmount: 0,
        paymentRemark: '',
    })
    const [errMessage, setErrMessage] = useState()
    async function getBankList() {
        let bankData = [] //await GetAllDataExpress('bank_list')
        setMyBankList(bankData)
    }
    useEffect(()=>{
        getBankList()
    },[])

    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        if (name === 'paymentAmmount') {
            value = e.target.value ? parseInt(e.target.value) : ''
            if (value > getPayableDue()) {
                value = getPayableDue()
                setPaymentInfo({ ...paymentInfo, [name]: value })
            } else if (value < 0) {
                setPaymentInfo({ ...paymentInfo, [name]: 0 })
            } else {
                setPaymentInfo({ ...paymentInfo, [name]: value })
            }
        } else {
            setPaymentInfo({ ...paymentInfo, [name]: value })
        }

        if (name ==='paymentMethod') {
            setPaymentInfo({ ...paymentInfo, [name]: parseInt(value) })
        }

    }
function getDateTime() {
        let mydate = new Date();
        let day = mydate.getDate()
        let month = mydate.getMonth() + 1
        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }
        let year = mydate.getFullYear()
        let hour = mydate.getHours()
        if (hour < 10) {
            hour = '0' + hour
        }
        let minute = mydate.getMinutes()
        if (minute < 10) {
            minute = '0' + minute
        }
        // console.log(minute)
        let seconds = mydate.getSeconds()
        if (seconds < 10) {
            seconds = '0' + seconds
        }
        let finalTime = year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + seconds
        return finalTime
    }
  
    function checkClick(params) {
        console.log(LocalDateToTime(paymentInfo.paymentDate))
        
    }

  async  function onSaveClick() {
        let payErr = ValidateFunction('paymentAmmount', 'Payment ')
        let remarkErr = ValidateFunction('paymentRemark', 'Remark  ')
        let dateErr = ValidateFunction('paymentDate', 'Date ')
        let bankIDErr = ''
        let sub_methodErr = ''
        if (paymentInfo.paymentMethod ===2 || paymentInfo.paymentMethod===3) {
            bankIDErr = ValidateFunction('bankID', 'Bank Name ')
            sub_methodErr = ValidateFunction('sub_method', 'Sub Method ')
        }
        setErrMessage({ ...errMessage, payErr, remarkErr, dateErr, bankIDErr, sub_methodErr })
        if (!payErr && !remarkErr && !dateErr && !bankIDErr && !sub_methodErr) {
            let paymentArr = []
            tableData.forEach(element => {
                if (element.nowPay) {
                    paymentArr.push(element)
                }
            });
            let userData = window.localStorage.getItem('userData')
            userData = JSON.parse(userData)
            if (paymentArr.length > 0) {
                let NumberCollection = []// await GetSingleDoc("NumberCollection", "NumberID")

                if (paymentArr.length > 1) {
                    if (paymentInfo.paymentAmmount === getPayableDue()) {
                        let pono = ''
                        paymentArr.forEach(async(item) => {
                            let dataID = item.id
                            //getDueAmmount(total, payment, discount, payAmmounthere)
                            let purchaseUpdate = {
                                paymentAmmount: getDueAmmount(item.total, item.payAmmount, item.percent, item.Payment),
                                paymentDate: LocalDateToTime(paymentInfo.paymentDate),
                                paymentRemark: paymentInfo.paymentRemark,
                                PVNO:NumberCollection.data().paymentVoucher,
                                creditVoucherNo:runningVoucherNo
                            }
                            //console.log(purchaseUpdate)
                            let summeryData = {
                                supplierName: singleSupplier?.companyaName,
                                status: 'PO Ammount Paid',
                                attemeptBy: userData.name,
                            }
                        // await UpdateDataArray('Purchase', dataID, 'Payment', {...purchaseUpdate})
                        //  await UpdateData('Purchase', { fullPaid: true}, dataID)
                        //  await SaveNestedExistingData('Purchase', dataID, 'PoSummery', summeryData)
                        });
                        // loop ends

                        paymentArr.map((item)=>{
                            pono += item.PoNo+', '
                        })

                        let paymentData = {
                            PoNo:pono,
                            paidAmmount:paymentInfo.paymentAmmount,
                            paymentMethod:paymentInfo.paymentMethod,
                            paymentRemark:paymentInfo.paymentRemark,
                            supplierInfo:singleSupplier,
                            paymentVoucher:NumberCollection.data().paymentVoucher,
                            processBy:userData.name,
                            processorID:window.localStorage.getItem('user_id')
                        }
                       // console.log(paymentData)
                        // await AutoIncrementNumberCustom('NumberCollection', 'NumberID', 'paymentVoucher', 1 )
                        // await SaveData('Payment', {...paymentData})
                        
                        alert('data saved')
                      //  console.log(paymentData)
                      
                    } else {
                        console.log('you must pay full ammount')
                    }
                } else {
                    let fullPaid = true
                    let status = ''
                    let dataID = paymentArr[0].id
                    if (paymentInfo.paymentAmmount < getDueAmmount(paymentArr[0].total, paymentArr[0].payAmmount, paymentArr[0].percent)) {
                        fullPaid =false
                        status = 'PO Ammount Partial Paid'
                    }else{
                        fullPaid =true
                        status = 'PO Ammount Paid'
                    }

                    let purchaseUpdate = {
                        paymentAmmount: paymentInfo.paymentAmmount,
                        paymentDate: LocalDateToTime(paymentInfo.paymentDate),
                        paymentRemark: paymentInfo.paymentRemark,
                        PVNO:NumberCollection.data().paymentVoucher,
                        creditVoucherNo:runningVoucherNo,
                        paymentMethod:methodReturn(paymentInfo.paymentMethod)
                    }
                    console.log(purchaseUpdate)
                    let summeryData = {
                        supplierName: singleSupplier?.companyaName,
                        status: status,
                        attemeptBy: userData.name,
                    }
                //     await UpdateDataArray('Purchase', dataID, 'Payment', {...purchaseUpdate})
                //    await UpdateData('Purchase', {fullPaid: fullPaid}, dataID)
                //    await SaveNestedExistingData('Purchase', dataID, 'PoSummery', summeryData)

                    let paymentData = {
                        PoNo:paymentArr[0].PoNo,
                        paidAmmount:paymentInfo.paymentAmmount,
                        paymentMethod:paymentInfo.paymentMethod,
                        paymentRemark:paymentInfo.paymentRemark,
                        supplierInfo:singleSupplier,
                        processBy:userData.name,
                        processorID:window.localStorage.getItem('user_id'),
                        paymentVoucher:NumberCollection.data().paymentVoucher,
                    }
                //    await SaveData('Payment', {...paymentData})
                //    await AutoIncrementNumberCustom('NumberCollection', 'NumberID', 'paymentVoucher', 1 )
                 //  alert('data saved')
                }
            } else {
                console.log('no invoice found')
            }
            
        }
        // console.log(paymentInfo)
    }
    function methodReturn(data) {
        let method = ''
        if (data===1) {
            method = 'Cash'
        } else if (data===2) {
            method = 'Online Deposite'
        }else if (data===3) {
            method = 'Transfered'
        }else{
            method ='Cheque'
        }
        return method
    }

    function ValidateFunction(field, message) {
        let text = ""
        if (!paymentInfo[field]) {
            text = message + "is Required"
        }
        return text;
    }
    const [runningVoucherNo, setRunningVoucherNo] = useState()
    async function getSearchData() {
        setSingleSupplier()
        setPaymentInfo({
            paymentDate: '',
            paymentMethod: 1,
            paymentAmmount: 0,
            paymentRemark: '',
        })
        if (SearchData) {
            supplierList.forEach(element => {
                if (element.supplierID === SearchData) {
                    setSingleSupplier(element)
                }
            });
            // let mtableData = await GetDataByFieldPath('Purchase', 'supplierInfo', 'supplierID', SearchData)
            // let numberData = await GetSingleDoc('NumberCollection', 'NumberID')
            // console.log(numberData.data().creditVoucherNo)
            // setRunningVoucherNo(numberData.data().creditVoucherNo+1)
            let newArr = []
            //console.log(mtableData)
            // mtableData.forEach(element => {
            //     console.log(mtableData)
            //     if (element.receivedPO) {
            //         if (!element.fullPaid) {
            //             element = { ...element, nowPay: true, nowTotal:element.total- getRetunrAmmount(element.returnItems), totalReturnAmmount:getRetunrAmmount(element.returnItems)}
            //             newArr.push(element)
            //         }
            //     }
            // });
            // let sortArr = newArr.sort((a, b) => (a.createdDate?.seconds > b.createdDate?.seconds) ? 1 : -1)
            // setTableData(sortArr)
        }else{
            alert('No Supplier Selected')
        }
        
    }
    function getRetunrAmmount(returnArr) {
        let total = 0
        returnArr?.map((ritem)=>{
            total += ritem.returnTotal
        })
        return total
    }
 
    function CheckAll() {
        let newArr = []
        tableData.forEach(element => {
            element = {
                ...element,
                nowPay: true
            }
            newArr.push(element)
        });
        setTableData(newArr)
    }
    function unCheckAll() {
        let newArr = []
        tableData.forEach(element => {
            element = {
                ...element,
                nowPay: false
            }
            newArr.push(element)
        });
        setTableData(newArr)
    }
    function CheckData(e, id) {
        let newArr = []
        tableData.forEach(element => {
            if (element.id === id) {
                element = {
                    ...element,
                    nowPay: true
                }
                newArr.push(element)
            } else {
                newArr.push(element)
            }
        });
        setTableData(newArr)
    }
    function unCheckData(e, id) {
        let newArr = []
        tableData.forEach(element => {
            if (element.id === id) {
                element = {
                    ...element,
                    nowPay: false
                }
                newArr.push(element)
            } else {
                newArr.push(element)
            }
        });
        setTableData(newArr)

    }
    function getPayableDue() {
        // {item.percent ? Math.round(item.total * parseInt(item.percent) / 100
        let dueTotal = 0
        let discount = 0
        tableData?.map((item) => {
            // console.log(item.total-item.payAmmount)
            if (item.nowPay) {
                if (item.percent) {
                    discount = Math.round(item.nowTotal * parseInt(item.percent) / 100)
                } else {
                    discount = 0
                }
                if (item.payAmmount) {
                    dueTotal += item.nowTotal - item.payAmmount - discount
                } else {
                    dueTotal += item.nowTotal - discount
                }

                if (item.Payment) {
                    let nowTotal = 0
                    item.Payment?.map((pitem)=>{
                        nowTotal +=pitem.paymentAmmount
                    })
                    dueTotal -= nowTotal
                }else{
                    dueTotal = dueTotal 
                }
            }
        })
        return dueTotal
    }
    function checkAllData(params) {
        for (let index = 0; index < tableData?.length; index++) {
            const element = tableData[index];
            if (!element.nowPay) {
                setMyCheck(false)
                break;
            } else {
                setMyCheck(true)
            }
        }
    }
    useEffect(() => {
        checkAllData()
        getPayableDue()
        setPaymentInfo({ ...paymentInfo, paymentAmmount: 0, paymentDate:getDateTime()})
    }, [tableData])

    function getLeadgerBalance() {
        // {item.percent ? Math.round(item.total * parseInt(item.percent) / 100
        let dueTotal = 0
        let discount = 0
        tableData?.map((item) => {
            // console.log(item.total-item.payAmmount)
            if (item.percent) {
                discount = Math.round(item.nowTotal * parseInt(item.percent) / 100)
            } else {
                discount = 0
            }
            if (item.payAmmount) {
                dueTotal += item.nowTotal - item.payAmmount - discount
            } else {
                dueTotal += item.nowTotal - discount
            }
        })
        return dueTotal
    }


    async function getSupplier() {
        let supplierData = [] //await GetAllDataExpress('supplier')
        // console.log(supplierData)
        setSupplierList(supplierData)
    }
    useEffect(() => {
        getSupplier()
    }, [])
    function getPaymentTotal(payArr){
        let total = 0
        payArr?.map((pitem)=>{
            total += pitem.paymentAmmount
        })

        return total
    }
    function getDueAmmount(total, payment, discount, payAmmounthere) {
        let payTotal = 0
        payAmmounthere?.map((item)=>{
           payTotal += item.paymentAmmount
        })
        let dueTotal = 0
        if (payment && discount && payAmmounthere) {
            dueTotal = total - (total * Math.round(parseInt(discount)) / 100) - payment-payTotal
        }
         else if (payment && discount) {
            dueTotal = total - (total * Math.round(parseInt(discount)) / 100) - payment
        } else if (payment) {
            dueTotal = total - payment
        } else if (discount) {
            dueTotal = total - Math.round(parseInt(discount))
        } else {
            dueTotal = total
        }
      //  console.log(Math.round(dueTotal))
        return Math.round(dueTotal)
    }
    const errStyle = {
        display: 'block',
        textAlign: 'right',
        color: 'red',
    }
 
    return (
        <>
            <section className={CreditVoucnerSupplierCss.heading}>
                <div className={CreditVoucnerSupplierCss.inner_headign}>
                    <div className={CreditVoucnerSupplierCss.input_section}>
                        <div className={CreditVoucnerSupplierCss.common}>
                            <select onChange={(e) => { setSearchData(e.target.value) }}>
                                <option value={''}>Select Supplier</option>
                                {supplierList?.map((item, index) => {
                                    return <option key={index} value={item.supplierID}>{item.companyaName}</option>
                                })}
                            </select>
                        </div>
                        <div className={CreditVoucnerSupplierCss.common}>
                            <button onClick={getSearchData}>Search</button>
                        </div>
                        <div className={CreditVoucnerSupplierCss.common}>
                            <button onClick={checkClick}>Credit Voucher Payment</button> 
                        </div>
                    </div>
                    {singleSupplier ? <div className={CreditVoucnerSupplierCss.supplier_info}>
                        <p>{singleSupplier?.companyaName}</p>
                        <p>{singleSupplier?.ownerName}</p>
                        <p>{singleSupplier?.phone}</p>
                        <p>{singleSupplier?.address + ', ' + singleSupplier?.Area + ', ' + singleSupplier?.District + ', ' + singleSupplier?.Divition}</p>
                    </div> : ''}
                    {singleSupplier ? <div className={CreditVoucnerSupplierCss.tableSection}>
                        <h3>Voucher No : {runningVoucherNo ? runningVoucherNo:0}</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Check
                                        {myCheck ? <FontAwesomeIcon style={{ color: 'white', margin: '0 0 0 8px', padding: '0', fontSize: "18px", cursor: 'pointer' }} icon={faSquareCheck} onClick={(e) => { unCheckAll(e) }} /> : <FontAwesomeIcon style={{ color: 'white', margin: '0 0 0 8px', padding: '0', fontSize: "18px", cursor: 'pointer' }} icon={faSquare} onClick={(e) => { CheckAll(e) }} />}
                                    </th>
                                    <th>Date</th>
                                    <th>Po No.</th>
                                    <th>Total Ammount</th>
                                    <th>Paid Ammount</th>
                                    <th>Discount</th>
                                    <th>Due Ammount</th>
                                </tr>

                                {tableData?.map((item, index) => {
                                    return <tr key={index}>
                                        <td>{item.nowPay ? <FontAwesomeIcon style={{ color: 'green', cursor: 'pointer', margin: '0 0 0 8px', padding: '0', }} icon={faSquareCheck} onClick={(e) => { unCheckData(e, item.id) }} /> : <FontAwesomeIcon style={{ color: '#b5b5b5', cursor: 'pointer', margin: '0 0 0 8px', padding: '0', }} icon={faSquare} onClick={(e) => { CheckData(e, item.id) }} />} </td>
                                        <td>{LocalTimeStampToDate(item.createdDate?.seconds)}</td>
                                        <td>{item.PoNo}</td>
                                        <td>{item.nowTotal}</td>
                                        <td>{
                                            (item.payAmmount ? parseInt(item.payAmmount)  : 0) + getPaymentTotal(item.Payment)
                                        }</td>
                                        <td>{
                                            (item.percent ? Math.round(item.nowTotal * parseInt(item.percent) / 100) : item.discountAmmount)   
                                        }</td>

                                        <td>{getDueAmmount(item.nowTotal, item.payAmmount, item.percent, item.Payment)}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                        <div className={CreditVoucnerSupplierCss.other_information}>
                            <div className={CreditVoucnerSupplierCss.text_section}>
                                <p className={CreditVoucnerSupplierCss.ledger_ballnce}>Supplier Ledger Ballance : {getLeadgerBalance()} Tk</p>
                                <div className={`${CreditVoucnerSupplierCss.payment_info} ${CreditVoucnerSupplierCss.limit_due}`}>
                                    <p>Payment Limit : 0</p>
                                    <p>Payable Due : {getPayableDue()} Tk</p>
                                </div>
                            </div>
                            <div className={CreditVoucnerSupplierCss.payment_info}>
                                <div className={CreditVoucnerSupplierCss.option_class}>
                                    <label>Payment Method</label>
                                    <select name='paymentMethod' onChange={onDataChange}>
                                        <option value={1}>Cash</option>
                                        <option value={2}>Online Deposite</option>
                                        <option value={3}>Transferred</option>
                                        <option value= {4} >Cheque</option>
                                    </select>
                                </div>
                                {paymentInfo?.paymentMethod === 2 || paymentInfo?.paymentMethod === 3 ? <>
                                    <div className={CreditVoucnerSupplierCss.option_class} >
                                        <label>Receipt Sub Method : </label>
                                        <select name='sub_method' onChange={onDataChange}>
                                            <option>Select Type</option>
                                            <option value='BEFTN'>BEFTN</option>
                                            <option value='NPSB'>NPSB</option>
                                            <option value='RTGS'>RTGS</option>
                                        </select>
                                    </div>
                                    {errMessage ? <p className={CreditVoucnerSupplierCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.sub_methodErr}</p> : ''}

                                    <div className={CreditVoucnerSupplierCss.option_class}>
                                        <label>Select Bank Account : </label>
                                        <select name='bankID' onChange={onDataChange}>
                                            <option value={''}>Select Bank</option>
                                            {myBankList?.map((item, index) => {
                                                return <option key={index} value={item.myBankID}>{item.bankName} - ({item.acNumber})</option>
                                            })}
                                        </select>
                                    </div>
                                    {errMessage ? <p className={CreditVoucnerSupplierCss.WarningText} style={{ textAlign: 'right' }}> {errMessage?.bankIDErr}</p> : ''}
                                </> : ''}
                                <div className={CreditVoucnerSupplierCss.input_section}>
                                    <label>Paid Ammount</label>
                                    <input onChange={onDataChange} name='paymentAmmount' type='number' placeholder='payment ammount' value={paymentInfo?.paymentAmmount} />
                                </div>
                                <p style={errStyle}>{errMessage?.payErr}</p>
                                <div className={CreditVoucnerSupplierCss.input_section}>
                                    <label>Payments Remarks</label>
                                    <input onChange={onDataChange} name='paymentRemark' type='text' placeholder='payment remark here' value={paymentInfo.remark} />
                                </div>
                                <p style={errStyle}>{errMessage?.remarkErr}</p>
                                <div className={CreditVoucnerSupplierCss.input_section}>
                                    <label>Date</label>
                                    <input onChange={onDataChange} name='paymentDate' type='datetime-local' value={paymentInfo?.paymentDate} />
                                </div>
                                <p style={errStyle}>{errMessage?.dateErr}</p>
                                <div className={CreditVoucnerSupplierCss.input_section}>
                                    <label>Remaining </label>
                                    <input type='text' disabled value={paymentInfo?.paymentAmmount ? getPayableDue() - paymentInfo?.paymentAmmount : getPayableDue()} />
                                </div>
                                <div className={CreditVoucnerSupplierCss.button_section}>
                                    <button onClick={onSaveClick}>Save</button>
                                </div>
                            </div>
                        </div>

                    </div> : ''}
                </div>
            </section>
        </>
    )
}

export default CreditVoucnerSupplier


 /* async function saveDatatoDatabase(method) {
    let userData = JSON.parse(window.localStorage.getItem('userData'))
    let userID = window.localStorage.getItem('user_id')
    let numberData = await GetSingleDoc('NumberCollection', 'NumberID')
   // console.log(numberData.data())
    if (method===1) {
        let salesReceive = {
            receiveTime: new Date().getTime(),
            receiveAmmount:parseFloat(receiveData.receivedAmmount),
            receiveType:'cash',
            particulars:'Credit Voucher',
            remark:receiveData.remark,
            receivedBy:userData.name,
            receivedByID:userID,
            voucherNo:numberData.data()?.creditVoucherNo+1
        }
    let cashData = {
        identity: dataToshow.invoiceno,
        voucherNo: numberData.data()?.creditVoucherNo+1,
        receiveAmmount:parseFloat(receiveData.receivedAmmount),
        particulars:'Sales',
        receiveType:'Credit Voucher',
        receivedBy:userData.name,
        receivedByID:userID,
        paymentAmmount:0,
        remark:receiveData.remark,
        customerName:dataToshow.name,
        transactionType:'cash',
        voucherType:1
    }
    //dataToshow.id
   await UpdateDataArray('sales', dataToshow.id, 'creditReceive', salesReceive)
   await SaveData('cashinhand', cashData)
   await SaveData('Vouchers', cashData)
   await AutoIncrementNumber('creditVoucherNo',1)
   setReceiveData({
    method: 1,
    receivedAmmount: '',
    remark: "",
    receiveDate: "",
    sub_method: "",
    bankID: "",
   })
   setDatatoShow()
} else if(method===2){
    let salesReceive = {
        receiveTime: new Date().getTime(),
        receiveAmmount:parseFloat(receiveData.receivedAmmount),
        receiveType:'Online Deposite',
        particulars:'Credit Voucher',
        remark:receiveData.remark,
        receivedBy:userData.name,
        receivedByID:userID,
        voucherNo:numberData.data()?.creditVoucherNo+1
    }
    let bankData = {
        identity: dataToshow.invoiceno,
        voucherNo: numberData.data()?.creditVoucherNo+1,
        receiveAmmount:parseFloat(receiveData.receivedAmmount),
        particulars:'Sales',
        transactionType:'Online Deposite',
        sub_method:receiveData.sub_method,
        bankID:receiveData.bankID,
        receiveType:'Credit Voucher',
        receivedBy:userData.name,
        receivedByID:userID,
        paymentAmmount:0,
        remark:receiveData.remark,
        customerName:dataToshow.name,
        voucherType:1
    }
    await UpdateDataArray('sales', dataToshow.id, 'creditReceive', salesReceive)
    await SaveData('Bank_Transaction', bankData)
    await SaveData('Vouchers', bankData)
    await AutoIncrementNumber('creditVoucherNo',1)
    await AutoIncrementNumberCustom('BankList', receiveData.bankID, 'ammount', parseFloat(receiveData.receivedAmmount))
    setDatatoShow()
}
    
}
*/