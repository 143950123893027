import React, { useRef } from "react";
import AddNewMenuEditeCss from "./css/AddNewMenuEdite.module.css";

const AddNewMenuEdit = ({ mstate, stateValue, item }) => {
  const tabItem = useRef();
  function changeUi(tabnumber) {
    mstate(tabnumber);
    // CheckPreviousData(data)
  }
  return (
    <div ref={tabItem} className={AddNewMenuEditeCss.addnewtab}>
      <h4
        onClick={() => {
          changeUi(1);
        }}
        className={stateValue === 1 ? AddNewMenuEditeCss.tabActive : ""}
      >
        Item Information
      </h4>
      <h4
        onClick={() => {
          changeUi(2);
        }}
        className={stateValue === 2 ? AddNewMenuEditeCss.tabActive : ""}
      >
        Item Price
      </h4>
      <h4
        onClick={() => {
          changeUi(3);
        }}
        className={stateValue === 3 ? AddNewMenuEditeCss.tabActive : ""}
      >
        Product Attributes
      </h4>
      <h4
        onClick={() => {
          changeUi(4);
        }}
        className={stateValue === 4 ? AddNewMenuEditeCss.tabActive : ""}
      >
        Image Upload
      </h4>
    </div>
  );
};

export default AddNewMenuEdit;
