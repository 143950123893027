import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GetAllDataExpress, GetDataPassQuery } from '../../../api/ExpressFunctions';
import { GetEndDate, GetStartDate, LocalTimeStampToDate } from '../../MyFunctions/MyFunctions';
import { HeaderFooterWrapper } from '../HeadetFooter';
import CashinHandReceiveViewPDFCss from './css/CashinHandReceiveViewPDF.module.css'

const CashinHandReceiveViewPDF = ({ heading }) => {
    const [invoiceState, setInvoiceState] = useState()
    const [backendUser, setBackendUser] = useState()
    let [searchParams, setSearchParams] = useSearchParams();

    async function getDataformDatabase(params) {
       // console.log(1)
        
        let cashData = []
        let startDate = searchParams.get('start_date')
        let endDate = searchParams.get('end_date')
        let myStartDate = GetStartDate(startDate)
        let myEndDate = GetEndDate(endDate)
        let allUserBack = await GetAllDataExpress('backend_user')
        setBackendUser(allUserBack)
        if (startDate) {
            console.log(1)
            let mQuery = `SELECT * FROM credit_voucher WHERE transactionType='cash' AND created_date > ${myStartDate} AND created_date < ${myEndDate}`
            cashData =  await GetDataPassQuery(mQuery)
            

        }else{
            let mQuery = `SELECT * FROM credit_voucher WHERE transactionType='cash'`
            cashData =  await GetDataPassQuery(mQuery)
        }
        let finalArr = []
        cashData.forEach(element => {
            let finalData = {
                date:element.created_date, 
                particulars:element.particulars,
                identity:element.identity,
                receivePerson:element.receivedByID,
                cash:element.receiveAmmount,
                balance:element.receiveAmmount
            }
            finalArr.push(finalData)
        });
        let sortArr = finalArr.sort((a, b) => (a.date > b.date) ? -1 : 1)
       // setInvoiceState(sortArr)
        getBalanceAmmount(sortArr)
    }
   
    function getBalanceAmmount(balanceArr) {
        let finalArr = []
        let myTempBalance = 0
        for (let index = 0; index < balanceArr.length; index++) {
            let element = balanceArr[index];
                if (index===0) {
                    element = {...element, mbalance:element.balance}
                    finalArr.push(element)
                    myTempBalance = element.balance
                }else{
                    myTempBalance += element.balance
                    element = {...element, mbalance:myTempBalance}
                    finalArr.push(element)
                }
           }
           setInvoiceState(finalArr)
    }

    function getAllTotal(arrayPass, identifire) {
        let total = 0
        arrayPass?.forEach(element => {
            total += element[identifire]
        });
        return total;
    }

    useEffect(() => {
        getDataformDatabase()
    }, [])
    function getColor() {
        let leftPart = document.querySelector('.sidepart')
        leftPart?.classList.add('display-none')
       // console.log(leftPart)
    }

    useEffect(() => {
        getColor()
    
    }, [])

    return (
        <>

           <HeaderFooterWrapper
           
           content={<>
            <h3>CASH IN HAND RECEIVE STATEMENT</h3>
            <div className={CashinHandReceiveViewPDFCss.details}>
                <div className={CashinHandReceiveViewPDFCss.client}>
                    <div className={CashinHandReceiveViewPDFCss.info}>
                        {searchParams.get('start_date') ? <span className={CashinHandReceiveViewPDFCss.moreinfo}>Date : {searchParams.get('start_date')}  To {searchParams.get('end_date')}</span> : <span className={CashinHandReceiveViewPDFCss.moreinfo}> Date : All Time</span>}
                    </div>
                </div>
                <div className={CashinHandReceiveViewPDFCss.invoice}>

                </div>
            </div>
            <div className={`${CashinHandReceiveViewPDFCss.table} ${CashinHandReceiveViewPDFCss.sales_table}`}>
                <table>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}>Sl</th>
                            <th>Date</th>
                            <th>Particulars</th>
                            <th >Received Person</th>
                            <th >Cash</th>
                            <th >Cash Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoiceState?.map((item, index) => {
                            return <tr key={index}>
                                <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                <td style={{ textAlign: 'center' }}>{LocalTimeStampToDate(item.date)}</td>
                                <td style={{ textAlign: 'left' }}>{item.particulars} (#{item.identity})</td>
                                <td style={{ textAlign: 'left' }}>
                                    {backendUser?.filter((fuser)=>{
                                        return item.receivePerson === fuser.id ? fuser:''
                                    })
                                    .map((fitem)=>{
                                        return fitem.userName
                                    })}
                                
                                </td>
                                <td style={{ textAlign: 'right' }}>{item.cash}</td>
                                <td>{item.mbalance}</td>
                            </tr>
                        })}
                        <tr style={{ fontWeight:'bold' }}>
                            <td colSpan={4}>Total : </td>
                            <td>{getAllTotal(invoiceState, 'cash')}</td>
                            <td>{getAllTotal(invoiceState, 'balance')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
           </>}
           
           />

        </>
    )
}

export default CashinHandReceiveViewPDF