import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ServerTimeToFormateDateOnly } from "../../../api/GlobalFunctions";
import { GetUserWiseReport } from "../../../api/SalesApi";
import styles from "./indreport.module.css";

async function organizeData(start_date, end_date, setTableData, setDateArr) {
  const query = `start_date=${start_date}&end_date=${end_date}`;
  const data = await GetUserWiseReport(query);
  // console.log(data);
  const baseDateArr = getTotalDateArr(start_date, end_date);
  let tempdataArr = [];
  const finalData = data.map((item) => {
    const datesplit = item.datedata.split(",");
    // console.log(datesplit);
    setDateArr(baseDateArr);
    const dateArr = datesplit.map((item) => {
      const splitItem = item.split(">");
      return {
        date: splitItem[0],
        amount: parseInt(splitItem[1]),
      };
    });

    const finalDateArr = baseDateArr.map((item) => {
      const finddata = dateArr.filter((fitem) => fitem.date === item);
      return {
        date: finddata[0]?.date || item,
        amount: finddata[0]?.amount || 0,
      };
    });
    tempdataArr.push(...finalDateArr);
    return {
      ...item,
      dateArr: finalDateArr,
    };
  });
  const mfinaldata = tempdataArr.reduce((value, current) => {
    if (!value[current.date]) {
      value[current.date] = [];
    }
    value[current.date].push(current);
    return value;
  }, {});
  setTableData({ head: finalData, tableArr: mfinaldata });
}

function getTotalDateArr(startDate, endDate) {
  const firstDate = ServerTimeToFormateDateOnly(parseInt(startDate));
  const lastDate = ServerTimeToFormateDateOnly(parseInt(endDate));
  let nextDate = new Date(firstDate);
  let dateArr = [];
  while (nextDate.getTime() < new Date(lastDate).getTime()) {
    dateArr.push(nextDate.toISOString().split("T")[0]);
    nextDate.setDate(nextDate.getDate() + 1);
  }
  return dateArr;
}

export default function IndreportPop({ popUp, setPopUp }) {
  const [tableData, setTableData] = useState({ head: [], tableArr: [] });
  const [dateArr, setDateArr] = useState([]);
  //   console.log(popUp);
  const [searchParams] = useSearchParams();
  const start_date = searchParams.get("start_date");
  const end_date = searchParams.get("end_date");

  useEffect(() => {
    organizeData(start_date, end_date, setTableData, setDateArr);
  }, [start_date, end_date]);
  let finaltotal = 0;
  return (
    <div className={styles.wrapper}>
      <div className={styles.holder}>
        <div className={styles.header}>
          <h3>Individual Report</h3>
          <button
            onClick={() => {
              setPopUp();
              document.body.style.overflow = "auto";
            }}
          >
            Close
          </button>
        </div>
        <div className={styles.table_wrapper}>
          <table>
            <thead>
              <tr>
                <th>SL</th>
                <th>Name</th>
                {/* {dateArr.map((item, index) => {
                  return <th key={index}>{item}</th>;
                })} */}
                {tableData.head.map((item, index) => {
                  return <th key={index}>{item.userName}</th>;
                })}
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {dateArr.map((item, index) => {
                let totalamo = 0;
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item}</td>
                    {tableData.tableArr[item].map((titem, tindex) => {
                      totalamo += titem.amount;
                      return <td key={`${index}d${tindex}`}>{titem.amount}</td>;
                    })}
                    <td aria-label="bold-text">{totalamo}</td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td aria-label="bold-text">Total</td>
                {tableData.head.map((mitem) => {
                  finaltotal += mitem.ftotal;
                  return (
                    <td aria-label="bold-text" key={mitem.ftotal}>
                      {mitem.ftotal}
                    </td>
                  );
                })}
                <td aria-label="bold-text">{finaltotal}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
