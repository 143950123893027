import MyAxios from 'axios'
import { API_LINK } from '../APILink'
const MY_API_LINK = `${API_LINK}report-link/`

const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}
export async function GetPurchaseReportData(mdata) {
    try {
        let data = await MyAxios.post(`${MY_API_LINK}purchase-data`, mdata, configdata)
        return data.data
    } catch (error) {
        return 0
    }
}
export async function GetIndivisualLadgerData(mdata) {
    try {
        let data = await MyAxios.post(`${MY_API_LINK}individual-pdf`, mdata, configdata)
        return data.data
    } catch (error) {
        return 0
    }
}