import React, { useState } from 'react'
import PasswordTab from './TabView/PasswordTab'
import ProfileTab from './TabView/ProfileTab'
import profileCss from './profileview.module.css'
const ProfileView = ({popUp, setPopUp}) => {
    const [viewState, setViewSate] = useState(1)
  return (
    <div className={profileCss.wrapper}>
      <div className={profileCss.holder}>
        <div className={profileCss.tabs}>
          <button className={viewState === 1 && profileCss.active_tab} onClick={()=>setViewSate(1)}>Profile</button>
          <button className={viewState === 2 && profileCss.active_tab} onClick={()=>setViewSate(2)}>Change Password</button>
        </div>
          {viewState === 1 && <ProfileTab popUp={popUp} setPopUp={setPopUp}/>}
          {viewState === 2 && <PasswordTab popUp={popUp} setPopUp={setPopUp}/>}
      </div>
    </div>
  )
}

export default ProfileView