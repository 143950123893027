import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import {
  fetchInitCustomer,
  fetchSearchedCustomer,
  selectCustomerStatus,
  selectInitCustomer,
} from "../../../ReduxFiles/Slices/CustomerSlice";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import CustomerLedgerPDF from "../SalesReportPDFnew/CustomerLedgerPDF";
import CustomerLedgerCss from "./css/CustomerLedger.module.css";

const CustomerLedger = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<MainComp />} />
        <Route path="/view_customer_ledger" element={<CustomerLedgerPDF />} />
        <Route path="/check" element={<CheckData />} />
      </Routes>
    </>
  );
};

export default CustomerLedger;

function CheckData() {
  return (
    <div>
      <h1>hello data</h1>
      <h1>hello data</h1>
      <h1>hello data</h1>
      <h1>hello data</h1>
      <h1>hello data</h1>
      <h1>hello data</h1>
      <h1>hello data</h1>
    </div>
  );
}
const MainComp = () => {
  const dispatch = useDispatch();
  const CustomerList = useSelector(selectInitCustomer);
  const customerStatus = useSelector(selectCustomerStatus);
  const [userData, setUserData] = useState();

  function onCustomerChange(data) {
    setUserData(data);
  }

  const onInputDataChange = (inputValue, { action }) => {
    if (action === "input-change") {
      if (inputValue.length >= 3) {
        dispatch(fetchSearchedCustomer(inputValue));
      }
    }
  };

  useEffect(() => {
    if (customerStatus === "idle") {
      dispatch(fetchInitCustomer());
    }
  }, [customerStatus, dispatch]);

  function onCustomerClick() {
    // console.log(userData)
    if (userData) {
      window.open(
        "/reports/customer-ladger/view_customer_ledger?phone=" + userData.phone,
        "_blank"
      );
    } else {
      alert("Select user to continue");
    }
  }
  return (
    <section
      style={{ marginTop: "10px" }}
      className={CustomerLedgerCss.salesMain}
    >
      <h2 className={CustomerLedgerCss.saleHeading}>Customer Ledger</h2>

      <div
        className={`${CustomerLedgerCss.common_class} ${CustomerLedgerCss.complain_category}`}
        style={{ margin: "0 20px" }}
      >
        <div className={CustomerLedgerCss.input_section2}>
          <div className={CustomerLedgerCss.inputSection}>
            <Select
              className={CustomerLedgerCss.selectArea}
              options={CustomerList}
              getOptionLabel={(option) => `${option.name} - ${option.phone}`}
              getOptionValue={(option) => option.id}
              onChange={onCustomerChange}
              onInputChange={onInputDataChange}
            />
            {userData ? (
              <div className={CustomerLedgerCss.input_wrapper}>
                <p>Customer Name : {userData?.name}</p>
                <p>Customer Phone : {userData?.phone}</p>
                <p>Customer Email : {userData?.email}</p>
              </div>
            ) : (
              ""
            )}
            <div className={CustomerLedgerCss.customer_btn}>
              <button onClick={onCustomerClick}>See Customer Ledger</button>
              {/* <button  style={{float:'none', background:'green'}} onClick={onAllLedgerClick}>See All Ledger</button> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
