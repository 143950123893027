import React from "react";
import { BsQuestionLg } from "react-icons/bs";
import styles from "./css/alertmessage.module.css";
export default function AlertMessage({ message, yesClick, noClick }) {
  return (
    <div className={styles.alert_wrapper}>
      <div className={styles.alert_holder}>
        <BsQuestionLg />
        <h2>{message}</h2>
        <div className={styles.alert_button_wrapper}>
          <button onClick={yesClick}>OK</button>
          <button onClick={noClick}>NO</button>
        </div>
      </div>
    </div>
  );
}
