import React, { useEffect, useState } from 'react'
import { GetDatafromDatabase } from '../../connector/DatabaseCheck'
import { Route, Routes } from 'react-router-dom'
import SalesCollectinStatementPDF from './SalesCollectinStatementPDF'
import SaleCollectionStatementCss from './css/SaleCollectionStatement.module.css'

const SaleCollectionStatement = () => {
      function getColor() {
        let leftPart = document.querySelector('.sidepart')
        leftPart?.classList.remove('display-none')
    }
    useEffect(()=>{
        getColor()
    },[])
    return (
        <>
        <Routes>
            <Route path='/' element={<MainComp/>}/>
            <Route path='/collection-view' element={<SalesCollectinStatementPDF/>}/>
        </Routes>
        </>
    )
}

export default SaleCollectionStatement

const MainComp = () => {
    const [SearchData, setSearchData] = useState()
    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        if (name==='time_selection') {
            setSearchData({[name]:value})
        }else{
            setSearchData({...SearchData, [name]:value})
        }
    }

    function onSearchClick() {
        let staticLink = '/admin/report/collection_statement/collection-view'
        let queryString = ''
        if (SearchData?.time_selection === 'custom_range') {
            if (SearchData?.start_date && SearchData?.end_date) {
                queryString = `/?start_date=${SearchData.start_date}&end_date=${SearchData.end_date}`
                window.open(staticLink + queryString, '_blank')
            } else {
                alert('Please Select Date Range to Continue')
            }
        } else {
            if (SearchData?.groupid) {
                queryString = `/?group=${SearchData.groupid}`
                window.open(staticLink + queryString, '_blank')
            } else {
                window.open(staticLink, '_blank')
            }

        }
    }

    return <>
    <section className={SaleCollectionStatementCss.heading}>
                <div className={SaleCollectionStatementCss.inner_headign}>
                    <h2 className={SaleCollectionStatementCss.headign_h2}>Sales Collection Statement</h2>
        <div className={SaleCollectionStatementCss.input_section}>
            <div className={SaleCollectionStatementCss.common}>
                <select name='time_selection' onChange={onDataChange}>
                    <option value={'all-time'}>All Time</option>
                    <option value={'custom_range'}>Custom Range</option>
                </select>                
            </div>
        </div>
        {SearchData?.time_selection ==='custom_range' ? 
        <><div className={`${SaleCollectionStatementCss.input_section2} ${SaleCollectionStatementCss.date_section}`}>
        <div className={SaleCollectionStatementCss.common}>
            <label>Start Date</label>
            <input name='start_date' type='date' onChange={onDataChange} />
        </div>
        <div className={SaleCollectionStatementCss.common}>
            <label>End Date</label>
            <input name='end_date' type='date' onChange={onDataChange} />
        </div>
    </div></> : 
        ''}
        <div className={SaleCollectionStatementCss.input_section2}>
            <div className={SaleCollectionStatementCss.common}>
                <button className={SaleCollectionStatementCss.report_button} onClick={onSearchClick}>Search</button>
            </div>
        </div>
        </div>
            </section>
    </>
}
