import React, { useEffect, useState } from 'react'
import { faToggleOn, faToggleOff, faTrashCan, faPrint, faEye} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LocalTimeStampToDate } from '../MyFunctions/MyFunctions'
import { GetBankList } from '../../api/BankTransactionApi'
import ManageChequeCss from './css/ManageCheque.module.css'
const ManageCheque = () => {
 const [allCheque, setAllCheque] = useState()
 const [tableData, setTableData] = useState([])
 const [bankData, setBankData] = useState()
 const [chequeData, setChequeData] = useState()

 async function getBankList() {
    let BankList =  await GetBankList()
    setBankData(BankList)
    let chequeData =  [] //await GetTowTableData('bank_list', 'bank_cheque', 'id', 'bankID')
    let sortArr = chequeData.sort((a, b) => (a.chequeNumber > b.chequeNumber) ? 1 : -1)
   // console.log(chequeData)
    if (chequeData) {
        setTableData(chequeData)
        setAllCheque(chequeData) 
    }

    }


    useEffect(()=>{
        getBankList()
    },[])
    
    
    function onBankChange(e) {
        let value = e.target.value
        let myNewArr = []
        if (!value) {
            setTableData(allCheque)
        }else{
            allCheque?.map((item)=>{
                if (item.bankID==value) {
                    myNewArr.push(item)
                }
            })
            setTableData(myNewArr)
        }
       
    }
    function onTypeChange(e) {
        let value = e.target.value
        let myNewArr = []
        allCheque?.map((item)=>{
            if (value==='used') {
                if (item.used) {
                myNewArr.push(item)
                }
            }else{
                if (!item.used) {
                    myNewArr.push(item)
                 }
            }
        })
        setTableData(myNewArr)
    }
    
    const marginTop = {
        marginTop: '10px'
    }
    return (
            <section  className={ManageChequeCss.sectionWrapper}>
            { chequeData?  <CreateCheque chequeData={chequeData} setChequeData={setChequeData}/>:''}
                <div className={ManageChequeCss.innerSection}>
                    <h2 className={ManageChequeCss.saleHeading}>Manage Cheque</h2>
                    <div className={ManageChequeCss.formWrapper}>
                        <form>
                            <div className={ManageChequeCss.inputWrapper}>
                                <label>Bank Name</label>
                                <select className={ManageChequeCss.sectonInner} onChange={onBankChange}>
                                    <option value={''}>Select Bank</option>
                                    {bankData?.map((item, index)=>{
                                    return <option key={index} value={item.id}>{item.bankName} - ({item.acNumber})</option>
                                    })}
                                </select>
                            </div>
                            <div className={ManageChequeCss.inputWrapper}>
                                <label>Cheque Type</label>
                                <select className={ManageChequeCss.sectonInner} onChange={onTypeChange}>
                                    <option value={''}>Select Cheque type</option>
                                    <option value={'used'}>used</option>
                                    <option value={'unused'}>unused</option>
                                </select>
                            </div>
                            <div className={ManageChequeCss.buttonWrapper}>
                              <button onClick={()=>{setChequeData(bankData)}}>Add New Cheque</button>
                            </div>  
                        </form>     
                    </div>
                    <div className={ManageChequeCss.tableWrapper}>
                        <table>
                            <thead>
                                <tr>
                                <th>SL</th>
                                <th>Bank Name</th>
                                <th>A/C Name</th>
                                <th>Cheque No</th>
                                <th>Genarate Date</th>
                                <th>Cheque Issue Date</th>
                                <th>Cancelled Date</th>
                                <th>status</th>
                                <th>Action</th>
                                </tr>
                            </thead>
                             <tbody>
                             {tableData?.map((item, index)=>{
                                return <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{item.bankName} - ({item.acNumber})</td>
                                    <td>{item.acName}</td>
                                    <td>{item.chequeNumber}</td>
                                    <td>{LocalTimeStampToDate(item.created_date)}</td>
                                    <td>{item.chequeIssuDate ? item.chequeIssuDate:'--'}</td>
                                    <td>{item.cancelledDate ? item.cancelledDate:'--'}</td>
                                    <td><ToggleSwitch toggleOnOff={item.active} id={item.cheque_id} tableData={tableData} setTableData={setTableData}/></td>
                                    <td>{item.used ? 
                                    <div className={ManageChequeCss.table_icon_sec}>
                                    <FontAwesomeIcon icon={faPrint}/>
                                    <FontAwesomeIcon icon={faEye}/>
                                    </div>:<div className={ManageChequeCss.table_icon_sec}><FontAwesomeIcon icon={faTrashCan} className={ManageChequeCss.color_red}/></div>}</td>

                                </tr>
                            })}
                            </tbody>       
                          
                        </table>
                    </div>
                </div>
            </section>

    )
}

export default ManageCheque
const ToggleSwitch = ({ toggleOnOff, id, tableData, setTableData}) => {
   // console.log(id)
    function toggleClick() {
     //   console.log(id)
       let myArr = []
       //console.log(tableData)
        tableData?.map(async (item)=>{
            if (item.cheque_id===id) {
                let okActive = !item.active
                item = {...item, active:okActive}
                myArr.push(item)
              //await UpdateItemExpressCustomID('bank_cheque', {active:okActive}, 'cheque_id', id)
            }
            else{
                myArr.push(item)
            }
        })
        setTableData(myArr)
    }



    return (
        <>
            {toggleOnOff ? <div className={ManageChequeCss.ToggleSwitchon}> <p onClick={() => { toggleClick(id) }}> </p><FontAwesomeIcon icon={faToggleOn} /></div> : <div className={ManageChequeCss.ToggleSwitchoff}><p onClick={() => { toggleClick(id) }}> </p><FontAwesomeIcon icon={faToggleOff} /></div>}
        </>
    )
}

const CreateCheque = ({chequeData, setChequeData})=>{
    const [chequeInfo, setChequeInfo] =useState()
  ///  console.log(chequeData)
    function onDataChange(e) {
       let name = e.target.name 
       let value = e.target.value
     //  console.log(value)
       if(name==='bankID'){
        chequeData?.map((item)=>{
            if (item.id==value) {
                setChequeInfo({...chequeInfo, startNumber:item.lastChequeNumber+1, [name]:value, lastNumber:item.lastChequeNumber})
            }
        })
            
       }else{
        setChequeInfo({...chequeInfo, [name]:value})
       }

    }

    
    const [errText, setErrText] = useState()

  async  function onCreateClick() {
        let bankIDErr = ValidateFunction('bankID', 'Bank Name')
        let chequeGenarateDateErr = ValidateFunction('chequeGenarateDate', 'Genarated Date')
        let endNumberErr = ValidateFunction('endNumber', 'End Number')
        let startNumberErr = ValidateFunction('startNumber', 'Start Number')
        setErrText({bankIDErr, chequeGenarateDateErr, endNumberErr, startNumberErr})
        if (!bankIDErr && !chequeGenarateDateErr && !endNumberErr && !startNumberErr) {
            let mStartNumber =  parseInt(chequeInfo.startNumber)
            let mEndNumber =  parseInt(chequeInfo.endNumber)

            console.log(chequeInfo)
            
            if (mStartNumber<=chequeInfo.lastNumber) {
                alert('cheque already exist')
            }else{
                //console.log(chequeInfo.lastNumber, mStartNumber)
                let dataArr = []
                for (let index = mStartNumber; index < mEndNumber+1; index++) {
                  let finalData = {
                     bankID:chequeInfo.bankID,
                     used:0,
                     cancelled:0,
                     chequeNumber:index,
                     chequeAmmount:0,
                     active:1,
                     created_date: new Date().getTime()
                    }
                    dataArr.push(finalData)
                 }
                
                 let myPromises = dataArr.map(async function (item) {
                   // console.log(item)
                    
                   // await SaveDataExpress('bank_cheque',{...item})
                 })
                
                Promise.all(myPromises).then(async ()=>{
                 //   console.log('saved')
                  //  await UpdateItemExpress('bank_list', {lastChequeNumber:mEndNumber}, chequeInfo.bankID)
                    setChequeData()
                })
               

            }
            
        }
        
       
    }
    function ValidateFunction(field, message) {
        let text = ""
            if (!chequeInfo[field]) {
                text = message + " is Required"
            }
        return text;
      }

    function genarateDate() {
        let mydate = new Date();
        let day = mydate.getDate()
        let month = mydate.getMonth() + 1
        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }
        let year = mydate.getFullYear()
        let hour = mydate.getHours()
        if (hour < 10) {
            hour = '0' + hour
        }
        //console.log(month+'-'+day+'-'+year)
        return year+'-'+ month+'-'+day
    }
    useEffect(()=>{
        setChequeInfo({...chequeInfo, chequeGenarateDate:genarateDate()})
    },[])

    return (
        <div className={ManageChequeCss.create_cheque_pop_wrapper}>
            <div className={ManageChequeCss.create_cheque_pop}>
                <h3>Create Cheque</h3>
                <div className={ManageChequeCss.inputWrapper}>
                    <div className={ManageChequeCss.input_common}>
                        <label>Cheque Genarate Date</label>
                        <input name='chequeGenarateDate' type={'date'} value={chequeInfo?.chequeGenarateDate} onChange={onDataChange}/>
                        {errText?.chequeGenarateDateErr ? <p className={ManageChequeCss.WarningText}>{errText.chequeGenarateDateErr}</p>:''}
                    </div>
                    
                    <div className={ManageChequeCss.input_common}>
                        <label>Cheque Genarate Date</label>
                        <select name='bankID' onChange={onDataChange}>
                                <option value={''}>Select Bank</option>
                                {chequeData?.map((item, index)=>{
                                return <option key={index} value={item.id}>{item.bankName} - ({item.acNumber})</option>
                                })}
                        </select>
                        {errText?.bankIDErr ? <p className={ManageChequeCss.WarningText}>{errText.bankIDErr}</p>:''}
                    </div>
                    
                    <div className={ManageChequeCss.input_common}>
                        <label>Start Number</label>
                        <input name='startNumber' type={'number'} onChange={onDataChange} value={chequeInfo?.startNumber ? chequeInfo.startNumber:0}/>
                        {errText?.startNumberErr ? <p className={ManageChequeCss.WarningText}>{errText.startNumberErr}</p>:''}
                    </div>
                    <div className={ManageChequeCss.input_common}>
                        <label>End Number</label>
                        <input name='endNumber' type={'number'} onChange={onDataChange}/>
                        {errText?.endNumberErr ? <p className={ManageChequeCss.WarningText}>{errText.endNumberErr}</p>:''}
                    </div>
                </div>
                <div className={`${ManageChequeCss.button_section} ${ManageChequeCss.create_cheque_button}`}>
                    <button onClick={onCreateClick}>Create</button>
                    <button onClick={()=>{setChequeData()}}>Close</button>
                </div>
            </div>
        </div>
    )
}