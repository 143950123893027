import React, { useEffect, useState } from 'react'
import { useSearchParams} from 'react-router-dom'
import { LocalTimeStampToDate } from '../../MyFunctions/MyFunctions'
import { HeaderFooterWrapper } from '../HeadetFooter'
import { GetALLSupplier } from '../../../api/SupplierApi'
import { GetPurchaseReportData } from '../../../api/ReportApi/PurchaseReportAPI'
import ShortReportCss from './css/ShortReport.module.css'
const ShortReport = ({heading}) => {
    const [invoiceState, setInvoiceState] = useState()
    let [searchParams, setSearchParams] = useSearchParams();
    const [dateRange, setDateRange] = useState()
    const [allSupplier, setAllSupplier] = useState()

 async function getData() {
    let start_date = searchParams.get('start_date')
    let end_date = searchParams.get('end_date')
    let supplierid = searchParams.get('supplierid')
    setDateRange({start_date, end_date})
   // console.log(start_date)
   let getAllSupplier = await GetALLSupplier()
   setAllSupplier(getAllSupplier)
    let purchaseData = await GetPurchaseReportData({start_date, end_date, supplierid})
    console.log(purchaseData);
     let finalArr = []
     purchaseData?.forEach(element => {
         let finalData = {
             date: element.created_date,
             pono: element.id,
             sname: element.supplierID,
             pamount: element.grandTotal,
             paymentAmount: 0,
             discount: element.percent ? element.percent : 0,
             ramount: 0,
             companyaName:element.companyaName
         }
         finalArr.push(finalData)

     });
     setInvoiceState(finalArr)
   
    
}

useEffect(()=>{
    getData()
},[])

 

    function checkClick(params) {
       
    }

    useEffect(() => {
        getData()
    }, [])

    function getAlltotal(marray, identity) {
        let allTotal = 0
        marray?.forEach(element => {
            allTotal += element[identity]
        });
       return Math.round(allTotal)
    }
    function getDiscountTotal(marray, identity) {
        let allTotal = 0
        marray?.forEach(element => {
            allTotal += (element[identity]*element.discount/100)
        });
       return Math.round(allTotal)
    }
   

  return (
    <>

            <HeaderFooterWrapper content={
                <>
                <h3>{heading}</h3>
            <div className={ShortReportCss.details}>
                <div className={ShortReportCss.client}>
                    <div className={ShortReportCss.info}>
                        <span className={ShortReportCss.moreinfo} onClick={checkClick}>Supplier : 
                         </span>{searchParams.get('supplierid') ? allSupplier?.filter((fitem)=>{
                            return fitem.id===parseInt(searchParams.get('supplierid')) ? fitem:''
                        }).map((item)=>{
                            return ' '+item.companyaName
                        }):'All Supplier'}
                    </div>
                </div>
                <div className={ShortReportCss.invoice}>
                    <div className={ShortReportCss.info}>
                        {<span className={ShortReportCss.moreinfo}>Date : {dateRange?.start_date ? dateRange?.start_date +' -TO- '+ dateRange?.end_date:'All Time'} </span>}
                    </div>
                </div>
            </div>
            <div className={ShortReportCss.table}>
                <table>
                    <thead>
                        <tr>
                            <th className={ShortReportCss.no}>Date & Time</th>
                            <th style={{ paddingLeft: '10px'}}>Po No.</th>
                            <th className={ShortReportCss.unit}>Supplier Name</th>
                            <th className={ShortReportCss.unit_price}>Purchase Amount</th>
                            <th className={ShortReportCss.unit_price}>Paid Amount</th>
                            <th className={ShortReportCss.unit_price}>Discount</th>
                            <th className={ShortReportCss.total}>Return Amount</th>
                            <th className={ShortReportCss.total}>Return Discount</th>
                            <th className={ShortReportCss.total}>Due Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                    {invoiceState?.map((item, index)=>{
                        return <tr key={index}>
                            <td>{LocalTimeStampToDate(parseInt(item.date)) }</td>
                            <td style={{ textAlign: 'left'}} >{item.pono}</td>
                            <td style={{ textAlign: 'left'}}>{item.companyaName}</td>
                            <td>{
                            (Math.round(item.pamount))
                            
                            }</td>
                            <td>{
                            (Math.round(item.paymentAmount))
                            
                            }</td>
                            <td>{Math.round(item.pamount*item.discount/100)}</td>
                            <td>{item.ramount - parseInt(Math.round(item.ramount*item.discount/100))}</td>
                            <td>{parseInt(Math.round(item.ramount*item.discount/100))}</td>
                            <td>{ Math.round(item.pamount) - (Math.round(item.pamount*item.discount/100)+Math.round(item.paymentAmount)+(item.ramount - parseInt(Math.round(item.ramount*item.discount/100))))}</td>
                            </tr>
                      
                    })}
                    
                    <tr className={ShortReportCss.final_row}>
                        {/* <td className={ShortReportCss.no_border_right} ></td> */}
                        <td className={ShortReportCss.no_border_right} colSpan={'3'}>Total :</td>
                        {/* <td>{getAlltotal(invoiceState, 'purchaseAmmount')}</td>
                        <td>{getAlltotal(invoiceState, 'returnAmmount')}</td> */}
                        <td>{getAlltotal(invoiceState, 'pamount')}</td>
                        <td>{getAlltotal(invoiceState, 'paymentAmount')}</td>
                        <td>{getDiscountTotal(invoiceState, 'pamount')}</td>
                        <td>{getAlltotal(invoiceState, 'ramount')-getDiscountTotal(invoiceState, 'ramount')}</td>
                        <td>{getDiscountTotal(invoiceState, 'ramount')}</td>
                        <td></td>
                    </tr>
                    <tr className={ShortReportCss.final_row}>
                        {/* <td className={ShortReportCss.no_border_right} ></td> */}
                        <td className={ShortReportCss.no_border_right} colSpan={'8'}>Balance :</td>
                        {/* <td>{getAlltotal(invoiceState, 'purchaseAmmount')}</td>
                        <td>{getAlltotal(invoiceState, 'returnAmmount')}</td> */}
                        <td>({(getAlltotal(invoiceState, 'pamount')+getDiscountTotal(invoiceState, 'ramount'))-(getAlltotal(invoiceState, 'paymentAmount') +getAlltotal(invoiceState, 'ramount')+getDiscountTotal(invoiceState, 'pamount'))})</td>
                       
                    </tr>
                    </tbody>
                </table>
                {/* <div className=last-raw'>
                <p>Balance :  {getAlltotal(invoiceState, 'purchaseAmmount')-getAlltotal(invoiceState, 'returnAmmount')+getAlltotal(invoiceState, 'receiptAmmount')-getAlltotal(invoiceState, 'paymentAmmount')}</p>
                </div> */}
            </div>
            </>
            }

            />

        </>
  )
}

export default ShortReport