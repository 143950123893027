import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { fetchBrands } from '../../../ReduxFiles/Slices/BrandListSlice'
import { SaveBrandList } from '../../../api/BrandListApi'
import addbrandCss from './addbrand.module.css'

const AddNewBrand = ({setPopUp }) => {
  const dispatch = useDispatch()
  function closeOverlay() {
    setPopUp()
    document.body.style.overflow = 'auto'
  }
  const [fakeUrl, setFakeUrl] = useState([])

  function onImageChange(e, name) {
    const imageFiles = e.target.files
    setFakeUrl({ ...fakeUrl, [name]: genarateFakeUrl(imageFiles[0]) })
  }
  function genarateFakeUrl(image) {
    let myurl = image ? URL.createObjectURL(image) : null
    return myurl;
  }
  async function onSaveData(e) {
    e.preventDefault()
    const BrandName = e.target.BrandName.value
    const BrandMT = e.target.BrandMT.value
    const BrandMD = e.target.BrandMD.value
    const Brandimg = e.target.Brandimg.files
    const BrandBanner = e.target.BrandBanner.files
    let data = {
      BrandName: BrandName,
      BrandMT: BrandMT,
      BrandMD: BrandMD,
      WebsiteVisibitly: 1,
      created_date: new Date().getTime()
    }
    try {
      await SaveBrandList(data, Brandimg[0], BrandBanner[0])
      dispatch(fetchBrands())
      setPopUp()
      document.body.style.overflow = 'auto'
      toast.success('Brand Added Successfully')
    } catch (error) {
      toast.error(error.message)
    }
  }
  return (
    <div className={addbrandCss.wrapper}>
      <div className={addbrandCss.holder}>
        <h2>Add New Brand</h2>
        <a onClick={closeOverlay} className={addbrandCss.close} href="#">&times;</a>
        <form className={addbrandCss.content} onSubmit={onSaveData}>
          <div className={addbrandCss.inner}>
            <label>Brand Name</label>
            <input name='BrandName' type='text' placeholder='brnad name here' required />
          </div>
          <div className={addbrandCss.inner}>
            <label>Brand Meta Title</label>
            <input name='BrandMT' type='text' placeholder='brand meta title here' required />
          </div>
          <div className={addbrandCss.inner}>
            <label>Brand Meta Description</label>
            <input name='BrandMD' type='text' placeholder='brand meta description here' required />
          </div>
          <div className={addbrandCss.inner}>
            <label>Brand Image</label>
            <input name='Brandimg' type='file' required onChange={(e) => onImageChange(e, 'Brandimg')} multiple={false} accept='image/webp'/>
          </div>
          <div className={addbrandCss.image_holder}>
            {fakeUrl.Brandimg && <img src={fakeUrl.Brandimg} alt='placeholder' />}
          </div>
          <div className={addbrandCss.inner}>
            <label>Brand Banner</label>
            <input name='BrandBanner' type='file' required onChange={(e) => onImageChange(e, 'BrandBanner')} multiple={false} accept='image/webp'/>
          </div>
          <div className={addbrandCss.image_holder}>
            {fakeUrl.BrandBanner && <img src={fakeUrl.BrandBanner} alt='placeholder' />}
          </div>
          <div className={addbrandCss.btn_group}>
            <button>Save Brand</button>
          </div>

        </form>
      </div>
    </div>
  )
}
export default AddNewBrand