import React from 'react'
import InfoAlertCss from './css/InfoAlert.module.css'

const InfoAlert = ({ okClick, message }) => {
    return (
        <div className={InfoAlertCss.InfoAlert}>
            <h2 className={InfoAlertCss.caption}>{message ? message : ""}</h2>
            <div className={InfoAlertCss.buttonsec}>
                <input onClick={okClick} className={InfoAlertCss.yes} type={"submit"} value={"ok"} />
            </div>
        </div>
    )
}

export default InfoAlert