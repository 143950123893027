import React, { useEffect, useState } from "react";
import ItemPriceEdit from "./ItemPriceEdit";
import ItemInformationEdit from "./ItemInformationEdit";
import ProductsAttributesEdit from "./ProductsAttributesEdit";
import { useParams } from "react-router-dom";
import { GetFilterProducts } from "../../../../api/ProductListApi";
import AddNewMenuEdit from "./AddNewMenuEdit";
import ImageUploader from "./ImageUploader";
import AddNewItemEditCss from "./css/AddNewItemEditCss.module.css";
async function getEditData(id, setItem) {
  let myData = await GetFilterProducts(`pl.id=${id}`);
  setItem(myData[0] || {});
}
const AddNewItemEdit = () => {
  const myParams = useParams();
  const [showState, setShowState] = useState(1);
  const [item, setItem] = useState({});

  useEffect(() => {
    getEditData(myParams.data, setItem);
  }, []);
  return (
    <>
      <div
        className={`${AddNewItemEditCss.addnewiteminfo} ${AddNewItemEditCss.admincomm}`}
      >
        <h3>Item Information</h3>
        <AddNewMenuEdit
          mstate={setShowState}
          item={item}
          stateValue={showState}
        />
        <div
          className={
            showState === 1
              ? `${AddNewItemEditCss.disBlock}`
              : `${AddNewItemEditCss.disNone}`
          }
        >
          <ItemInformationEdit
            item={item}
            setstate={setShowState}
            setItem={setItem}
          />
        </div>
        <div
          className={
            showState === 2
              ? `${AddNewItemEditCss.disBlock}`
              : `${AddNewItemEditCss.disNone}`
          }
        >
          <ItemPriceEdit
            setstate={setShowState}
            item={item}
            setItem={setItem}
          />
        </div>
        <div
          className={
            showState === 3
              ? `${AddNewItemEditCss.disBlock}`
              : `${AddNewItemEditCss.disNone}`
          }
        >
          <ProductsAttributesEdit
            showState={showState}
            item={item}
            setItem={setItem}
          />
        </div>
        <div
          className={
            showState === 4
              ? `${AddNewItemEditCss.disBlock}`
              : `${AddNewItemEditCss.disNone}`
          }
        >
          <ImageUploader showState={showState} item={item} setItem={setItem} />
        </div>
      </div>
    </>
  );
};

export default AddNewItemEdit;
