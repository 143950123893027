import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import StockSummeryPSPdf from '../PdfFiles/StockSummeryPSPdf'
import StockSummeryPSCss from './css/StockSummeryPS.module.css'
const StockSummeryPS = () => {
    return (
        <>
            <Routes>
                <Route path='/' element={<MainComp/>}/>
                <Route path='/all-time' element={<StockSummeryPSPdf/>}/>
            </Routes>
        </>
    )
}

export default StockSummeryPS
const MainComp = () => {
    return (
        <section className={StockSummeryPSCss.heading}>
            <div className={StockSummeryPSCss.inner_headign}>
                <h2 className={StockSummeryPSCss.headign_h2}>Stock Summery Purchase & Sales</h2>
                <ShortReportSection />
            </div>
        </section>
    )
}
const ShortReportSection = () => {
    const [SearchData, setSearchData] = useState()
    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        setSearchData({...SearchData, [name]:value})
    }

    function onSearchClick() {
        let staticLink = '/admin/report/stock_summery_purchase_sales/all-time'
        let queryString = ''
        if (SearchData?.start_date && SearchData?.end_date) {
            queryString = `/?start_date=${SearchData.start_date}&end_date=${SearchData.end_date}`
        }else{
            queryString = ''
        }
       window.open(staticLink+queryString, '_blank')
    }
   
    return <>

        <div className={StockSummeryPSCss.input_section}>
            <div className={StockSummeryPSCss.common}>
                <select name='time_selection' onChange={onDataChange}>
                    <option value={'all-time'}>All Time</option>
                    <option value={'custom_range'}>Custom Range</option>
                </select>
            </div>
        </div>
        {SearchData?.time_selection ==='custom_range' ? 
        <><div className={`${StockSummeryPSCss.input_section2} ${StockSummeryPSCss.date_section}`}>
        <div className={StockSummeryPSCss.common}>
            <label>Start Date</label>
            <input name='start_date' type='date' onChange={onDataChange} />
        </div>
        <div className={StockSummeryPSCss.common}>
            <label>End Date</label>
            <input name='end_date' type='date' onChange={onDataChange} />
        </div>
    </div></> : 
        ''}
        <div className={StockSummeryPSCss.input_section2}>
            <div className={StockSummeryPSCss.common}>
                <button className={StockSummeryPSCss.report_button} onClick={onSearchClick}>Search</button>
            </div>
        </div>
    </>
}
