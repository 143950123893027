import React, { useEffect, useState } from 'react'
import { GetAllDataExpress } from '../../../api/ExpressFunctions'
import { Route, Routes } from 'react-router-dom'
import StockCapitalReport from '../PdfFiles/StockCapitalReport'
import StockCapitalCss from './css/StockCapital.module.css'

const StockCapital = () => {
 

  return (
    <>
      <Routes>
        <Route path='/' element={<MainComp/>}/>
        <Route path='/view' element={<StockCapitalReport/>}/>
      </Routes>
    </>
  )
}

export default StockCapital

const MainComp = () => {
  const [myBrands, setMyBrands] = useState()
  const [parentCatagory, setParentCatagory] = useState()
  const [subCatagory, setSubCatagory] = useState()
  const [selectedData, setSelectedData] =useState()
  const [dropdownData, setDropdownData] =useState()

  function onDataChange(e) {
    let name = e.target.name
    let value = e.target.value
   // console.log(value)
    setSelectedData({...selectedData, [name]:value})
    dropdownDataSet(value)
  }
  function onSearchDataChange(e) {
    let name = e.target.name
    let value = e.target.value
    setSelectedData({...selectedData, [name]:value})
  }

  function onSearchClick(params) {
    let staticLink = '/admin/report/stock_capital/view'
    let queryString = ''
    if (selectedData?.selection ==='Brand' && selectedData?.searchData) {
      myBrands?.forEach((item)=>{
        if (item.id===parseInt(selectedData.searchData)) {
          queryString = `?brand=${item.BrandName}&brand_id=${item.id}`
        }
      })
    }else if(selectedData?.selection ==='Parent Catagory' && selectedData?.searchData){
      let parentCat = ''
      parentCatagory.forEach((myitem)=>{
        if (myitem.id===parseInt(selectedData?.searchData)) {
          parentCat = myitem.catName
        }
      })
      queryString = `?catagory=${parentCat.replaceAll('&', 'and')}&cat_id=${selectedData?.searchData}`

    }else if(selectedData?.selection ==='Sub Catagory' && selectedData?.searchData){
      subCatagory?.forEach((item)=>{
        if (item.id===parseInt(selectedData.searchData)) {
          queryString = `?subCatagory=${item.catName}&sub_catagory_id=${selectedData.searchData}`
        }
      })
     // console.log(queryString)
    }
    console.log(queryString)

   window.open(staticLink+queryString, '_blank')
  }

function dropdownDataSet(valueData) {
    if (valueData==='Brand') {
      setDropdownData(myBrands)
    }
    else if (valueData==='Parent Catagory') {
      setDropdownData(parentCatagory)
    }
    else if (valueData==='Sub Catagory') {
      setDropdownData(subCatagory)
    }else{
      setDropdownData()
      setSelectedData()
    }
}



 async function getDatafromdatabase() {
    let brandList = await GetAllDataExpress('brand_list')
    setMyBrands(brandList)
    let catagoryLsit = await GetAllDataExpress('category_name')
    let parentArr = []
    let subArr = []
    catagoryLsit.forEach((item)=>{
      if (item.selectedItem) {
        subArr.push(item)
      }else{
        parentArr.push(item)
      }
    })
   setParentCatagory(parentArr)
   setSubCatagory(subArr)

  }
 
  useEffect(()=>{
    // getDatafromdatabase()
  },[])
  
  function getColor() {
    let leftPart = document.querySelector('.sidepart')
    leftPart?.classList.remove('display-none')
}
useEffect(()=>{
    getColor()
},[])

  return (
    <>
    <section className={StockCapitalCss.heading}>
        <div className={StockCapitalCss.inner_headign}>
          <h2 className={StockCapitalCss.headign_h2}>Stock Capital Report</h2>
          <div className={StockCapitalCss.input_section2}>
            <div className={StockCapitalCss.common}>
              <label><strong>Select Type</strong></label>
              <select name='selection'  onChange={onDataChange}>
                <option value=''>Select Type</option>
                <option value='Brand'>Brand</option>
                <option value='Parent Catagory'>Parent Catagory</option>
                <option value='Sub Catagory'>Sub Catagory</option>
              </select>
            </div>

           {dropdownData ?  <div className={StockCapitalCss.common}>
            <label><strong>{selectedData?.selection} List</strong></label>
              <select name='searchData' onChange={onSearchDataChange}>
                <option value=''>Select {selectedData?.selection}</option>
                {dropdownData?.map((item, index)=>{
                  return <option key={index} value={item.id} >{item.catName ? item.catName:item.BrandName}</option>
                })}
              </select>
            </div>:''}
            <div className={StockCapitalCss.common}>
             <button onClick={onSearchClick}>Search</button>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}