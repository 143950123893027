import React, { useEffect, useState } from "react";
import getImageNameCss from "./imagename.module.css";

const GetImageName = () => {
  const [state, setState] = useState([]);
  const [finalState, setFinalState] = useState();

  function onInputChange(e) {
    setState(e.target.files);
  }

  function onButtonClick() {
    let myArr = [];
    for (let index = 0; index < state.length; index++) {
      const element = state[index];

      let data = {
        name: element.name,
        copied: false,
      };
      myArr.push(data);
    }
    window.localStorage.setItem("image_name", JSON.stringify(myArr));
    setFinalState(myArr);
  }

  useEffect(() => {
    let preData = window.localStorage.getItem("image_name");
    if (preData !== undefined || preData !== "undefined") {
      preData = JSON.parse(preData);
      setFinalState(preData);
    }
  }, []);

  function onItemClick(item) {
    let tempArr = [];
    for (let index = 0; index < finalState.length; index++) {
      let element = finalState[index];
      if (element.name === item.name) {
        element = {
          ...element,
          copied: true,
        };
      }
      tempArr.push(element);
    }
    CopyText(item.name);
    window.localStorage.setItem("image_name", JSON.stringify(tempArr));
    setFinalState(tempArr);
    console.log(item.name);
  }

  function CopyText(text) {
    navigator.clipboard.writeText(text).then(
      function () {
        //console.log('Async: Copying to clipboard was successful!');
        //alert("Coppied");
      },
      function (err) {
        alert("Not Coppied", err);
      }
    );
  }

  return (
    <div className={getImageNameCss.salesMain}>
      <h2>Upload Image & Get Name</h2>
      <div className={getImageNameCss.innerSale}>
        <div className={getImageNameCss.input_wrapper}>
          <label>Upload Image *</label>
          <input
            name="imagename"
            type="file"
            multiple
            onChange={onInputChange}
            accept="image/png, image/gif, image/jpeg"
          />

          <button onClick={onButtonClick}>Click Me</button>
        </div>
        <div className={getImageNameCss.table}>
          {finalState?.map((item, index) => {
            return (
              <p
                key={index}
                className={
                  item.copied
                    ? getImageNameCss.display_none
                    : getImageNameCss.name_click
                }
                onClick={() => {
                  onItemClick(item);
                }}
              >
                {index + 1} . {item.name}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GetImageName;
