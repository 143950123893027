import UnverifiedDetailsCss from "./salesCss/unverifiedDetails.module.css";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetUnverifiedSalesData,
  GetUnverifiedSalesDataItems,
  InformationMessage,
  VerifyOrderAPI,
} from "../../api/SalesApi";

import { toast } from "react-toastify";
import ConfirmAlert from "../global/ConfirmAlert";
import GobackButton from "../global/GobackButton";

async function getData(order_id, setData, setOrderData) {
  const myData = await GetUnverifiedSalesData(order_id);
  const orderData = myData[0] || {};
  setOrderData(orderData);
  const data = await GetUnverifiedSalesDataItems(order_id);
  setData(data);
}

const UnverifiedDetails = () => {
  const myNavigation = useNavigate();
  const myParams = useParams();
  const [tableData, setTableData] = useState([]);
  const [orderData, setOrderData] = useState({});
  const [conShow, setConShow] = useState(false);

  async function onApproveClick(e) {
    setConShow(true);
  }

  async function clickYes() {
    let myoredermessage = `Dear Sir, Your order has been Verified.%0aThank You for shopping with us.%0aAnayase`;
    const result = await VerifyOrderAPI({ order_id: myParams.orderid });
    toast.success(result.message);
    try {
      let mPhone = orderData.phone;
      await InformationMessage({
        phone: `88${mPhone}`,
        message: myoredermessage,
      });
    } catch (error) {
      toast.error(`SMS : ${error.message}`);
    }

    setConShow(false);
    myNavigation("/sales/pending");
  }

  function clickNo() {
    document.body.style.overflow = "auto";
    setConShow(false);
  }

  function convertDate(time) {
    const dateInMillis = time * 1000;
    const date = new Date(dateInMillis);
    let myDate = date.toLocaleDateString();
    let myTime = date.toLocaleTimeString();
    myDate = myDate.replaceAll("/", "-");
    return myDate + " " + myTime;
  }

  useEffect(() => {
    getData(myParams.orderid, setTableData, setOrderData);
  }, [myParams.orderid]);

  return (
    <section className={UnverifiedDetailsCss.wrapper}>
      {conShow ? (
        <ConfirmAlert
          message="Do you want to Approve this order?"
          yesClick={clickYes}
          noClick={clickNo}
        />
      ) : (
        ""
      )}
      <div className={UnverifiedDetailsCss.holder}>
        <div className={UnverifiedDetailsCss.head}>
          <GobackButton />
          <h2>Unverified Details</h2>
        </div>

        <div className={UnverifiedDetailsCss.information}>
          <div className={UnverifiedDetailsCss.customerDetails}>
            <h3>Order ID #{myParams?.orderid}</h3>
            <p>
              <strong>Customer Name</strong> : {orderData.name}
            </p>
            <p>
              <strong>Phone</strong> : {orderData.phone}
            </p>
            <div className={UnverifiedDetailsCss.addressSec}>
              {orderData?.pickupPoint ? (
                <p>
                  <strong>Pickup Point</strong> : {orderData.pickupPoint}
                </p>
              ) : (
                <p>
                  <strong>Delivery Address</strong> : {orderData.full_address}
                </p>
              )}
            </div>
          </div>
          <div className={UnverifiedDetailsCss.order_details}>
            <h3>Order Details</h3>
            <p>Order Date : {convertDate(orderData.created_date / 1000)} </p>
            <p>Payment Type : {orderData?.paymentType}</p>
          </div>
        </div>
        <div className={UnverifiedDetailsCss.table_wrapper}>
          <table>
            <tbody>
              <tr>
                <th>SL</th>
                <th>Item</th>
                <th>Variance</th>
                <th>Age</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total Ammount</th>
              </tr>
              {tableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.en_sort_title}</td>
                    <td>{item.Variance_name}</td>
                    <td>{item.age_name}</td>
                    <td>{item.price}</td>
                    <td>{item.quantity}</td>
                    <td>{item.quantity * item.price}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className={UnverifiedDetailsCss.payment_info}>
            <div>
              <p>Payment Type</p>
              <p>: {orderData?.paymentType}</p>
            </div>
            <div>
              <p>Discount</p>
              <p>: {orderData?.discount ? orderData?.discount : "0"} tk</p>
            </div>
            <div>
              <p>Delivery Charge</p>
              <p>: {orderData?.deliveryCharge} tk</p>
            </div>
            <div>
              <p>Total Ammount</p>
              <p>: {orderData?.grandTotal + orderData?.deliveryCharge} tk</p>
            </div>
          </div>
          <div className={UnverifiedDetailsCss.btn_group}>
            <button onClick={onApproveClick}>Verify Order</button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UnverifiedDetails;
