import React, { useEffect, useState } from 'react'
import rpmCss from './rpm.module.css'
import { useSelector } from 'react-redux'
import { selectUserInfo } from '../../../ReduxFiles/Slices/UserSlice'
import { useNavigate } from 'react-router-dom'
import { SaveProductStock, SaveProductVarianceStock } from '../../../api/StockApi'
import { GetFilterPurchaseVariance, GetPurchaseItems, SavePurchaseSummery, UpdatePurchaseData } from '../../../api/PurchaseApi'
import { SaveDayBookData } from '../../../api/DayBookApi'
import { selectProductList } from '../../../ReduxFiles/Slices/ProductSlice'
import { GetFilterProductVariance } from '../../../api/ProductVarianceApi'

export default function ReceivePoModel({ popData, setPopData }) {
    const userInfo = useSelector(selectUserInfo)
    const [tableData, setTableData] = useState()
    const [varData, setVarData] = useState()
    const myNavigate = useNavigate()
    async function onConfirm() {
        tableData.forEach(async (element) => {
            await SaveProductStock({ product_id: element.product_id, stock_quantity: element.quantity })
            if (element.hasVriance) {
                element.variance.map(async (vitem) => {
                    let vrID = vitem.varinace_id
                    await SaveProductVarianceStock({ variance_id: vrID, stock_quantity: vitem.quantity })
                })
            }
        });
          await UpdatePurchaseData(popData.id, {pending:0, receivedPO: 1, status: 'PO Received' })

          let dayBookData = {
            created_date:new Date().getTime(),
            paticulars:"Purchase (PO Received)",
            identity:popData.id,
            received:0,
            payment:popData.payAmmount ? popData.payAmmount:0,
            processorID:userInfo.id,
        }
       await SaveDayBookData(dayBookData)
        let summeryData = {
            order_date:popData.poDate,
            supplier_id:popData.supplierID,
            status:'PO Received',
            attemptBy:userInfo.id,
            purchase_id:popData.id,
            created_date:new Date().getTime()
          }
        await SavePurchaseSummery(summeryData)
    setPopData()
   myNavigate('/purchase/history')
        
    }
    function onCloseClick(params) {
        setPopData()
    }
    const mAllProducts = useSelector(selectProductList)
    async function getTableData() {
        let tableData = await GetPurchaseItems(`purchase_id = ${popData.id}`)
        let AllVarData = []
        let tempArr = []
      let myPromises = tableData.map(async function(item) {
            if (item.hasVriance) {
                let varianceData = await GetFilterProductVariance(`product_id = ${item.product_id}`)
                let purchasedVar = await GetFilterPurchaseVariance(`product_id = ${item.product_id} AND purchase_item_id = ${item.id}`)
                item = {
                    ...item,
                    variance:purchasedVar
                }
                AllVarData.push(...varianceData)
                tempArr.push(item)
                
            }else{
                item = {
                    ...item,
                    variance:[]
                }
                tempArr.push(item)
            } 
        })
        Promise.all(myPromises).then(()=>{            
            let myArr = []
            tempArr.forEach(element => {
                for (let index = 0; index < mAllProducts?.length; index++) {
                    const pelement = mAllProducts[index];
                    if (element.product_id === pelement.id) {
                        element = {
                            ...element,
                            name: pelement.en_sort_title
                        }
                        myArr.push(element)
                        break
                    }
                }
            });
            setTableData(myArr) 
            setVarData(AllVarData)           
        })
    }

    useEffect(() => {
        getTableData()
    }, [])

  return (
    <div className={rpmCss.wrapper}>
   <div className={rpmCss.holder}>
   <h3>Receive Purchase Item(s)- <strong> #{popData.id}</strong></h3>
    <div className={rpmCss.table_wrapper}>
    <table>
        <tbody>
            <tr>
                <th>Sl</th>
                <th>Item Name</th>
                <th>Total Quantity</th>
                <th>Details</th>
            </tr>
            {tableData?.map((item, index) => {
               return  <tr key={index+'main'}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>{item.hasVriance ?
                       item.variance.map((vitem) => {
                           return <React.Fragment key={vitem.id + 'vr'}>
                               {varData.filter((fitem) => {
                                return fitem.id===vitem.varinace_id ? fitem:''
                               })
                                .map((mvar) => {
                                   return <p key={mvar.id+'mvar'}>{mvar.Variance_name}</p>
                                })}
                    </React.Fragment> 
                }) 
                :'No Variance'}</td>
            </tr>
            })
                
            }

        </tbody>
    </table>
    </div>
    <div className={rpmCss.button_group}>
        <button onClick={onConfirm}>Receive</button>
        <button onClick={onCloseClick}>Close</button>
    </div>
   </div>
</div>
  )
}
