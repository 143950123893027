import React, { useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import SalesViewReturn from '../SalesReportPDFnew/SalesViewReturn'
import SalesReturnReportNewCss from './css/SalesReturnReportNew.module.css'

const SalesReturnReport = () => {
    const [viewState, setViewState] = useState(1)
    return (
        <>
            <Routes>
                <Route path='/' element={<MainComp />} />
                <Route path='/sales_view_return' element={<SalesViewReturn />} />
            </Routes>
        </>
    )
}

export default SalesReturnReport

const ShortReportSection = () => {

    //

    return <></>
}

const MainComp = () => {
    const [SearchData, setSearchData] = useState()
    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        setSearchData({ ...SearchData, [name]: value })
    }

    function onSearchClick() {
        let staticLink = '/admin/report/sales_report_return/sales_view_return'
        if (SearchData?.start_date && SearchData?.end_date) {
            let queryString = `/?start_date=${SearchData.start_date}&end_date=${SearchData.end_date}`
            window.open(staticLink + queryString)
        } else {
            toast.warn('Start date & End date is Required')
        }
    }

    return <>
        <section className={SalesReturnReportNewCss.heading}>
        <h2 className='headign-h2'>Sales Return Report</h2>
            <div className={SalesReturnReportNewCss.inner_headign}>
            
                <div className={`${SalesReturnReportNewCss.input_section2} 
                    ${SalesReturnReportNewCss.date_section}`}>
                    <div className={SalesReturnReportNewCss.common}>
                        <label>Start DateP:</label>
                        <input name='start_date' type='date' onChange={onDataChange} />
                    </div>
                    <div className={SalesReturnReportNewCss.common}>
                        <label>End Date:</label>
                        <input name='end_date' type='date' onChange={onDataChange} />
                    </div>
                </div>
                <div className={SalesReturnReportNewCss.input_section}>
                    <div className={SalesReturnReportNewCss.common}>
                        <button className={SalesReturnReportNewCss.report_button} onClick={onSearchClick}>Search</button>
                    </div>
                </div>
            </div>
        </section>
    </>
}
