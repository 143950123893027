import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ManageBanners from './ManageBanners'
import ManageSlides from './ManageSlides'
import OfferPopup from './OfferPopup'
import WebsiteSetting from './WebsiteSetting'
import PageList from './pages/PageList'
import AddNewPage from './pages/AddNewPage'
import PageContentMain from './pagecontent/PageContentMain'
import PageContentEdit from './pagecontent/PageContentEdit'
import EditPage from './pages/EditPage'
import CSVReader from './othesLinks/CSVReader'
import GetImageName from './othesLinks/GetImageName'
import ManageUser from './ManageUser/ManageUser'
export default function SettingRoutes() {
  return (
    <Routes>
        <Route path='web-setting' element={<WebsiteSetting/>}/>
        <Route path='slides' element={<ManageSlides/>}/>
        <Route path='banners' element={<ManageBanners/>}/>
        <Route path='offer-popup' element={<OfferPopup/>}/>
        {/* <Route path='change-password' element={<WebsiteSetting/>}/> */}
        <Route path='page-list' element={<PageList/>}/>
        <Route path='page-list/edit' element={<EditPage/>}/>
        <Route path='add-page' element={<AddNewPage/>}/>

        <Route path='page-content' element={<PageContentMain/>}/>
        <Route path='page-content/edit' element={<PageContentEdit/>}/>

        <Route path='read-csv' element={<CSVReader/>}/>
        <Route path='get-image-name' element={<GetImageName/>}/>
        <Route path='manage-user' element={<ManageUser/>}/>
    </Routes>
  )
}
