import React, { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { MdEditSquare, MdLockReset } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { UpdateBackUserData } from "../../../../api/BackendUserApi";
import {
  fetchAllUser,
  selectActiveUserList,
  selectUserInfo,
} from "../../../../ReduxFiles/Slices/UserSlice";
import AlertMessage from "../AlertMessage";
import manageUserCss from "../css/manageUser.module.css";
import EditManageUser from "../EditManageUser";
import ResetPassword from "../ResetPassword";
import UserInfoView from "../UserInfoView";
export default function ActiveUsers() {
  const dispatch = useDispatch();
  const userData = useSelector(selectActiveUserList);
  const currentUser = useSelector(selectUserInfo);
  const [alertMessage, setAlertMessage] = useState();
  const [profileData, setProfileData] = useState();
  const [editManage, setEditManage] = useState(false);
  const [editData, setEditData] = useState([]);
  const [resetPasswordPop, setResetPasswordPop] = useState(false);
  function onInfoClick(item) {
    setProfileData(item);
    document.body.style.overflow = "hidden";
  }
  function onEditClick(item) {
    setEditManage(true);
    setEditData(item);
  }

  async function onDeleteClick(item) {
    userData.forEach(async (element) => {
      if (element.id === item.id) {
        if (item.id === currentUser.id) {
          toast.warning("You Cannot Delete Your Own ID");
        } else {
          if (item.userrole < currentUser.rule) {
            toast.warning("You Cannot Delete This user");
          } else {
            setAlertMessage(item);
            document.body.style.overflow = "hidden";
          }
        }
      }
    });
  }
  function onResetClick(item) {
    setResetPasswordPop(item);
  }
  async function onYesClick() {
    await UpdateBackUserData(alertMessage.id, { active: 0 });
    dispatch(fetchAllUser());
    setAlertMessage();
    document.body.style.overflow = "auto";
  }

  function onNoClick() {
    setAlertMessage();
    document.body.style.overflow = "auto";
  }
  return (
    <div className={manageUserCss.wrapper}>
      {alertMessage ? (
        <AlertMessage
          message="Do You Want to Delete This User?"
          yesClick={onYesClick}
          noClick={onNoClick}
          //   setAlertMessage={setAlertMessage}
        />
      ) : (
        ""
      )}
      {editManage ? (
        <EditManageUser editData={editData} setEditManage={setEditManage} />
      ) : (
        ""
      )}
      {profileData ? (
        <UserInfoView userInfo={profileData} setUserInfo={setProfileData} />
      ) : (
        ""
      )}
      {resetPasswordPop ? (
        <ResetPassword
          resetPasswordPop={resetPasswordPop}
          setResetPasswordPop={setResetPasswordPop}
        />
      ) : (
        ""
      )}
      <div className={manageUserCss.holder}>
        <div className={manageUserCss.table_wrapper}>
          {<h2>Active User</h2>}
          <table>
            <thead>
              <tr>
                <th>SL</th>
                <th>Name</th>
                <th>User Name</th>
                <th>Rule</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {userData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.fullName}</td>
                    <td>{item.userName}</td>
                    <td>{item.rule}</td>
                    <td>
                      <div className={manageUserCss.action_Wrapper}>
                        <button
                          onClick={() => {
                            onInfoClick(item);
                          }}
                        >
                          <FaInfoCircle />
                        </button>
                        <button onClick={() => onEditClick(item)}>
                          <MdEditSquare />
                        </button>
                        <button
                          onClick={() => {
                            onDeleteClick(item);
                          }}
                        >
                          <RiDeleteBin6Line />
                        </button>
                        <button
                          onClick={() => {
                            onResetClick(item);
                          }}
                        >
                          <MdLockReset />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
