import React, { useState } from "react";
import csvreaderCss from "./Css/csvReader.module.css";
import Papa from "papaparse";
const CSVReader = () => {
  const [csvFile, setCsvFile] = useState();
  const [csvData, setCsvData] = useState([]);
  const [headerList, setHeaderList] = useState([]);
  function handleFileUpload(e) {
    const file = e.target.files[0];
    setCsvFile(file);
  }

  function onReadClick() {
    // if (csvFile && csvFile.type === "text/csv") {
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     const mcsvData = reader.result;
    //     console.log(mcsvData);

    //     const lines = mcsvData.split("\n");
    //     const data = [];
    //     //   console.log(lines);
    //     lines.forEach((line) => {
    //       const dataArray = line
    //         .split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
    //         .map((item) => item.replace(/^"(.*)"$/, "$1"));

    //       // const [status, time, from, to, sms, smsid, camid] = dataArray;
    //       // console.log(dataArray);

    //       const [
    //         time,
    //         sender,
    //         receiver,
    //         status,
    //         user_id,
    //         uname,
    //         medium,
    //         channel,
    //         counter,
    //         content,
    //       ] = dataArray;

    //       let tempData = {
    //         time,
    //         sender,
    //         receiver,
    //         status,
    //         user_id,
    //         uname,
    //         medium,
    //         channel,
    //         counter,
    //         content,
    //       };

    //       data.push(tempData);
    //     });

    //     data.pop();
    //     data.shift();
    //     setCsvData(data);
    //     //  console.table(data);
    //   };

    //   reader.readAsText(csvFile);
    // } else {
    //   alert("please upload a csv file");
    //   setCsvData([]);
    // }
    Papa.parse(csvFile, {
      header: true, // If your CSV has a header row
      complete: (results) => {
        setCsvData(results.data);
        setHeaderList(results.meta.fields);
      },
      error: (error) => {
        console.error("Error parsing CSV", error);
      },
    });
  }

  return (
    <div className={csvreaderCss.salesMain}>
      <div className={csvreaderCss.innerSale}>
        <div className={csvreaderCss.input_wrapper}>
          <label>Image Upload *</label>
          <input type="file" onChange={handleFileUpload} />
          <button onClick={onReadClick}>Read File</button>
        </div>

        <div className={csvreaderCss.tableSection}>
          <table>
            <thead>
              <tr>
                <th>SL</th>
                {headerList.map((item, index) => {
                  return <th key={index}>{item}</th>;
                })}
                {/* <th>Status</th>
                <th>Event Time</th>
                <th>From No</th>
                <th>To No</th>
                <th>Sms</th>
                <th>SMSID</th>
                <th>Campaign ID</th> */}
              </tr>
            </thead>
            <tbody>
              {csvData?.map((item, index) => {
                return (
                  <tr key={item.smsid + index}>
                    <td>{index + 1}</td>
                    {headerList.map((hitem, mindex) => {
                      return <td key={index + "td" + mindex}>{item[hitem]}</td>;
                    })}

                    {/* <td>{item.Status}</td>
                    <td>{item.Date}</td>
                    <td>{item.Sender}</td>
                    <td>{item.Receiver}</td>
                    <td>{item.sms}</td>
                    <td>{item.Content}</td>
                    <td>{item.SMSID}</td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CSVReader;

/*
const data = 'Success,24/06/23 01:55,8801810180099,8801726904044,"Dear Sir/Madam, We have received your order. Your Order Number is : 1467. We will let you know when the order is confirmed. Thank You Anayase 01810169101",a8fcd7ef,1094113';

// Split the data by comma (,) and remove any surrounding quotes (") if present
const dataArray = data.split(',').map((item) => item.replace(/^"(.*)"$/, '$1'));

// Extract the individual values from the dataArray
const [status, date, sender, receiver, message, code, id] = dataArray;

// Create a JSON object using object assignment
const jsonObject = {
  status,
  date,
  sender,
  receiver,
  message,
  code,
  id,
};

console.log(jsonObject);


*/
