import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { fetchBrands } from '../../../ReduxFiles/Slices/BrandListSlice'
import { GetBrandImageLink, UpdateBrandList } from '../../../api/BrandListApi'
import addbrandCss from './addbrand.module.css'

const UpdateBrand = ({popUp, setPopUp }) => {
  const dispatch = useDispatch()
  function closeOverlay() {
    setPopUp()
    document.body.style.overflow = 'auto'
  }
  const [fakeUrl, setFakeUrl] = useState([])

  function onImageChange(e, name) {
    const imageFiles = e.target.files
    setFakeUrl({ ...fakeUrl, [name]: genarateFakeUrl(imageFiles[0]) })
  }
  function genarateFakeUrl(image) {
    let myurl = image ? URL.createObjectURL(image) : null
    return myurl;
  }
  async function onUpdateData(e) {
    e.preventDefault()
    const BrandName = e.target.BrandName.value
    const BrandMT = e.target.BrandMT.value
    const BrandMD = e.target.BrandMD.value
    const Brandimg = e.target.Brandimg.files
    const BrandBanner = e.target.BrandBanner.files
    let data = {
      BrandName: BrandName,
      BrandMT: BrandMT,
      BrandMD: BrandMD,
    }
    try {
      await UpdateBrandList(popUp.id, data, Brandimg[0], BrandBanner[0])
      dispatch(fetchBrands())
      setPopUp()
      document.body.style.overflow = 'auto'
      toast.success('Brand Updated Successfully')
    } catch (error) {
      toast.error(error.message)
    }
  }
  return (
    <div className={addbrandCss.wrapper}>
      <div className={addbrandCss.holder}>
        <h2>Edit Your Brand</h2>
        <a onClick={closeOverlay} className={addbrandCss.close} href="#">&times;</a>
        <form className={addbrandCss.content} onSubmit={onUpdateData}>
          <div className={addbrandCss.inner}>
            <label>Brand Name</label>
            <input name='BrandName' type='text' placeholder='brnad name here' required  defaultValue={popUp.BrandName}/>
          </div>
          <div className={addbrandCss.inner}>
            <label>Brand Meta Title</label>
            <input name='BrandMT' type='text' placeholder='brand meta title here' required defaultValue={popUp.BrandMT}/>
          </div>
          <div className={addbrandCss.inner}>
            <label>Brand Meta Description</label>
            <input name='BrandMD' type='text' placeholder='brand meta description here' required defaultValue={popUp.BrandMD}/>
          </div>
          <div className={addbrandCss.inner}>
            <label>Brand Image</label>
            <input name='Brandimg' type='file' onChange={(e) => onImageChange(e, 'Brandimg')} multiple={false} accept='image/webp'/>
          </div>
          <div className={addbrandCss.image_holder}>
            {fakeUrl.Brandimg && <img src={fakeUrl.Brandimg} alt='placeholder' />}
            <ImageComp name={popUp.Brandimg}/>
          </div>
          <div className={addbrandCss.inner}>
            <label>Brand Banner</label>
            <input name='BrandBanner' type='file' onChange={(e) => onImageChange(e, 'BrandBanner')} multiple={false} accept='image/webp'/>
          </div>
          <div className={addbrandCss.image_holder}>
            {fakeUrl.BrandBanner && <img src={fakeUrl.BrandBanner} alt='placeholder' />}
            <ImageComp name={popUp.BrandBanner}/>
          </div>
          <div className={addbrandCss.btn_group}>
            <button>Update Brand</button>
          </div>

        </form>
      </div>
    </div>
  )
}

export default UpdateBrand

const ImageComp = ({ name }) => {
  const [image, setImage] = useState()
  async function getLink() {
    if (name) {
      let imageInfo = await GetBrandImageLink({ image_name: name })
      if (imageInfo.imageExist) {
        setImage(imageInfo.url)
      } else {
        setImage('')
      }
    } else {
      setImage('')
    }
  }
  useEffect(() => {
    getLink()
  }, [])
  return (
    <>
      {image ? <img style={{ height: '50px' }} src={image} alt={name} /> : ''}
    </>
  )
}