import productListCss from "./css/productList.module.css";

import React, { useEffect, useState } from "react";
import { FaRegTimesCircle } from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { selectBrandList } from "../../ReduxFiles/Slices/BrandListSlice";
import { selectCategoryList } from "../../ReduxFiles/Slices/CategorySlice";
import {
  fetchProducts,
  selectProductList,
  selectProductStatus,
} from "../../ReduxFiles/Slices/ProductSlice";
import { UpdateProductList } from "../../api/ProductListApi";
import ToggleSwitch from "../smallThings/ToggleSwitch";
import SeoPop from "./productlistmodels/SeoPop";

import AgePop from "./productlistmodels/AgePop";
import AvaillableStock from "./productlistmodels/AvaillableStock";
import CommonAlertFile from "./productlistmodels/CommonAlertFile";

export default function ProductList() {
  const dispatch = useDispatch();
  const allProducts = useSelector(selectProductList);
  const productStatus = useSelector(selectProductStatus);
  const categoryList = useSelector(selectCategoryList);
  const brandList = useSelector(selectBrandList);
  const [tableData, setTableData] = useState([]);
  const [stockAmmountState, setStockAmmountState] = useState();
  function StockEntry(e, item) {
    setStockAmmountState(item);
  }

  async function getAllData() {
    let tempArr = [];
    allProducts?.forEach((element) => {
      if (element.is_active) {
        tempArr.push(element);
      }
    });
    // console.log(data)
    let sortArr = tempArr.sort((a, b) => (a.item_code > b.item_code ? 1 : -1));
    setTableData(sortArr);
    getExistedCat(allProducts);
  }
  function getExistedCat(arrayData) {
    let catArr = [];
    let myArr = [];
    arrayData.forEach((element) => {
      if (catArr.includes(element.selected_catagory_id)) {
      } else {
        catArr.push(element.selected_catagory_id);
        for (let index = 0; index < categoryList?.length; index++) {
          const melement = categoryList[index];
          if (element.selected_catagory_id === melement.id) {
            myArr.push(melement);
          }
        }
      }
    });
    setExistedCatagory(myArr);
  }

  useEffect(() => {
    if (productStatus === "success") {
      getAllData();
    }
  }, [productStatus]);
  useEffect(() => {
    if (productStatus === "idle") {
      dispatch(fetchProducts());
    }
  }, [productStatus]);

  const [seoData, setSeoData] = useState();
  async function onSeoClick(item) {
    setSeoData(item);
    document.body.style.overflow = "hidden";
  }

  const [ageItem, setAgeItem] = useState();

  function onAddAgeClick(item) {
    setAgeItem(item);
  }

  async function onDeliveryClick(item) {
    let mfree_delivery = 0;
    if (!item.free_delivery) {
      mfree_delivery = 1;
    }
    await UpdateProductList(item.id, { free_delivery: mfree_delivery });
    let tempArr = [];
    tableData?.forEach((element) => {
      if (element.id === item.id) {
        element = {
          ...element,
          free_delivery: mfree_delivery,
        };
      }
      tempArr.push(element);
    });
    setTableData(tempArr);
  }

  async function onActiveClick(item) {
    let mis_active = 0;
    if (!item.is_active) {
      mis_active = 1;
    }
    await UpdateProductList(item.id, { is_active: mis_active });
    let tempArr = [];
    tableData?.forEach((element) => {
      if (element.id === item.id) {
        element = {
          ...element,
          is_active: mis_active,
        };
      }
      tempArr.push(element);
    });
    setTableData(tempArr);
  }

  const [searchData, setSearchData] = useState();

  const [existedCatagory, setExistedCatagory] = useState();

  function onBrandChange(item) {
    if (item) {
      let value = item.id;
      let newArr = [];
      if (value) {
        allProducts?.forEach((item) => {
          if (item.selected_brand_id === parseInt(value)) {
            newArr.push(item);
          }
        });
      } else {
        newArr = allProducts;
      }
      setSearchData({ ...searchData, brand: value, catagory: "" });
      setTableData(newArr);
      getExistedCat(newArr);
    } else {
      setTableData(allProducts);
      getExistedCat(allProducts);
    }
  }
  function onCatagoryChange(item) {
    if (item) {
      let value = item.id;
      let newArr = [];
      if (value) {
        allProducts?.forEach((item) => {
          if (item.selected_catagory_id === parseInt(value)) {
            newArr.push(item);
          }
        });
      } else {
        newArr = allProducts;
      }
      setSearchData({ ...searchData, catagory: value });
      setTableData(newArr);
    } else {
      setTableData(allProducts);
    }
  }
  function onItemClick(item) {
    if (item) {
      let myArr = [];
      myArr.push(item);
      setTableData(myArr);
    } else {
      setTableData(allProducts);
    }
  }
  function onCheckChange(e) {
    const isChecked = e.target.checked;
    let mcheck = isChecked ? 1 : 0;
    let newArr = [];
    allProducts?.forEach((item) => {
      if (item.is_active == mcheck) {
        newArr.push(item);
      }
    });
    setTableData(newArr);
  }

  const [alertState, setAlertState] = useState();
  function onYesNoClick(item, identity) {
    setAlertState({ item, identity });
    document.body.style.overflow = "hidden";
  }
  return (
    <div className={productListCss.list_item}>
      {seoData && <SeoPop popData={seoData} setPopData={setSeoData} />}
      {ageItem ? <AgePop popUp={ageItem} setPopUp={setAgeItem} /> : ""}
      {alertState && (
        <CommonAlertFile popUp={alertState} setPopUp={setAlertState} />
      )}

      {/* {stockAmmountState ? <MyAvailableStock stockAmmountState={stockAmmountState} setStockAmmountState={setStockAmmountState} tableData={tableData} /> : ''} */}
      {stockAmmountState && (
        <AvaillableStock
          popUp={stockAmmountState}
          setPopUp={setStockAmmountState}
        />
      )}

      <div className={productListCss.itemreport}>
        <div className={productListCss.common}>
          <h2>
            <strong>{allProducts?.length}</strong> Items
          </h2>
        </div>
        <div className={productListCss.common}>
          <h2>
            <strong>{categoryList?.length}</strong> Catagory
          </h2>
        </div>
        <div className={productListCss.common}>
          <h2>
            <strong>{brandList?.length}</strong> Brands
          </h2>
        </div>
        <div className={productListCss.common}>
          <h2>
            <Link to={"/products/add-new-product"}>Add New Product</Link>
          </h2>
        </div>
      </div>

      <div className={productListCss.itemsearch}>
        <div className={productListCss.headign}>
          <h2>Filter Item List</h2>
        </div>

        <div className={productListCss.filter_sec}>
          <Select
            className={productListCss.selectOption}
            options={brandList}
            getOptionLabel={(option) => `${option.BrandName}`}
            getOptionValue={(option) => `${option.id}`}
            placeholder={"select brand"}
            isClearable
            onChange={onBrandChange}
          />

          <Select
            className={productListCss.selectOption}
            options={existedCatagory}
            getOptionLabel={(option) => `${option.catName}`}
            getOptionValue={(option) => `${option.id}`}
            placeholder={"select category"}
            isClearable
            onChange={onCatagoryChange}
          />

          <Select
            className={productListCss.selectOption}
            options={allProducts}
            getOptionLabel={(option) =>
              `${option.en_sort_title} - (${option.Selling_price}/-)`
            }
            getOptionValue={(option) => `${option.id}`}
            placeholder={"select product"}
            isClearable
            onChange={onItemClick}
          />
        </div>
      </div>
      <div className={productListCss.table_wrapper}>
        <table>
          <thead>
            <tr>
              <th>Sl</th>
              <th>Item Name</th>
              <th>
                <span>
                  Active{" "}
                  <input
                    type="checkbox"
                    defaultChecked={true}
                    onChange={onCheckChange}
                  />
                </span>
              </th>
              <th>Category</th>
              <th>Brand</th>
              <th>Featured</th>
              <th>Stock Out</th>
              <th>Avi Stock</th>
              <th>Free Delivery</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {item.en_sort_title}-({item.Selling_price}/-) <br />
                    {item.hasAge ? (
                      <button
                        onClick={() => {
                          onAddAgeClick(item);
                          document.body.style.overflow = "hidden";
                        }}
                        className={productListCss.age_value_button}
                        style={{ backgroundColor: "red" }}
                      >
                        add age value
                      </button>
                    ) : (
                      ""
                    )}
                  </td>
                  <td className={productListCss.active}>
                    <ToggleSwitch
                      toggleOnOff={item.is_active}
                      toggleClick={() => {
                        onActiveClick(item);
                      }}
                    />
                  </td>
                  <td>{item.catName}</td>
                  <td>{item.BrandName}</td>
                  <td>
                    <div
                      className={productListCss.yes_no_div}
                      data={item.featured ? "featured" : "notfeatured"}
                    >
                      <FaRegTimesCircle
                        onClick={() => {
                          onYesNoClick(item, "notfeatured");
                        }}
                      />
                      Featured
                      <FaRegCircleCheck
                        onClick={() => {
                          onYesNoClick(item, "featured");
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      className={productListCss.yes_no_div}
                      data={item.stockout ? "stockout" : "instock"}
                    >
                      <FaRegTimesCircle
                        onClick={() => {
                          onYesNoClick(item, "stockout");
                        }}
                      />
                      Applicable
                      <FaRegCircleCheck
                        onClick={() => {
                          onYesNoClick(item, "instock");
                        }}
                      />
                    </div>
                  </td>

                  <td>
                    {
                      <p
                        onClick={(e) => {
                          StockEntry(e, item);
                          document.body.style.overflow = "hidden";
                        }}
                        className={productListCss.stock_entry}
                        data={item.hasVariance ? "vr" : "notvr"}
                      >
                        {item?.virtualStock ? item.virtualStock : 0}
                      </p>
                    }
                  </td>

                  <td className={productListCss.free_deliv}>
                    <ToggleSwitch
                      toggleOnOff={item.free_delivery}
                      toggleClick={() => {
                        onDeliveryClick(item);
                      }}
                    />
                  </td>
                  <td>
                    <div className={productListCss.action_buttons}>
                      <button>
                        <Link to={`${item.id}`}>Edit</Link>
                      </button>
                      <button
                        onClick={() => {
                          onSeoClick(item);
                        }}
                      >
                        SEO
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}

            {/* {CatItem} */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
