import React, { useEffect, useState } from "react";
import Headerimage from "../../assets/Headerimage.jpg";
import QRcode from "../../assets/anayase_qrcode.png";
import { GenarateText } from "../connector/InWord";
import InvoiceReCss from "./salesCss/InvoiceReturn.module.css";

const InvoiceReturn = () => {
  const [invoiceState, setInvoiceState] = useState();
  function PrintPaper() {
    window.print();
  }
  function convertDate(time) {
    const dateInMillis = time;
    const date = new Date(dateInMillis);
    let myDate = date.toLocaleDateString();
    myDate = myDate.replaceAll("/", "-");
    return myDate;
  }
  function convertDateTime(time) {
    const dateInMillis = time;
    const date = new Date(dateInMillis);
    let myTime = date.toLocaleTimeString();
    return myTime;
  }
  useEffect(() => {
    let data = localStorage.getItem("invoiceData");
    console.log(JSON.parse(data));
    setInvoiceState(JSON.parse(data));
  }, []);

  function getQTotal(item) {
    let total = 0;
    item?.forEach((element) => {
      total += element.quantity ? element.quantity : 0;
    });

    return total;
  }
  function totalAmmount(item) {
    let quantity = getQTotal(item);
    let total = 0;
    total += item[0].price;
    return total * quantity;
  }
  function getGrandTotal(data) {
    // console.log(data)
    let total = 0;
    let myData = [];
    data?.forEach((element) => {
      element?.forEach((melement) => {
        myData.push(melement);
      });
    });

    myData.forEach((element) => {
      let quantity = element.quantity ? element.quantity : 0;
      total += element.price * quantity;
    });
    return total;
  }
  const tableItem = invoiceState?.tableData.map((item, index) => {
    return (
      <InvoiceTable
        sl={index + 1}
        description={item[0].mainName ? item[0].mainName : item[0].name}
        varName={item.map((mvrName) => {
          return (
            <p>
              {mvrName.vrName
                ? mvrName.vrName +
                  " - " +
                  (mvrName.mQuantity ? mvrName.mQuantity : mvrName.rQuantity)
                : ""}
            </p>
          );
        })}
        quantity={getQTotal(item)}
        uniteprice={item[0].price}
        total={totalAmmount(item)}
      />
    );
  });

  return (
    <>
      <div className={InvoiceReCss.invoicemain}>
        <button className={InvoiceReCss.printBtn} onClick={PrintPaper}>
          Print
        </button>

        <section className={InvoiceReCss.invoiceSection}>
          <div className={InvoiceReCss.container} id="printInvoice">
            <div className={InvoiceReCss.topSection}>
              <img src={Headerimage} alt="" />
            </div>

            <div className={InvoiceReCss.main}>
              <h3>Return INVOICE / BILL</h3>
              <div className={InvoiceReCss.details}>
                <div className={InvoiceReCss.client}>
                  <div className={InvoiceReCss.info}>
                    <span className={InvoiceReCss.moreinfo}>
                      Invoice Number :{" "}
                    </span>
                    {invoiceState?.id}
                  </div>
                  <div className={InvoiceReCss.info}>
                    <span className={InvoiceReCss.moreinfo}>
                      Customar Name :{" "}
                    </span>
                    {invoiceState?.name}
                  </div>
                  <div className={InvoiceReCss.info}>
                    <span className={InvoiceReCss.moreinfo}>Phone No : </span>
                    {invoiceState?.phone || invoiceState?.Phone}
                  </div>
                  <div className={InvoiceReCss.info}>
                    <span className={InvoiceReCss.moreinfo}>Address : </span>{" "}
                    {invoiceState?.Address +
                      ", " +
                      invoiceState?.Area +
                      ", " +
                      invoiceState?.District +
                      ", " +
                      invoiceState?.Divition +
                      ","}
                  </div>
                </div>
                <div className={InvoiceReCss.invoice}>
                  <div className={InvoiceReCss.info}>
                    <span className={InvoiceReCss.moreinfo}>Date : </span>
                    {convertDate(invoiceState?.order_created)}
                  </div>
                  <div className={InvoiceReCss.info}>
                    <span className={InvoiceReCss.moreinfo}>Time : </span>{" "}
                    {convertDateTime(invoiceState?.order_created)}
                  </div>
                  <div className={InvoiceReCss.info}>
                    <span className={InvoiceReCss.moreinfo}>Sold By : </span>
                    Anayase.com
                  </div>
                </div>
              </div>
              <div className={InvoiceReCss.table}>
                <table>
                  <thead>
                    <tr>
                      <th className={InvoiceReCss.no}>SI No.</th>
                      <th style={{ paddingLeft: "10px" }}>Item Name</th>
                      <th className={InvoiceReCss.unit}>Quantity</th>
                      <th className={InvoiceReCss.unit_price}>Unit Price</th>
                      <th className={InvoiceReCss.total}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* here table item goes */}
                    {tableItem}
                  </tbody>
                  <tbody className={InvoiceReCss.tfoot}>
                    <tr>
                      <td colSpan="3"></td>
                      <td>Sub Total</td>
                      <td>{getGrandTotal(invoiceState?.tableData)} TK</td>
                    </tr>
                    <tr>
                      <td colSpan="3"></td>
                      <td>Discount(-)</td>
                      <td>
                        {invoiceState?.discountPercent
                          ? Math.round(
                              (getGrandTotal(invoiceState?.tableData) *
                                invoiceState.discountPercent) /
                                100
                            )
                          : 0}{" "}
                        Tk
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="5">
                        <span></span>
                      </td>
                    </tr>
                    <tr className={InvoiceReCss.gran_total}>
                      <td colSpan="3"></td>
                      <td className={InvoiceReCss.gran_total_text}>
                        Grand Total
                      </td>
                      <td>
                        {getGrandTotal(invoiceState?.tableData) -
                          Math.round(
                            invoiceState?.discountPercent
                              ? (getGrandTotal(invoiceState?.tableData) *
                                  invoiceState.discountPercent) /
                                  100
                              : 0
                          )}{" "}
                        Tk
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className={InvoiceReCss.notice1}
                style={{ marginTop: "-70px" }}
              >
                <div className={InvoiceReCss.notice1_1}>
                  <p>
                    <span className={InvoiceReCss.inword}>In Word : </span>
                    {GenarateText(
                      getGrandTotal(invoiceState?.tableData) -
                        (invoiceState?.discountPercent
                          ? Math.round(
                              (getGrandTotal(invoiceState?.tableData) *
                                invoiceState.discountPercent) /
                                100
                            )
                          : 0)
                    )}
                  </p>
                </div>
              </div>
              <div className={InvoiceReCss.notices}>
                <div>
                  <b className={InvoiceReCss.notetext}>NOTE : </b>
                </div>
                <div className={InvoiceReCss.notice}>
                  {invoiceState?.special_instruction}
                </div>
              </div>
              <img src={QRcode} alt="" />
              <div className={InvoiceReCss.seal_signiture}>
                <p>Customer Signature</p>
                <p>Authority Signature</p>
              </div>
              <div
                className={`${InvoiceReCss.seal_signiture} ${InvoiceReCss.seal_signiture1}`}
              >
                <span className={InvoiceReCss.thankyou_msg}>
                  Thank you for being with Anayase.com.
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default InvoiceReturn;

const InvoiceTable = ({
  sl,
  description,
  varName,
  quantity,
  uniteprice,
  total,
}) => {
  return (
    <>
      <tr>
        <td className={InvoiceReCss.no}>{sl}</td>
        <td className={InvoiceReCss.desc}>
          <strong>{description}</strong>{" "}
          <span style={{ color: "#424242" }}>{varName}</span>
        </td>
        <td className={InvoiceReCss.unit}>{quantity}</td>
        <td className={InvoiceReCss.unit_price}>{uniteprice}</td>
        <td className={InvoiceReCss.total}>{total}</td>
      </tr>
    </>
  );
};
