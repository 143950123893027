import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { GetEndDate, GetStartDate, LocalTimeStampToDateOnly, RearrangeDate } from '../../MyFunctions/MyFunctions'
import { HeaderFooterWrapper } from '../HeadetFooter'
import { SalesReportDataDynamic } from '../../../api/ReportApi/SalesReportApi'
import SalesReportViewDetailsNewCss from './css/SalesReportViewDetailsNew.module.css'

const SalesReportViewDetailsNew = () => {
    const [invoiceState, setInvoiceState] = useState()
    let [searchParams] = useSearchParams();
    const [customerData, setCustomerData] = useState()
    const [addressData, setAddressData] = useState()
    const [itemState, setItemState] = useState()
    const [allProducts, setAllProcducts] = useState()
    const [varianceAll, setVarianceAll] = useState()

    async function getData() {
        let start_date =  GetStartDate(searchParams.get('start_date'))
        let end_date =GetEndDate(searchParams.get('end_date'))    
        let type =searchParams.get('type')
        let myQuery = ''
        switch (type) {
            case 'packaging':
                myQuery = 'packaging = 1'
                break;
            case 'confirm':
                myQuery = 'confirmed = 1 AND packaging = 0'
                break;
            case 'pending':
                myQuery = 'pending = 1'
                break;
            case 'declined':
                myQuery = 'declined = 1'
                break;
            default:
                break;
        }   
        sendRequestForData(start_date, end_date, myQuery)
      }
     async function sendRequestForData(start_date, end_date, myQuery) {
        let data =  await SalesReportDataDynamic({starttime:start_date, endtime:end_date, query:myQuery})
        let tempArr = []
        data?.forEach(item => {
          let discount =(item.discountPercent*item.total_orprice/100)+(item.total_orprice-item.total_price)
          let tba = item.total_orprice - ((item.discountPercent*item.total_orprice/100)+(item.total_orprice-item.total_price))+item.deliveryCharge
          let netSale = tba-item.codSSLCharge-item.deliveryCost-item.return_charge
          let due = tba - item.receiveAmount
            item = {
              ...item, discount, tba, netSale,due
            }
            tempArr.push(item)
          });
        setInvoiceState(tempArr)
       // setAllProcessedData(tempArr)
        //SetAllTotal(tempArr)
      }

useEffect(()=>{
    getData()
},[])

    function checkClick(params) {
       
    }

    function getAlltotal(array, identity) {
        let allTotal = 0
        array?.forEach(element => {
            allTotal += element[identity] ? element[identity]:0
        });
        
       return allTotal
    }

  return (
    <>
           <HeaderFooterWrapper content={
            <> <h3>{searchParams.get('type')} Order Report</h3>
            <div className={SalesReportViewDetailsNewCss.details}>
                <div className={SalesReportViewDetailsNewCss.client}>
                    <div className={SalesReportViewDetailsNewCss.info}>
                        <span className={SalesReportViewDetailsNewCss.moreinfo} onClick={checkClick}>Area : </span> All Area 
                    </div>
                </div>
                <div className={SalesReportViewDetailsNewCss.invoice}>
                    <div className={SalesReportViewDetailsNewCss.info}>
                    {<span className={SalesReportViewDetailsNewCss.moreinfo}>Date : {RearrangeDate(searchParams.get('start_date'))}  To {RearrangeDate(searchParams.get('end_date'))}</span>}
                    </div>
                </div>
            </div>
            <div className={`${SalesReportViewDetailsNewCss.table} ${SalesReportViewDetailsNewCss.sales_table} ${SalesReportViewDetailsNewCss.new_css_sales} ${SalesReportViewDetailsNewCss.fixed_table}`}>
                <table>
                    <thead>
                        <tr className={SalesReportViewDetailsNewCss.fixed_table_header}>
                            <th >Sl</th>
                            <th >Invoice No.</th>
                            <th>Order Date</th>
                            <th>Delivery Date</th>
                            <th >Customer Name</th>
                            <th >Phone</th>
                            <th >Address</th>
                            <th >Status</th>
                            <th style={{width:'150px'}}>Items</th>
                            <th >Total Amount</th>
                            <th >D. Charge</th>  
                        </tr>
                    </thead>
                    <tbody>
                    {invoiceState?.map((item, index)=>{
                        return <>
                            <tr key={index}>
                            <td>{index+1}</td>
                            <td style={{ textAlign: 'center'}}>#{item.id}</td>
                            <td style={{ textAlign: 'left'}}>{LocalTimeStampToDateOnly(item.created_date)}</td>
                            <td style={{ textAlign: 'left'}}>{RearrangeDate(item.delivery_date)}</td>
                            <td style={{ textAlign: 'left'}}>{item.username}</td>
                            <td style={{ textAlign: 'left'}}>{item.phone}</td>
                            <td style={{ textAlign: 'left'}}>{item.address}</td>
                            <td style={{ textAlign: 'left'}}>{item.status}</td>
                            <td style={{ textAlign: 'left'}}>{item.product_names}</td>
                            <td>{item.grandTotal}</td>
                            <td>{item.deliveryCharge}</td>
                            
                            </tr>
                        </>
                    })}
                    
                    <tr className={SalesReportViewDetailsNewCss.final_row}>
                        <td className={SalesReportViewDetailsNewCss.no_border_right} colSpan={'9'}>Total :</td>
                        <td >{getAlltotal(invoiceState, 'grandTotal')}</td>
                        <td >{getAlltotal(invoiceState, 'deliveryCharge')}</td>             
                    </tr>
                 
                    </tbody>
                </table>
            </div></>
           }         
           />

        </>
  )
}

export default SalesReportViewDetailsNew