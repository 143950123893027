import React, { useState } from 'react'
import DropDownSearchCss from './css/DropDownSearch.module.css'

const DropDownSearch = ({ mainArray, searchField, showDataField, onMyClick, mplaceholder}) => {
    const [searchText, setSerchText] = useState('')
    function onDataChange(e) {
        setSerchText(e.target.value)
    }
    function onItemClick(e, item) {
        setSerchText(item[searchField])
        if (onMyClick) {
            onMyClick(item)
        }
    }
    return (
        <div className={DropDownSearchCss.comp_search}>
            {/* <label>Search By Product Name:</label> */}
            <input type='search' onChange={onDataChange} value={searchText ? searchText : ''} placeholder={mplaceholder ? mplaceholder:'Search Your Products Name Here'}  autoComplete='off'/>
            <div className={`${DropDownSearchCss.search_item_section} ${DropDownSearchCss.dropdown_list}`}>
                {mainArray?.filter((fitem) => {
                    let msearchText = searchText?.toLowerCase()
                    let matchText = fitem[searchField]?.toLowerCase()

                    if (msearchText && matchText !== msearchText && matchText?.includes(msearchText)) {
                        return fitem
                    } else {
                        return null
                    }
                    // return conditionMatch &&  matchText.includes(msearchText) || matchTextnumber.includes(msearchText)
                }).slice(0, 10)
                    .map((item, index) => {
                        return <p key={index} onClick={(e)=>{onItemClick(e, item)}} >{item[searchField]} <span >{item[showDataField] ? ' - '+item[showDataField]:''}</span></p>
                    })}
            </div>
        </div>
    )
}

export default DropDownSearch