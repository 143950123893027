import MyAxios from 'axios'
import { API_LINK } from './APILink'
const MY_API_LINK = `${API_LINK}brand-list/`
const configdata = {
    headers: {
        'Content-Type': 'application/json'
    }
}

const configfile = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

export async function SaveBrandList(data, Brandimg, BrandBanner) {
    const BrandimgData = new FormData();
    const BrandimgrData = new FormData();
    BrandimgData.append("myfile", Brandimg);
    BrandimgrData.append("myfile", BrandBanner);
    try {
        let resultBrandimg = await MyAxios.post(`${MY_API_LINK}upload`, BrandimgData, configfile)
        let resultBrandBanner = await MyAxios.post(`${MY_API_LINK}upload`, BrandimgrData, configfile)
        let result = await MyAxios.post(`${MY_API_LINK}add`, { ...data, Brandimg: resultBrandimg.data, BrandBanner: resultBrandBanner.data}, configdata)
        return result.data
    } catch (error) {
        return 0
    }
}

export async function UpdateBrandList(id, data, Brandimg, BrandBanner) {
  
    try {
        let resultBrandimg = {}
        let resultBrandBanner = {}
        if (Brandimg) {
            const BrandimgData = new FormData();
            BrandimgData.append("myfile", Brandimg);
            resultBrandimg = await MyAxios.post(`${MY_API_LINK}upload`, BrandimgData, configfile)
            await MyAxios.post(`${MY_API_LINK}/delete-image`, {image_name:data.Brandimg}, configdata)
        }
        
        if (BrandBanner) {
            const BrandimgrData = new FormData();
            BrandimgrData.append("myfile", BrandBanner);
            resultBrandBanner = await MyAxios.post(`${MY_API_LINK}upload`, BrandimgrData, configfile)
            await MyAxios.post(`${MY_API_LINK}/delete-image`, {image_name:data.BrandBanner}, configdata)
        }
    
        let result = await MyAxios.put(`${MY_API_LINK}${id}`, { ...data, Brandimg: resultBrandimg?.data || data.Brandimg, BrandBanner: resultBrandBanner?.data || data.BrandBanner}, configdata)

        return result.data
    } catch (error) {
        return 0
    }
}
export async function DeleteBrand(id, data,) {

    try {
        let result = await MyAxios.delete(`${MY_API_LINK}${id}`)
        await MyAxios.post(`${MY_API_LINK}/delete-image`, {image_name:data.Brandimg}, configdata)
        await MyAxios.post(`${MY_API_LINK}/delete-image`, {image_name:data.BrandBanner}, configdata)
        return result.data
    } catch (error) {
        return 0
    }
}


export async function GetAllBrands() {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}all`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetBrandImageLink(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}get-image`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function UpdateStatus(id, data) {
    try {
        let result = await MyAxios.put(`${MY_API_LINK}${id}`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}