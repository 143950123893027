import React from "react";
import { Link } from "react-router-dom";
import purchaseCss from "./css/PurchaseMain.module.css";

const PurchaseMain = () => {
  const purchaseMenu = [
    { title: "Pending Purchase", link: "/purchase/pending", icon: "" },
    { title: "Purchase History", link: "/purchase/history", icon: "" },
    { title: "New Purchase", link: "/purchase/new-purchase", icon: "" },
    { title: "Decline Purchase", link: "/purchase/decline", icon: "" },
    { title: "Purchase Return", link: "/purchase/purchase-return", icon: "" },
    {
      title: "Purchase Return History",
      link: "/purchase/purchase-return-history",
      icon: "",
    },
    { title: "Stock Details", link: "/purchase/stock-details", icon: "" },
    { title: "Stock Request", link: "/purchase/stock-request", icon: "" },
  ];

  return (
    <div className={purchaseCss.wrapper}>
      <div className={purchaseCss.menuitems}>
        {purchaseMenu.map((item, index) => {
          return (
            <div className={purchaseCss.item} key={index}>
              <Link to={item.link}>
                <h2>{item.title}</h2>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PurchaseMain;
