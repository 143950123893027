import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { SaveData, DeleteData, UpdateData } from '../../../connector/DatabaseCheck'
import ComplainTypeCss from './css/ComplainType.module.css'

const ComplainType = () => {
  const [allData, setAllData] = useState()
  const [typeData, setTypeData] = useState({
    complainTypenotes: '',
    complainType: '',
  })
  const [editData, setEditData] = useState()
  const [deleteData, setDeleteData] = useState()

  function onDataChange(e) {
    let name = e.target.name
    let value = e.target.value
    setTypeData({ ...typeData, [name]: value })
  }
  async function saveToDataBase() {
    let finalData = {
      ...typeData,
      identity: 'type'
    }
    if (!finalData.complainType) {
      alert('please Enter complain Type Name')

    } else if (!finalData.complainTypenotes) {
      alert('please Enter complain Type Notes')
    } else {
      await SaveData('ComplainSetting', finalData)
    }
    setTypeData({
      complainTypenotes: '',
      complainType: '',
    })
    allData.push(finalData)
  }
  async function onUpdateClick() {
    let finalData = {
      complainType: typeData.complainType,
      complainTypenotes: typeData.complainTypenotes
    }
    if (!finalData.complainType) {
      alert('please Enter complain Type Name')

    } else if (!finalData.complainTypenotes) {
      alert('please Enter complain Type Notes')
    } else {
      if (editData.id) {
        await UpdateData('ComplainSetting', finalData, editData.id)
        let myArr = []
        allData.forEach(element => {
          if (element.id === editData.id) {
            element = { ...element, ...finalData }
            myArr.push(element)
          } else {
            myArr.push(element)
          }
        });
        setAllData(myArr)
        setEditData();
        setTypeData({
          complainTypenotes: '',
          complainType: '',
        })

      } else {
        alert('please Refresh this page to continue')
      }
    }

  }

  async function getDatafromDataBase() {
    let data = [] // await GetWhereAndOrderData('ComplainSetting', 'identity', 'type', 'createdDate', 'asc')
    setAllData(data)
  }

  useEffect(() => {
    getDatafromDataBase()
  }, [])


  const tableIconStyle = {
    padding: '0',
    margin: '0 5px 0 5px',
    fontSize: '18px',
    cursor: 'pointer'

  }
  const TableItem = allData?.map((item, index) => {
    return (
      <MyTable key={index} sl={index + 1} name={item.complainType} notes={item.complainTypenotes}
        manage={<>
          <FontAwesomeIcon style={{ ...tableIconStyle, color: 'green' }} icon={faEdit} onClick={(e) => { setEditData(item); setTypeData({ ...item }) }} />
          <FontAwesomeIcon style={{ ...tableIconStyle, color: 'red' }} icon={faTrashCan} onClick={() => { setDeleteData(item) }} />
        </>}
      />

    )
  })
  return (    

      <div className={ComplainTypeCss.common_class}>
      {deleteData ? <ConfirmAlert item={deleteData} setDeleteData={setDeleteData} allData={allData} /> : ''}
        <div className={ComplainTypeCss.input_section}>
          <div className={ComplainTypeCss.input_wrapper}>
            <label>Complain Type Name *</label>
            <input name='complainType' type={'text'} placeholder='Complain Priority Name' onChange={onDataChange} value={typeData?.complainType} />
          </div>
          <div className={ComplainTypeCss.input_wrapper}>
            <label>Complain Type Notes *</label>
            <textarea name='complainTypenotes' rows="4" cols="20" placeholder='Complain Category Notes' onChange={onDataChange} value={typeData?.complainTypenotes}></textarea>
          </div>
          <div className={ComplainTypeCss.buttonWrapper}>
          {!editData ? <button onClick={saveToDataBase}>Save Priority</button> :
            <>
              <button onClick={() => {
                setEditData(); setTypeData({
                  complainTypenotes: '',
                  complainType: '',
                })
              }}
                 >Cancel</button>

              <button onClick={onUpdateClick}>Update Priority</button>
            </>}
          </div>
        
        </div>
        <div className={ComplainTypeCss.tableSection} >
          <table>
            <tbody>
              <tr>
                <th>SL</th>
                <th>Type Name</th>
                <th>Type Notes</th>
                <th>Manage</th>
              </tr>
              {TableItem}
            </tbody>
          </table>
        </div>
      </div>
  )
}

export default ComplainType
const MyTable = ({ sl, name, notes, manage }) => {

  return <>
    <tr>
      <td>{sl}</td>
      <td>{name}</td>
      <td>{notes}</td>
      <td>{manage}</td>
    </tr>
  </>
}

const ConfirmAlert = ({ item, setDeleteData, allData }) => {
  function yesClick() {
    if (item.id) {

      DeleteData('ComplainSetting', item.id)
      allData.forEach((element, index) => {
        if (element.id === item.id) {
          allData.splice(index, 1)
        }
      });
      setDeleteData()
    } else {
      alert('please refresh the page and try again')
    }
  }
  function noClick() {
    setDeleteData()
  }

  return (
    <>
      <div className={ComplainTypeCss.conAlert}>
        <h2 className={ComplainTypeCss.caption}>Do you Want To Delete This?</h2>
        <div className={ComplainTypeCss.buttonsec}>
          <input onClick={yesClick} className={ComplainTypeCss.yes} type={"submit"} value={"Yes"} />
          <input onClick={noClick} className={ComplainTypeCss.no} type={"submit"} value={"No"} />
        </div>
      </div>
    </>
  )
}