import React from "react";
import styles from "./updatedate.module.css";
import { GetSingleOrderItems, UpdateOrderData } from "../../../api/SalesApi";
import { toast } from "react-toastify";
import { UpdateSalesItemsData } from "../../../api/OrderInformationApi";
export default function UpdateDate({ popUp, setPopUp }) {
  async function onUpdateDate(e) {
    e.preventDefault();
    const mdate = e.target.created_date.value;
    const created_date = new Date(mdate).getTime();
    try {
      const data = await GetSingleOrderItems({ id: popUp.id });
      console.log(data);
      data.map(async (item) => {
        await UpdateSalesItemsData(item.id, { created_date });
      });
      await UpdateOrderData(popUp.id, { created_date });
      setPopUp();
      document.body.style.overflow = "auto";
    } catch (error) {
      toast.error(error.message);
    }
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.holder}>
        <h2>Update Invoice Date</h2>

        <form onSubmit={onUpdateDate}>
          <label>Date</label>
          <input type="date" required name="created_date" />
          <button>Update</button>
          <button
            type="button"
            onClick={() => {
              setPopUp();
              document.body.style.overflow = "auto";
            }}
          >
            Close
          </button>
        </form>
      </div>
    </div>
  );
}
