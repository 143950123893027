import React from 'react'
import { Link } from 'react-router-dom'


import reportCss from './ReportMain.module.css'

const ReportMain = () => {

    const salesMenu = [
        {title:'Sales Report', link:'sales-report', icon: '' },
        {title:'Sales Report Product Wise', link:'sales-report-productwise', icon: '' },
        {title:'Sales Return Report', link:'sales-return-report', icon: '' },
        {title:'Customer ladger', link:'customer-ladger', icon: '' },    
    ]

    const purchaseMenu = [
        {title:'Purchase Report', link:'purchase-report', icon: '' },
        {title:'Supplier Ledger', link:'supplier-ladger', icon: '' },
        {title:'Stock Summery (Purchase & Sales)', link:'stock-summery-ps', icon: '' },
        {title:'Stock Capital Report', link:'stock-capital', icon: '' },    
        {title:'Stock Maintain Report', link:'stock-maintain', icon: '' },    
    ]
    const expenseMenu = [
        {title:'Expence Report', link:'expense-report', icon: '' },
        {title:'Head Wise Expence Report', link:'head-wise-ex', icon: '' },
    ]
    const bankMenu = [
        {title:'Sale Collection Statement', link:'sales-collection', icon: '' },
        {title:'Cash in Hand Received Statement', link:'cash-in-hand', icon: '' },
        {title:'Online Payment Received (SSL)', link:'online-payment', icon: '' },
        {title:'Bank Payment & Received', link:'bank-payment', icon: '' },
    ]
    const accountsMenu = [
        {title:'At-A-Glance Report', link:'sales-collection', icon: '' },
        {title:'Account At-A-Glance Report', link:'cash-in-hand', icon: '' },
        {title:'Trial Balance Report', link:'online-payment', icon: '' },
        {title:'Trading Account Report', link:'bank-payment', icon: '' },
        {title:'Balance Sheet Report', link:'bank-payment', icon: '' },
        {title:'Profit & Loss Summery', link:'bank-payment', icon: '' },
        {title:'Profit & Loss Account Report', link:'bank-payment', icon: '' },
    ]


    return (
        <div className={reportCss.wrapper}>
            <div className={reportCss.report_devider}>
                <h1>Sales Reports</h1>
                <div className={reportCss.menuitems}>
                    {salesMenu.map((item, index) => {
                        return <Link to={item.link} className={reportCss.item} key={index}>
                            <h2>{item.title}</h2>
                        </Link>
                    })}
                </div>
            </div>
            <div className={reportCss.report_devider}>
                <h1>Purchase Reports</h1>
                <div className={reportCss.menuitems}>
                    {purchaseMenu.map((item, index) => {
                        return <Link to={item.link} className={reportCss.item} key={index}>
                            <h2>{item.title}</h2>
                        </Link>
                    })}
                </div>
            </div>
            <div className={reportCss.report_devider}>
                <h1>Expence Reports</h1>
                <div className={reportCss.menuitems}>
                    {expenseMenu.map((item, index) => {
                        return <Link to={item.link} className={reportCss.item} key={index}>
                            <h2>{item.title}</h2>
                        </Link>
                    })}
                </div>
            </div>
            <div className={reportCss.report_devider}>
                <h1>Bank Balance</h1>
                <div className={reportCss.menuitems}>
                    {bankMenu.map((item, index) => {
                        return <Link to={item.link} className={reportCss.item} key={index}>
                            <h2>{item.title}</h2>
                        </Link>
                    })}
                </div>
            </div>
            <div className={reportCss.report_devider}>
                <h1>Accounts Report</h1>
                <div className={reportCss.menuitems}>
                    {accountsMenu.map((item, index) => {
                        return <Link to={item.link} className={reportCss.item} key={index}>
                            <h2>{item.title}</h2>
                        </Link>
                    })}
                </div>
            </div>
        </div>
    )
}

export default ReportMain