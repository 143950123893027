import UnverifiedSaleCss from "./salesCss/unverifiedSales.module.css";
import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ServerTimeToFormateTime } from "../../api/GlobalFunctions";
import {
  GetUnverifiedSalesData,
  UpdateUnverifiedSales,
} from "../../api/SalesApi";
import CustomerInformationModel from "./models/CustomerInformationModel";
import GobackButton from "../global/GobackButton";
import ConfirmAlert from "../global/ConfirmAlert";
import { toast } from "react-toastify";

async function getDataFormDatabase(setTableData) {
  let data = await GetUnverifiedSalesData("", 0);
  setTableData(data);
}

const UnverifiedSales = () => {
  const [tableData, setTableData] = useState([]);
  const [showUser, setShowUser] = useState(false);
  const [conShow, setConShow] = useState(false);

  async function customerClick(item) {
    setShowUser(item);
    document.body.style.overflow = "hidden";
  }

  useEffect(() => {
    !conShow && getDataFormDatabase(setTableData);
  }, [conShow]);
  function onDeleteClick(item) {
    setConShow(item);
    document.body.style.overflow = "hidden";
  }
  async function clickYes() {
    try {
      await UpdateUnverifiedSales(conShow.order_id);
      toast.success("Order Declined Successfully!");
      setConShow();
      document.body.style.overflow = "auto";
    } catch (error) {
      toast.error(error.message);
    }
  }

  function clickNo() {
    setConShow(false);
    document.body.style.overflow = "auto";
  }

  return (
    <section className={UnverifiedSaleCss.wrapper}>
      {showUser && (
        <CustomerInformationModel popUp={showUser} setPopUp={setShowUser} />
      )}
      {conShow && (
        <ConfirmAlert
          message="Do you want to Decline this order?"
          yesClick={clickYes}
          noClick={clickNo}
        />
      )}
      <div className={UnverifiedSaleCss.holder}>
        <div className={UnverifiedSaleCss.head}>
          <GobackButton />
          <h2 className={UnverifiedSaleCss.saleHeading}>Unverified Saleas</h2>
        </div>
        <div className={UnverifiedSaleCss.table_wrapper}>
          <table>
            <thead>
              <tr>
                <th>id</th>
                <th>Date & Time</th>
                <th>Customer Name</th>
                <th>Customer Phone</th>
                <th>Customer Address</th>
                <th>Total Amount</th>
                <th>SSL Paid</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link to={`/sales/unverified_details/${item.order_id}`}>
                        {"#" + item.order_id}
                      </Link>
                    </td>
                    <td>{ServerTimeToFormateTime(item.created_date)}</td>
                    <td onClick={() => customerClick(item)}>{item.name}</td>
                    <td>{item.phone}</td>
                    <td>{item.full_address}</td>
                    <td>{item.grandTotal + item.deliveryCharge}</td>
                    <td>{item.paymentCompleate ? "Yes" : "No"}</td>
                    <td>
                      <div className={UnverifiedSaleCss.action_buttons}>
                        <Link to={`/sales/unverified_details/${item.order_id}`}>
                          <FaEye />
                        </Link>
                        <button onClick={() => onDeleteClick(item)}>
                          <RiDeleteBin6Line />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default UnverifiedSales;
