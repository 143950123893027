import MyAxios from "axios";
import { API_LINK } from "./APILink";
const MY_API_LINK = `${API_LINK}product-list/`;
const Gallery_API_LINK = `${API_LINK}product-gallery-image/`;

const configdata = {
  headers: {
    "Content-Type": "application/json",
  },
};

const configfile = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export async function SaveProducts(data, imagedata) {
  const formData = new FormData();
  formData.append("myfile", imagedata);
  try {
    let resultImage = await MyAxios.post(
      `${MY_API_LINK}upload`,
      formData,
      configfile
    );
    let itemImageName = resultImage.data;
    let result = await MyAxios.post(
      `${MY_API_LINK}add`,
      { ...data, itemImageName },
      configdata
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function UpdateProductImage(id, imagedata, preimage) {
  const formData = new FormData();
  formData.append("myfile", imagedata);
  try {
    let resultImage = await MyAxios.post(
      `${MY_API_LINK}upload-product-image`,
      formData,
      configfile
    );
    let itemImageName = resultImage.data;
    let result = await MyAxios.put(
      `${MY_API_LINK}/${id}`,
      { itemImageName: itemImageName },
      configdata
    );
    await MyAxios.post(
      `${MY_API_LINK}/delete-image`,
      { image_name: preimage },
      configdata
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function SaveGalleryData(imagedata, id) {
  const formData = new FormData();
  for (var i = 0; i < imagedata.length; i++) {
    formData.append("myfile", imagedata[i]);
  }
  try {
    let resultImage = await MyAxios.post(
      `${Gallery_API_LINK}upload`,
      formData,
      configfile
    );
    let image_name = resultImage.data;
    image_name.forEach(async (element) => {
      await MyAxios.post(
        `${Gallery_API_LINK}add`,
        { product_id: id, image_name: element.filename },
        configdata
      );
    });
    return image_name;
  } catch (error) {
    return 0;
  }
}

export async function GetAllProducts() {
  try {
    let result = await MyAxios.get(`${MY_API_LINK}all`);
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function GetFilterProducts(query) {
  try {
    let result = await MyAxios.get(`${MY_API_LINK}filter/${query}`);
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function GetLastItem(id) {
  try {
    let result = await MyAxios.get(`${MY_API_LINK}last-item/${id}`);
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function UpdateProductList(id, data, imagedata) {
  try {
    let itemImageName = "";
    if (imagedata) {
      const formData = new FormData();
      formData.append("myfile", imagedata);
      let resultImage = await MyAxios.post(
        `${Gallery_API_LINK}upload`,
        formData,
        configfile
      );
      await MyAxios.post(
        `${MY_API_LINK}/delete-image`,
        { image_name: data.itemImageName },
        configdata
      );
      itemImageName = resultImage.data[0].filename;
    }
    let result = await MyAxios.put(
      `${MY_API_LINK}${id}`,
      {
        ...data,
        itemImageName: itemImageName ? itemImageName : data.itemImageName,
      },
      configdata
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function DeleteProduct(id) {
  try {
    let result = await MyAxios.delete(`${MY_API_LINK}${id}`);
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function DeleteProductImage(data) {
  try {
    let result = await MyAxios.post(
      `${MY_API_LINK}/delete-image`,
      data,
      configdata
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}

const GalleryApiLink = `${API_LINK}product-gallery-image/`;

export async function GetAllGalleryImage(query) {
  try {
    let result = await MyAxios.get(`${GalleryApiLink}filter/${query}`);
    return result.data;
  } catch (error) {
    return 0;
  }
}

export async function DeleteGalleryItem(id) {
  try {
    let result = await MyAxios.delete(`${GalleryApiLink}${id}`);
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function DeleteGalleryImage(data) {
  try {
    let result = await MyAxios.post(
      `${GalleryApiLink}delete-image/`,
      data,
      configdata
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}

//get product image to show
export async function GetProductImageConverted(data) {
  try {
    let result = await MyAxios.post(
      `${MY_API_LINK}get-converted-images`,
      data,
      configdata
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function GetProductVarianceConverted(data) {
  try {
    let result = await MyAxios.post(
      `${MY_API_LINK}get-converted-variance`,
      data,
      configdata
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function GetProductImageLink(data) {
  try {
    let result = await MyAxios.post(
      `${MY_API_LINK}getimagelink`,
      data,
      configdata
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function SaveProductSeoData(data) {
  try {
    let result = await MyAxios.post(`${MY_API_LINK}add-seo`, data, configdata);
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function UpdateProductSeoData(id, data) {
  try {
    let result = await MyAxios.post(
      `${MY_API_LINK}seo-update/${id}`,
      data,
      configdata
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}

export async function GetSingleSeoData(id) {
  try {
    let result = await MyAxios.post(
      `${MY_API_LINK}get-seo-single`,
      { id: id },
      configdata
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}
