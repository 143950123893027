import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ServerTimeToFormateTime } from "../../api/GlobalFunctions";
import { GetUnverifiedSalesData } from "../../api/SalesApi";
import GobackButton from "../global/GobackButton";
import CustomerInformationModel from "./models/CustomerInformationModel";
import UnverifiedSaleCss from "./salesCss/unverifiedSales.module.css";

async function getDataFormDatabase(setTableData) {
  let data = await GetUnverifiedSalesData("", 1);
  setTableData(data);
}

const UnverifiedSalesDeclined = () => {
  const [tableData, setTableData] = useState([]);
  const [showUser, setShowUser] = useState(false);

  async function customerClick(item) {
    setShowUser(item);
    document.body.style.overflow = "hidden";
  }

  useEffect(() => {
    getDataFormDatabase(setTableData);
  }, []);

  return (
    <section className={UnverifiedSaleCss.wrapper}>
      {showUser && (
        <CustomerInformationModel popUp={showUser} setPopUp={setShowUser} />
      )}

      <div className={UnverifiedSaleCss.holder}>
        <div className={UnverifiedSaleCss.head}>
          <GobackButton />
          <h2 className={UnverifiedSaleCss.saleHeading}>Unverified Saleas</h2>
        </div>
        <div className={UnverifiedSaleCss.table_wrapper}>
          <table>
            <thead>
              <tr>
                <th>id</th>
                <th>Date & Time</th>
                <th>Customer Name</th>
                <th>Customer Phone</th>
                <th>Customer Address</th>
                <th>Total Amount</th>
                <th>SSL Paid</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link to={`/sales/unverified_details/${item.order_id}`}>
                        {"#" + item.order_id}
                      </Link>
                    </td>
                    <td>{ServerTimeToFormateTime(item.created_date)}</td>
                    <td onClick={() => customerClick(item)}>{item.name}</td>
                    <td>{item.phone}</td>
                    <td>{item.full_address}</td>
                    <td>{item.grandTotal + item.deliveryCharge}</td>
                    <td>{item.paymentCompleate ? "Yes" : "No"}</td>
                    <td>
                      <div className={UnverifiedSaleCss.action_buttons}>
                        <Link to={`/sales/unverified_details/${item.order_id}`}>
                          <FaEye />
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default UnverifiedSalesDeclined;
