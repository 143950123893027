import React from 'react'
import AtributeButtonCss from './css/AtributeButton.module.css'
import { IoIosCloseCircleOutline } from "react-icons/io";
function AtributeButton(props) {
    const { clickevent, value, identity} = props
    return (
        <>
            <div className={AtributeButtonCss.atributeValue} uid={identity}>
                <div className={AtributeButtonCss.area}>
                    <div>
                    {value} 
                    </div>
               
                
                < IoIosCloseCircleOutline onClick={clickevent}/>
                </div>
             
            
            </div>
        </>
    )
}

export default AtributeButton