import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from "react-router-dom";
import { GetAllDataExpress } from '../../../api/ExpressFunctions';
import AllLedger from '../PdfFiles/AllLedger';
import IndividualLedger from '../PdfFiles/IndividualLedger';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllSupplier, selectSupplierList, selectSupplierStatus } from '../../../ReduxFiles/Slices/SupplierSlice';
import SupplierLedgerCss from './css/SupplierLedger.module.css'
const SupplierLedger = () => {

  return (
    <>
    <Routes>
      <Route path='/' element={<MainComp/>}/>
      <Route path='/all-pdf' element={<AllLedger/>}/>
      <Route path='/:reportShow/individual-pdf' element={<IndividualLedger/>}/>
    </Routes>
      
    </>
  )
}

export default SupplierLedger


const MainComp =()=>{
  const supplierStatus = useSelector(selectSupplierStatus)
  const supplierList = useSelector(selectSupplierList)
  const dispatch = useDispatch()
  const myNavigate = useNavigate()
  const [SearchData, setSearchData] = useState()

  useEffect(()=>{
    if (supplierStatus === 'idle') {
      dispatch(fetchAllSupplier())
    }
  },[])
  return <section className={SupplierLedgerCss.heading}>

    <h2 className={SupplierLedgerCss.headign_h2}>Supplier Ledger</h2>
    <div className={SupplierLedgerCss.inner_headign}>
    <select onChange={(e)=>{setSearchData(e.target.value)}}>
          <option>Select Supplier</option>
          {supplierList?.map((item,index)=>{
            return <option value={item.id}>{item.companyaName}</option>
          })}
        </select>
      <div className={SupplierLedgerCss.common}>
        <button onClick={()=>{
          if (SearchData) {
            window.open('/admin/report/supplier_ledger/'+SearchData+'/individual-pdf', '_blank')
          // myNavigate()
        }else{
          alert('Supplier Required')
        }
        
        }}>Search</button>
      </div>
      <div className={SupplierLedgerCss.common}>
        <button onClick={()=>{myNavigate('/admin/report/supplier_ledger/all-pdf')}}>All Supplier Ledger</button>
      </div>

    </div>
 
</section>
}