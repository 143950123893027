import React, { useEffect, useState } from "react";
import { GetStockRequestData, UpdateStockRequest } from "../../../api/StockApi";
import stockRequestCss from "./css/StockRequest.module.css";

const StockRequest = () => {
  const [tableData, setTableData] = useState();
  const [spinnerShow, setSpinnerShow] = useState(false);

  async function onConfirmClick(e, id, index) {
    await UpdateStockRequest(id, { status: "confirmed" });
    let newArr = [];
    tableData.forEach((element) => {
      if (element.request_id === id) {
        element = { ...element, status: "confirmed" };
        newArr.push(element);
      } else {
        newArr.push(element);
      }
    });
    setTableData(newArr);
    // console.log('ok')
  }

  async function getRequsetData(params) {
    setSpinnerShow(true);
    let reqData = await GetStockRequestData();
    //console.log(reqData);
    setTableData(reqData);
    setSpinnerShow(false);
  }
  useEffect(() => {
    getRequsetData();
  }, []);

  function convertDate(time) {
    const dateInMillis = time;
    const date = new Date(dateInMillis);
    let myDate = date.toLocaleDateString();
    let myTime = date.toLocaleTimeString();
    myDate = myDate.replaceAll("/", "-");
    return myDate + " " + myTime;
  }
  const CommonStyle = {
    borderRadius: "5px",
    textAlign: "center",
    padding: "5px",
  };
  const statusSwitch = (value) => {
    let data = <></>;
    switch (value) {
      case "confirmed":
        data = (
          <p
            style={{
              ...CommonStyle,
              color: "green",
              border: "1px solid green",
            }}
          >
            {value}
          </p>
        );
        break;
      default:
        data = (
          <p style={{ ...CommonStyle, color: "red", border: "1px solid red" }}>
            Pending
          </p>
        );
        break;
    }

    return data;
  };

  const TableItem = tableData?.map((item, index) => {
    return (
      <MyTable
        key={index}
        sl={index + 1}
        product={item.name}
        catagory={item.catName}
        customer={item.name}
        phone={item.phone}
        status={statusSwitch(item.status)}
        reqTime={convertDate(item.created_date)}
        action={
          <button
            className={stockRequestCss.confirm_btn}
            onClick={(e) => {
              onConfirmClick(e, item.request_id, index);
            }}
          >
            Confirm
          </button>
        }
      />
    );
  });

  return (
    <>
      {spinnerShow ? <Spinner text={"Loading..."} /> : ""}
      <section
        style={{ marginTop: "10px" }}
        className={`${stockRequestCss.salesMain} ${stockRequestCss.manageuserMain}`}
      >
        <div className={stockRequestCss.wrapper}>
          <div className={stockRequestCss.holder}>
            <h2>Request Stock List</h2>
            <div className={stockRequestCss.table_wrapper}>
              <table>
                <tbody>
                  <tr>
                    <th>SL</th>
                    <th>Product</th>
                    <th>Catagory</th>
                    <th>Customer</th>
                    <th>Customer Phone</th>
                    <th>Status</th>
                    <th>Req Time</th>
                    <th>Action</th>
                  </tr>
                  {TableItem}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <button onClick={incrementClick}>increment</button>
                <button onClick={decrementClick}>decrement</button> */}
      </section>
    </>
  );
};

export default StockRequest;

const MyTable = ({
  sl,
  product,
  catagory,
  customer,
  phone,
  status,
  reqTime,
  action,
}) => {
  return (
    <>
      <tr>
        <td>{sl}</td>
        <td>{product}</td>
        <td>{catagory}</td>
        <td>{customer}</td>
        <td>{phone}</td>
        <td>{status}</td>
        <td>{reqTime}</td>
        <td>{action}</td>
      </tr>
    </>
  );
};

const Spinner = ({ text }) => {
  const myIndex = {
    zIndex: "15",
  };
  return (
    <>
      <div style={myIndex} className={stockRequestCss.SpinnerWrrapper}>
        <div id="loader"></div>
        <p>{text}</p>
      </div>
    </>
  );
};
