import React from 'react'
import offerPopupEditCss from "./css/offerPopurEdit.module.css"
const OfferPopupEdit = ({popUp, setPopData}) => {
    async function onUpdateOffer(e){
        e.preventDefault()
        const banner_name=e.target.banner_name.value;
        console.log(banner_name)
        setPopData()
    }
  return (
    <div className={offerPopupEditCss.editPopupSection}>
        <div className={offerPopupEditCss.editPoup}>
        <h3>Offer Popup Edit</h3>
        <form className={offerPopupEditCss.input_wrapper} onSubmit={onUpdateOffer}>
            <div className={offerPopupEditCss.input_holder}>
                <label>Banner Name</label>
                <input type="text" required name="banner_name" defaultValue={popUp.banner_name}/>
            </div>
            <div className={offerPopupEditCss.btn_group}>
                <button type='submit'>Update</button>
                <button onClick={()=>{setPopData(); document.body.style.overflow = 'auto'}} type='button'>Close</button>
            </div>
        </form>
        </div>
    </div>
  )
}

export default OfferPopupEdit