import React, { useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { selectBrandList } from "../../../ReduxFiles/Slices/BrandListSlice";
import { selectActiveUserList } from "../../../ReduxFiles/Slices/UserSlice";
import {
  GetIndividualInvoiceWiseReport,
  GetIndividualInvoiceWiseReportProductWise,
} from "../../../api/SalesApi";
import BrandTotalModel from "../../Setting/ManageUser/MyProfile/reports/models/BrandTotalModel";
import ProductDetailsView from "../../Setting/ManageUser/MyProfile/reports/models/ProductDetailsView";
import GobackButton from "../../global/GobackButton";
import style from "./css/report.module.css";
import { ExportExcelFile } from "../../../api/GlobalFunctions";
export default function ProductWiseReport() {
  const userData = useSelector(selectActiveUserList);
  const [tableData, setTableData] = useState([]);
  const [tableTotal, setTableTotal] = useState({
    quantity_total: 0,
    dis_total: 0,
    grand_total: 0,
  });
  async function onGenarateClick(e) {
    e.preventDefault();
    const start = e.target.start.value;
    const end = e.target.end.value;
    const user_id = e.target.user_id.value;
    const start_date = new Date(start).getTime();
    const end_date = new Date(end).getTime();
    setTableData([]);
    const query =
      user_id === "50000"
        ? `start_date=${start_date}&end_date=${end_date}`
        : `start_date=${start_date}&end_date=${end_date}&user_id=${user_id}`;
    const data = await GetIndividualInvoiceWiseReport(query);
    const ids = data.map((item) => item.id).join();
    if (ids) {
      const productData = await GetIndividualInvoiceWiseReportProductWise(
        `sales_ids=${ids}`
      );
      organizeData(productData);
    } else {
      toast.info("No Data Found");
    }
  }

  function organizeData(dataArr) {
    const groupdata = dataArr.reduce((data, curr) => {
      if (data[curr.product_id]) {
        data = {
          ...data,
          [curr.product_id]: [...data[curr.product_id], curr],
        };
      } else {
        data = {
          ...data,
          [curr.product_id]: [curr],
        };
      }

      return data;
    }, {});
    const tableData = Object.keys(groupdata).map((productId) => {
      const products = groupdata[productId];
      const total = products.reduce(
        (item, curr) => {
          item.quantity += parseInt(curr.rest_qty);
          item.totalamount += curr.price * curr.rest_qty;
          item.mpr_total += curr.mrp_price * curr.rest_qty;
          item.tp_total += curr.tp_price * curr.rest_qty;
          item.dis_amount += Math.round(curr.dis_amount);
          return item;
        },
        {
          quantity: 0,
          totalamount: 0,
          mpr_total: 0,
          tp_total: 0,
          dis_amount: 0,
        }
      );
      return {
        product_id: productId,
        name: products[0].en_sort_title,
        tp_price: products[0].tp_price,
        mrp_price: products[0].mrp_price,
        brand: products[0].brand,
        products: products,
        ...total,
      };
    });
    setTableData(tableData);
    const allTotal = tableData.reduce(
      (item, curritem) => {
        let diff = curritem.mpr_total - curritem.totalamount;
        item.quantity_total += curritem.quantity;
        item.dis_total += curritem.dis_amount + diff;
        item.grand_total += curritem.totalamount - curritem.dis_amount;
        return item;
      },
      {
        quantity_total: 0,
        dis_total: 0,
        grand_total: 0,
      }
    );
    setTableTotal(allTotal);
  }
  const [brandModelData, setBrandModelData] = useState();
  const [detailsData, setDetailsData] = useState();
  function onDetailsView(item) {
    setDetailsData(item);
    document.body.style.overflow = "hidden";
  }
  const brandList = useSelector(selectBrandList);
  function onBrandwiseClick() {
    if (tableData.length > 0) {
      const brandData = brandList.map((item) => {
        const brandItems = tableData.filter((fitem) => item.id === fitem.brand);
        const brandTotal = brandItems.reduce((total, curritem) => {
          total += curritem.totalamount - (curritem.dis_amount || 0);
          return total;
        }, 0);
        return {
          ...item,
          brandTotal: brandTotal || 0,
        };
      });
      setBrandModelData(brandData);
      document.body.style.overflow = "hidden";
    } else {
      toast.info("No Data Found");
    }
  }

  const formRef = useRef();
  const userRef = useRef();

  function onExcelDownload() {
    if (tableData.length > 0) {
      const form = formRef.current;
      const start = form.start.value;
      const end = form.end.value;
      const userData = userRef.current.getValue();
      const finalData = tableData.map((item, index) => {
        return {
          SL: index + 1,
          "Product Name": item.name,
          "TP Price": item.tp_price,
          "MRP Price": item.mrp_price,
          Quantity: item.quantity,
          "TP Total": item.totalamount,
          "MRP Total": item.mpr_total,
        };
      });
      ExportExcelFile(
        finalData,
        `${userData[0]?.fullName}-(${start} to ${end}) product wise`
      );
    } else {
      toast.warn("No Data Found to export");
    }
  }

  return (
    <div className={style.wrapper}>
      {brandModelData && (
        <BrandTotalModel popUp={brandModelData} setPopUp={setBrandModelData} />
      )}
      {detailsData && (
        <ProductDetailsView popUp={detailsData} setPopUp={setDetailsData} />
      )}
      <div className={style.header_part}>
        <GobackButton />
        <h3>Product Wise Report</h3>
      </div>
      <div className={style.holder}>
        <div className={style.form_wrapper}>
          <form onSubmit={onGenarateClick} ref={formRef}>
            <div className={style.input_wrapper}>
              <label>User Select</label>
              <Select
                options={[{ fullName: "All User", id: 50000 }, ...userData]}
                getOptionValue={(option) => `${option.id}`}
                getOptionLabel={(option) => `${option.fullName}`}
                name="user_id"
                required
                ref={userRef}
              />
            </div>
            <div className={style.input_wrapper}>
              <label>Start Date</label>
              <input type="date" name="start" required />
            </div>
            <div className={style.input_wrapper}>
              <label>End Date</label>
              <input type="date" name="end" required />
            </div>
            <div className={style.button_wrapper}>
              <button>Genarate</button>
              <button type="button" onClick={onBrandwiseClick}>
                Brand Wise Report
              </button>
              <button type="button" onClick={onExcelDownload}>
                XLSX
              </button>
            </div>
          </form>
        </div>
        <div className={style.table_wrapper}>
          <table aria-label="product">
            <thead>
              <tr>
                <th>SL</th>
                <th>Product Name</th>
                <th>TP</th>
                <th>MRP</th>
                <th>Quantity</th>
                <th>Dicount</th>
                <th>Total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.tp_price}</td>
                    <td>{item.mrp_price}</td>
                    <td>{item.quantity}</td>
                    <td>
                      {item.mpr_total - item.totalamount + item.dis_amount}
                    </td>
                    <td>{item.totalamount - item.dis_amount}</td>
                    <td>
                      <button onClick={() => onDetailsView(item)}>
                        <FaEye />
                      </button>
                    </td>
                  </tr>
                );
              })}
              <tr className={style.grand_total_wrapper}>
                <td colSpan={4}>Grand Total : </td>
                <td>{tableTotal.quantity_total}</td>
                <td>{tableTotal.dis_total}</td>
                <td>{tableTotal.grand_total}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
