import React from "react";
import { IoClose } from "react-icons/io5";
import { API_LINK } from "../../../../api/APILink";
import editProfileCss from "./css/editProfile.module.css";
import { UpdateBackUserData } from "../../../../api/BackendUserApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setIsLogedIn,
  setUserInfo,
} from "../../../../ReduxFiles/Slices/UserSlice";
export default function EditProfile({ setEditManage, editData }) {
  function onClose() {
    setEditManage(false);
  }
  const myNavigate = useNavigate();
  const myDispatch = useDispatch();

  async function onUpdateProfile(e) {
    e.preventDefault();
    const fullName = e.target.fullName.value;
    const email = e.target.email.value;
    const phone = e.target.phone.value;
    const userbio = e.target.userbio.value;
    try {
      await UpdateBackUserData(editData.id, {
        fullName,
        email,
        phone,
        userbio,
      });
      toast.success("Inforamtion Updated!");
      window.localStorage.removeItem("userData");
      myNavigate("/");
      myDispatch(setIsLogedIn(false));
      myDispatch(setUserInfo({}));
    } catch (error) {
      toast.error(error.message);
    }
    console.log({ fullName, email, phone, userbio });
  }

  return (
    <div className={editProfileCss.wrapper_popup}>
      <div className={editProfileCss.holder}>
        <h3>Update Your Profile</h3>
        <button onClick={onClose} className={editProfileCss.close_button}>
          <IoClose />
        </button>
        <form onSubmit={onUpdateProfile}>
          <div className={editProfileCss.input_wrapper}>
            <label>User Name*</label>
            <input type="text" defaultValue={editData.userName} disabled />
          </div>
          <div className={editProfileCss.input_wrapper}>
            <label>Full Name*</label>
            <input
              type="text"
              defaultValue={editData.fullName}
              name="fullName"
              required
            />
          </div>
          <div className={editProfileCss.input_wrapper}>
            <label>Email*</label>
            <input
              type="text"
              defaultValue={editData.email}
              name="email"
              required
            />
          </div>
          <div className={editProfileCss.input_wrapper}>
            <label>Phone*</label>
            <input
              type="text"
              defaultValue={editData.phone}
              name="phone"
              required
            />
          </div>
          <div className={editProfileCss.input_wrapper}>
            <label>Bio*</label>
            <input
              type="text"
              defaultValue={editData.userbio}
              name="userbio"
              required
            />
          </div>

          <div className={editProfileCss.button_wrapper}>
            <button>Update</button>
          </div>
        </form>
      </div>
    </div>
  );
}
