import React, { useState } from "react";
import style from "./css/courierRatio.module.css";
import CourierTable from "./CourierTable";
import { GetDeliveryRation } from "../../../api/CustomerApi";
import { toast } from "react-toastify";
export default function CourierRatio() {
  const [loading, setLoading] = useState(false);
  const [curierData, setCurierData] = useState();
  async function onSearchClick(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const phone = e.target.phone.value;
      const response = await GetDeliveryRation(phone);
      // console.log(response);
      setCurierData(response.data.courierData);
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  return (
    <div className={style.wrapper}>
      <div className={style.holder}>
        <div className={style.form_wrapper}>
          <h2>Check Coustomer</h2>
          <form onSubmit={onSearchClick}>
            <div className={style.input_wrapper}>
              <label>Phone Number *</label>
              <input
                type="number"
                name="phone"
                placeholder="Phone Number"
                required
              />
            </div>
            <div className={style.button_wrapper}>
              <button>Search</button>
            </div>
          </form>
        </div>
      </div>
      {loading ? (
        <h2>Please Wait, Data is Loading...</h2>
      ) : (
        <CourierTable table_item={curierData} />
      )}
    </div>
  );
}
