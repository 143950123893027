import React, { useEffect, useState } from "react";
import { API_LINK } from "../../api/APILink";
import {
  DeleteBannerData,
  GetBannerData,
  SaveBannerData,
  UpdateBannerData,
} from "../../api/SettingApi";
import ConfirmAlert from "../smallThings/ConfirmAlert";
import ToggleSwitch from "../smallThings/ToggleSwitch";
import ManageBannersCss from "./css/ManageBanners.module.css";
import { toast } from "react-toastify";
import ManageBannersPop from "./ManageBannersPop";

async function getAllData(setTableData) {
  let myData = await GetBannerData();
  setTableData(myData);
  // console.log(myData)
}

const ManageBanners = () => {
  const myData = new Date();

  const [currentState, setCurrentState] = useState({});
  const [tableData, setTableData] = useState([]);
  const [conAlert, setConAlert] = useState();

  async function ButtonClick(e) {
    e.preventDefault();
    const name = e.target.name.value;
    const alter_text = e.target.alter_text.value;
    const banner_url = e.target.banner_url.value;
    const imageNo = e.target.imageNo.value;
    const sectionNo = e.target.sectionNo.value;
    const banner_image = e.target.banner_image.files;
    let finalData = {
      name,
      alter_text,
      banner_url,
      imageNo,
      sectionNo,
      active: 0,
      created_date: myData.getTime(),
    };
    // console.log(finalData, banner_image);
    try {
      await SaveBannerData(finalData, banner_image[0]);
      toast.success("Your Banner Uploaded Successfully.");
      e.target.reset();
      getAllData(setTableData);
    } catch (error) {
      toast.error(error.message);
    }
  }

  const [editData, setEditData] = useState();
  function onEditClick(item) {
    setEditData(item);
  }

  function DeletClick(item) {
    setConAlert(item);
  }

  async function ClickYes() {
    let newArr = [];
    let deleteid = conAlert.id;
    await DeleteBannerData(deleteid, currentState.image_name);
    setConAlert(false);
    setCurrentState({});
    tableData.forEach((element) => {
      if (element.id === deleteid) {
      } else {
        newArr.push(element);
      }
    });

    setTableData(newArr);
    setConAlert();
  }

  function ClickNo() {
    setConAlert(false);
    setCurrentState({
      name: "",
      url: "",
      alterText: "",
      slideOption: "",
    });
  }

  useEffect(() => {
    getAllData(setTableData);
  }, []);
  async function onItemSwitchClick(item) {
    let isActice = 0;
    if (item.active === 0) {
      isActice = 1;
    } else {
      isActice = 0;
    }
    //console.log(image_name:item.image_name)
    await UpdateBannerData(item.id, {
      active: isActice,
      image_name: item.image_name,
    });
    //    console.log(item.id)
    let tempArr = [];
    tableData?.map((okItem) => {
      if (okItem.id === item.id) {
        okItem = {
          ...okItem,
          active: isActice,
        };
      }
      tempArr.push(okItem);
    });
    setTableData(tempArr);
  }

  return (
    <section className={ManageBannersCss.wrapper}>
      {conAlert ? <ConfirmAlert yesClick={ClickYes} noClick={ClickNo} /> : ""}
      {editData && <ManageBannersPop popUp={editData} setPopUp={setEditData} />}
      <div className={ManageBannersCss.innerSale}>
        <h3>Banner Image List</h3>
        <form className={ManageBannersCss.my_form} onSubmit={ButtonClick}>
          <div className={ManageBannersCss.common}>
            <label>Name *</label>
            <input name="name" type="text" placeholder="banner name" required />
          </div>
          <div className={ManageBannersCss.common}>
            <label>URL *</label>
            <input
              name="banner_url"
              type="text"
              placeholder="banner url here"
              required
            />
          </div>
          <div className={ManageBannersCss.common}>
            <label>Alter Text *</label>
            <input
              name="alter_text"
              type="text"
              placeholder="image alter here"
              required
            />
          </div>
          <div className={ManageBannersCss.common}>
            <label>Banner Image *</label>
            <input type="file" name="banner_image" required />
            <img
              className={ManageBannersCss.SlideImageshow}
              src={currentState.fakebanner_image}
            />
          </div>

          <div className={ManageBannersCss.common}>
            <label>Section Name</label>
            <select name="sectionNo" required>
              <option value={""}>Select Section</option>
              <option value={1}>Section One</option>
              <option value={2}>Section Two</option>
              <option value={3}>Section Three</option>
              <option value={4}>Section Four</option>
              <option value={5}>Section Five</option>
            </select>
          </div>

          <div className={ManageBannersCss.common}>
            <label>Image No</label>
            <select name="imageNo" required>
              <option value={""}>Select One</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
            </select>
          </div>
          <div className={ManageBannersCss.buttonWrapper}>
            <button>Save</button>
          </div>
        </form>

        <div className={ManageBannersCss.table_wrapper}>
          <table>
            <thead>
              <tr>
                <th>Sl</th>
                <th>Images</th>
                <th>Alter Text</th>
                <th>Section No</th>
                <th>Image No</th>
                <th>URL</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>

                    <td>
                      <img
                        src={`${API_LINK}slider_images/${item.image_name}`}
                      />
                    </td>
                    <td>{item.alter_text}</td>
                    <td>{item.sectionNo}</td>
                    <td>{item.imageNo}</td>
                    <td>{item.banner_url}</td>
                    <td>
                      <ToggleSwitch
                        toggleOnOff={item.active}
                        toggleClick={() => {
                          onItemSwitchClick(item);
                        }}
                      />
                    </td>
                    <td>
                      <div className={ManageBannersCss.action_buttons}>
                        <button onClick={() => onEditClick(item)}>Edit</button>
                        <button
                          onClick={() => {
                            DeletClick(item);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default ManageBanners;
