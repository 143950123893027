import React, { useEffect, useState } from "react";
import Headerimage from "../../assets/Headerimage.jpg";
import QRcode from "../../assets/anayase_qrcode.png";
import { GenarateText } from "../connector/InWord";
import { RearrangeDateThree } from "../MyFunctions/MyFunctions";
import InvoiceCss from "./salesCss/Invoice.module.css";
import { FaBangladeshiTakaSign } from "react-icons/fa6";
const Invoice = () => {
  const [invoiceState, setInvoiceState] = useState();

  function PrintPaper() {
    window.print();
  }
  function convertDateTime(time) {
    const dateInMillis = time;
    const date = new Date(dateInMillis);
    let myDate = date.toLocaleDateString();
    let myTime = date.toLocaleTimeString();
    myDate = myDate.replaceAll("/", "-");
    return myTime;
  }
  const [extraInfo, setExtrainfo] = useState({});

  async function getData() {
    let data = JSON.parse(localStorage.getItem("invoiceData"));
    setInvoiceState(data);
    getGrandTotal(data?.tableData);
  }
  useEffect(() => {
    getGrandTotal(invoiceState?.tableData);
  }, [invoiceState]);

  function getGrandTotal(data) {
    let total = 0;
    let saleTotal = 0;
    let myData = [];
    data?.forEach((element) => {
      element?.forEach((melement) => {
        myData.push(melement);
      });
    });

    myData.forEach((element) => {
      total += element.orPrice * element.quantity - (element.rtotal || 0);
      saleTotal += element.price * element.quantity - (element.rtotal || 0);
    });
    let percentDis = invoiceState?.discountPercent
      ? (total * invoiceState?.discountPercent) / 100
      : 0;
    //  console.log(percentDis);
    let discount = total - saleTotal + percentDis;
    setExtrainfo({ total, saleTotal, discount });
  }

  useEffect(() => {
    getData();
  }, []);

  function getQTotal(item) {
    let total = 0;
    item?.forEach((element) => {
      total += element.quantity - element.rquantity || 0;
    });

    return total;
  }
  function totalAmmount(item) {
    let quantity = getQTotal(item);
    let total = 0;
    total += item[0].orPrice;
    return total * quantity;
  }

  const tableItem = invoiceState?.tableData
    ?.filter((fitem) => {
      return getQTotal(fitem) > 0 ? fitem : "";
    })
    .map((item, index) => {
      return (
        <InvoiceTable
          sl={index + 1}
          description={item[0].name}
          varName={
            item[0].hasVriance ? (
              <>
                {item.map((mainItem) => {
                  return (
                    <>
                      {mainItem.Variance_name} : {mainItem.quantity} pcs
                      {mainItem.attr_value_name
                        ? ` (${mainItem.attr_value_name})`
                        : ""}
                      <br />
                    </>
                  );
                })}
              </>
            ) : (
              ""
            )
          }
          quantity={getQTotal(item)}
          uniteprice={item[0].orPrice}
          total={totalAmmount(item)}
        />
      );
    });

  return (
    <div className={InvoiceCss.invoicemain}>
      <button className={InvoiceCss.printBtn} onClick={PrintPaper}>
        Print
      </button>

      <section className={InvoiceCss.invoiceSection}>
        <div className={InvoiceCss.container} id="printInvoice">
          <img src={Headerimage} alt="" />

          <div className={InvoiceCss.main}>
            <h3>INVOICE / BILL</h3>
            <div className={InvoiceCss.details}>
              <div className={InvoiceCss.client}>
                <div className={InvoiceCss.info}>
                  <span className={InvoiceCss.moreinfo}>Invoice Number : </span>
                  #{invoiceState?.id}
                </div>
                <div className={InvoiceCss.info}>
                  <span className={InvoiceCss.moreinfo}>Customar Name : </span>
                  {invoiceState?.name}
                </div>
                <div className={InvoiceCss.info}>
                  <span className={InvoiceCss.moreinfo}>Phone No : </span>
                  {invoiceState?.Phone}
                </div>
                <div className={InvoiceCss.info}>
                  <span className={InvoiceCss.moreinfo}>Address : </span>{" "}
                  {invoiceState?.Address
                    ? invoiceState?.Address +
                      ", " +
                      invoiceState?.Area +
                      ", " +
                      invoiceState?.District +
                      ", " +
                      invoiceState?.Divition +
                      ","
                    : ""}
                </div>
              </div>
              <div className={InvoiceCss.invoice}>
                <div className={InvoiceCss.info}>
                  <span
                    className={`${InvoiceCss.moreinfo} ${InvoiceCss.invoiceMoreinfo}`}
                  >
                    Date :{" "}
                  </span>
                  {RearrangeDateThree(
                    new Date(invoiceState?.order_created).toLocaleDateString()
                  )}
                </div>
                <div className={InvoiceCss.info}>
                  <span
                    className={`${InvoiceCss.moreinfo} ${InvoiceCss.invoiceMoreinfo}`}
                  >
                    Time :{" "}
                  </span>{" "}
                  {convertDateTime(invoiceState?.order_created)}
                </div>
                <div className={InvoiceCss.info}>
                  <span
                    className={`${InvoiceCss.moreinfo} ${InvoiceCss.invoiceMoreinfo}`}
                  >
                    Sold By :{" "}
                  </span>
                  Anayase.com
                </div>
              </div>
            </div>
            <div className={InvoiceCss.table}>
              <table>
                <thead>
                  <tr>
                    <th className={InvoiceCss.no}>SI No.</th>
                    <th style={{ paddingLeft: "10px" }}>Item Name</th>
                    <th className={InvoiceCss.unit}>Quantity</th>
                    <th className={InvoiceCss.unit_price}>Unit Price</th>
                    <th className={InvoiceCss.total}>Total</th>
                  </tr>
                </thead>
                <tbody>{tableItem}</tbody>
                <tfoot>
                  <div className={InvoiceCss.notices}>
                    <p>
                      <span className={InvoiceCss.inword}>In Word : </span>
                      {GenarateText(
                        Math.round(
                          extraInfo.total -
                            extraInfo.discount +
                            invoiceState?.deliveryCharge
                        )
                      )}
                    </p>
                    <b className={InvoiceCss.notetext}>
                      NOTE : {invoiceState?.special_instruction || "no note"}{" "}
                    </b>
                    <img src={QRcode} alt="" />
                  </div>
                  <tr>
                    <td colSpan="3" className={InvoiceCss.inWord}></td>
                    <td>Sub Total</td>
                    <td>
                      {extraInfo.total} <FaBangladeshiTakaSign />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3"></td>
                    <td style={{ width: "110px" }}>Discount(-)</td>
                    <td>
                      {" "}
                      {extraInfo.discount?.toFixed()}
                      <FaBangladeshiTakaSign />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3"></td>
                    <td>Delivery Charge</td>
                    <td>
                      {invoiceState?.deliveryCharge} <FaBangladeshiTakaSign />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="5">
                      <span></span>
                    </td>
                  </tr>
                  <tr className={InvoiceCss.gran_total}>
                    <td colSpan="3"></td>
                    <td className={InvoiceCss.gran_total_text}>Grand Total</td>
                    <td className={InvoiceCss.gran_total_text}>
                      {(
                        extraInfo.total -
                        extraInfo.discount +
                        invoiceState?.deliveryCharge
                      ).toFixed(2)}{" "}
                      <FaBangladeshiTakaSign />
                    </td>
                  </tr>
                  <tr className={InvoiceCss.pre_Paid}>
                    <td colSpan=" 3"></td>
                    <td className={InvoiceCss.pre_Paid_text}>Pre Paid(-)</td>
                    <td>
                      {invoiceState?.totalCollection || 0}{" "}
                      <FaBangladeshiTakaSign />
                    </td>
                  </tr>
                  <tr className={InvoiceCss.amount}>
                    <td colSpan="3">
                      {/* <p>
                        <span className={InvoiceCss.inword}>In Word : </span>
                        {GenarateText(
                          Math.round(
                            extraInfo.total -
                              extraInfo.discount +
                              invoiceState?.deliveryCharge
                          )
                        )}
                      </p> */}
                    </td>
                    <td className={InvoiceCss.amount_text}>Amount to Pay</td>
                    <td>
                      {(
                        extraInfo.total -
                        extraInfo.discount +
                        invoiceState?.deliveryCharge
                      ).toFixed(2)}{" "}
                      <FaBangladeshiTakaSign />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3"></td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <div className={InvoiceCss.seal_signiture}>
              <span>Customer Signature</span>
              <span>Authority Signature</span>
            </div>
            <div className={InvoiceCss.seal_signiture1}>
              <span className={InvoiceCss.thankyou_msg}>
                Thank you for being with{" "}
                <strong style={{ fontSize: "18px" }}>anayase.com</strong>
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Invoice;

const InvoiceTable = ({
  sl,
  description,
  varName,
  ageName,
  quantity,
  uniteprice,
  total,
}) => {
  return (
    <tr>
      <td className={InvoiceCss.no}>{sl}</td>
      <td className={InvoiceCss.desc}>
        {description}{" "}
        <span style={{ color: "#424242", display: "flex" }}>
          {varName} {ageName}
        </span>
      </td>
      <td className={InvoiceCss.unit}>{quantity}</td>
      <td className={InvoiceCss.unit_price}>{uniteprice}</td>
      <td className={InvoiceCss.total}>{total}</td>
    </tr>
  );
};
