import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAtributes, fetchAtributesName, selectAttributeList, selectAttributeNameList, selectMAUStatus } from '../../ReduxFiles/Slices/MAU_Slice'
import AtributeButton from '../smallThings/AtributeButton'
import AtributeCss from './css/Atributes.module.css'

const Atributes = () => {
    const mauStatus = useSelector(selectMAUStatus)
    const attributeValue = useSelector(selectAttributeList)
    const attributesName = useSelector(selectAttributeNameList)
    const [attrValue, setattrValue] = useState({
        attrName: "",
        attr_value_name: ""
    });
    const [tableData, setTableData] = useState();
    // const [attributeValue, setAttributeValue] = useState();

    function handleChange(e) {
        let name = e.target.name;
        let value = e.target.value
        setattrValue({ ...attrValue, [name]: value })
    }

    function saveAttribute(e) {
        // DeleteUnvaluedAttributeName()
        if (attrValue.attrName !== "" && attrValue.attr_value_name !== "") {
            saveData()
            setattrValue({ ...attrValue, ["attr_value_name"]: "" })
        } else {
            alert("No Data for Save")
        }
    }
    async function saveData() {
        let myUpperValue = attrValue.attrName.toUpperCase()
        let singleData = [] //await GetFilteredDataExpress('attributes_name', 'att_name', myUpperValue)
        if (singleData.length > 0) {
            //await SaveDataExpress('attributes', { attr_value_name: attrValue.attr_value_name, attr_name_id: singleData[0].id })
            // setAttributeValue([...attributeValue, { attr_value_name: attrValue.attr_value_name, attr_name_id: singleData[0].id }])
        } else {
            let itemID = [] //await SaveDataExpress('attributes_name', { att_name: myUpperValue })
           // await SaveDataExpress('attributes', { attr_value_name: attrValue.attr_value_name, attr_name_id: itemID })
            setTableData([...tableData, { att_name: myUpperValue, id: itemID }])
            // setAttributeValue([...attributeValue, { attr_value_name: attrValue.attr_value_name, attr_name_id: singleData[0].id }])
        }
        // console.log(attrValue.attr_value_name)

    }
    async function DeleteUnvaluedAttributeName() {
        for (let index = 0; index < tableData?.length; index++) {
            let notfoundid = ''
            let found = false
            let element = tableData[index]
            // console.log(element.att_name)
            for (let index = 0; index < attributeValue.length; index++) {
                const aelement = attributeValue[index];
                if (element.id === aelement.attr_name_id) {
                    //  console.log(element.id, element.att_name)
                    found = true
                    notfoundid = ''
                    break
                } else {
                    // console.log('else', element.id, element.att_name)
                    notfoundid = element.id
                }
            }
            if (!found) {
                // console.log(notfoundid)
               // await DeleteItemExpress('attributes_name', notfoundid)
                // fetchData('attributes_name', setTableData)
            }
        }

    }

    async function attrDelete(e) {
        let id = e.target.getAttribute("uid")
       // await DeleteItemExpress('attributes', id)
        let tempArr = []
        attributeValue.forEach(element => {
            if (element.id !== parseInt(id)) {
                tempArr.push(element)
            }
        });
        // setAttributeValue(tempArr)
       // DeleteUnvaluedAttributeName()

    }

    
const dispatch = useDispatch()
    useEffect(() => {
      //  DeleteUnvaluedAttributeName()
        if (mauStatus.attributesNameStatus==='idle') {
            dispatch(fetchAtributesName())
        }
        if (mauStatus.attributesStatus ==='idle') {
            dispatch(fetchAtributes())
        }
    }, [])

    let TableItem = attributesName?.map((item, index) => {
        return <tr key={index}>
            <td>{index + 1}</td>
            <td>{item.att_name}</td>
            <td>{attributeValue?.filter((fitem) => {
                return item.id === fitem.attr_name_id ? fitem : ''
            }).map((item, id) => {
                return <AtributeButton key={item.id} clickevent={attrDelete} value={item.attr_value_name} identity={item.id} />
            })}</td>
        </tr>
    })

    return (
        <>
            <div className={`${AtributeCss.addCatagory} ${AtributeCss.admincomm}`}>
                <h3 className={AtributeCss.itemlisth3} >Manage Attributes</h3>
                <div className={AtributeCss.atributesec} >
                    <div className={AtributeCss.nameAtrribute} >
                        {/* <PopUpInput popname="attrName" poplabel="Attribute Name*" popplaceholder="Attribute  Name" poptype="text" ondatachange={handleChange} value={attrValue.attrName} />
                        <PopUpInput popname="attr_value_name" poplabel="Attribute Value Name*" popplaceholder="Attribute Value Name" poptype="text" ondatachange={handleChange} value={attrValue.attr_value_name} /> */}
                        <input className={AtributeCss.addnew}  type={"submit"} value="Add New" onClick={saveAttribute} />
                    </div>

                </div>

                <div className={AtributeCss.AtributesTable}>
                    <table>
                        <tbody>
                            <tr>
                                <th>Sl</th>
                                <th>Attribute Name</th>
                                <th>Atribute Values</th>
                            </tr>
                            {TableItem}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default Atributes