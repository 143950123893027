export const OrderMethod = [
  { label: "Messanger", value: 1 },
  { label: "Whats App", value: 2 },
  { label: "Website", value: 3 },
  { label: "Facebook Ads", value: 4 },
  { label: "Google Ads", value: 5 },
  { label: "Office Visit", value: 6 },
];
export const OrderMethodObject = {
  1: "Messanger",
  2: "WhatsApp",
  3: "Website",
  4: "Facebook Ads",
  5: "Google Ads",
  6: "Office Visit",
};
export const OrderMethodColors = {
  1: "#9B36FF",
  2: "#25D366",
  3: "#333333",
  4: "#1877F2",
  5: "#34A853",
  6: "#e83e8c",
};
