import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import PopUpLocation from "./PopUpLocation";
import { GetDetailsReturnOrderItems, GetSingleOrder } from "../../api/SalesApi";
import {
  GetSingleCustomer,
  GetSingleCustomerOrderAddress,
} from "../../api/CustomerApi";
import ReturnDetViewCss from "./salesCss/ReturnDetailsView.module.css";
import { IoMdArrowBack } from "react-icons/io";
const ReturnDetailsView = () => {
  const myParams = useParams();
  const [tableData, setTableData] = useState();
  const [orderData, setOrderData] = useState();
  const [popAddress, setPopAddress] = useState(false);

  function onPrintInvoice() {
    let okdata = {};
    okdata = { ...okdata, tableData, ...orderData };
    localStorage.setItem("invoiceData", JSON.stringify(okdata));
  }

  function convertDate(time) {
    const dateInMillis = time;
    const date = new Date(dateInMillis);
    let myDate = date.toLocaleDateString();
    let myTime = date.toLocaleTimeString();
    myDate = myDate.replaceAll("/", "-");
    return myDate + " " + myTime;
  }

  async function getAllData() {
    let myData = await GetSingleOrder(myParams.orderid);
    let myCustomer = await GetSingleCustomer(myData[0].userID);
    let userAddress = await GetSingleCustomerOrderAddress(
      myData[0].user_address_id
    );
    let getSSLdata = [];
    let finalData = {
      ...myData[0],
      order_created: myData[0].created_date,
      ...userAddress[0],
      ...myCustomer[0],
      ...getSSLdata[0],
      deliveryCharge: myData[0].deliveryCharge,
      id: myData[0].id,
    };
    setOrderData(finalData);
    let morderItems = await GetDetailsReturnOrderItems({
      id: myParams.orderid,
    });
    organizeData(morderItems);
  }
  function organizeData(nreturnData) {
    let allProducts = JSON.parse(window.localStorage.getItem("allProduct"));
    let finalArr = [];
    let uniqueElements = nreturnData.slice();
    for (let index = 0; index < nreturnData.length; index++) {
      const element = nreturnData[index];
      let tempArr = [];
      for (let mindex = 0; mindex < uniqueElements.length; mindex++) {
        const melement = uniqueElements[mindex];
        if (melement.product_id === element.product_id) {
          tempArr.push(melement);
        }
      }

      if (tempArr.length > 0) {
        tempArr.forEach((element) => {
          uniqueElements.forEach((melement, mindex) => {
            if (melement.return_id === element.return_id) {
              uniqueElements.splice(mindex, 1);
            }
          });
        });
        finalArr.push(tempArr);
      }
    }

    let okArr = [];
    finalArr.forEach((element) => {
      // console.log(element)
      for (let index = 0; index < allProducts.length; index++) {
        const pelement = allProducts[index];
        if (pelement?.id === element[0]?.product_id) {
          element[0].name = pelement.en_sort_title;
          //   element = [...element, element[0].name]
          okArr.push(element);
          break;
        }
      }
    });
    setTableData(okArr);
  }

  useEffect(() => {
    getAllData();
  }, [myParams.orderid]);
  useEffect(() => {
    getAllTotal();
  }, [tableData]);

  const [extainfo, setExtrainfo] = useState({});
  function getAllTotal() {
    let total = 0;
    let saleTotal = 0;
    tableData?.forEach((item) => {
      item.forEach((element) => {
        total += element.quantity * element.orPrice;
        saleTotal += element.quantity * element.price;
      });
    });
    let discount =
      total - saleTotal + (total * orderData?.discountPercent) / 100;
    setExtrainfo({ total, saleTotal, discount });
  }

  function getQTotal(item) {
    let total = 0;
    item?.forEach((element) => {
      total += element.quantity;
    });

    return total;
  }
  function totalAmmount(item) {
    let quantity = getQTotal(item);
    let total = 0;
    total += item[0].orPrice;
    return total * quantity;
  }

  const TableItem = tableData?.map((item, index) => {
    return (
      <MyTable
        key={index}
        item={item[0].mainName ? item[0]?.mainName : item[0].name}
        editVariance=""
        price={item[0]?.orPrice}
        qty={getQTotal(item)}
        tammount={totalAmmount(item)}
      />
    );
  });
  return (
    <>
      <div className={ReturnDetViewCss.border}>
        <section className={ReturnDetViewCss.salesMain}>
          <div className={ReturnDetViewCss.innerSale}>
            {popAddress ? (
              <div className={ReturnDetViewCss.popupLocation}>
                <PopUpLocation
                  setPopAddress={setPopAddress}
                  orderData={orderData}
                />
              </div>
            ) : (
              ""
            )}
            <div className={ReturnDetViewCss.gobackSec}>
              <h2 className={ReturnDetViewCss.saleHeading}>
                Return Order Details
              </h2>
              <NavLink to={"/admin/sales/sale_history"}>
                <IoMdArrowBack />
              </NavLink>
            </div>
            <div className={ReturnDetViewCss.orderdetaislInner}>
              <h3>Invoice #{orderData?.id}</h3>
              <div className={ReturnDetViewCss.customerSection}>
                <div className={ReturnDetViewCss.customerDetails}>
                  <h4>Customer Details</h4>
                  <p>
                    <strong>Customer Name</strong> : {orderData?.name}
                  </p>
                  <p>
                    <strong>Phone</strong> : {orderData?.Phone}
                  </p>
                  <p>
                    <strong>Division</strong> :{orderData?.Divition}
                  </p>
                  <p>
                    <strong>District</strong> : {orderData?.District}
                  </p>
                  <p>
                    <strong>area</strong> : {orderData?.Area}
                  </p>
                  <div className={ReturnDetViewCss.addressSec}>
                    <p>
                      <strong>Address</strong> : {orderData?.Address}
                    </p>
                  </div>
                </div>
                <div className={ReturnDetViewCss.orderDetails}>
                  <h4>Order Details</h4>
                  <p>
                    <strong>Order Date :</strong>{" "}
                    {convertDate(orderData?.order_created)}{" "}
                  </p>
                  <p>
                    <strong>Payment Type :</strong> {orderData?.paymentType}
                  </p>
                </div>
              </div>
              <div className={ReturnDetViewCss.customerSection}>
                <div className={ReturnDetViewCss.customerDetails}>
                  <p className={ReturnDetViewCss.highlighter}>
                    Confirmation Details
                  </p>
                  <p>
                    <strong>Note : </strong> : {orderData?.approval_note}
                  </p>
                  <p>
                    <strong>Delivery Priority : </strong> :{orderData?.priority}
                  </p>
                  <p>
                    <strong>Delivery Date : </strong> :{" "}
                    {orderData?.delivery_date}
                  </p>
                  <p>
                    <strong>Special Instruction's : </strong> :{" "}
                    {orderData?.special_instruction}
                  </p>
                </div>
              </div>
              <div
                className={`${ReturnDetViewCss.tableSection} ${ReturnDetViewCss.detailstableSection}`}
              >
                <table>
                  <tbody>
                    <tr>
                      <th>Item</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Total Ammount</th>
                    </tr>
                    {TableItem}
                  </tbody>
                </table>
                <div className={ReturnDetViewCss.tableDetails}>
                  <p>Total Ammount : {extainfo.total} tk</p>
                  <p>Discount : {extainfo.discount} tk</p>
                  <p>Return Charge : {orderData?.return_charge} tk</p>
                  <p>Grand Total : {extainfo.total - extainfo.discount} tk</p>
                </div>
                <div className={ReturnDetViewCss.detailsWrapper}>
                  <div
                    className={`${ReturnDetViewCss.detailInput}${ReturnDetViewCss.salesDetail}`}
                  >
                    <div className={ReturnDetViewCss.ButtonGroup}>
                      <Link
                        to={`/sales/salesdetails/${orderData?.id}/invoice_return`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={onPrintInvoice}
                      >
                        Print Invoice
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ReturnDetailsView;

const MyTable = ({ item, editVariance, price, qty, tammount }) => {
  return (
    <>
      <tr>
        <td className={ReturnDetViewCss.foreditVariance}>
          {item} {editVariance}
        </td>
        <td>{price}</td>
        {/* <td>{variations}</td> */}
        <td>{qty}</td>
        <td>{tammount}</td>
      </tr>
    </>
  );
};
