import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ExportExcelFile,
  ServerTimeToFormateTime,
} from "../../../api/GlobalFunctions";
import { SalesReportData } from "../../../api/ReportApi/SalesReportApi";
import Headerimage from "../../../assets/Headerimage.jpg";
import { GetEndDate, GetStartDate } from "../../MyFunctions/MyFunctions";
import Spinner from "../../smallThings/Spinner";
import SalesReportViewNewCss from "./css/SalesReportViewNew.module.css";
import ScrollComp from "../../global/ScrollComp";

const SalesReportViewNew = ({ heading }) => {
  const [invoiceState, setInvoiceState] = useState();
  const [AllProcessedData, setAllProcessedData] = useState();
  const [showSpinner, setShowSpinner] = useState(false);
  let [searchParams] = useSearchParams();

  async function getData() {
    let start_date = GetStartDate(searchParams.get("start_date"));
    let end_date = GetEndDate(searchParams.get("end_date"));
    sendRequestForData(start_date, end_date);
  }
  let minusAmount = 0;
  let month = new Date(searchParams.get("start_date")).getMonth();
  let year = new Date(searchParams.get("start_date")).getFullYear();
  if (month === 1 && year === 2024) {
    minusAmount = 842;
  }
  // if (month === 9 && year === 2024) {
  //   minusAmount = 600;
  // }
  // console.log(month, year);

  async function sendRequestForData(start_date, end_date) {
    setShowSpinner(true);
    // console.log(start_date, end_date);
    let data = await SalesReportData({
      starttime: start_date,
      endtime: end_date,
    });
    // console.table(data);
    let tempArr = [];
    // console.log(data[1]);
    data.forEach((item) => {
      let receiveAmount = item.bank + item.cash;
      let returnPrice = item.fullReturn ? 0 : item.rprice;
      let discount = item.fullReturn
        ? 0
        : (item.discountPercent * item.total_orprice) / 100 +
          (item.total_orprice - item.total_price);
      let tba = item.fullReturn
        ? 0
        : item.total_orprice -
          ((item.discountPercent * item.total_orprice) / 100 +
            (item.total_orprice - item.total_price)) +
          item.deliveryCharge -
          returnPrice;
      let netSale =
        tba - (item.codSSLCharge + item.deliveryCost + item.return_charge);
      let due = tba - receiveAmount;
      item = {
        ...item,
        discount,
        tba,
        netSale,
        due,
        total_orprice: item.fullReturn ? 0 : item.total_orprice - returnPrice,
        receiveAmount: receiveAmount || 0,
        deliveryCharge: item.fullReturn ? 0 : item.deliveryCharge,
      };
      tempArr.push(item);
    });
    // console.log(tempArr);
    setInvoiceState(tempArr);
    setAllProcessedData(tempArr);
    setShowSpinner(false);
    SetAllTotal(tempArr);
    // console.log(tempArr);
  }
  const [AllTotal, setAllTotal] = useState({});

  function SetAllTotal(filteredData) {
    let grandTotal = 0;
    let discount = 0;
    let totalBill = 0;
    let codSSLCharge = 0;
    let deliveryCharge = 0;
    let deliveryCost = 0;
    let return_charge = 0;
    let netSale = 0;
    let collectedAmmount = 0;
    let due = 0;
    let bankTotal = 0;
    let cashTotal = 0;
    let bankcost = 0;
    let cashcost = 0;
    let extracost = 0;
    // console.log(filteredData[0]);
    filteredData?.forEach((element) => {
      grandTotal += element.fullReturn ? 0 : element.total_orprice;
      bankcost +=
        element.bank > 0
          ? element.deliveryCost + element.return_charge + element.codSSLCharge
          : 0;
      cashcost +=
        element.cash > 0 && element.bank <= 0
          ? element.deliveryCost + element.return_charge + element.codSSLCharge
          : 0;
      if (element.bank <= 0 && element.cash <= 0) {
        extracost +=
          element.deliveryCost + element.return_charge + element.codSSLCharge;
      }

      discount += element.discount;
      totalBill += element.tba;
      codSSLCharge += element.codSSLCharge;
      deliveryCharge += element.deliveryCharge;
      deliveryCost += element.deliveryCost;
      return_charge += element.return_charge;
      netSale += element.netSale;
      collectedAmmount += element.fullReturn ? 0 : element.receiveAmount;
      due += element.due;
      bankTotal += element.bank;
      cashTotal += element.cash;
    });
    // console.log(extracost - return_charge, "ex");
    extracost = extracost - return_charge;

    // console.log(totalBill, minusAmount);
    // console.log(cashTotal, cashcost);
    bankTotal = bankTotal - bankcost - return_charge;
    cashTotal = cashTotal - cashcost - minusAmount - extracost;
    // console.log(bankTotal + cashTotal);
    // console.log(
    //   bankTotal,
    //   cashTotal,
    //   cashcost,
    //   deliveryCost,
    //   return_charge,
    //   codSSLCharge
    // );
    setAllTotal({
      grandTotal,
      discount,
      totalBill,
      codSSLCharge,
      deliveryCharge,
      deliveryCost,
      return_charge,
      netSale,
      collectedAmmount,
      due,
      bankTotal,
      cashTotal,
    });
  }

  useEffect(() => {
    getData();
  }, []);

  function checkClick() {}
  const [filterVar, setFilterVar] = useState({
    bank: false,
    cash: false,
  });
  function onCheckBoxChange(e) {
    let name = e.target.name;
    if (name === "bank" && !filterVar.bank) {
      setFilterVar({ ...filterVar, [name]: !filterVar[name], cash: false });
      dataFilter("bank");
    } else if (name === "cash" && !filterVar.cash) {
      setFilterVar({ ...filterVar, [name]: !filterVar[name], bank: false });
      dataFilter("cash");
    } else {
      setFilterVar({ ...filterVar, [name]: !filterVar[name] });
      dataFilter();
    }
  }

  function dataFilter(identity) {
    let tempArr = [];
    AllProcessedData.forEach((element) => {
      if (identity === "bank") {
        if (element.bank > 0 || element.fullReturn) {
          tempArr.push(element);
        }
      } else if (identity === "cash") {
        element.cash > 0 && tempArr.push(element);
      } else {
        tempArr.push(element);
      }
    });
    setInvoiceState(tempArr);
    SetAllTotal(tempArr);
  }
  function PrintPaper() {
    window.print();
  }

  function onExportClick() {
    console.log(invoiceState);
    let start_date = searchParams.get("start_date");
    let end_date = searchParams.get("end_date");
    if (invoiceState.length > 0) {
      const finalData = invoiceState.map((item, index) => {
        return {
          SL: index + 1,
          "Invoice No": item.id,
          "Customer Name": item.name,
          "Date & Time": ServerTimeToFormateTime(item.created_date),
          Name: item.name,
          "MRP Amount": item.total_orprice,
          Discount: parseFloat(item.discount.toFixed(2)),
          "Delivery Charge": parseFloat(item.deliveryCharge.toFixed(2)),
          "Total Bill Amount": parseFloat(item.tba.toFixed(2)),
          "D.C Expence": parseFloat(item.deliveryCost.toFixed(2)),
          "COD Charge": parseFloat(item.codSSLCharge.toFixed(2)),
          "Return Charge": parseFloat(item.return_charge.toFixed(2)),
          "Net Sales": parseFloat(item.netSale.toFixed(2)),
          Payment: parseFloat(item.receiveAmount.toFixed(2)),
          Due: parseFloat(item.due.toFixed(2)),
          Status: item.status,
          "Payment Method":
            item.bank > 0 ? "Bank" : item.cash > 0 ? "Cash" : "Not",
        };
      });

      ExportExcelFile(
        finalData,
        `Sales Report Invoice Wise (${start_date} to ${end_date})`
      );
    } else {
      toast.info("No Data Found to Export!");
    }

    // ExportExcelFile()
  }

  return (
    <div id="invmain" className={SalesReportViewNewCss.invoice_main}>
      <ScrollComp divid={"invmain"} />
      {showSpinner ? <Spinner /> : ""}
      <button className={SalesReportViewNewCss.printBtn} onClick={PrintPaper}>
        Print
      </button>

      <section className={SalesReportViewNewCss.contents}>
        <img src={Headerimage} alt="invoice header" />
        <div className={SalesReportViewNewCss.client}>
          <p className={SalesReportViewNewCss.moreinfo} onClick={checkClick}>
            Area :All Area
          </p>
          <p className={SalesReportViewNewCss.moreinfo}>
            Date : {searchParams.get("start_date")} To{" "}
            {searchParams.get("end_date")}
          </p>
        </div>

        <div className={SalesReportViewNewCss.filter_data}>
          <div className={SalesReportViewNewCss.checks}>
            <input
              id="check1"
              type="checkbox"
              onChange={onCheckBoxChange}
              name="bank"
              checked={filterVar.bank}
            />
            <label htmlFor="check1">bank</label>
            <input
              id="check2"
              type="checkbox"
              onChange={onCheckBoxChange}
              name="cash"
              checked={filterVar.cash}
            />
            <label htmlFor="check2">cash</label>
          </div>
          <div>
            <button onClick={onExportClick}>Download XLSX</button>
          </div>
        </div>

        <div className={SalesReportViewNewCss.table_wrapper}>
          <table>
            <thead>
              <tr>
                <th>Sl</th>
                <th style={{ width: "20px" }}>Invoice No.</th>
                {/* <th>Date & Time</th> */}
                <th>Customer Name</th>
                <th>MRP Amount</th>
                <th>Discount</th>
                <th>D.C Collect</th>
                <th>Total Bill Amount</th>
                <th>D.C Expence</th>
                <th>COD Charge</th>
                <th>Return Charge</th>
                <th>Net Sales</th>
                <th>Payment</th>
                <th>Due</th>
              </tr>
            </thead>
            <tbody>
              {invoiceState?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td style={{ textAlign: "center" }}>#{item.id}</td>
                    <td style={{ textAlign: "left", fontSize: "12px" }}>
                      {item.name}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.total_orprice.toFixed(2)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.discount.toFixed(2)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.deliveryCharge?.toFixed(2)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.tba.toFixed(2)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.deliveryCost?.toFixed(2)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.codSSLCharge?.toFixed(2)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.return_charge?.toFixed(2)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.netSale?.toFixed(2)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.receiveAmount?.toFixed(2)}{" "}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.due?.toFixed(2)}
                    </td>
                  </tr>
                );
              })}

              <tr className={SalesReportViewNewCss.final_row}>
                <td
                  className={SalesReportViewNewCss.no_border_right}
                  colSpan={"3"}
                >
                  Total :
                </td>
                <td>{AllTotal.grandTotal?.toFixed(1)}</td>
                <td>{AllTotal.discount?.toFixed(1)}</td>
                <td>{AllTotal.deliveryCharge?.toFixed(1)}</td>
                <td>{AllTotal.totalBill?.toFixed(1)}</td>
                <td>{AllTotal.deliveryCost?.toFixed(1)}</td>
                <td>{AllTotal.codSSLCharge?.toFixed(1)}</td>
                <td>{AllTotal.return_charge?.toFixed(1)}</td>
                <td>{AllTotal.netSale?.toFixed(1)}</td>
                <td>{AllTotal.collectedAmmount?.toFixed(1)}</td>
                <td>{AllTotal.due?.toFixed(1)}</td>
              </tr>
              <tr className={SalesReportViewNewCss.final_row}>
                <td
                  className={SalesReportViewNewCss.no_border_right}
                  colSpan={"10"}
                >
                  {" "}
                  Total MRP Sale :
                </td>
                <td colSpan={"3"}>{AllTotal.grandTotal?.toFixed(2)}</td>
              </tr>
              <tr className={SalesReportViewNewCss.final_row}>
                <td
                  className={SalesReportViewNewCss.no_border_right}
                  colSpan={"10"}
                >
                  Discount :
                </td>
                <td colSpan={"3"}>{AllTotal.discount?.toFixed(2)}</td>
              </tr>
              <tr className={SalesReportViewNewCss.final_row}>
                <td
                  className={SalesReportViewNewCss.no_border_right}
                  colSpan={"10"}
                >
                  After Discount :
                </td>
                <td colSpan={"3"}>
                  {(AllTotal.grandTotal - AllTotal.discount)?.toFixed(2)}
                </td>
              </tr>
              <tr className={SalesReportViewNewCss.final_row}>
                <td
                  className={SalesReportViewNewCss.no_border_right}
                  colSpan={"10"}
                >
                  Bank Received :
                </td>
                <td colSpan={"3"}>{AllTotal.bankTotal?.toFixed(2)}</td>
              </tr>
              <tr className={SalesReportViewNewCss.final_row}>
                <td
                  className={SalesReportViewNewCss.no_border_right}
                  colSpan={"10"}
                >
                  Delivery Charge Collect :
                </td>
                <td colSpan={"3"}>{AllTotal.deliveryCharge?.toFixed(2)}</td>
              </tr>
              <tr className={SalesReportViewNewCss.final_row}>
                <td
                  className={SalesReportViewNewCss.no_border_right}
                  colSpan={"10"}
                >
                  Delivery Charge Expense :
                </td>
                <td colSpan={"3"}>
                  {(AllTotal.deliveryCost + AllTotal.return_charge)?.toFixed(2)}
                </td>
              </tr>
              <tr className={SalesReportViewNewCss.final_row}>
                <td
                  className={SalesReportViewNewCss.no_border_right}
                  colSpan={"10"}
                >
                  Delivery Charge Difference :
                </td>
                <td colSpan={"3"}>
                  {(
                    AllTotal.deliveryCharge -
                    AllTotal.return_charge -
                    AllTotal.deliveryCost
                  )?.toFixed(2)}
                </td>
              </tr>
              <tr className={SalesReportViewNewCss.final_row}>
                <td
                  className={SalesReportViewNewCss.no_border_right}
                  colSpan={"10"}
                >
                  COD Charge :
                </td>
                <td colSpan={"3"}>{AllTotal.codSSLCharge?.toFixed(2)}</td>
              </tr>
              <tr className={SalesReportViewNewCss.final_row}>
                <td
                  className={SalesReportViewNewCss.no_border_right}
                  colSpan={"10"}
                >
                  Cash in Hand :
                </td>
                <td colSpan={"3"}>{AllTotal.cashTotal?.toFixed(2)}</td>
              </tr>
              <tr className={SalesReportViewNewCss.final_row}>
                <td
                  className={SalesReportViewNewCss.no_border_right}
                  colSpan={"10"}
                >
                  Total TP Sale :
                </td>
                <td colSpan={"3"}>
                  {(
                    AllTotal.grandTotal -
                    (AllTotal.grandTotal * 20) / 100
                  )?.toFixed(2)}
                </td>
              </tr>
              <tr className={SalesReportViewNewCss.final_row}>
                <td
                  className={SalesReportViewNewCss.no_border_right}
                  colSpan={"10"}
                >
                  Total Amount Collection :
                </td>
                <td colSpan={"3"}>
                  {(AllTotal.bankTotal + AllTotal.cashTotal)?.toFixed(2)}
                </td>
              </tr>
              <tr className={SalesReportViewNewCss.final_row}>
                <td
                  className={SalesReportViewNewCss.no_border_right}
                  colSpan={"10"}
                >
                  Invoice Discount :
                </td>
                <td colSpan={"3"}>
                  {(
                    AllTotal.bankTotal +
                    AllTotal.cashTotal -
                    (AllTotal.grandTotal - (AllTotal.grandTotal * 20) / 100)
                  ).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};
//((AllTotal.bankTotal + AllTotal.cashTotal-AllTotal.return_charge) - (AllTotal.grandTotal - AllTotal.grandTotal * 20 / 100))?.toFixed(2)
export default SalesReportViewNew;
