import { faBars, faEye, faSquareCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { LocalTimeStampToDate } from '../MyFunctions/MyFunctions'
import ExpenseHistoryCss from './css/ExpenseHistory.module.css'
const ExpenseHistory = () => {
    const [filterData, setFilterData] = useState()
    const [tableData, setTableData] = useState()


    function onDataChange(params) {
        
    }
    function onSearchClick(params) {
        
    }
    const [activityID, setActivityID] = useState()
    function onActivityClick(item, mexNo) {
        //console.log(item)
        setActivityID({id:item.id, exno:mexNo})
    }
    const [mypayAmount, setmyPayamount] = useState()
    function onPaymentClick(item) {
        setmyPayamount(item)
    }
    function getPaymentTotal(payArr) {
        let total = 0
        payArr?.map((item)=>{
            total += item.paidAmount
        })
       // console.log(total)
        return total
    }

    const tableIconStyle = {
        margin: '0',
        padding: '0',
        margin: '0 3px 0 3px',
        fontSize: '18px',
        cursor: 'pointer'
      }
  return (
    <>
    {activityID ? <OrderActivities activityID={activityID} setActivityID={setActivityID}/>:''}
    {mypayAmount ?  <PayAmmount setmyPayamount={setmyPayamount} mypayAmount={mypayAmount}/>:''}
    <section className={ExpenseHistoryCss.salesMain}>
        <div className={ExpenseHistoryCss.innerSale}>
            <h2 className={ExpenseHistoryCss.saleHeading}>Expense History</h2>
            <div className={ExpenseHistoryCss.search_section}>
                      <div className={ExpenseHistoryCss.input_section}>
                          <label>Start Date</label>
                          <input name='startDate' type={'date'} value={filterData?.startDate? filterData?.startDate:''} onChange={onDataChange}/>
                      </div>
                      <div className={ExpenseHistoryCss.input_section}>
                          <label>End Date</label>
                          <input name='endDate' type={'date'} value={filterData?.endDate ? filterData?.endDate:''} onChange={onDataChange}/>
                      </div>
                    
                      
                  </div>
                  <div className={ExpenseHistoryCss.input_section}>
                          <button onClick={onSearchClick}>search</button>
                          <button>reset</button>
                      </div>
            <div className={ExpenseHistoryCss.tableSection}>
                <table>
                    <tbody>
                        <tr>
                            <th>Sl</th>
                            <th>Date</th>
                            <th>Ex. No</th>
                            <th>Amount</th>
                            <th>Paid Amount</th>
                            <th>Due Amount</th>
                            <th>Payment Type</th>
                            <th>Process By</th>
                            <th>Action</th>
                        </tr>
                        {tableData?.map((item, index)=>{
                          return <tr key={index}>
                              <td>{index+1}</td>
                              <td>{LocalTimeStampToDate(item.created_date)}</td>
                              <td>{item.id}</td>
                              <td>{item.ammount}</td>
                              <td>{item.paymentAmmount+getPaymentTotal(item.Payment)}</td>
                              <td>{item.ammount-getPaymentTotal(item.Payment)-item.paymentAmmount}
                              {item.ammount-getPaymentTotal(item.Payment) -item.paymentAmmount>0 ? <FontAwesomeIcon style={{color:'green', margin:'0 0 0 5px', padding:'0', fontSize:'18px', cursor:'pointer'}} icon={faSquareCheck} onClick={()=>{onPaymentClick(item)}}/>:''}
                              </td>
                              <td>{item.paymentType}</td>
                              <td>{item.processBy}</td>
                              <td>
                                <span style={{display:'flex'}}>
                                {/* <FontAwesomeIcon style={{...tableIconStyle, color:'green'}} icon={faEdit}/> */}
                                    <NavLink to={"/admin/expenses/expense_history/expense_view_history?exno="+item.id}>
                                        <FontAwesomeIcon style={{...tableIconStyle, color:'#A91079'}} icon={faEye}/>
                                    </NavLink>
                                     <FontAwesomeIcon onClick={()=>{onActivityClick(item, item.expenseNo)}} style={{...tableIconStyle, color:'blue'}} icon={faBars}/>
                                </span>
                            </td>
                          </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</>
  )
}

export default ExpenseHistory

const PayAmmount =({mypayAmount, setmyPayamount})=>{
    const [myExData, setMyExData] = useState()
   const [changeData, setChangeData] = useState()
    function onTableDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        myExData.forEach((element, index) => {
            if (index === parseInt(name)) {
                if (parseFloat(value)>element.due) {
                    value = element.due
                    setChangeData({...changeData, [name]:value})
                }else{
                    setChangeData({...changeData, [name]:value})
                }
            }
        });

    }

 async   function onPayAmountClick() {
       let allTotal = 0
     let myPromise =  myExData.map(async (element, index) => {
            if (changeData[index]>0) {
                //console.log(element)
             
                allTotal += parseFloat(changeData[index])
            }
       });
       
       Promise.all(myPromise).then( async ()=>{
        if (allTotal>0) {
            let debitData = {
                identity: mypayAmount.id,
                particulars:'Expense',
                processorID:window.localStorage.getItem('user_id'),
                paymentAmmount:allTotal,
                remark:'',
                paymentType :'cash',
                created_date:new Date().getTime(),
            }

            let dayBookData = {
                created_date:new Date().getTime(),
                paticulars:"Expense",
                identity:mypayAmount.id,
                received:0,
                payment:allTotal,
                processorID:window.localStorage.getItem('user_id'),
            }

          
     
          
           }
        setmyPayamount()
        })
        //console.log(myExData)
        
    }
    const [newTotal, setNewTotal] = useState()
    function getAllpaiddata() {
      // console.log(changeData)
      let total = 0
      if (changeData) {
        Object?.keys(changeData).forEach(function(key) {
            // console.log(key, changeData[key]);
             total += changeData?.[key] ? parseFloat(changeData[key]):0
         });
      }
    setNewTotal(mypayAmount?.ammount - mypayAmount?.paymentAmmount -total)
   // return mypayAmount?.ammount - mypayAmount?.paymentAmmount -total
    }

    const [headData, setHeadData] = useState()


    return (
        <div>
            <table>
                <thead>
                    <tr>
                    <th>sl</th>
                    <th>Particular</th>
                    <th>Head</th>
                    <th>price</th>
                    <th>paid</th>
                    <th>Now Pay</th>
                    </tr>
                    {myExData?.map((item, index)=>{
                        return <tr>
                        <td>{index+1}</td>
                        <td>{item.particular}</td>
                        <td>{
                            headData?.filter((fitem)=>{
                                return fitem.id === item.expenseHeadID ? fitem:''
                            }).map((mitem)=>{
                                return mitem.headName
                            })}</td>
                        <td>{item.price}</td>
                        <td>{item.paymentAmmount}</td>
                        <td><input name={index} value={changeData?.[index]? changeData?.[index]:''} onChange={onTableDataChange}/></td>
                        </tr>
                    })}
                </thead>
            </table>
            
            <div>
                 <h3>Your Due Amount is <span style={{ fontWeight: 'bold', color: 'red' }}>{newTotal}</span></h3>
                <p style={{marginLeft:'50px'}}>Payment Type : {mypayAmount?.paymentType}</p>
            </div>
            <div>
                <button style={{ background: 'red' }} onClick={() => { setmyPayamount() }}>Cancel</button>
                <button onClick={onPayAmountClick}>Pay Now</button>
            </div>
        </div>
    )
}

const OrderActivities = ({ activityID, setActivityID }) => {
    const [activityTableData, setActivityTableData] = useState()
     async  function getActivityData() {
     
        // console.log(myData, activityID)
       
    }
    useEffect(()=>{
        getActivityData()
    },[])
    return (
        <>
            <div className={ExpenseHistoryCss.orderactivites}>
                <h3> Expense Activities</h3>

                <h4> Expense No : #{activityID.exno}</h4>
                <table>
                    <thead>
                    <tr>
                        <th>Sl</th>
                        <th>Activity</th>
                        <th>Entry Time</th>
                        <th>Processed By</th>
                        <th>Notes</th>
                    </tr>
                    {activityTableData?.map((item, index) => {
                        return <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.status}</td>
                            <td>{LocalTimeStampToDate(item.created_date)}</td>
                            <td>{item.attemptByID}</td>
                            <td>{item.note}</td>
                        </tr>
                    })}
                    </thead>
                </table>
                <button onClick={() => { setActivityID() }}>Close</button>
            </div>
        </>
    )
}