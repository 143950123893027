import { faBars, faEdit, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { LocalTimeStampToDate } from '../MyFunctions/MyFunctions'
import PendingExpenseCss from './css/PendingExpense.module.css'


const PendingExpense = () => {
    const [filterData, setFilterData] = useState()
    const [tableData, setTableData] = useState()

    async  function getDataFormDatabase() {   
        let myData = [] //await GetFilteredDataExpress('expense', 'pending', 1)
        setTableData(myData)
    }
    
    function onDataChange(params) {
        
    }
    function onSearchClick(params) {
        
    }
    const [activityID, setActivityID] = useState()
    function onActivityClick(item, mexNo) {
        setActivityID({id:item.id, exno:mexNo})
    }

    useEffect(() => {
        // getDataFormDatabase()
    },[])
    const tableIconStyle = {
        margin: '0',
        padding: '0',
        margin: '0 3px 0 3px',
        fontSize: '18px',
        cursor: 'pointer'
      }
  return (
    <>
    {activityID ? <OrderActivities activityID={activityID} setActivityID={setActivityID}/>:''}
    <section className={PendingExpenseCss.salesMain}>
        <div className={PendingExpenseCss.innerSale}>
            <h2 className={PendingExpenseCss.saleHeading}>Pending Expense</h2>
            <div className={PendingExpenseCss.search_section}>
                      <div className={PendingExpenseCss.input_section}>
                          <label>Start Date</label>
                          <input name='startDate' type={'date'} value={filterData?.startDate? filterData?.startDate:''} onChange={onDataChange}/>
                      </div>
                      <div className={PendingExpenseCss.input_section}>
                          <label>End Date</label>
                          <input name='endDate' type={'date'} value={filterData?.endDate ? filterData?.endDate:''} onChange={onDataChange}/>
                      </div>
                     
                     
                      
                  </div>
                  <div className={PendingExpenseCss.input_section}>
                      
                        <button onClick={onSearchClick}>search</button>
                          <button>reset</button>
                                              
                      </div>
            <div className={PendingExpenseCss.tableSection}>
                <table>
                    <tbody>
                        <tr>
                            <th>Sl</th>
                            <th>Date</th>
                            <th>Ex. No</th>
                            <th>Amount</th>
                            <th>Paid Amount</th>
                            <th>Due Amount</th>
                            <th>Payment Type</th>
                            <th>Process By</th>
                            <th>Action</th>
                        </tr>
                        {tableData?.map((item, index)=>{
                          return <tr key={index}>
                              <td>{index+1}</td>
                              <td>{LocalTimeStampToDate(item.created_date)}</td>
                              <td>{item.id}</td>
                              <td>{item.ammount}</td>
                              <td>{item.paymentAmmount}</td>
                              <td>{item.ammount-item.paymentAmmount}</td>
                              <td>{item.paymentType}</td>
                              <td>{item.processorID}</td>
                              <td>
                                <span style={{display:'flex'}}>
                                {/* <FontAwesomeIcon style={{...tableIconStyle, color:'green'}} icon={faEdit}/> */}
                                    <NavLink to={"/admin/expenses/pending_expense/expense_edit?exno="+item.id}>
                                        <FontAwesomeIcon style={{...tableIconStyle, color:'green'}} icon={faEdit}/>
                                    </NavLink>
                                    <NavLink to={"/admin/expenses/pending_expense/expense_view?exno="+item.id}>
                                        <FontAwesomeIcon style={{...tableIconStyle, color:'#A91079'}} icon={faEye}/>
                                    </NavLink>
                                     <FontAwesomeIcon onClick={()=>{onActivityClick(item, item.expenseNo)}} style={{...tableIconStyle, color:'blue'}} icon={faBars}/>
                                </span>
                            </td>
                          </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</>
  )
}

export default PendingExpense

const OrderActivities = ({ activityID, setActivityID }) => {

    const [activityTableData, setActivityTableData] = useState()
     async  function getActivityData() {
        let myData = [] //await GetFilteredDataExpress('expense_summery', 'expensID', activityID.id)
       // console.log(myData)
        setActivityTableData(myData)
    }
    useEffect(()=>{
        getActivityData()
    },[])
    return (
        <>
            <div className={PendingExpenseCss.orderactivites}>
                <h3> Expense Activities</h3>
                <h4> Expense No : #{activityID.id}</h4>
                <table>
                    <thead>
                    <tr>
                        <th>Sl</th>
                        <th>Activity</th>
                        <th>Entry Time</th>
                        <th>Processed By</th>
                        <th>Notes</th>
                    </tr>
                    {activityTableData?.map((item, index) => {
                        return <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td>{item.status}</td>
                            <td>{LocalTimeStampToDate(item.created_date)}</td>
                            <td>{item.attemptByID}</td>
                            <td>{item.note}</td>
                        </tr>
                    })}
                    </thead>
                </table>
                <button onClick={() => { setActivityID() }}>Close</button>
            </div>
        </>
    )
}