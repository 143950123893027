import React, { useEffect, useRef, useState } from 'react'
import assignCss from './css/assignproduct.module.css'
import Select from 'react-select'
import { BogoAssignAdd, BogoAssignGet, BogoGroupGet } from '../../../../api/OrderInformationApi'
import { useSelector } from 'react-redux'
import { selectProductList } from '../../../../ReduxFiles/Slices/ProductSlice'
import { toast } from 'react-toastify'
import { DateFormate } from '../../../../api/GlobalFunctions'

async function getData(setData) {
  const data = await BogoGroupGet()
  setData(data)
}

async function getBogoItems(bogo_group_id, setData) {
  const data = await BogoAssignGet(`bogo_group_id=${bogo_group_id}`)
  setData(data)
}
const AssignProducts = () => {
  const productList = useSelector(selectProductList)
  const [groupData, setGroupData] = useState([])
  const [itemData, setItemData] = useState([])
  const group_ref = useRef()
  const bogo_group_id = group_ref.current?.getValue()[0]?.bogo_group_id
  async function onAssignClick(e) {
    const mbogo_group_id = group_ref.current?.getValue()[0]?.bogo_group_id
      e.preventDefault()
      const  bogo_group_id= e.target.bogo_group_id.value
      const  product_id= e.target.product_id.value
      
      try {
        await BogoAssignAdd({bogo_group_id, product_id})
        toast.success('Product Assigned to Buy 1 Get 1!')
        if (mbogo_group_id) {
          getBogoItems(mbogo_group_id, setItemData)
        }
      } catch (error) {
          toast.error(error.message)
      }
  }
  async function onGroupChange(e) {
    if (e.bogo_group_id) {
      getBogoItems(e.bogo_group_id, setItemData)
    }
  }
  useEffect(()=>{
    getData(setGroupData)
  },[])

  useEffect(()=>{
    if (bogo_group_id) {
      getBogoItems(bogo_group_id, setItemData)
    }
  },[bogo_group_id])
  return (
    <div className={assignCss.wrapper}>
      <div className={assignCss.innerSale}>
      <form className={assignCss.my_form} onSubmit={onAssignClick}>
        <div className={assignCss.form_inner}>
          <label>Group Name</label>
          <Select
          options={groupData}
          getOptionLabel={(option)=>`${option.group_name}`}
          getOptionValue={(option)=>`${option.bogo_group_id}`} 
          name='bogo_group_id'
          className={assignCss.my_select}
          ref={group_ref}
          onChange={onGroupChange}
          required
          />
        </div>
        <div className={assignCss.form_inner}>
          <label>Product Name</label>
          <Select
          options={productList}
          getOptionLabel={(option)=>`${option.en_sort_title}`}
          getOptionValue={(option)=>`${option.id}`}
          name='product_id'
          className={assignCss.my_select}
          required
          />
        </div>
        <div className={assignCss.btn_group}>
            <button>Assign</button>
        </div>
      </form>
      <div className={assignCss.table_wrapper}>
          <table>
            <thead>
              <tr>
                <th>SL</th>
                <th>Product Name</th>
                <th>Group Name</th>
                <th>Start</th>
                <th>End</th>
              </tr>
            </thead>
            <tbody>
                {itemData.map((item, index)=>{
                  return <tr key={index}>
                    <td>{index+1}</td>
                    <td>{item.en_sort_title}</td>
                    <td>{item.group_name}</td>
                    <td>{DateFormate(item.start_date)}</td>
                    <td>{DateFormate(item.end_date)}</td>
                  </tr>
                })}
            </tbody>
          </table>
      </div>
      </div>
      
    </div>
  )
}

export default AssignProducts