import React, { useEffect, useState } from 'react'
import { GetSiteInformation, SaveSiteInformation, UpdateSiteInformation } from '../../api/SettingApi'
import WebsiteSettingCss from './css/WebsiteSetting.module.css'

const WebsiteSetting = () => {
    const [siteInfo, setSiteInfo] = useState()
    const [siteContact, setSiteContact] = useState()

    function onSiteInfoChange(e) {
        let name = e.target.name
        let value = e.target.value
        setSiteInfo({ ...siteInfo, [name]: value })
    }

    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        setSiteContact({ ...siteContact, [name]: value })
    }
    function onSiteInfoSave(e) {
        e.preventDefault()
        console.log(siteInfo)
    }
    async function onSitecontactSave() {
        if (siteContact.id) {
            await UpdateSiteInformation(siteContact.id, siteContact)
        } else {
            await SaveSiteInformation(siteContact)
        }
    }
    async function getContactData(params) {
        let contactData = await GetSiteInformation()
        setSiteContact(contactData[0])
    }
    useEffect(() => {
        getContactData()
    }, [])
    return (
        <div className={WebsiteSettingCss.salesMain}>
             <h2>Site Settings</h2>
            <section className={WebsiteSettingCss.innerSale}>
               
                <h6>Site Information</h6>
                <div className={WebsiteSettingCss.inputWrapperInformation}>
                    {/* name, labeltext, Itype, placeholder, mclass, changeme, myref, myValue, setedValue, enableDisable, warning */}
                   
                         <div className={WebsiteSettingCss.inputSection}>
                            <label>Site Title *</label>
                            <input  placeholder='Site Title Goes Here' name="site_title" type='text'  required/>
                         </div>
                       
                         <div className={WebsiteSettingCss.inputSection}>
                            <label>Upload Site Logo *</label>
                            <input name="site_logo" type='file' required/>
                         </div>

                         <div className={WebsiteSettingCss.inputSection}>
                            <label>Site Meta Tag *</label>
                            <input name="site_meta_tag" type='file' required/>
                         </div>

                         <div className={WebsiteSettingCss.inputSection}>
                            <label>Site Meta Description *</label>
                            <textarea id="site_meta_description" name="site_meta_description" rows="4" cols="50">
                            </textarea>

                         </div>
                         <div className={WebsiteSettingCss.inputSection}>
                            <label>Site Meta Tag Text Goes Here *</label>
                            <textarea id="site_meta_tag" name="site_meta_tag" rows="4" cols="50">
                            </textarea>

                         </div>

                         <div className={WebsiteSettingCss.inputSection}>
                            <label>Site Meta Description *</label>
                            <textarea id="site_meta_description" name="site_meta_description" rows="4" cols="50">
                            </textarea>
                         </div>
                </div>
                <div className={WebsiteSettingCss.buttonWrapper}>
                     <button className={WebsiteSettingCss.bt_save} type="submit" onClick={onSiteInfoSave}>Save</button>
                </div>

            </section>

            <section className={WebsiteSettingCss.innerSale}>

                <h6>Contact</h6>
            

                    <form className={WebsiteSettingCss.inputWrapper}>

                         <div className={WebsiteSettingCss.inputSection}>
                            <label>Email Address *</label>
                            <input type='text' defaultValue={siteContact?.email} required/>
                         </div>

                         <div className={WebsiteSettingCss.inputSection}>
                            <label>Primary Phone *</label>
                            <input type='text' defaultValue={siteContact?.primary_phone ? siteContact?.primary_phone : ''} required/>
                         </div>

                         <div className={WebsiteSettingCss.inputSection}>
                            <label>Alternative Phone *</label>
                            <input name="alternative_phoneb" type='text' defaultValue={siteContact?.alternative_phoneb ? siteContact?.alternative_phoneb : ''} required/>
                         </div>
                       
                         <div className={WebsiteSettingCss.inputSection}>
                            <label>Fax No. *</label>
                            <input name="fax_no" type='text' defaultValue={siteContact?.fax_no ? siteContact?.fax_no : ''} required/>
                         </div>

                         <div className={WebsiteSettingCss.inputSection}>
                            <label>Address Text Goes Here *</label>
                            <input name="address" type='text' defaultValue={siteContact?.address ? siteContact?.address : ''} required/>
                         </div>
                     
                       

                    </form>
                    <div className={WebsiteSettingCss.buttonWrapper}>
                             <button className={WebsiteSettingCss.bt_save} type="submit" onClick={onSiteInfoSave}>Save</button>
                        </div>
                      
            </section>

            <section className={WebsiteSettingCss.innerSale}>

                <h6>Social Links</h6>
             

                    <div className={WebsiteSettingCss.inputWrapper}>

                        <div className={WebsiteSettingCss.inputSection}>
                            <label>Facebook *</label>
                            <input name="facebook" type='text' defaultValue={siteContact?.facebook ? siteContact?.facebook : ''} required/>
                         </div>

                         <div className={WebsiteSettingCss.inputSection}>
                            <label>Youtube *</label>
                            <input name="youtube" type='text' defaultValue={siteContact?.youtube ? siteContact?.youtube : ''} required/>
                         </div>
                        
                         <div className={WebsiteSettingCss.inputSection}>
                            <label>Instagram *</label>
                            <input name="instagram" type='text' defaultValue={siteContact?.instagram ? siteContact?.instagram : ''} required/>
                         </div>

                         <div className={WebsiteSettingCss.inputSection}>
                            <label>Office Timing *</label>
                            <input name="office_timming" type='text' defaultValue={siteContact?.office_timming ? siteContact?.office_timming : ''} required/>
                         </div>      
                    </div>
                  
                    <div className={WebsiteSettingCss.buttonWrapper}>
                        <button className={WebsiteSettingCss.bt_save} type="submit" onClick={onSitecontactSave}>Update</button>

                        <button className={WebsiteSettingCss.bt_cancel}>Cancel</button>
                    </div>
              
            </section>

            
           
        </div>
    )
}

export default WebsiteSetting