import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { selectUserInfo } from '../../../ReduxFiles/Slices/UserSlice'
import { GetSingleProductVarianceName, SaveSummeryData, SaveTrackingData } from '../../../api/OrderInformationApi'
import { UpdateOrderData } from '../../../api/SalesApi'
import LoadingShow from '../../smallThings/LoadingShow'
import packagingCss from './packaging.module.css'

const PackagingPop = ({ myPackagingData, setshowPackagingBox, tableData }) => {
    const userIfno = useSelector(selectUserInfo)
    const [orders, setOrders] = useState([])
    const [showLoading, setShowLoading] = useState(false)
    const [disableButton, setDisableButton] = useState(false)
    async function onUpdateClick() {
        try {
            setShowLoading(true)
        let trackData = {
            sales_id: myPackagingData.id,
            created_date: new Date().getTime(),
            note: 'Your Order Packaging is Complete.',
        }
        await SaveTrackingData(trackData)
        await UpdateOrderData(myPackagingData.id, { status: "Packaging Complete", confirmed: 0, packaging: 1 })

        let summeryData = {
            activity: 'Proceed To packaging',
            sale_id: myPackagingData.id,
            ProssesedBy: userIfno.id,
            ForwardTo: "",
            deliveryDate: "",
            note: "",
            created_date: new Date().getTime()
        }
        await SaveSummeryData(summeryData)
        let findIndex = tableData.findIndex(obj => parseInt(obj.id) === parseInt(myPackagingData.id))
        tableData[findIndex] = {
            ...tableData[findIndex],
            status: "Packaging Complete", confirmed: 0, packaging: 1
        }
        setshowPackagingBox(false)
        document.body.style.overflow = 'auto'
        setShowLoading(false)
        } catch (error) {
            toast.error(error.message)
        }
    }


    function oncloseClick() {
        document.body.style.overflow = 'auto'
        setshowPackagingBox(false)
    }

    useEffect(() => {
        async function getOrderItems() {
            let myOrders = await GetSingleProductVarianceName({ id: myPackagingData.id })
            setOrders(myOrders)

           for (let index = 0; index < myOrders.length; index++) {
            const element = myOrders[index];
                if (element.hasVriance) {
                    if (parseInt(element.vrstock) < 0) {
                        setDisableButton(true)
                        break
                    }
                }else{
                    if ( parseInt(element.pstock) < 0) {
                        setDisableButton(true)
                        break
                    }
                }
            
           }
        }
        getOrderItems()
    }, [myPackagingData])
    return (
        <div className={packagingCss.wrapper}>
            <LoadingShow ShowLodign={showLoading} message={'Processing your data...'}/>
            <div className={packagingCss.holder}>
                <h3>Proceed To Packaging</h3>
               <div className={packagingCss.info_holder}>
                <div className={packagingCss.info_section}>
                    <p>Order No : # {myPackagingData?.id}</p>
                    <p>Customer Name : {myPackagingData?.name}</p>
                    <p>Phone : {myPackagingData?.aphone || myPackagingData.phone}</p>
                </div>
               <div className={packagingCss.table_wrapper}>
               <table>
                    <thead>
                        <tr>
                            <th>sl</th>
                            <th>Item</th>
                            <th>Variations</th>
                            <th>Quantity</th>
                            <th>Availabe Stock</th>
                        </tr>
                    </thead>
                    <tbody>
                    {orders?.map((item, index) => {
                            return <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{item.vrName}</td>
                                <td>{item.quantity}</td>
                                <td>{item.hasVriance ? item.vrstock:item.pstock}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
               </div>
               
                <div className={packagingCss.buttonsection}>
                    <button onClick={oncloseClick}>Close</button>
                    <button onClick={onUpdateClick} disabled={disableButton}>Update</button>
                </div>
               </div>
            </div>
        </div>
    )
}


export default PackagingPop