import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { selectProductList } from "../../ReduxFiles/Slices/ProductSlice";
import { GetDistrictList } from "../../api/Address/DistrictApi";
import { CheckExisitingUser, SaveCustomerData } from "../../api/CustomerApi";
import { GetFilterProductVariance } from "../../api/ProductVarianceApi";
import createCss from "./salesCss/createorder.module.css";
import {
  AddAddress,
  SaveDayBookData,
  SaveOrderData,
  SaveSalesItems,
  SaveSalesOfferItems,
  SaveSalesTracking,
} from "../../api/SalesApi";
import { useNavigate } from "react-router-dom";
import GobackButton from "../global/GobackButton";

async function getDistricts(setData) {
  const districts = await GetDistrictList();
  setData(districts);
}
export default function CreateOrder() {
  const [districtList, setDistrictList] = useState([]);
  const allProducts = useSelector(selectProductList);
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedVariance, setSelectedVariance] = useState();
  const [varianceList, setVarianceList] = useState([]);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [deliveryCharge, setdeliveryCharge] = useState(60);
  const districtRef = useRef();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  function onDistrictChange(e) {
    if (e?.district_id === 1) {
      setdeliveryCharge(60);
    } else {
      setdeliveryCharge(130);
    }
  }
  const productRef = useRef();
  async function onProductChange(e) {
    setSelectedProduct(e);
    if (e?.hasVariance) {
      const variance = await GetFilterProductVariance(`product_id=${e.id}`);
      setVarianceList(variance);
    } else {
      setVarianceList([]);
      setSelectedVariance();
    }
  }
  useEffect(() => {
    getDistricts(setDistrictList);
  }, []);

  function onAddProduct(e) {
    e.preventDefault();
    const addData = {
      en_sort_title: selectedProduct.en_sort_title,
      Variance_name: selectedVariance?.Variance_name || "",
      product_id: selectedProduct.id,
      variance_id: selectedVariance?.id || 0,
      hasVriance: selectedProduct.hasVariance || 0,
      quantity: 1,
      price: selectedProduct.Promo_price || selectedProduct.Selling_price,
      is_offer: selectedProduct.Promo_price ? 1 : 0,
      hasAge: selectedProduct.hasAge,
      age_id: selectedProduct.age_id || 0,
      tp_price:
        selectedProduct.Selling_price -
        (selectedProduct.Selling_price * 20) / 100,
      mrp_price: selectedProduct.Selling_price,
      End_date: selectedProduct.End_date,
      Start_date: selectedProduct.Start_date,
    };
    productRef.current.setValue(null);
    const tempArr = [...selectedProductList];
    if (selectedProduct.hasVariance) {
      const findItem = selectedProductList.filter(
        (fitem) =>
          fitem.product_id === selectedProduct.id &&
          fitem.variance_id === selectedVariance.id
      );
      if (findItem.length > 0) {
        toast.warn("Product Exist with same variance");
      } else {
        tempArr.push(addData);
        setSelectedVariance(null);
        setSelectedProduct();
      }
    } else {
      const findItem = selectedProductList.filter(
        (fitem) => fitem.product_id === selectedProduct.id
      );
      if (findItem.length > 0) {
        toast.warn("Product Exist");
      } else {
        tempArr.push(addData);
        setSelectedVariance(null);
        productRef.current.setValue(null);
        setSelectedProduct();
      }
    }
    setSelectedProductList(tempArr);
  }
  function onRemoveClick(item) {
    if (item.hasVriance) {
      const newList = selectedProductList.filter(
        (fitem) => fitem.variance_id !== item.variance_id
      );
      setSelectedProductList(newList);
    } else {
      const newList = selectedProductList.filter(
        (fitem) => fitem.product_id !== item.product_id
      );
      setSelectedProductList(newList);
    }
  }

  function onMinusClick(item) {
    const newList = selectedProductList.map((mitem) => {
      if (
        item.product_id === mitem.product_id &&
        item.variance_id === mitem.variance_id
      ) {
        mitem = {
          ...mitem,
          quantity: item.quantity - 1,
        };
      }
      return mitem;
    });
    setSelectedProductList(newList);
  }

  function onPlusClick(item) {
    const newList = selectedProductList.map((mitem) => {
      if (
        item.product_id === mitem.product_id &&
        item.variance_id === mitem.variance_id
      ) {
        mitem = {
          ...mitem,
          quantity: item.quantity + 1,
        };
      }
      return mitem;
    });
    setSelectedProductList(newList);
  }
  let sub_total = 0;
  let discount = 0;
  const myNavigation = useNavigate();

  async function onCreateOrder(e) {
    e.preventDefault();
    const c_name = e.target.c_name.value;
    const c_phone = e.target.c_phone.value;
    const address = e.target.address.value;
    const district = districtRef.current.getValue()[0];
    if (selectedProductList.length > 0) {
      setButtonDisabled(true);
      const userInfo = await checkUser(c_name, c_phone);
      const addressData = {
        Address: address,
        Phone: c_phone,
        Divition: district.division_name,
        District: district.district_name,
        Area: "no",
        deliveryCharge: deliveryCharge,
        active: 1,
        userID: userInfo.id,
        created_date: new Date().getTime(),
      };
      const addressResponse = await AddAddress({ ...addressData });
      const orderData = {
        userID: userInfo.id,
        grandTotal: sub_total - discount + deliveryCharge,
        deliveryCharge: deliveryCharge,
        pickupPoint: 0,
        paymentType: "cash",
        status: "Order Placed",
        created_date: new Date().getTime(),
        pending: 1,
        user_address_id: addressResponse.insertId,
      };
      const orderResponse = await SaveOrderData(orderData);
      await saveOrderItems(userInfo, orderResponse.insertId);
      setButtonDisabled(false);
      myNavigation("/sales/pending");
    } else {
      toast.warn("No Product Found.");
    }
  }
  async function saveOrderItems(userInfo, orderID) {
    let itemArray = [];
    let promoArray = [];
    selectedProductList.forEach((element) => {
      if (element.is_offer) {
        let offerData = {
          userID: userInfo.id,
          sales_id: orderID,
          product_id: element.product_id,
          promoPrice: element.price,
          original_price: element.mrp_price,
          quantity: element.quantity,
          End_date: element.End_date,
          Start_date: element.Start_date,
          created_date: new Date().getTime(),
          tp_price: element.tp_price,
          mrp_price: element.mrp_price,
        };
        promoArray.push(offerData);
      }
      let data = {
        userID: userInfo.id,
        sales_id: orderID,
        product_id: element.product_id,
        hasVriance: element.hasVriance,
        hasAge: element.hasAge,
        age_id: element.age_id,
        variance_id: element.variance_id,
        price: element.price,
        promo: element.is_offer,
        quantity: element.quantity,
        created_date: new Date().getTime(),
        tp_price: element.tp_price,
        mrp_price: element.mrp_price,
      };
      itemArray.push(data);
    });
    itemArray.map(async (element) => await SaveSalesItems(element));
    promoArray.map(async (element) => await SaveSalesOfferItems(element));
    let trackData = {
      sales_id: orderID,
      created_date: new Date().getTime(),
      note: "Your Order is Being Verified.",
    };
    await SaveSalesTracking(trackData);
    let dayBookData = {
      created_date: new Date().getTime(),
      paticulars: "Sales (order Placed)",
      identity: orderID,
      received: 0,
      payment: 0,
      processorID: userInfo.id,
    };
    await SaveDayBookData(dayBookData);
  }

  async function checkUser(name, phone) {
    const userData = await CheckExisitingUser(phone);
    let userInfo = {};
    if (userData.length > 0) {
      userInfo = {
        ...userData[0],
      };
    } else {
      const datatoSave = {
        name: name,
        phone: phone,
        password: "123456",
        created_date: new Date().getTime(),
      };
      let muserData = await SaveCustomerData(datatoSave);
      userInfo = {
        ...datatoSave,
        id: muserData.userInfo.insertId,
      };
    }
    return userInfo;
  }

  return (
    <div className={createCss.wrapper}>
      <div className={createCss.holder}>
        <GobackButton />
        <h2>Create New Order</h2>
        <form className={createCss.add_product} onSubmit={onAddProduct}>
          <Select
            options={allProducts}
            placeholder="select product here"
            className={createCss.my_select}
            getOptionLabel={(option) => `${option.en_sort_title}`}
            getOptionValue={(option) => `${option.id}`}
            onChange={(e) => onProductChange(e)}
            required
            isClearable
            ref={productRef}
          />
          {selectedProduct?.hasVariance ? (
            <Select
              options={varianceList}
              getOptionLabel={(option) => `${option.Variance_name}`}
              getOptionValue={(option) => `${option.id}`}
              placeholder="select variance"
              className={createCss.my_select}
              onChange={(e) => setSelectedVariance(e)}
              required
              isClearable
            />
          ) : (
            ""
          )}

          <button>Add Product</button>
        </form>
        <div className={createCss.table_wrapper}>
          <table>
            <thead>
              <tr>
                <th>SL</th>
                <th>Product Name</th>
                <th>Variance Name</th>
                <th>Age name</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {selectedProductList.map((item, index) => {
                sub_total += item.mrp_price;
                discount += item.mrp_price - item.price;
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.en_sort_title}</td>
                    <td>{item.Variance_name}</td>
                    <td>{item.age_name}</td>
                    <td>
                      {item.mrp_price} {item.price}
                    </td>
                    <td>
                      <div className={createCss.qty_button}>
                        <button onClick={() => onMinusClick(item)}>-</button>
                        {item.quantity}
                        <button onClick={() => onPlusClick(item)}>+</button>
                      </div>
                    </td>
                    <td>{item.mrp_price * item.quantity}</td>
                    <td>
                      <button onClick={() => onRemoveClick(item)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan={5}></td>
                <td>Sub Total</td>
                <td>{sub_total}</td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={5}></td>
                <td>Discount</td>
                <td>{discount}</td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={5}></td>
                <td>Delivery Charge</td>
                <td>{deliveryCharge}</td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={5}></td>
                <td>Grand Total</td>
                <td>{sub_total - discount + deliveryCharge}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <form className={createCss.customer_info} onSubmit={onCreateOrder}>
          <div className={createCss.info_common}>
            <label>Name</label>
            <input name="c_name" type="text" placeholder="name" required />
          </div>
          <div className={createCss.info_common}>
            <label>Phone</label>
            <input name="c_phone" type="number" placeholder="phone" required />
          </div>
          <div className={createCss.info_common}>
            <label>Address</label>
            <input name="address" type="text" placeholder="address" required />
          </div>
          <div className={createCss.info_common}>
            <label>District</label>
            <Select
              ref={districtRef}
              options={districtList}
              getOptionLabel={(option) => `${option.district_name}`}
              getOptionValue={(option) => `${option.district_id}`}
              isClearable
              required
              onChange={onDistrictChange}
              menuPlacement="top"
            />
          </div>
          <div className={createCss.button_section}>
            <button disabled={buttonDisabled}>Create order</button>
          </div>
        </form>
      </div>
    </div>
  );
}
