import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import BankPaymentReceivePDF from './BankPaymentReceivePDF'
import BankPaymentReceiveCss from './css/BankPaymentReceive.module.css'
const BankPaymentReceive = () => {
      function getColor() {
        let leftPart = document.querySelector('.sidepart')
        leftPart?.classList.remove('display-none')
    }
    useEffect(()=>{
        getColor()
    },[])
    return (
        <>
            <Routes>
                <Route path='/' element={<MainComp/>}/>
                <Route path='/bank_payment_receive_view' element={<BankPaymentReceivePDF/>}/>
            </Routes>
        </>
    )
}

export default BankPaymentReceive

const MainComp = () => {
    const [SearchData, setSearchData] = useState()
    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        if (name==='time_selection') {
            setSearchData({[name]:value})
        }else{
            setSearchData({...SearchData, [name]:value})
        }
    }

    function onSearchClick() {
        let staticLink = '/admin/report/bank_payment_receive/bank_payment_receive_view'
        let queryString = ''
        if (SearchData?.time_selection === 'custom_range') {
            if (SearchData?.start_date && SearchData?.end_date) {
                queryString = `/?start_date=${SearchData.start_date}&end_date=${SearchData.end_date}`
                window.open(staticLink + queryString, '_blank')
            } else {
                alert('Please Select Date Range to Continue')
            }
        } else {
            if (SearchData?.groupid) {
                queryString = `/?group=${SearchData.groupid}`
                window.open(staticLink + queryString, '_blank')
            } else {
                window.open(staticLink, '_blank')
            }

        }
    }

    return <>
     <section className={BankPaymentReceiveCss.heading}>
                <div className={BankPaymentReceiveCss.inner_headign}>
                    <h2 className={BankPaymentReceiveCss.headign_h2}>Bank Payment & Received</h2>
        <div className={BankPaymentReceiveCss.input_section2}>
            <div className={BankPaymentReceiveCss.common}>
                <select name={BankPaymentReceiveCss.time_selection} onChange={onDataChange}>
                    <option value={'all-time'}>All Time</option>
                    <option value={'custom_range'}>Custom Range</option>
                </select>                
            </div>
        </div>
        {SearchData?.time_selection ==='custom_range' ? 
        <><div className={`${BankPaymentReceiveCss.input_section} ${BankPaymentReceiveCss.date_section}`}>
        <div className={BankPaymentReceiveCss.common}>
            <label>Start Date</label>
            <input name='start_date' type='date' onChange={onDataChange} />
        </div>
        <div className={BankPaymentReceiveCss.common}>
            <label>End Date</label>
            <input name='end_date' type='date' onChange={onDataChange} />
        </div>
    </div></> : 
        ''}
        <div className={BankPaymentReceiveCss.input_section}>
            <div className={BankPaymentReceiveCss.common}>
                <button className={BankPaymentReceiveCss.report_button} onClick={onSearchClick}>Search</button>
            </div>
        </div>
        </div>
            </section>
    </>
}
