import React, { useEffect, useState } from 'react'
import { FaBars, FaInfoCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { GetDeclineSalesData } from '../../api/SalesApi'
import GobackButton from '../global/GobackButton'
import SalesSearchComp from './SalesSearchComp'
import ActivityViewModel from './models/ActivityViewModel'
import DecOrder from './salesCss/DeclinedOrders.module.css'

const DeclinedOrders = ({ mtableData }) => {
    const [tableData, setTableData] = useState(mtableData || [])
    const [activityView, setActivityView] = useState(false)
    async function activityViewClick(e, item) {
        setActivityView(item)
        document.body.style.overflow = 'hidden'
    }
    function convertDate(time) {
        const dateInMillis = time
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }
    async function getDataFormDatabase() {
        let declineData = await GetDeclineSalesData()
        setTableData(declineData)
    }
    useEffect(() => {
        getDataFormDatabase()
    }, [mtableData])


    return (
        <section className={DecOrder.wrapper}>
            {activityView ? <ActivityViewModel popUp={activityView} setPopUp={setActivityView}/> : ""}
                <div className={DecOrder.holder}>
                    
                    <div className={DecOrder.head}>
                        <GobackButton/>
                    <h2 className={DecOrder.saleHeading}>Declined Orders</h2>
                    </div>
                    <div className={DecOrder.search_holder}>
                    <SalesSearchComp passedFrom={'declined'} setFilteredData={setTableData} />
                    </div>
                    
                    <div className={DecOrder.table_wrapper}>
                        <table>
                            <tbody>
                                <tr>
                                    <th>id</th>
                                    <th>Date</th>
                                    <th>Customer Name</th>
                                    <th>Customer Phone</th>
                                    <th>Customer Address</th>
                                    <th>Total Ammount</th>
                                    <th>Action</th>
                                </tr>
                                {tableData.map((item, index)=>{
                                    return <tr key={index}>
                                        <td>{`#${item.id}`}</td>
                                        <td>{convertDate(item.created_date)}</td>
                                        <td>{item.name}</td>
                                        <td>{item.phone || item.aphone}</td>
                                        <td>{item.address}</td>
                                        <td>{item.grandTotal}</td>
                                        <td>
                                            <div className={DecOrder.action_buttons}>
                                               <Link to={`/sales/declinedetails/${item.id}`}><FaInfoCircle/></Link>
                                               <Link to={""}>    <FaBars onClick={(e) => { activityViewClick(e, item)}}/>
                                               </Link>                                  
                                            </div>
                                        </td>
                                    </tr>
                                })}
                               
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
    )
}

export default DeclinedOrders
