import { useNavigate } from 'react-router-dom'
// import bcrypt from 'bcrypt/bcrypt'
import { toast } from 'react-toastify'
import { SaveCustomerDataFromBack } from '../../api/CustomerApi'
import AddnewCustomerCss from './css/AddnewCustomer.module.css'


const AddnewCustomer = () => {
  const navigate = useNavigate();

  async function FormSubmit(e) {
    e.preventDefault();
    const name = e.target.name.value
    const phone = e.target.phone.value
    const email = e.target.email.value
    const password = e.target.password.value
    const confirmpassword = e.target.confirmpassword.value
    let okData = {
      name: name,
      phone: phone,
      email: email,
      password: password,
      created_date: new Date().getTime()
    }
    
    if (password === confirmpassword) {
      let responseData = await SaveCustomerDataFromBack(okData)
        if (responseData.userFound) {
          toast.warn(responseData.message)
        } else {
          toast.success(responseData.message)
          navigate('/admin/manage_customer/customerlist')
        }
    }else{
      toast.warn('Password Mismatch! Check your Password!')
    }
   
  }

  return (
    <div className={AddnewCustomerCss.salesMain}>
      <h2>Create New User</h2>
      <div className={AddnewCustomerCss.innerSale}>
      <form onSubmit={FormSubmit} className={AddnewCustomerCss.my_form}>
        <div className={AddnewCustomerCss.inner}>
            <label>Customer Name</label> :
            <input type='text' name='name' placeholder='Enter Full Name Here' required />
          </div>
          <div className={AddnewCustomerCss.inner}>
            <label>Customer Phone Number</label> :
            <input type='number' name='phone' placeholder='Enter Phone Number' pattern='01[0-9]{9}' title='Enter 11 digits' required />

          </div>
        <div className={AddnewCustomerCss.inner}>
          <label>Customer Email</label> :
          <input type='text' name='email' placeholder='Enter Email Here' />
        </div>
        <div className={AddnewCustomerCss.inner}>
          <label>Password</label> :
          <input type='password' name='password' placeholder='Enter Password Here' required />
        </div>
        <div className={AddnewCustomerCss.inner}>
          <label>Confirm Password</label> :
          <input type='password' name='confirmpassword' placeholder='Enter Password Here' required/>
        </div>
         
      </form>
      <div className={AddnewCustomerCss.btn_group}>
           <button>Create Customer</button>
        </div>
      </div>
     

    </div>
  )
}

export default AddnewCustomer
