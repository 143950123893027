import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { fetchInitCustomer, fetchSearchedCustomer, selectCustomerStatus, selectInitCustomer } from '../../../ReduxFiles/Slices/CustomerSlice'
import { InformationMessage } from '../../../api/SalesApi'
import crmCss from './crm.module.css'
import { RiDeleteBin6Line } from "react-icons/ri";
const CrmMain = () => {
  // const [allCustomer, setAllCustomer] = useState()
  const [addedNumber, setAddedNumber] = useState([])
  const [inputedNumber, setInputedNumber] = useState({})
  const [messageText, setMessageText] = useState()
  const [characterCount, setCharacterCount] = useState(0)
  const [messageCount, setMessageCount] = useState(0)
  const dispatch = useDispatch()
  const customerStatus = useSelector(selectCustomerStatus)
  const allCustomer = useSelector(selectInitCustomer)



useEffect(() => {
      if (customerStatus === 'idle') {
          dispatch(fetchInitCustomer(10))
      }
  }, [])

  async function getAllCustomer() {
  //  console.log(1)
    let mAllCustomer = [] //await GetAllDataExpress('user')
    // setAllCustomer(mAllCustomer)
    // console.log(mAllCustomer)
  }
  function onAddClick(item) {
    setAddedNumber([item,...addedNumber])
  }
  function onRemoveClick(item, mindex) {
    let tempArr = []
   // console.log(mindex)
    addedNumber.forEach((element, index) => {
      if (mindex!==index) {
        tempArr.push(element)
      }
    });
    setAddedNumber(tempArr)
  }
  function addToList() {
    if (inputedNumber) {
      setAddedNumber([inputedNumber,...addedNumber])
      setInputedNumber()
    }else{
      alert('Write a number to add')
    }
   
  }
  function onSendClick() {
    // console.log(messageText) %0a 
    if (messageText) {
      let stringArr = messageText.split('\n')
      let finalText = ''
      stringArr.forEach(element => {
        finalText += element + '%0a'
      });
      // console.log(finalText)
      if (addedNumber.length > 0) {
        // await SendSMSFromRobi(element.phone, finalText)
        addedNumber.forEach(async (element) => {
          try {
            await InformationMessage({ phone: `88${element.phone}`, message: finalText })
          } catch (error) {

          }
        });
        setAddedNumber([])
        setMessageText()
      }else{
        alert('Please add at list one number to send SMS')
      }
    } else {
      alert('You cannot send blank SMS')
    }

  }
  function wordCount() {
    //per sms 160 character
    let count = 0
    for (let index = 0; index < messageText?.length; index++) {
      // const element = array[index];
      count++
    }
   if (count>0) {
    let smsNumber = Math.floor(count/161)
    setMessageCount(smsNumber+1)
   }else{
    setMessageCount(0)
   }
    setCharacterCount(count)
   
  }
  useEffect(()=>{
    wordCount()
  },[messageText])

  useEffect(() => {
    getAllCustomer()
   
  }, [])

  function onCustomerChange(data) {
   // console.log(data.phone);
     setInputedNumber(data)
    // setSearchDate({ ...searchDate, customer_id: data.id })
   }
   const onInputDataChange = (inputValue, { action }) => {
       if (action === 'input-change') {
          if (inputValue.length >=3) {
               dispatch(fetchSearchedCustomer(inputValue))
          }

          setInputedNumber({phone:inputValue})
       }
     };
  return (
    <div className={crmCss.salesMain}>
       <h2>Send Mesage</h2>
      <div className={crmCss.innerSale}>
        <div className={crmCss.add_number}>
          <label>Contact List :</label>
          <div className={crmCss.select}>
            <Select  options={allCustomer} getOptionLabel={(option)=> `${option.name} - ${option.phone}`} getOptionValue={(option)=>option.id} onChange={onCustomerChange} onInputChange={onInputDataChange}/>
          </div>     
          <button onClick={addToList}>Add to List</button>
        </div>
      
        <div>
          <div className={crmCss.tableSection}>
            <table>
              <thead>
              <tr>
                <th>SL</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
                {addedNumber?.map((item, index) => {
                  return <tr key={index+10000}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.phone}</td>
                    <td><RiDeleteBin6Line  onClick={()=>{onRemoveClick(item, index)}} /></td>
                  </tr>
                })}

              </tbody>

            </table>
          </div>
        </div>


        <div className={crmCss.messagebox}>
          <textarea rows="8" cols="45" placeholder='write your message here' onChange={(e)=>{setMessageText(e.target.value)}} value={messageText ? messageText:''}></textarea>
          <div className={crmCss.action_wrapper}>
            <div className={crmCss.count_section}>
              <p>Character {characterCount}</p>
             
              <p>Message {messageCount}</p>
             
            </div>
              <button onClick={onSendClick}>send</button>
          </div>
        </div>
      


      </div>
   
    </div>
  )
}

export default CrmMain