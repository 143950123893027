import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { OrderMethodColors, OrderMethodObject } from "../global/GlobalConstant";
ChartJS.register(ArcElement, Tooltip, Legend);
export default function PieChart({ pidata }) {
  const data = {
    labels: pidata?.map(
      (item) => OrderMethodObject[item.method_code] || "Not Assigned"
    ),
    datasets: [
      {
        data: pidata?.map((item) => item.source_total),
        backgroundColor: pidata?.map(
          (item) => OrderMethodColors[item.method_code] || "#9c9e9f"
        ),
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{ width: "420px", height: "100%" }}>
      <Pie
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "left",
            },
          },
        }}
      />
    </div>
  );
}
