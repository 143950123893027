import { useEffect, useState } from 'react'
import AddCss from './addvariance.module.css'
import Select from 'react-select'
import {toast} from 'react-toastify'
import { selectAttributeList } from '../../../../../ReduxFiles/Slices/MAU_Slice'
import { useSelector } from 'react-redux'
import { selectCategoryList } from '../../../../../ReduxFiles/Slices/CategorySlice'
import { GetVarianceImage, UpdateProductVariance } from '../../../../../api/ProductVarianceApi'

const EditVariance = ({popUp, setPopUp, mainitem}) => {
    const [fackUrls, setFackUrls] = useState({})
    const [varianceOp, setVarianceOp] = useState([])
    const allAttributes = useSelector(selectAttributeList)
    const categoryList = useSelector(selectCategoryList)
   async function addVarianceClick(e) {
        e.preventDefault(e)
        const form = e.target
        const Variance_name = form.Variance_name.value
        const selectedVarUnit = form.selectedVarUnit.value
        const MainIamge = form.MainIamge.files[0]
        const ShadeIamge = form.ShadeIamge.files[0]
        let field = {
            ...popUp,
            Variance_name: Variance_name,
            attr_value: selectedVarUnit,
        }
        delete field.id
        delete field.product_id
        delete field.status
        delete field.serializer
        delete field.created_date
        try {
           await UpdateProductVariance(popUp.id, field, MainIamge, ShadeIamge )
            toast.success('Variance Updated Successfully')
             setPopUp()
        } catch (error) {
            toast.success(error.message)
        }
    }
    function onImageChange(e) {
        const mImage = e.target.files[0]
        CheckRatio(mImage, (isImageOK)=>{
            if (!isImageOK) {
                e.target.value = null
                ImageShow(null, e.target.name)
            }else{
                ImageShow(mImage, e.target.name)
            }
        })
      
    }
    function ImageShow(mimage, name) {
        if (mimage) {
            let myurl = URL.createObjectURL(mimage)
            setFackUrls({...fackUrls, [name]:myurl})
        }else{
            setFackUrls({...fackUrls, [name]:''})
        }
        
    }

    function CheckRatio(imageFile, callback) {
        let isImageOK = false
        let showImagediv = document.getElementById('image_hide')
        const img = document.createElement('img');
        const objectURL = URL.createObjectURL(imageFile);
        img.onload = function handleLoad() {
            // console.log(`Width: ${img.width}, Height: ${img.height}`);
      
            if (img.width === img.height) {
             toast.success('Image Size is ok')
             isImageOK = true
            }else{
                toast.warn('Image Aspect Ration is not ok. Please Select 1:1 Image')
            }
            URL.revokeObjectURL(objectURL);
            callback(isImageOK)
          };
          img.src = objectURL;
          showImagediv.appendChild(img);
    }
    
    const onKeyEvent = (e) => {
        if (e.keyCode===27) {
            setPopUp();
        }
      };

      useEffect(() => {
        document.addEventListener('keyup', onKeyEvent);
        return () => {
          document.removeEventListener('keyup', onKeyEvent);
        };
      }, []);

      async function getSelectedData() {
        let tempArr = []
        // console.log(categoryList, popUp);
        let attribute_id = null
        for (let index = 0; index < categoryList.length; index++) {
            const element = categoryList[index];
            if (mainitem.selected_catagory_id===element.id) {
                attribute_id = element.SelectedAttribute
                break
            }    
                
        }  
        allAttributes.forEach(element => {
            if (element.attr_name_id === attribute_id) {
                tempArr.push(element)
            }
        });
         setVarianceOp(tempArr)
    }
    useEffect(() => {
        getSelectedData() 
    }, [popUp])

    return ( 
        <div className={AddCss.wrapper} >
            <div className={AddCss.holder}>
                <h4>Edit Variance ({mainitem.en_sort_title})</h4>
                <form onSubmit={addVarianceClick} >
              <div className={AddCss.input_wrapper}>
                    <div className={AddCss.common_att}>
                        <label>Variance Name *</label>
                        <input name='Variance_name' type='text' required defaultValue={popUp.Variance_name}/>
                    </div>
                    <div className={AddCss.common_att}>
                        <label>Attribute Value </label>
                        <Select
                        options={varianceOp}
                        getOptionLabel={(option)=>`${option.attr_value_name}`}
                        getOptionValue={(option)=>`${option.id}`}
                        isClearable
                        name='selectedVarUnit'
                        className={AddCss.m_select}
                        placeholder={popUp.attr_value ? popUp.attr_value:'Select...'}
                        />
                    </div>
                    <div className={AddCss.common_att}>
                        <label className="additemlabel">Product Image *</label>
                        <div className={AddCss.fake_image}>
                        <input name="MainIamge"  type={"file"} multiple ={false} accept='image/webp' onChange={onImageChange}/>
                            {fackUrls.MainIamge && <img src={fackUrls.MainIamge} alt='fake image'/>}
                            {popUp.MainIamge && <ProductVarianceImageShow name={popUp.MainIamge}/>}
                        </div>
                    </div>
                    <div className={AddCss.common_att}>
                        <label className="additemlabel">Shade Image *</label>
                       
                        <div className={AddCss.fake_image}>
                        <input name="ShadeIamge"  type={"file"} multiple= {false} accept='image/webp' onChange={onImageChange}/>
                            {fackUrls.ShadeIamge && <img src={fackUrls.ShadeIamge} alt='fake image'/>}
                            {popUp.ShadeIamge && <ProductVarianceImageShow name={popUp.ShadeIamge}/>}
                        </div>
                    </div>
                    <div className={AddCss.att_buttons}>
                        <button type='submit'>Add Variance</button>
                        <button type='button' onClick={()=>setPopUp()}>Close</button>
                    </div>
                </div>
                
              </form>
            </div>
            <div id='image_hide' className={AddCss.image_hide}>
            </div>
        </div>
    )
}

export default EditVariance


const ProductVarianceImageShow = ({name}) => {
    const imgStyle = {
        width: "100px",
        height: "auto"
    }

    const [image, setImage] = useState()
    
    async function getLink() {
       // console.log(name);
        if (name) {
            let imageInfo =  await GetVarianceImage({image_name:name})
           // console.log(imageInfo);
            if (imageInfo.imageExist) {
                setImage(imageInfo.url)
            }else{
                setImage('')
            }
        }else{
            setImage('')
        }
    }
    useEffect(() => {
        getLink()
    }, [name])
    return (
        <> {image ? <img src={image} alt={name} />:''}</>
    )
}