import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { selectUserInfo } from "../../ReduxFiles/Slices/UserSlice";
import {
  GetSingleCustomer,
  GetSingleCustomerOrderAddress,
} from "../../api/CustomerApi";
import { SaveSummeryData } from "../../api/OrderInformationApi";
import {
  GetDetailsOrderItems,
  GetSingleOrder,
  InformationMessage,
  UpdateOrderData,
} from "../../api/SalesApi";
import ConfirmAlert from "../global/ConfirmAlert";
import GobackButton from "../global/GobackButton";
import selesDeViewCss from "./salesCss/SalesDetailView.module.css";
const SalesDetailView = () => {
  const userInfo = useSelector(selectUserInfo);
  const myParams = useParams();
  const [tableData, setTableData] = useState([]);
  const [orderData, setOrderData] = useState();
  const [conShow, setConShow] = useState(false);

  function onPrintInvoice() {
    let okdata = {};
    okdata = { ...okdata, tableData, ...orderData };
    localStorage.setItem("invoiceData", JSON.stringify(okdata));
  }
  function convertDate(time) {
    const dateInMillis = time;
    const date = new Date(dateInMillis);
    let myDate = date.toLocaleDateString();
    let myTime = date.toLocaleTimeString();
    myDate = myDate.replaceAll("/", "-");
    return myDate + " " + myTime;
  }

  function organizeData(myData) {
    let finalArr = [];
    let uniqueElements = myData?.slice();
    for (let index = 0; index < myData.length; index++) {
      const element = myData[index];
      // console.log(element)
      let tempArr = [];
      for (let mindex = 0; mindex < uniqueElements.length; mindex++) {
        const melement = uniqueElements[mindex];
        if (melement.product_id === element.product_id) {
          tempArr.push(melement);
        }
      }

      if (tempArr.length > 0) {
        tempArr.forEach((element) => {
          uniqueElements.forEach((melement, mindex) => {
            if (melement.id === element.id) {
              uniqueElements.splice(mindex, 1);
            }
          });
        });

        finalArr.push(tempArr);
      }
    }
    setTableData(finalArr);
  }

  useEffect(() => {
    async function getAllData() {
      let myData = await GetSingleOrder(myParams.orderid);

      let myCustomer = await GetSingleCustomer(myData[0]?.userID);
      let userAddress = await GetSingleCustomerOrderAddress(
        myData[0]?.user_address_id
      );
      let getSSLdata = [];
      let finalData = {
        ...myData[0],
        order_created: myData[0].created_date,
        ...userAddress[0],
        ...myCustomer[0],
        ...getSSLdata[0],
        deliveryCharge: myData[0].deliveryCharge,
        id: myData[0].id,
      };
      // console.log(finalData)

      setOrderData(finalData);
      let morderItems = await GetDetailsOrderItems({ id: myParams.orderid });
      organizeData(morderItems);
    }
    getAllData();
  }, [myParams.orderid]);

  function getQTotal(item) {
    let total = 0;
    item?.forEach((element) => {
      total += element.quantity - parseInt(element.rquantity);
    });

    return total;
  }
  const [extainfo, setExtrainfo] = useState({});

  useEffect(() => {
    function getAllTotal() {
      let total = 0;
      let saleTotal = 0;
      tableData?.forEach((item) => {
        item.forEach((element) => {
          total += element.quantity * element.orPrice - element.rtotal;
          saleTotal += element.quantity * element.price - element.rtotal;
        });
      });
      let discount =
        total - saleTotal + (total * orderData?.discountPercent) / 100;
      setExtrainfo({ total, saleTotal, discount });
    }
    getAllTotal();
  }, [tableData, orderData]);

  function totalAmmount(item) {
    let quantity = getQTotal(item);
    let total = 0;
    total += item[0].orPrice;
    return total * quantity;
  }
  function onDeclineClick() {
    setConShow(true);
    document.body.style.overflow = "hidden";
  }
  const myNavigate = useNavigate();
  async function clickYes() {
    await UpdateOrderData(myParams.orderid, {
      declined: 1,
      confirmed: 0,
      packaging: 0,
    });
    let summeryData = {
      ProssesedBy: userInfo.id,
      sale_id: myParams.orderid,
      created_date: new Date().getTime(),
      activity: "Order Declined",
    };
    await SaveSummeryData(summeryData);
    let myoredermessage = `Dear Sir, Your order -${myParams.orderid} has been canceled.%0aThank You for shopping with us.%0aAnayase`;
    try {
      let mPhone = orderData.Phone ? orderData.Phone : orderData.phone;
      await InformationMessage({
        phone: `88${mPhone}`,
        message: myoredermessage,
      });
    } catch (error) {
      toast.error(error.message);
    }
    setConShow(false);
    myNavigate("/admin/sales/decline_orders");
    document.body.style.overflow = "auto";
  }

  function clickNo() {
    setConShow(false);
    document.body.style.overflow = "auto";
  }
  function getVarianceList(item) {
    let variations = "";
    item.forEach((element) => {
      variations += element.Variance_name ? element.Variance_name + ", " : "";
    });
    return variations;
  }
  function getAgeList(item) {
    let age = "";
    item.forEach((element) => {
      age += element.attr_value_name ? element.attr_value_name + ", " : "";
    });
    return age;
  }

  return (
    <div className={selesDeViewCss.wrapper}>
      {conShow ? (
        <ConfirmAlert
          message="Do you want to decline this order?"
          yesClick={clickYes}
          noClick={clickNo}
        />
      ) : (
        ""
      )}
      <section className={selesDeViewCss.holder}>
        <div className={selesDeViewCss.head}>
          <GobackButton />
          <h2 className={selesDeViewCss.saleHeading}>Order History Details</h2>
        </div>
        <div className={selesDeViewCss.invoice_info}>
          <p>
            <strong>Invoice #</strong>
            {orderData?.id}
          </p>
          <p>
            <strong>Note </strong> : {orderData?.approval_note}
          </p>
          <p>
            <strong>Priority </strong> : {orderData?.priority}
          </p>
          <p>
            <strong>D. Date </strong> : {orderData?.delivery_date}
          </p>
          <p>
            <strong>S. Note </strong> : {orderData?.special_instruction}
          </p>
        </div>
        <div className={selesDeViewCss.information}>
          <div className={selesDeViewCss.customerDetails}>
            <p className={selesDeViewCss.highlighter}>Customer Details</p>
            <p>
              <strong>Customer Name</strong> : {orderData?.name}
            </p>
            <p>
              <strong>Phone</strong> : {orderData?.Phone}
            </p>
            <div className={selesDeViewCss.address}>
              <p>
                <strong>Division</strong> :{orderData?.Divition}
              </p>
              <p>
                <strong>District</strong> : {orderData?.District}
              </p>
              <p>
                <strong>Area</strong> : {orderData?.Area}
              </p>
            </div>
            <div className={selesDeViewCss.addressSec}>
              <p>
                <strong>Details Address</strong> : {orderData?.Address}
              </p>
            </div>
          </div>
          <div className={selesDeViewCss.orderDetails}>
            <p className={selesDeViewCss.highlighter}>Order Details</p>
            <p>Order Date : {convertDate(orderData?.order_created)} </p>
            <p>Payment Type : {orderData?.paymentType}</p>
          </div>
        </div>

        <div className={selesDeViewCss.table_wrapper}>
          <table>
            <tbody>
              <tr>
                <th>Item</th>
                <th>Variance</th>
                <th>age</th>
                <th>Price</th>
                {/* <th>Variations</th> */}
                <th>Quantity</th>
                <th>Total Ammount</th>
              </tr>
              {/* {TableItem} */}
              {tableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {item[0].mainName ? item[0]?.mainName : item[0].name}
                    </td>
                    <td>{getVarianceList(item)}</td>
                    <td>{getAgeList(item)}</td>
                    <td>{item[0]?.orPrice}</td>
                    <td>{getQTotal(item)}</td>
                    <td>{totalAmmount(item)}</td>
                  </tr>
                );
              })}

              <tr className={selesDeViewCss.last_tr}>
                <td colSpan={5}>Total Ammount :</td>
                <td>{extainfo.total?.toFixed(2)} tk</td>
              </tr>
              <tr className={selesDeViewCss.last_tr}>
                <td colSpan={5}>Discount :</td>
                <td>{extainfo.discount?.toFixed(2)} tk</td>
              </tr>
              <tr className={selesDeViewCss.last_tr}>
                <td colSpan={5}>Delivery Charge :</td>
                <td>{orderData?.deliveryCharge.toFixed(2)} tk</td>
              </tr>
              <tr className={selesDeViewCss.last_tr}>
                <td colSpan={5}>Grand Total :</td>
                <td>
                  {(
                    extainfo.total -
                    extainfo.discount +
                    orderData?.deliveryCharge
                  ).toFixed(2)}{" "}
                  tk
                </td>
              </tr>
              <tr className={selesDeViewCss.last_tr}>
                <td colSpan={5}>Collected :</td>
                <td>{(orderData?.totalCollection || 0).toFixed(2)} TK</td>
              </tr>
            </tbody>
          </table>

          <div className={selesDeViewCss.button_group}>
            {orderData?.packaging ? (
              ""
            ) : (
              <button onClick={onDeclineClick}>Decline</button>
            )}
            <button>Print Chalan Copy</button>
            <button>
              <Link
                to={`/sales/salesdetails/${orderData?.id}/invoice`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onPrintInvoice}
              >
                Print Invoice
              </Link>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SalesDetailView;
