import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { DateFormate } from '../../../../api/GlobalFunctions'
import { BogoGroupAdd, BogoGroupGet } from '../../../../api/OrderInformationApi'
import groupCss from './css/grouplist.module.css'
import BogoListEditpop from './models/BogoListEditpop'
async function getData(setData) {
  const data = await BogoGroupGet()
  setData(data)
}
const GroupList = () => {
  const [tableData, setTableData] = useState([])
 async function onAddGroup(e) {
    e.preventDefault()
    const group_name = e.target.group_name.value
    const start_date = e.target.start_date.value
    const end_date = e.target.end_date.value
    try {
        await BogoGroupAdd({group_name, start_date, end_date})
        toast.success('Buy 1 Get 1 Group Added!')
        getData(setTableData)
        e.target.reset()
    } catch (error) {
      toast.error(error.message)
    }
  }
  const [editItem, setEditItem] = useState()
  function onEditClick(item) {
    setEditItem(item);
    document.body.style.overflow = 'hidden'
  }


  useEffect(()=>{
    if (!editItem) {
      getData(setTableData)
    }
  },[editItem])

  

  return (
    <div className={groupCss.salesMain}>
        <div className={groupCss.innerSale}>
        <h2>Add BOGO Group</h2>
           
            {editItem && <BogoListEditpop popUp={editItem} setPopUp= {setEditItem}/>}
            
            <form className={groupCss.searchSection} onSubmit={onAddGroup}>
                <div className={groupCss.input_section}>
                    <label>Group Name</label>
                    <input type='text' name='group_name' required placeholder='enter group name'/>
                </div>
                <div className={groupCss.input_section}>
                    <label>Start Date</label>
                    <input type='date' name='start_date' required/>
                </div>
                <div className={groupCss.input_section}>
                    <label>End Date</label>
                    <input type='date' name='end_date' required/>
                </div>
               
            </form>
            <div className={groupCss.buttonWrapper}>
                    <button>Add Group</button>
            </div>

            <div className={groupCss.table_wrapper}>
                <table>
                  <thead>
                    <tr>
                      <th>SL</th>
                      <th>Name</th>
                      <th>Start</th>
                      <th>End</th>
                      <th>Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item, index)=>{
                      return <tr key={index}>
                          <td>{index+1}</td>
                          <td>{item.group_name}</td>
                          <td>{DateFormate(item.start_date) }</td>
                          <td>{DateFormate(item.end_date)}</td>
                          <td>{item.is_active}</td>
                          <td><button onClick={()=>onEditClick(item)}>Edit</button></td>
                      </tr>
                    })}
                  </tbody>
                </table>
           
        </div>
        </div>
    
    </div>
  )
}

export default GroupList