import MyAxios from 'axios'
import { API_LINK} from './APILink'
const TAG_API_LINK = `${API_LINK}manage-tag/`
const ATTRIBUTES_API_LINK = `${API_LINK}attributes/`
const UNITS_API_LINK = `${API_LINK}unites/`
const ATTRIBUTES_NAME_API_LINK = `${API_LINK}att-name/`

const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}

export async function GetALLTag() {
    try {
        let result = await MyAxios.get(`${TAG_API_LINK}/all`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetALLUnits() {
    try {
        let result = await MyAxios.get(`${UNITS_API_LINK}/all`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetALLAttributes() {
    try {
        let result = await MyAxios.get(`${ATTRIBUTES_API_LINK}/all`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetALLAttributesName() {
    try {
        let result = await MyAxios.get(`${ATTRIBUTES_NAME_API_LINK}/all`)
        return result.data        
    } catch (error) {
        return 0
    }    
}


export async function SaveUnits(data) {
    try {
        let result = await MyAxios.post(`${UNITS_API_LINK}/add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function UpdateUnits(id, data) {
    try {
        let result = await MyAxios.put(`${UNITS_API_LINK}/${id}`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function DeleteUnits(id) {
    try {
        let result = await MyAxios.delete(`${UNITS_API_LINK}/${id}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}