import EditItemCss from './css/edititem.module.css'

import JoditEditor from 'jodit-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBrandList } from '../../../../ReduxFiles/Slices/BrandListSlice'
import { selectCategoryList } from '../../../../ReduxFiles/Slices/CategorySlice'
import { fetchAtributes, fetchUnits, selectMAUStatus, selectUnitList } from '../../../../ReduxFiles/Slices/MAU_Slice'
import { GetLastItem, UpdateProductList } from '../../../../api/ProductListApi'
import Select from 'react-select'
import { toast } from 'react-toastify'



const ItemInformationEdit = ({ item, setItem}) => {
    const dispatch = useDispatch()
    const Brands = useSelector(selectBrandList)
    const itemCat = useSelector(selectCategoryList)
    const Units = useSelector(selectUnitList)
    const mauStatus = useSelector(selectMAUStatus)

    function onCategoryChange(e, name) {
        getItemCode(e?.id, name)
    }

    async function getItemCode(value, name) {
        let itemCodeValue = ""
        let itemSlug = ""
        let attribute_id;
        if (value) {
            itemCat.forEach(element => {
                if (element.id === parseInt(value)) {
                    itemCodeValue = element.code
                    itemSlug = element.slug
                    attribute_id = element.SelectedAttribute
                }
            });
            let findProducts = value ?  await GetLastItem(value) :[]
            let finalValue = 0
            if (findProducts.length > 0) {
                finalValue = parseInt(findProducts[0].item_code) + 1;
            } else {
                finalValue = itemCodeValue + '00' + 1
            }
            setItem({ ...item, item_code: finalValue, [name]: value, itemSlug: itemSlug, updateValue: finalValue, attribute_id })
        }else{
            setItem({ ...item, item_code: '', [name]: null, itemSlug: '', updateValue: '', attribute_id:'' })
        }

    }
    
    async function SaveItem(e) {
        e.preventDefault()
        const form = e.target
        const en_sort_title = form.en_sort_title.value
        const en_long_title = form.en_long_title.value
        const bn_sort_title = form.bn_sort_title.value
        const bn_long_title = form.bn_long_title.value
        const selected_catagory_id = form.selected_catagory_id.value
        const item_code = form.item_code.value
        const selected_brand_id = form.selected_brand_id.value
        const ISBN_number = form.ISBN_number.value
        const selectedUnit = form.selectedUnit.value
        const en_description = form.en_description.value
        const bn_description = form.bn_description.value
        const en_speicification = form.en_speicification.value
        const bn_speicification = form.bn_speicification.value
        let data = {
            en_sort_title, en_long_title, bn_sort_title, bn_long_title, selected_catagory_id,item_code, selected_brand_id, ISBN_number, selectedUnit, en_description, bn_description, en_speicification, bn_speicification
        }
        try {
            await UpdateProductList(item.id, data)
            toast.success('Information Updated')
        } catch (error) {
           toast.error(error.message) 
        }
    }
    async function OptionItem() {
        if (mauStatus.unitStatus === 'idle') {
            dispatch(fetchUnits())
        }

        if (mauStatus.attributesStatus === 'idle') {
            dispatch(fetchAtributes())
        }
    } 
  
    useEffect(() => {
        OptionItem()
    }, [])

    const [deleteItem, setDeleteItem] = useState()
 
    const config = {
        useSplitMode: false,
        uploader: {
          insertImageAsBase64URI: true
        },
        placeholder: 'Type Your Content Here...',
        showXPathInStatusbar: false
      };
    return (
        <>
       <section className={EditItemCss.iteminfo}>
                <div className={EditItemCss.row}>
                    <form onSubmit={SaveItem}>
                        <div className={EditItemCss.input_wrapper}>
                            <div className={EditItemCss.common}>
                                <label>English Short Title*</label>
                                <input name="en_sort_title" type='text' placeholder='English Short Title' required defaultValue={item.en_sort_title}/>
                            </div>
                            <div className={EditItemCss.common}>
                                <label>English Long Title</label>
                                <input name="en_long_title" type='text' placeholder='English Long Title' required defaultValue={item.en_long_title}/>
                            </div>
                            <div className={EditItemCss.common}>
                                <label>Bengali Short Title*</label>
                                <input name="bn_sort_title" type='text' placeholder='Bengali Short Title' required defaultValue={item.bn_sort_title}/>
                            </div>
                            <div className={EditItemCss.common}>
                                <label>Bengali Long Title*</label>
                                <input name="bn_long_title" type='text' placeholder='Bengali Long Title' required defaultValue={item.bn_long_title}/>
                            </div>
                            <div className={EditItemCss.common}>
                                <label>Category</label>
                                <Select options={itemCat}
                                    getOptionLabel={(option) => `${option.catName}`}
                                    getOptionValue={(option) => `${option.id}`}
                                    name='selected_catagory_id'
                                    onChange={(e)=>onCategoryChange(e, 'selected_catagory_id')}
                                    required 
                                    isClearable
                                    // onChange={(e)=>setPopUp({...popUp, department:e?.id})}
                                    value={itemCat.filter((fitem)=>fitem.id===item.selected_catagory_id)}
                                />
                            </div>
                            <div className={EditItemCss.common}>
                                <label>Item Code</label>
                                <input name="item_code" type='text' placeholder='item code' disabled defaultValue={item.item_code}/>
                            </div>

                            <div className={EditItemCss.common}>
                                <label>Brand</label>
                                <Select options={Brands}
                                    getOptionLabel={(option) => `${option.BrandName}`}
                                    getOptionValue={(option) => `${option.id}`}
                                    name='selected_brand_id'
                                    required
                                    isClearable
                                    onChange={(e)=>{setItem({...item, selected_brand_id:e?.id})}}
                                    value={Brands.filter((fitem)=>fitem.id===item.selected_brand_id)}
                                />
                            </div>
                            <div className={EditItemCss.common}>
                                <label>ISBN Number</label>
                                <input name="ISBN_number" type='text' placeholder='ISBN Number' />
                            </div>

                            <div className={EditItemCss.common}>
                                <label>Unit</label>
                                <Select options={Units}
                                    getOptionLabel={(option) => `${option.unit}`}
                                    getOptionValue={(option) => `${option.id}`}
                                    name='selectedUnit'
                                    required
                                    isClearable
                                    onChange={(e)=>{setItem({...item, selectedUnit:e?.id})}}
                                    value={Units.filter((fitem)=>fitem.id===item.selectedUnit)}
                                />
                            </div>
                        </div>
                        <div className={`${EditItemCss.extra_wrapper} ${EditItemCss.text_editor}`}>
                            <h4>Product Description (English)</h4>
                            <div className={EditItemCss.inner_common}>
                                <JoditEditor
                                    value={item.en_description || ''}
                                    config={config}
                                    tabIndex={1}
                                    name='en_description'
                                />
                            </div>
                        </div>
                        <div className={`${EditItemCss.extra_wrapper} ${EditItemCss.text_editor}`}>
                            <h4>Product Description (Bengali)</h4>
                            <div className={EditItemCss.inner_common}>
                                <JoditEditor
                                    value={item.bn_description || ''}
                                    config={config}
                                    tabIndex={1} // tabIndex of textarea
                                    name='bn_description'
                                />
                            </div>
                        </div>
                        <div className={`${EditItemCss.extra_wrapper} ${EditItemCss.text_editor}`}>
                            <h4>Product Specifications (English)</h4>
                            <div className={EditItemCss.inner_common}>
                                <JoditEditor
                                    value={item.en_speicification || ''}
                                    config={config}
                                    tabIndex={1} // tabIndex of textarea
                                    name='en_speicification'
                                />
                            </div>
                        </div>
                        <div className={`${EditItemCss.extra_wrapper} ${EditItemCss.text_editor}`}>
                            <h4>Product Specifications (Bengali)</h4>
                            <div className={EditItemCss.inner_common}>
                                <JoditEditor
                                    value={item.bn_speicification || ''}
                                    config={config}
                                    tabIndex={1} // tabIndex of textarea
                                    name='bn_speicification'
                                />
                            </div>
                        </div>
                        <div className={EditItemCss.btn_group}>
                        <button type={"submit"} >Update Information</button>
                        </div>
                    </form>
                </div>

            </section>
        </>
    )
}

export default ItemInformationEdit

