import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { GetEndDate, GetStartDate, } from '../../MyFunctions/MyFunctions'
import { GetDataPassQuery } from '../../../api/ExpressFunctions'
import { HeaderFooterWrapper } from '../HeadetFooter'
import StockSummeryPSPdfCss from './css/StockSummeryPSPdf.module.css'

const StockSummeryPSPdf = () => {
    const [invoiceState, setInvoiceState] = useState()

    let [searchParams, setSearchParams] = useSearchParams();

    async function getData() {
        let startDate = searchParams.get('start_date')
        let endDate = searchParams.get('end_date')
        let myStartDate = GetStartDate(startDate)
        let myEndDate = GetEndDate(endDate)
        // console.log(new Date(myStartDate))
        let allData = []

        let purchaseData;
        let salesData;
        if (startDate && endDate) {
            //   console.log(myStartDate) 
            let purchaseQuery = `SELECT pitem.*, preturn.quantity as rquantity  FROM purchase_items pitem LEFT JOIN purchase_return_items preturn ON pitem.product_id=preturn.product_id WHERE pitem.created_date > ${myStartDate} AND pitem.created_date < ${myEndDate}`
            let salesQuery = `SELECT sitem.*, sreturn.quantity as rquantity  FROM test_anayase.sale_items sitem LEFT JOIN test_anayase.sales_return_items sreturn ON sitem.product_id=sreturn.product_id WHERE sitem.created_date > ${myStartDate} AND sitem.created_date < ${myEndDate}`
            purchaseData = await GetDataPassQuery(purchaseQuery)
            salesData = await GetDataPassQuery(salesQuery)

        } else {
            let purchaseQuery = `SELECT pitem.*, preturn.quantity as rquantity  FROM purchase_items pitem LEFT JOIN purchase_return_items preturn ON pitem.product_id=preturn.product_id`
            let salesQuery = `SELECT sitem.*, sreturn.quantity as rquantity  FROM test_anayase.sale_items sitem LEFT JOIN test_anayase.sales_return_items sreturn ON sitem.product_id=sreturn.product_id`
            purchaseData = await GetDataPassQuery(purchaseQuery)
            salesData = await GetDataPassQuery(salesQuery)
        }
        salesData = salesData.map((item) => {
            return { ...item, tableName: 'sales' }
        })
        purchaseData = purchaseData.map((item) => {
            return { ...item, tableName: 'purchase' }
        })
        // console.log(salesData, purchaseData)
        allData.push(...salesData, ...purchaseData)
        // console.log(salesData)
        organizeData(allData)
    }

    function organizeData(myData) {
        let finalArr = []
        let uniqueElements = myData.slice()
        for (let index = 0; index < myData.length; index++) {
            const element = myData[index];
            let tempArr = []
            for (let mindex = 0; mindex < uniqueElements.length; mindex++) {
                const melement = uniqueElements[mindex];
                if (melement.product_id === element.product_id) {
                    tempArr.push({ ...melement, fquantity: melement.quantity - melement.rquantity })
                }
            }

            if (tempArr.length > 0) {
                tempArr.forEach(element => {
                    uniqueElements.forEach((melement, mindex) => {
                        if (melement.product_id === element.product_id) {
                            uniqueElements.splice(mindex, 1)
                        }
                    });
                });

                finalArr.push(tempArr)
            }

        }
        //  console.log(finalArr)
        // setInvoiceState(finalArr)
        getTotalData(finalArr)
    }
    function getTotalData(myArr) {
        //  console.log(myArr)
        let allProduct = JSON.parse(window.localStorage.getItem('allProduct'))

        let okArr = []
        myArr.forEach(element => {
            let id = ''
            let totalSale = 0
            let totalPurchase = 0
            let totalPurchaseqty = 0
            let totalSaleqty = 0
            let punit_price = 0
            let sunit_price = 0

            element.forEach(felement => {
                id = felement.product_id
                if (felement.tableName === 'purchase') {
                    totalPurchase += felement.fquantity * felement.price
                    totalPurchaseqty += felement.fquantity
                    punit_price = felement.price
                } else {
                    totalSale += felement.fquantity * felement.price
                    totalSaleqty += felement.fquantity
                    sunit_price = felement.price
                }
            });

            let finalData = {
                productID: id,
                saleQuantity: totalSaleqty,
                saleAmount: totalSale,
                purchaseQuantity: totalPurchaseqty,
                purchaseAmount: totalPurchase,
                punitPrice: punit_price,
                sunitPrice: sunit_price,
                availableQty: totalPurchaseqty - totalSaleqty,
                stockValue: (totalPurchaseqty - totalSaleqty) * punit_price
            }

            okArr.push(finalData)
        });
        let withNameArr = []
        for (let index = 0; index < okArr.length; index++) {
            let element = okArr[index];
            for (let index = 0; index < allProduct.length; index++) {
                const allelement = allProduct[index];
                if (element.productID === allelement.id) {
                    element = {
                        ...element,
                        name: allelement.en_sort_title
                    }
                }

            }
            withNameArr.push(element)
        }
        // console.log(withNameArr)
        setInvoiceState(withNameArr)
        //console.log('done')
    }


    function getTotalAll(marray, identity) {
        let total = 0
        marray?.forEach(element => {
            total += element[identity]
        });
        return total
    }

    useEffect(() => {
        getData()
    }, [])


    function checkClick(params) {
        // console.log(1)
        getData()

    }


    return (
        <>
        <HeaderFooterWrapper
        content={
            <><h3>Stock Summery (Purchase & Sales)</h3>
            <div className={StockSummeryPSPdfCss.details}>
                <div className={StockSummeryPSPdfCss.client}>
                    <div className={StockSummeryPSPdfCss.info}>
                        {searchParams.get('start_date') ? <span className={StockSummeryPSPdfCss.moreinfo}>Date : {searchParams.get('start_date')}  To {searchParams.get('end_date')}</span> : ''}

                        {!searchParams.get('start_date') ? <span className={StockSummeryPSPdfCss.moreinfo}>Date : All Time</span> : ''}
                    </div>
                </div>
            </div>
            <div className={`${StockSummeryPSPdfCss.table} ${StockSummeryPSPdfCss.fixed_table}`}>
                <table>
                    <thead>
                        <tr className={StockSummeryPSPdfCss.fixed_table_header}>
                            <th >Sl</th>
                            <th>Product Name</th>
                            <th style={{ paddingLeft: '10px' }}>Purchase Qty.</th>
                            <th style={{ paddingLeft: '10px' }}>Unit Price</th>
                            <th className={StockSummeryPSPdfCss.unit}>Purchase Amount </th>
                            <th className={StockSummeryPSPdfCss.unit_price}>Sales Qty.</th>
                            <th style={{ paddingLeft: '10px' }}>Unit Price</th>
                            <th className={StockSummeryPSPdfCss.unit_price}>Sales Amount</th>
                            <th className={StockSummeryPSPdfCss.unit_price}>Stock Av. Qty</th>
                            <th className={StockSummeryPSPdfCss.unit_price}>Stock Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoiceState?.map((item, index) => {
                            return <tr key={index}>
                                <td>{index + 1}</td>
                                <td style={{ textAlign: 'left' }} >{item.name}</td>
                                <td>{item.purchaseQuantity}</td>
                                <td>{item.punitPrice}</td>
                                <td>{item.purchaseAmount}</td>
                                <td>{item.saleQuantity}</td>
                                <td>{item.sunitPrice}</td>
                                <td>{item.saleAmount}</td>
                                <td>{item.availableQty}</td>
                                <td>{item.stockValue}</td>
                            </tr>
                        })}

                        <tr className={StockSummeryPSPdfCss.final_row}>
                            <td className={StockSummeryPSPdfCss.no_border_right} colSpan={'2'}>Total :</td>
                            <td>{getTotalAll(invoiceState, 'purchaseQuantity')}</td>
                            <td></td>
                            <td >{getTotalAll(invoiceState, 'purchaseAmount')}</td>
                            <td >{getTotalAll(invoiceState, 'saleQuantity')}</td>
                            <td></td>
                            <td >{getTotalAll(invoiceState, 'saleAmount')}</td>
                            <td >{getTotalAll(invoiceState, 'availableQty')}</td>
                            <td>{getTotalAll(invoiceState, 'stockValue')}</td>
                        </tr>
                    </tbody>
                </table>

            </div></>
        }
        
        />
        </>
    )
}

export default StockSummeryPSPdf