import React, { useEffect, useState } from 'react'
import { Link, NavLink, useParams, useSearchParams } from 'react-router-dom'
import { GetAllLocalProducts } from '../MyFunctions/MyFunctions'
import { GetPurchaseItems, GetsinglePurchase } from '../../api/PurchaseApi'
import { GetDebitDataFilter } from '../../api/DebitApi'
import { selectSupplierList } from '../../ReduxFiles/Slices/SupplierSlice'
import { useSelector } from 'react-redux'
import detailCss from './css/ViewOnly.module.css'
import GobackButton from '../global/GobackButton'

const HisotoryViewDetails = () => {
    const myParams = useParams()
    const [searchParams] = useSearchParams()
    const poid = searchParams.get('poid')
    const [tableData, setTableData] = useState()
    const [orderData, setOrderData] = useState()
    const supplierData = useSelector(selectSupplierList)

    async function onPrintInvoice() {
        let finalData = { ...orderData, purchaseItems: tableData }
        window.localStorage.setItem('PoInvoiceData', JSON.stringify(finalData))
    }

    function convertDate(time) {
        const dateInMillis = time
        const date = new Date(dateInMillis)
        let myDate = date.toLocaleDateString()
        let myTime = date.toLocaleTimeString()
        myDate = myDate.replaceAll('/', '-')
        return myDate + " " + myTime
    }
    async function getAllData() {
        let mAllProducts = GetAllLocalProducts()
        let myData = await GetsinglePurchase(poid)
        let myNestedData = await GetPurchaseItems(`purchase_id = ${poid}`)
        let myPayData = await GetDebitDataFilter(`identity = ${poid}`)
        let myArr = []
        myNestedData.forEach(element => {
            for (let index = 0; index < mAllProducts.length; index++) {
                const pelement = mAllProducts[index];
                if (element.product_id === pelement.id) {
                    element = {
                        ...element,
                        name: pelement.en_sort_title
                    }
                    myArr.push(element)
                    break
                }
            }
        });
        let total = 0
        let finalData = {
            ...myData[0]
        }
        for (let index = 0; index < myPayData?.length; index++) {
            const pelement = myPayData[index];
            if (pelement.identity === parseInt(poid)) {
                total += pelement.paymentAmmount
            }

        }
        setOrderData({ ...finalData, payAmmount: total })
        setTableData(myArr)
    }

    useEffect(() => {
        getAllData()
    }, [myParams.orderid])

    function finalQuantity(quantity, returnQuantity) {
        let mquantity = 0
        if (returnQuantity) {
            mquantity = quantity - returnQuantity
        } else {
            mquantity = quantity
        }
        return mquantity
    }
    const TableItem = tableData?.map((item, index) => {
        return <MyTable key={item.productID} item={item.name}
            price={item?.price} qty={finalQuantity(item.quantity, item.returnQuantity)} tammount={finalQuantity(item.quantity, item.returnQuantity) * item.price} />

    })

    function getItemTotal(mtabelData) {
        let total = 0
        mtabelData?.map((item) => {
            total += item.quantity * item.price
        })

        return total
    }
    function getPaymentTotal(payArr) {
        let total = 0
        payArr?.map((pitem) => {
            total += pitem.paymentAmmount
        })

        return total
    }

    return (
        <section className={detailCss.wrapper}>
            <div className={detailCss.holder}>
                <div className={detailCss.head}>
                    <GobackButton />
                    <h2 className={detailCss.saleHeading}>Purchase History Details</h2>
                </div>
                <div className={detailCss.information}>
                <div className={detailCss.customerDetails}>
                        <h3>PO No. #{orderData?.id}</h3>
                            <p className={detailCss.highlighter}>Supplier Details</p>
                            {supplierData?.filter((fitem) => {
                                return fitem.id === orderData?.supplierID ? fitem : ''
                            }).map((item, index) => {
                                return <div key={index}><p><strong>Company Name</strong> : {item?.companyaName}</p>
                                    <p><strong>Phone</strong> : {item?.phone}</p>
                                    <p><strong>Division</strong> :{item?.Divition}</p>
                                    <p><strong>District</strong> : {item?.District}</p>
                                    <p><strong>area</strong> : {item?.Area}</p>
                                </div>
                            })}
                        </div>
                        <div className={detailCss.orderDetails}>
                            <p className={detailCss.highlighter}>PO Details</p>
                            <p>PO Date : {convertDate(orderData?.created_date)} </p>
                            <p>Payment Method : {orderData?.paymentMethod}</p>
                        </div>
                </div>
                <div className={detailCss.table_wrapper }>
                        <table>
                            <tbody>
                                <tr>
                                    <th>Item</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Total Ammount</th>
                                </tr>
                                {TableItem}

                                <tr className={detailCss.last_tr}>
                                    <td colSpan={3}>Sub Total :</td>
                                    <td>{getItemTotal(tableData)} tk</td>
                                </tr>
                                <tr className={detailCss.last_tr}>
                                    <td colSpan={3}>Discount ({orderData?.percent?.toFixed(2)}%):</td>
                                    <td>{Math.round((getItemTotal(tableData)) * orderData?.percent / 100)} tk</td>
                                </tr>
                                <tr className={detailCss.last_tr}>
                                    <td colSpan={3}>Paid :</td>
                                    <td>{orderData?.payAmmount ? parseInt(orderData?.payAmmount) + getPaymentTotal(orderData?.Payment) : "0"} tk</td>
                                </tr>
                                <tr className={detailCss.last_tr}>
                                    <td colSpan={3}>Due Ammount :</td>
                                    <td>{Math.round(getItemTotal(tableData) - (orderData?.payAmmount ? orderData?.payAmmount : 0) - (orderData?.percent ? (getItemTotal(tableData) * orderData?.percent) / 100 : 0)) - getPaymentTotal(orderData?.Payment)} tk</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className={detailCss.button_group}>
                                    <Link to={`invoice?poid=${poid}`} target='_blank' rel="noopener noreferrer"><button onClick={onPrintInvoice}>Print Invoice</button></Link>
                                </div>

                    </div>
            </div>
        </section>

    )
}

export default HisotoryViewDetails

const MyTable = ({ item, editVariance, price, qty, tammount }) => {

    return (
        <>
            <tr>
                <td className={detailCss.foreditVariance}>{item} {editVariance}</td>
                <td >{price}</td>
                <td>{qty}</td>
                <td>{tammount}</td>
            </tr>
        </>
    )
}