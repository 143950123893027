import { Route, Routes } from 'react-router-dom'
import ExpenseHead from './ExpenseHead'
import ExpenseGroup from './ExpenseGroup'
import AddNewExpense from './AddNewExpense'
import PendingExpense from './PendingExpense'
import PendingView from './PendingView'
import PendingEditView from './PendingEditView'
import ExpenseHistory from './ExpenseHistory'
import HistoryView from './HistoryView'
import ExpenseDecline from './ExpenseDecline'
import DeclineView from './DeclineView'

export default function ExpenseRoutes() {
  return (
    <Routes>
    <Route path='/add-new-expense' element={<AddNewExpense />}/>
    <Route path='/pending' element={<PendingExpense />}/>
    <Route path='/pending-expense/expense-view' element={<PendingView />}/>
    <Route path='/pending-expense/expense-edit' element={<PendingEditView />}/>
    <Route path='/history' element={<ExpenseHistory />}/>
    <Route path='/expense-history/expense-view-history' element={<HistoryView />}/>
    <Route path='/decline' element={<ExpenseDecline />}/>
    <Route path='/declined-expense/expense-view-decline' element={<DeclineView />}/>
    <Route path='/head' element={<ExpenseHead />}/>
    <Route path='/group' element={<ExpenseGroup />}/>
</Routes>
  )
}
