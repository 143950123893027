import { useEffect, useState } from "react";
import {
  DeleteOfferPop,
  GetOfferPopBanners,
  SaveOfferBanner,
  UpdateOfferBanner,
} from "../../api/SettingApi";
import offerCss from "./css/offerpopup.module.css";
import ConfirmAlert from "../smallThings/ConfirmAlert";
import { toast } from "react-toastify";
import { API_LINK } from "../../api/APILink";
import ToggleSwitch from "../smallThings/ToggleSwitch";
import OfferPopupEdit from "./OfferPopupEdit";
async function getData(setData) {
  let data = await GetOfferPopBanners();
  setData(data || []);
}
const OfferPopup = () => {
  const [dataList, setDataList] = useState([]);
  const [conAlert, setConAlert] = useState(false);
  const [deleteData, setDeleteData] = useState();
  function onDeleteClick(item) {
    setDeleteData(item);
    setConAlert(true);
  }
  async function ClickYes() {
    const id = deleteData.banner_id;
    // console.log(deleteData);
    await DeleteOfferPop(id);
    toast.success("Delete Success");
    getData(setDataList);
    setConAlert(false);
  }

  function ClickNo() {
    setConAlert(false);
  }
  async function onAddBanner(e) {
    e.preventDefault();
    const banner_name = e.target.banner_name.value;
    const banner_image = e.target.banner_image.files;
    try {
      await SaveOfferBanner({ banner_name }, banner_image[0]);
      toast.success("Banner Added");
      getData(setDataList);
    } catch (error) {
      toast.error(error.message);
    }
  }
  async function onToggleClick(item) {
    // console.log(item);
    try {
      await UpdateOfferBanner(item.banner_id, { active: !item.active });
      getData(setDataList);
      toast.success("Banner Updated");
    } catch (error) {
      toast.error(error.message);
    }
  }
  const [editData, setEditData] = useState()

  function onEditClik(item) {
    setEditData(item)
  }
  useEffect(() => {
    getData(setDataList);
  }, []);

  return (
    <div className={offerCss.salesMain}>
      {conAlert ? <ConfirmAlert yesClick={ClickYes} noClick={ClickNo} /> : ""}
      {editData && <OfferPopupEdit popUp={editData} setPopData={setEditData}/>}
      <h2>Offer Popup</h2>
      <div className={offerCss.innerSale}>
        <form className={offerCss.input_wrapper} onSubmit={onAddBanner}>
          <div className={offerCss.input_holder}>
            <label>Banner Name</label>
            <input type="text" required name="banner_name" />
          </div>
          <div className={offerCss.input_holder}>
            <label>Banner Image</label>
            <input
              type="file"
              accept="image/webp, image/png, image/jpg, image/jpeg"
              required
              name="banner_image"
            />
          </div>
          <div className={offerCss.btn_group}>
            <button>Submit</button>
          </div>
        </form>

        <div className={offerCss.table_wrapper}>
          <table>
            <thead>
              <tr>
                <th>SL</th>
                <th>Banner Name</th>
                <th>Banner Image</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {dataList.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.banner_name}</td>
                    <td>
                      <img
                        src={`${API_LINK}popupbanner/${item.banner_image}`}
                        alt={`${item.banner_name}`}
                      />
                    </td>
                    <td>
                      <ToggleSwitch
                        item={item}
                        toggleOnOff={item.active}
                        toggleClick={onToggleClick}
                      />
                    </td>

                    <td>
                      <div className={offerCss.action_buttons}>
                       <button onClick={()=>onEditClik(item)}>
                          Edit
                        </button>
                        <button
                          onClick={() => {
                            onDeleteClick(item);
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>  
    </div>
    
  );
};

export default OfferPopup;

