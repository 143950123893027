import MyAxios from 'axios'
import { API_LINK} from './APILink'
const SALES_ITEMS_API_LINK = `${API_LINK}sales-item/`
const SALES_TRACKING_API_LINK = `${API_LINK}sales-tracking/`
const SALES_SUMMERY_API_LINK = `${API_LINK}sales-summery/`

const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}

export async function SaveTrackingData(data) {
    try {
        let result = await MyAxios.post(`${SALES_TRACKING_API_LINK}add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function SaveSummeryData(data) {
    try {
        let result = await MyAxios.post(`${SALES_SUMMERY_API_LINK}add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetSummeryData(data) {
    try {
        let result = await MyAxios.post(`${SALES_SUMMERY_API_LINK}order_summery`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}


export async function GetSingleProductVarianceName(data) {
    try {
        let result = await MyAxios.post(`${SALES_ITEMS_API_LINK}signle-variance`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function SaveSalesItemsData(data) {
    try {
        let result = await MyAxios.post(`${SALES_ITEMS_API_LINK}add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function UpdateSalesItemsData(id, data) {
    try {
        let result = await MyAxios.put(`${SALES_ITEMS_API_LINK}${id}`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function DeleteSalesItemsData(id, data) {
    try {
        let result = await MyAxios.delete(`${SALES_ITEMS_API_LINK}${id}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}

// sales offer items
const SALES_OFFER_ITEMS_API_LINK = `${API_LINK}sales-offer-items/`

export async function GetInitOfferItem(data) {
    try {
        let result = await MyAxios.post(`${SALES_OFFER_ITEMS_API_LINK}init-data`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetSigleSalesOfferItem(id) {
    try {
        let result = await MyAxios.get(`${SALES_OFFER_ITEMS_API_LINK}${id}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function DeleteSalesOfferItem(id) {
    try {
        let result = await MyAxios.delete(`${SALES_OFFER_ITEMS_API_LINK}${id}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetFilterSalesOfferItem(query) {
    try {
        let result = await MyAxios.get(`${SALES_OFFER_ITEMS_API_LINK}filter/${query}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function SaveSalesOfferItemsData(data) {
    try {
        let result = await MyAxios.post(`${SALES_OFFER_ITEMS_API_LINK}add`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function UpdateSalesOfferItemsData(id, data) {
    try {
        let result = await MyAxios.put(`${SALES_OFFER_ITEMS_API_LINK}${id}`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}


// delivery Management api

const DELIVERY_MANAGEMENT_API_LINK = `${API_LINK}delivery-management/`

export async function GetDeliveryBoyList() {
    try {
        let result = await MyAxios.get(`${DELIVERY_MANAGEMENT_API_LINK}all`)
        return result.data        
    } catch (error) {
        return 0
    }    
}


// Bogo Offer API

export async function BogoGroupAdd(passedData) {
    try {
        let result = await MyAxios.post(`${SALES_OFFER_ITEMS_API_LINK}bogo-group-add`, passedData, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function BogoGroupUpdate(id, passedData) {
    try {
        let result = await MyAxios.put(`${SALES_OFFER_ITEMS_API_LINK}bogo-group-update/${id}`, passedData, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function BogoGroupGet() {
    try {
        let result = await MyAxios.get(`${SALES_OFFER_ITEMS_API_LINK}bogo-group/get`)
        return result.data        
    } catch (error) {
        console.log(error.message);
        return []
    }    
}
export async function BogoAssignAdd(passedData) {
    try {
        let result = await MyAxios.post(`${SALES_OFFER_ITEMS_API_LINK}bogo-assign-add`, passedData, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function BogoAssignGet(query) {
    try {
        let result = await MyAxios.get(`${SALES_OFFER_ITEMS_API_LINK}bogo-assign/get?${query}`)
        return result.data        
    } catch (error) {
        return 0
    }    
}