import MyAxios from 'axios'
import { API_LINK } from '../APILink'
const MY_API_LINK = `${API_LINK}report-link/`

const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}

export async function SalesReportData(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}sales`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function SalesReportDataDynamic(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}sales-dynamic`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function SalesProductWiseReportDynamic(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}sales-product-wise`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}

export async function SalesReturnReportData(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}sales-return-data`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}


export async function GetCredidDataBySalesID(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}credit-by-salesid`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function GetCustomerLadgerData(data) {
    //customer_id
    try {
        let result = await MyAxios.post(`${MY_API_LINK}customer-ladger`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}
