import MyAxios from "axios";
import CryptoJS from "crypto-js";
import { API_LINK, ENCRYPTION_KEY } from "./APILink";
const MY_API_LINK = `${API_LINK}back-user/`;

const configdata = {
  headers: {
    "Content-Type": "application/json",
  },
};
const configfile = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export async function SaveBackUserData(data, userimage) {
  try {
    let userImageName = "";
    if (userimage) {
      const formData = new FormData();
      formData.append("myfile", userimage);
      let resultImage = await MyAxios.post(
        `${MY_API_LINK}upload`,
        formData,
        configfile
      );
      userImageName = resultImage.data;
    }
    let result = await MyAxios.post(
      `${MY_API_LINK}add`,
      { ...data, userImageName },
      configdata
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function GetAllBackUser(query) {
  try {
    let result = await MyAxios.get(`${MY_API_LINK}all?${query}`);
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function UpdateUserPassword(passedData) {
  let result = await MyAxios.post(
    `${MY_API_LINK}change-password`,
    passedData,
    configdata
  );
  return result.data;
}
export async function UpdateBackUserData(id, data, userimage, image_name) {
  try {
    let userImageName = "";
    if (userimage) {
      const formData = new FormData();
      formData.append("myfile", userimage);
      let resultImage = await MyAxios.post(
        `${MY_API_LINK}upload`,
        formData,
        configfile
      );
      userImageName = resultImage.data;
    }
    let result = await MyAxios.put(
      `${MY_API_LINK}/${id}`,
      {
        ...data,
        userImageName: userImageName ? userImageName : data.userImageName,
      },
      configdata
    );
    if (image_name) {
      await MyAxios.post(
        `${MY_API_LINK}delete-image`,
        { image_name },
        configdata
      );
    }
    return result.data;
    // return true;
  } catch (error) {
    return 0;
  }
}
export async function LoginUser(data) {
  try {
    let result = await MyAxios.post(
      `${MY_API_LINK}user-validate/`,
      data,
      configdata
    );
    return result.data;
  } catch (error) {
    return 0;
  }
}
export async function GetUserImage(data) {
  try {
    let result = await MyAxios.post(`${MY_API_LINK}getimage`, data, configdata);
    return result.data;
  } catch (error) {
    return 0;
  }
}
export function DecryptLocalData(data) {
  try {
    const decryptedData = CryptoJS.AES.decrypt(data, ENCRYPTION_KEY).toString(
      CryptoJS.enc.Utf8
    );
    return decryptedData;
  } catch (error) {
    window.localStorage.removeItem("userData");
    return 0;
  }
}
