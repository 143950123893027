import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GetAllDataExpress, GetDataPassQuery } from '../../../api/ExpressFunctions';
import { GetEndDate, GetStartDate, LocalTimeStampToDate } from '../../MyFunctions/MyFunctions';
import { HeaderFooterWrapper } from '../HeadetFooter';
import BankPaymentReceivePDFCss from './css/BankPaymentReceivePDF.module.css'
const BankPaymentReceivePDF = ({ heading }) => {
    
    const [invoiceState, setInvoiceState] = useState()
    let [searchParams, setSearchParams] = useSearchParams();

    async function getDataformDatabase(params) {
       // console.log(1)
        let bankList = await GetAllDataExpress('bank_list')
        let bankTData = []
        let startDate = searchParams.get('start_date')
        let endDate = searchParams.get('end_date')
        let myStartDate = GetStartDate(startDate)
        let myEndDate = GetEndDate(endDate)
        if (startDate) {
            let mQuery = `SELECT * FROM bank_transaction WHERE created_date > ${myStartDate} AND created_date < ${myEndDate}`
            bankTData =  await GetDataPassQuery(mQuery)
        }else{
            bankTData = await GetAllDataExpress('bank_transaction')
        }
        let myArangeData = []
       // console.log(bankTData)
        for (let index = 0; index < bankTData.length; index++) {
            let element = bankTData[index];
            let mbankName = ''
            let mAcNo = ''
            for (let index = 0; index < bankList.length; index++) {
                const belement = bankList[index];
                if (element.bankID===belement.id) {
                    mbankName = belement.bankName
                    mAcNo = belement.acNumber
                    break
                }
            } 
            element = {...element, bankName:mbankName, acNumber:mAcNo}
            myArangeData.push(element)
        }
        let finalArr = []
      //  console.log(myArangeData)
        myArangeData.forEach(element => {

            let finalData = {
                date:element.created_date, 
                type:element.particulars,
                particulars:element.transactionType,
                bankName:element.bankName,
                acNumber:element.acNumber,
                identity:element.identity,
                paymnet:element.paymentAmmount,
                received:element.receiveAmmount,
                balance:element.receiveAmmount-element.paymentAmmount
            }
            finalArr.push(finalData)
        });
        let sortArr = finalArr.sort((a, b) => (a.date > b.date) ? -1 : 1)
       // setInvoiceState(sortArr)
        getBalanceAmmount(sortArr)
    }
   
    function getBalanceAmmount(balanceArr) {
        let finalArr = []
        let myTempBalance = 0
        for (let index = 0; index < balanceArr.length; index++) {
            let element = balanceArr[index];
                if (index===0) {
                    element = {...element, mbalance:element.balance}
                    finalArr.push(element)
                    myTempBalance = element.balance
                }else{
                    myTempBalance += element.balance
                    element = {...element, mbalance:myTempBalance}
                    finalArr.push(element)
                }
           }
           setInvoiceState(finalArr)
    }

    function getAllTotal(arrayPass, identifire) {
        let total = 0
        arrayPass?.forEach(element => {
            total += element[identifire]
        });
        return total;
    }

    useEffect(() => {
        getDataformDatabase()
    }, [])
    function getColor() {
        let leftPart = document.querySelector('.sidepart')
        leftPart?.classList.add('display-none')
       // console.log(leftPart)
    }

    useEffect(() => {
        getColor()
    
    }, [])

    return (
        <>
        <HeaderFooterWrapper
            content={<> <h3>BANK PAYMENT & Receive STATEMENT</h3>
            <div className={BankPaymentReceivePDFCss.details}>
                <div className={BankPaymentReceivePDFCss.client}>
                    <div className={BankPaymentReceivePDFCss.info}>
                        {searchParams.get('start_date') ? <span className={BankPaymentReceivePDFCss.moreinfo}>Date : {searchParams.get('start_date')}  To {searchParams.get('end_date')}</span> : <span className={BankPaymentReceivePDFCss.moreinfo}> Date : All Time</span>}
                    </div>
                </div>
                <div className={BankPaymentReceivePDFCss.invoice}>

                </div>
            </div>
            <div className={`${BankPaymentReceivePDFCss.table} ${BankPaymentReceivePDFCss.sales_table}`}>
                <table>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}>Sl</th>
                            <th>Date</th>
                            <th>Type</th>
                            <th>Particulars</th>
                            <th >Bank Name</th>
                            <th >Receive Ammount</th>
                            <th >Payment Ammount</th>
                            <th >Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoiceState?.map((item, index) => {
                            return <tr key={index}>
                                <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                <td style={{ textAlign: 'center' }}>{LocalTimeStampToDate(item.date)}</td>
                                <td style={{ textAlign: 'left' }}>{item.particulars}</td>
                                <td style={{ textAlign: 'left' }}>{item.type} (#{item.identity})</td>
                                <td style={{ textAlign: 'left' }}>{item.bankName} (#{item.acNumber})</td>
                                <td style={{ textAlign: 'right' }}>{item.received}</td>
                                <td style={{ textAlign: 'right' }}>{item.paymnet}</td>
                                <td>{item.mbalance}</td>
                            </tr>
                        })}
                        <tr style={{ fontWeight:'bold' }}>
                            <td colSpan={5}>Total : </td>
                            <td>{getAllTotal(invoiceState, 'received')}</td>
                            <td>{getAllTotal(invoiceState, 'paymnet')}</td>
                            <td>{getAllTotal(invoiceState, 'received')-getAllTotal(invoiceState, 'paymnet')}</td>
                        </tr>
                    </tbody>
                </table>
            </div></>}
        />
        </>
    )
}

export default BankPaymentReceivePDF