import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { GetSearchedCustomer } from "../../../api/CustomerApi";
import { GetCustomerLadgerData } from "../../../api/ReportApi/SalesReportApi";
import Headerimage from "../../../assets/Headerimage.jpg";
import { LocalTimeStampToDate } from "../../MyFunctions/MyFunctions";
import styles from "./css/CustomerLedgerPDF.module.css";

async function getData(phone, setInvoiceState, setCustomerInfo) {
  if (phone) {
    let customer = await GetSearchedCustomer({ search_text: phone });
    let myInvoiceData = await GetCustomerLadgerData({
      customer_id: customer[0].id,
    });
    setCustomerInfo(customer[0]);

    let tempArr = [];
    myInvoiceData.forEach((item) => {
      let melement = {
        created_date: item.created_date,
        id: item.id,
        status: item.declined ? "Declined Order" : item.status,
        saleAmount: item.declined
          ? 0
          : parseInt(item.total_orprice) + parseInt(item.deliveryCharge),
        returnAmount: item.declined ? 0 : item.rprice,
        discount: item.declined
          ? 0
          : item.total_orprice -
            item.total_price +
            (item.total_orprice * item.discountPercent) / 100,
        received: item.declined ? 0 : item.receiveAmount || 0,
        payment: item.declined ? 0 : item.paymentAmmount || 0,
        balance: item.declined
          ? 0
          : item.total_orprice +
            item.paymentAmmount +
            item.deliveryCharge -
            (item.receiveAmount + discount(item) + item.rprice),
        note: item.approval_note,
      };
      tempArr.push(melement);
    });

    setInvoiceState(tempArr);
  }
}
function discount(item) {
  return (
    item.total_orprice -
    item.total_price +
    (item.total_orprice * item.discountPercent) / 100
  );
}

const CustomerLedgerPDF = ({ heading }) => {
  const [invoiceState, setInvoiceState] = useState();
  const [customerInfo, setCustomerInfo] = useState();
  let [searchParams] = useSearchParams();
  let phone = searchParams.get("phone");
  useEffect(() => {
    getData(phone, setInvoiceState, setCustomerInfo);
  }, [phone]);

  function getTotalAmmount(arrayData, identifire) {
    let total = 0;
    arrayData?.forEach((element) => {
      total += element[identifire] ? parseInt(element[identifire]) : 0;
    });
    return total;
  }
  function PrintPaper() {
    window.print();
  }

  return (
    <div className={styles.invoicemain}>
      <button className={styles.printBtn} onClick={PrintPaper}>
        Print
      </button>

      <section className={styles.holder}>
        <div className={styles.topSection}>
          <img src={Headerimage} alt="" />
        </div>
        <div className={styles.main}>
          <h3>{heading}</h3>
          <div className={styles.details}>
            <div className={styles.client}>
              <p>
                <span>Customar Name : </span>
                {customerInfo?.name}
              </p>
              <p>
                <span>Phone No : </span>
                {customerInfo?.phone}
              </p>
            </div>
            <div className={styles.invoice}>
              <p>
                <span>Date : </span> All Time
              </p>
              <p>
                <span>Sold By : </span>
                Anayase.com
              </p>
            </div>
          </div>
          <div className={styles.table_wrapper}>
            <table>
              <thead>
                <tr className={styles.fixed_table_header}>
                  <th>Sl No.</th>
                  <th style={{ paddingLeft: "10px" }}>Date</th>
                  <th>Invoice No</th>
                  <th>Status</th>
                  <th>Sales Ammount</th>
                  <th>Return Ammount</th>
                  <th>Discount</th>
                  <th>Received Ammount</th>
                  <th>Payment</th>
                  <th>Balance</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                {invoiceState?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{LocalTimeStampToDate(item.created_date)}</td>
                      <td>
                        <strong>#{item.id}</strong>
                      </td>
                      <td>{item.status}</td>
                      <td>{item.saleAmount}</td>
                      <td>{item.returnAmount}</td>
                      <td>{item.discount.toFixed(2)}</td>
                      <td>{parseInt(item.received) || 0}</td>
                      <td>{item.payment}</td>
                      <td>{item.balance.toFixed(2)}</td>
                      <td>{item.note || "no note"}</td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={"4"}>Total :</td>
                  <td>{getTotalAmmount(invoiceState, "saleAmount")}</td>
                  <td>{getTotalAmmount(invoiceState, "returnAmount")}</td>
                  <td>
                    {getTotalAmmount(invoiceState, "discount").toFixed(2)}
                  </td>
                  <td>{getTotalAmmount(invoiceState, "received")}</td>
                  <td>{getTotalAmmount(invoiceState, "payment")}</td>
                  <td>{getTotalAmmount(invoiceState, "balance").toFixed(2)}</td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan={"9"}>
                    <strong>Total Due :</strong>
                  </td>
                  <td>
                    <strong>
                      {getTotalAmmount(invoiceState, "saleAmount") +
                        getTotalAmmount(invoiceState, "payment") -
                        (getTotalAmmount(invoiceState, "discount") +
                          getTotalAmmount(invoiceState, "received") +
                          getTotalAmmount(invoiceState, "returnAmount"))}
                    </strong>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className={styles.seal_signiture}>
            <p>Customer Signature</p>
            <p>Authority Signature</p>
          </div>

          <div className={styles.thankyou_msg}>
            <p>Thank you for being with Anayase.com</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CustomerLedgerPDF;
