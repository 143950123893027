import React, { useEffect, useState } from 'react'
import { faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalTimeStampToDate } from '../../MyFunctions/MyFunctions';
import { CustomerDebitData, SaveDebitData } from '../../../api/DebitApi';
import { useSelector, useDispatch } from 'react-redux'
import { fetchInitCustomer, fetchSearchedCustomer, selectCustomerStatus, selectInitCustomer } from '../../../ReduxFiles/Slices/CustomerSlice';
import Select from 'react-select'
import { DecreaseBankAmount, GetBankList, SaveBankTransactionData } from '../../../api/BankTransactionApi';
import { selectUserInfo } from '../../../ReduxFiles/Slices/UserSlice';
import { SaveSummeryData } from '../../../api/OrderInformationApi';
import { SaveDayBookData } from '../../../api/DayBookApi';
import DebitVoucherCustomerCss from './Css/DebitVoucherCustomer.module.css'

const DebitVoucherCustomer = () => {
    const dispatch = useDispatch()
    const customerStatus = useSelector(selectCustomerStatus)
    const CustomerList = useSelector(selectInitCustomer)
    const [userData, setUserData] = useState()
    const [myCheck, setMyCheck] = useState(true)
    const [tableData, setTableData] = useState()
    const [allData, setAllData] = useState()
    const [errMessage, setErrMessage] = useState()
    const [paymentInfo, setPaymentInfo] = useState({
        paymentDate: '',
        paymentMethod: 1,
        paymentAmmount: 0,
        paymentRemark: '',
        bankID: '',
        sub_method: ''
    })
    const [myBankList, setMyBankList] = useState()
    async function getBankList() {
        let bankData = await GetBankList()
        setMyBankList(bankData)
    }
    useEffect(() => {
        getBankList()
    }, [])


    function onCustomerChange(data) {
        setUserData(data)
    }

    const onInputDataChange = (inputValue, { action }) => {
        if (action === 'input-change') {
            if (inputValue.length >= 3) {
                dispatch(fetchSearchedCustomer(inputValue))
            }
        }
    };

    useEffect(() => {
        if (customerStatus === 'idle') {
            dispatch(fetchInitCustomer())
        }
    }, [])

    function onDataChange(e) {
        let payableDue = getPayableDue()
        let name = e.target.name
        let value = e.target.value
        if (name === 'paymentAmmount') {
            value = e.target.value ? parseInt(e.target.value) : ''
            // setPaymentInfo({ ...paymentInfo, [name]: value })
            // console.log(payableDue);
            if (value > payableDue) {
                value = payableDue
                setPaymentInfo({ ...paymentInfo, [name]: value })
            } else if (value < 0) {
                setPaymentInfo({ ...paymentInfo, [name]: 0 })
            } else {
                setPaymentInfo({ ...paymentInfo, [name]: value })
            }
        } else {
            setPaymentInfo({ ...paymentInfo, [name]: value })
        }

        if (name === 'paymentMethod') {
            //console.log(parseInt(value))
            if (parseInt(value) === 1 || parseInt(value) === 4) {
                setPaymentInfo({
                    ...paymentInfo, [name]: parseInt(value), bankID: '',
                    sub_method: ''
                })
            } else {
                setPaymentInfo({ ...paymentInfo, [name]: parseInt(value) })
            }
        }
    }

    function CheckAll() {
        //        console.log(tableData)
        let newArr = []
        tableData.forEach(element => {
            element = {
                ...element,
                nowPay: true
            }
            newArr.push(element)
        });
        setTableData(newArr)
    }
    function unCheckAll() {
        let newArr = []
        tableData.forEach(element => {
            element = {
                ...element,
                nowPay: false
            }
            newArr.push(element)
        });
        setTableData(newArr)
    }
    function CheckData(e, id) {
        let newArr = []
        tableData.forEach(element => {
            if (element.id === id) {
                element = {
                    ...element,
                    nowPay: true
                }
                newArr.push(element)
            } else {
                newArr.push(element)
            }
        });
        setTableData(newArr)
    }
    function unCheckData(e, id) {
        let newArr = []
        tableData.forEach(element => {
            if (element.id === id) {
                element = {
                    ...element,
                    nowPay: false
                }
                newArr.push(element)
            } else {
                newArr.push(element)
            }
        });
        setTableData(newArr)

    }
    function checkAllData(params) {
        for (let index = 0; index < tableData?.length; index++) {
            const element = tableData[index];
            if (!element.nowPay) {
                setMyCheck(false)
                break;
            } else {
                setMyCheck(true)
            }
        }
    }
    useEffect(() => {
        checkAllData()
        getPayableDue()
        setPaymentInfo({ ...paymentInfo, paymentAmmount: 0, paymentDate: getDateTime() })
    }, [tableData])

    function getDateTime() {
        let mydate = new Date();
        let day = mydate.getDate()
        let month = mydate.getMonth() + 1
        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }
        let year = mydate.getFullYear()
        let hour = mydate.getHours()
        if (hour < 10) {
            hour = '0' + hour
        }
        let minute = mydate.getMinutes()
        if (minute < 10) {
            minute = '0' + minute
        }
        // console.log(minute)
        let seconds = mydate.getSeconds()
        if (seconds < 10) {
            seconds = '0' + seconds
        }
        let finalTime = year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + seconds
        return finalTime
    }
    function getPayableDue() {
        let dueTotal = 0
        tableData?.map((item) => {
            // console.log(item.total-item.payAmmount)
            if (item.nowPay) {
                dueTotal += item.due
            }
        })
        return dueTotal
    }
    async function onSearchClick() {
        if (userData) {
            let customerSales = await CustomerDebitData({ customer_id: userData.id })

            let tempArr = []
            customerSales.forEach(element => {
                let due = element.receiveAmount - (parseInt(element.total_price) + element.deliveryCharge - element.rprice) - element.paymentAmmount
                element = {
                    ...element,
                    discount: (parseInt(element.total_price) - element.total_orprice) - (element.total_orprice * element.discountPercent / 100),
                    due: due
                }
                if (due > 0) {
                    tempArr.push(element)
                }

            });
            setTableData(tempArr)
            setAllData(tempArr)
        } else {
            alert('Select user to continue')
        }
    }

    function debitTotal(debitArr, identity) {
        // console.log(paymentArr)
        let total = 0
        debitArr?.filter((fitem) => {
            return fitem.identity === identity ? fitem : ''
        })
            .map((pitem) => {
                total += pitem.paymentAmmount
            })
        return total
    }

    function getLeadgerBalance() {
        let dueTotal = 0
        allData?.map((item) => {
            dueTotal += item.due
        })
        return dueTotal
    }

    function onSaveClick() {
        let payErr = ValidateFunction('paymentAmmount', 'Receive Ammount ')
        let remarkErr = ValidateFunction('paymentRemark', 'Remark  ')
        let dateErr = ValidateFunction('paymentDate', 'Receive Date ')
        let bankIDErr = ''
        let sub_methodErr = ''
        if (paymentInfo.paymentMethod === 2 || paymentInfo.paymentMethod === 3) {
            bankIDErr = ValidateFunction('bankID', 'Bank Name ')
            sub_methodErr = ValidateFunction('sub_method', 'Sub Method ')
        }
        setErrMessage({ ...errMessage, payErr, remarkErr, dateErr, bankIDErr, sub_methodErr })
        if (!payErr && !remarkErr && !dateErr && !bankIDErr && !sub_methodErr) {
            let paymentArr = []
            tableData.forEach(element => {
                if (element.nowPay) {
                    paymentArr.push(element)
                }
            });

            if (paymentArr.length > 0) {
                if (paymentInfo.paymentMethod === 1) {
                    //  console.log(paymentArr)
                    cashPayment(paymentArr)
                } else if (paymentInfo.paymentMethod === 2) {
                    // alert('not applied')
                    onlinePayment(paymentArr)
                } else if (paymentInfo.paymentMethod === 3) {
                    onlinePayment(paymentArr)
                }
            } else {
                console.log('no invoice found')
            }

        }

    }
    function ValidateFunction(field, message) {
        let text = ""
        if (!paymentInfo[field]) {
            text = message + " is Required"
        }
        return text;
    }
    let activeUserData = useSelector(selectUserInfo)
    async function cashPayment(paymentArr) {
        let userID = activeUserData.id
        if (paymentArr.length > 1) {
            if (paymentInfo.paymentAmmount === getPayableDue()) {

                paymentArr.forEach(async (item) => {
                    let dataID = item.id
                    let debitData = {
                        identity: dataID,
                        paymentAmmount: item.dueAmmount,
                        particulars: 'Sales',
                        processorID: userID,
                        remark: paymentInfo.paymentRemark,
                        paymentType: 'cash',
                        created_date: new Date().getTime()
                    }
                    let dayBookData = {
                        created_date: new Date().getTime(),
                        paticulars: "Sales (Payment Returned)",
                        identity: dataID,
                        received: 0,
                        payment: item.dueAmmount * (-1),
                        processorID: userID,
                    }
                    let summeryData = {
                        ProssesedBy: userID,
                        sale_id: dataID,
                        created_date: new Date().getTime(),
                        activity: 'Due Amount Full Returned',
                    }
                    // console.log(paymentInfo.paymentRemark)

                    console.log(debitData, dayBookData, summeryData, 1)
                    // await SaveDataExpress('debit_voucher', {...debitData})
                    // await SaveDataExpress('day_book', {...dayBookData})
                    // await SaveDataExpress('sales_summery', {...summeryData})
                });

            } else {
                alert('you must pay full ammount')
            }
        } else {
            let fullPaid = false
            let status = ''
            let dataID = paymentArr[0].id
            // console.log(dataID)
            if (paymentInfo.paymentAmmount < getPayableDue()) {
                fullPaid = false
                status = 'Due Amount Partial Returned'
            } else {
                fullPaid = true
                status = 'Due Amount Full Returned'
            }
            let debitData = {
                identity: dataID,
                paymentAmmount: paymentInfo.paymentAmmount,
                particulars: 'Sales',
                processorID: userID,
                remark: paymentInfo.paymentRemark,
                paymentType: 'cash',
                created_date: new Date().getTime()
            }
            let dayBookData = {
                created_date: new Date().getTime(),
                paticulars: "Sales (Payment Returned)",
                identity: dataID,
                received: 0,
                payment: paymentInfo.paymentAmmount,
                processorID: userID,
            }
            let summeryData = {
                ProssesedBy: userID,
                sale_id: dataID,
                created_date: new Date().getTime(),
                activity: status,
            }

            console.log(debitData, dayBookData, summeryData)
            //    await SaveDataExpress('debit_voucher', {...debitData})
            //    await SaveDataExpress('day_book', {...dayBookData})
            //    await SaveDataExpress('sales_summery', {...summeryData})
            // alert('data saved')
        }
    }

    async function onlinePayment(paymentArr) {
        let userID = activeUserData.id
        if (paymentArr.length > 1) {
            if (paymentInfo.paymentAmmount === getPayableDue()) {
                paymentArr.forEach(async (item) => {
                    let dataID = item.id
                    let debitData = {
                        identity: dataID,
                        paymentAmmount: item.dueAmmount,
                        particulars: 'Sales',
                        processorID: userID,
                        remark: paymentInfo.paymentRemark,
                        paymentType: 'Bank',
                        created_date: new Date().getTime()
                    }
                    let dayBookData = {
                        created_date: new Date().getTime(),
                        paticulars: "Sales (Payment Returned)",
                        identity: dataID,
                        received: 0,
                        payment: item.dueAmmount * (-1),
                        processorID: userID,
                    }
                    let summeryData = {
                        ProssesedBy: userID,
                        sale_id: dataID,
                        created_date: new Date().getTime(),
                        activity: 'Due Amount Full Returned',
                    }
                    let debitDataInserted = await SaveDebitData(debitData)
                    let bankData = {
                        debitCredit: 2,
                        bankID: paymentInfo.bankID,
                        identity: dataID,
                        receiptNumber: '',
                        voucherNo: debitDataInserted.insertId,
                        receiveAmmount: 0,
                        paymentAmmount: item.dueAmmount,
                        particulars: 'Sales',
                        head: 'Withdraw',
                        processorID: userID,
                        remark: paymentInfo.paymentRemark,
                        transactionType: 'Bank Withdraw',
                        voucherType: 1,
                        created_date: new Date().getTime()
                    }

                    //  console.log(bankData, paymentInfo.paymentAmmount)
                    await DecreaseBankAmount({ id: paymentInfo.bankID, amount: item.dueAmmount })
                    await SaveDayBookData(dayBookData)
                    await SaveSummeryData(summeryData)
                    await SaveBankTransactionData(bankData)

                });

            } else {
                alert('you must pay full ammount')
            }
        } else {
            let fullPaid = false
            let status = ''
            let dataID = paymentArr[0].id
            if (paymentInfo.paymentAmmount < getPayableDue()) {
                fullPaid = false
                status = 'Due Amount Partial Returned'
            } else {
                fullPaid = true
                status = 'Due Amount Full Returned'
            }
            let debitData = {
                identity: dataID,
                paymentAmmount: paymentInfo.paymentAmmount,
                particulars: 'Sales',
                processorID: userID,
                remark: paymentInfo.paymentRemark,
                paymentType: 'Bank',
                created_date: new Date().getTime()
            }
            let dayBookData = {
                created_date: new Date().getTime(),
                paticulars: "Sales (Payment Returned)",
                identity: dataID,
                received: 0,
                payment: paymentInfo.paymentAmmount,
                processorID: userID,
            }
            let summeryData = {
                ProssesedBy: userID,
                sale_id: dataID,
                created_date: new Date().getTime(),
                activity: status,
            }
            let debitDataInserted = await SaveDebitData(debitData)
            let bankData = {
                debitCredit: 2,
                bankID: paymentInfo.bankID,
                identity: dataID,
                receiptNumber: '',
                voucherNo: debitDataInserted.insertId,
                receiveAmmount: 0,
                paymentAmmount: paymentInfo.paymentAmmount,
                particulars: 'Sales',
                head: 'Withdraw',
                processorID: userID,
                remark: '',
                transactionType: 'Bank Withdraw',
                voucherType: 1,
                created_date: new Date().getTime()
            }

            //  console.log(bankData, paymentInfo.paymentAmmount)
            await DecreaseBankAmount({ id: paymentInfo.bankID, amount: paymentInfo.paymentAmmount })
            await SaveDayBookData(dayBookData)
            await SaveSummeryData(summeryData)
            await SaveBankTransactionData(bankData)

            alert('data saved')
        }
    }

    function methodReturn(data) {
        let method = ''
        if (data === 1) {
            method = 'Cash'
        } else if (data === 2) {
            method = 'Online Deposite'
        } else if (data === 3) {
            method = 'Transfered'
        } else {
            method = 'Cheque'
        }
        return method
    }

    const errStyle = {
        display: 'block',
        textAlign: 'right',
        color: 'red',
    }

    return (
        
        <div className={DebitVoucherCustomerCss.debitVoucherCustomerWrapper}>

        
                <div className={DebitVoucherCustomerCss.input_section}>
                    <Select options={CustomerList} getOptionLabel={(option) => `${option.name} - ${option.phone}`} getOptionValue={(option) => option.id} onChange={onCustomerChange} onInputChange={onInputDataChange} />
                    {userData ? <div className={DebitVoucherCustomerCss.input_wrapper}>
                        <p>Customer Name : {userData?.name}</p>
                        <p>Customer Phone : {userData?.phone}</p>
                        <p>Customer Email : {userData?.email}</p>
                    </div> : ''}
                    <div className={DebitVoucherCustomerCss.search_data}>
                        <button onClick={onSearchClick}>Search Data</button>
                    </div>
                </div>
       
            {tableData ? <div className={DebitVoucherCustomerCss.tableSection}>
                {/* <h3>Debit Voucher No : {runningVoucherNo}</h3> */}
                <table>
                    <tbody>
                        <tr>
                            <th>Check
                                {myCheck ? <FontAwesomeIcon style={{ color: 'white', margin: '0 0 0 8px', padding: '0', fontSize: "18px", cursor: 'pointer' }} icon={faSquareCheck} onClick={(e) => { unCheckAll(e) }} /> : <FontAwesomeIcon style={{ color: 'white', margin: '0 0 0 8px', padding: '0', fontSize: "18px", cursor: 'pointer' }} icon={faSquare} onClick={(e) => { CheckAll(e) }} />}
                            </th>
                            <th>Date</th>
                            <th>Invoice No</th>
                            <th>Total Ammount</th>
                            <th>Paid Ammount</th>
                            <th>Return Ammount</th>
                            <th>Discount</th>
                            <th>Due Ammount</th>
                        </tr>

                        {tableData?.map((item, index) => {
                            return <tr key={index}>
                                <td>{item.nowPay ? <FontAwesomeIcon icon={faSquareCheck} onClick={(e) => { unCheckData(e, item.id) }} /> : <FontAwesomeIcon  icon={faSquare} onClick={(e) => { CheckData(e, item.id) }} />} </td>
                                <td>{LocalTimeStampToDate(item.created_date)}</td>
                                <td>{item.id}</td>
                                <td>{item.total_price}</td>
                                <td>{item.paymentAmmount}</td>
                                <td>{item.receiveAmount}</td>
                                <td>{item.discount}</td>
                                <td>{item.due}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
                <div className={DebitVoucherCustomerCss.other_information}>
                    <div className={DebitVoucherCustomerCss.text_section}>
                        <p className={DebitVoucherCustomerCss.ledger_ballnce}>Customer Ledger Balance : {getLeadgerBalance()} Tk</p>
                        <div className={`${DebitVoucherCustomerCss.payment_info} ${DebitVoucherCustomerCss.limit_due}`}>
                            <p>Payment Limit : 0</p>
                            <p>Payable Due : {getPayableDue()} Tk</p>
                        </div>
                    </div>
                    <div className={DebitVoucherCustomerCss.payment_info}>
                        <div className={DebitVoucherCustomerCss.input_section}>
                            <label>Payment Method*</label>
                            <select name='paymentMethod' onChange={onDataChange}>
                                <option value={1}>Cash</option>
                                <option value={2}>Online Deposite</option>
                                <option value={3}>Transferred</option>
                                <option value={4} >Cheque</option>
                            </select>
                        </div>
                        {paymentInfo?.paymentMethod === 2 || paymentInfo?.paymentMethod === 3 ? <>
                            <div className={DebitVoucherCustomerCss.input_section} >
                                <label>Payment Sub Method*</label>
                                <select name='sub_method' onChange={onDataChange}>
                                    <option value={''}>Select Type</option>
                                    <option value='BEFTN'>BEFTN</option>
                                    <option value='NPSB'>NPSB</option>
                                    <option value='RTGS'>RTGS</option>
                                </select>
                            </div>
                            {errMessage ? <p className={DebitVoucherCustomerCss.WarningText} > {errMessage?.sub_methodErr}</p> : ''}

                            <div className={DebitVoucherCustomerCss.input_section}>
                                <label>Select Bank Account*</label>
                                <select name='bankID' onChange={onDataChange}>
                                    <option value={''}>Select Bank</option>
                                    {myBankList?.map((item, index) => {
                                        return <option key={index} value={item.id}>{item.bankName} - ({item.acNumber})</option>
                                    })}
                                </select>
                            </div>
                            {errMessage ? <p className={DebitVoucherCustomerCss.WarningText} > {errMessage?.bankIDErr}</p> : ''}
                        </> : ''}
                        <div className={DebitVoucherCustomerCss.input_section}>
                            <label>Payable Ammount*</label>
                            <input onChange={onDataChange} name='paymentAmmount' type='number' placeholder='payment ammount' value={paymentInfo?.paymentAmmount} />
                        </div>
                        <p style={errStyle}>{errMessage?.payErr}</p>
                        <div className={DebitVoucherCustomerCss.input_section}>
                            <label>Payment Remarks*</label>
                            <input onChange={onDataChange} name='paymentRemark' type='text' placeholder='payment remark here' value={paymentInfo?.remark} />
                        </div>
                        <p style={errStyle}>{errMessage?.remarkErr}</p>
                        <div className={DebitVoucherCustomerCss.input_section}>
                            <label>Date*</label>
                            <input onChange={onDataChange} name='paymentDate' type='datetime-local' value={paymentInfo?.paymentDate} />
                        </div>
                        <p style={errStyle}>{errMessage?.dateErr}</p>
                        <div className={DebitVoucherCustomerCss.input_section}>
                            <label>Remaining*</label>
                            <input type='text' disabled value={paymentInfo?.paymentAmmount ? getPayableDue() - paymentInfo?.paymentAmmount : getPayableDue()} />
                        </div>
                        <div className={DebitVoucherCustomerCss.button_section}>
                            <button onClick={onSaveClick}>Save</button>
                        </div>
                    </div>
                </div>
            </div> : ''}
        </div>
         
      
    )
}

export default DebitVoucherCustomer