import React, { useState } from "react";
import AddnewUser from "./AddnewUser";
import manageUserCss from "./css/manageUser.module.css";
import ActiveUsers from "./tabComps/ActiveUsers";
import InActiveUsers from "./tabComps/InActiveUsers";
export default function ManageUser() {
  const [addUserShow, setAddUserShow] = useState(false);
  const [userActivet, setuserActivet] = useState(true);

  function onAddNewUser() {
    setAddUserShow(true);
  }

  function onInactiveUser() {
    setuserActivet(false);
  }
  function onActiveUser() {
    setuserActivet(true);
  }
  return (
    <div className={manageUserCss.wrapper}>
      {addUserShow ? <AddnewUser setAddUserShow={setAddUserShow} /> : ""}

      <div className={manageUserCss.holder}>
        <h3>User List</h3>
        <div className={manageUserCss.header_button_wrapper}>
          <button onClick={onAddNewUser}>Add New User</button>
          {userActivet ? (
            <button onClick={onInactiveUser}>Inactive User</button>
          ) : (
            <button onClick={onActiveUser}>Active User</button>
          )}
        </div>
      </div>
      {userActivet ? <ActiveUsers /> : <InActiveUsers />}
    </div>
  );
}
