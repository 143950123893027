import React, { useEffect, useState } from 'react'
import { GetAllDataExpress } from '../../../api/ExpressFunctions'
import { Route, Routes } from 'react-router-dom'
import ExpenseReportPDF from './ExpenseReportPDF'
import ExpenseReportCss from './css/ExpenseReport.module.css'

const ExpenseReport = () => {
    const [viewState, setViewState] = useState(1)
    function getColor() {
        let leftPart = document.querySelector('.sidepart')
        leftPart?.classList.remove('display-none')
    }
    useEffect(()=>{
        getColor()
    },[])
    return (
        <>
        <Routes>
            <Route path='/' element={<MainComp/>}/>
            <Route path='/expense-view' element={<ExpenseReportPDF/>}/>
        </Routes>
        </>
    )
}

export default ExpenseReport

const MainComp = () => {
    const [groupData, setGroupData] = useState()
    const [SearchData, setSearchData] = useState()
    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        if (name==='time_selection') {
            setSearchData({[name]:value})
        }else{
            setSearchData({...SearchData, [name]:value})
        }
    }

    function onSearchClick() {
        let staticLink = '/admin/report/expense_report/expense-view'
        let queryString = ''
      //  console.log(SearchData?.groupid, groupData)
        if (SearchData?.time_selection==='custom_range') {
            if (SearchData?.start_date && SearchData?.end_date) {
                if (SearchData?.groupid) {
                    queryString = queryString = `/?start_date=${SearchData.start_date}&end_date=${SearchData.end_date} &group=${SearchData?.groupid}`
                    window.open(staticLink+queryString, '_blank')
                } else {
                    queryString = `/?start_date=${SearchData.start_date}&end_date=${SearchData.end_date}`
                    window.open(staticLink+queryString, '_blank')
                }
            }else{
                alert('Please Select Date Range to Continue')
            }
        }else{
            if (SearchData?.groupid) {
                queryString = `/?group=${SearchData.groupid}`
                window.open(staticLink+queryString, '_blank')
            } else {
                window.open(staticLink, '_blank')
            }
           
        }
    }
   async function getExpenseGroup() {
        let myGroupData = await GetAllDataExpress('expense_group')
       // console.log(myGroupData)
       setGroupData(myGroupData)
    }
    useEffect(()=>{
       getExpenseGroup()
    },[])
   
    return <>
         <section className={ExpenseReportCss.heading}>
                <div className={ExpenseReportCss.inner_headign}>
                    <h2 className={ExpenseReportCss.headign_h2}>Expense Report</h2>
        <div className={ExpenseReportCss.input_section}>
            <div className={ExpenseReportCss.common}>
                <select name='time_selection' onChange={onDataChange}>
                    <option value={'all-time'}>All Time</option>
                    <option value={'custom_range'}>Custom Range</option>
                </select>                
            </div>
            <div className={ExpenseReportCss.common}>
                <select name='groupid' onChange={onDataChange}>
                   <option value={''}>Select Group</option>
                   {groupData?.map((item)=>{
                        return <option key={item.id} value={item.id}>{item.groupName}</option>
                   })}
                </select>             
            </div>
        </div>
        {SearchData?.time_selection ==='custom_range' ? 
        <><div className={`${ExpenseReportCss.input_section2} ${ExpenseReportCss.date_section}`}>
        <div className={ExpenseReportCss.common}>
            <label>Start Date</label>
            <input name='start_date' type='date' onChange={onDataChange} />
        </div>
        <div className={ExpenseReportCss.common}>
            <label>End Date</label>
            <input name='end_date' type='date' onChange={onDataChange} />
        </div>
    </div></> : 
        ''}
        <div className={ExpenseReportCss.input_section2}>
            <div className={ExpenseReportCss.common}>
                <button className={ExpenseReportCss.report_button} onClick={onSearchClick}>Search</button>
            </div>
        </div>
        </div>
            </section>
    </>
}
