import React, { useEffect, useState } from 'react'
import {useParams} from 'react-router-dom'
import { LocalTimeStampToDate } from '../../MyFunctions/MyFunctions'
import { GetDataPassQuery } from '../../../api/ExpressFunctions'
import { HeaderFooterWrapper } from '../HeadetFooter'
import { GetFilterSupplier } from '../../../api/SupplierApi'
import { GetIndivisualLadgerData } from '../../../api/ReportApi/PurchaseReportAPI'
import IndividualLedgerCss from './css/IndividualLedger.module.css'

const IndividualLedger = ({heading, date}) => {
    const myParams = useParams()
    const [invoiceState, setInvoiceState] = useState()
    const [supplierInfo, setSupplierInfo] = useState({})

 async function getData() {
     let supplier_id = myParams.reportShow
     let invoiceData = supplier_id ? await GetIndivisualLadgerData({ supplierid: supplier_id }) : []
     let supplierData = await GetFilterSupplier(supplier_id)
     let okData = {
        name:supplierData[0]?.companyaName
    }
     setSupplierInfo(okData)
     let allArr = []
     let myTempBalance = 0
     invoiceData?.map((item, index)=>{
        let discount = item.percent ? item.percent:0
         if (index === 0) {
             myTempBalance = item.grandTotal
         } else {
             myTempBalance += item.grandTotal
         }
        let purchaseData = {
            date:item.created_date,
            PoNo:item.id,
            purchaseAmmount: item.grandTotal - (item.grandTotal*discount/100),
            receiptAmmount:item.paymentAmmount,
            paymentAmmount:item.payAmmount ? item.payAmmount:0,
            remark:'',
            tempBalance:item.total - (item.total*discount/100),
            returnAmmount:item.receiveAmount,
            balance:myTempBalance
        }
        allArr.push(purchaseData)
    })
     setInvoiceState(allArr)
     
   
    }
    function myDataOrganize(passedData, returnArr, paymentArr) {
        let allArr = []
        passedData?.map((item)=>{
            let discount = item.percent ? item.percent:0
            let purchaseData = {
                date:item.created_date,
                PoNo:item.id,
                purchaseAmmount: item.grandTotal - (item.grandTotal*discount/100),
                receiptAmmount:0,
                paymentAmmount:item.payAmmount ? item.payAmmount:0,
                remark:'',
                tempBalance:item.total - (item.total*discount/100),
                returnAmmount:0
            }

            let returnArrb =  getReturnItem(returnArr, item.id, discount)
            let paymentArrb =  getPaymentAmmount(paymentArr, item.id, item.grandTotal)
            allArr.push(purchaseData, ...returnArrb, ...paymentArrb)

        })
        getitemWithBalance(allArr)
    }
    function  getitemWithBalance(itemArr) {
        let okArr = []
        itemArr?.map((item)=>{
                let mBalance = 0
                if (item.purchaseAmmount && item.paymentAmmount) {
                    mBalance = item.purchaseAmmount - item.paymentAmmount
                }
                else if (item.purchaseAmmount) {
                    mBalance = item.purchaseAmmount
                } else if (item.returnAmmount) {
                    mBalance = -item.returnAmmount
                }else if (item.receiptAmmount) {
                    mBalance = item.receiptAmmount
                }else if(item.paymentAmmount){
                    mBalance = -item.paymentAmmount
                }
                item = {...item,
                    balance:mBalance
                }
                okArr.push(item)
        })
        let sortArr = okArr.sort((a, b)=>(a.date>b.date)? 1:-1)
        finalBanlance(sortArr)
    }
    function finalBanlance(balanceArr) {
        let finalArr = []
        let myTempBalance = 0
       for (let index = 0; index < balanceArr.length; index++) {
        let element = balanceArr[index];
            if (index===0) {
                element = {...element, balance:element.balance}
                finalArr.push(element)
                myTempBalance = element.balance
            }else{
                myTempBalance += element.balance
                element = {...element, balance:myTempBalance}
                finalArr.push(element)
            }
       }
       setInvoiceState(finalArr)

    }
    function getReturnItem(returnArr, pono, discount) {
        let organizedArr =  organizeReturnData(returnArr)
        let returnArrFinal = []
        organizedArr?.map((ritem)=>{
            let ammount = 0
            ritem?.map((finalItem)=>{
                console.log(finalItem)
                if (pono===finalItem.po_id) {
                    let tempAmmount = finalItem.price*finalItem.quantity
                ammount += tempAmmount - (tempAmmount*discount/100)
                
                }
            })
            let returnData = {
                date:ritem[0].created_date,
                PoNo:pono,
                purchaseAmmount: 0,
                receiptAmmount:0,
                paymentAmmount:0,
                returnAmmount: Math.round(ammount),
                remark:'',
            }

            if (ammount>0) {
                returnArrFinal.push(returnData)
            }
        })
            return returnArrFinal
        }

        function organizeReturnData(myData) {
            let finalArr = []
            let uniqueElements = myData.slice()
            for (let index = 0; index < myData.length; index++) {
                const element = myData[index];
                let tempArr = []
                for (let mindex = 0; mindex < uniqueElements.length; mindex++) {
                    const melement = uniqueElements[mindex];
                    if (melement.date === element.date) {
                        tempArr.push(melement)
                    }
                }
                
                if (tempArr.length > 0) {
                    finalArr.push(tempArr)
                    tempArr.forEach(element => {
                        uniqueElements.forEach((melement, mindex) => {
                            if (melement.date === element.date) {
                                uniqueElements.splice(mindex, 1) 
                            }
                        });
                    });
                }
    
            }

            return finalArr
            // console.log('final', finalArr)
    
        }
    
    function getPaymentAmmount(paymentArr, pono) {
        let returnArrFinal = []

        paymentArr?.map((pitem)=>{
            if (pono===pitem.identity) {
                if (pitem.paymentAmmount>0) {
                    let returnData = { 
                        date:pitem.created_date,
                        PoNo:pono,
                        purchaseAmmount: 0,
                        receiptAmmount:0,
                        paymentAmmount:pitem.paymentAmmount,
                        returnAmmount: 0,
                        remark:pitem.remark,
                    }
                    returnArrFinal.push(returnData)
                }
            }
            })

            return returnArrFinal
        }

function checkClick() {
        console.log(invoiceState)
    }

    useEffect(() => {
        getData()
    }, [])

    function getAlltotal(array, identity) {
        let allTotal = 0
        array?.map((item)=>{
            allTotal += item[identity] ?  parseInt(item[identity]):0
        })
       return allTotal
    }
    
  return (
    <>
    <HeaderFooterWrapper
    content={<>
    <h3>{heading}</h3>
            <div className={IndividualLedgerCss.details}>
                <div className={IndividualLedgerCss.client}>
                    <div className={IndividualLedgerCss.info}>
                        <span className={IndividualLedgerCss.moreinfo} onClick={checkClick}>Supplier : </span>{supplierInfo?.name}
                    </div>
                </div>
                <div className={IndividualLedgerCss.invoice}>
                    <div className={IndividualLedgerCss.info}>
                        {date ? <span className={IndividualLedgerCss.moreinfo}>Date : {date} </span>:''}
                    </div>
                </div>
            </div>
            <div className={IndividualLedgerCss.table}>
                <table>
                    <thead>
                        <tr>
                            <th>Date & Time</th>
                            <th style={{ paddingLeft: '10px'}}>Po No.</th>
                            <th className={IndividualLedgerCss.unit}>Purchase Ammount</th>
                            <th className={IndividualLedgerCss.unit_price}>Return Ammount</th>
                            <th className={IndividualLedgerCss.total}>Receipt</th>
                            <th className={IndividualLedgerCss.total}>Payment</th>
                            <th className={IndividualLedgerCss.total}>Balance</th>
                            <th className={IndividualLedgerCss.total}>Remark</th>
                        </tr>
                    </thead>
                    <tbody>
                    {invoiceState?.map((item, index)=>{
                        return <>
                            <tr key={index}>
                            <td>{LocalTimeStampToDate(item.date)}</td>
                            <td style={{ textAlign: 'left'}} >{item.PoNo}</td>
                            <td>{item.purchaseAmmount}</td>
                            <td>{item.returnAmmount}</td>
                            <td>{item.receiptAmmount}</td>
                            <td>{item.paymentAmmount}</td>
                            <td>{item.balance}</td>
                            <td>{item.remark || 'no note'}</td>
                            </tr>
                        </>
                    })}
                    
                    <tr className={IndividualLedgerCss.final_row}>
                        {/* <td className=no-border-right' ></td> */}
                        <td  colSpan='2'>Total :</td>
                        <td>{getAlltotal(invoiceState, 'purchaseAmmount')}</td>
                        <td>{getAlltotal(invoiceState, 'returnAmmount')}</td>
                        <td>{getAlltotal(invoiceState, 'receiptAmmount')}</td>
                        <td>{getAlltotal(invoiceState, 'paymentAmmount')}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr className={IndividualLedgerCss.final_row}>
                        {/* <td className='no-border-right' ></td> */}
                        <td  colSpan='6'>Total : </td>
                        <td colSpan='2'> {getAlltotal(invoiceState, 'purchaseAmmount')-getAlltotal(invoiceState, 'returnAmmount')+getAlltotal(invoiceState, 'receiptAmmount')-getAlltotal(invoiceState, 'paymentAmmount')}</td>
                       
                    </tr>
                    </tbody>
                </table>
                
            </div>
    </>}
    />
       
    </>
  )
}

export default IndividualLedger