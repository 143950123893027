import React from 'react'

const Table = (props) => {
    const { param1, param2, param3, param4, param5, param6, param7, param8, param9, param10 } = props

    let dataArr = []

    if (param1 != undefined) {
        dataArr.push(param1)
    }
    if (param2 != undefined) {
        dataArr.push(param2)

    }
    if (param3 != undefined) {
        dataArr.push(param3)

    }
    if (param4 != undefined) {
        dataArr.push(param4)

    }
    if (param5 != undefined) {
        dataArr.push(param5)

    }
    if (param6 != undefined) {
        dataArr.push(param6)

    }
    if (param7 != undefined) {
        dataArr.push(param7)

    }
    if (param8 != undefined) {
        dataArr.push(param8)

    }
    if (param9 != undefined) {
        dataArr.push(param9)

    }
    if (param10 != undefined) {
        dataArr.push(param10)

    }

    const mData = dataArr.map((item, index) => {
        return <td key={index + 1}>{item}</td>
    })


    return (
        <>
            <tr>

                {mData}

            </tr>
        </>
    )
}

export default Table