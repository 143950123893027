import React, { useEffect, useState } from "react";
import styles from "./css/userinfoview.module.css";
import { GetUserImage } from "../../../api/BackendUserApi";
import DummyImage from "../../../assets/dummyuser.png";
export default function UserInfoView({ userInfo, setUserInfo }) {
  function onCloseProfileView() {
    setUserInfo();
    document.body.style.overflow = "auto";
  }

  return (
    <div className={styles.info_pop_Wrapper}>
      <div className={styles.info_pop_holder}>
        <div className={styles.image_wrapper}>
          <AvatarComp name={userInfo.userImageName} />
        </div>
        <div>
          <div>
            <p>Name : {userInfo.fullName}</p>
            <p>User Name : {userInfo.userName}</p>
            <p>Email : {userInfo.email}</p>
            <p>Bio : {userInfo.userbio}</p>
            <p>Rule : {userInfo.userrole}</p>
          </div>
        </div>
        <div className={styles.button_Wrpper}>
          <button onClick={onCloseProfileView}>Close</button>
        </div>
      </div>
    </div>
  );
}
async function getLink(name, setImage) {
  if (name) {
    let imageInfo = await GetUserImage({ image_name: name });
    if (imageInfo.imageExist) {
      setImage(imageInfo.url);
    } else {
      setImage(DummyImage);
    }
  } else {
  }
}

const AvatarComp = ({ name }) => {
  const [image, setImage] = useState();

  useEffect(() => {
    getLink(name, setImage);
  }, [name]);
  return (
    <>
      <img className={styles.userImage} src={image} alt="avatar" />
    </>
  );
};
