import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GetLimitCustomer, GetSearchedCustomer } from '../../api/CustomerApi'

const initialState = {
  initCustomer:[],
  status:'idle'
}
export const CustomerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setInitCustomer: (state, action) => {
      state.initCustomer = action.payload
    },
    setStatus: (state, action) => {
        state.status = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchInitCustomer.pending, (state, action) => {
      state.status = 'pending'
    })
    .addCase(fetchInitCustomer.fulfilled, (state, action) => {
      state.status = 'success'
      let mData = action.payload
        state.initCustomer = mData
    })
    .addCase(fetchInitCustomer.rejected, (state, action) => {
      state.status = action.payload
    })
    .addCase(fetchSearchedCustomer.pending, (state, action) => {
      state.status = 'pending'
    })
    .addCase(fetchSearchedCustomer.fulfilled, (state, action) => {
      state.status = 'success'
      let mData = action.payload
        state.initCustomer = mData
    })
    .addCase(fetchSearchedCustomer.rejected, (state, action) => {
      state.status = action.payload
    })
},
})

export const fetchInitCustomer = createAsyncThunk('customer/fetchInitCustomer', async (limit)=>{
  try {
    const resposne = await GetLimitCustomer({limit:limit})
    return resposne
  } catch (error) {
    return error.message
  }
})
export const fetchSearchedCustomer = createAsyncThunk('customer/fetchSearchedCustomer', async (search_text)=>{
  try {
    const resposne = await GetSearchedCustomer({search_text:search_text})
    return resposne
  } catch (error) {
    return error.message
  }
})

export const selectInitCustomer = state =>state.customer.initCustomer
export const selectCustomerStatus = state =>state.customer.status
export const { setInitCustomer, setStatus} = CustomerSlice.actions

export default CustomerSlice.reducer
