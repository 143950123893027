import React from "react";
import styles from "./updatedate.module.css";
import Select from "react-select";
import { OrderMethod, OrderMethodObject } from "../../global/GlobalConstant";
import { AddOrderMethod, UpdateOrderMethod } from "../../../api/SalesApi";
import { toast } from "react-toastify";

export default function SourceModel({ popUp, setPopUp }) {
  const sourceData = popUp.source;
  /*
    order_method_id int AI PK 
method_code int 
order_id int 
remark
    */
  async function onUpdateSource(e) {
    e.preventDefault();
    const method_code = e.target.method_code.value;
    const remark = e.target.remark.value;
    const order_method_id = popUp.source?.order_method_id;
    console.log(popUp);

    try {
      if (order_method_id) {
        await UpdateOrderMethod(order_method_id, {
          method_code,
          order_id: popUp.id,
          remark,
        });
        toast.success("Order Source Added Successfully.");
      } else {
        await AddOrderMethod({ method_code, order_id: popUp.id, remark });
        toast.success("Order Source Added Successfully.");
      }
      setPopUp();
      document.body.style.overflow = "auto";
    } catch (error) {
      toast.error(error.response.data || error.message);
    }
    // console.log(method_code, popUp.id, remark);
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.holder} aria-label="source">
        <h2>Update Order Source #{popUp.id}</h2>
        <form aria-label="source" onSubmit={onUpdateSource}>
          <div className={styles.input_wrap}>
            <label>Source</label>
            <Select
              options={OrderMethod}
              name="method_code"
              className={styles.myselect}
              required
              placeholder={OrderMethodObject[sourceData.method_code]}
            />
          </div>
          <div className={styles.input_wrap}>
            <label>Note</label>
            <input
              name="remark"
              placeholder="note"
              defaultValue={sourceData.remark}
            />
          </div>
          <div className={styles.source_buttons}>
            <button
              type="button"
              onClick={() => {
                setPopUp();
                document.body.style.overflow = "auto";
              }}
            >
              Close
            </button>
            <button>Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}
