import React from 'react'
import editCustomer from './editcustomer.module.css'
import DummyImage from '../../../assets/dummyuser.png'
const EditCustomer = ({popUp, setPopUp}) => {

    async function onUpdateClick(e) {
        e.preventDefault()
        const name = e.target.name.value
        const email = e.target.email.value
        const about = e.target.about.value
        // console.log({name, email, about});
        setPopUp()
        document.body.style.overflow = 'auto'
    }
    function ClosePop() {
        setPopUp()
        document.body.style.overflow = 'auto'
    }
  return (
    <div className={editCustomer.EditUserPop_wrapper}>
    <div className={editCustomer.EditUserPop}>
      <div className={editCustomer.profile} >
        {popUp?.userImage ? <img name='userImage' src={!popUp?.fakeUrl ? popUp.userImage : popUp?.fakeUrl} alt='user image here' /> : ""}

        {!popUp?.userImage ? <img name='userImage' src={!popUp?.fakeUrl ? DummyImage : popUp?.fakeUrl} alt='user image here' /> : ""}
      </div>

        <form onSubmit={onUpdateClick} className={editCustomer.filter_sections} >
            <div className={editCustomer.common}>
              <label>Full Name</label>:
              <input name='name' type='text' placeholder="Full Name" defaultValue={popUp?.name} required />
          </div>
          <div className={editCustomer.common}>
              <label>Email</label>: 
              <input name='email' type='text' placeholder="Email" defaultValue={popUp?.email}  />
          </div>
          <div className={editCustomer.common}>
              <label>Phone</label>:
              <input name='notchangeable' type='text' disabled placeholder="Phone" defaultValue={popUp?.phone}  />
          </div>
          <div className={editCustomer.common}>
              <label>Bio</label>:
              <input name='about' type='text' placeholder="about" defaultValue={popUp?.about}  />
          </div>

          <div className={editCustomer.common}>
              <label>Upload Image</label>:
              <input name='userImageUpdated' type='file'  />
          </div>
          
        </form>
        <div className={editCustomer.btn_group}>
              <button type='button' onClick={ClosePop}>Close</button>
              <button type='submit'>Update Info</button>
          </div>

    </div>
  </div>
  )
}

export default EditCustomer