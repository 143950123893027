import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import AccountAraGlanceReportPDF from './AccountAraGlanceReportPDF'
import AccountAraGlanceReportCss from './css/AccountAraGlanceReport.module.css'
const AccountAraGlanceReport = () => {
      function getColor() {
        let leftPart = document.querySelector('.sidepart')
        leftPart?.classList.remove('display-none')
    }
    useEffect(()=>{
        getColor()
    },[])
    return (
        <>
            <Routes>
                <Route path='/' element={<MainComp/>}/>
                <Route path='/account_ata_glance_view' element={<AccountAraGlanceReportPDF/>}/>
            </Routes>
        </>
    )
}

export default AccountAraGlanceReport

const MainComp = () => {
    const [SearchData, setSearchData] = useState()
    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        if (name==='time_selection') {
            setSearchData({[name]:value})
        }else{
            setSearchData({...SearchData, [name]:value})
        }
    }

    function onSearchClick() {
        let staticLink = '/admin/report/account_ata_glance/account_ata_glance_view'
        let queryString = ''
        if (SearchData?.time_selection === 'custom_range') {
            if (SearchData?.start_date && SearchData?.end_date) {
                queryString = `/?start_date=${SearchData.start_date}&end_date=${SearchData.end_date}`
                window.open(staticLink + queryString, '_blank')
            } else {
                alert('Please Select Date Range to Continue')
            }
        } else {
            if (SearchData?.groupid) {
                queryString = `/?group=${SearchData.groupid}`
                window.open(staticLink + queryString, '_blank')
            } else {
                window.open(staticLink, '_blank')
            }

        }
    }

    return <>
     <section className={AccountAraGlanceReportCss.heading}>
                <div className={AccountAraGlanceReportCss.inner_headign}>
                    <h2 className={AccountAraGlanceReportCss.headign_h2}>Account At a glance report</h2>
        <div className={AccountAraGlanceReportCss.input_section}>
            <div className={AccountAraGlanceReportCss.common}>
                <select name='time_selection' onChange={onDataChange}>
                    <option value={'all-time'}>All Time</option>
                    <option value={'custom_range'}>Custom Range</option>
                </select>                
            </div>
        </div>
        {SearchData?.time_selection ==='custom_range' ? 
        <><div className={`${AccountAraGlanceReportCss.input_section} ${AccountAraGlanceReportCss.date_section}`}>
        <div className={AccountAraGlanceReportCss.common}>
            <label>Start Date</label>
            <input name='start_date' type='date' onChange={onDataChange} />
        </div>
        <div className={AccountAraGlanceReportCss.common}>
            <label>End Date</label>
            <input name='end_date' type='date' onChange={onDataChange} />
        </div>
    </div></> : 
        ''}
        <div className={AccountAraGlanceReportCss.input_section}>
            <div className={AccountAraGlanceReportCss.common}>
                <button className={AccountAraGlanceReportCss.report_button} onClick={onSearchClick}>Search</button>
            </div>
        </div>
        </div>
            </section>  
    </>
}
