import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectBrandList } from '../../../ReduxFiles/Slices/BrandListSlice'
import { selectCategoryList } from '../../../ReduxFiles/Slices/CategorySlice'
import { GetFilterProductVariance } from '../../../api/ProductVarianceApi'
import { GetProductStock, GetVarianceStock } from '../../../api/StockApi'
import DropDownSearch from '../../smallThings/DropDownSearch'
import StockDetailsCss from './css/StockDetails.module.css'

const StockDetails = () => {
    const brandList = useSelector(selectBrandList)
    const catagoryList = useSelector(selectCategoryList)
    const [allData, setAllData] = useState([])
    const [tableData, setTableData] = useState()
    const [paginationNumber, setPaginationNumber] = useState()
    const [customPageNumber, setCustomPageNumber] = useState(1)
    const [viewValue, setViewValue] = useState(10)
    const [spinnerShow, setSpinnerShow] = useState(false)
    const nextRef = useRef()
    const preRef = useRef()
    const [varianceData, setVarianceData] = useState()

    function onViewChange(e) {
        setViewValue(parseInt(e.target.value))
    }
    useEffect(() => {
        if (allData) {
            paginateData(1)
            let pageNumber = allData.length / viewValue
            let vagshesh = allData.length % viewValue
            if (vagshesh > 0) {
                //console.log(vagshesh)
                pageNumber = parseInt(pageNumber) + 1
            }
            setPaginationNumber(pageNumber)
        }
    }, [viewValue, allData])

    async function getAllProducts() {
        setSpinnerShow(true)
        const stockData = await GetProductStock()
        let sortArr = stockData.sort((a, b) => (a.quantity > b.quantity) ? -1 : 1)
        let allArr = []
        sortArr?.forEach((element, index) => {
            element = { ...element, index: index }
            allArr.push(element)
        });
        setAllData(allArr)
        setAllData(allArr)
        setSpinnerShow(false)
    }

    function onPageNumberChange(e) {
        let value = 0
        if (parseInt(e.target.value) > paginationNumber) {
            setCustomPageNumber(paginationNumber)
            value = paginationNumber
        } else if (parseInt(e.target.value) < 1) {
            setCustomPageNumber(1)
            value = 1
        } else {
            setCustomPageNumber(parseInt(e.target.value))
            value = parseInt(e.target.value)
        }
        paginateData(value)
        e.target.select()
    }

    function NextData(params) {
        paginateData(customPageNumber + 1)
    }

    function PreviousData(params) {
        paginateData(customPageNumber - 1)
    }

    function paginateData(value) {
        let myArr = []
        let counter = viewValue * (value)
        if (counter >= allData.length) {
            counter = allData.length
            nextRef.current.classList.add('button-disable')
            nextRef.current.disabled = true
            // console.log(nextRef.current)
        }

        for (let index = viewValue * (value - 1); index < counter; index++) {
            const element = allData[index];
            myArr.push(element)
        }
        setTableData(myArr)

        if (value < paginationNumber) {
            setCustomPageNumber(value)
        } else {
            setCustomPageNumber(paginationNumber)
        }

        if (value <= 1) {
            preRef.current.classList.add('button-disable')
            preRef.current.disabled = true
            nextRef.current.classList.remove('button-disable')
            nextRef.current.disabled = false
        } else if (value >= paginationNumber) {
            preRef.current.classList.remove('button-disable')
            preRef.current.disabled = false
            nextRef.current.classList.add('button-disable')
            nextRef.current.disabled = true
        } else {
            preRef.current.classList.remove('button-disable')
            preRef.current.disabled = false
            nextRef.current.classList.remove('button-disable')
            nextRef.current.disabled = false
        }
    }


    useEffect(() => {
        getAllProducts()
        nextRef.current.classList.remove('button-disable')
        nextRef.current.disabled = false
        preRef.current.classList.add('button-disable')
        preRef.current.disabled = true
    }, [])
    //item.stockAmmount item.selected_brand_id
    const TableItem = tableData?.map((item, index) => {
        return <MyTable key={index} sl={item.index + 1} itemName={item.en_sort_title} brand={item.BrandName}
            catagory={item.catName} quantity={<>
                {item.hasVariance ? <><button className={StockDetailsCss.view_btn} onClick={(e) => { onVarianceView(e, item); document.body.style.overflow = 'hidden' }}>view</button></> : <>{item.quantity ? <span style={{ color: 'green' }}>{item.quantity}</span> : <span style={{ color: 'red' }}>Empty Stock</span>}</>}
            </>} lastPrice={item.price}
        />
    })
    async function onVarianceView(e, item) {
        // console.log(item)
        let allVariance = await GetFilterProductVariance(`product_id = ${item.id}`) //await GetFilteredDataExpress('product_vriance', 'product_id', item.id)
        allVariance.sort((a, b) => (parseInt(a.Variance_name) > parseInt(b.Variance_name)) ? 1 : -1)
        let myData = {
            allVariance: allVariance,
            mainItem: item
        }
        setVarianceData(myData)
    }
    function serchDataClick(item) {
        let mySingleData = []
        mySingleData.push(item)
        setTableData(mySingleData)
    }
    function onResetClick(params) {
        //   window.FontFaceSetLoadEvent()
        window.location.reload(true);
    }
    const [minimunQuantity, setMinimumQuantity] = useState()
    function onMinimumChange(e) {
        if (e.target.value) {
            setMinimumQuantity(parseInt(e.target.value))
        }
    }
    function onMinimumClick() {
        let myArr = []
        allData?.map((item) => {
            if (item.stockAmmount <= minimunQuantity || !item.stockAmmount) {
                myArr.push(item)
            }
        })
        setTableData(myArr)
    }
    const [searchData, setSearchData] = useState()
    function onBrandChange(e) {
        let value = e.target.value
        let newArr = []
        if (value) {
            allData?.map((item) => {
                if (item.selected_brand_id === parseInt(value)) {
                    newArr.push(item)
                }
            })
        } else {
            newArr = allData
        }
        setSearchData({ ...searchData, brand: value, catagory: '' })
        setTableData(newArr)
    }


    function onCatagoryChange(e) {
        let value = e.target.value
        let newArr = []
        if (value) {
            allData?.map((item) => {
                if (item.selected_catagory_id === parseInt(value)) {
                    newArr.push(item)
                }
            })
        } else {
            newArr = allData
        }
        setSearchData({ ...searchData, catagory: value })
        setTableData(newArr)
    }

    return (
        <>
            {spinnerShow ? <Spinner text={'Loading...'} /> : ""}
            <div className={StockDetailsCss.wrapper}>

                {varianceData ? <VarianceInfo varianceData={varianceData} setVarianceData={setVarianceData} /> : ''}
                {/* <DropDownSearch mainArray ={tableData} searchField={'en_sort_title'}/> */}
                <div className={StockDetailsCss.holder}>
                    <h3>Stock Details</h3>
                    {/* <DropDownSearch mainArray={allData} searchField={'en_sort_title'} showDataField={'stockAmmount'} onMyClick={serchDataClick} /> */}
                    <div className={StockDetailsCss.search_holder}>
                        <h4 className={StockDetailsCss.filteritemh3}>Filter Item List</h4>
                        <div className={StockDetailsCss.search_wrapper}>
                            <div className={StockDetailsCss.common}>
                                <select onChange={onBrandChange}>
                                    <option value={''}>-Select Brand-</option>
                                    {brandList?.map((item, index) => {
                                        return <option key={index} value={item.id}>{item.BrandName}</option>
                                    })}
                                </select>
                            </div>

                            <div className={StockDetailsCss.common}>
                                <select onChange={onCatagoryChange} >
                                    <option value={''}>-Select Categories-</option>
                                    {catagoryList?.map((item, index) => {
                                        return <option key={index} value={item}>{
                                            item.catName
                                        }</option>
                                    })}
                                </select>
                            </div>

                            <div className={StockDetailsCss.common}>
                                <input type={'number'} placeholder='Type Minimun Quantity' onChange={onMinimumChange} />
                            </div>

                            <div className={StockDetailsCss.StockDetails_info_button}>
                                <button onClick={onMinimumClick}>Submit</button>
                                <button onClick={onResetClick}>Reset</button>
                            </div>
                        </div>
                    </div>
                    <div className={StockDetailsCss.view_quantity}>
                        <div className={StockDetailsCss.StockViewSearch}>
                            <DropDownSearch mainArray={allData} searchField={'en_sort_title'} showDataField={'stockAmmount'} onMyClick={serchDataClick} />
                        </div>

                        <div className={StockDetailsCss.StockViewSearch2}>
                            <label>Preview</label>
                            <select onChange={onViewChange}>
                                <option value={5} >5</option>
                                <option value={10} selected>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                                <option value={25}>25</option>
                            </select>
                        </div>

                    </div>

                    <div className={StockDetailsCss.table_wrapper}>
                        <table>
                            <tbody>
                                <tr>
                                    <th>SL</th>
                                    <th>Item Name</th>
                                    <th>Brand</th>
                                    <th>Category</th>
                                    <th>Quantity</th>
                                    <th>Last Buying Price</th>
                                </tr>
                                {TableItem}
                            </tbody>
                        </table>
                    </div>

                    <div className={StockDetailsCss.pagination_section}>
                        <button ref={preRef} onClick={PreviousData}>Previous Data</button>
                        <span className={StockDetailsCss.page_number}>
                            <input type={'number'} value={customPageNumber} onChange={onPageNumberChange} onFocus={(e) => { e.target.select() }} /> <span> of {paginationNumber}</span>
                        </span>
                        <button ref={nextRef} onClick={NextData}>Next Data</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StockDetails

const MyTable = ({ sl, itemName, brand, catagory, quantity, lastPrice }) => {

    return <>
        <tr>
            <td>{sl}</td>
            <td>{itemName}</td>
            <td>{brand}</td>
            <td>{catagory}</td>
            <td>{quantity}</td>
            <td>{lastPrice}</td>
        </tr>
    </>
}

const Spinner = ({ text }) => {
    const myIndex = {
        zIndex: '15'
    }
    return (
        <>
            <div style={myIndex} className={StockDetailsCss.SpinnerWrrapper}>
                <div id="loader"></div>
                <p>{text}</p>
            </div>
        </>
    )
}
const VarianceInfo = ({ varianceData, setVarianceData }) => {
    const [allVarianceFinal, setAllVarianceFinal] = useState()
    async function getUnitandStock() {
        let myString = ''
        varianceData.allVariance?.forEach(element => {
            myString += element.id + ','
        });
        let str = myString.substring(0, myString.length - 1);
        let varianceStock = await GetVarianceStock({ ids: str })
        setAllVarianceFinal(varianceStock)

    }
    function getTotal() {
        let total = 0
        allVarianceFinal?.forEach((item) => {
            total += item.stock_quantity
        })
        return total
    }
    useEffect(() => {
        getUnitandStock()
    }, [])
    //   selectedUnit.trim() varianceData?.mainItem?.selectedUnit
    return <div className={StockDetailsCss.stock_variance_pop_wrapper}>
        <div className={StockDetailsCss.stock_variance_pop}>
            <button className={StockDetailsCss.StockInfoCloseButton} onClick={() => { setVarianceData(); document.body.style.overflow = 'auto' }}>X</button>
            <h3>#{varianceData?.mainItem?.en_sort_title}</h3>
            <p>Total: {getTotal()}</p>
            <table>
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Variance Name</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
              {allVarianceFinal?.map((item, index) => {
                return <tr key={index}>
                    <td >{index+1}</td>
                    <td >{item.vrname}</td>
                    <td >{item.stock_quantity} {item.unit}</td>
                </tr>

            })}
              </tbody>
            </table>         
        </div>
    </div>
}
