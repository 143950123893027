import MyAxios from 'axios'
import { API_LINK } from './APILink'
const MY_API_LINK = `${API_LINK}category-name/`

const configdata = {
    headers: {
        'Content-Type': 'application/json'
    }
}
const configfile = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

export async function SaveCategory(data, catImage, catIcon, catBanner) {
    let resultcatImage = {}
    let resultcatIcon = {}
    let resultcatBanner = {}
    if (catImage) {
        const catImageData = new FormData();
        catImageData.append("myfile", catImage);
        resultcatImage = await MyAxios.post(`${MY_API_LINK}upload`, catImageData, configfile)
    }
    if (catIcon) {
        const catIconData = new FormData();
        catIconData.append("myfile", catIcon);
        resultcatIcon = await MyAxios.post(`${MY_API_LINK}upload`, catIconData, configfile)
    }
    if (catBanner) {
        const catBannerData = new FormData();
        catBannerData.append("myfile", catBanner);
        resultcatBanner = await MyAxios.post(`${MY_API_LINK}upload`, catBannerData, configfile)
    }
   
    try {
        
        let result = await MyAxios.post(`${MY_API_LINK}add`, { ...data, catImage: resultcatImage.data, catIcon: resultcatIcon.data, catBanner: resultcatBanner.data }, configdata)
        return result.data
    } catch (error) {
        return 0
    }
}
export async function SaveCategoryMetadata(data, ogimage) {
    let resultogimage = {}

    if (ogimage) {
        const ImageData = new FormData();
        ImageData.append("myfile", ogimage);
        resultogimage = await MyAxios.post(`${MY_API_LINK}upload-ogimage`, ImageData, configfile) 
    }
   
    try {
        let result = await MyAxios.post(`${MY_API_LINK}category-metadata-add`, { ...data, og_image: resultogimage.data,}, configdata)
        return result.data
    } catch (error) {
        return 0
    }
}
export async function UpdateCategoryMetadata(data, ogimage, id) {
    let resultogimage = {}
    
    if (ogimage) {
        const ImageData = new FormData();
        ImageData.append("myfile", ogimage);
        resultogimage = await MyAxios.post(`${MY_API_LINK}upload-ogimage`, ImageData, configfile)
        await MyAxios.post(`${MY_API_LINK}delete-ogimage`, {image_name:data.og_image}, configdata)
    }
    try {
        let result = await MyAxios.post(`${MY_API_LINK}category-metadata-update/${id}`, { ...data, og_image: resultogimage.data,}, configdata)
        return result.data
    } catch (error) {
        return 0
    }
}
export async function GetCategoryMetadata(id) {
    
    try {
        let result = await MyAxios.get(`${MY_API_LINK}category-metadata/${id}`)
        return result.data
    } catch (error) {
        return 0
    }
}


export async function UpdateCategory(id, data, catImage, catIcon, catBanner) {
    
    let resultcatImage = {}
    let resultcatIcon = {}
    let resultcatBanner ={}
    if (catImage) {
        const catImageData = new FormData();
        catImageData.append("myfile", catImage);
        resultcatImage = await MyAxios.post(`${MY_API_LINK}upload`, catImageData, configfile)
        await MyAxios.post(`${MY_API_LINK}delete-image`, { image_name: data.catImage }, configdata)
        // console.log(id, data, catImage, catIcon, catBanner, 'image');
    }
    if (catIcon) {
        const catIconData = new FormData();
        catIconData.append("myfile", catIcon);
        resultcatIcon = await MyAxios.post(`${MY_API_LINK}upload`, catIconData, configfile)
        await MyAxios.post(`${MY_API_LINK}delete-image`, { image_name: data.catIcon }, configdata)
        // console.log(id, data, catImage, catIcon, catBanner, 'icon');
    }
    if (catBanner) {
        const catBannerData = new FormData();
        catBannerData.append("myfile", catBanner);
        resultcatBanner = await MyAxios.post(`${MY_API_LINK}upload`, catBannerData, configfile)
        await MyAxios.post(`${MY_API_LINK}delete-image`, { image_name: data.resultcatBanner }, configdata)
        // console.log(id, data, catImage, catIcon, catBanner, 'banner');
    }
    
    try {
        let result = await MyAxios.put(`${MY_API_LINK}${id}`, { ...data, catImage: resultcatImage?.data || data.catImage, catIcon: resultcatIcon?.data || data.catIcon, catBanner: resultcatBanner?.data || data.catBanner }, configdata)
        return result.data
    } catch (error) {
        return 0
    }
}
export async function DeleteCategory(id, data) {

    try {
        let result = await MyAxios.delete(`${MY_API_LINK}${id}`)
        await MyAxios.post(`${MY_API_LINK}delete-image`, { image_name: data.catImage }, configdata)
        await MyAxios.post(`${MY_API_LINK}delete-image`, { image_name: data.catIcon }, configdata)
        await MyAxios.post(`${MY_API_LINK}delete-image`, { image_name: data.resultcatBanner }, configdata)
        return result.data
    } catch (error) {
        return 0
    }
}
export async function GetAllCategory() {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}all`)
        return result.data
    } catch (error) {
        return 0
    }
}
export async function GetCategoryImageLink(data) {
    try {
        let result = await MyAxios.post(`${MY_API_LINK}get-image`, data, configdata)
        return result.data
    } catch (error) {
        return 0
    }
}