import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { DistrictData } from '../../../api/MyLocation'
import SalesReportViewProductWiseNew from '../SalesReportPDFnew/SalesReportViewProductWiseNew'
import sprCss from './css/SalesProductWiseReportNew.module.css'
import Select from 'react-select';
import {toast} from 'react-toastify'

const SalesProductWiseReportNew = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<MainComp />} />
        <Route path='view' element={<SalesReportViewProductWiseNew />} />
      </Routes>
    </>
  )
}

export default SalesProductWiseReportNew

const MainComp = () => {
  const [searchData, setSearchData] = useState()

  function onDataChange(e) {
    let name = e.target.name
    let value = e.target.value
    setSearchData({ ...searchData, [name]: value })
  }
  const [invoiceNo, setInvoiceNo] = useState({
    startIn: '',
    endIn: ''
  })
  function onInvoiceNoChange(e) {
    let name = e.target.name
    let value = e.target.value
    setInvoiceNo({ ...invoiceNo, [name]: value })
  }
  function onCheckClick() {
    // console.log(DistrictData)
   
  }

  let staticLink = '/reports/sales-report-productwise/view'

  // async function onSalesReortClick() {
  //   if (searchData?.start_date && searchData?.end_date) {

  //     let dateQueryUrl = `start_date=${searchData?.start_date}&end_date=${searchData?.end_date} `
  //     window.open(`${staticLink}?` + dateQueryUrl, '_blank')
  //   } else {
  //     alert('select date to continue')
  //   }

  // }

  async function onDeliveryReportClick() {
    let filterOk = true
    if (searchData?.start_date && invoiceNo.startIn) {
      filterOk = false
    } else if (searchData?.start_date && invoiceNo.endIn) {
      filterOk = false
    } else if (searchData?.end_date && invoiceNo.startIn) {
      filterOk = false
    }
    else if (searchData?.end_date && invoiceNo.endIn) {
      filterOk = false
    } else if (searchData?.end_date && searchData.start_date) {
      filterOk = true
    } else if (invoiceNo.startIn && invoiceNo.endIn) {
      filterOk = true
    } else {
      filterOk = false
    }

    if (filterOk) {
      if (searchData?.start_date && searchData?.end_date) {
        let dateQueryUrl = `&start_date=${searchData?.start_date}&end_date=${searchData?.end_date} `
        window.open(`${staticLink}?type=delivery${dateQueryUrl}`, '_blank')
      } else {
        if (invoiceNo.startIn && invoiceNo.endIn) {
          let dateQueryUrl = `&startID=${invoiceNo.startIn}&endID=${invoiceNo.endIn} `
          window.open(`${staticLink}?type=delivery${dateQueryUrl}`, '_blank')
        } else {
          alert('Please Enter Start & End Invoice Number')
        }
        //alert('select date to continue')
      }
    } else {
      toast.error('Please select date range or invoice range')
    }

  }
  async function packagingReportClick(params) {
    if (searchData?.start_date && searchData?.end_date) {

      // let dateQueryUrl = `&start_date=${searchData?.start_date}&end_date=${searchData?.end_date} `
      // window.open(`${staticLink}?type=packaging` + dateQueryUrl, '_blank')
      toast.warn('Working in progress ⏱️')
    } else {
      toast.error('Please select date range or invoice range')
    }



  }

  function onConfirmClick() {
    if (searchData?.start_date && searchData?.end_date) {
      let dateQueryUrl = `&start_date=${searchData?.start_date}&end_date=${searchData?.end_date} `
      window.open(`${staticLink}?type=confirm` + dateQueryUrl, '_blank')
    } else {
     toast.error('Please select date range or invoice range')
    }

  }
  function onPendingClick() {
    if (searchData?.start_date && searchData?.end_date) {
      let dateQueryUrl = `&start_date=${searchData?.start_date}&end_date=${searchData?.end_date} `
      window.open(`${staticLink}?type=pending` + dateQueryUrl, '_blank')
    } else {
     toast.error('Please select date range or invoice range')
    }
  }

  return (
      <section className={sprCss.heading}>
        <h2 onClick={onCheckClick}>Sales Related Reports (Product Wise)</h2>
        <div className={sprCss.inner_headign}>
          

          <div className={sprCss.input_section}>
            <div className={sprCss.address}>
              <div className={sprCss.input_wrapper}>
                <label>Division:</label>
                <Select options={[]}
                className={sprCss.mselect}
                />
              </div>

              <div className={sprCss.input_wrapper}>
                <label>District:</label>
                <Select options={DistrictData}
                getOptionLabel={(option)=>`${option.district}`}
                className={sprCss.mselect}
                />

              </div>
              <div className={sprCss.input_wrapper}>
                <label>Area:</label>
                <Select options={[]}
                  className={sprCss.mselect}
                />
              </div>
            </div>
            <div className={sprCss.dates}>
              <div className={sprCss.dateWrapper} >
                <label>Start Date:</label>
                <input name='start_date' type='date' onChange={onDataChange} />
              </div>
              <div className={sprCss.dateWrapper}>
                <label>End Date:</label>
                <input name='end_date' type='date' onChange={onDataChange} />
              </div>
            </div>
            <div className={sprCss.invoices}>
              <div  className={sprCss.invoicesWrapper}>
                <label>Start Invoice No:</label>
                <input name='startIn' type='number' onChange={onInvoiceNoChange} />
              </div>
              <div className={sprCss.invoicesWrapper}>
                <label>End Invoice No:</label>
                <input name='endIn' type='number' onChange={onInvoiceNoChange} />
              </div>
            </div>
          <div className={sprCss.buttons}>
            <button onClick={onDeliveryReportClick}>Delivery Report</button>
            <button onClick={packagingReportClick}>Packaging Report</button>
            <button onClick={onConfirmClick}>Comfirm Report</button>
            <button onClick={onPendingClick}>Pending Report</button>
          </div>
          </div>
        </div>
      </section>
  )
}
