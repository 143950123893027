import { configureStore } from '@reduxjs/toolkit'
import UserSlice from './Slices/UserSlice'
import ProductSlice from './Slices/ProductSlice'
import CategorySlice from './Slices/CategorySlice'
import BrandListSlice  from './Slices/BrandListSlice'
import MAU_Slice from './Slices/MAU_Slice'
import SupplierSlice from './Slices/SupplierSlice'
import CustomerSlice from './Slices/CustomerSlice'
import VarianceSlice from './Slices/VarianceSlice'
export const store = configureStore({
  reducer: {
    backenuser:UserSlice,
    products:ProductSlice,
    category:CategorySlice,
    brandlist:BrandListSlice,
    mauslice:MAU_Slice,
    supplier:SupplierSlice,
    customer:CustomerSlice,
    variance:VarianceSlice,
  },
})