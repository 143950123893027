import React, { useEffect, useState } from 'react'
import stockCss from './stock.module.css'
import { GetFilterProductVariance, UpdateProductVariance } from '../../../api/ProductVarianceApi'
import { UpdateProductList } from '../../../api/ProductListApi'
import { fetchProducts } from '../../../ReduxFiles/Slices/ProductSlice'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

async function getVarianceData(pid, setData) {
    let data = await GetFilterProductVariance(`product_id = ${pid}`)

        let sortArr = data.sort((a, b) => (parseInt(a.Variance_name) > parseInt(b.Variance_name)) ? 1 : -1)
        setData(sortArr)
}


export default function AvaillableStock({popUp, setPopUp}) {
    const [varianceData, setVarianceData] = useState([])
    const dispatch = useDispatch()
   async function onSaveClick(e) {
        e.preventDefault()
        const form = e.target
        // console.log(popUp, varianceData);
        if (popUp.hasVariance) {
            const toastid = toast.loading("Please wait... updating virtual stcok")
            let inputs = Array.from(form.querySelectorAll('input'));
            let allTotal = 0
           const promises = inputs.map(async(element) => {
                const value = parseInt(element.value || 0)
                allTotal += value
                await UpdateProductVariance(element.name, { virtualStock: value })
                
            });
            await Promise.all(promises);
            await UpdateProductList(popUp.id, { virtualStock: allTotal})
            toast.update(toastid, { render: "Virtual Stock Update Success.", type: "success", isLoading: false, autoClose:2000});
            dispatch(fetchProducts())
            setPopUp()
            document.body.style.overflow = 'auto'
            
        }else{
            let input = form.querySelector('input')
            await UpdateProductList(input.name, { virtualStock: input.value})
            dispatch(fetchProducts())
            setPopUp()
            document.body.style.overflow = 'auto'
        }
    }

    useEffect(()=>{
        if (popUp.hasVariance) {
            getVarianceData(popUp.id, setVarianceData)
        }
    },[popUp])

  return (
    <div className={stockCss.wrapper}>
        <div className={stockCss.holder}>
            <h3>{popUp.en_sort_title}</h3>
            <form onSubmit={onSaveClick} className={stockCss.form_section}>

           
            {popUp.hasVariance ? 

            <div className={stockCss.vr_inputs}>
                {varianceData.map((item, index)=>{
                    return <div key={index} className={stockCss.input_common}>
                        <label>{item.Variance_name}</label>
                        <input name={item.id} type='number' defaultValue={item.virtualStock}/>
                    </div>
                })}
            </div>            
            :
            <div className={stockCss.input_common}>
                <label>Virtual Stock Quantity : </label>
                <input name={popUp.id} type='number' defaultValue={popUp.virtualStock}/>
            </div>
            }
        <div className={stockCss.button_section}>
        <button type='button' onClick={() => { setPopUp(); document.body.style.overflow = 'auto' }}>close</button>
        <button type='submit' >Save</button>
        
    </div>
    </form>
        </div>
        
    </div>
  )
}
