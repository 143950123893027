
import React from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import ManageBanners from './ManageBanners'
import ManageSlides from './ManageSlides'
import WebsiteSetting from './WebsiteSetting'
import settingCss from './css/SettingMain.module.css'
import OfferPopup from './OfferPopup'

const SettingMain = () => {
    const myParams = useParams()
    //console.log(myParams.msetting)
    const settingMenu = [
        { link: 'web-setting', title: 'Website Settings' },
        { link: 'slides', title: 'Manage Slieds' },
        { link: 'banners', title: 'Manage Banners' },
        { link: 'offer-popup', title: 'Offer Popup' },
        { link: 'page-list', title: 'Page List' },
        { link: 'add-page', title: 'Add New Page' },
        { link: 'page-content', title: 'Page Content' },
        { link: 'get-image-name', title: 'Get Image Name' },
        { link: 'read-csv', title: 'Read CSV File' },
        { link: 'manage-user', title: 'Manage User' },
        // { link: 'change-password', title: 'Change Password' },
    ]
    return (
        <div className={settingCss.menupart}>
            <div className={settingCss.wrapper}>
                <div className={settingCss.menuitems}>
                    {settingMenu.map((item, index) => {
                        return <Link to={item.link} className={settingCss.item} key={index}>
                            <h2>{item.title}</h2>
                        </Link>
                    })}
                </div>
            </div>
        </div>
    )
}

export default SettingMain