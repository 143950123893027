import React, { useEffect, useState } from 'react'
import WithdrawCss from './Css/Withdraw.module.css'

const Withdraw = () => {
    const [bankList, setBankList] = useState()
    const [chequeList, setChequeList] = useState()
    const [inputData, setInputData] = useState()
    const [chequeNumber, setChequeNumber] = useState()
    const [errText, setErrText] = useState()
    const [currentBalance, setCurrentBalance] = useState()
    let chequeIDNO = ''
    function onInputChange(e) {
        let name = e.target.name
        let value = e.target.value
        setInputData({...inputData, [name]:value})
    }
    function onbankChange(e) {
        let name = e.target.name
        let value = e.target.value
      
        if (value) {
            bankList.forEach(element => {
              //  console.log(element, value)
                if (element.id===parseInt(value)) {
                    setInputData({...inputData, [name]:value, bankName:element.bankName,acName:element.acName, acNumber:element.acNumber})
                    setCurrentBalance(element.ammount)
                   
                }
            });
            
           for (let index = 0; index < chequeList?.length; index++) {
                const element = chequeList[index];
                //console.log(element.chequeNumber)
                if (parseInt(value)===element.bankID && element.active && !element.used) {
                    setChequeNumber(element.chequeNumber)
                    chequeIDNO = element.cheque_id
                   break
                } 
           }
        }else{
            setInputData({...inputData, [name]:value, bankName:'',acName:'', acNumber:''})
            setCurrentBalance(0)
            setChequeNumber()
        }
    }
    function onChequeNumberChange(e) {
        let value = e.target.value
       setChequeNumber(parseInt(value))
    }

    function chequeNoValidation(number) {
        let conditionMatch = false
       // console.log(chequeList, inputData.selected_bankID) 
        // console.log(chequeList, inputData.selected_bankID)
            for (let index = 0; index < chequeList.length; index++) {
                const element = chequeList[index];
                if (element.bankID=== parseInt(inputData.selected_bankID) && parseInt(number)===element.chequeNumber && element.active ) {
                    chequeIDNO = element.cheque_id
                    conditionMatch = true
                    break
                }else{
                    conditionMatch = false
                }
            
           }
           if (!conditionMatch) {
                alert('enter valid cheque no')
           }
        return conditionMatch
    }
  async  function onSaveClick() {
        let processorData = window.localStorage.getItem('userData')
        processorData = JSON.parse(processorData)
        let userID = window.localStorage.getItem('user_id')
       if (inputData) {
            let withdraw_dateErr = ValidateFunction('withdraw_date', 'Withdraw Date')
            let selected_bankIDErr = ValidateFunction('selected_bankID', 'Bank Name ')
            // let acNameErr = ValidateFunction('acName', 'Account Name ')
            let checkNumberErr = ValidateFunctionB(chequeNumber, 'Check Number ')
            let amountErr = ValidateFunction('amount', 'Amount is ')
            let noteErr = ValidateFunction('note', 'Note is ')
            setErrText({withdraw_dateErr, selected_bankIDErr, checkNumberErr, amountErr, noteErr})

            if (!withdraw_dateErr && !selected_bankIDErr && !checkNumberErr && !amountErr && !noteErr && chequeNoValidation(chequeNumber)) {

                let CreditData = {
                    identity: chequeIDNO,
                    receiveAmmount:inputData.amount,
                    particulars:'Bank Withdraw',
                    transactionType:'cash',
                    receivedByID:userID,
                    remark:inputData.note,
                    receiveType : 'Check Withdraw',
                    created_date:new Date().getTime()
                }
                let CheckDataUpdate = {
                    used:1,
                    chequeAmmount:inputData.amount,
                }
             
                let bankData = {
                    debitCredit:2,
                    bankID: inputData.selected_bankID,
                    identity: chequeIDNO,
                    receiptNumber:chequeNumber,
                  
                    receiveAmmount:0,
                    particulars:'Withdraw',
                    head:'Withdraw',
                    processorID:userID,
                    paymentAmmount:parseFloat(inputData.amount),
                    remark:inputData.note,
                    transactionType:'Cheque Withdraw',
                    voucherType:1,
                    created_date:new Date().getTime() 
                }
                
            
              
                alert('data saved')
            }
       }else{
        alert('input data required')
       }
    }

    function ValidateFunction(field, message) {
        let text = ""
            if (!inputData[field]) {
                text = message + " is Required"
            }
        return text;
      }
    function ValidateFunctionB(field, message) {
        let text = ""
            if (!field) {
                text = message + " is Required"
            }
        return text;
      }



    return (
        <>
            <section className={WithdrawCss.salesMain}>
                <div className={WithdrawCss.innerSale}>
                <div className={WithdrawCss.withdraw_headign}>
                <h2>Withdraw</h2>
                <p>Current Balance : {currentBalance ? currentBalance:'0'} TK</p>
                </div>
                <div className={WithdrawCss.addsupplier}>
                    <div className={WithdrawCss.supplierinput} >
                        <label>Date *</label>
                        <input type={'date'} name='withdraw_date'  onChange={onInputChange}/>
                        {errText ? <p className={WithdrawCss.WarningText}>{errText?.withdraw_dateErr}</p> : ""}
                    </div>
                    <div className={WithdrawCss.supplierinput} >
                        <label>Select Bank *</label>
                        <select name='selected_bankID' onChange={onbankChange}>
                           <option value={''}>Select Bank</option>
                           {bankList?.map((item ,index)=>{
                                return <option key={index} value={item.id}>{item.bankName} ({item.acName})</option>
                           })}
                        </select>
                        {errText ? <p className={WithdrawCss.WarningText}>{errText?.selected_bankIDErr}</p> : ""}
                    </div>
                    {/* <div className={WithdrawCss.supplierinput}>
                         <label>Select Account Name *</label>
                        <select name='acName' onChange={onInputChange}>
                           <option value={''}>Select Account Name</option>
                        </select>
                        {errText ? <p className={WithdrawCss.WarningText}>{errText?.acNameErr}</p> : ""}
                    </div> */}
                    <div className={WithdrawCss.supplierinput}>
                        <label>Check Number *</label>
                        <input name='checkNumber' type='number' placeholder='Check Number' onChange={onChequeNumberChange} value={chequeNumber ? chequeNumber:''}/>
                        {errText ? <p className={WithdrawCss.WarningText}>{errText?.checkNumberErr}</p> : ""}
                    </div>
                    <div className={WithdrawCss.supplierinput}>
                        <label>Total Amount *</label>
                        <input name='amount' type='number' placeholder='Amount' onChange={onInputChange} />
                        {errText ? <p className={WithdrawCss.WarningText}>{errText?.amountErr}</p> : ""}
                    </div>
                    <div className={WithdrawCss.supplierinput}>
                        <label>Note *</label>
                        <input name='note' type='text' placeholder='type your note' onChange={onInputChange} />
                        {errText ? <p className={WithdrawCss.WarningText}>{errText?.noteErr}</p> : ""}
                    </div>
                   

                </div>
                <div className={`${WithdrawCss.withdraw} ${WithdrawCss.buttonsection}`}>
                        <button onClick={onSaveClick}>SAVE</button>
                    </div>
                </div>
               
            </section>
        </>
    )
}

export default Withdraw