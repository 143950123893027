import React, {useState, useEffect} from 'react'
import { useSearchParams } from 'react-router-dom';
import { GetAllDataExpress, GetDataPassQuery, GetDoubleQueryExpress } from '../../../api/ExpressFunctions';
import { GetEndDate, GetStartDate, LocalTimeStampToDate} from '../../MyFunctions/MyFunctions';
import { HeaderFooterWrapper } from '../HeadetFooter';
import SalesCollectinStatementPDFCss from './css/SalesCollectinStatementPDF.module.css'

const SalesCollectinStatementPDF = ({ heading }) => {
    
    const [invoiceState, setInvoiceState] = useState()
    let [searchParams, setSearchParams] = useSearchParams();

    async function getDataformDatabase(params) {
        let saleData = []
        let startDate = searchParams.get('start_date')
        let endDate = searchParams.get('end_date')
        let myStartDate = GetStartDate(startDate)
        let myEndDate = GetEndDate(endDate)
        let myCustomer = await GetAllDataExpress('user')
        if (startDate) {
            let mQuery = `SELECT * FROM sales WHERE created_date > ${myStartDate} AND created_date < ${myEndDate}`
            saleData = await GetDataPassQuery(mQuery)
        }else{
            let mQuery = `SELECT * FROM sales ORDER BY id DESC Limit 500`
            saleData = await GetDataPassQuery(mQuery)
        }
        let creditArr = [] 
        let myPromises = saleData.map(async function (item) {
            let creditData = await GetDoubleQueryExpress('credit_voucher', 'identity', 'particulars', item.id, 'Sales')
            creditArr.push(...creditData)
        })
        Promise.all(myPromises).then(()=>{
            let finalArr = []
            saleData.forEach(element => {
               let totalPaid = getPaidTotal(creditArr, element.id)
                let finalData = {
                    invoicenNo:element.id, 
                    date:element.created_date,
                    customerID:element.userID,
                    customerName:getCustomerInfo(myCustomer, element.userID, 'name'),
                    phone:getCustomerInfo(myCustomer, element.userID, 'phone'),
                    total:element.grandTotal+element.deliveryCharge,
                    paid:totalPaid,
                    due:(element.grandTotal+element.deliveryCharge)- (totalPaid)
                }
                finalArr.push(finalData)
            });
            setInvoiceState(finalArr)
        })

       
    }
    function getCustomerInfo(myArray, id, dataCol) {
        let myData = ''
        myArray.forEach(element => {
            if (element.id===id) {
                myData = element[dataCol]
            }
        });
        return myData
    }

    function getPaidTotal(myArray, id) {
        let total = 0
        myArray.forEach(element => {
            if (element.identity===id) {
                total += element.receiveAmmount
            }
        });

        return total
    }

    function saleReceiveTotal(myArray) {
        let total = 0
        myArray?.forEach(element => {
            total += element.receiveAmmount
        });
        return total
    }

    function getAllTotal(arrayPass, identifire) {
        let total = 0
        arrayPass?.forEach(element => {
            total += element[identifire]
        });
        return total;
    }

    useEffect(() => {
        getDataformDatabase()
    }, [])
    function getColor() {
        let leftPart = document.querySelector('.sidepart')
        leftPart?.classList.add('display-none')
       // console.log(leftPart)
    }

    useEffect(() => {
        getColor()
    
    }, [])

    return (
        <>
        <HeaderFooterWrapper
            content={<>
            <h3>{heading}</h3>
            <div className={SalesCollectinStatementPDFCss.details}>
                <div className={SalesCollectinStatementPDFCss.client}>
                    <div className={SalesCollectinStatementPDFCss.info}>
                        {searchParams.get('start_date') ? <span className={SalesCollectinStatementPDFCss.moreinfo}>Date : {searchParams.get('start_date')}  To {searchParams.get('end_date')}</span> : <span className={SalesCollectinStatementPDFCss.moreinfo}> Date : All Time</span>}
                    </div>
                </div>
                <div className={SalesCollectinStatementPDFCss.invoice}>

                </div>
            </div>
            <div className={`${SalesCollectinStatementPDFCss.table} ${SalesCollectinStatementPDFCss.sales_table}`}>
                <table>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}>Sl</th>
                            <th>Invoice No</th>
                            <th>Date</th>
                            <th>Customer Name</th>
                            <th >Phone</th>
                            <th >Total Sale</th>
                            <th >Paid</th>
                            <th >Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoiceState?.map((item, index) => {
                            return <tr key={index}>
                                <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                <td style={{ textAlign: 'center' }}>#{item.invoicenNo}</td>
                                <td style={{ textAlign: 'left' }}>{LocalTimeStampToDate(item.date)}</td>
                                <td style={{ textAlign: 'left' }}>{item.customerName}</td>
                                <td style={{ textAlign: 'left' }}>{item.phone}</td>
                                <td style={{ textAlign: 'right' }}>{item.total}</td>
                                <td style={{ textAlign: 'right' }}>{item.paid}</td>
                                <td>{item.due >0 ? `Due (${item.due})`:`Complete`}</td>
                            </tr>
                        })}
                        <tr style={{ fontWeight:'bold' }}>
                            <td colSpan={5}>Total : </td>
                            <td>{getAllTotal(invoiceState, 'total')}</td>
                            <td>{getAllTotal(invoiceState, 'paid')}</td>
                            <td>{getAllTotal(invoiceState, 'due')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>}
        />
        </>
    )
}

export default SalesCollectinStatementPDF