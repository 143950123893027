import React from 'react'
import SpinnerCss from './css/Spinner.module.css'

const Spinner = () => {
    return (
        <div className={SpinnerCss.SpinnerWrrapper}>
            <div id="loader"></div>
        </div>
    )
}

export default Spinner