import ItemPriceCss from './css/ItemPrice.module.css'
import React from 'react'

const ItemPrice = ({ item, setItem, setstate, showState }) => {

    function SaveItem(e) {
        e.preventDefault()
        const form = e.target
        const Selling_price = form.Selling_price.value
        const tax = form.tax.value
        const Promo_price = form.Promo_price.value
        const Start_date = form.Start_date.value
        const End_date = form.End_date.value
        setItem({...item, Selling_price, tax, Promo_price, Start_date, End_date})
        setstate(3)
    }

    return (
        <>
          
            <section className={ItemPriceCss.main}>
           
                <h4>Product Price</h4>
          
                <form onSubmit={SaveItem}>
                <div className={ItemPriceCss.input_wrapper}>
                    <div className={ItemPriceCss.common}>
                        <label>Selling Price*</label>
                        <input name="Selling_price" type='text' placeholder='Selling Price' required />
                    </div>
                    <div className={ItemPriceCss.common}>
                        <label>TAX</label>
                        <input name="tax" type='text' placeholder='Tax' />
                    </div>
                    <div className={ItemPriceCss.common}>
                        <label>Promo Price</label>
                        <input name="Promo_price" type='text' placeholder='Promo Price' />
                    </div>
                    <div className={ItemPriceCss.common}>
                        <label>Start date</label>
                        <input name="Start_date" type='date' />
                    </div>
                    <div className={ItemPriceCss.common}>
                        <label>End date</label>
                        <input name="End_date" type='date'  />
                    </div>
                </div>
                <div className={ItemPriceCss.btn_group}>
                    <button>Save &  Continue</button>
                </div>
                </form>
            </section>
        </>
    )
}

export default ItemPrice