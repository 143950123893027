import React, { useEffect, useState } from 'react'
import {useSearchParams} from 'react-router-dom'
import { GetDataPassQuery } from '../../../api/ExpressFunctions'
import { HeaderFooterWrapper } from '../HeadetFooter'
import StockCapitalReportCss from './css/StockCapitalReport.module.css'

const StockCapitalReport = ({heading, date}) => {
    const [invoiceState, setInvoiceState] = useState()
    const [header, setHeader] = useState()
    let [searchParams, setSearchParams] = useSearchParams();

 async function getData() {
    let brand = searchParams.get('brand')
    let brandID = searchParams.get('brand_id')

    let parentCat = searchParams.get('catagory')
    let pcat = parentCat
    let catID = searchParams.get('cat_id')
    let subCatagory = searchParams.get('subCatagory')
    let sub_catagory_id = searchParams.get('sub_catagory_id')



    let AllProducts = JSON.parse(window.localStorage.getItem('allProduct'))

    let purchaseQuery = `SELECT pitem.*, preturn.quantity as rquantity  FROM purchase_items pitem LEFT JOIN purchase_return_items preturn ON pitem.product_id=preturn.product_id`
    let  purchaseData = await GetDataPassQuery(purchaseQuery)
   // console.log(purchaseData)
    let finalArr =[]
    AllProducts.forEach(aelement => {
        purchaseData.forEach(pelement => {
            if (aelement.id===pelement.product_id) {
                aelement = {
                    ...aelement,
                    myStock:pelement.quantity-pelement.rquantity
                }
            }
        });
        finalArr.push(aelement)

    });


    let filterArr =[] 
    if (brand) {
        setHeader(brand) 
        finalArr.map((item)=>{
        if (item.selected_brand_id === parseInt(brandID)) {
            filterArr.push(item)
        }
        })
        setInvoiceState(filterArr)
    }else if(parentCat){
        setHeader(pcat)
        let filterArr = []
        let myCatQuery = `SELECT * FROM category_name WHERE selectedItem=${catID}`  
        let catItems = await GetDataPassQuery(myCatQuery)
        console.log(catItems)      
       catItems.map((spliceitem)=>{ 
            finalArr.map((pitem)=>{
                if (pitem.selected_catagory_id ===spliceitem.id) {
                    filterArr.push(pitem)
                }
            })
        })
        setInvoiceState(filterArr)
    }else if (subCatagory) {
        setHeader(subCatagory)
        finalArr.map((item)=>{
        if (item.selected_catagory_id === parseInt(sub_catagory_id)) {
            filterArr.push(item)
        }
        })
        setInvoiceState(filterArr)
    }else{
        setInvoiceState(finalArr)
    }
}
    function checkClick() {
       
    }

    useEffect(() => {
        getData()
    }, [])

    function getAlltotal(array) {
        let allTotal = 0
        array?.forEach(element => {
            allTotal += (element.myStock ? element.myStock:0) * element.Selling_price
        });
        
       return allTotal
    }
    function getTotalQuantity(array) {
        let allTotal = 0
        array?.forEach(element => {
            allTotal += element.myStock ? element.myStock:0
        });

       return allTotal
    }
    function getColor() {
        let leftPart = document.querySelector('.sidepart')
        leftPart?.classList.add('display-none')
       // console.log(leftPart)
    }

    useEffect(() => {
        getColor()
       
    }, [])

  return (
    <>
    <HeaderFooterWrapper
    content={<>
    <h3>Stock Capital Report</h3>
            <div className={StockCapitalReportCss.details}>
                <div className={StockCapitalReportCss.client}>
                    <div className={StockCapitalReportCss.info}>
                        <span className={StockCapitalReportCss.moreinfo} onClick={checkClick}>Type : </span>{header ?  header:'All Products' }
                    </div>
                </div>
            </div>
            <div className={`${StockCapitalReportCss.table} ${StockCapitalReportCss.fixed_table}`}>
                <table>
                    <thead>
                        <tr className={StockCapitalReportCss.fixed_table_header}>
                            <th>Sl</th>
                            <th>Product Name</th>
                            <th style={{ paddingLeft: '10px'}}>Ava. Quantity</th>
                            <th className={StockCapitalReportCss.unit}>Unit Price</th>
                            <th className={StockCapitalReportCss.unit_price}>Sup Total</th>
                        </tr>
                    </thead>
                    <tbody>
                    {invoiceState?.map((item, index)=>{
                        return <>
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td style={{ textAlign: 'left' }} >{item.en_sort_title}</td>
                                <td>{item.myStock ? item.myStock : 0}</td>
                                <td>{item.Selling_price}</td>
                                <td>{(item.myStock ? item.myStock : 0) * item.Selling_price}
                                </td>
                            </tr>
                        </>
                    })}
                    
                    <tr className={StockCapitalReportCss.final_row}>
                        <td className={StockCapitalReportCss.no_border_right} colSpan={'2'}>Total :</td>
                        <td >{getTotalQuantity(invoiceState)}</td>
                        <td ></td>
                        <td >{getAlltotal(invoiceState)}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
    </>}
    />
    </>
  )
}

export default StockCapitalReport