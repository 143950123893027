import MyAxios from 'axios'
import { API_LINK} from './APILink'
const MY_API_LINK = `${API_LINK}wish-list/`

const configdata = {
    headers:{
        'Content-Type' : 'application/json'
    }
}


export async function GetWishListAllData() {
    try {
        let result = await MyAxios.get(`${MY_API_LINK}back-data`)
        return result.data        
    } catch (error) {
        return 0
    }    
}
export async function UpdateWishListData(id, data) {
    try {
        let result = await MyAxios.put(`${MY_API_LINK}/${id}`, data, configdata)
        return result.data        
    } catch (error) {
        return 0
    }    
}