import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectUserInfo } from "../../../../ReduxFiles/Slices/UserSlice";
import { GetUserImage } from "../../../../api/BackendUserApi";
import DummyImage from "../../../../assets/dummyuser.png";
import EditProfile from "./EditProfile";
import ProfileChangePassword from "./ProfileChangePassword";
import UserImageUpdate from "./UserImageUpdate";
import userProfileCss from "./css/userProfile.module.css";
export default function UserProfile() {
  const currentUser = useSelector(selectUserInfo);
  const [editManage, setEditManage] = useState(false);
  const [passwordPop, setPasswordPop] = useState(false);
  const [updateImagePop, setUpdateImagePop] = useState(false);
  const [edit, setEdit] = useState();
  const [updateImage, setUpdateImage] = useState();

  function onEditClick(item) {
    setEditManage(true);
    setEdit(item);
  }
  function onChangePassword() {
    setPasswordPop(currentUser);
  }
  function onUpdateImage(item) {
    setUpdateImagePop(true);
    setUpdateImage(item);
  }
  return (
    <div className={userProfileCss.wrapper}>
      {updateImagePop ? (
        <UserImageUpdate
          setUpdateImagePop={setUpdateImagePop}
          updateImage={updateImage}
        />
      ) : (
        ""
      )}

      {passwordPop ? (
        <ProfileChangePassword
          popUp={passwordPop}
          setPasswordPop={setPasswordPop}
        />
      ) : (
        ""
      )}
      {editManage ? (
        <EditProfile setEditManage={setEditManage} editData={edit} />
      ) : (
        ""
      )}
      <div className={userProfileCss.holder}>
        <div className={userProfileCss.side_bar}>
          <div className={userProfileCss.image_wrapper}>
            <UserImage name={currentUser.userImageName} />
          </div>
          <div className={userProfileCss.text_wrapper}>
            <button onClick={(e) => onEditClick(currentUser)}>
              Edit Profile
            </button>
            <button onClick={(e) => onUpdateImage(currentUser)}>
              Update Image
            </button>
            <button onClick={(e) => onChangePassword()}>Change Password</button>
          </div>
        </div>
        <div className={userProfileCss.main_wrapper}>
          <h2>User Information</h2>
          <div className={userProfileCss.main_holder}>
            <div className={userProfileCss.information_wrapper}>
              <p>Name :</p>
              <span>{currentUser.fullName}</span>
            </div>
            <div className={userProfileCss.information_wrapper}>
              <p>Phone :</p>
              <span>{currentUser.phone}</span>
            </div>
            <div className={userProfileCss.information_wrapper}>
              <p>Email :</p>
              <span>{currentUser.email}</span>
            </div>
          </div>
          <div className={userProfileCss.report_warapper}>
            <h3>Reports</h3>
            <div className={userProfileCss.report_links}>
              <Link to={"invoice-wise"}>Invoice Wise Sales Report</Link>
              <Link to={"product-wise"}>Product Wise Sales Report</Link>
              {/* <Link to={"brand-wise"}>Brand Sales Report</Link> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const UserImage = ({ name }) => {
  const [image, setImage] = useState();

  useEffect(() => {
    async function getLink() {
      if (name) {
        const imageUrl = await GetUserImage({ image_name: name });
        if (imageUrl.imageExist) {
          setImage(imageUrl.url);
        } else {
          setImage(DummyImage);
        }
      } else {
      }
    }
    getLink();
  }, [name]);

  return (
    <>
      <img src={image} alt="User profile" />
    </>
  );
};
