import React, { useEffect, useState } from 'react'
import { GetAllDataExpress } from '../../../api/ExpressFunctions'
import { Route, Routes } from 'react-router-dom'
import StockMaintainView from '../PdfFiles/StockMaintainView'
import StockMaintainReportCss from './css/StockMaintainReport.module.css'

const StockMaintainReport = () => {

    return (
        <>
        <Routes>
            <Route path='/' element={<MainComp/>}/>
            <Route path='/maintain-view' element={<StockMaintainView/>}/>
        </Routes>
        </>
    )
}

export default StockMaintainReport

const MainComp = () => {
    const [brandData, setBrandData] = useState()
    const [SearchData, setSearchData] = useState()
    function onDataChange(e) {
        let name = e.target.name
        let value = e.target.value
        if (name === 'time_selection') {
            setSearchData({ [name]: value })
        } else {
            setSearchData({ ...SearchData, [name]: value })
        }
    }

    function onSearchClick() {
        let staticLink = '/admin/report/stock_maintain_report/maintain-view'
        let queryString = ''
        if (SearchData?.time_selection === 'custom_range') {
            if (SearchData?.start_date && SearchData?.end_date) {
                if (SearchData?.brandName) {
                    queryString = queryString = `/?start_date=${SearchData.start_date}&end_date=${SearchData.end_date} &brand=${SearchData.brandName}`
                    window.open(staticLink + queryString, '_blank')
                } else {
                    queryString = `/?start_date=${SearchData.start_date}&end_date=${SearchData.end_date}`
                    window.open(staticLink + queryString, '_blank')
                }
            } else {
                alert('Please Select Date Range to Continue')
            }
        } else {
            if (SearchData?.brandName) {
                queryString = `/?brand=${SearchData.brandName}`
                window.open(staticLink + queryString, '_blank')
            } else {
                window.open(staticLink, '_blank')
            }

        }
    }
    async function getBrandList() {
        let brandData = await GetAllDataExpress('brand_list')
        setBrandData(brandData)
        // console.log(brandData)  
    }
    useEffect(() => {
        // getBrandList()
    }, [])

    return <>
        <section className={StockMaintainReportCss.heading}>
            <div className={StockMaintainReportCss.inner_headign}>
                <h2 className={StockMaintainReportCss.headign_h2}>Stock Maintain Report</h2>
                <div className={StockMaintainReportCss.input_section}>
                    <div className={StockMaintainReportCss.common}>
                        <select name='time_selection' onChange={onDataChange}>
                            <option value={'all-time'}>All Time</option>
                            <option value={'custom_range'}>Custom Range</option>
                        </select>
                    </div>
                    <div className={StockMaintainReportCss.common}>
                        <select name='brandName' onChange={onDataChange}>
                            <option value={''}>Select Brand</option>
                            {brandData?.map((item) => {
                                return <option key={item.id} value={item.BrandName}>{item.BrandName}</option>
                            })}
                        </select>
                    </div>
                    <div className={StockMaintainReportCss.common}>
                        <select name='productIdentity' onChange={onDataChange}>
                            <option value={''}>Select Product</option>
                        </select>
                    </div>
                </div>
                {SearchData?.time_selection === 'custom_range' ?
                    <><div className={`${StockMaintainReportCss.input_section2} ${StockMaintainReportCss.date_section}`}>
                        <div className={StockMaintainReportCss.common}>
                            <label>Start Date</label>
                            <input name='start_date' type='date' onChange={onDataChange} />
                        </div>
                        <div className={StockMaintainReportCss.common}>
                            <label>End Date</label>
                            <input name='end_date' type='date' onChange={onDataChange} />
                        </div>
                    </div></> :
                    ''}
                <div className={StockMaintainReportCss.input_section2}>
                    <div className={StockMaintainReportCss.common}>
                        <button className={StockMaintainReportCss.report_button} onClick={onSearchClick}>Search</button>
                    </div>
                </div>
            </div>
        </section>
    </>
}
