import React, { useEffect, useState } from 'react'
import { LocalTimeStampToDate } from '../../MyFunctions/MyFunctions'
import TransictionInCss from './Css/TransictionIN.module.css'
const TransictionIN = () => {
    const [filterData, setFilterData] = useState()
    const [tableData, setTableData] = useState()
    const [bankList, setBankList] = useState()



    function onDataChange(params) {
        
    }
    function onSearchClick(params) {
        
    }


    function getTotalAmmount(myArray, fieldName) {
        let total = 0
        myArray?.map((item)=>{
            total +=item[fieldName] ? item[fieldName]:0
        })
        return total
    }
  return (
    <>
    <section className={TransictionInCss.salesMain}>
        <div className={TransictionInCss.innerSale}>
            <h2 className={TransictionInCss.saleHeading}>Transaction In</h2>
            <div className={TransictionInCss.search_section}>
                      <div className={TransictionInCss.input_section}>
                          <label>Start Date</label>
                          <input name='startDate' type={'date'} value={filterData?.startDate? filterData?.startDate:''} onChange={onDataChange}/>
                      </div>
                      <div className={TransictionInCss.input_section}>
                          <label>End Date</label>
                          <input name='endDate' type={'date'} value={filterData?.endDate ? filterData?.endDate:''} onChange={onDataChange}/>
                      </div>
                                         
                  </div>

                  <div className={TransictionInCss.buttonWrapper}>
                          <button onClick={onSearchClick}>search</button>
                          <button>reset</button>
                      </div>
            <div className={TransictionInCss.tableSection}>
                <table>
                    <tbody>
                        <tr>
                            <th>Sl</th>
                            <th>Date</th>
                            <th>Bank Name</th>
                            <th>Particular</th>
                            <th>Received</th>
                            <th>Process By</th>
                            <th>Action</th>
                        </tr>
                        {tableData?.map((item, index)=>{
                          return <tr key={index}>
                              <td>{index+1}</td>
                              <td>{LocalTimeStampToDate(item.created_date)}</td>
                              <td>{bankList.filter((fitem)=>{
                                return fitem.id===item.bankID ? fitem:''
                              })
                              .map((mitem)=>{
                                return <span key={mitem.id}>{mitem.bankName} - ({mitem.acNumber})</span>
                              })}</td>
                              <td>{item.particulars} (#{item.identity})</td>
                              <td>{item.receiveAmmount}</td>
                              <td>{item.processorID}</td>
                              <td>action</td>
                          </tr>
                        })}
                        <tr>
                            <td colSpan={4} style={{textAlign:'right'}}>Total : </td>
                            <td>{getTotalAmmount(tableData, 'receiveAmmount')}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</>
  )
}

export default TransictionIN