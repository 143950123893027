import React, { useEffect } from 'react'
import allrouteCss from './css/allroute.module.css'
import {Route, Routes, useNavigate } from 'react-router-dom'
import AddNewItemEdit from './listItemComponents/EditItem/AddNewItemEdit'
import AddNewItem from './AddNewItem'
import Item_catagory from './Item_catagory'
import BrandList from './BrandList'
import ManageTag from './ManageTag'
import Atributes from './Atributes'
import Units from './Units'
import ProductList from './ProductList'
import ErrorPage from '../../ErrorPage'
export default function AllRoutes() {
  return (
      <div className={allrouteCss.wrapper}>
          <Routes>
              <Route path='product-list' element={<ProductList />} />
              <Route path='product-list/:data' element={<AddNewItemEdit />} />
              <Route path='add-new-product' element={<AddNewItem />} />
              <Route path='category' element={<Item_catagory />} />
              <Route path='brands' element={<BrandList />} />
              <Route path='managetag' element={<ManageTag />} />
              <Route path='atributes' element={<Atributes />} />
              <Route path='unit' element={<Units />} />
              <Route path='/*' element={<ErrorPage />} />
          </Routes>
      </div>
  )
}
