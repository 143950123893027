import newPurchaseCss from './css/NewPurchase.module.css'
import { faCircle, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { RiDeleteBin6Line } from "react-icons/ri"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { fetchCategory, selectCategoryList, selectCategoryStatus } from '../../ReduxFiles/Slices/CategorySlice'
import { fetchUnits, selectMAUStatus, selectUnitList } from '../../ReduxFiles/Slices/MAU_Slice'
import { selectProductList } from '../../ReduxFiles/Slices/ProductSlice'
import { fetchAllSupplier, selectSupplierList, selectSupplierStatus } from '../../ReduxFiles/Slices/SupplierSlice'
import { selectUserInfo } from '../../ReduxFiles/Slices/UserSlice'
import { SaveDayBookData } from '../../api/DayBookApi'
import { SaveDebitData } from '../../api/DebitApi'
import { GetLastPurchaseNumber, GetLastPurchasePrice, SavePurchaseData, SavePurchaseItemData } from '../../api/PurchaseApi'

import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { FaMinus, FaPlus } from "react-icons/fa";

const NewPurchase = () => {
    const dispatch = useDispatch()
    const userInfo = useSelector(selectUserInfo)
    const mauStatus = useSelector(selectMAUStatus)
    const supplierStatus = useSelector(selectSupplierStatus)
    const categoryStatus = useSelector(selectCategoryStatus)
    const allProducts = useSelector(selectProductList)
    const mySupplier = useSelector(selectSupplierList)
    const unitData = useSelector(selectUnitList)
    const [myTabelItem, setTableItem] = useState([])
    const [myFinalData, setMyFinalData] = useState()
    const [alerMessage, setAlertMessage] = useState()
    // const [unitData, setUnitdata] = useState()  


    async function loadInitialData() {

        if (mauStatus.unitStatus === 'idle') {
            dispatch(fetchUnits())
        }

        if (supplierStatus === 'idle') {
            dispatch(fetchAllSupplier())
        }

        if (categoryStatus === 'idle') {
            dispatch(fetchCategory())
        }
    }

    useEffect(() => {
        loadInitialData()
    }, [])

    function decreaseClick(e, index) {
        let tableArr = [...myTabelItem]
        tableArr[index].quantity = tableArr[index].quantity ? tableArr[index].quantity - 1 : 0
        setTableItem([...tableArr])
    }

    function increaseClick(e, index) {
        let tableArr = [...myTabelItem]
        tableArr[index].quantity = tableArr[index].quantity ? tableArr[index].quantity + 1 : 1
        setTableItem([...tableArr])
    }
    function onQuantityChange(e, index) {
        let name = e.target.name
        let value = e.target.value
        let tableArr = [...myTabelItem]
        tableArr[index][name] = parseInt(value)
        setTableItem([...tableArr])
    }
    function onPriceChange(e, index) {
        let name = e.target.name
        let value = e.target.value
        let tableArr = [...myTabelItem]
        tableArr[index][name] = value
        setTableItem([...tableArr])
    }
    function onRemove(e, index) {
        let tableArr = [...myTabelItem]
        tableArr.splice(index, 1)
        setTableItem([...tableArr])
    }
    const [discountState, setDiscountState] = useState()
    function discountClick(e) {
        let name = e.target.getAttribute('name');
        let myValue = false
        if (!discountState?.[name]) {
            myValue = true
        }
        setMyFinalData({ ...myFinalData, fixed: 0, percent: 0 })
        setDiscountState({ ...discountState, discountfixed: false, discountpercent: false, [name]: myValue })
    }
    function discountChange(e) {
        let name = e.target.name
        let value = e.target.value
        setMyFinalData({ ...myFinalData, [name]: value })
    }
    function paymentMethodClick(e) {
        // let name = e.target.name
        let value = e.target.value
        setMyFinalData({ ...myFinalData, paymentMethod: value })
    }
    function payAmmountChange(e) {
        let name = e.target.name
        let value = e.target.value
        setMyFinalData({ ...myFinalData, [name]: value })
    }

    function getTotal(quantity, price) {
        if (!quantity) {
            quantity = 0
        }
        if (!price) {
            price = 0
        }
        return quantity * price
    }

    function getAllTotal(tableArr) {
        let myTotal = 0

        tableArr?.forEach(element => {
            let quantity = element.quantity ? element.quantity : 0
            let price = element.price ? element.price : 0
            myTotal += quantity * price
        });
        return myTotal
    }
    function getDicountAmmount() {
        let discount = 0
        let total = getAllTotal(myTabelItem)
        let fixed = 0
        let percent = 0
        if (myFinalData?.fixed) {
            fixed = myFinalData?.fixed
        }
        if (myFinalData?.percent) {
            percent = myFinalData?.percent
        }
        if (discountState?.discountfixed) {
            discount = parseInt(fixed)

        } else if (discountState?.discountpercent) {
            discount = (total * parseInt(percent)) / 100
        } else {
            discount = 0
        }
        return discount
    }
    function getDicountPercent() {
        let discountPercent = 0
        if (myFinalData?.fixed) {
            discountPercent = myFinalData?.fixed * 100 / myFinalData.total
        } else {
            discountPercent = myFinalData?.percent
        }
        // console.log(discountPercent)
        return discountPercent ? discountPercent : 0
    }
    function getGrandTotal() {
        let discount = getDicountAmmount()
        let total = getAllTotal(myTabelItem)
        return total - discount
    }
    function dueAmmount() {
        let total = 0
        let payAmmount = 0
        if (myFinalData?.payAmmount) {
            payAmmount = myFinalData?.payAmmount
        }

        if (myFinalData?.payAmmount) {
            total = getGrandTotal() - payAmmount
        } else {
            total = getGrandTotal()
        }
        let quantity = 0
        myTabelItem.forEach(element => {
            quantity += element.quantity ? element.quantity : 0
        })
        //console.log(quantity)
        setMyFinalData({ ...myFinalData, discountAmmount: getDicountAmmount(), grandTotal: getGrandTotal(), dueAmmount: total, totalQuantity: quantity, percent: getDicountPercent() })
    }

    useEffect(() => {
        dueAmmount()
    }, [myTabelItem])

    useEffect(() => {
        dueAmmount()
    }, [myFinalData?.percent, myFinalData?.fixed, myFinalData?.payAmmount])



    async function getDateTime() {
        let mydate = new Date();
        let day = mydate.getDate()
        let month = mydate.getMonth() + 1
        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }
        let year = mydate.getFullYear()
        let hour = mydate.getHours()
        if (hour < 10) {
            hour = '0' + hour
        }
        let minute = mydate.getMinutes()
        if (minute < 10) {
            minute = '0' + minute
        }
        // console.log(minute)
        let seconds = mydate.getSeconds()
        if (seconds < 10) {
            seconds = '0' + seconds
        }
        let finalTime = 'Date:' + year + '-' + month + '-' + day + ' ' + 'Time:' + hour + ':' + minute + ':' + seconds
        let NumberDoc = await GetLastPurchaseNumber()
        setMyFinalData({ ...myFinalData, poDate: finalTime, PoNo: (NumberDoc.lastNumber + 1), processorID: userInfo.id })
    }
    useEffect(() => {
        getDateTime()
    }, [])

    function dateChange(e) {
        let value = e.target.value
        setMyFinalData({ ...myFinalData, poDate: value })
    }
    function onSupplierChange(e) {
        let value = e.target.value

        if (value) {
            mySupplier.forEach(element => {
                if (value === element.companyaName) {
                    setMyFinalData({ ...myFinalData, supplierID: element.id })
                }
            });
        }
    }
    function getPercent() {
        // console.log(myFinalData)
        let myPercent = 0
        if (myFinalData.fixed) {
            let myTotal = getAllTotal(myTabelItem)
            myPercent = (myFinalData.fixed * 100) / myTotal
        } else {
            myPercent = myFinalData?.percent
        }

        return myPercent
    }
    async function onCompleteClick() {
        //  getPercent()
        //  console.log(getPercent())
        let tableDataok = false;
        if (myTabelItem.length < 1) {
            setAlertMessage('table item needed')
            toast.error('table item needed')
            // console.log()
        } else {
            for (let index = 0; index < myTabelItem.length; index++) {
                const element = myTabelItem[index];
                if (element.quantity > 0 && element.price > 0) {
                    tableDataok = true
                } else {
                    tableDataok = false
                    setAlertMessage('Price & Quantity Required in Item No : #' + (index + 1))
                    break;
                }
            }

            if (tableDataok) {
                if (!myFinalData.supplierID) {
                    setAlertMessage('Please Select Supplier to Continue')
                } else {
                    if (!myFinalData.paymentMethod) {
                        setAlertMessage('Please Select Payment Methotd to Continue')
                    } else {
                        // let finalAmount = myFinalData.payAmmount ? parseInt(myFinalData.payAmmount):0
                        let poData = {
                            ...myFinalData,
                            // payAmmount:myFinalData.payAmmount ? parseInt(myFinalData.payAmmount):0,
                            percent: getPercent(),
                            grandTotal: myFinalData?.grandTotal + myFinalData?.discountAmmount,
                            created_date: new Date().getTime(),
                            pending: 1,
                            status: 'PO Placed',
                            varianceManaged: 0,
                            receivedPO: 0,
                        }
                        delete poData.discountAmmount
                        delete poData.dueAmmount
                        delete poData.fixed
                        delete poData.PoNo
                        delete poData.totalQuantity
                        delete poData.payAmmount
                        let mDock = await  SavePurchaseData(poData)
                        let docID = mDock.insertId
                           let myPromises = myTabelItem.map(async function (element) {
                            let datatoSaved = {
                                purchase_id: docID,
                                product_id: element.id,
                                hasVriance: element.hasVariance,
                                quantity: element.quantity,
                                price: element.price,
                                created_date: new Date().getTime(),
                                varianceManaged: 0,
                            }
                          await SavePurchaseItemData(datatoSaved)
                        });

                        let dayBookData = {
                            created_date: new Date().getTime(),
                            paticulars: "Purchase (PO Placed)",
                            identity: docID,
                            received: 0,
                            payment: 0,
                            processorID: userInfo.id,
                        }
                        await SaveDayBookData(dayBookData)

                        let debitData = {
                            identity: docID,
                            paymentAmmount: myFinalData.payAmmount ? parseInt(myFinalData.payAmmount) : 0,
                            particulars: 'Purchase Payment',
                            processorID: userInfo.id,
                            remark: '',
                            paymentType: myFinalData.paymentMethod,
                            created_date: new Date().getTime()
                        }
                       await SaveDebitData(debitData)

                        Promise.all(myPromises).then(() => {
                           // setAlertMessage('Data Saved')
                            setAlertMessage('Data Saved')
                        })
                    }
                }
            }
        }
    }

    async function onSearchItemClick(item) {
        if (item) {
            let myArr = [];
        let dataFound = false
        myTabelItem.forEach(element => {
            myArr.push(element)
            if (element.id === item.id) {
                dataFound = true
            }
        });
        if (!dataFound) {
            item = { ...item, price: item.lastPrice }
            let latPriceItem = await GetLastPurchasePrice({id:item.id})
            myArr.push({ ...item, price: latPriceItem[0]?.price || 0 })

        } else {
            setAlertMessage('item already exist in the table')
        }
        setTableItem([])
        setTableItem(myArr)
        }
    }

    return (  
            <section className={newPurchaseCss.wrapper}>
            {alerMessage ? <AlertMessage message={alerMessage} setAlertMessage={setAlertMessage} /> : ""}
                <div className={newPurchaseCss.holder}>
                    <h2>New Purchase</h2>
                <div className={newPurchaseCss.p_search}>
                    <label>Search By Product</label>
                    <Select
                    options={allProducts}
                    getOptionLabel={(option)=>`${option.en_sort_title}`}
                    getOptionValue={(option)=>`${option.id}`}
                    onChange={onSearchItemClick}
                    className={newPurchaseCss.my_select}
                    isClearable
                    />
                </div>
                
               
                <div className={newPurchaseCss.table_others}>
                    <div className={newPurchaseCss.table_wrapper}>
                        <table>
                            <thead>
                                    <tr>
                                        <th>Sl</th>
                                        <th>Item Name</th>
                                        <th>Quantity</th>
                                        <th>Unit</th>
                                        <th>Price</th>
                                        <th>Total</th>
                                        <th>Action</th>
                                    </tr>
                            </thead>
                            <tbody>
                            {myTabelItem.map((item, index)=>{
                                return <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{item.en_sort_title}</td>
                                    <td><div className={newPurchaseCss.input_wrap}>
                                            <button onClick={(e) => { decreaseClick(e, index) }}><FaMinus /></button>
                                            <input type='number' name='quantity'
value={item.quantity ? item.quantity : 0} onChange={(e) => { onQuantityChange(e, index) }}/>
                                            <button onClick={(e) => { increaseClick(e, index) }}><FaPlus /></button>
                                        </div></td>
                                        <td>pcs</td>
                                    <td><div className={newPurchaseCss.input_wrap}>
                                            
                                            <input name='price' type='number' value={item.price ? item.price : 0} onChange={(e) => { onPriceChange(e, index) }}/>
                                        </div></td>
                                        <td>{getTotal(item.quantity, item.price)}</td>
                                        <td><div className={newPurchaseCss.action_buttons}>
                                        <button onClick={(e) => { onRemove(e, index) }}><RiDeleteBin6Line /></button>
                                        </div></td>
                                </tr>
                            })}
                            </tbody>
                        </table>
                    </div>

                    <div className={newPurchaseCss.info_section}>
                        <div className={newPurchaseCss.po_section}>
                            <p>PO No : </p>
                            <p># {myFinalData?.PoNo}</p>
                        </div>
                        <div className={newPurchaseCss.po_section}>
                            <p>PO Date : </p>
                            <input className={newPurchaseCss.datepicker} name='poDate' defaultValue={myFinalData?.poDate} onChange={dateChange} type="text"/>
                        </div>
                       
                        <div className={newPurchaseCss.supplier_select}>
                            <label>Select Supplier</label>
                            <select name='supplierName' onChange={onSupplierChange}>
                                <option value=''>--select one--</option>
                                {mySupplier?.map((item) => {
                                    return <option key={item.id} value={item.companyaName}>{item.companyaName}</option>
                                })}
                            </select>
                        </div>
                        
                        <div className={newPurchaseCss.supplier_discount}>
                            <p onClick={discountClick} name='discountfixed'> {discountState?.discountfixed ? <FontAwesomeIcon className={newPurchaseCss.selected} icon={faCircleCheck} /> : <FontAwesomeIcon icon={faCircle} />} Discount (Fixed)</p>
                            <p onClick={discountClick} name='discountpercent'> {discountState?.discountpercent ? <FontAwesomeIcon className={newPurchaseCss.selected} icon={faCircleCheck} /> : <FontAwesomeIcon icon={faCircle} />} Discount (%)</p>
                            {discountState?.discountfixed ?  <input name='fixed' type='text' onChange={discountChange} placeholder='discount ammount' /> : ""}
                            {discountState?.discountpercent ?  <input name='percent' type='text' onChange={discountChange} placeholder='discount %' /> : ""}
                        </div>

                       

                      

                        <div className={newPurchaseCss.purchase_total} >
                            <div className={newPurchaseCss.counting}>
                                <p className={newPurchaseCss.constant}>Sub Total </p>
                                <p>: {myFinalData?.grandTotal + myFinalData?.discountAmmount} <span>Taka</span></p>
                            </div>
                            <div className={newPurchaseCss.counting}>
                                <p className={newPurchaseCss.constant}>Discount (-) </p>
                                <p>: {myFinalData?.discountAmmount} <span>Taka</span></p>
                            </div>
                            <div className={newPurchaseCss.counting}>
                                <p className={newPurchaseCss.constant}>Grand Total </p>
                                <p>: {myFinalData?.grandTotal} <span>Taka</span></p>
                            </div>
                        </div>
                        <div className={newPurchaseCss.payment_method}>
                            <div className={newPurchaseCss.cash}>
                                <input type="radio" id="cash" name="paymentType" value="cash" onClick={paymentMethodClick} />
                                <label htmlFor="cash">Cash</label>
                            </div>
                            <div className={newPurchaseCss.check}>
                                <input type="radio" id="check" name="paymentType" value="check" onClick={paymentMethodClick} />
                                <label htmlFor="check">Check</label>
                            </div>
                        </div>

                        <div className={`${newPurchaseCss.paid_ammount} ${newPurchaseCss.total_due}`}>
                            <div className={newPurchaseCss.total}>
                                <p>Net Payable Amount : <span>{myFinalData?.grandTotal}</span></p>
                            </div>
                        </div>

                        <div className={newPurchaseCss.paid_ammount}>
                            <label>Paid Ammount : </label>
                            <input name='payAmmount' type='text' defaultValue={0} onChange={payAmmountChange} />
                        </div>
                        <div className={ `${newPurchaseCss.paid_ammount} ${newPurchaseCss.total_due}`}>
                            <div className={newPurchaseCss.due}>
                                <p>Due Amount : <span>{myFinalData?.dueAmmount}</span></p>
                            </div>
                        </div>
                        <div className={newPurchaseCss.paid_ammount}>
                            <label>Upload File : </label>
                            <input type='file' />
                        </div>
                        <div className={newPurchaseCss.button_group}>
                            <button onClick={onCompleteClick}>Complete</button>
                            <button>Save as Draft</button>
                        </div>

                    </div>
                </div>
                </div>
               

            </section>
    

    )
}

export default NewPurchase

const AlertMessage = ({ message, setAlertMessage }) => {
    let myNavigate = useNavigate()
    function alertClick(e) {
        // console.log(e)
        if (message === 'Data Saved') {
            myNavigate('/admin/purchase/pendinglist')
            setAlertMessage()
        } else {
            setAlertMessage()
        }

    }
    return (
        <div className={newPurchaseCss.AlertMessage}>
            <div className={newPurchaseCss.pop_holder}>
            <h2 >{message}</h2>
            <button onClick={alertClick}>OK</button>
            </div>
        </div>
    )
}