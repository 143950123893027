import React, { useState } from "react";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../../../../ReduxFiles/Slices/UserSlice";
import { ServerTimeToFormateTime } from "../../../../../api/GlobalFunctions";
import {
  GetIndividualInvoiceWiseReport,
  GetIndividualInvoiceWiseReportProductWise,
} from "../../../../../api/SalesApi";
import GobackButton from "../../../../global/GobackButton";
import style from "./css/report.module.css";
import InvoiceDetails from "./models/InvoiceDetails";
export default function InvoiceWiseReport() {
  const [tableData, setTableData] = useState([]);
  const [tableTotal, setTableTotal] = useState({
    price_total: 0,
    dc_total: 0,
    grand_total: 0,
  });

  const userInfo = useSelector(selectUserInfo);

  async function onGenarateClick(e) {
    e.preventDefault();
    const start = e.target.start.value;
    const end = e.target.end.value;
    const start_date = new Date(start).getTime();
    const end_date = new Date(end).getTime();
    const data = await GetIndividualInvoiceWiseReport(
      `start_date=${start_date}&end_date=${end_date}&user_id=${userInfo.id}`
    );
    setTableData(data);
    const allTotal = data.reduce(
      (item, curritem) => {
        item.price_total += parseInt(curritem.total_price);
        item.dc_total += curritem.deliveryCharge;
        item.grand_total +=
          curritem.deliveryCharge + parseInt(curritem.total_price);
        return item;
      },
      {
        price_total: 0,
        dc_total: 0,
        grand_total: 0,
      }
    );
    setTableTotal(allTotal);
  }
  const [detailInvoice, setDetailInvoice] = useState();
  async function onDetailViewClick(item) {
    const productData = await GetIndividualInvoiceWiseReportProductWise(
      `sales_ids=${item.id}`
    );
    setDetailInvoice({ ...item, products: productData });
    // console.log(productData);
  }
  return (
    <div className={style.wrapper}>
      {detailInvoice && (
        <InvoiceDetails popUp={detailInvoice} setPopUp={setDetailInvoice} />
      )}
      <div className={style.header_part}>
        <GobackButton />
        <h3>Invoice Wise Report</h3>
      </div>
      <div className={style.holder}>
        <div className={style.form_wrapper}>
          <form onSubmit={onGenarateClick}>
            <div className={style.input_wrapper}>
              <label>Start Date*</label>
              <input type="date" name="start" required />
            </div>
            <div className={style.input_wrapper}>
              <label>End Date*</label>
              <input type="date" name="end" required />
            </div>
            <div className={style.button_wrapper}>
              <button>Genarate</button>
            </div>
          </form>
        </div>
        <div className={style.table_wrapper}>
          <table aria-label="invoice">
            <thead>
              <tr>
                <th>SL</th>
                <th>Invoice</th>
                <th>Status</th>
                <th>Date</th>
                <th>Name</th>
                <th>Address</th>
                <th>Price</th>
                <th>DC</th>
                <th>Total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.id}</td>
                    <td>{item.status}</td>
                    <td>{ServerTimeToFormateTime(item.created_date)}</td>
                    <td>{item.name}</td>
                    <td>{item.address}</td>
                    <td>{item.total_price}</td>
                    <td>{item.deliveryCharge}</td>
                    <td>{item.deliveryCharge + parseInt(item.total_price)}</td>
                    <td>
                      <button onClick={() => onDetailViewClick(item)}>
                        <FaEye />
                      </button>
                    </td>
                  </tr>
                );
              })}
              <tr className={style.grand_total_wrapper}>
                {/* <td colSpan={5}></td> */}
                <td colSpan={6}>Grand Total : </td>
                <td>{tableTotal.price_total}</td>
                <td>{tableTotal.dc_total}</td>
                <td>{tableTotal.grand_total}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
