import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { LocalTimeStampToDate } from '../../MyFunctions/MyFunctions'
import { HeaderFooterWrapper } from '../HeadetFooter'
import AccountAraGlanceReportPDFCss from './css/AccountAraGlanceReportPDF.module.css'
const AccountAraGlanceReportPDF = ({ heading, date }) => {
    const [bankList, setBankList] = useState()
    let [searchParams, setSearchParams] = useSearchParams();
    const [dateRange, setDateRange] = useState()
    const [todayCash, setTodayCash] = useState()
    const [preDateCash, setPreDateCash] = useState()
    const [todayExpense, setTodayExpense] = useState()
    const [preDateExpense, setPreDateExpense] = useState()
    const [todayDueSales, setTodayDueSales] = useState()
    const [preDueSales, setPreDueSalse] = useState()
    const [todayBankamount, setTodayBankamount] = useState()
    const [preBankAmount, setPreBankAmount] = useState()
    async function getData() {
        let myBankList = [] //await GetDatafromDatabase('BankList', 'mbankID')
        setBankList(myBankList)
        let mySalesData = [] //await GetDatafromDatabase('sales', 'salesID')
        let myPurchaseData = [] //await GetDatafromDatabase('Purchase', 'purchaseID')
        let myCashData = [] //await GetDatafromDatabase('cashinhand', 'cashID')
        let myExpenseData  = [] //await GetDatafromDatabase('Expense', 'expenseID')
       
        let myTransictionData = [] //await GetDatafromDatabase('Bank_Transaction', 'trID')
        let SSLData = [] //await GetDatafromDatabase('SSLData', 'sslID')

        let trData = []
        myTransictionData.forEach(element => {
            if (element.head === "Withdraw" || element.head === "Deposite") {
                trData.push(element)
            }
        });
       // console.log(myCashData)
      let todayCash = todayData(myCashData)
      let preCash = preData(myCashData)
      let todaySales = todayData(mySalesData)
      let preSales = preData(mySalesData)
      let todaytrData = todayData(myTransictionData)
      let pretrData = preData(myTransictionData)
      let todaysslData = todayData(SSLData)
      let presslData = preData(SSLData)

      let todayExData = todayData(myExpenseData)
      let preExData = preData(myExpenseData)
      let todayPurchaseData = todayData(myPurchaseData)
      let prePurchaseData = preData(myPurchaseData)

    //  console.log(todaySales, preSales)

      setTodayCash(organizeCashData(myBankList, todaytrData, todayCash, todaysslData))
      setPreDateCash(organizeCashData(myBankList, pretrData, preCash, presslData,))
      setTodayDueSales(organizeDueSales(todaySales))
      setPreDueSalse(organizeDueSales(preSales))
      setTodayExpense(organizeExpenseData(todayExData, todayPurchaseData))
      setPreDateExpense(organizeExpenseData(preExData, prePurchaseData))
      setTodayBankamount(bankBalance(myBankList, todaytrData))
      setPreBankAmount(bankBalance(myBankList, pretrData))
       //organizeCashData(myBankList, trData, myCashData)
    }
    function todayData(itemsArr) {
        let mendDate = searchParams.get('end_date')
        let todayTime = ''
        if (mendDate) {
            todayTime = new Date(mendDate).toLocaleDateString()
        } else {
            todayTime = new Date().toLocaleDateString()
        }
        
        let toadayArr = []
        itemsArr?.forEach(element => {
            let myNewData = new Date(element.createdDate.seconds*1000).toLocaleDateString()
            if (myNewData===todayTime) {
                toadayArr.push(element)
            }
          });
        return toadayArr
    }
    function preData(itemsArr) {
        let mendDate = searchParams.get('end_date')
        let todayTime = ''
        if (mendDate) {
            todayTime = new Date(mendDate).toLocaleDateString()
        } else {
            todayTime = new Date().toLocaleDateString()
        }

        let previousArr = []
        itemsArr?.forEach(element => {
            let myNewData = new Date(element.createdDate.seconds*1000).toLocaleDateString()
            if (myNewData!==todayTime) {
                previousArr.push(element)
            }
        });
        return previousArr
        
    }

    function organizeCashData(myBankList, trData, myCashData, mySSlData, mysaleData) {
        let finalTrArr = []
        let okArr = []
       // console.log(myBankList)
     //   console.log(trData)
        okArr.push({particular:'Opening Balance Cash', amount:0})
        myBankList.forEach(melement => {
           // console.log(melement)
            let total = 0
            let bankName =melement.bankName
            trData.forEach(element => {
                if (element.bankID === melement.mbankID) {
                   // console.log(melement.bankName)
                    
                    if (element.particulars==='Withdraw') {
                        element = {...element, bankName:melement.bankName, acNumber:melement.acNumber, acName:melement.acName} 
                        finalTrArr.push(element)
                        total += element.paymentAmmount
                        bankName = melement.bankName+` (${melement.acName})`
                    }
                   
                 }
            });
            okArr.push({particular:'Withdraw from ' + bankName, amount:total})
        });
        let cashArr = []
        let myTotal = 0
        myCashData.forEach(element => {
            if (element.head !== "Withdraw" && element.head !== "Deposite") {
                myTotal += element.receiveAmmount
                cashArr.push(element)
            } else {
                //  console.log(element.head)
            }
        });
        okArr.push({particular:'Cash Received', amount:myTotal})
        let sslTotal = 0
        mySSlData.forEach(element => {
            sslTotal +=element.receiveAmmount 
        });
        okArr.push({particular:'SSL Received', amount:sslTotal})
       return okArr
    }
    function organizeDueSales( salesData)  {
       // console.log(expenseData)
        let saleDue = 0
        salesData.forEach(element => {
            saleDue +=element.grandTotal+element.deliveryCharge- element.collectedAmmount
        });
        
       return saleDue
    }
    function organizeExpenseData( expenseData, purchaseData)  {
       // console.log(expenseData)
        let okArr = []
        let myTotal = 0
        expenseData.forEach(element => {
          //  console.log(element.paymentAmmount)
            myTotal += element.paymentAmmount
        });
        okArr.push({particular:'Expense', amount:myTotal})
        okArr.push({particular:'Capital Withdraw', amount:0})
        okArr.push({particular:'Capitel Expense', amount:0})
        let pTotal = 0
      //  console.log(purchaseData)
        purchaseData.forEach(element => {
            element?.Payment.forEach(pelement => {
                pTotal += pelement.paymentAmmount
            });
          //  console.log(element.paymentAmmount)
          
        });
        okArr.push({particular:'Purchase', amount:pTotal})
       return okArr
    }
    function bankBalance(myBankList, trData) {
        let okArr = []
       
        myBankList.forEach(melement => {
             let rtotal = 0
             let bankName =melement.bankName
             trData?.forEach(element => {
                 if (element.bankID === melement.mbankID) {
                   
                    if (element.particulars==='Deposite') {
                       // console.log(element.receiveAmmount)
                        rtotal += element.receiveAmmount
                        bankName = melement.bankName+` (${melement.acName})`
                    }
                  }
             });
             okArr.push({particular:'' + bankName, amount:rtotal})
         })
         return okArr
    }

    useEffect(() => {
        getData()
    }, [])

    function checkClick(params) {

    }
   
    return (
        <>
            <HeaderFooterWrapper
                content={<>
                <h3>Account AT A GLANCE REPORT</h3>
            <div>
                <div className={AccountAraGlanceReportPDFCss.invoice}>
                    <div className={AccountAraGlanceReportPDFCss.info}>
                        {dateRange?.start_date ? <span className={AccountAraGlanceReportPDFCss.moreinfo}>Date : {dateRange?.start_date ? LocalTimeStampToDate(dateRange?.start_date) + ' to ' + LocalTimeStampToDate(dateRange?.end_date) : ''} </span> : <span className={AccountAraGlanceReportPDFCss.moreinfo}> Date : {date}</span>}
                    </div>
                </div>
            </div>
            <div className={AccountAraGlanceReportPDFCss.table}>
                <table>
                    <thead>
                        <tr>
                            <th className={AccountAraGlanceReportPDFCss.no} style={{ textAlign: 'center', fontSize: '16px' }} colSpan={3}>At A Glance - {dateRange?.end_date ? LocalTimeStampToDate(dateRange?.end_date) : new Date().toLocaleDateString()}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {todayCash?.map((item, index) => {
                            return <MyCashTable sl={index+1} particular={item.particular} amount={item.amount} Message={'On Date '}/>
                        })}
                        <MyTableTotal array={todayCash} Message={'On Date Toatal Cash Received'}/>

                        {preDateCash?.map((item, index) => {
                            return <MyCashTable sl={index+1} particular={item.particular} amount={item.amount+todayCash?.[index]?.amount} Message={'To Date'}/>
                        })}
                        <MyTableTotal array={preDateCash} arrayB={todayCash} Message={'To Date Toatal Cash Received'}/>
                        <MyCashTable sl={1} particular={''} amount={preDueSales} Message={'Opening Outstanding'}/>
                        <MyCashTable sl={2} particular={''} amount={todayDueSales} Message={'Closing Outstanding'}/>
                        <td></td>
                        {/* {console.log(todayExpense)} */}
                        {todayExpense?.map((item, index) => {
                            return <MyCashTable sl={index+1} particular={item.particular} amount={item.amount} Message={'On Date'}/>
                        })}
                         <MyTableTotal array={todayExpense} Message={'On Date Toatal Expense'}/>
                        {preDateExpense?.map((item, index) => {
                            return <MyCashTable sl={index+1} particular={item.particular} amount={item.amount+todayExpense?.[index]?.amount} Message={'To Date'}/>
                        })}
                         <MyTableTotal array={todayExpense}  arrayB={preDateExpense} Message={'On Date Toatal Expense'}/>
                         {/* {console.log(bankList)} */}
                        {todayBankamount?.map((item, index) => {
                            return <MyCashTable sl={index+1} particular={item.particular} amount={item.amount} Message={'On Date'}/>
                        })}
                         <MyTableTotal array={todayBankamount} Message={'On Date Bank Total'}/>
                        {preBankAmount?.map((item, index) => {
                            return <MyCashTable sl={index+1} particular={item.particular} amount={item.amount+todayBankamount?.[index].amount+bankList?.[index].openingBanlance} Message={'To Date'}/>
                        })}
                         <MyTableTotal array={preBankAmount} arrayB={todayBankamount} arrayC={bankList} Message={'To Date Bank Total'}/>
                    
                    </tbody>
                </table>
            </div>
                </>}
            />
        </>
    )
}

export default AccountAraGlanceReportPDF

const MyCashTable = ({sl, particular, amount, Message})=>{
   
    return <>
        <tr key={sl}>
    <td>{sl}</td>
    <td style={{textAlign:'left'}}>{Message} {particular}</td>
    <td>{amount}</td>
    </tr>
    </>
}
const MyTableTotal = ({array, arrayB, arrayC, Message})=>{
    // console.log(arrayB)
    
    function getTotal (passArr) {
        let Total = 0
        passArr?.map((item)=>{
            Total += item.amount
        })
      return Total  
    }
    function getTotalC (passArr) {
        let Total = 0
        passArr?.map((item)=>{
            Total += item.openingBanlance
        })
      return Total  
    }
    return  <tr>
    <td colSpan={2} style={{fontWeight:'bold'}}>{Message}</td>
    <td style={{fontWeight:'bold'}}>{getTotal(array)+getTotal(arrayB)+getTotalC(arrayC)}</td>
</tr>
}