import React, { useEffect, useState } from 'react'
import { GetDataByFieldPath, GetDatafromDatabase, GetDatafromDatabasewithOrder, GetNestedAllData, GetSingleDoc } from '../../connector/DatabaseCheck'
import {useParams, useSearchParams} from 'react-router-dom'
import { LocalTimeStampToDate } from '../../MyFunctions/MyFunctions'
import { GetAllDataExpress, GetFilteredDataExpress, GetSingleDocExpress } from '../../../api/ExpressFunctions'
import { HeaderFooterWrapper } from '../HeadetFooter'
import DetailsReportCss from './css/DetailsReport.module.css'

const DetailsReport = ({heading, date}) => {
    const [invoiceState, setInvoiceState] = useState()
    const [supplierInfo, setSupplierInfo] = useState()
    let [searchParams, setSearchParams] = useSearchParams();
    const [dateRange, setDateRange] = useState()
    const [allProducts, setAllProducts] = useState()

 async function getData() {
    setAllProducts(JSON.parse(window.localStorage.getItem('allProduct')))
    let getAllPurchase = []
    let supplierid = searchParams.get('supplierid')
   
    //console.log(start_date, end_date, supplierid, productIdentity)
    //start_date && end_date && supplierid &
    if (supplierid) {
        let supplierData =[] //await GetSingleDocExpress('supplier', supplierid)
        console.log(supplierid)
        setSupplierInfo(supplierData[0])
        getAllPurchase = [] // await GetFilteredDataExpress('purchase','supplierID', supplierid )
      //  getindivisualData(getAllPurchase)
    }else{
        getAllPurchase = [] // await GetAllDataExpress('purchase')
      //  getindivisualData(getAllPurchase)
    }
}

function getindivisualData(purchaseArr) {
    let start_date = searchParams.get('start_date')
    let end_date = searchParams.get('end_date')
   // console.log(end_date)
    let productIdentity = parseInt(searchParams.get('productIdentity'))
    setDateRange({start_date, end_date})
    
    let allProduct = []
    let returnProducts = []
    const myPromises = purchaseArr?.map(async function(item){
        let myNestedData = [] // await GetFilteredDataExpress('purchase_items', 'purchase_id', item.id)
        let myReturnData = [] //await GetFilteredDataExpress('purchase_return_items', 'po_id', item.id)
        myNestedData.forEach(myelement => {
            allProduct.push({...myelement, PoNo:item.id})
        });
        myReturnData.forEach(myelement => {
            returnProducts.push({...myelement, PoNo:item.id})
        });
    })

    Promise.all(myPromises).then(()=>{
        let filterArr = []
        if (start_date && end_date && productIdentity) {
            allProduct?.filter((fitem)=>{
                return fitem.product_id===productIdentity ? fitem:''
            })
            .map((item)=>{
                if (item.created_date>new Date(start_date).getTime() && item.created_date<new Date(end_date).getTime()) {
                    filterArr.push(item)
                }
            })
            let sortArr = calculateData(filterArr, returnProducts).sort((a, b) => (a.created_date > b.created_date) ? 1 : -1)
            setInvoiceState(sortArr)
        }else if (start_date && end_date) {
            //console.log('working this', allProduct)
            allProduct?.map((item)=>{
                if (item.created_date>new Date(start_date).getTime() && item.created_date<new Date(end_date).getTime()) {
                   // console.log(item) 
                    filterArr.push(item)
                }
                
            })
            let sortArr = calculateData(filterArr, returnProducts).sort((a, b) => (a.created_date > b.created_date) ? 1 : -1)
            setInvoiceState(sortArr)
        }
        else if(productIdentity){
            allProduct.sort((a, b) => (a.created_date > b.created_date) ? -1 : 1)
            let filterArr = []
            allProduct?.filter((fitem)=>{
                return fitem.product_id===productIdentity ? fitem:''
            })
            .map((item)=>{
                filterArr.push(item)
            })
           // console.log(filterArr)
            setInvoiceState(calculateData(filterArr, returnProducts))
        }else{
            allProduct.sort((a, b) => (a.created_date > b.created_date) ? -1 : 1)
            setInvoiceState(calculateData(allProduct, returnProducts))
        }
    })
}
function calculateData(allProduct, returnProducts) {
    let finalArr = []
    allProduct.forEach(element => {
        let dataFound = false;
        returnProducts.forEach(relement => {
            if (relement.product_id===element.product_id && relement.po_id===element.purchase_id) {
                console.log(relement)
                dataFound = true; 
                 element = {
                    ...element, 
                    rQuantity:relement.quantity ? relement.quantity:0,
                    finalQty:element.quantity-relement.quantity
                 }
                 finalArr.push(element)
            }
       });
       if (!dataFound) {
        finalArr.push({...element, rQuantity:0, finalQty:element.quantity})
       }
    });
 //   console.log(finalArr)
    return finalArr
}

useEffect(()=>{
    getData()
},[])

 function dataOrganize(passedData) {
        
}

    function checkClick(params) {
   
    }

    useEffect(() => {
        getData()
    }, [])

    function getAlltotal(array) {
        let allTotal = 0
        array?.forEach(element => {
            allTotal += element.finalQty * (element.lastPrice ? parseInt(element.lastPrice):parseInt(element.price))
        });
        
       return allTotal
    }
    function getTotalQuantity(array) {
        let allTotal = 0
        array?.forEach(element => {
            allTotal += element.quantity
        });

       return allTotal
    }
    function getTotalQuantityCustom(array, identity) {
        let allTotal = 0
        array?.forEach(element => {
            allTotal += element[identity]
        });

       return allTotal
    }

  return (
    <>

           <HeaderFooterWrapper
           content={<>
            <h3>{heading}</h3>
            <div className={DetailsReportCss.details}>
                <div className={DetailsReportCss.client}>
                    <div className={DetailsReportCss.info}>
                        <span className={DetailsReportCss.moreinfo} onClick={checkClick}>Supplier : </span>{supplierInfo ?  supplierInfo?.companyaName:'All Supplier' }
                    </div>
                </div>
                <div className={DetailsReportCss.invoice}>
                    <div className={DetailsReportCss.info}>
                        {date ? <span className={DetailsReportCss.moreinfo}>Date : {dateRange?.start_date ? dateRange?.start_date +' to '+ dateRange?.end_date:'All Time'} </span>:''}
                    </div>
                </div>
            </div>
            <div className={DetailsReportCss.table}>
                <table>
                    <thead>
                        <tr>
                            <th className={DetailsReportCss.no}>Sl</th>
                            <th className={DetailsReportCss.no}>Date & Time</th>
                            <th style={{ paddingLeft: '10px'}}>Po No.</th>
                            <th >Product Name</th>
                            <th >P. Quantity</th>
                            <th >R. Quantity</th>
                            <th >T. Quantity</th>
                            <th className={DetailsReportCss.total}>Price</th>
                            <th className={DetailsReportCss.total}>Sub Total</th>
                        </tr>
                    </thead>
                    <tbody>
                    {invoiceState?.map((item, index)=>{
                        return <>
                            <tr key={index}>
                            <td>{index+1}</td>
                            <td>{LocalTimeStampToDate(item.created_date/1000)}</td>
                            <td style={{ textAlign: 'left'}} >{item.PoNo}</td>
                            <td style={{ textAlign: 'left'}}>{allProducts?.filter((fitem)=>{
                                return fitem.id === item.product_id ? fitem:''
                            }).map((myItems)=>{
                                return myItems.en_sort_title
                            })}</td>
                            <td>{item.quantity}</td>
                            <td>{item.rQuantity}</td>
                            <td>{item.finalQty}</td>
                            <td>{item.price}</td>
                            <td>{item.finalQty* (item.lastPrice ? parseInt(item.lastPrice):parseInt(item.price))}</td>
                            </tr>
                        </>
                    })}
                    
                    <tr className={DetailsReportCss.final_row}>
                        {/* <td className={DetailsReportCss.no_border_right} ></td> */}
                        <td className={DetailsReportCss.no_border_right} colSpan={'4'}>Total :</td>
                        {/* <td>{getAlltotal(invoiceState, 'purchaseAmmount')}</td>
                        <td>{getAlltotal(invoiceState, 'returnAmmount')}</td> */}
                        <td >{getTotalQuantity(invoiceState)}</td>
                        <td >{getTotalQuantityCustom(invoiceState, 'rQuantity')}</td>
                        <td >{getTotalQuantityCustom(invoiceState, 'finalQty')}</td>
                        <td >-</td>
                        <td >{getAlltotal(invoiceState)}</td>
                    </tr>
                    </tbody>
                </table>
                {/* <div className='last-raw'>
                <p>Balance :  {getAlltotal(invoiceState, 'purchaseAmmount')-getAlltotal(invoiceState, 'returnAmmount')+getAlltotal(invoiceState, 'receiptAmmount')-getAlltotal(invoiceState, 'paymentAmmount')}</p>
                </div> */}
            </div>
           </>}
           />

        </>
  )
}

export default DetailsReport