import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetALLAttributes, GetALLAttributesName, GetALLUnits } from '../../api/MAU_Api'

const initialState = {
  unit_list:[],
  tag_list:[],
  attributes_list:[],
  attributes_name_list:[],
  status:{
    unitStatus:'idle',
    attributesStatus:'idle',
    attributesNameStatus:'idle',
    tagStatus:'idle',
  },
}
export const MAU_slice = createSlice({
  name: 'mauslice',
  initialState,
  reducers: {
    setUnitList: (state, action) => {
      state.unit_list = action.payload
    },
    setTaglist: (state, action) => {
        state.tag_list = action.payload
    },
    setAttributesList: (state, action) => {
        state.attributes_list = action.payload
    },
    setAttributesNameList: (state, action) => {
        state.attributes_name_list = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchUnits.pending, (state, action) => {
      state.status.unitStatus = 'pending'
    })
    .addCase(fetchUnits.fulfilled, (state, action) => {
        state.status.unitStatus = 'success'
      let mData = action.payload
        state.unit_list = mData
    })
    .addCase(fetchUnits.rejected, (state, action) => {
        state.status.unitStatus = action.payload
    })
    .addCase(fetchAtributes.pending, (state, action) => {
      state.status.attributesStatus = 'pending'
    })
    .addCase(fetchAtributes.fulfilled, (state, action) => {
        state.status.attributesStatus = 'success'
      let mData = action.payload
        state.attributes_list =mData
    })
    .addCase(fetchAtributes.rejected, (state, action) => {
        state.status.attributesStatus = action.payload
    })
    .addCase(fetchAtributesName.pending, (state, action) => {
      state.status.attributesStatus = 'pending'
    })
    .addCase(fetchAtributesName.fulfilled, (state, action) => {
        state.status.attributesNameStatus = 'success'
      let mData = action.payload
        state.attributes_name_list =mData
    })
    .addCase(fetchAtributesName.rejected, (state, action) => {
        state.status.attributesStatus = action.payload
    })
},
  
})

export const fetchUnits = createAsyncThunk('category/fetchUnits', async ()=>{
    try {
      const resposne = await GetALLUnits()
      return resposne
    } catch (error) {
      return error.message
    }
})
export const fetchAtributes = createAsyncThunk('category/fetchAtributes', async ()=>{
    try {
      const resposne = await GetALLAttributes()
      return resposne
    } catch (error) {
      return error.message
    }
})
export const fetchAtributesName = createAsyncThunk('category/fetchAtributesName', async ()=>{
    try {
      const resposne = await GetALLAttributesName()
      return resposne
    } catch (error) {
      return error.message
    }
})


export const selectUnitList = state =>state.mauslice.unit_list
export const selectAttributeList = state =>state.mauslice.attributes_list
export const selectAttributeNameList = state =>state.mauslice.attributes_name_list
export const selectTagList = state =>state.mauslice.tag_list
export const selectMAUStatus = state =>state.mauslice.status
export const { setUnitList, setTaglist, setAttributesList, setAttributesNameList} = MAU_slice.actions

export default MAU_slice.reducer
