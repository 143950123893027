import MyAxios from 'axios'
import CryptoJS from 'crypto-js'

//admin functions

//local link
// export let ApiLink = 'http://192.168.10.14:3002/'
// export let ImageLink = 'http://192.168.10.14:3002/uploads/'

// vm link
// export let ApiLink = '//43.224.110.126:5002/'
// export let ImageLink = '//43.224.110.126:5002/uploads/'

// vm link test anayase
// export let ImageLink = 'http://43.224.110.126:5003/uploads/'
// export let ApiLink = 'http://43.224.110.126:5003/'

// export let ApiLink = 'http://10.110.117.68:5003/'
// export let ImageLink = 'http://10.110.117.68:5003/uploads/'

// export let ApiLink = '//dummy.goldenmartbd.com/'
// export let ImageLink = '//dummy.goldenmartbd.com/'

//anayse back end api functions
// vm link test anayase
// export let ImageLink = 'http://43.224.110.126:5003/uploads/'
// export let ApiLink = 'http://43.224.110.126:5003/'

// // vm link anayase main
export let ApiLink = 'https://api.anayase.com/'
export let ImageLink = 'https://api.anayase.com/'




export async function UserValidation() {
  let userok = false
  let localUser = window.localStorage.getItem('user_id')
  if (localUser) {
    let mquery = `SELECT * FROM anayasemain.backend_user where id=${localUser} and active = 1;`
    let userData =  await GetDataPassQuery (mquery)
   // console.log(userData)
    if (userData.length>0) {
      userok = true
    }
  }
  return userok
}


export function SendSMSFromRobi(phone, message) {
  let urlget = `https://api.mobireach.com.bd/SendTextMessage?Username=anayase&Password=Dhaka@0088&From=Anayase&To=88${phone}&Message=${message}`
  MyAxios.get(urlget, {})
    .then(function (response) {
      console.log(response); // this will print xml data structure
    })
}

export async function GetDataPassQuery (query) {
    let Data;
    await MyAxios.get(ApiLink+`getdataparam/${query}`).then((res)=>{

        Data =  res.data
    })
    return Data
}
export async function GetAllDataExpress (tableName) {
    let Data;
    await MyAxios.get(ApiLink+`getdata/${tableName}`).then((res)=>{

        Data =  res.data
    })
    return Data
}
export async function GetSingleDocExpress (tableName, passedID) {
    let Data;
    await MyAxios.get(ApiLink+`singledoc/${tableName}/${passedID}`).then((res)=>{

        Data =  res.data
    })
    return Data
}
export async function GetFilteredDataExpress (tableName, colName, coldata) {
    let Data;
    await MyAxios.get(ApiLink+`getfileterdata/${tableName}/${colName}/${coldata}`).then((res)=>{

        Data =  res.data
    })
    return Data
}
export async function GetDoubleQueryExpress (tableName, colName1, colName2, coldata1, coldata2) {
    let Data;
    await MyAxios.get(ApiLink+`getDoubleQuery/${tableName}/${colName1}/${coldata1}/${colName2}/${coldata2}`, ).then((res)=>{
      Data = res.data      
    })
    return Data
}

export async function SaveDataExpress (tableName, passedObject) {
    let itemID;
    await MyAxios.post(ApiLink+'create-items/'+tableName, {...passedObject}).then((res)=>{

        itemID =  res.data.insertId
    })
    return itemID
}

export async function DeleteItemExpress (tableName, passedID) {
   // console.log(passedID)
    await MyAxios.delete(ApiLink+`delete/${tableName}/${passedID}`)
}

export async function UpdateItemExpress (tableName, passedOBJ, passedID) {
    await MyAxios.put(ApiLink+`update-dynamic/${tableName}`, {...passedOBJ, id:passedID})
}
export async function UpdateItemExpressCustomID (tableName, passedOBJ, idname, passedID) {
  //update-customid/:tableName/:customid
    await MyAxios.put(ApiLink+`update-customid/${tableName}/${idname}`, {...passedOBJ, [idname]:passedID})
}

// upload image here
export async function UploadImageExpress(imageState) {
    let imageName = ''
    const formData = new FormData();
    formData.append("myfile", imageState);
    await MyAxios.post(ApiLink+'fileupload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => {
        console.log(response)
        imageName = response.data
    })
    
    return imageName 
  }
  
export async function UploadImageAndUpdateDataExpress(imageState, datatable, objName, dataid, customPath) {
  let imageName = ''
  if (customPath) {
    customPath = '/' +customPath 
  }else{
    customPath = ''
  }
    const formData = new FormData();
    formData.append("myfile", imageState);
    await MyAxios.post(ApiLink+'fileupload'+customPath, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((response) => {
  //    console.log(response)
        UpdateItemExpress (datatable, {[objName]:response.data}, dataid)
        imageName = response.data
    })
    //
    //  console.log(imageState, ApiLink+'fileupload'+customPath)
    return imageName
  }
export async function UploadGalleryImageExpress(GaleryImages) {
  let ImageInfo = []
  let formData = new FormData(); 
  // console.log(item.galleryImage)
   for(var i = 0 ; i < GaleryImages.length; i++){
       formData.append('image',GaleryImages[i])
   }
   if(!GaleryImages) return;   
    try {
      const config = {
          headers:{
              'Content-Type' :  'application/json'
          }
      }
      await MyAxios.post(ApiLink+'fileupload/multiple', formData, config)
      .then((res)=>{
        ImageInfo = res.data
        //console.log(res.data)
      });
      return ImageInfo
  } catch (error) {
      console.log(error);
  }
  
  }
  
export async function DeleteImageExpress(pathName, imageName) {   
    await MyAxios.delete(`${ApiLink}delete_image/${pathName}/${imageName}`)
  }
//large Data Function

export async function GetTowTableData (firsttable, secondtable, firstparam, secondparam) {
  let Data;
  await MyAxios.get(ApiLink+`largedata/${firsttable}/${secondtable}/${firstparam}/${secondparam}`).then((res)=>{
      Data =  res.data
  })
  return Data
}

export async function IncreaseValue(tableName, updateCol, quantity, updateIdentyFireCol, idNo) {
  let myQuery = `UPDATE ${tableName} SET ${updateCol} = IncreaseNumber(${updateCol}+${quantity}) where ${updateIdentyFireCol} = ${idNo}`
  await GetDataPassQuery(myQuery)
}
export async function DecreaseValue(tableName, updateCol, quantity, updateIdentyFireCol, idNo) {
  let myQuery = `UPDATE ${tableName} SET ${updateCol} = IncreaseNumber(${updateCol}-${quantity}) where ${updateIdentyFireCol} = ${idNo}`
  //console.log(myQuery)
 await GetDataPassQuery(myQuery)
}

const encryptionKey = 'Himon824143'
export async function SendOtp(data) {
  let newDate = await GetServerTime()
  data = {
    ...data,
    passedTime:newDate+120000,
  }
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
   let response =  await MyAxios.post(ApiLink+'send_otp', {data:encryptedData}, config)
   return response.data
}
export async function SendMessage(data) {
  let newDate = await GetServerTime()
  data = {
    ...data,
    passedTime:newDate+120000,
  }
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), encryptionKey).toString();
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
   let response =  await MyAxios.post(ApiLink+'send_sms', {data:encryptedData}, config)
   return response.data
}
async function GetServerTime() {
  let Data;
  await MyAxios.get(ApiLink + `singledoc/timestamp/1`).then((res) => {
    // console.log(res.data.insertId)
    Data = res.data
  })
  return Data?.[0]?.nowtime
}