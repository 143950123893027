import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { GetEndDate, GetStartDate, } from '../../MyFunctions/MyFunctions'
import { GetAllDataExpress, GetDataPassQuery } from '../../../api/ExpressFunctions'
import { HeaderFooterWrapper } from '../HeadetFooter'
import StockMaintainViewCss from './css/StockMaintainView.module.css'

const StockMaintainView = ({heading}) => {

    let [searchParams, setSearchParams] = useSearchParams();

     async function getData() {
        let purchaseQuery = `SELECT pitem.*, preturn.quantity as rquantity  FROM purchase_items pitem LEFT JOIN purchase_return_items preturn ON pitem.product_id=preturn.product_id` 
        let salesQuery = `SELECT sitem.*, sreturn.quantity as rquantity  FROM test_anayase.sale_items sitem LEFT JOIN test_anayase.sales_return_items sreturn ON sitem.product_id=sreturn.product_id` 
        let purchaseData = await GetDataPassQuery(purchaseQuery)
        let salesData = await GetDataPassQuery(salesQuery)
        arrangeData(salesData, purchaseData)
        
    }

    function arrangeData(mySalesData, mypurchaseData) {
        // console.log(mypurchaseData)
        //let tempSalesArr = [...mySalesData]
        let myTableData = []
        mypurchaseData?.forEach((element) => {
            let finalData = {
                tableName:'purchase',
                saleQuantity: 0,
                saleAmount: 0,
                productID: element.product_id,
                purchaseQuantity: element.quantity-element.rquantity,
                purchaseAmount: parseInt(element.price), 
                identity: element.identity,
                date:element.created_date
            }
            myTableData.push(finalData)
        });
        mySalesData?.forEach((element) => {  
            let finalData = {
                tableName:'sales',
                saleQuantity: element.quantity-element.rquantity,
                saleAmount: parseInt(element.price),
                productID: element.product_id,
                purchaseQuantity: 0,
                purchaseAmount: 0,
                date:element.created_date
            }
            myTableData.push(finalData)
        });
       // console.log(myTableData)
       let withNameArr = []
       let allData = JSON.parse(window.localStorage.getItem('allProduct'))
       myTableData.forEach(melement => {
            for (let index = 0; index < allData.length; index++) {
                const element = allData[index];
                if (element.id===melement.productID) {
                    melement = {
                        ...melement,
                        itemName:element.en_sort_title
                        
                    }
                    withNameArr.push(melement)
                    break
                }
                
            }
        });
        
        filterData(withNameArr)
    }
    function filterData(dataArray) {
        let startDate = searchParams.get('start_date')
        let endDate = searchParams.get('end_date')
        let myStartDate = GetStartDate(startDate)
        let myEndDate = GetEndDate(endDate) 
        console.log(myStartDate, myEndDate)
        let timeStampStart = new Date(myStartDate).getTime()/1000
        let timeStampend = new Date(myEndDate).getTime()/1000
        let openingArr = []
        let mainArr = []

       dataArray.forEach(element => {
            if (startDate && endDate) {
                if (element.date>myStartDate && element.date<myEndDate) {
                    mainArr.push(element)
                }else if(element.date<myStartDate){
                   // console.log(5)
                    openingArr.push(element)
                }
            }else{
                mainArr.push(element)
            }
       });

     // console.log(mainArr, openingArr)
       organizeData(mainArr, openingArr)
    }
    function organizeData(myData, openingArr) {
        // console.log(myData)
        let finalArr = []
        let uniqueElements = myData.slice()
        for (let index = 0; index < myData.length; index++) {
            const element = myData[index];
            let tempArr = []
            for (let mindex = 0; mindex < uniqueElements.length; mindex++) {
                const melement = uniqueElements[mindex];
                if (melement.productID === element.productID) {
                    tempArr.push(melement)
                }
            }

            if (tempArr.length > 0) {
                tempArr.forEach(element => {
                    uniqueElements.forEach((melement, mindex) => {
                        if (melement.productID === element.productID) {
                            uniqueElements.splice(mindex, 1)
                        }
                    });
                });

                finalArr.push(tempArr)
            }

        }
        getTotalData(finalArr, openingArr)
    }
    function getTotalData(myArr, openingArr) {
        //  console.log(myArr)
        let okArr = []
        myArr.forEach(element => {
            let name = ''
            let id = ''
            let totalSale = 0
            let totalPurchase = 0
            let totalPurchaseqty = 0
            let totalSaleqty = 0
            let refPoNo = ''
            element.forEach(myElement => {
                totalSale = myElement.saleAmount
                totalPurchase = myElement.purchaseAmount
                totalPurchaseqty += myElement.purchaseQuantity
                totalSaleqty += myElement.saleQuantity
                name = myElement.itemName
                id = myElement.productID
                refPoNo += myElement.identity ? myElement.identity + ', ' : ''
            })
            let finalData = {
                itemName: name,
                saleQuantity: totalSaleqty,
                saleAmount: totalSale,
                productID: id,
                purchaseQuantity: totalPurchaseqty,
                purchaseAmount: element[0].purchaseAmount,
                totalPurchaseAmm: totalPurchaseqty * element[0].purchaseAmount,
                totalSaleAmm: totalSale * totalSaleqty,
                availableQty: totalPurchaseqty - totalSaleqty,
                stockValue: (totalPurchaseqty - totalSaleqty) * element[0].purchaseAmount,
                refPoNo: refPoNo
            }
            okArr.push(finalData)
        });
        //setInvoiceState(okArr)
        //console.log('done')
        groupProducts(okArr, openingArr)
    }
    const [goldenRoseFinal, setgoldenRoseFinal] = useState()
    const [goldCareFinal, setgoldCareFinal] = useState()
    const [roxanneFinal, setroxaneeFinal] = useState()
    const [weeBabyFinal, setweeBabyFinal] = useState()
    const [myCeyFinal, setmyCeyFinal] = useState()
    const [oliveOilFinal, setOliveOilFinal] = useState()

  async function groupProducts(arrangeData, openingArr) {
        let brandName = searchParams.get('brand')
       // console.log(brandName)
        let allData = JSON.parse(window.localStorage.getItem('allProduct'))
        let myBrands = await GetAllDataExpress('brand_list')
        //console.log(allData)
        let goldenRose = []
        let goldCare = []
        let roxanee = []
        let weeBaby = []
        let myCey = []
        let oliveOil = []
       // console.log(myBrands)
        myBrands.forEach(brand => {
            allData.map((item) => {
                if (item.selected_brand_id === brand.id) {
                    let data = {
                        id: item.id,
                        gorup: item.selected_brand_id,
                        name: item.en_sort_title,
                        sellingPrice:parseInt(item.Selling_price)
                    }
                    if (brand.BrandName==="Golden Rose") {
                        goldenRose.push(data)
                    }else if(brand.BrandName==="Roxanne"){
                        roxanee.push(data)
                    }else if(brand.BrandName==="Gold Care"){
                        goldCare.push(data)
                    }else if(brand.BrandName==="WeeBaby"){
                        weeBaby.push(data)
                    }else if(brand.BrandName==="MyCey"){
                        myCey.push(data)
                    }else{
                        oliveOil.push(data)
                    }
                    
                }
            })
        });

        let gr = allAranger(goldenRose, arrangeData, openingArr)
        let gc = allAranger(goldCare, arrangeData, openingArr)
        let rx = allAranger(roxanee, arrangeData, openingArr)
        let wb = allAranger(weeBaby, arrangeData, openingArr)
        let mc = allAranger(myCey, arrangeData, openingArr)
        let ol = allAranger(oliveOil, arrangeData, openingArr)

        setgoldenRoseFinal(gr)
        setgoldCareFinal(gc)
        setroxaneeFinal(rx)
        setweeBabyFinal(wb)
        setmyCeyFinal(mc)
        setOliveOilFinal(ol)

        let allDataArr = []
        if (brandName) {
            if (brandName === "Golden Rose") {
                allDataArr.push(...gr)
            }
            else if (brandName === "Gold Care") {
                allDataArr.push(...gc)
            }
            else if (brandName === "Roxanne") {
                allDataArr.push(...rx)
            }
            else if (brandName === "MyCey") {
               allDataArr.push(...wb)
            }
            else if (brandName === "WeeBaby") {
                allDataArr.push(...mc)
            } else {
                allDataArr.push(...ol)
            }
        }else{
            allDataArr.push(...gr, ...gc, ...rx,...wb, ...mc, ...ol)
        }
       
       
        getmyAllTotal(allDataArr)
    }
    const [myAllTotal, setMyallTotal] = useState()
    function getmyAllTotal(allData) {
        let openingStockTotal = 0
        let openingStockTotalValue = 0
        let totalPurchaseQty = 0 
        let totalPurchaseAmm = 0
        let totalSaleqty = 0
        let totalSaleAmm = 0
        let clossingStock = 0
        let clossingStockValue = 0
        let tndValue = 0
      //  let totalPurchaseAmm = 0
        allData.forEach(element => {
            //console.log(element.purchaseAmount)
            openingStockTotal += element.openingStock
            openingStockTotalValue += element.openingStockValue
            totalPurchaseQty += element.purchaseQuantity 
            totalPurchaseAmm += element.purchaseAmount*element.purchaseQuantity
            totalSaleqty += element.saleQuantity
            totalSaleAmm += element.saleAmount
            clossingStock += element.purchaseQuantity+element.openingStock-element.saleQuantity
            clossingStockValue += element.clossingStockValue
            tndValue += element.tndValue
        });
        setMyallTotal({openingStockTotal, openingStockTotalValue, totalPurchaseQty, totalPurchaseAmm, totalSaleqty, totalSaleAmm, clossingStock, clossingStockValue, tndValue})
    }
    function allAranger(mainArr, arrangeData , openingArr) {
        let myArr = []
        // console.log(mainArr,arrangeData )
        mainArr.forEach(element => {
           // console.log(element)
            let found = false
            let openingStock = 0
            let openingStockSales = 0
            let openingStockValue = 0 
            let openingStockSalesValue = 0
            let openingTotalStock = 0
            openingArr.forEach(openingElement => {
                if (element.id === openingElement.productID) {
                    openingStock += openingElement.purchaseQuantity
                    openingStockValue += openingElement.purchaseQuantity * openingElement.purchaseAmount
                    openingTotalStock += openingElement.purchaseQuantity
                    openingStockSalesValue += openingElement.saleAmount*openingElement.saleQuantity
                    openingStockSales += openingElement.saleQuantity
                }
            });
           // console.log(openingStockSalesValue, openingStockSales)
            arrangeData.forEach(aelement => {
                if (element.id === aelement.productID) {
                    let okData = { 
                        ...aelement,
                        sellingPrice:element.sellingPrice,
                        openingStock: openingStock-openingStockSales,
                        openingStockValue: openingStockValue-openingStockSalesValue,
                        openingTotalStock: openingTotalStock-openingStockSales,
                        clossingStockValue:aelement.purchaseAmount *(aelement.purchaseQuantity-aelement.saleQuantity +(openingStock-openingStockSales)),
                        clossingStock: aelement.purchaseQuantity-aelement.saleQuantity +(openingStock-openingStockSales),
                        tndValue:aelement.saleAmount*aelement.saleQuantity
                    }
                    myArr.push(okData)
                    found = true
                }
            });
            if (!found) {
                let okData = {
                    itemName: element.name,
                    sellingPrice:element.sellingPrice,
                    saleQuantity: 0,
                    saleAmount: 0,
                    productID: element.id,
                    purchaseQuantity: 0,
                    purchaseAmount: 0,
                    totalPurchaseAmm: 0,
                    totalSaleAmm: 0,
                    availableQty: 0,
                    stockValue: 0,
                    refPoNo: '',
                    openingStock: openingStock-openingStockSales,
                    openingStockValue: openingStockValue-openingStockSalesValue,
                    openingTotalStock: openingTotalStock-openingStockSales,
                    clossingStockValue: (openingStock-openingStockSales),
                    clossingStock: (openingStock-openingStockSales),
                    tndValue:0
                }
                myArr.push(okData)
            }
        });
        return myArr
    }
    function getColor() {
        let leftPart = document.querySelector('.sidepart')
        leftPart?.classList.add('display-none')
       // console.log(leftPart)
    }

    useEffect(() => {
        getColor()
       getData()
       //filterData()
    }, [])


    function checkClick(params) {
        //
        
        console.log(1)
        getData()

    }

    return (
        <>
        <HeaderFooterWrapper
        content={<>

            <h3>Stock Maintain Report</h3>
            <div className={StockMaintainViewCss.details}>
                <div className={StockMaintainViewCss.client}>
                    <div className={StockMaintainViewCss.info} onClick={checkClick}>
                        {searchParams.get('start_date') ? <span className={StockMaintainViewCss.moreinfo}>Date : {searchParams.get('start_date')}  To {searchParams.get('end_date')}</span> : ''}

                        {!searchParams.get('start_date') ? <span className={StockMaintainViewCss.moreinfo}>Date : All Time</span> : ''}
                    </div>
                </div>
            </div>
            <div className={`${StockMaintainViewCss.table} ${StockMaintainViewCss.fixed_table}`}>
                <table>
                    <thead >
                        <tr className={StockMaintainViewCss.fixed_table_header}>
                            <th style={{ fontSize: '9px' }} >Sl</th>
                            <th style={{ fontSize: '9px' }}>Product Name</th>
                            {/* <th style={{ fontSize: '9px' }}>Purchase Qty.</th> */}
                            <th style={{ fontSize: '9px' }}>P. Rate</th>
                            <th style={{ fontSize: '9px' }}>Opening Stock </th>
                            <th style={{ fontSize: '9px' }}>Opening Stock Value</th>
                            <th style={{ fontSize: '9px' }}>Ref. PO ID</th>
                            <th style={{ fontSize: '9px' }}>Net PO Rcv. Qty.</th>
                            <th style={{ fontSize: '9px' }}>Net PO Value</th>
                            {/* <th style={{ fontSize: '9px' }}>Opening Total Stock</th> */}
                            <th style={{ fontSize: '9px' }}>Total Net Delivery</th>
                            <th style={{ fontSize: '9px' }}>S. Price</th>
                            <th style={{ fontSize: '9px' }}>T. N. Delivery Value</th>
                            <th style={{ fontSize: '9px' }}>Closing Stock</th>
                            <th style={{ fontSize: '9px' }}>Closing Stock Value (T.P)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchParams.get('brand') && searchParams.get('brand')==='Golden Rose' ? <MyTableok tableArr={goldenRoseFinal} brandName={'Golden Rose'} />:''}
                        {searchParams.get('brand') && searchParams.get('brand')==='Gold Care' ?  <MyTableok tableArr={goldCareFinal} brandName={'Gold Care'} />:''}
                        {searchParams.get('brand') && searchParams.get('brand')==='Roxanne' ?  <MyTableok tableArr={roxanneFinal} brandName={'Roxanne'} />:''}
                        {searchParams.get('brand') && searchParams.get('brand')==='WeeBaby' ?  <MyTableok tableArr={weeBabyFinal} brandName={'WeeBaby'} />:''}
                        {searchParams.get('brand') && searchParams.get('brand')==='MyCey' ?  <MyTableok tableArr={myCeyFinal} brandName={'MyCey'} />:''}
                        {searchParams.get('brand') && searchParams.get('brand')===`Olive's Oil Land` ?  <MyTableok tableArr={oliveOilFinal} brandName={'Olive Oils land'} />:''}
                        
                       {!searchParams.get('brand') ? <>
                       <MyTableok tableArr={goldenRoseFinal} brandName={'Golden Rose'} />
                        <MyTableok tableArr={goldCareFinal} brandName={'Gold Care'} />
                        <MyTableok tableArr={roxanneFinal} brandName={'Roxanne'} />
                        <MyTableok tableArr={weeBabyFinal} brandName={'WeeBaby'} />
                        <MyTableok tableArr={myCeyFinal} brandName={'MyCey'} />
                        <MyTableok tableArr={oliveOilFinal} brandName={'Olive Oils land'} />
                       </>:''}
                        
                        
                        
                        
                        <tr>
                            <td colSpan={3} style={{ fontWeight: 'bold', color:'#850E35' }}>Grand Total : </td>
                            <td style={{ fontWeight: 'bold', color:'#850E35' }}>{myAllTotal?.openingStockTotal}</td>
                            <td style={{ fontWeight: 'bold', color:'#850E35' }}>{myAllTotal?.openingStockTotalValue}</td>
                            <td></td>
                            <td style={{ fontWeight: 'bold', color:'#850E35' }}>{myAllTotal?.totalPurchaseQty}</td>
                            <td style={{ fontWeight: 'bold', color:'#850E35' }}>{myAllTotal?.totalPurchaseAmm}</td>
                            <td style={{ fontWeight: 'bold', color:'#850E35' }}>{myAllTotal?.totalSaleqty}</td>
                                                     
                            <td></td>
                            <td style={{ fontWeight: 'bold', color:'#850E35' }}>{myAllTotal?.tndValue}</td>
                            <td style={{ fontWeight: 'bold', color:'#850E35' }}>{myAllTotal?.clossingStock}</td>
                            <td style={{ fontWeight: 'bold', color:'#850E35' }}>{myAllTotal?.clossingStockValue}</td>
                        </tr>
                        <tr style={{ fontWeight: 'bold', color:'black' }}>
                           <td colSpan={12}>Opening Stock Value (T.P):</td>
                           <td colSpan={0}>{myAllTotal?.openingStockTotalValue}</td>
                        </tr>
                        <tr style={{ fontWeight: 'bold', color:'black' }}>
                          {searchParams.get('start_date')?  <td colSpan={12}>From {searchParams.get('start_date')} To {searchParams.get('end_date')} Total Stock : </td>: <td colSpan={12}>All Time Total Stock : </td>}

                        <td colSpan={0}>{myAllTotal?.totalPurchaseAmm}</td>
                        </tr>
                        <tr style={{ fontWeight: 'bold', color:'black' }}>
                          <td colSpan={12}>Total Net Delivery Value (S.A) : </td>
                        <td colSpan={0}>{myAllTotal?.tndValue}</td>
                        </tr>
                        <tr style={{ fontWeight: 'bold', color:'black' }}>
                        {searchParams.get('start_date')?  <td colSpan={12}>From {searchParams.get('start_date')} To {searchParams.get('end_date')} Closing Stock Value (T.P): </td>: <td colSpan={12}>All Time Closing Stock Value (T.P): </td>}
                        <td colSpan={0}>{myAllTotal?.clossingStockValue}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
       
        </>}
        />
           
        </>
    )
}

export default StockMaintainView

const MyTableok = ({ tableArr, brandName }) => {
    function getTotalAll(marray, identity) {
        let total = 0
        marray?.forEach(element => {
            total += element[identity]
        });
        return total
    }
    return (
        <>
            {tableArr?.map((item, index) => {
                return <tr key={item.productID}>
                    <td style={{ fontSize: '10px' }}>{index + 1}</td>
                    <td style={{ textAlign: 'left', fontSize: '10px' }} >{item.itemName}</td>
                    {/* <td style={{ fontSize: '10px' }}>{item.purchaseQuantity}</td> */}
                    <td style={{ fontSize: '10px' }}>{item.purchaseAmount}</td>
                    <td style={{ fontSize: '10px' }}>{item.openingStock}</td>
                    <td style={{ fontSize: '10px' }}>{item.openingStockValue}</td>
                    <td style={{ fontSize: '10px', padding: '3px' }}>{item.refPoNo}</td>
                    <td style={{ fontSize: '10px' }}>{item.purchaseQuantity}</td>
                    <td style={{ fontSize: '10px' }}>{item.totalPurchaseAmm}</td>
                    {/* <td style={{ fontSize: '10px' }}>{item.openingTotalStock}</td> */}
                    <td style={{ fontSize: '10px' }}>{item.saleQuantity}</td>
                    <td style={{ fontSize: '10px' }}>{item.sellingPrice}</td>
                    <td style={{ fontSize: '10px' }}>{item.tndValue}</td>
                    <td style={{ fontSize: '10px' }}>{item.clossingStock}</td>
                    
                    <td style={{ fontSize: '10px' }}>{item.clossingStockValue}</td>
                </tr>
            })}
            <tr className={StockMaintainViewCss.final_row} style={{color:'#CB1C8D'}}>
                <td style={{ fontSize: '12px' }} colSpan={'2'}> {brandName} Total :</td>
                {/* <td style={{ fontSize: '12px' }}>{getTotalAll(tableArr, 'purchaseQuantity')}</td> */}
                <td></td>
                <td style={{ fontSize: '12px' }}>{getTotalAll(tableArr, 'openingStock')}</td>
                <td style={{ fontSize: '12px' }}>{getTotalAll(tableArr, 'openingStockValue')}</td>
                <td></td>
                <td style={{ fontSize: '12px' }}>{getTotalAll(tableArr, 'purchaseQuantity')}</td>
                <td style={{ fontSize: '12px' }}>{getTotalAll(tableArr, 'totalPurchaseAmm')}</td>
                <td style={{ fontSize: '12px' }}>{getTotalAll(tableArr, 'saleQuantity')}</td>
                <td style={{ fontSize: '12px' }}></td>
                <td style={{ fontSize: '12px' }}>{getTotalAll(tableArr, 'tndValue')}</td>
                <td style={{ fontSize: '12px' }}>{getTotalAll(tableArr, 'clossingStock')}</td>
                <td style={{ fontSize: '12px' }}>{getTotalAll(tableArr, 'clossingStockValue')}</td>
            </tr>
        </>
    )
}