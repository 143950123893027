import React, { useEffect, useState } from "react";
import { FaBars, FaInfoCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectUserInfo } from "../../ReduxFiles/Slices/UserSlice";
import { SaveSummeryData } from "../../api/OrderInformationApi";
import { GetReturnSalesData, UpdateOrderData } from "../../api/SalesApi";
import GobackButton from "../global/GobackButton";
import Spinner from "../smallThings/Spinner";
import SalesSearchComp from "./SalesSearchComp";
import ActivityViewModel from "./models/ActivityViewModel";
import CustomerInformationModel from "./models/CustomerInformationModel";
import SalesReturnHisCss from "./salesCss/SalesReturnHistory.module.css";
const SalesReturnHistory = () => {
  const [tableData, setTableData] = useState([]);
  const [showUser, setShowUser] = useState(false);
  const [spinnerShow, setSpinnerShow] = useState(false);
  const [activityView, setActivityView] = useState(false);

  async function customerClick(item) {
    setShowUser(item);
    document.body.style.overflow = "hidden";
  }

  function convertDate(time) {
    const date = new Date(time);
    let myDate = date.toLocaleDateString();
    let myTime = date.toLocaleTimeString();
    myDate = myDate.replaceAll("/", "-");
    return myDate + " " + myTime;
  }

  async function tableDataFilter() {
    setSpinnerShow(true);
    let returnData = await GetReturnSalesData({ limit: 10 });
    let tempArr = [];
    returnData.forEach((element) => {
      element = {
        ...element,
        discount: (element.total_orprice * element.discountPercent) / 100,
      };
      tempArr.push(element);
    });
    setTableData(tempArr);
    setSpinnerShow(false);
  }

  useEffect(() => {
    tableDataFilter();
  }, []);

  async function activityViewClick(e, item) {
    setActivityView(item);
    document.body.style.overflow = "hidden";
  }

  const [chargeItem, setChargeItem] = useState();
  function AddReturnChargeClick(item) {
    setChargeItem(item);
  }

  return (
    <section className={SalesReturnHisCss.wrapper}>
      {activityView ? (
        <ActivityViewModel popUp={activityView} setPopUp={setActivityView} />
      ) : (
        ""
      )}
      {showUser && (
        <CustomerInformationModel popUp={showUser} setPopUp={setShowUser} />
      )}
      {chargeItem ? (
        <ReturnChargeAdd
          chargeItem={chargeItem}
          setChargeItem={setChargeItem}
        />
      ) : (
        ""
      )}
      {spinnerShow ? <Spinner /> : ""}
      <div className={SalesReturnHisCss.holder}>
        <div className={SalesReturnHisCss.head}>
          <GobackButton />
          <h2 className={SalesReturnHisCss.saleHeading}>
            Sales Return History
          </h2>
        </div>
        <div className={SalesReturnHisCss.search_holder}>
          <SalesSearchComp
            passedFrom={"rhistory"}
            setFilteredData={setTableData}
          />
        </div>

        <div className={SalesReturnHisCss.table_wrapper}>
          <table>
            <tbody>
              <tr>
                <th>id</th>
                <th>Date</th>
                <th>Customer Name</th>
                <th>Customer Phone</th>
                <th>Customer Address</th>
                <th>Total Ammount</th>
                <th>Return Cost</th>
                <th>Status</th>
                <th>Declined By</th>
                <th>Action</th>
              </tr>

              {tableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link to={`/sales/sales_return_history/${item.id}`}>
                        {"#" + item.id}
                      </Link>
                    </td>
                    <td>{convertDate(item.created_date)}</td>
                    <td>
                      <span onClick={() => customerClick(item)}>
                        {item.name}
                      </span>
                    </td>
                    <td>{item.phone || item.aphone}</td>
                    <td>{item.address}</td>
                    <td>{item.rprice - item.discount}</td>
                    <td>{item.return_charge}</td>
                    <td>
                      <div className={SalesReturnHisCss.myStatus}>
                        <p className={SalesReturnHisCss.fullreturn}>
                          {"Order Return"}
                        </p>
                        <button
                          onClick={() => {
                            AddReturnChargeClick(item);
                          }}
                        >
                          Return Charge
                        </button>
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <div className={SalesReturnHisCss.action_buttons}>
                        <Link to={`/sales/sales_return_history/${item.id}`}>
                          <FaInfoCircle />
                        </Link>

                        <Link to={""}>
                          {" "}
                          <FaBars
                            onClick={(e) => {
                              activityViewClick(e, item);
                            }}
                          />
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default SalesReturnHistory;

const ReturnChargeAdd = ({ chargeItem, setChargeItem }) => {
  const userInfo = useSelector(selectUserInfo);
  const [return_charge, setReturnCharge] = useState(chargeItem?.return_charge);
  async function onUpdateClick() {
    let nestedData = {
      activity: "Return Charge Added",
      ProssesedBy: userInfo.id,
      sale_id: chargeItem.id,
      created_date: new Date().getTime(),
    };

    await UpdateOrderData(chargeItem.id, { return_charge: return_charge });
    await SaveSummeryData(nestedData);
    setChargeItem();
  }

  return (
    <div className={SalesReturnHisCss.deliverydone_wrapper}>
      <div className={SalesReturnHisCss.deliverydone}>
        <h3>Add Return Charge</h3>
        <h4>Return Number : {return_charge}</h4>
        <p>Customer Name : {chargeItem.name}</p>
        <div className={SalesReturnHisCss.common}>
          <input
            type="number"
            placeholder="0"
            name="return_charge"
            onChange={(e) => {
              setReturnCharge(e.target.value);
            }}
            value={return_charge}
          />
          <div className={SalesReturnHisCss.deliverydone_btns}>
            <button onClick={onUpdateClick}>Update</button>
            <button
              onClick={() => {
                setChargeItem();
                document.body.style.overflow = "auto";
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
