import React from "react";
import style from './css/brandTotal&ProductDetails.module.css'
export default function BrandTotalModel({ popUp, setPopUp }) {
  return (
    <div className={style.wrapper}>
      <div className={style.holder}>
        <h2>Brand Wise Sale</h2>
        <div className={style.table_wrapper}>
          <table>
            <thead>
              <tr>
                <th>SL</th>
                <th>Brand Name</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {popUp.map((item,index) => {
                return (
                  <tr key={item.id}>
                    <td>{index+1}</td>
                    <td>{item.BrandName}</td>
                    <td>{item.brandTotal}</td>
                  </tr>
                );
              })}
            </tbody>
         
          </table>
        </div>
       <div className={style.button_Wrapper}>
       <button 
          onClick={() => {
            setPopUp();
            document.body.style.overflow = "auto";
          }}
        >
          Close
        </button>
       </div>
      </div>
    </div>
  );
}
