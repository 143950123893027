import React from 'react'
import { Link } from 'react-router-dom'
import manageCss from './css/ManageCustomerMain.module.css'

const ManageCustomerMain = () => {
    const crmMenu = [
        {title:'Customer List', link:'/manage-customer/customer-list', icon:''},
        {title:'Add New Customer', link:'/manage-customer/add-new-customer', icon:''},
        {title:'Trash List', link:'/manage-customer/trash-list', icon:''},
        {title:'Send Message', link:'/manage-customer/send-message', icon:''},
        {title:'Wish List', link:'/manage-customer/wishlist', icon:''},
        {title:'Manage Complain', link:'/manage-customer/complain', icon:''},
    ]
    return (
        <div className={manageCss.wrapper}>
            <div className={manageCss.menuitems}>
                {crmMenu.map((item, index) => {
                    return <Link to={item.link} className={manageCss.item} key={index}>
                        <h2>{item.title}</h2>
                    </Link>
                })}
            </div>
        </div>
    )
}

export default ManageCustomerMain
