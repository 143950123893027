import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GetAllDataExpress, GetDataPassQuery, GetFilteredDataExpress } from '../../../api/ExpressFunctions';
import { GetEndDate, GetStartDate, LocalTimeStampToDateOnly } from '../../MyFunctions/MyFunctions';
import { HeaderFooterWrapper } from '../HeadetFooter';
import ExpenseReportPDFCss from './css/ExpenseReportPDF.module.css'


const ExpenseReportPDF = ({ heading }) => {
    
    const [invoiceState, setInvoiceState] = useState()
    const [gorupData, setGroupData] = useState()
    const [headData, setHeadData] = useState()
    let [searchParams, setSearchParams] = useSearchParams();

    async function getDataformDatabase(params) {
        let myGroupData = await GetAllDataExpress('expense_group')
        setGroupData(myGroupData)
        let myHeadData = await GetAllDataExpress('expense_head')
        setHeadData(myHeadData)
        let expenseData = []
        let startDate = searchParams.get('start_date')
        let endDate = searchParams.get('end_date')
        let myGroup = searchParams.get('group')
        let myStartDate = GetStartDate(startDate)
        let myEndDate = GetEndDate(endDate)
        if (startDate) {
            //console.log(myStartDate, myEndDate)
            let mQuery = `SELECT * FROM expense WHERE created_date > ${myStartDate} AND created_date < ${myEndDate}`
            expenseData = await GetDataPassQuery(mQuery)
           // console.log(new Date(expenseData[0]?.created_date))
        }else{
            expenseData = await GetAllDataExpress('expense')
          //  console.log(expenseData) 
        }
       
        let itemArr = []
        let myPromises = expenseData.map(async function (item) {
            let nestedData = await GetFilteredDataExpress('expense_items', 'expenseID', item.id)
            nestedData?.forEach(element => {
                if (myGroup) {
                    //console.log(myGroup, element, element.selectedGroup)
                    if (parseInt(myGroup) === element.selectedGroup) {
                        element = { ...element, expenseNo: item.id }
                        itemArr.push(element) 
                    }
                }else{
                    element = { ...element, expenseNo: item.id }
                    itemArr.push(element) 
                }
               
            })
        })
        Promise.all(myPromises).then(() => {
            // console.log(itemArr) 
            let okArr = []
            for (let index = 0; index < itemArr.length; index++) {
                let element = itemArr[index];
                // console.log(element)
                for (let index = 0; index < myGroupData?.length; index++) {
                    let gelement = myGroupData[index];
                    if (element.selectedGroup === gelement.gorupID) {
                        element = { ...element, groupName: gelement.groupName }
                        okArr.push(element)
                    }
                }
            }
            setInvoiceState(itemArr)
            // console.log(itemArr)
        })
        //  setInvoiceState(expenseData)
    }
    function getAllTotal(arrayPass, identifire) {
        let total = 0
        arrayPass?.forEach(element => {
            total += element[identifire]
        });
        return total;
    }

    useEffect(() => {
        getDataformDatabase()
    }, [])
    function getColor() {
        let leftPart = document.querySelector('.sidepart')
        leftPart?.classList.add('display-none')
       // console.log(leftPart)
    }

    useEffect(() => {
        getColor()
       
    }, [])

    return (
        <>
            <HeaderFooterWrapper
            content={<>
            <h3>Expense Report</h3>
            <div className={ExpenseReportPDFCss.details}>
                <div className={ExpenseReportPDFCss.client}>
                    <div className={ExpenseReportPDFCss.info}>
                        {searchParams.get('start_date') ? <span className={ExpenseReportPDFCss.moreinfo}>Date : {searchParams.get('start_date')}  To {searchParams.get('end_date')}</span> : <span className={ExpenseReportPDFCss.moreinfo}> Date : All Time</span>}
                    </div>
                </div>
                <div className={ExpenseReportPDFCss.invoice}>

                </div>
            </div>
            <div className={`${ExpenseReportPDFCss.table} ${ExpenseReportPDFCss.sales_table}`}>
                <table>
                    <thead>
                        <tr>
                            <th style={{ textAlign: 'center' }}>Sl</th>
                            <th>Exp. Id</th>
                            <th>Date</th>
                            <th>Particulars</th>
                            <th >Expense Group</th>
                            <th >Expense Head</th>
                            <th >Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoiceState?.map((item, index) => {
                            return <tr key={index}>
                                <td style={{ textAlign: 'center' }}>{index + 1}</td>
                                <td style={{ textAlign: 'center' }}>Ex #{item.expenseNo}</td>
                                <td style={{ textAlign: 'left' }}>{LocalTimeStampToDateOnly(item.created_date)}</td>
                                <td style={{ textAlign: 'left' }}>{item.particular}</td>
                                <td style={{ textAlign: 'left' }}>
                                {gorupData?.filter((fitem)=>{
                                    return fitem.id===item.selectedGroup ? fitem:''
                                })
                                .map((mitem)=>{
                                    return mitem.groupName
                                })}</td>
                                <td style={{ textAlign: 'left' }}>
                                {headData?.filter((fitem)=>{
                                    return fitem.id===item.expenseHeadID ? fitem:''
                                })
                                .map((mitem)=>{
                                    return mitem.headName
                                })}</td>
                                <td style={{ textAlign: 'right' }}>{item.price}</td>
                            </tr>
                        })}
                        <tr>
                            <td colSpan={6}>Total : </td>
                            <td>{getAllTotal(invoiceState, 'price')}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </>}
            />
        </>
    )
}

export default ExpenseReportPDF