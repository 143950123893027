import { faCircle, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  GetBankList,
  IncreaseBankAmount,
  SaveBankTransactionData,
} from "../../../api/BankTransactionApi";
import { GetCreditFilterData, SaveCreditData } from "../../../api/CreditApi";
import { SaveDayBookData } from "../../../api/DayBookApi";
import {
  SaveSummeryData,
  SaveTrackingData,
} from "../../../api/OrderInformationApi";
import { UpdateOrderData } from "../../../api/SalesApi";
import { selectUserInfo } from "../../../ReduxFiles/Slices/UserSlice";
import LoadingShow from "../../smallThings/LoadingShow";
import collectionCss from "./collection.module.css";

const CollectOption = ({
  deliveryDoneInfo,
  setDeliveryDoneInfo,
  tableData,
  setTableData,
}) => {
  const userIfno = useSelector(selectUserInfo);
  const myFocusRef = useRef();
  const [bankList, setBankList] = useState();
  const [addDeliveryData, setAddDeliveryData] = useState();
  const [selectedType, setSelectedType] = useState("bank");
  const [bankID, setBankID] = useState();
  const [showLoading, setShowLoading] = useState(false);
  function onDataChange(e) {
    let name = e.target.name;
    let value = e.target.value || 0;
    setAddDeliveryData({ ...addDeliveryData, [name]: parseFloat(value) });
  }

  function onBankSelect(e) {
    setBankID(e.target.value);
  }

  function focusOutFunction() {}

  async function onSubmitClick(e) {
    e.preventDefault();
    if (selectedType === "bank") {
      bankData();
    } else {
      submitFunction();
    }
  }

  async function bankData() {
    if (bankID) {
      if (addDeliveryData.collectedAmmount > 0) {
        setShowLoading(true);
        let myCollectedAmount = addDeliveryData.collectedAmmount;
        delete addDeliveryData.collectedAmmount;
        delete addDeliveryData.totalCollection;
        let dataforUpdate = {
          ...addDeliveryData,
          deliveryComplete: 1,
          status: "Delivery Complete",
          packaging: 0,
        };
        delete dataforUpdate.orBalance;
        delete dataforUpdate.discountAmount;
        delete dataforUpdate.returnAmount;
        let nestedData = {
          ProssesedBy: userIfno.id,
          sale_id: deliveryDoneInfo.id,
          created_date: new Date().getTime(),
          activity: "Delivery handover complete",
        };
        await UpdateOrderData(deliveryDoneInfo.id, dataforUpdate);
        await SaveSummeryData(nestedData);

        if (!deliveryDoneInfo.paymentCompleate) {
          let cashData = {
            identity: deliveryDoneInfo.id,
            receiveAmmount: myCollectedAmount,
            particulars: "Sales",
            receiveType: "Collection",
            transactionType: "bank",
            receivedByID: userIfno.id,
            remark: "",
            created_date: new Date().getTime(),
          };
          let creditID = await SaveCreditData(cashData);
          let bankData = {
            debitCredit: 1,
            bankID: bankID,
            identity: deliveryDoneInfo.id,
            receiptNumber: "",
            voucherNo: creditID.insertId,
            receiveAmmount: myCollectedAmount,
            particulars: "Sales",
            head: "Deposite",
            processorID: userIfno.id,
            paymentAmmount: 0,
            remark: "",
            transactionType: "Bank Deposite",
            voucherType: 2,
            created_date: new Date().getTime(),
          };
          let dayBookData = {
            created_date: new Date().getTime(),
            paticulars: "Sales (Order Completed)",
            identity: deliveryDoneInfo.id,
            received: myCollectedAmount,
            payment: 0,
            processorID: userIfno.id,
          };
          await SaveBankTransactionData(bankData);
          await SaveDayBookData(dayBookData);
          await IncreaseBankAmount({ id: bankID, amount: myCollectedAmount });
          reduceData();
          setDeliveryDoneInfo();
          setShowLoading(false);
          document.body.style.overflow = "auto";
        }
      } else {
        alert("You Must Collect Minimum Amount to Continue");
      }
    } else {
      toast.error("please select bank to continue");
    }
  }

  async function submitFunction() {
    setShowLoading(true);
    let myCollectedAmount = addDeliveryData.collectedAmmount;
    delete addDeliveryData.collectedAmmount;
    delete addDeliveryData.totalCollection;
    let dataforUpdate = {
      ...addDeliveryData,
      deliveryComplete: 1,
      status: "Delivery Complete",
      packaging: 0,
    };
    delete dataforUpdate.orBalance;
    delete dataforUpdate.discountAmount;
    delete dataforUpdate.returnAmount;
    let nestedData = {
      ProssesedBy: userIfno.id,
      sale_id: deliveryDoneInfo.id,
      created_date: new Date().getTime(),
      activity: "Delivery handover complete",
    };
    await UpdateOrderData(deliveryDoneInfo.id, dataforUpdate);
    await SaveSummeryData(nestedData);

    if (!deliveryDoneInfo.paymentCompleate) {
      if (selectedType === "cash") {
        let cashData = {
          identity: deliveryDoneInfo.id,
          receiveAmmount: myCollectedAmount,
          particulars: "Sales",
          receiveType: "Collection",
          transactionType: "cash",
          receivedByID: userIfno.id,
          remark: "",
          created_date: new Date().getTime(),
        };
        let dayBookData = {
          created_date: new Date().getTime(),
          paticulars: "Sales (Order Completed)",
          identity: deliveryDoneInfo.id,
          received: myCollectedAmount,
          payment: 0,
          processorID: userIfno.id,
        };
        await SaveDayBookData(dayBookData);
        if (myCollectedAmount > 0) {
          await SaveCreditData(cashData);
        }
      } else {
        // let sslData = {
        //     identity: deliveryDoneInfo.id,
        //     receiveAmmount: myCollectedAmount,
        //     particulars: 'Sales',
        //     receiveType: 'Collection',
        //     receivedByID: userIfno.id,
        //     remark: ''
        // }
        if (myCollectedAmount > 0) {
          // await SaveDataExpress('ssl_payment', { ...sslData })
        }
        let dayBookData = {
          created_date: new Date().getTime(),
          paticulars: "Sales (Order Completed)",
          identity: deliveryDoneInfo.id,
          received: myCollectedAmount,
          payment: 0,
          processorID: userIfno.id,
        };
        await SaveDayBookData(dayBookData);
      }
    }
    let trackData = {
      sales_id: deliveryDoneInfo.id,
      created_date: new Date().getTime(),
      note: "Your Delivery is Completed.",
    };
    await SaveTrackingData(trackData);
    reduceData();
    setDeliveryDoneInfo();
    setShowLoading(false);
    document.body.style.overflow = "auto";
  }

  function reduceData() {
    const myArr = tableData.filter((fitem) => {
      return fitem.id !== deliveryDoneInfo.itemid;
    });
    setTableData(myArr);
  }

  function onCancelClick() {
    setDeliveryDoneInfo();
    document.body.style.overflow = "auto";
  }

  async function FirstData() {
    let getCreditData = await GetCreditFilterData(
      `identity = ${deliveryDoneInfo.id} AND particulars = 'Sales'`
    );
    let myBanks = await GetBankList();
    setBankList(myBanks);
    let Ctotal = 0;
    getCreditData.forEach((item) => {
      Ctotal += item.receiveAmmount || 0;
    });
    let returnAmount = deliveryDoneInfo.rprice * deliveryDoneInfo.rquantity;
    let discountAmount =
      (parseInt(deliveryDoneInfo?.total_orprice) *
        deliveryDoneInfo.discountPercent) /
      100;
    setAddDeliveryData({
      codSSLCharge: "",
      collectedAmmount:
        parseInt(deliveryDoneInfo?.total_price) +
        deliveryDoneInfo?.deliveryCharge -
        returnAmount -
        Ctotal -
        discountAmount,
      deliveryCost: deliveryDoneInfo?.deliveryCharge,
      orBalance:
        parseInt(deliveryDoneInfo?.total_price) +
        deliveryDoneInfo?.deliveryCharge -
        returnAmount -
        Ctotal -
        discountAmount,
      discountAmount,
      returnAmount,
      totalCollection: Ctotal,
    });
  }

  useEffect(() => {
    FirstData();
  }, []);

  function onTypeClick(text) {
    setSelectedType(text);
  }
  return (
    <div className={collectionCss.deliverydone}>
      <LoadingShow
        ShowLodign={showLoading}
        message={"Processing your data..."}
      />
      {/* onClick={onSubmitClick} */}
      <div className={collectionCss.holder}>
        <h3>Mark as Deliver Done (Invoice - #{deliveryDoneInfo.id})</h3>
        <form onSubmit={onSubmitClick} className={collectionCss.my_form}>
          <div className={collectionCss.inputsection}>
            <div className={collectionCss.common}>
              <label>
                Collectable Ammount {deliveryDoneInfo?.totalCollection}
              </label>
              {deliveryDoneInfo.paymentCompleate ? (
                <>{addDeliveryData?.collectedAmmount}</>
              ) : (
                <input
                  ref={myFocusRef}
                  name="collectedAmmount"
                  onChange={onDataChange}
                  onBlur={focusOutFunction}
                  type={"number"}
                  placeholder="0.00"
                  defaultValue={addDeliveryData?.collectedAmmount}
                />
              )}
            </div>
            <div className={collectionCss.common}>
              <label>Delivery Cost</label>
              <input
                name="deliveryCost"
                onChange={onDataChange}
                onBlur={focusOutFunction}
                type={"number"}
                placeholder="0.00"
                defaultValue={addDeliveryData?.deliveryCost}
              />
            </div>
            <div className={collectionCss.common}>
              <label>COD/SSL Charge</label>
              <input
                name="codSSLCharge"
                onChange={onDataChange}
                onBlur={focusOutFunction}
                type={"number"}
                placeholder="0.00"
                defaultValue={addDeliveryData?.codSSLCharge}
                required
              />
            </div>
            {/* <p>Total Ammount : {getTotal()}</p> */}
            <div className={collectionCss.delivery_info}>
              <p>
                <strong>Payable Amount : </strong>
                {addDeliveryData?.orBalance}
              </p>
              <p>
                <strong>Collected Amount : </strong>
                {addDeliveryData?.totalCollection}
              </p>
              <p className={collectionCss.color_red}>
                <strong>Due Amount : </strong>
                {addDeliveryData ? (
                  addDeliveryData?.orBalance -
                  addDeliveryData?.totalCollection -
                  addDeliveryData?.collectedAmmount
                ) : (
                  <>
                    {deliveryDoneInfo?.grandTotal +
                      deliveryDoneInfo.deliveryCharge}
                  </>
                )}
              </p>
            </div>
            <div className={collectionCss.receive}>
              <p>
                <strong>Received By : </strong>
              </p>
              {deliveryDoneInfo.paymentCompleate ? (
                <> : SSL Payment Complete</>
              ) : (
                <>
                  <div className={`${collectionCss.icon} ${collectionCss.sec}`}>
                    {selectedType === "bank" ? (
                      <span className={collectionCss.active_svg}>
                        <FontAwesomeIcon
                          className={collectionCss.color_green}
                          icon={faCircleCheck}
                        />{" "}
                        <p>Bank</p>
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          onTypeClick("bank");
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCircle}
                          className={collectionCss.color_icon}
                        />{" "}
                        <p>Bank</p>
                      </span>
                    )}
                  </div>
                  <div className={`${collectionCss.icon} ${collectionCss.sec}`}>
                    {selectedType === "cash" ? (
                      <span className={collectionCss.active_svg}>
                        <FontAwesomeIcon
                          className={collectionCss.color_green}
                          icon={faCircleCheck}
                        />{" "}
                        <p>Cash</p>
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          onTypeClick("cash");
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCircle}
                          className={collectionCss.color_icon}
                        />{" "}
                        <p>Cash</p>
                      </span>
                    )}
                  </div>
                  <div className={`${collectionCss.icon} ${collectionCss.sec}`}>
                    {selectedType === "ssl" ? (
                      <span className={collectionCss.active_svg}>
                        <FontAwesomeIcon
                          className={collectionCss.color_green}
                          icon={faCircleCheck}
                        />{" "}
                        <p>SSL Commerz</p>
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          onTypeClick("ssl");
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCircle}
                          className={collectionCss.color_icon}
                        />{" "}
                        <p>SSL Commerz</p>
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>
            {selectedType === "bank" ? (
              <div className={collectionCss.bank_select}>
                <select onChange={onBankSelect} required>
                  <option value={""}>Select Bank</option>
                  {bankList?.map((item) => {
                    return (
                      <option value={item.id} key={item.id}>
                        {item.bankName} ({item.acNumber})
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : (
              ""
            )}

            <div className={`${collectionCss.buttonsection}`}>
              <button type="button" onClick={onCancelClick}>
                Cancel
              </button>
              <button type="submit" disabled={showLoading}>
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default CollectOption;
