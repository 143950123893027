import pendingSalesCss from "./salesCss/PendingSales.module.css";
import React, { useEffect, useState } from "react";
import { FaBars, FaInfoCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ServerTimeToFormateTime } from "../../api/GlobalFunctions";
import { GetOrderMethod, GetPendingSalesData } from "../../api/SalesApi";
import CustomerInformationModel from "./models/CustomerInformationModel";
import GobackButton from "../global/GobackButton";
import UpdateDate from "./models/UpdateDate";
import { selectUserInfo } from "../../ReduxFiles/Slices/UserSlice";
import { useSelector } from "react-redux";
import { GrResources } from "react-icons/gr";
import SourceModel from "./models/SourceModel";
import { OrderMethodObject } from "../global/GlobalConstant";

async function getDataFormDatabase(setTableData) {
  let data = await GetPendingSalesData();
  // console.log(data);
  setTableData(data);
}

const PendingSales = () => {
  const [tableData, setTableData] = useState([]);
  const [showUser, setShowUser] = useState(false);
  const currentUser = useSelector(selectUserInfo);
  async function customerClick(item) {
    setShowUser(item);
    document.body.style.overflow = "hidden";
  }
  const [dateData, setDateData] = useState();
  const [sourceData, setSourceData] = useState();
  useEffect(() => {
    !dateData && !sourceData && getDataFormDatabase(setTableData);
  }, [dateData, sourceData]);

  async function onSourceClick(item) {
    const msourcedata = await GetOrderMethod(`order_id=${item.id}`);
    setSourceData({ ...item, source: msourcedata[0] || {} });
    document.body.style.overflow = "hidden";
  }

  return (
    <section className={pendingSalesCss.wrapper}>
      {sourceData && (
        <SourceModel popUp={sourceData} setPopUp={setSourceData} />
      )}
      {dateData && <UpdateDate popUp={dateData} setPopUp={setDateData} />}
      {showUser && (
        <CustomerInformationModel popUp={showUser} setPopUp={setShowUser} />
      )}
      <div className={pendingSalesCss.holder}>
        <div className={pendingSalesCss.head}>
          <GobackButton />
          <h2 className={pendingSalesCss.saleHeading}>Pending Sales</h2>
        </div>
        <div className={pendingSalesCss.table_wrapper}>
          <table>
            <thead>
              <tr>
                <th>id</th>
                <th>Date & Time</th>
                <th>Customer Name</th>
                <th>Customer Phone</th>
                <th>Customer Address</th>
                <th>Total Amount</th>
                <th>SSL Paid</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <Link to={`/sales/details/${item.id}`}>
                        {"#" + item.id}
                      </Link>
                    </td>
                    <td>
                      {ServerTimeToFormateTime(item.created_date)}
                      {currentUser.userrole === 1 ? (
                        <button
                          className={pendingSalesCss.edit_button}
                          onClick={() => {
                            setDateData(item);
                            document.body.style.overflow = "hidden";
                          }}
                        >
                          Edit
                        </button>
                      ) : (
                        ""
                      )}
                    </td>
                    <td onClick={() => customerClick(item)}>{item.name}</td>
                    <td>{item.phone}</td>
                    <td>{`${item.District}, ${item.Area}, ${item.Address}.`}</td>
                    <td>{item.grandTotal + item.deliveryCharge}</td>
                    <td>{item.paymentCompleate ? "Yes" : "No"}</td>
                    <td>
                      <div className={pendingSalesCss.action_buttons}>
                        <Link to={`/sales/details/${item.id}`}>
                          <FaInfoCircle />
                        </Link>
                        <button
                          className={
                            item.method_code > 0 ? pendingSalesCss.assigned : ""
                          }
                          title={OrderMethodObject[item.method_code]}
                          onClick={() => onSourceClick(item)}
                        >
                          <GrResources />
                        </button>
                        <button>
                          <FaBars />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default PendingSales;
