import React from "react";
import style from "./css/brandTotal&ProductDetails.module.css";
import { ServerTimeToFormateTime } from "../../../../../../api/GlobalFunctions";

export default function ProductDetailsView({ popUp, setPopUp }) {
  return (
    <div className={style.wrapper}>
      <div className={style.holder}>
        <h4>{popUp.name}</h4>
        <div className={style.product_price}>
          <p>TP Price : {popUp.tp_price}</p>
          <p>MRP Price : {popUp.mrp_price}</p>
        </div>
        <div className={style.table_wrapper}>
          <table>
            <thead>
              <tr>
                <th>SL</th>
                <th>Date</th>
                <th>Order No</th>
                <th>Variance Name</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {popUp.products.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{ServerTimeToFormateTime(item.created_date)}</td>
                    <td>{item.sales_id}</td>
                    <td>{item.Variance_name}</td>
                    <td>{item.price}</td>
                    <td>{item.dis_amount}</td>
                    <td>{item.quantity}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={style.button_Wrapper}>
          <button
            onClick={() => {
              setPopUp();
              document.body.style.overflow = "auto";
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
