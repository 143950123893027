import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GetAllVariance } from '../../api/ProductVarianceApi'

const initialState = {
  allVariance:[],
  status:'idle'
}
export const VarianceSlice = createSlice({
  name: 'variance',
  initialState,
  reducers: {
    setAllVariance: (state, action) => {
      state.allVariance = action.payload
    },
    setStatus: (state, action) => {
        state.status = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchAllVariance.pending, (state, action) => {
      state.status = 'pending'
    })
    .addCase(fetchAllVariance.fulfilled, (state, action) => {
      state.status = 'success'
      let mData = action.payload
        state.allVariance = mData
    })
    .addCase(fetchAllVariance.rejected, (state, action) => {
      state.status = action.payload
    })
},
})

export const fetchAllVariance = createAsyncThunk('variance/fetchAllVariance', async ()=>{
  try {
    const resposne = await GetAllVariance()
    return resposne
  } catch (error) {
    return error.message
  }
})

export const selectAllVariance = state =>state.variance.allVariance
export const selectVarianceStatus = state =>state.variance.status

export const { setAllVariance, setStatus} = VarianceSlice.actions

export default VarianceSlice.reducer
